import {Component, ExistingProvider, forwardRef, Type} from '@angular/core';
import {PageableTableComponent} from '../../common/components/pageable-table.component';
import {IContact} from '../../api/shared/app-domain/contact';
import {AppResourceService} from '../../app.resource.service';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {ColumnsInfoService} from '../common/column-header.component';
import {ICompany, TCompanyType} from '../../api/shared/app-domain/company';
import {inheritanceProvider} from '../../common/util/util';


@Component({
  selector: 'app-contacts-table',
  template: `
    <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit']"></app-table-toolbar>
    <app-entity-edit-dialog #editDialog (apply)="onCreatedOrUpdated($event)"  width="auto" minWidth="auto">
      <ng-template pTemplate let-param>
        <app-contact-editor [param]="param"></app-contact-editor>
      </ng-template>
    </app-entity-edit-dialog>

    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [(first)]="firstPage"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Contacts"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               [filters]="filters"
               [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="name" filterType="optionsIn" label="Contact"
                               frozen="lastLeft" [fixedWidth]="300"
                               [options]="$any(propertyOptions.getOptions('contactFullNames') | async)"
                               optionValue="name"
                               [optionsVirtualScroll]="true"></app-column-header>
            <app-column-header field="status" filterType="optionsEquals"
                               [fixedWidth]="140"
                               [options]="propertyOptions.commonStatuses">
            </app-column-header>
            <app-column-header field="types" filterType="optionsArray"
                               [options]="propertyOptions.contactTypes"
                               optionsSelectedLabel="{0} Types selected">
            </app-column-header>

            <app-column-header filterType="text" field="email" [fixedWidth]="250">
            </app-column-header>
            <app-column-header field="country" filterType="optionsIn"
                               [fixedWidth]="150"
                               optionValue="name" optionLabel="name"
                               optionIcon="flag"
                               [options]="$any(propertyOptions.getOptions('countries') | async) | options">
            </app-column-header>
            <app-column-header field="state" filterType="optionsIn"
                               [fixedWidth]="150"
                               [options]="propertyOptions.usStates | options">
            </app-column-header>
            <app-column-header filterType="text" field="city" [fixedWidth]="150">
            </app-column-header>
            <app-column-header filterType="text" field="phone" [fixedWidth]="150">
            </app-column-header>
            <app-column-header field="birthday" filterType="date" [fixedWidth]="180">
            </app-column-header>

            <app-column-header field="createdAt" filterType="date" [fixedWidth]="180"></app-column-header>
            <app-column-header field="updatedAt" filterType="date" [fixedWidth]="180"></app-column-header>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="name" type="custom" [rowData]="rowData"
                            frozen="lastLeft" tdStyleClass="py-0"
                            styleClass="flex justify-content-between">
              <div class="flex align-items-center overflow-x-hidden">
                <app-avatar [(ngModel)]="rowData.avatar" [name]="rowData.name" [editable]="false"></app-avatar>
                <div class="ml-2 mt-overflow-ellipsis" appTooltipOnOverflow>
                  <a class="text-primary no-underline"
                     [routerLink]="['/tbd', 'contact-edit', rowData.id]">{{rowData.name}}</a>
                </div>
              </div>
              <app-row-menu-button [tableBase]="this" [rowData]="rowData"></app-row-menu-button>
            </app-table-cell>
            <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            <app-table-cell type="stringArray" field="types" [rowData]="rowData"
                            [stringArrayRoute]="getAssocLinkByType"></app-table-cell>

            <app-table-cell type="externalLink" field="email" [href]="'mailto:' + rowData.email"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="country" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="state" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="city" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="phone" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="birthday" [value]="rowData.birthday | date: 'MM/dd/yyyy'"></app-table-cell>

            <app-table-cell field="createdAt"
                            [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="updatedAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService,
    inheritanceProvider(PageableTableComponent, ContactsTableComponent)
  ]
})
export class ContactsTableComponent extends PageableTableComponent<IContact> {

  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.entityName = 'Contact';
    this.api = this.resource.contacts;
  }

  getAssocLinkByType(type: TCompanyType, company: ICompany): string {
    return '/tbd';
  }

}
