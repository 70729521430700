import { FilterMatchMode, LazyLoadEvent, SelectItem } from 'primeng/api';
import { isArray, keys } from 'lodash';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { ESortDir, ISearchRequest, ISort, TQuery, TQueryLogical, TQueryOperator } from '../../api/shared/search-api';

export const CUSTOM_MATCH_MODE = {
  IS_NULL: 'isNull',
  IS_NOT_NULL: 'isNotNull'
};

export const FILTER_TO_OPERATOR_MAP: { [fmm: string]: TQueryOperator } = {
  [FilterMatchMode.STARTS_WITH]: 'startsWith',
  [FilterMatchMode.ENDS_WITH]: 'endsWith',
  [FilterMatchMode.CONTAINS]: 'contains',
  [FilterMatchMode.NOT_CONTAINS]: 'notContains',
  [FilterMatchMode.EQUALS]: 'equals',
  [FilterMatchMode.NOT_EQUALS]: 'notEquals',
  [FilterMatchMode.IN]: 'in',
  [FilterMatchMode.LESS_THAN]: 'lt',
  [FilterMatchMode.LESS_THAN_OR_EQUAL_TO]: 'lte',
  [FilterMatchMode.GREATER_THAN]: 'gt',
  [FilterMatchMode.GREATER_THAN_OR_EQUAL_TO]: 'gte',
  [FilterMatchMode.BETWEEN]: 'between',
  [FilterMatchMode.IS]: 'equals',
  [FilterMatchMode.IS_NOT]: 'notEquals',
  [FilterMatchMode.BEFORE]: 'lt',
  [FilterMatchMode.AFTER]: 'gt',
  [FilterMatchMode.DATE_IS]: 'equals',
  [FilterMatchMode.DATE_IS_NOT]: 'notEquals',
  [FilterMatchMode.DATE_BEFORE]: 'lte',
  [FilterMatchMode.DATE_AFTER]: 'gte',
  [CUSTOM_MATCH_MODE.IS_NULL]: 'isNull',
  [CUSTOM_MATCH_MODE.IS_NOT_NULL]: 'isNotNull',
};

export type TMatchModeOptionsType = 'null' | 'text' | 'strongText' | 'numeric' | 'date' | 'timestamp' | 'json' | 'boolean' | 'array' | 'default';

export const MATCH_MODE_OPTIONS: {[type in TMatchModeOptionsType]: Array<SelectItem>} = {
  null: [
    { label: 'Is null', value: CUSTOM_MATCH_MODE.IS_NULL},
    { label: 'Is not null', value: CUSTOM_MATCH_MODE.IS_NOT_NULL},
  ],
  text: [
    { label: 'Starts with', value: FilterMatchMode.STARTS_WITH },
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS },
    { label: 'Ends with', value: FilterMatchMode.ENDS_WITH },
    { label: 'Equals', value: FilterMatchMode.EQUALS },
    { label: 'Not equals', value: FilterMatchMode.NOT_EQUALS }
  ],
  strongText: [
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS },
    { label: 'Equals', value: FilterMatchMode.EQUALS },
    { label: 'Not equals', value: FilterMatchMode.NOT_EQUALS }
  ],
  numeric: [
    { label: 'Equals', value: FilterMatchMode.EQUALS },
    { label: 'Not equals', value: FilterMatchMode.NOT_EQUALS },
    { label: 'Less than', value: FilterMatchMode.LESS_THAN },
    { label: 'Less than or equal to', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    { label: 'Greater than', value: FilterMatchMode.GREATER_THAN },
    { label: 'Greater than or equal to', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }

  ],
  date: [
    { label: 'Date is', value: FilterMatchMode.DATE_IS },
    { label: 'Date is not', value: FilterMatchMode.DATE_IS_NOT },
    { label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Date is after', value: FilterMatchMode.DATE_AFTER }
  ],
  timestamp: [
    { label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Date is after', value: FilterMatchMode.DATE_AFTER }
  ],
  json: [
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS },
  ],
  boolean: [
    { label: 'Is', value: FilterMatchMode.IS },
  ],
  array: [
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Any', value: FilterMatchMode.IN }
  ],
  default: [
    { label: 'Equals', value: FilterMatchMode.EQUALS},
    { label: 'Not equals', value: FilterMatchMode.NOT_EQUALS}
  ]
};



export interface IPrepareTableQueryParams {
  pageSize: number;
  search?: string;
}

export class TableQuery {

  constructor() {
  }

  getMatchModeOptions(matchModeType: TMatchModeOptionsType, nullable = false): Array<SelectItem> {
    const options = [
      ...(nullable ? MATCH_MODE_OPTIONS['null'] : []),
      ...MATCH_MODE_OPTIONS[matchModeType],
    ];
    return options;
  }

  prepareQuery(event: LazyLoadEvent, params: IPrepareTableQueryParams): ISearchRequest {
    console.log(event, params);
    const result: ISearchRequest = {
      offset: event.first ?? 0,
      limit: event.rows ?? params.pageSize,
      search: params.search || undefined
    }
    if (event.multiSortMeta?.length) {
      result.sort = event.multiSortMeta.map<ISort>((ms) => ({
        field: ms.field,
        dir: ms.order === 1 ? ESortDir.ASC : ESortDir.DESC
      }));
    } else if (!!event.sortField) {
      result.sort = [{
        field: event.sortField,
        dir: event.sortOrder === 1 ? ESortDir.ASC : ESortDir.DESC
      }]
    }

    const query: TQuery = {
      logical: 'and',
      predicates: []
    };
    if (event.filters) {
      console.log('filters', event.filters);
      keys(event.filters).forEach((f) => {
        const field: string = f;
        const filters: Array<FilterMetadata> = (isArray(event.filters![field]) ? event.filters![field] : [event.filters![field]]) as Array<FilterMetadata>;
        const filterQuery: TQuery = {
          logical: 'and',
          predicates: []
        }
        filters.forEach((filter) => {
          if (!(
            filter.value == null
            || (isArray(filter.value) && !(filter.value as Array<any>).length)
          )) {
            filterQuery.logical = filter.operator as TQueryLogical || 'and';
            filterQuery.predicates.push({
              field: field,
              operator: FILTER_TO_OPERATOR_MAP[filter.matchMode!],
              value: filter.value
            })
          }
        });
        if (filterQuery.predicates.length) {
          query.predicates.push(filterQuery);
        }
      });
      if (query.predicates.length) {
        result.query = query;
      }
    }
    console.log('query', result);
    return result;
  }
}
