import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {finalize} from 'rxjs';
import {cloneDeep, filter, findIndex, isEqual, keyBy, mapValues, omit, remove, sortBy} from 'lodash';
import {AppResourceService} from '../../../app.resource.service';
import {EHsPropertyFieldType, EHsPropertyType, IHsObjectType, IHsPropertyOption} from '../../../api/shared/dev-tools/hs-api';
import {ConfirmationService, LazyLoadEvent, MessageService, SortMeta} from 'primeng/api';
import {TableQuery} from '../../../common/util/table-query';
import {Table} from 'primeng/table';
import {IDictionary} from 'src/app/common/types';
import {HsService} from '../../../services/hs/hs.service';


interface IObjectRow {
  hs_object_id: string;

  [key: string]: any;
}

interface IPropertyDefMap {
  [prop: string]: {
    type: EHsPropertyType;
    fieldType: EHsPropertyFieldType;
    isCurrency: boolean;
    options: Array<IHsPropertyOption>;
    optionsMap: { [val: string]: string };
  }
}

const NEW_RECORD_ID = (-Number.MAX_VALUE).toString();

@Component({
  selector: 'app-hs-object-table',
  template: `
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container class="shadow-1">
      <p-table
        [columns]="objectType.properties"
        [value]="data"
        [(first)]="firstPage"
        responsiveLayout="scroll"
        [scrollable]="true"
        scrollDirection="horizontal"
        [(selection)]="selectedObjects" dataKey="hs_object_id" [selectionPageOnly]="true"
        [lazy]="true"
        (onLazyLoad)="load($any($event))"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [lazyLoadOnInit]="false"
        [rows]="10"
        [totalRecords]="total"
        editMode="row"
        [editingRowKeys]="editingRowKeys"
        sortMode="single"
        [multiSortMeta]="multiSortMeta">
        <ng-template pTemplate="caption">
          <div class="flex">
            <button pButton pRipple type="button" icon="pi pi-plus"
                    class="p-button-rounded p-button-text ml-2"
                    pTooltip="Add Object" tooltipPosition="bottom"
                    (click)="add()" [disabled]="!canAdd()">
            </button>
            <button pButton pRipple type="button" icon="pi pi-refresh"
                    class="p-button-rounded p-button-text"
                    pTooltip="Reload" tooltipPosition="bottom"
                    (click)="load()">
            </button>
<!--            <button pButton pRipple type="button" icon="pi pi-filter-slash"-->
<!--                    class="p-button-rounded p-button-text"-->
<!--                    pTooltip="Clear filters" tooltipPosition="bottom"-->
<!--                    (click)="table.clear()">-->
<!--            </button>-->
            <span class="p-input-icon-left ml-auto">
               <i class="pi pi-search"></i>
               <input pInputText type="text" placeholder="Search" [(ngModel)]="search"
                      appChangesDebounce (debounced)="load()"/>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="min-width: 160px" pFrozenColumn class="mt-last-left-frozen-column">
              <p-tableHeaderCheckbox class="ml-6"></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns" style="width: 250px; position: relative" [pSortableColumn]="col.name">
              <i *ngIf="col.isSearchable"
                 class="pi pi-search text-gray-600 text-xs absolute cursor-auto"
                 style="left: .2rem; top: .2rem" pTooltip="Searchable Field" tooltipPosition="top"></i>
              <div class="flex justify-content-between align-content-center w-full">
                <span class="align-self-center mt-overflow-ellipsis" appTooltipOnOverflow style="width: 210px">
                  {{col.label}}
                </span>
                <p-sortIcon class="align-self-center" [field]="col.name"></p-sortIcon>
                <ng-container *ngIf="propertyDefMap[col.name].type !== 'enumeration'; else enumeration">
                  <ng-container [ngSwitch]="propertyDefMap[col.name].type">
                    <ng-container *ngSwitchCase="'date'">
                      <p-columnFilter type="date" [field]="col.name" display="menu" class="ml-auto" [showOperator]="false">
                      </p-columnFilter>
                    </ng-container>
                    <ng-container *ngSwitchCase="'datetime'">
                      <p-columnFilter type="date" [field]="col.name" display="menu" class="ml-auto" [showOperator]="false"
                                      [matchModeOptions]="tableQuery.getMatchModeOptions('timestamp')">
                      </p-columnFilter>
                    </ng-container>
                    <ng-container *ngSwitchCase="'number'">
                      <p-columnFilter type="numeric" [field]="col.name" display="menu" class="ml-auto" [showOperator]="false">
                      </p-columnFilter>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <p-columnFilter type="text" [field]="col.name" display="menu" class="ml-auto"
                                        [showOperator]="false"
                                        [matchModeOptions]="tableQuery.getMatchModeOptions('strongText')">
                        </p-columnFilter>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #enumeration>
                  <ng-container [ngSwitch]="propertyDefMap[col.name].fieldType">
                    <ng-container *ngSwitchCase="'booleancheckbox'">
                      <p-columnFilter type="boolean" matchMode="is"
                                      [field]="col.name" display="menu"></p-columnFilter>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <p-columnFilter [field]="col.name" matchMode="in" display="menu"
                                      [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <p-multiSelect [ngModel]="value" [options]="propertyDefMap[col.name].options"
                                         placeholder="Any"
                                         selectedItemsLabel="{0} items selected"
                                         optionLabel="label" optionValue="value"
                                         (onChange)="filter($event.value)">
                            <ng-template let-option pTemplate="item">
                              <span>{{option.label}}</span>
                            </ng-template>
                          </p-multiSelect>
                        </ng-template>
                      </p-columnFilter>
                    </ng-container>
                  </ng-container>
                </ng-template>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body"
                     let-rowData let-columns="columns" let-editing="editing" let-expanded="expanded" let-ri="rowIndex">
          <tr [pEditableRow]="rowData">
            <td style="min-width: 160px; z-index: 2" pFrozenColumn class="mt-last-left-frozen-column flex align-items-center">
              <button type="button" pButton pRipple [pRowToggler]="rowData"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
              </button>
              <p-tableCheckbox class="ml-1" [disabled]="isNewRow(rowData)" [value]="rowData"></p-tableCheckbox>
              <ng-container *ngIf="!editing">
                <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                        (click)="edit(rowData)" class="p-button-rounded p-button-text ml-2"></button>
                <button pButton pRipple type="button" pInitEditableRow icon="pi pi-trash"
                        (click)="remove($event, rowData, ri)" class="p-button-rounded p-button-text"></button>
              </ng-container>
              <ng-container *ngIf="editing">
                <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                        [disabled]="!canSave(rowData)"
                        (click)="save(rowData, ri)"
                        class="p-button-rounded p-button-text p-button-success ml-2"></button>
                <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                        (click)="cancelEdit(rowData, ri)"
                        class="p-button-rounded p-button-text p-button-danger"></button>
              </ng-container>
              <div *ngIf="isNewRow(rowData)"
                   style="position: absolute; top: 0; left: .4em; font-size: 12px" class="text-gray-400">
                new
              </div>
            </td>
            <td *ngFor="let col of columns" style="width: 250px; min-height: 69px;">
              <p-cellEditor class="mt-overflow-ellipsis w-full relative">
                <ng-template pTemplate="input">
                  <div class="p-fluid">
                    <ng-container *ngIf="propertyDefMap[col.name].type !== 'enumeration'; else enumeration">
                      <ng-container [ngSwitch]="propertyDefMap[col.name].type">
                        <ng-container *ngSwitchCase="'date'">
                          <p-calendar [(ngModel)]="rowData[col.name]" [showIcon]="true"
                                      [showButtonBar]="true" appendTo="body"></p-calendar>
                        </ng-container>
                        <ng-container *ngSwitchCase="'datetime'">
                          <p-calendar [(ngModel)]="rowData[col.name]" [showTime]="true" [showIcon]="true"
                                      [showSeconds]="true" [showButtonBar]="true" appendTo="body"></p-calendar>
                        </ng-container>
                        <ng-container *ngSwitchCase="'number'">
                          <ng-container *ngIf="!propertyDefMap[col.name].isCurrency; else currency">
                            <p-inputNumber [(ngModel)]="rowData[col.name]" mode="decimal" locale="en-US"
                                           [minFractionDigits]="0" [maxFractionDigits]="20"
                                           [showButtons]="true"></p-inputNumber>
                          </ng-container>
                          <ng-template #currency>
                            <p-inputNumber [(ngModel)]="rowData[col.name]" mode="currency" currency="USD"
                                           locale="en-US" [showButtons]="true"></p-inputNumber>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <ng-container *ngIf="propertyDefMap[col.name].fieldType==='textarea'; else text">
                            <span class="p-input-icon-right">
                                <i class="pi pi-times cursor-pointer"
                                   (click)="textAreaModel.control.setValue(null)"></i>
                                <textarea pInputTextarea #textAreaModel="ngModel" [(ngModel)]="rowData[col.name]"
                                          style="resize: vertical;">
                                </textarea>
                            </span>
                          </ng-container>
                          <ng-template #text>
                            <span class="p-input-icon-right">
                              <i class="pi pi-times cursor-pointer" (click)="textModel.control.setValue(null)"></i>
                              <input #textModel="ngModel" [(ngModel)]="rowData[col.name]"
                                     [class.ng-invalid]="col.isRequired && !rowData[col.name]"
                                     [class.ng-dirty]="col.isRequired && !rowData[col.name]"
                                     type="text" pInputText nullable/>
                            </span>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-template #enumeration>
                      <ng-container [ngSwitch]="propertyDefMap[col.name].fieldType">
                        <ng-container *ngSwitchCase="'checkbox'">
                          <p-multiSelect [options]="propertyDefMap[col.name].options"
                                         [(ngModel)]="rowData[col.name]"
                                         optionLabel="label" optionValue="value"
                                         appendTo="body"></p-multiSelect>
                        </ng-container>
                        <ng-container *ngSwitchCase="'booleancheckbox'">
                          <p-checkbox [(ngModel)]="rowData[col.name]"
                                      [binary]="true"
                                      [trueValue]="'true'" [falseValue]="'false'"></p-checkbox>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <p-dropdown [options]="propertyDefMap[col.name].options"
                                      [(ngModel)]="rowData[col.name]"
                                      optionLabel="label" optionValue="value"
                                      appendTo="body" [autoDisplayFirst]="false"
                                      [filter]="propertyDefMap[col.name].options.length > 10" filterBy="label"
                                      [showClear]="true">
                          </p-dropdown>
                        </ng-container>
                      </ng-container>
                    </ng-template>
                  </div>
                </ng-template>
                <ng-template pTemplate="output">
                  <ng-container *ngIf="rowData[col.name] != null; else nullValue">
                    <span appTooltipOnOverflow [useParent]="true">
                      <ng-container *ngIf="propertyDefMap[col.name].type !== 'enumeration'; else enumeration">
                        <ng-container [ngSwitch]="propertyDefMap[col.name].type">
                          <ng-container *ngSwitchCase="'date'">
                            {{rowData[col.name] | date: 'MM/dd/yy'}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'datetime'">
                            {{rowData[col.name] | date: 'MM/dd/yy hh:mm:ss a'}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'number'">
                            <ng-container *ngIf="!propertyDefMap[col.name].isCurrency; else currency">
                               {{rowData[col.name]}}
                            </ng-container>
                            <ng-template #currency>
                               {{rowData[col.name] | currency:'USD':'symbol':'1.0-0'}}
                           </ng-template>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                             {{rowData[col.name]}}
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <ng-template #enumeration>
                        <ng-container [ngSwitch]="propertyDefMap[col.name].fieldType">
                          <ng-container *ngSwitchCase="'checkbox'">
                            {{rowData[col.name]}}
                          </ng-container>
                          <ng-container *ngSwitchCase="'booleancheckbox'">
                            <i class="pi"
                               [class.pi-check-circle]="rowData[col.name]==='true'"
                               [class.pi-times-circle]="rowData[col.name]==='false'"></i>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                             {{propertyDefMap[col.name].optionsMap[rowData[col.name]]}}
                          </ng-container>
                        </ng-container>
                    </ng-template>
                  </span>
                  </ng-container>
                  <ng-template #nullValue>
                    <div class="border-bottom-1 text-gray-400" style="width: 25px"></div>
                  </ng-template>
                </ng-template>
              </p-cellEditor>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData let-columns>
          <tr>
            <td [attr.colspan]="columns.length + 1" class="bg-gray-100">
              <div class="p-5">
                TBD: Associated Objects will be here
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `
})
export class HsObjectTableComponent implements OnChanges {
  @Input() objectType!: IHsObjectType;
  data: Array<IObjectRow> = [];
  loading = false;
  selectedObjects!: Array<IObjectRow>;
  total = 0;
  firstPage = 0;
  @ViewChild(Table, {static: true}) table!: Table;
  editingRowKeys: IDictionary<boolean> = {};
  private editCache: IDictionary<IObjectRow> = {};
  multiSortMeta: SortMeta[] = [];
  propertyDefMap: IPropertyDefMap = {};
  private requiredProperties: Array<string> = [];
  search!: string;
  tableQuery: TableQuery = new TableQuery();

  constructor(private resource: AppResourceService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['objectType']) {
      if (changes['objectType'].currentValue) {
        this.onChangeObjectType();
      }
    }
  }

  private onChangeObjectType(): void {

    const propertyDefMap: IPropertyDefMap = {}
    this.objectType.properties.forEach((prop) => {
      propertyDefMap[prop.name] = {
        type: prop.type,
        fieldType: prop.fieldType,
        options: sortBy(prop.options || [], (o) => o.displayOrder ?? -1),
        optionsMap: mapValues(keyBy(prop.options, 'value'), 'label'),
        isCurrency: !!prop.showCurrencySymbol
      }
    });
    this.propertyDefMap = propertyDefMap;

    this.requiredProperties = filter(this.objectType.properties, {isRequired: true})
      .map((p) => p.name);

    this.firstPage = 0;
    this.multiSortMeta = [];
    this.editingRowKeys = {};
    this.editCache = {};
    this.selectedObjects = [];
    (this.table.sortField as any) = null;
    this.table.filters = {};
    this.load();
  }

  load(event?: LazyLoadEvent): void {
    setTimeout(() => this.loading = true);
    const query = this.tableQuery.prepareQuery(
      event || this.table.createLazyLoadMetadata(), {
        pageSize: 10,
        search: this.search
      }
    );
    this.resource.hsSearchObjects(this.objectType.objectTypeId,
      query
    ).pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((response) => {
      this.data = response.results.map((r) => {
        return r.properties;
      }) as Array<IObjectRow>;
      this.total = response.total;
      this.editCache = {};
      this.editingRowKeys = {};
    });
  }

  private indexInPage(index: number): number {
    return index % 10 // page size;
  }

  isNewRow(obj: IObjectRow): boolean {
    return obj.hs_object_id === NEW_RECORD_ID;
  }

  edit(obj: IObjectRow): void {
    this.editCache[obj.hs_object_id] = cloneDeep(obj);
  }

  canSave(obj: IObjectRow): boolean {
    for (const prop of this.requiredProperties) {
      if (obj[prop] == null || obj[prop] === '') {
        return false;
      }
    }
    return !isEqual(obj, this.editCache[obj.hs_object_id]);
  }

  save(obj: IObjectRow, index: number): void {
    if (this.canSave(obj)) {
      index = this.indexInPage(index);
      const isNew = this.isNewRow(obj);
      const bodyObj: IDictionary = omit(cloneDeep(obj), ['hs_object_id']);
      this.loading = true;
      (
        !isNew
          ? this.resource.hsUpdateObject(this.objectType.objectTypeId, obj.hs_object_id, {
            properties: bodyObj
          }) : this.resource.hsCreateObject(this.objectType.objectTypeId, {
            properties: bodyObj
          })
      ).pipe(
        finalize(() => this.loading = false)
      ).subscribe((response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Object has been ${isNew ? 'created' : 'updated'}`
        });
        delete this.editCache[obj.hs_object_id];
        this.data[index] = obj = response.properties as IObjectRow;
      });
    }
  }

  cancelEdit(obj: IObjectRow, index: number): void {
    index = this.indexInPage(index);
    this.data[index] = this.editCache[obj.hs_object_id];
    delete this.editCache[obj.hs_object_id];
    if (this.isNewRow(obj)) {
      delete this.editingRowKeys[obj.hs_object_id];
      remove(this.data, {hs_object_id: NEW_RECORD_ID});
    }
  }

  canAdd(): boolean {
    return findIndex(this.data, {hs_object_id: NEW_RECORD_ID}) === -1;
  }

  add(): void {
    if (this.canAdd()) {
      this.data.unshift({
        hs_object_id: NEW_RECORD_ID
      } as IObjectRow);
      this.editingRowKeys[NEW_RECORD_ID] = true;
      this.edit(this.data[0]);
    }
  }

  remove(ev: any, obj: IObjectRow, index: number): void {
    index = this.indexInPage(index);
    delete this.editingRowKeys[obj.hs_object_id];
    const onRemove = () => {
      this.data.splice(index, 1);
      delete this.editCache[obj.hs_object_id];
    };
    this.confirmationService.confirm({
      target: ev.target,
      message: 'Are you sure that you want to delete object?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.resource.hsDeleteObject(this.objectType.objectTypeId, obj.hs_object_id)
          .subscribe(() => {
            onRemove();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Object has been deleted`
            });
          });
      }
    });
  }
}


@Component({
  selector: 'app-hs-object-browser',
  template: `
    <app-spinnerizer [active]="loading"
                     [invertSpinner]="false"
                     [invertText]="true"
                     text="Loading schemas..."
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <div class="text-2xl mb-2">
        Object Browser
      </div>
      <div style="width: 30%">
        <p-dropdown [options]="objectTypes"
                    class="w-full"
                    styleClass="w-full"
                    [(ngModel)]="selectedType"
                    dataKey="objectTypeId"
                    optionLabel="label"
                    [filter]="true"
                    filterBy="label"
                    placeholder="Select Object Type"
                    (ngModelChange)="onChangeObjectType()">
          <ng-template pTemplate="selectedItem">
            <div
              [class.custom-object-type]="selectedType?.isCustom">
              {{selectedType?.label}} <span class="text-xs">({{selectedType?.objectTypeId}})</span>
            </div>
          </ng-template>
          <ng-template let-type pTemplate="item">
            <span [class.custom-object-type]="type.isCustom">{{type.label}}</span>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div *ngIf="selectedType" class="mt-3">
      <app-hs-object-table [objectType]="selectedType"></app-hs-object-table>
    </div>
  `,
  styles: [`
    .custom-object-type {
      color: var(--primary-color);
    }
  `]
})
export class HsObjectBrowserComponent implements OnInit {
  loading = false;
  objectTypes!: Array<IHsObjectType>;
  selectedType: IHsObjectType | null = null;

  constructor(private hsService: HsService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.hsService.getObjectTypes()
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((types) => {
        this.objectTypes = sortBy(filter(types, (ot) => ot.objectTypeId !== '0-51' && ot.properties.length > 0),
          (ot) => ot.label.toLowerCase());
      });
  }

  onChangeObjectType(): void {
  }
}
