
export const HS_API_ENDPOINTS = {
  objectTypes: '/api/hs/object-types',
  objectType: '/api/hs/object-types/:objectTypeId',
  objectTypeAssociations: '/api/hs/object-types/:objectTypeId/associations',
  objectTypeAssociation: '/api/hs/object-types/:fromObjectTypeId/associations/:toObjectTypeId',
  associations: '/api/hs/associations',
  searchObjects: '/api/hs/objects/:objectTypeId/search',
  objects: '/api/hs/objects/:objectTypeId',
  object: '/api/hs/objects/:objectTypeId/:objectId',
};

export enum EHsPropertyType {
  // A string representing a set of options, separated by semicolons
  // Field Type: booleancheckbox, checkbox, radio, select
  enumeration = 'enumeration',
  // An ISO 8601 formatted value representing a specific day, month, and year.
  // Field Type: date
  date = 'date',
  // An ISO 8601 formatted value representing a specific day, month, year and time of day. The HubSpot app will not display the time of day.
  // Field Type: date
  dateTime = 'datetime',
  // A plain text strings, limited to 65,536 characters.
  // Field Type: file, text, textarea
  string = 'string',
  // A number value containing numeric digits and at most one decimal.
  // Field Type: number
  number = 'number'
}

export enum EHsPropertyFieldType {
  // An input that will allow users to select one of either Yes or No.
  // When used in a form, it will be displayed as a single checkbox.
  booleanCheckbox = 'booleancheckbox',
  // A list of checkboxes that will allow a user to select multiple options from a set of options allowed for the property.
  checkbox = 'checkbox',
  // A date value, displayed as a date picker.
  date = 'date',
  // Allows for a file to be uploaded to a form. Stored and displayed as a URL link to the file.
  file = 'file',
  // A string of numerals or numbers written in decimal or scientific notation.
  number = 'number',
  // An input that will allow users to select one of a set of options allowed for the property.
  // When used in a form, this will be displayed as a set of radio buttons.
  radio = 'radio',
  // A dropdown input that will allow users to select one of a set of options allowed for the property.
  select = 'select',
  // A plain text string, displayed in a single line text input.
  text = 'text',
  // A plain text string, displayed as a multi-line text input.
  textArea = 'textarea'
}

export interface IHsPropertyOption {
  label: string;
  value: string;
  description?: string;
  displayOrder?: number;
}

export interface IHsProperty {
  name: string;
  label: string;
  type: EHsPropertyType;
  fieldType: EHsPropertyFieldType;
  description?: string;
  showCurrencySymbol?: boolean;
  displayOrder?: number;
  options: Array<IHsPropertyOption>;
  isRequired?: boolean;
  isSearchable?: boolean;
}

export interface IHsObjectType {
  name: string;
  label: string;
  objectTypeId: string;
  isCustom: boolean;
  properties: Array<IHsProperty>;
}

export enum EHsRelationship {
  o2m = 'o2m',
  m2o = 'm2o',
  m2m = 'm2m'
}

export interface IHsAssociation {
  name?: string;
  fromObjectTypeId: string;
  toObjectTypeId: string;
  relationship: EHsRelationship;
}

export type THsObjectPropertyType = string | number | Array<string>;

export interface IHsObjectProperties {
  [key: string]: THsObjectPropertyType;
}

export interface IHsObjectData {
  properties: IHsObjectProperties;
}

export interface IHsObject extends IHsObjectData {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  archived?: boolean;
  archivedAt?: Date;
}
