import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ENDPOINTS, IUserProfileRecord,} from './api/api';
import {Injectable} from '@angular/core';
import {EndpointUrl} from './common/util/http-converters';
import {HS_API_ENDPOINTS, IHsAssociation, IHsObject, IHsObjectData, IHsObjectType} from './api/shared/dev-tools/hs-api';
import {DB_API_ENDPOINTS, IDbRelationship, IDbSchema, IDbSchemaInconsistencies} from './api/shared/dev-tools/db-api';
import {IDeletePayload, IDownloadRefResponse, ISearchRequest, ISearchResponse, TWithId} from './api/shared/search-api';
import {IDictionary} from './common/types';
import {
  IProject,
  IProjectAgency,
  IProjectBase,
  IProjectClient,
  IProjectTeamMember,
  PROJECT_API_ENDPOINTS,
  TAllAgenciesDetails,
  TAllClientsDetails,
  TAllProjectsDetails,
  TProjectEntryEntity,
  TProjectScope,
  TSingleAgencyDetails,
  TSingleClientDetails,
  TSingleProjectDetails
} from './api/shared/app-domain/project';
import {IUserRole, IViewAs, USERS_API_ENDPOINTS} from './api/shared/user-api';
import {
  ITeamMember,
  ITeamMemberTalentPartner,
  TEAM_API_ENDPOINTS,
  TSingleTeamMemberDetails,
  TTeamMemberSave
} from './api/shared/app-domain/team';
import {ITeamCandidate, TEAM_CANDIDATE_API_ENDPOINTS, TTeamCandidateSave} from './api/shared/app-domain/team-candidate';
import {COMPANY_API_ENDPOINT, ICompany, TCompanySave} from './api/shared/app-domain/company';
import {
  IDepartment,
  IExpenseCategory,
  IPaidTimeOff,
  IRole,
  ISearchSource,
  IWorkweek,
  SETTINGS_API_ENDPOINTS,
  TDepartmentSave,
  TExpenseCategorySave,
  TPaidTimeOffSave,
  TRoleSave,
  TWorkweekSave
} from './api/shared/app-domain/settings';
import {downloadRef, ServiceLocator} from './common/util/util';
import {IReferral, REFERRAL_API_ENDPOINTS, TReferralSave} from './api/shared/app-domain/referral';
import {HUBSPOT_API_ENDPOINTS, IHubspotCandidate, IHubspotContact} from './api/shared/app-domain/hubspot';
import {CONTACT_API_ENDPOINT, IContact, TContactSave} from './api/shared/app-domain/contact';
import {ISkill, SKILL_API_ENDPOINTS, TSkillSave} from './api/shared/app-domain/skill';
import {COMMON_API_ENDPOINTS, ICountry, IIdentified, IPeriodRequest} from './api/shared/app-domain/common';
import {
  HOLIDAY_CALENDAR_API_ENDPOINTS,
  IHolidayCalendar,
  IHolidaysByDate,
  THolidayCalendarSave
} from './api/shared/app-domain/schedule';
import {
  ITimeEntryRow,
  ITimeOff,
  ITimeSheetsValueData,
  TDayTimeEntriesRequest,
  TIME_API_ENDPOINTS,
  TIME_OFF_API_ENDPOINTS,
  TTimeApprovalsRequest,
  TTimeApprovalsTeamMember,
  TTimeOffApprovalsTeamMember,
  TTimeOffSave,
  TTimeSheetsDetails,
  TTimeSheetsDetailsRequest,
} from './api/shared/app-domain/time';
import {IDetailsCategoryRow, IPeriodRange} from './api/shared/app-domain/details';
import {EXPENSES_API_ENDPOINTS, IExpense, IExpenseReport, TExpenseSave} from './api/shared/app-domain/expenses';
import {map} from 'rxjs/operators';
import {filter} from 'lodash';


export interface IEntityResource<S extends {}, T extends S & IIdentified> {
  readonly importUrl: string;

  searchEntities(searchRequest: ISearchRequest): Observable<ISearchResponse<T>>;

  getCount(searchRequest: ISearchRequest): Observable<number>;

  getEntity(id: string): Observable<T>;

  createEntity(data: S): Observable<T>;

  updateEntity(id: string, data: S): Observable<T>;

  deleteEntities(ids: Array<string>): Observable<any>;

  patchEntities(data: Array<TWithId<S>>): Observable<Array<T>>;

  exportEntities(searchRequest: ISearchRequest): void;

  archiveEntities(entities: Array<TWithId<S>>, statusChangedReason?: string): Observable<Array<T>>;
}

export class EntityResource<S extends {}, T extends S & IIdentified> implements IEntityResource<S, T> {
  http: HttpClient;
  constructor(protected endpoint: string) {
    this.http = ServiceLocator.get<HttpClient>(HttpClient);
  }

  searchEntities(searchRequest: ISearchRequest): Observable<ISearchResponse<T>> {
    return this.http.post<ISearchResponse<T>>(`${this.endpoint}/search`, searchRequest);
  }

  getCount(searchRequest: ISearchRequest): Observable<number> {
    return this.searchEntities({...searchRequest, ...{offset: 0, limit: 0}})
      .pipe(
        map((response) => response.total)
      );
  }

  getEntity(id: string): Observable<T> {
    return this.http.get<T>(EndpointUrl.resolve(`${this.endpoint}/:id`, {id}));
  }

  createEntity(data: S): Observable<T> {
    return this.http.put<T>(this.endpoint, data);
  }

  updateEntity(id: string, data: S): Observable<T> {
    return this.http.post<T>(EndpointUrl.resolve(`${this.endpoint}/:id`, {id}), data);
  }

  deleteEntities(ids: Array<string>): Observable<any> {
    return this.http.delete(this.endpoint, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchEntities(data: Array<TWithId<S>>): Observable<Array<T>> {
    return this.http.patch<Array<T>>(this.endpoint, data)
  }

  exportEntities(searchRequest: ISearchRequest): void {
    downloadRef(this.http.post<IDownloadRefResponse>(`${this.endpoint}/export`, searchRequest));
  }

  get importUrl(): string {
    return `${this.endpoint}/import`;
  }

  archiveEntities(entities: Array<TWithId<S>>, statusChangedReason?: string): Observable<Array<T>> {
    const data: Array<TWithId<S>> = filter<any>(entities, (e) => e.status !== 'Archived').map((e) => ({
      id: e.id,
      status: 'Archived',
      statusChangedReason
    } as TWithId<any>));
    return this.patchEntities(data);
  }
}


@Injectable({
  providedIn: 'root'
})
export class AppResourceService {

  contacts = new class Contacts extends EntityResource<TContactSave, IContact> {}(CONTACT_API_ENDPOINT);
  companies = new class Companies extends EntityResource<TCompanySave, ICompany> {}(COMPANY_API_ENDPOINT);

  constructor(public http: HttpClient) {
  }

  getCurrentUserProfile(): Observable<IUserProfileRecord> {
    return this.http.get<IUserProfileRecord>(ENDPOINTS.currentUserProfile);
  }

  getViewAs(): Observable<IViewAs> {
    return this.http.get<IViewAs>(USERS_API_ENDPOINTS.viewAs);
  }

  getUserRoles(): Observable<Array<IUserRole>> {
    return this.http.get<Array<IUserRole>>(USERS_API_ENDPOINTS.userRoles);
  }

  /*
   * HubSpot tools
   */
  hsGetObjectTypes(): Observable<Array<IHsObjectType>> {
    return this.http.get<Array<IHsObjectType>>(HS_API_ENDPOINTS.objectTypes);
  }

  hsGetObjectTypeAssociations(objectTypeId: string): Observable<Array<IHsAssociation>> {
    return this.http.get<Array<IHsAssociation>>(
      EndpointUrl.resolve(HS_API_ENDPOINTS.objectTypeAssociations, {objectTypeId})
    );
  }

  hsCreateOrUpdateObjectTypeAssociation(objectTypeId: string, payload: IHsAssociation): Observable<any> {
    return this.http.post<any>(
      EndpointUrl.resolve(HS_API_ENDPOINTS.objectTypeAssociations, {objectTypeId}),
      payload
    );
  }

  hsRemoveObjectTypeAssociation(fromObjectTypeId: string, toObjectTypeId: string): Observable<any> {
    return this.http.delete<any>(
      EndpointUrl.resolve(HS_API_ENDPOINTS.objectTypeAssociation, {fromObjectTypeId, toObjectTypeId}),
    );
  }

  hsGetAssociations(): Observable<Array<IHsAssociation>> {
    return this.http.get<Array<IHsAssociation>>(HS_API_ENDPOINTS.associations);
  }

  hsSearchObjects(objectTypeId: string, searchRequest: ISearchRequest): Observable<ISearchResponse<IHsObject>> {
    return this.http.post<ISearchResponse<IHsObject>>(
      EndpointUrl.resolve(HS_API_ENDPOINTS.searchObjects, {objectTypeId}),
      searchRequest);
  }

  hsUpdateObject(objectTypeId: string, objectId: string, objectInput: IHsObjectData): Observable<IHsObject> {
    return this.http.patch<IHsObject>(
      EndpointUrl.resolve(HS_API_ENDPOINTS.object, {objectTypeId, objectId}),
      objectInput);
  }

  hsCreateObject(objectTypeId: string, objectInput: IHsObjectData): Observable<IHsObject> {
    return this.http.post<IHsObject>(
      EndpointUrl.resolve(HS_API_ENDPOINTS.objects, {objectTypeId}),
      objectInput);
  }

  hsDeleteObject(objectTypeId: string, objectId: string): Observable<any> {
    return this.http.delete<any>(
      EndpointUrl.resolve(HS_API_ENDPOINTS.object, {objectTypeId, objectId}));
  }

  /*
   * Database tools
   */

  dbGetSchema(): Observable<IDbSchema> {
    return this.http.get<IDbSchema>(DB_API_ENDPOINTS.schema);
  }

  dbGetRelationships(): Observable<Array<IDbRelationship>> {
    return this.http.get<Array<IDbRelationship>>(DB_API_ENDPOINTS.relationships);
  }

  dbCheckSchema(): Observable<IDbSchemaInconsistencies> {
    return this.http.get<IDbSchemaInconsistencies>(DB_API_ENDPOINTS.checkSchema);
  }

  dbSearch(tableName: string, searchRequest: ISearchRequest): Observable<ISearchResponse<any>> {
    return this.http.post<ISearchResponse<any>>(
      EndpointUrl.resolve(DB_API_ENDPOINTS.search, {tableName}),
      searchRequest
    );
  }

  dbUpdateTableRow(tableName: string, rowData: IDictionary): Observable<IDictionary> {
    return this.http.patch<IDictionary>(EndpointUrl.resolve(DB_API_ENDPOINTS.tableCrud, {tableName}), rowData);
  }

  dbCreateTableRow(tableName: string, rowData: IDictionary): Observable<IDictionary> {
    return this.http.post<IDictionary>(EndpointUrl.resolve(DB_API_ENDPOINTS.tableCrud, {tableName}), rowData);
  }

  dbDeleteTableRow(tableName: string, rowData: IDictionary): Observable<IDictionary> {
    return this.http.put<IDictionary>(EndpointUrl.resolve(DB_API_ENDPOINTS.tableCrud, {tableName}), rowData);
  }

  /*
   * Departments
   */
  getDepartments(searchRequest: ISearchRequest): Observable<ISearchResponse<IDepartment>> {
    return this.http.post<ISearchResponse<IDepartment>>(SETTINGS_API_ENDPOINTS.departments, searchRequest);
  }

  getDepartment(id: string): Observable<IDepartment> {
    return this.http.get<IDepartment>(EndpointUrl.resolve(SETTINGS_API_ENDPOINTS.department, {id}));
  }

  createDepartment(data: TDepartmentSave): Observable<IDepartment> {
    return this.http.put<IDepartment>(SETTINGS_API_ENDPOINTS.departments, data);
  }

  updateDepartment(id: string, data: TDepartmentSave): Observable<IDepartment> {
    return this.http.post<IDepartment>(EndpointUrl.resolve(SETTINGS_API_ENDPOINTS.department, {id}), data);
  }


  deleteDepartments(ids: Array<string>): Observable<any> {
    return this.http.delete(SETTINGS_API_ENDPOINTS.departments, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchDepartments(data: Array<TWithId<TDepartmentSave>>): Observable<Array<IDepartment>> {
    return this.http.patch<Array<IDepartment>>(SETTINGS_API_ENDPOINTS.departments, data)
  }

  /*
   * Expense Categories
   */

  getExpenseCategories(searchRequest: ISearchRequest): Observable<ISearchResponse<IExpenseCategory>> {
    return this.http.post<ISearchResponse<IExpenseCategory>>(SETTINGS_API_ENDPOINTS.expenseCategories, searchRequest);
  }

  getExpenseCategory(id: string): Observable<IExpenseCategory> {
    return this.http.get<IExpenseCategory>(EndpointUrl.resolve(SETTINGS_API_ENDPOINTS.expenseCategory, {id}));
  }

  createExpenseCategory(data: TExpenseCategorySave): Observable<IExpenseCategory> {
    return this.http.put<IExpenseCategory>(SETTINGS_API_ENDPOINTS.expenseCategories, data);
  }

  updateExpenseCategory(id: string, data: TExpenseCategorySave): Observable<IExpenseCategory> {
    return this.http.post<IExpenseCategory>(EndpointUrl.resolve(SETTINGS_API_ENDPOINTS.expenseCategory, {id}), data);
  }


  deleteExpenseCategories(ids: Array<string>): Observable<any> {
    return this.http.delete(SETTINGS_API_ENDPOINTS.expenseCategories, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchExpenseCategories(data: Array<TWithId<TExpenseCategorySave>>): Observable<Array<IExpenseCategory>> {
    return this.http.patch<Array<IExpenseCategory>>(SETTINGS_API_ENDPOINTS.expenseCategories, data)
  }

  /*
   * Roles
   */

  getRoles(searchRequest: ISearchRequest): Observable<ISearchResponse<IRole>> {
    return this.http.post<ISearchResponse<IDepartment>>(SETTINGS_API_ENDPOINTS.roles, searchRequest);
  }

  getRole(id: string): Observable<IRole> {
    return this.http.get<IRole>(EndpointUrl.resolve(SETTINGS_API_ENDPOINTS.role, {id}));
  }

  createRole(data: TRoleSave): Observable<IRole> {
    return this.http.put<IRole>(SETTINGS_API_ENDPOINTS.roles, data);
  }

  updateRole(id: string, data: TRoleSave): Observable<IRole> {
    return this.http.post<IRole>(EndpointUrl.resolve(SETTINGS_API_ENDPOINTS.role, {id}), data);
  }


  deleteRoles(ids: Array<string>): Observable<any> {
    return this.http.delete(SETTINGS_API_ENDPOINTS.roles, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchRoles(data: Array<TWithId<TRoleSave>>): Observable<Array<IRole>> {
    return this.http.patch<Array<IRole>>(SETTINGS_API_ENDPOINTS.roles, data)
  }

  /*
   * Settings
   */

  getCountries(): Observable<Array<ICountry>> {
    return this.http.get<Array<ICountry>>(COMMON_API_ENDPOINTS.countries);
  }

  getTimeZones(): Observable<Array<string>> {
    return this.http.get<Array<string>>(COMMON_API_ENDPOINTS.timeZones);
  }

  getSearchSources(): Observable<Array<ISearchSource>> {
    return this.http.get<Array<ISearchSource>>(SETTINGS_API_ENDPOINTS.searchSources);
  }

  searchWorkweeks(searchRequest: ISearchRequest): Observable<ISearchResponse<IWorkweek>> {
    return this.http.post<ISearchResponse<IWorkweek>>(SETTINGS_API_ENDPOINTS.searchWorkweeks, searchRequest);
  }

  updateWorkweeks(data: Array<TWorkweekSave>): Observable<Array<IWorkweek>> {
    return this.http.post<Array<IWorkweek>>(SETTINGS_API_ENDPOINTS.workweeks, data);
  }


  searchPaidTimeOffs(searchRequest: ISearchRequest): Observable<ISearchResponse<IPaidTimeOff>> {
    return this.http.post<ISearchResponse<IPaidTimeOff>>(SETTINGS_API_ENDPOINTS.searchPaidTimeOffs, searchRequest);
  }

  getPaidTimeOff(id: string): Observable<IPaidTimeOff> {
    return this.http.get<IPaidTimeOff>(EndpointUrl.resolve(SETTINGS_API_ENDPOINTS.paidTimeOff, {id}));
  }

  createPaidTimeOff(data: TPaidTimeOffSave): Observable<IPaidTimeOff> {
    return this.http.put<IPaidTimeOff>(SETTINGS_API_ENDPOINTS.paidTimeOffs, data);
  }

  updatePaidTimeOff(id: string, data: TPaidTimeOffSave): Observable<IPaidTimeOff> {
    return this.http.post<IPaidTimeOff>(EndpointUrl.resolve(SETTINGS_API_ENDPOINTS.paidTimeOff, {id}), data);
  }


  deletePaidTimeOffs(ids: Array<string>): Observable<any> {
    return this.http.delete(SETTINGS_API_ENDPOINTS.paidTimeOffs, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchPaidTimeOffs(data: Array<TWithId<TPaidTimeOffSave>>): Observable<Array<IPaidTimeOff>> {
    return this.http.patch<Array<IPaidTimeOff>>(SETTINGS_API_ENDPOINTS.paidTimeOffs, data)
  }
  /*
   * Schedule
   */

  getHolidayCalendars(searchRequest: ISearchRequest): Observable<ISearchResponse<IHolidayCalendar>> {
    return this.http.post<ISearchResponse<IHolidayCalendar>>(HOLIDAY_CALENDAR_API_ENDPOINTS.calendars, searchRequest);
  }

  getHolidayCalendar(id: string): Observable<IHolidayCalendar> {
    return this.http.get<IHolidayCalendar>(EndpointUrl.resolve(HOLIDAY_CALENDAR_API_ENDPOINTS.calendar, {id}));
  }

  createHolidayCalendar(data: THolidayCalendarSave): Observable<IHolidayCalendar> {
    return this.http.put<IHolidayCalendar>(HOLIDAY_CALENDAR_API_ENDPOINTS.calendars, data);
  }

  updateHolidayCalendar(id: string, data: THolidayCalendarSave): Observable<IHolidayCalendar> {
    return this.http.post<IHolidayCalendar>(EndpointUrl.resolve(HOLIDAY_CALENDAR_API_ENDPOINTS.calendar, {id}), data);
  }

  patchHolidayCalendar(data: Array<TWithId<THolidayCalendarSave>>): Observable<Array<IHolidayCalendar>> {
    return this.http.patch<Array<IHolidayCalendar>>(HOLIDAY_CALENDAR_API_ENDPOINTS.calendars, data)
  }

  deleteHolidayCalendar(ids: Array<string>): Observable<any> {
    return this.http.delete(HOLIDAY_CALENDAR_API_ENDPOINTS.calendars, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  getHolidaysUniverse(year: number): Observable<Array<IHolidaysByDate>> {
    return this.http.get<Array<IHolidaysByDate>>(EndpointUrl.resolve(HOLIDAY_CALENDAR_API_ENDPOINTS.holidaysUniverse, {year}));
  }
  /*
   * Skills
   */
  searchSkills(searchRequest: ISearchRequest): Observable<ISearchResponse<ISkill>> {
    return this.http.post<ISearchResponse<ISkill>>(SKILL_API_ENDPOINTS.searchSkills, searchRequest);
  }

  getSkill(id: string): Observable<ISkill> {
    return this.http.get<ISkill>(EndpointUrl.resolve(SKILL_API_ENDPOINTS.skill, {id}));
  }

  createSkill(data: TSkillSave): Observable<ISkill> {
    return this.http.put<ISkill>(SKILL_API_ENDPOINTS.skills, data);
  }

  updateSkill(id: string, data: TSkillSave): Observable<ISkill> {
    return this.http.post<ISkill>(EndpointUrl.resolve(SKILL_API_ENDPOINTS.skill, {id}), data);
  }


  deleteSkills(ids: Array<string>): Observable<any> {
    return this.http.delete(SKILL_API_ENDPOINTS.skills, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchSkills(data: Array<TWithId<TSkillSave>>): Observable<Array<ISkill>> {
    return this.http.patch<Array<ISkill>>(SKILL_API_ENDPOINTS.skills, data)
  }

  exportSkills(searchRequest: ISearchRequest): void {
    downloadRef(this.http.post<IDownloadRefResponse>(SKILL_API_ENDPOINTS.export, searchRequest));
  }

  /*
   * Referrals
   */
  getReferrals(searchRequest: ISearchRequest): Observable<ISearchResponse<IReferral>> {
    return this.http.post<ISearchResponse<IReferral>>(
      REFERRAL_API_ENDPOINTS.referrals, searchRequest);
  }

  getReferralCount(searchRequest: ISearchRequest): Observable<number> {
    return this.http.post<number>(REFERRAL_API_ENDPOINTS.count, searchRequest);
  }

  getReferral(id: string): Observable<IReferral> {
    return this.http.get<IReferral>(EndpointUrl.resolve(REFERRAL_API_ENDPOINTS.referral, {id}));
  }

  createReferral(data: TReferralSave): Observable<IReferral> {
    return this.http.put<IReferral>(REFERRAL_API_ENDPOINTS.referrals, data);
  }

  updateReferral(id: string, data: TReferralSave): Observable<IReferral> {
    return this.http.post<IReferral>(EndpointUrl.resolve(REFERRAL_API_ENDPOINTS.referral, {id}), data);
  }


  deleteReferrals(ids: Array<string>): Observable<any> {
    return this.http.delete(REFERRAL_API_ENDPOINTS.referrals, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchReferrals(data: Array<TWithId<TReferralSave>>): Observable<Array<IReferral>> {
    return this.http.patch<Array<IReferral>>(REFERRAL_API_ENDPOINTS.referrals, data)
  }

  exportReferrals(searchRequest: ISearchRequest): void {
    downloadRef(this.http.post<IDownloadRefResponse>(REFERRAL_API_ENDPOINTS.referrals, searchRequest));
  }

  /*
   * Hubspot
   */
  getHubspotCandidates(searchRequest: ISearchRequest): Observable<ISearchResponse<IHubspotCandidate>> {
    return this.http.post<ISearchResponse<IHubspotCandidate>>(
      HUBSPOT_API_ENDPOINTS.candidates, searchRequest);
  }

  getHubspotCandidate(id: string): Observable<IHubspotCandidate> {
    return this.http.get<IHubspotCandidate>(EndpointUrl.resolve(HUBSPOT_API_ENDPOINTS.candidate, {id}));
  }

  getHubspotContacts(searchRequest: ISearchRequest): Observable<ISearchResponse<IHubspotContact>> {
    return this.http.post<ISearchResponse<IHubspotContact>>(
      HUBSPOT_API_ENDPOINTS.contacts, searchRequest);
  }

  getHubspotContact(id: string): Observable<IHubspotContact> {
    return this.http.get<IHubspotContact>(EndpointUrl.resolve(HUBSPOT_API_ENDPOINTS.contact, {id}));
  }


  /*
   * Projects
   */
  getProjects(searchRequest: ISearchRequest, scope: TProjectScope): Observable<ISearchResponse<IProjectBase>> {
    return this.http.post<ISearchResponse<IProjectBase>>(
      EndpointUrl.resolve(PROJECT_API_ENDPOINTS.projects, {scope}), searchRequest);
  }

  getProjectCount(searchRequest: ISearchRequest): Observable<number> {
    return this.http.post<number>(PROJECT_API_ENDPOINTS.projectCount, searchRequest);
  }

  getProject(id: string): Observable<IProject> {
    return this.http.get<IProject>(EndpointUrl.resolve(PROJECT_API_ENDPOINTS.project, {id}));
  }

  getProjectClient(id: string): Observable<IProjectClient> {
    return this.http.get<IProjectClient>(EndpointUrl.resolve(PROJECT_API_ENDPOINTS.client, {id}));
  }

  getProjectAgency(id: string): Observable<IProjectAgency> {
    return this.http.get<IProjectAgency>(EndpointUrl.resolve(PROJECT_API_ENDPOINTS.agency, {id}));
  }

  getProjectTeam(id: string, searchRequest: ISearchRequest): Observable<ISearchResponse<IProjectTeamMember>> {
    return this.http.post<ISearchResponse<IProjectTeamMember>>(
      EndpointUrl.resolve(PROJECT_API_ENDPOINTS.projectTeam, {id}),
      searchRequest
    );
  }

  getAllProjectsDetails(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<TAllProjectsDetails>> {
    return this.http.post<ISearchResponse<TAllProjectsDetails>>(PROJECT_API_ENDPOINTS.allProjectsDetails, searchRequest);
  }

  getAllClientsDetails(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<TAllClientsDetails>> {
    return this.http.post<ISearchResponse<TAllClientsDetails>>(PROJECT_API_ENDPOINTS.allClientsDetails, searchRequest);
  }

  getAllAgenciesDetails(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<TAllAgenciesDetails>> {
    return this.http.post<ISearchResponse<TAllAgenciesDetails>>(PROJECT_API_ENDPOINTS.allAgenciesDetails, searchRequest);
  }

  getSingleProjectDetails(id: string, request: IPeriodRequest): Observable<TSingleProjectDetails> {
    return this.http.post<TSingleProjectDetails>(
      EndpointUrl.resolve(PROJECT_API_ENDPOINTS.singleProjectDetails, {id}),
      request
    );
  }

  getSingleProjectPlanning(id: string, request: IPeriodRequest): Observable<TSingleProjectDetails> {
    return this.http.post<TSingleProjectDetails>(
      EndpointUrl.resolve(PROJECT_API_ENDPOINTS.singleProjectPlanning, {id}),
      request
    );
  }

  getSingleClientDetails(id: string, request: IPeriodRequest): Observable<TSingleClientDetails> {
    return this.http.post<TSingleClientDetails>(
      EndpointUrl.resolve(PROJECT_API_ENDPOINTS.singleClientDetails, {id}),
      request
    );
  }

  getSingleAgencyDetails(id: string, request: IPeriodRequest): Observable<TSingleAgencyDetails> {
    return this.http.post<TSingleAgencyDetails>(
      EndpointUrl.resolve(PROJECT_API_ENDPOINTS.singleAgencyDetails, {id}),
      request
    );
  }


  /*
   * Team
   */

  getTeamMembers(searchRequest: ISearchRequest): Observable<ISearchResponse<ITeamMember>> {
    return this.http.post<ISearchResponse<ITeamMember>>(
      TEAM_API_ENDPOINTS.teamMembers, searchRequest);
  }

  getTeamMemberCount(searchRequest: ISearchRequest): Observable<number> {
    return this.http.post<number>(TEAM_API_ENDPOINTS.teamMemberCount, searchRequest);
  }

  getTeamMember(id: string): Observable<ITeamMember> {
    return this.http.get<ITeamMember>(EndpointUrl.resolve(TEAM_API_ENDPOINTS.teamMember, {id}));
  }

  createTeamMember(data: TTeamMemberSave): Observable<ITeamMember> {
    return this.http.put<ITeamMember>(TEAM_API_ENDPOINTS.teamMembers, data);
  }

  updateTeamMember(id: string, data: TTeamMemberSave): Observable<ITeamMember> {
    return this.http.post<ITeamMember>(EndpointUrl.resolve(TEAM_API_ENDPOINTS.teamMember, {id}), data);
  }

  deleteTeamMembers(ids: Array<string>): Observable<any> {
    return this.http.delete(TEAM_API_ENDPOINTS.teamMembers, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchTeamMembers(data: Array<TWithId<TTeamMemberSave>>): Observable<Array<ITeamMember>> {
    return this.http.patch<Array<ITeamMember>>(TEAM_API_ENDPOINTS.teamMembers, data)
  }

  exportTeamMembers(searchRequest: ISearchRequest): void {
    downloadRef(this.http.post<IDownloadRefResponse>(TEAM_API_ENDPOINTS.export, searchRequest));
  }


  getTeamMemberTalentPartners(searchRequest: ISearchRequest): Observable<ISearchResponse<ITeamMemberTalentPartner>> {
    return this.http.post<ISearchResponse<ITeamMemberTalentPartner>>(
      TEAM_API_ENDPOINTS.talentPartners, searchRequest);
  }

  getTeamMemberCompanies(searchRequest: ISearchRequest): Observable<ISearchResponse<ICompany>> {
    return this.http.post<ISearchResponse<ICompany>>(
      TEAM_API_ENDPOINTS.teamMemberCompanies, searchRequest);
  }

  getTeamMemberPaymentRecipients(searchRequest: ISearchRequest): Observable<ISearchResponse<IContact>> {
    return this.http.post<ISearchResponse<IContact>>(
      TEAM_API_ENDPOINTS.paymentRecipients, searchRequest);
  }

  getSingleTeamMemberDetails(id: string, request: IPeriodRequest): Observable<TSingleTeamMemberDetails> {
    return this.http.post<TSingleTeamMemberDetails>(
      EndpointUrl.resolve(TEAM_API_ENDPOINTS.singleTeamMemberDetails, {id}),
      request
    );
  }

  getSingleTeamMemberPlanning(id: string, request: IPeriodRequest): Observable<TSingleTeamMemberDetails> {
    return this.http.post<TSingleTeamMemberDetails>(
      EndpointUrl.resolve(TEAM_API_ENDPOINTS.singleTeamMemberPlanning, {id}),
      request
    );
  }

  /*
   * Team Candidates
   */

  getTeamCandidates(searchRequest: ISearchRequest): Observable<ISearchResponse<ITeamCandidate>> {
    return this.http.post<ISearchResponse<ITeamCandidate>>(
      TEAM_CANDIDATE_API_ENDPOINTS.teamCandidates, searchRequest);
  }

  getTeamCandidateCount(searchRequest: ISearchRequest): Observable<number> {
    return this.http.post<number>(TEAM_CANDIDATE_API_ENDPOINTS.teamCandidateCount, searchRequest);
  }

  getTeamCandidate(id: string): Observable<ITeamCandidate> {
    return this.http.get<ITeamCandidate>(EndpointUrl.resolve(TEAM_CANDIDATE_API_ENDPOINTS.teamCandidate, {id}));
  }

  createTeamCandidate(data: TTeamCandidateSave): Observable<ITeamCandidate> {
    return this.http.put<ITeamCandidate>(TEAM_CANDIDATE_API_ENDPOINTS.teamCandidates, data);
  }

  updateTeamCandidate(id: string, data: TTeamCandidateSave): Observable<ITeamCandidate> {
    return this.http.post<ITeamCandidate>(EndpointUrl.resolve(TEAM_CANDIDATE_API_ENDPOINTS.teamCandidate, {id}), data);
  }

  deleteTeamCandidates(ids: Array<string>): Observable<any> {
    return this.http.delete(TEAM_CANDIDATE_API_ENDPOINTS.teamCandidates, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchTeamCandidates(data: Array<TWithId<TTeamCandidateSave>>): Observable<Array<ITeamCandidate>> {
    return this.http.patch<Array<ITeamCandidate>>(TEAM_CANDIDATE_API_ENDPOINTS.teamCandidates, data)
  }

  exportTeamCandidates(searchRequest: ISearchRequest): void {
    downloadRef(this.http.post<IDownloadRefResponse>(TEAM_CANDIDATE_API_ENDPOINTS.export, searchRequest));
  }

  /*
   * Time
   */
  getDayTimeSheets(teamMemberId: string, request: TTimeSheetsDetailsRequest): Observable<TTimeSheetsDetails> {
    return this.http.post<TTimeSheetsDetails>(EndpointUrl.resolve(TIME_API_ENDPOINTS.dayTimeSheets, {teamMemberId}), request);
  }

  getDayProjectCategoryRow(
    teamMemberId: string, projectId: string, request: IPeriodRequest
  ): Observable<IDetailsCategoryRow<TProjectEntryEntity, ITimeSheetsValueData>> {
    return this.http.post<IDetailsCategoryRow<TProjectEntryEntity, ITimeSheetsValueData>>(
      EndpointUrl.resolve(TIME_API_ENDPOINTS.dayTimeProject,{teamMemberId, projectId}), request);
  }

  getWeekTimeSheets(teamMemberId: string, request: TTimeSheetsDetailsRequest): Observable<TTimeSheetsDetails> {
    return this.http.post<TTimeSheetsDetails>(EndpointUrl.resolve(TIME_API_ENDPOINTS.weekTimeSheets, {teamMemberId}), request);
  }

  updateDayTimeEntries(
    teamMemberId: string, projectId: string, request: TDayTimeEntriesRequest
  ): Observable<IDetailsCategoryRow<TProjectEntryEntity, TTimeSheetsDetails>> {
    return this.http.post<IDetailsCategoryRow<TProjectEntryEntity, TTimeSheetsDetails>>(
      EndpointUrl.resolve(TIME_API_ENDPOINTS.dayTimeEntries,{teamMemberId, projectId}), request);
  }

  searchTimeEntryRows(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<ITimeEntryRow>> {
    return this.http.post<ISearchResponse<ITimeEntryRow>>(TIME_API_ENDPOINTS.searchTimeEntries, searchRequest);
  }

  searchTimeApprovals(searchRequest: ISearchRequest<TTimeApprovalsRequest>): Observable<ISearchResponse<TTimeApprovalsTeamMember, IPeriodRange>> {
    return this.http.post<ISearchResponse<TTimeApprovalsTeamMember, IPeriodRange>>(TIME_API_ENDPOINTS.searchTimeApprovals, searchRequest);
  }

  /*
   * Expenses
   */
  searchExpenses(teamMemberId: string, searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<IExpense>> {
    return this.http.post<ISearchResponse<IExpense>>(EndpointUrl.resolve(EXPENSES_API_ENDPOINTS.searchExpenses, {teamMemberId}), searchRequest);
  }

  getExpense(teamMemberId: string, id: string): Observable<IExpense> {
    return this.http.get<IExpense>(EndpointUrl.resolve(EXPENSES_API_ENDPOINTS.expense, {teamMemberId, id}));
  }

  createExpense(teamMemberId: string, data: TExpenseSave): Observable<IExpense> {
    return this.http.put<IExpense>(EndpointUrl.resolve(EXPENSES_API_ENDPOINTS.expenses, {teamMemberId}), data);
  }

  updateExpense(teamMemberId: string, id: string, data: TExpenseSave): Observable<IExpense> {
    return this.http.post<IExpense>(EndpointUrl.resolve(EXPENSES_API_ENDPOINTS.expense, {teamMemberId, id}), data);
  }


  deleteExpenses(teamMemberId: string, ids: Array<string>): Observable<any> {
    return this.http.delete(EndpointUrl.resolve(EXPENSES_API_ENDPOINTS.expenses, {teamMemberId}), {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchExpenses(teamMemberId: string, data: Array<TWithId<IExpense>>): Observable<Array<IExpense>> {
    return this.http.patch<Array<IExpense>>(EndpointUrl.resolve(EXPENSES_API_ENDPOINTS.expenses, {teamMemberId}), data)
  }


  searchExpenseReports(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<IExpenseReport>> {
    return this.http.post<ISearchResponse<IExpenseReport>>(EXPENSES_API_ENDPOINTS.searchExpenseReports, searchRequest);
  }

  deleteExpenseReports(ids: Array<string>): Observable<any> {
    return this.http.delete(EXPENSES_API_ENDPOINTS.expenseReports, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchExpenseReports(data: Array<TWithId<IExpense>>): Observable<Array<IExpenseReport>> {
    return this.http.patch<Array<IExpenseReport>>(EXPENSES_API_ENDPOINTS.expenseReports, data)
  }

  getExpenseReport(id: string): Observable<IExpenseReport> {
    return this.http.get<IExpenseReport>(EndpointUrl.resolve(EXPENSES_API_ENDPOINTS.expenseReport, {id}));
  }


  updateExpenseReport(id: string, data: TExpenseSave): Observable<IExpenseReport> {
    return this.http.post<IExpenseReport>(EndpointUrl.resolve(EXPENSES_API_ENDPOINTS.expenseReport, {id}), data);
  }

  getExpenseReportsTotal(searchRequest: ISearchRequest<IPeriodRequest>): Observable<number> {
    return this.http.post<number>(EXPENSES_API_ENDPOINTS.expenseReportsTotal, searchRequest);
  }

  searchExpenseApprovals(searchRequest: ISearchRequest<TTimeApprovalsRequest>): Observable<ISearchResponse<IExpenseReport>> {
    return this.http.post<ISearchResponse<IExpenseReport>>(EXPENSES_API_ENDPOINTS.searchExpenseApprovals, searchRequest);
  }

  /*
   * Time Offs
   */

  searchTimeOffRequests(teamMemberId: string, searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<ITimeOff>> {
    return this.http.post<ISearchResponse<ITimeOff>>(EndpointUrl.resolve(TIME_OFF_API_ENDPOINTS.searchTimeOffRequests, {teamMemberId}), searchRequest);
  }

  getTimeOffRequest(teamMemberId: string, id: string): Observable<ITimeOff> {
    return this.http.get<ITimeOff>(EndpointUrl.resolve(TIME_OFF_API_ENDPOINTS.timeOffRequest, {teamMemberId, id}));
  }

  createTimeOffRequest(teamMemberId: string, data: TTimeOffSave): Observable<ITimeOff> {
    return this.http.put<ITimeOff>(EndpointUrl.resolve(TIME_OFF_API_ENDPOINTS.timeOffRequests, {teamMemberId}), data);
  }

  updateTimeOffRequest(teamMemberId: string, id: string, data: TTimeOffSave): Observable<ITimeOff> {
    return this.http.post<ITimeOff>(EndpointUrl.resolve(TIME_OFF_API_ENDPOINTS.timeOffRequest, {teamMemberId, id}), data);
  }


  deleteTimeOffRequests(teamMemberId: string, ids: Array<string>): Observable<any> {
    return this.http.delete(EndpointUrl.resolve(TIME_OFF_API_ENDPOINTS.timeOffRequests, {teamMemberId}), {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchTimeOffRequests(teamMemberId: string, data: Array<TWithId<ITimeOff>>): Observable<Array<ITimeOff>> {
    return this.http.patch<Array<ITimeOff>>(EndpointUrl.resolve(TIME_OFF_API_ENDPOINTS.timeOffRequests, {teamMemberId}), data)
  }


  searchTimeOffs(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<TTimeOffApprovalsTeamMember>> {
    return this.http.post<ISearchResponse<TTimeOffApprovalsTeamMember>>(TIME_OFF_API_ENDPOINTS.searchTimeOffs, searchRequest);
  }

  getTimeOff(id: string): Observable<ITimeOff> {
    return this.http.get<ITimeOff>(EndpointUrl.resolve(TIME_OFF_API_ENDPOINTS.timeOff, {id}));
  }

  updateTimeOff(id: string, data: TTimeOffSave): Observable<ITimeOff> {
    return this.http.post<ITimeOff>(EndpointUrl.resolve(TIME_OFF_API_ENDPOINTS.timeOff, {id}), data);
  }


  deleteTimeOffs(ids: Array<string>): Observable<any> {
    return this.http.delete(TIME_OFF_API_ENDPOINTS.timeOffs, {
      body: {
        ids
      } as IDeletePayload
    });
  }

  patchTimeOffs(data: Array<TWithId<IExpense>>): Observable<Array<ITimeOff>> {
    return this.http.patch<Array<ITimeOff>>(TIME_OFF_API_ENDPOINTS.timeOffs, data)
  }


}
