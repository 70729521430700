import {ICountry, IIdentified, INamed, ITimestamped, TCommonStatus} from './common';

export const HOLIDAY_CALENDAR_API_ENDPOINTS = {
  calendars: '/api/holiday-calendars',
  calendar: '/api/holiday-calendars/:id',
  holidaysUniverse: '/api/holiday-calendars/holidays-universe/:year'
};


export interface IHoliday extends INamed {
  date: Date;
  observedDate: Date;
  isInactive?: boolean;
  isCustom?: boolean;
}

export interface IYearHolidays {
  year: number;
  holidays: Array<IHoliday>;
}


export interface IHolidayCalendarBase {
  baseId?: string;
  baseName?: string;
  countryName: string;
  countryCode: string;
  countryFlag?: string;
  holidayRange: Array<IYearHolidays>;
}


export interface IHolidayCalendar extends IIdentified, INamed, ITimestamped, IHolidayCalendarBase {
  status: TCommonStatus;
  holidayCount: number;
  description: string;
}

export type THolidayCalendarSave = Omit<IHolidayCalendar, 'id' | 'holidayCount' | 'createdAt' | 'updatedAt'>;


export interface IDayHolidays {
  name: string;
  countries: Array<ICountry>;
  calendars: Array<Pick<IHolidayCalendar, 'id' | 'name'>>;
}

export interface IHolidaysByDate {
  date: Date;
  holidays: Array<IDayHolidays>;
}
