import { Component, EventEmitter, Output } from '@angular/core';
import { PageableTableComponent } from '../../common/components/pageable-table.component';
import { ISearchRequest, ISearchResponse } from '../../api/shared/search-api';
import { MenuItem } from 'primeng/api';
import { AppResourceService } from '../../app.resource.service';
import { Router } from '@angular/router';
import { TableQuery } from '../../common/util/table-query';
import { Observable } from 'rxjs';
import { ITeamMemberTalentPartner, TTeamMemberType } from '../../api/shared/app-domain/team';
import { getTeamMemberTypeIcon, TTeamScope } from './team';
import { IScopeFilter } from '../common/common';

@Component({
  selector: 'app-team-talent-partners-table',
  template: `
    <p-menu #tableActionsMenu [popup]="true" [model]="tableActionItems"></p-menu>
    <div class="flex align-items-center justify-content-between mb-2">
      <div class="flex align-items-center">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <input [(ngModel)]="search"
                 pInputText type="text" class="p-inputtext-sm" [style.width]="'300px'"
                 placeholder="Search"
                 appChangesDebounce (debounced)="load()"/>
        </span>
        <div class="ml-3 text-gray-700" style="width: 6rem;">{{selection.length}} selected</div>
        <p-menubar class="ml-2 mt-page-menu-bar" [model]="tableMenu"></p-menubar>
      </div>
      <button pButton pRipple label="Table Actions" class="p-button-sm p-button-outlined"
              icon="pi pi-chevron-down"
              iconPos="right" (click)="tableActionsMenu.toggle($event)"></button>
    </div>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Talent Partners"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: (1 * 45) + 'px', 'max-width': (1 * 45) + 'px'}">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="name" style="min-width: 300px">
              <div class="flex justify-content-between align-items-center w-full">
                <div>Talent Partner</div>
                <p-sortIcon class="align-self-center" field="name"></p-sortIcon>
                <p-columnFilter type="text" field="name" display="menu" class="ml-auto">
                </p-columnFilter>
              </div>
            </th>
            <app-budget-components-column-headers [useColumnFilters]="true"
                                                  [width]="180"></app-budget-components-column-headers>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <td [style]="{width: (1 * 45) + 'px', 'max-width': (1 * 45) + 'px'}">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td style="padding-top: 0; padding-bottom: 0; min-width: 300px" [class.cursor-pointer]="true"
                (click)="goToDetail(rowData.id, rowData.name)">
              <div class="flex align-items-center justify-content-between w-full">
                <div class="flex align-items-center">
                  <div>
                    <div class="text-primary">{{rowData.name}}</div>
                  </div>
                </div>
              </div>
            </td>
            <app-budget-components-cells [rowData]="rowData"></app-budget-components-cells>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `
})
export class TeamTalentPartnersTableComponent extends PageableTableComponent<ITeamMemberTalentPartner> {
  @Output() changeModeFilter = new EventEmitter<{ scope: TTeamScope; scopeFilter: IScopeFilter | null }>();

  tableActionItems: Array<MenuItem> = [
    {label: 'Import', command: this.tbd},
    {label: 'Export to CSV', command: this.tbd}
  ];

  rowMenu: Array<MenuItem> = [
    {label: 'Edit', icon: 'pi pi-pencil'},
    {label: 'Duplicate', icon: 'pi pi-clone'},
    {label: 'Archive', icon: 'pi pi-server'},
    {label: 'Delete', icon: 'pi pi-trash', styleClass: 'mt-alert-menuitem'}
  ];

  tableMenu: Array<MenuItem> = [
    {label: 'Edit', icon: 'pi pi-pencil', disabled: true},
    {label: 'Archive', icon: 'pi pi-server', disabled: true},
    {label: 'Delete', icon: 'pi pi-trash', disabled: true}
  ];

  constructor(private resource: AppResourceService,
              private router: Router) {
    super();
  }


  protected override fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<ITeamMemberTalentPartner>> {
    return this.resource.getTeamMemberTalentPartners(searchRequest); // TODO use companies
  }

  getTeamMemberTypeIcon(type: TTeamMemberType): string | null {
    return getTeamMemberTypeIcon(type);
  }

  goToDetail(id: string, name: string): void {
    this.changeModeFilter.emit({scope: 'all', scopeFilter: {field: 'talentPartnerId', id, name}});
    this.router.navigate(['team'], {queryParams: {scope: 'all'}});
  }

  override updateTableMenu(): void {
    for (const mi of this.tableMenu) {
      mi.disabled = !this.selection?.length;
    }
    this.tableMenu = [...this.tableMenu];
  }

  tbd(): void {
    alert('Not implemented yet');
  }
}
