import {Component, Host, Input, OnInit} from '@angular/core';
import {
  IProjectEmbedded, isInstanceOfProjectEmbedded,
  TProjectEntryEntity,
} from '../../../api/shared/app-domain/project';

import { Params } from '@angular/router';
import {EntityPropertyOptionsService} from '../../common/entity-property-options.service';



@Component({
  selector: 'app-project-entry-entity',
  template: `
    <img [src]="propertyOptions.getProjectTypeIcon(data.type)" height="20" width="20"
         [pTooltip]="data.type" tooltipPosition="top"/>
    <div class="flex flex-column ml-2" [style.width]="width">
      <div class="mt-overflow-ellipsis text" appTooltipOnOverflow>
        <a *ngIf="routerLink != null; else simple" class="text-primary no-underline"
           [routerLink]="[routerLink, data.id]" [queryParams]="queryParams">
          {{data.name}}
        </a>
        <ng-template #simple>
          {{data.name}}
        </ng-template>
      </div>
      <div class="mt-overflow-ellipsis text-sm text-gray-600"
           appTooltipOnOverflow>{{data.clientName}} | {{data.status}}</div>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      align-items: center;
    }
  `],
  providers: [
    EntityPropertyOptionsService
  ]
})
export class ProjectEntryEntityComponent implements OnInit {
  @Input() entryEntity!: TProjectEntryEntity | IProjectEmbedded;
  @Input() routerLink?: string;
  @Input() queryParams: Params | null = null;
  @Input() width = '120px';

  data!: TProjectEntryEntity;

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }

  ngOnInit() {
    if (isInstanceOfProjectEmbedded(this.entryEntity)) {
      this.data = {
        id: this.entryEntity.projectId,
        name: this.entryEntity.projectName,
        status: this.entryEntity.projectStatus,
        type: this.entryEntity.projectType,
        clientName: this.entryEntity.clientName
      } as TProjectEntryEntity;
    } else {
      this.data = this.entryEntity;
    }
  }

}
