import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {IPaidTimeOff, TPaidTimeOffSave} from '../../api/shared/app-domain/settings';
import {AppResourceService} from '../../app.resource.service';
import {finalize} from 'rxjs';
import {existsAsyncValidator} from '../../common/util/util';
import {TableBaseComponent} from '../../common/components/table-base.component';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {ColumnsInfoService} from '../common/column-header.component';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {EntityEditorComponent} from '../common/entity-editor.component';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {TControlsOf} from '../../common/types';

@Component({
  selector: 'app-paid-time-off-form-control',
  template: `
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'name'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'status'" [controlName]="name" controlType="dropdown"
                        [options]="propertyOptions.commonStatuses"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'days'" [controlName]="name" dataType="number" suffix="d" placeholder="&infin;"
                                [showButtons]="true" [min]="0"></app-form-control-wrapper>
    </ng-container>
  `
})
export class PaidTimeOffFormControlComponent {
  @Input() name!: string;

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }
}

@Component({
  selector: 'app-paid-time-off-editor',
  template: `
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container>
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-6 p-fluid">
            <app-paid-time-off-form-control name="name"></app-paid-time-off-form-control>
          </div>
          <div class="field col-3 p-fluid">
            <app-paid-time-off-form-control name="days"></app-paid-time-off-form-control>
          </div>
          <div class="field col-3 p-fluid">
            <app-paid-time-off-form-control name="status"></app-paid-time-off-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => PaidTimeOffEditorComponent)},
    SubscriptionsService
  ]

})
export class PaidTimeOffEditorComponent extends EntityEditorComponent<TPaidTimeOffSave, IPaidTimeOff> {

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              fb: FormBuilder) {
    super(fb);
    this.entityName = 'Paid Time Off';
    this.api = {
      getEntity: this.resource.getPaidTimeOff.bind(this.resource),
      createEntity: this.resource.createPaidTimeOff.bind(this.resource),
      updateEntity: this.resource.updatePaidTimeOff.bind(this.resource),
    }
  }

  override getName(): string | null {
    return this.entity.name ?? '';
  }

  override buildForm(): void {
    const checkExists = this.getCheckExists(this.resource.searchPaidTimeOffs.bind(this.resource), 'name');
    this.form = this.fb.group<TControlsOf<TPaidTimeOffSave>>({
      name: new FormControl(this.entity.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExists)]
      }),
      days: new FormControl(this.isNew() ? null : this.entity.days, []),
      status: new FormControl(this.isNew() ? 'Active' : this.entity.status, [Validators.required]),
    });
    if (!this.isNew()) {
      this.setEnable('name', false);
    }
  }
}

@Component({
  selector: 'app-paid-time-offs-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="mt-page-title">Paid Time Off</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <button pButton pRipple label="New Custom Paid Time Off"
                    class="ml-2"
                    icon="pi pi-plus" (click)="openCreateOrUpdateDialog(null)"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 shadow-1 bg-white p-3 pb-3">
      <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit']" [showActions]="false"></app-table-toolbar>
      <app-entity-edit-dialog #editDialog (apply)="onCreatedOrUpdated($event)">
        <ng-template pTemplate let-param>
          <app-paid-time-off-editor [param]="param"></app-paid-time-off-editor>
        </ng-template>
      </app-entity-edit-dialog>
      <app-spinnerizer [active]="loading"
                       [target]="container">
      </app-spinnerizer>
      <div #container>
        <p-table [value]="data"
                 responsiveLayout="scroll"
                 [(selection)]="selection"
                 (selectionChange)="onSelectionChange()"
                 [globalFilterFields]="['name','status']">
          <ng-template pTemplate="header">
            <tr>
              <app-column-header [isSelector]="true"></app-column-header>
              <app-column-header field="name" filterType="text">
              </app-column-header>
              <app-column-header field="days" filterType="numeric"></app-column-header>
              <app-column-header field="status" filterType="optionsEquals"
                                 [options]="propertyOptions.commonStatuses">
              </app-column-header>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
              <app-table-cell field="name" type="custom" [rowData]="rowData"
                              styleClass="flex justify-content-between">
                <div class="flex align-items-center overflow-x-hidden">
                  <img [src]="propertyOptions.getTeamMemberTypeIcon(rowData.name)" height="16" width="16"/>
                  <div class="ml-1 mt-overflow-ellipsis mt-link" appTooltipOnOverflow (click)="openCreateOrUpdateDialog(rowData)">
                    {{rowData.name}}
                  </div>
                </div>
                <app-row-menu-button [tableBase]="this" [rowData]="rowData" [omitMenuItems]="['duplicate']"></app-row-menu-button>
              </app-table-cell>
              <app-table-cell field="days" align="right" [rowData]="rowData" nullSymbol="&infin;"></app-table-cell>
              <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService
  ]
})
export class PaidTimeOffsPageComponent extends TableBaseComponent<IPaidTimeOff> implements OnInit {
  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.api = {
      archiveEntities: this.resource.patchPaidTimeOffs.bind(this.resource),
      deleteEntities: this.resource.deletePaidTimeOffs.bind(this.resource),
    };
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.tableComponent?.filterGlobal(this.search, 'contains');
    this.loading = true;
    this.resource.searchPaidTimeOffs({}).pipe(
      finalize(() => this.loading = false)
    ).subscribe((response) => {
      this.data = response.results;
    });
  }

}
