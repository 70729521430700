import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges, OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Directive({
  selector: '[appRecreateViewKey]'
})
export class RecreateViewDirective implements OnChanges {
  @Input('appRecreateViewKey') key: any;

  viewRef: EmbeddedViewRef<any> | null = null;

  constructor(private templateRef: TemplateRef<any>,
              private parentViewRef: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['key']) {
      this.recreate();
    }
  }

  recreate(): void {
    if (!!this.viewRef) {
      this.destroyView();
    }
    this.createView();
  }

  private createView() {
    this.viewRef = this.parentViewRef.createEmbeddedView(this.templateRef);
  }

  private destroyView() {
    this.viewRef!.destroy();
    this.viewRef = null;
  }
}


@Directive({
  selector: '[appRenderThis]'
})
export class RenderThisDirective implements OnInit {

  viewRef: EmbeddedViewRef<any> | null = null;

  constructor(private templateRef: TemplateRef<any>,
              private parentViewRef: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.recreate();
  }

  recreate(): void {
    if (!!this.viewRef) {
      this.destroyView();
    }
    this.createView();
  }

  private createView() {
    this.viewRef = this.parentViewRef.createEmbeddedView(this.templateRef);
  }

  private destroyView() {
    this.viewRef!.destroy();
    this.viewRef = null;
  }

}
