import {Component, Input, OnInit} from '@angular/core';
import {TreeTable} from 'primeng/treetable';
import {TColumnFilter} from './column-header.component';
import {FilterMatchMode} from 'primeng/api';
import {isArray} from 'lodash';

@Component({
  selector: 'app-tt-filter',
  template: `
    <p-overlayPanel #op [dismissable]="true" (onShow)="valueTmp = value">
      <ng-template pTemplate>
        <div class="flex align-items-center">
          <ng-container [ngSwitch]="filterType">
            <ng-container *switchCases="['optionsIn', 'optionsContains', 'optionsArray']">
              <p-multiSelect [(ngModel)]="valueTmp"  [style]="{minWidth: '200px'}"
                             [options]="options!"
                             [optionLabel]="optionLabel!"
                             [optionValue]="optionValue!"
                             [virtualScroll]="optionsVirtualScroll"
                             [showToggleAll]="!optionsVirtualScroll"
                             [virtualScrollItemSize]="39"
                             [placeholder]="filterType === 'optionsArray' ? '' : filterType === 'optionsIn' ? 'Any' : 'Contains'"
                             [selectedItemsLabel]="optionsSelectedLabel!"
                             [showClear]="true">
                <ng-template *ngIf="optionIcon" let-item pTemplate="item">
                  <div class="flex align-items-center">
                    <img class="mr-2" [src]="item[optionIcon!]" width="24"/>
                    <div>{{optionLabel ? item[optionLabel!] : item}}</div>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-container>
          </ng-container>
          <button class="ml-2" pButton
                  (click)="value = valueTmp; op.hide(); treeTable.filter(value, field, matchMode)">
            Apply
          </button>
        </div>
      </ng-template>
    </p-overlayPanel>
    <button type="button" icon="pi pi-filter"
            class="p-column-filter-menu-button cursor-pointer"
            [class.p-column-filter-menu-button-active]="isFilterActive()"
            (click)="op.toggle($event); $event.stopPropagation()"><span class="pi pi-filter-icon pi-filter"></span>
    </button>
  `
})
export class TreeTableFilterComponent implements OnInit {
  @Input() filterType: TColumnFilter = 'text';
  @Input() field!: string;
  @Input() optionsVirtualScroll = false;
  @Input() options?: any[];
  @Input() optionLabel?: string;
  @Input() optionValue?: string;
  @Input() optionIcon?: string;
  @Input() optionsSelectedLabel?: string;
  value: any;
  valueTmp: any;
  matchMode = FilterMatchMode.EQUALS;


  constructor(public treeTable: TreeTable) {
  }
  ngOnInit(): void {
    if (!!this.optionValue && !!!this.optionLabel) {
      this.optionLabel = this.optionValue;
    }
    if (!!!this.optionsSelectedLabel) {
      this.optionsSelectedLabel = `{0} items selected`;
    }

    if (['optionsContains', 'optionsArray'].includes(this.filterType)) {
      this.matchMode = FilterMatchMode.CONTAINS;
    } else if (this.filterType === 'optionsIn') {
      this.matchMode = FilterMatchMode.IN;
    }
  }

  isFilterActive(): boolean {
    if (this.value == null) {
      return false;
    }
    if (isArray(this.value) && this.value.length === 0) {
      return false;
    }
    return true;
  }
}
