import {Directive, forwardRef, Input} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {Observable} from 'rxjs';
import {existsAsyncValidator} from '../util/util';

@Directive({
  selector: '[existsAsync][ngModel]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => ExistsAsyncValidatorDirective),
      multi: true
    }
  ]
})
export class ExistsAsyncValidatorDirective implements AsyncValidator {
  @Input('existsAsync') checkFn!: (value: any) => Observable<boolean>;

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return existsAsyncValidator(this.checkFn)(control);
  }
}


@Directive({
  selector: '[exists][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ExistsValidatorDirective),
      multi: true
    }
  ]
})
export class ExistsValidatorDirective implements Validator {
  @Input('exists') checkFn!: (value: any) => boolean;

  validate(
    control: AbstractControl
  ): ValidationErrors | null {
    return this.checkFn(control.value) ? {exists: true} : null;
  }
}
