import {AfterViewInit, Component, forwardRef, OnChanges} from '@angular/core';
import {AppResourceService} from '../../app.resource.service';
import {PageableTableComponent} from '../../common/components/pageable-table.component';
import {ICompany, TCompanyType} from '../../api/shared/app-domain/company';
import {COMPANY_ASSOC_LINKS, getCompanyTypeIcon} from './company';
import {ColumnsInfoService} from '../common/column-header.component';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {inheritanceProvider} from '../../common/util/util';

@Component({
  selector: 'app-companies-table',
  template: `
    <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit']"></app-table-toolbar>
    <app-entity-edit-dialog #editDialog (apply)="onCreatedOrUpdated($event)">
      <ng-template pTemplate let-param>
        <app-company-editor [param]="param"></app-company-editor>
      </ng-template>
    </app-entity-edit-dialog>

    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [(first)]="firstPage"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Companies"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               [filters]="filters">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="name" filterType="text" [style]="{minWidth: '240px'}"></app-column-header>
            <app-column-header field="status" filterType="optionsEquals"
                               [style]="{width: '120px'}"
                               [options]="propertyOptions.commonStatuses">
            </app-column-header>
            <app-column-header field="types" filterType="optionsArray"
                               [options]="propertyOptions.companyTypes"
                               optionsSelectedLabel="{0} Types selected">
            </app-column-header>
            <app-column-header field="createdAt" filterType="date" [fixedWidth]="180"></app-column-header>
            <app-column-header field="updatedAt" filterType="date" [fixedWidth]="180"></app-column-header>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="name" type="custom" [rowData]="rowData" styleClass="flex justify-content-between">
              <div class="flex align-items-center overflow-x-hidden">
                <div class="mt-overflow-ellipsis" appTooltipOnOverflow>
                  <a class="text-primary no-underline"
                     [routerLink]="['/tbd', 'company-edit', rowData.id]">{{rowData.name}}</a>
                </div>
              </div>
              <app-row-menu-button [tableBase]="this" [rowData]="rowData"></app-row-menu-button>
            </app-table-cell>
            <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            <app-table-cell type="stringArray" field="types" [rowData]="rowData"
                            [stringArrayRoute]="getAssocLinkByType"></app-table-cell>
            <app-table-cell field="createdAt"
                            [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="updatedAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService,
    inheritanceProvider(PageableTableComponent, CompaniesTableComponent),
  ]
})
export class CompaniesTableComponent extends PageableTableComponent<ICompany> implements AfterViewInit, OnChanges {
  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.api = this.resource.companies;
  }

  getCompanyTypeIcon(type: TCompanyType): string | null {
    return getCompanyTypeIcon(type);
  }

  getAssocLinkByType(type: TCompanyType, company: ICompany): string {
    return COMPANY_ASSOC_LINKS[type].replace('{:id}', company.id).replace('{:name}', company.name);
  }

}
