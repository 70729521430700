import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {IDbTable} from '../../../../api/shared/dev-tools/db-api';

@Injectable()
export class DbBrowserService {
  private browseTableSource = new Subject<IDbTable>();
  private clearAllStatesSource = new Subject<void>();

  browseTableSource$ = this.browseTableSource.asObservable();
  clearAllStatesSource$ = this.clearAllStatesSource.asObservable();

  onBrowseTable(table: IDbTable): void {
    this.browseTableSource.next(table);
  }

  onClearAllStates(): void {
    this.clearAllStatesSource.next();
  }
}
