import {IIdentified, INamed, THubspotStage} from './common';

export const HUBSPOT_API_ENDPOINTS = {
  candidates: '/api/hubspot/candidates',
  candidate: '/api/hubspot/candidates/:id',
  contacts: '/api/hubspot/contacts',
  contact: '/api/hubspot/contacts/:id',

};

export interface IHubspotCandidate extends IIdentified, INamed {
  pipeline: string;
  stage: THubspotStage;
  url: string;
}

export interface IHubspotContact extends IIdentified, INamed {
  url: string;
}


