import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {IDepartment, TDepartmentSave} from '../../api/shared/app-domain/settings';
import {AppResourceService} from '../../app.resource.service';
import {finalize} from 'rxjs';
import {existsAsyncValidator} from '../../common/util/util';
import {TableBaseComponent} from '../../common/components/table-base.component';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {ColumnsInfoService} from '../common/column-header.component';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {EntityEditorComponent} from '../common/entity-editor.component';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {TControlsOf} from '../../common/types';

@Component({
  selector: 'app-department-form-control',
  template: `
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'name'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'status'" [controlName]="name" controlType="dropdown"
                        [options]="propertyOptions.commonStatuses"></app-form-control-wrapper>
    </ng-container>
  `
})
export class DepartmentFormControlComponent {
  @Input() name!: string;

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }
}

@Component({
  selector: 'app-department-editor',
  template: `
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container>
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-8 p-fluid">
            <app-department-form-control name="name"></app-department-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-department-form-control name="status"></app-department-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => DepartmentEditorComponent)},
    SubscriptionsService
  ]

})
export class DepartmentEditorComponent extends EntityEditorComponent<TDepartmentSave, IDepartment> {

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              fb: FormBuilder) {
    super(fb);
    this.entityName = 'Department';
    this.api = {
      getEntity: this.resource.getDepartment.bind(this.resource),
      createEntity: this.resource.createDepartment.bind(this.resource),
      updateEntity: this.resource.updateDepartment.bind(this.resource),
    }
  }

  override getName(): string | null {
    return this.entity.name ?? '';
  }

  override buildForm(): void {
    const checkExistsDepartment = this.getCheckExists(this.resource.getDepartments.bind(this.resource), 'name');
    this.form = this.fb.group<TControlsOf<TDepartmentSave>>({
      name: new FormControl(this.entity.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsDepartment)]
      }),
      status: new FormControl(this.isNew() ? 'Active' : this.entity.status, [Validators.required]),
    });
  }
}

@Component({
  selector: 'app-departments-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="mt-page-title">Departments</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <button pButton pRipple label="New Department"
                    class="ml-2"
                    icon="pi pi-plus" (click)="openCreateOrUpdateDialog(null)"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 shadow-1 bg-white p-3 pb-3">
      <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit']" [showActions]="false"></app-table-toolbar>
      <app-entity-edit-dialog #editDialog (apply)="onCreatedOrUpdated($event)">
        <ng-template pTemplate let-param>
          <app-department-editor [param]="param"></app-department-editor>
        </ng-template>
      </app-entity-edit-dialog>
      <app-spinnerizer [active]="loading"
                       [target]="container">
      </app-spinnerizer>
      <div #container>
        <p-table [value]="data"
                 responsiveLayout="scroll"
                 [(selection)]="selection"
                 (selectionChange)="onSelectionChange()"
                 [globalFilterFields]="['name','status']">
          <ng-template pTemplate="header">
            <tr>
              <app-column-header [isSelector]="true"></app-column-header>
              <app-column-header field="name" filterType="text">
              </app-column-header>
              <app-column-header field="status" filterType="optionsEquals"
                                 [options]="propertyOptions.commonStatuses">
              </app-column-header>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
              <app-table-cell field="name" type="custom" [rowData]="rowData"
                              styleClass="flex justify-content-between">
                <div class="flex align-items-center overflow-x-hidden">
                  <div class="mt-overflow-ellipsis mt-link" appTooltipOnOverflow (click)="openCreateOrUpdateDialog(rowData)">
                    {{rowData.name}}
<!--                    <a class="text-primary no-underline"-->
<!--                       [routerLink]="['/tbd', 'department-edit', rowData.id]">{{rowData.name}}</a>-->
                  </div>
                </div>
                <app-row-menu-button [tableBase]="this" [rowData]="rowData" [omitMenuItems]="['duplicate']"></app-row-menu-button>
              </app-table-cell>
              <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService
  ]
})
export class DepartmentsPageComponent extends TableBaseComponent<IDepartment> implements OnInit {
  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.api = {
      archiveEntities: this.resource.patchDepartments.bind(this.resource),
      deleteEntities: this.resource.deleteDepartments.bind(this.resource),
    };
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.tableComponent?.filterGlobal(this.search, 'contains');
    this.loading = true;
    this.resource.getDepartments({}).pipe(
      finalize(() => this.loading = false)
    ).subscribe((response) => {
      this.data = response.results;
    });
  }

}
