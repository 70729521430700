import {Component, ViewChild} from "@angular/core";
import {MenuItem} from 'primeng/api';
import {AuthService} from '../../common/services/auth.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import {MAIN_MENU, SETTINGS_MENU, USER_MENU} from './main-menu';
import {cloneDeep} from 'lodash';
import {Router} from '@angular/router';
import {AppConfigService} from '../../app-config.service';

@Component({
  selector: 'app-main-page',
  template: `
    <p-toast></p-toast>
    <p-confirmPopup key="g-popup"></p-confirmPopup>
    <p-confirmDialog key="g-dialog" [style]="{width: '30vw'}" rejectButtonStyleClass="p-button-text"></p-confirmDialog>
    <div class="mt-app-main" [class.mt-show-alert-bar]="showAlertBar">
      <div *ngIf="showAlertBar" class="mt-alert-bar">
        <div class="grid grid-nogutter w-full">
          <div class="col-6 flex align-items-center">
            <span>10 trial days remaining.</span>
            <a class="ml-2 opacity-80 cursor-pointer no-underline text-white"
               [href]="'/tbd'">View pricing plans.</a>
          </div>
          <div class="col-6 flex justify-content-end align-items-center">
            <a class="ml-2 opacity-80 cursor-pointer no-underline text-white"
               [href]="'/tbd'">Sign up for a free training webinar</a>
            <button pButton pRipple type="button" icon="pi pi-times"
                    class="ml-3 p-button-rounded p-button-text"
                    (click)="showAlertBar=false"></button>
          </div>
        </div>
      </div>
      <div class="mt-app-top-bar justify-content-between">
        <div class="flex align-items-center">
          <img alt="logo" class="mr-2" src="assets/images/mt-logo.svg"/>
          <p-menubar *ngIf="auth.isSignedIn()" [model]="mainMenuItems"></p-menubar>
        </div>
        <div class="flex align-items-center justify-content-end h-full">
          <ng-container *ngIf="auth.isSignedIn()">
            <ng-container>
              <button pButton pRipple type="button" label="Track Time"
                      icon="pi pi-clock"
                      class="p-button-rounded p-button-text text-white text-sm mr-4"
                      (click)="opTrackTime.toggle($event)">
              </button>
              <p-overlayPanel #opTrackTime>
                <ng-template pTemplate>
                  <h5>Time Entry</h5>
                  <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
                  <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
                  <p-skeleton width="15rem" height="4rem" styleClass="mb-2"></p-skeleton>
                </ng-template>
              </p-overlayPanel>
            </ng-container>
            <ng-container>
              <button pButton pRipple type="button" icon="pi pi-cog"
                      class="p-button-rounded p-button-text text-white"
                      (click)="opSettings.toggle($event)">
              </button>
              <p-overlayPanel #opSettings styleClass="mt-user-popup">
                <ng-template pTemplate>
                  <p-menu [model]="settingsMenuItems"></p-menu>
                </ng-template>
              </p-overlayPanel>
            </ng-container>
            <button pButton pRipple type="button" icon="pi pi-bell"
                    class="p-button-rounded p-button-text text-white relative">
              <p-badge [value]="'5'" severity="success" class="absolute" [style.top]="'.2rem'" [style.right]="'.4rem'">
              </p-badge>
            </button>
          </ng-container>
          <div class="ml-3 mr-3 mt-app-top-bar-divider"></div>
          <ng-container *ngIf="auth.isSignedIn(); else notSigned">
            <div class="flex align-items-center cursor-pointer h-full" (click)="opUser.toggle($event)">
              <div class="mt-app-top-bar-avatar">
                <i *ngIf="!appConfig.userProfile?.avatar; else avatar" class="pi pi-user"></i>
                <ng-template #avatar>
                  <img [src]="'data:image/jpeg;base64,' + appConfig.userProfile?.avatar"
                       style="border-radius: 50%" width="30">
                </ng-template>
              </div>
              <i class="ml-1 pi pi-angle-down"></i>
            </div>
            <p-overlayPanel #opUser styleClass="mt-user-popup">
              <ng-template pTemplate>
                <div class="border-bottom-1 border-gray-300 px-3 py-2">
                  <div>Hi {{appConfig.userProfile?.firstName}} {{appConfig.userProfile?.lastName}}</div>
                  <div class="mt-1 text-xs text-gray-500">{{auth.user?.email}}</div>
                </div>
                <p-menu [model]="userMenuItems"></p-menu>
              </ng-template>
            </p-overlayPanel>
          </ng-container>
          <ng-template #notSigned>
<!--            <button pButton pRipple type="button" [loading]="auth.isSigningIn"-->
<!--                    class="p-button-text p-button-sm" (click)="auth.signInAsync('')">-->
<!--              <img alt="google" src="assets/images/google.svg" width="20"/>-->
<!--              <span class="ml-2 text-500 text-white">Sign In</span>-->
<!--            </button>-->
          </ng-template>
        </div>
      </div>
      <div class="mt-app-desktop">
        <router-outlet></router-outlet>
      </div>
      <div class="mt-app-bottom-bar">
        <img alt="Mindtrust" src="assets/images/mindtrust.svg" height="26"/>
        <a class="bottom-bar-link" [href]="'/tbd'">Terms of Service</a>
        <a class="bottom-bar-link" [href]="'/tbd'">Privacy Policy</a>
      </div>
    </div>
  `,
  styles: [`
    :host ::ng-deep .p-toast {
      width: 50rem;
    }

    :host ::ng-deep .p-toast .p-toast-message .p-toast-icon-close {
      min-width: 2rem;
      min-height: 2rem;
    }

    ::ng-deep .mt-user-popup.p-overlaypanel .p-overlaypanel-content {
      padding: 0;
    }

    ::ng-deep .mt-user-popup.p-overlaypanel .p-overlaypanel-content .p-menu {
      border: none;
      font-size: .8rem;
    }

    .bottom-bar-link {
      color: #7F8B96;
      font-weight: 500;
      font-size: 12px;
      margin-left: 3rem;
      text-decoration: none;
    }
  `]
})
export class MainPageComponent {
  showAlertBar = true;
  mainMenuItems: MenuItem[];
  userMenuItems: MenuItem[];
  settingsMenuItems: MenuItem[];
  @ViewChild('opUser') userMenuPanel!: OverlayPanel;
  @ViewChild('opSettings') settingsMenuPanel!: OverlayPanel;

  constructor(public auth: AuthService,
              public appConfig: AppConfigService,
              public router: Router) {
    this.mainMenuItems = cloneDeep(MAIN_MENU);
    this.userMenuItems = USER_MENU.map((item) => {
      return {
        ...item,
        ...{
          command: item.id === 'sign-out'
            ? () => {
              this.auth.signOut();
              this.userMenuPanel.hide();
            }
            : () => this.userMenuPanel.hide()
        }
      };
    });
    this.settingsMenuItems = SETTINGS_MENU.map((item) => {
      return {
        ...item,
        ...{
          command: () => this.settingsMenuPanel.hide()
        }
      };
    });
  }

}
