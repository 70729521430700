import {IBudgetComponents, IIdentified, INamed, IPerson, ISimpleEntryEntity} from './common';
import {TTeamMemberEntryEntity} from './team';
import {IDetails} from './details';
import {TQuery, TQueryPredicate} from '../search-api';

export const PROJECT_API_ENDPOINTS = {
  projectCount: '/api/projects/count',
  projects: '/api/projects/:scope',
  project: '/api/projects/:id',
  projectTeam: '/api/projects/:id/team',
  allProjectsDetails: '/api/projects/details/search',
  allClientsDetails: '/api/projects/clients/details/search',
  allAgenciesDetails: '/api/projects/agencies/details/search',
  singleProjectDetails: '/api/projects/:id/details',
  singleProjectPlanning: '/api/projects/:id/planning',
  singleClientDetails: '/api/projects/clients/:id/details',
  singleAgencyDetails: '/api/projects/agencies/:id/details',
  client: '/api/projects/clients/:id',
  agency: '/api/projects/agencies/:id',
};


export const PROJECT_TYPE = [
  'Monthly Flex Subscription',
  'Monthly Fixed Subscription',
  'Service Block Recurring',
  'Service Block',
  'Monthly Pay as you Go',
  'Milestones',
  'Dedicated Resource Fixed',
  'Non-Billable',
  'Non-Billable Internal'
] as const;

export type TProjectType = typeof PROJECT_TYPE[number];

export const PROJECT_STATUS = [
  'Active',
  'Pending',
  'Quoted',
  'Archived'
] as const;

export type TProjectStatus = typeof PROJECT_STATUS[number];

export interface IProjectBase extends IIdentified, INamed, IBudgetComponents {
}

export interface IProject extends IProjectBase {
  clientId: string;
  clientName: string;
  agencyId: string;
  agencyName: string;
  status: TProjectStatus;
  type: TProjectType;
}

// tslint:disable-next-line:no-empty-interface
export interface IProjectAgg extends IProjectBase {
}

export const PROJECT_SCOPES = ['all', 'client', 'agency'] as const;

export type TProjectScope = typeof PROJECT_SCOPES[number];


export type TViewpoint = 'money' | 'time';

export const PROJECT_COST_STAGES = [
  'strategy', 'technology', 'design', 'development', 'production', 'marketing'
] as const;

export type TProjectCostStage = typeof PROJECT_COST_STAGES[number];
export type TProjectCostStageData = { [prop in TProjectCostStage]: number };

export interface IProjectBalance {
  budget: number;
  bonus: number;
  teamTime: number;
  billableExpenses: number;
  remainingBudget: number;
  hasUnsubmittedTimesheet?: boolean;
  asOf: Date;
  remaining: number;
  costStages: { data: TProjectCostStageData, total: number };
}

export type TProjectBalances = { [prop in TViewpoint]: IProjectBalance };

export interface IProjectTeamMember extends IIdentified, IBudgetComponents, IPerson {
  hasUnsubmittedTimesheet?: boolean;
  progress: number;
}

export interface IProjectClient extends IIdentified, INamed {
}

export interface IProjectAgency extends IIdentified, INamed {
}

export const PROJECT_ENTRY_ENTITY_FIELDS: Array<keyof IProject> = ['id', 'name', 'clientName', 'status', 'type'];
export type TProjectEntryEntity = Pick<IProject, typeof PROJECT_ENTRY_ENTITY_FIELDS[number]>;
export type TAllProjectsDetails = IDetails<TProjectEntryEntity>;
export type TAllClientsDetails = IDetails<ISimpleEntryEntity>;
export type TAllAgenciesDetails = IDetails<ISimpleEntryEntity>;
export type TSingleProjectDetails = IDetails<TTeamMemberEntryEntity>;
export type TSingleClientDetails = IDetails<TProjectEntryEntity>;
export type TSingleAgencyDetails = IDetails<ISimpleEntryEntity>;


export interface IProjectEmbedded {
  projectId: string;
  projectName: string;
  projectType: TProjectType;
  projectStatus: TProjectStatus;
  clientName: string;
}

export interface IClientEmbedded {
  clientId: string;
  clientName: string;
}

export function isInstanceOfProjectEmbedded(project: IProjectEmbedded | TProjectEntryEntity): project is IProjectEmbedded {
  return 'projectId' in project;
}
