import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {IEntityEditorParam} from '../common/entity-editor.component';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {MenuItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, debounceTime} from 'rxjs';
import {map} from 'rxjs/operators';
import {find} from 'lodash';
import {HolidayCalendarEntityEditorComponent} from './holiday-calendar-entity-editor.component';
import {IHolidayCalendar} from '../../api/shared/app-domain/schedule';

@Component({
  selector: 'app-holiday-calendar-edit-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-6">
            <div class="mt-page-title">{{editor?.title || 'Edit Holiday Calendar: Unknown'}}</div>
            <div *ngIf="param?.id != null" class="text-gray-700 flex align-items-center">
              <span class="mr-2">Country of origin: {{$any(editor)?.entity?.countryName}}</span>
              <img class="mr-2" [src]="$any(editor)?.entity?.countryFlag" width="24">
              <span>Based on: {{$any(editor)?.entity?.baseName || 'itself'}}</span>
            </div>
          </div>
          <div *ngIf="!editor?.hasBeenDeleted" class="col-6 flex justify-content-end">
            <div class="flex align-items-center">
              <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
              <button *ngIf="this.param?.id != null" pButton pRipple label="Actions"
                      class="p-button-outlined mr-2"
                      icon="pi pi-chevron-down"
                      iconPos="right" (click)="actionsMenu.toggle($event)"></button>
              <p-button icon="pi pi-check"
                        [disabled]="!editor?.canSubmit()"
                        (onClick)="performApply()"
                        [label]="isEditExisting() || param?.id == null ? 'Apply' : 'Duplicate'"></p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3">
      <app-holiday-calendar-entity-editor *ngIf="param" [param]="param"></app-holiday-calendar-entity-editor>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService
  ]
})
export class HolidayCalendarEditPageComponent implements OnInit, OnDestroy {
  param?: IEntityEditorParam;
  @ViewChild(EntityEditorBaseComponent) protected editor?: HolidayCalendarEntityEditorComponent;
  checkActionsMenuPollingId: any = null;
  actionItems: Array<MenuItem> = [
    {
      id: 'edit',
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () =>
        this.router.navigate(['/settings/holiday-calendars/edit', this.param?.id])
    },
    {
      id: 'duplicate',
      label: 'Duplicate',
      icon: 'pi pi-clone',
      command: () =>
        this.router.navigate(['/settings/holiday-calendars/edit', this.param?.id], {queryParams: {duplicate: 'true'}})
    },
    {
      id: 'archive',
      label: 'Archive',
      icon: 'pi pi-server',
      command: () => this.editor?.archiveThis()
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: 'pi pi-trash',
      styleClass: 'mt-alert-menuitem',
      command: () => this.editor?.deleteThis()
    }
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {

    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(
        map(results => ({params: results[0], query: results[1]})), debounceTime(0)
      )
      .subscribe(results => {
        if (!!results.params['id']) {
          this.param = {
            id: results.params['id'] === 'new' ? null : results.params['id'],
            isDuplicate: results.query['duplicate'] === 'true'
          };
          this.processActionMenu();
        }
      });
  }

  ngOnInit(): void {
  }

  private processActionMenu(): void {
    find(this.actionItems, {id: 'edit'})!.disabled = !this.param?.isDuplicate;
    find(this.actionItems, {id: 'archive'})!.disabled = !this.editor?.canArchiveThis();
    find(this.actionItems, {id: 'duplicate'})!.disabled = this.param?.isDuplicate;
    find(this.actionItems, {id: 'delete'})!.disabled = this.param?.isDuplicate;
    this.checkActionsMenuPollingId = setInterval(() => {
      find(this.actionItems, {id: 'archive'})!.disabled = !this.editor?.canArchiveThis();
    }, 1000);
  }

  isEditExisting(): boolean {
    return !!this.param?.id && !!!this.param?.isDuplicate;
  }

  protected performApply(): void {
    if (this.editor?.canPerformApply()) {
      this.editor.apply((result: IHolidayCalendar) => {
        if (this.param?.isDuplicate) {
          this.router.navigate(['/settings/holiday-calendars/edit', result.id]);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.checkActionsMenuPollingId != null) {
      clearInterval(this.checkActionsMenuPollingId);
    }
  }
}
