import {Component, forwardRef, Input, SimpleChanges} from '@angular/core';
import {EntityFormControlBaseComponent} from '../../common/entity-form-control-base.component';
import {ITimeOff, TTimeOffSave} from '../../../api/shared/app-domain/time';
import {EntityPropertyOptionsService} from '../../common/entity-property-options.service';
import {EntityEditorComponent} from '../../common/entity-editor.component';
import {IIdentified} from '../../../api/shared/app-domain/common';
import {AppResourceService} from '../../../app.resource.service';
import {SubscriptionsService} from '../../../common/services/subscriptions.service';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {TControlsOf} from '../../../common/types';
import {EntityEditorBaseComponent} from '../../common/entity-editor-base.component';
import {currentDate} from '../../../common/util/util';

@Component({
  selector: 'app-time-off-request-form-control',
  template: `
      <ng-container [ngSwitch]="name">
          <app-form-control-wrapper *ngSwitchCase="'startDate'" dataType="date"
                                    [controlName]="name"></app-form-control-wrapper>
          <app-form-control-wrapper *ngSwitchCase="'endDate'" dataType="date"
                                    [controlName]="name"></app-form-control-wrapper>
          <app-form-control-wrapper *ngSwitchCase="'useHalfDay'" [controlName]="name" label="Use Half of a Day?"
                                    dataType="boolean"></app-form-control-wrapper>
          <app-form-control-wrapper *ngSwitchCase="'details'" [controlName]="name"
                                    dataType="text"></app-form-control-wrapper>
      </ng-container>
  `
})
export class TimeOffRequestFormControl extends EntityFormControlBaseComponent<ITimeOff> {
  constructor(propertyOptions: EntityPropertyOptionsService) {
    super(propertyOptions);
  }
}


@Component({
  selector: 'app-time-off-request-editor',
  template: `
      <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
      <div #container>
          <form *ngIf="form" #frm [formGroup]="form">
              <div class="formgrid grid">
                  <div class="field col-6 p-fluid">
                      <app-time-off-request-form-control name="startDate"></app-time-off-request-form-control>
                  </div>
                  <div class="field col-6 p-fluid">
                      <app-time-off-request-form-control name="endDate"></app-time-off-request-form-control>
                  </div>
                  <div class="field col-12 p-fluid">
                      <app-time-off-request-form-control name="useHalfDay"></app-time-off-request-form-control>
                  </div>
                  <div class="field col-12 p-fluid">
                      <app-time-off-request-form-control name="details"></app-time-off-request-form-control>
                  </div>
              </div>
          </form>
          <div *ngIf="getValue('startDate') && getValue('endDate')">
              <span class="text-gray-700 text-500">Number of days: </span>
              <span class="text-gray-600 font-bold">{{numberOfDays}}</span>
          </div>

      </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => TimeOffRequestEditorComponent)},
    SubscriptionsService
  ]
})
export class TimeOffRequestEditorComponent extends EntityEditorComponent<TTimeOffSave, ITimeOff> {
  @Input() teamMember?: IIdentified;

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              fb: FormBuilder) {
    super(fb);
    this.entityName = 'Time Off Request';
  }

  override ngOnChanges(changes: SimpleChanges): void {
    this.api = !!this.teamMember ? {
      getEntity: (id: string) => this.resource.getTimeOffRequest(this.teamMember!.id, id),
      createEntity: (data: TTimeOffSave) => this.resource.createTimeOffRequest(this.teamMember!.id, data),
      updateEntity: (id: string, data: TTimeOffSave) => this.resource.updateTimeOffRequest(this.teamMember!.id, id, data),
    } : {
      getEntity: this.resource.getTimeOff.bind(this.resource),
      updateEntity: this.resource.updateTimeOff.bind(this.resource),
    }
    super.ngOnChanges(changes);
  }

  override getName(): string | null {
    return this.entity.startDate != null ? new DatePipe('en-US').transform(this.entity.startDate, 'EEE, MMM dd, y') : '';
  }

  override buildForm(): void {
    const endDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      let isValid = true;
      if (this.getValue('startDate') != null && control.value != null) {
        isValid = this.getValue('startDate') <= control.value;
      }
      return isValid ? null : {'End Date should be equal to or greater than Start Date': true};
    };
    this.form = this.fb.group<TControlsOf<TTimeOffSave>>({
      startDate: new FormControl(this.isNew() ? currentDate() : this.entity.startDate, [Validators.required]) as any,
      endDate: new FormControl(this.isNew() ? currentDate() : this.entity.endDate, [Validators.required, endDateValidator]) as any,
      useHalfDay: new FormControl(this.isNew() ? false : this.entity.useHalfDay, []),
      details: new FormControl(this.entity.details, [Validators.required]),
    });

    this.subscriptions
      .add(this.getControl('startDate')!.valueChanges.subscribe((val) => this.processStartDate(val)))
      .add(this.getControl('endDate')!.valueChanges.subscribe((val) => this.checkHalfDay()));
    this.checkHalfDay();
  }

  processStartDate(startDate: Date | null): void {
   if (startDate && this.getValue('endDate') && startDate > this.getValue('endDate')) {
    this.setValueAndMark('endDate', startDate);
   }
   this.checkHalfDay();
  }

  checkHalfDay(): void {
    if (this.numberOfDays === 1 || this.numberOfDays === 0.5) {
      this.setEnable('useHalfDay', true, false);
    } else {
      this.setEnable('useHalfDay', false, false);
      this.setValueAndMark('useHalfDay', false);
    }
  }

  get numberOfDays(): number {
    if (this.getValue('startDate') == null || this.getValue('endDate') == null) {
      return 0;
    }
    let n = (this.getValue('endDate').getTime() - this.getValue('startDate').getTime()) / (1000 * 3600 * 24) + 1;
    if (n === 1 && this.getValue('useHalfDay')) {
      return 0.5
    }
    return n;
  }
}
