import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';
import {MessageService} from 'primeng/api';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BypassHttpService {
  private httpClient: HttpClient;

  constructor(private handler: HttpBackend,
              private messageService: MessageService) {
    this.httpClient = new HttpClient(handler);
  }

  request<T, B>(method: 'get' | 'post' | 'patch' | 'delete' | 'put', url: string, options: {
    body?: B;
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
  }): Observable<T> {
    return this.httpClient.request<T>(method, `${environment.apiUrl}${url}`, {...options, ...{responseType: 'json'}})
      .pipe(
        catchError((response) => {
          if (response instanceof HttpErrorResponse) {
            const httpErrorResponse = response as HttpErrorResponse;
            const detail = httpErrorResponse.error?.message || httpErrorResponse.message;
            this.messageService.add({
              severity: 'error',
              summary: 'Response Error',
              detail: detail,
              life: 10000
            })
          }
          return throwError(response);
        })
      );
  }
}

