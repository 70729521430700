import { Injectable } from '@angular/core';
import { finalize, Observable, of, share } from 'rxjs';
import { IHsObjectType } from '../../api/shared/dev-tools/hs-api';
import { AppResourceService } from '../../app.resource.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HsService {
  private cachedObjectTypes: Array<IHsObjectType> | null = null;
  private cachedObservable: Observable<Array<IHsObjectType>> | null = null;

  constructor(private resource: AppResourceService) {
  }

  getObjectTypes(): Observable<Array<IHsObjectType>> {
    let observable: Observable<Array<IHsObjectType>>;
    if (!!this.cachedObjectTypes) {
      observable = of(this.cachedObjectTypes);
    } else if (this.cachedObservable) {
      observable = this.cachedObservable;
    } else {
      this.cachedObservable = this.resource.hsGetObjectTypes()
        .pipe(
          tap((response) => this.cachedObjectTypes = response),
          share(),
          finalize(() => this.cachedObservable = null)
        );
      observable = this.cachedObservable;
    }
    return observable;
  }
}
