import {Component, OnInit} from '@angular/core';
import {AppResourceService} from '../../../app.resource.service';
import {IGraphData, IGraphLink, IGraphNode} from '../../../common/components/relationship-graph.component';
import {finalize, forkJoin} from 'rxjs';
import {startCase} from 'lodash';
import {IDbTable} from '../../../api/shared/dev-tools/db-api';
import {DbNormalizeNamePipe} from './db-normalize-name.pipe';

@Component({
  selector: 'app-db-relationship-diagram-page',
  template: `
    <app-spinnerizer [active]="loading"
                     [invertSpinner]="false"
                     [invertText]="true"
                     text="Loading schema..."
                     [target]="container">
    </app-spinnerizer>
    <div #container class="svg-container shadow-1 relative">
      <app-relationship-graph [data]="data" (selectNode)="onSelectNode($event)"></app-relationship-graph>
      <div *ngIf="selectedTable" class="absolute" [style]="{top: 0, left: 0, 'min-width': '400px'}">
        <p-accordion>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="flex justify-content-between w-full">
                <div>{{selectedTable.name | dbNormalizeName}}</div>
                <div class="mr-2">
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <div class="border-top-1 border-gray-300 pt-1">
                <div *ngFor="let col of selectedTable.columns" class="grid">
                  <div class="col-7 mt-overflow-ellipsis prop-label" appTooltipOnOverflow>
                    {{col.name}} {{col.isRequired && !col.constraints?.PK && !col.constraints?.FK ? '*' : ''}}
                    {{col.constraints?.PK ? '(PK)' : ''}}{{col.constraints?.FK ? '(FK)' : ''}}
                  </div>
                  <div class="col-5 mt-overflow-ellipsis prop-type" appTooltipOnOverflow>
                    {{col.type.name}}
                    <ng-container *ngFor="let d of col.arrayDimensions | arrayOf">[]</ng-container>
                    {{col.type.category === 'custom' ? '(' + col.type.dbType + ')' : ''}}
                  </div>
                </div>
              </div>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  `,
  styles: [`
    .svg-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 148px);
      background-color: #FFF;
    }

    :host ::ng-deep .p-accordion .p-accordion-header .p-accordion-header-link {
      padding: .5rem 1.25rem;
    }

    .prop-label {
      font-size: .9rem;
    }

    .prop-type {
      color: var(--gray-600);
      font-size: .8rem;
    }
  `]
})
export class DbRelationshipDiagramPageComponent implements OnInit {
  data!: IGraphData;
  tables: { [tableName: string]: IDbTable } = {};
  selectedTable: IDbTable | null = null;
  loading = false;

  constructor(private resource: AppResourceService) {
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin([this.resource.dbGetSchema(), this.resource.dbGetRelationships()])
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((results) => {
        console.log(results[0]);
        for (const table of results[0].tables) {
          this.tables[table.name] = table;
        }
        const nodes: Array<IGraphNode> = results[0].tables
          .map((t) => ({id: t.name, name: this.normalizeName(t.name)}));
        const links: Array<IGraphLink> = results[1]
          .map((r) => ({target: r.toTable, source: r.fromTable}));
        this.data = {
          nodes,
          links
        };
      });
  }

  onSelectNode(ev: { event: any; id: string }): void {
    if (ev.event.button === 0) {
      if (ev.event.ctrlKey) {
        // TODO: navigate to db browser
      } else {
        this.selectedTable = this.tables[ev.id];
      }
    }
  }

  normalizeName(name: string): string {
    return new DbNormalizeNamePipe().transform(name);
  }
}
