import {Injectable} from '@angular/core';
import {dateToISOString} from '../util/util';
import {forEach} from 'lodash';
import {DateTime} from 'luxon';

@Injectable()
export class HttpDataConverterService {
  public static inputDateRegExs = [
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.?\d*))(?:Z|(\+|-)([\d|:]*))?$/,
    /^(\d{4})-(\d{2})-(\d{2})$/
  ];

  static convertInputDate(dateAsStr: string): Date {
    return DateTime.fromISO(dateAsStr).toJSDate();
  }

  static convertOutputDate(date: Date): string {
    return dateToISOString(date);
  }

  static isStringADate(value: string): Date | null {
    let result: Date | null = null;
    if (!value) {
      return result;
    }
    forEach(HttpDataConverterService.inputDateRegExs, (regEx) => {
      const match = value.match(regEx);
      if (match) {
        result = HttpDataConverterService.convertInputDate(match[0]);
        return false;
      } else {
        return true;
      }
    });
    return result;
  }

  static traverseInput(data: any): any {
    if (typeof data !== 'object') {
      return data;
    }

    for (const key in data) {
      if (!data.hasOwnProperty(key)) {
        continue;
      }
      const value = data[key];
      if (typeof value === 'string') {
        const date = HttpDataConverterService.isStringADate(value);
        if (date) {
          data[key] = date;
        }
      } else if (typeof value === 'object') {
        HttpDataConverterService.traverseInput(value);
      }
    }
  }

  static traverseOutput(data: any): any {
    if (typeof data !== 'object') {
      return data;
    }

    for (const key in data) {
      if (!data.hasOwnProperty(key)) {
        continue;
      }
      const value = data[key];
      if (value instanceof Date) {
        data[key] = HttpDataConverterService.convertOutputDate(value);
      } else if (typeof value === 'object') {
        HttpDataConverterService.traverseOutput(value);
      }
    }
  }

  in(data: any): any {
    if (data) {
      HttpDataConverterService.traverseInput(data);
    }
    return data;
  }

  out(data: any): any {
    if (data) {
      HttpDataConverterService.traverseOutput(data);
    }
    return data;
  }

}

