import {Component} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  template: ''
})
export abstract class EntityEditorBaseComponent<T, F extends { [K in keyof F]: AbstractControl<any>; } = any> {
  form?: FormGroup<F> | null;
  loading = false;
  protected _title!: string;
  protected applyAttempted = false;

  get title(): string {
    return this._title;
  }


  isValid(): boolean {
    return !!this.form?.valid;
  }

  canSubmit(): boolean {
    if (!this.form) {
      return false;
    }
    if (!this.applyAttempted) {
      return this.form.dirty;
    } else {
      return this.isValid();
    }
  }

  canPerformApply(): boolean {
    if (!this.form) {
      return false;
    }
    this.touchAllFormFields(this.form);
    this.applyAttempted = true;
    return this.isValid() && this.form.dirty;
  }


  getValue(field: keyof F): any {
    if (this.form?.controls == null) {
      return undefined;
    }
    return (this.form?.controls as F)[field].value;
  }

  getControl(field: keyof F): AbstractControl | undefined {
    return (this.form?.controls as F)[field];
  }

  setValueAndMark(field: keyof F, value: any): void {
    if (this.form) {
      (this.form.controls as F)[field].setValue(value);
      (this.form.controls as F)[field].markAsDirty();
      (this.form.controls as F)[field].markAsTouched();
    }
  }

  setEnable(field: keyof F, isEnable: boolean, setRequired = true): void {
    if (this.form) {
      if (isEnable) {
        (this.form.controls as F)[field].enable();
        if (setRequired) {
          (this.form.controls as F)[field].setValidators([Validators.required]);
        }
      } else {
        (this.form.controls as F)[field].disable();
        if (setRequired) {
          (this.form.controls as F)[field].removeValidators([Validators.required]);
        }
      }
      if (setRequired) {
        (this.form.controls as F)[field].updateValueAndValidity();
      }
    }
  }

  abstract apply(onApply: (result: T) => void): void;

  touchAllFormFields(formGroup: FormGroup, untouch = false): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        if (untouch) {
          control.markAsUntouched({onlySelf: true});
        } else {
          control.markAsTouched({onlySelf: true});
        }
      } else if (control instanceof FormGroup) {
        this.touchAllFormFields(control);
      }
    });
  }

  getLink(): string | null {
    return null;
  }


}
