import {IIdentified, INamed, ITimestamped, TCommonStatus} from './common';

export const SETTINGS_API_ENDPOINTS = {
  departments: '/api/settings/departments',
  department: '/api/settings/departments/:id',

  roles: '/api/settings/roles',
  role: '/api/settings/roles/:id',

  searchSources: '/api/settings/search-sources',

  searchWorkweeks: '/api/settings/workweeks/search',
  workweeks: '/api/settings/workweeks',

  expenseCategories: '/api/settings/expense-categories',
  expenseCategory: '/api/settings/expense-category/:id',

  paidTimeOffs: '/api/settings/paid-time-offs',
  searchPaidTimeOffs: '/api/settings/paid-time-offs/search',
  paidTimeOff: '/api/settings/paid-time-offs/:id',

};


export interface IDepartment extends IIdentified, INamed {
  status: TCommonStatus;
}

export type TDepartmentSave = Omit<IDepartment, 'id'>;

export interface IRole extends IIdentified, INamed {
  status: TCommonStatus;
}

export type TRoleSave = Omit<IRole, 'id'>;

export interface ISearchSource extends IIdentified, INamed {
  urlDomainLinkage: string;
  url: string;
}

export type TSearchSourceSave = Omit<ISearchSource, 'id'>;

export interface IWorkweek extends IIdentified, INamed, ITimestamped {
  hours: Array<string>;
  startDate: Date | null;
}

export type TWorkweekSave = Omit<IWorkweek, 'createdAt' | 'updatedAt'>;


export interface IExpenseCategory extends IIdentified, INamed {
  isBillable: boolean;
  accountingCode: string;
  status: TCommonStatus;
}

export type TExpenseCategorySave = Omit<IExpenseCategory, 'id'>;

export interface ICategoryEmbedded {
  categoryId: string;
  categoryName: string;
}

export interface IPaidTimeOff extends IIdentified, INamed, ITimestamped {
  days: number | null;
  status: TCommonStatus;
  isCustom?: boolean;
}

export type TPaidTimeOffSave = Omit<IPaidTimeOff, 'id' | 'createdAt' | 'updatedAt' | 'isCustom' | 'isCustom'>;
