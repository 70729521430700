import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EntityEditorBaseComponent} from './entity-editor-base.component';
import {ServiceLocator} from '../../common/util/util';
import {finalize, Observable, of} from 'rxjs';
import {ISearchRequest, ISearchResponse, TQueryExpression, TWithId} from '../../api/shared/search-api';
import {map} from 'rxjs/operators';
import {IIdentified, isInstanceOfDraftable, TCommonStatus} from '../../api/shared/app-domain/common';
import {TControlsOf} from '../../common/types';
import {forEach} from 'lodash';
import {EntityResource, IEntityResource} from '../../app.resource.service';


export interface IEntityEditorParam {
  id: string;
  isDuplicate?: boolean;
  data?: any;
}


@Component({
  template: ''
})
export abstract class EntityEditorComponent<S extends {}, T extends S & IIdentified> extends EntityEditorBaseComponent<T, TControlsOf<S>>
  implements OnInit, OnDestroy, OnChanges {
  @Input() param!: IEntityEditorParam;
  protected entityName!: string;
  protected entity!: T;
  protected saveEntity!: T;
  protected isDraftable = false;
  significantDraftFields: Array<keyof T> = [];

  hasBeenDeleted = false;


  api: Partial<IEntityResource<S, T>> = {} = {};

  protected constructor(protected fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form) {
      this.form = null;
    }
    if (!this.isNew()) {
      this.load();
    } else {
      this.newEntity();
    }
  }

  load(): void {
    this.doLoad(this.param.id!);
  }

  doLoad(id: string): void {
    if (this.api.getEntity) {
      this.loading = true;
      this.api.getEntity(id)
        .pipe(
          finalize(() => this.loading = false)
        )
        .subscribe((entity) => {
          this.setEntity(entity);
          this.onLoad();
        });
    }
  }

  onLoad(): void {
  }

  getEntityName(): string {
    return this.entityName;
  }

  override apply(onApply: (result: T) => void): void {
    this.loading = true;
    const data = this.getData();
    if (this.param.isDuplicate) {
      // delete data['id'];
    }
    const createOrUpdate = this.isNew() || this.param.isDuplicate
      ? (this.api.createEntity ? this.api.createEntity(data) : undefined)
      : (this.api.updateEntity ? this.api.updateEntity(this.param.id!, data) : undefined);
    if (createOrUpdate) {
      createOrUpdate
        .pipe(
          finalize(() => this.loading = false)
        )
        .subscribe((result) => {
          this.onApplyDone(result);
          onApply(result);
        });
    }
  }

  protected getData(): S {
    return this.form!.getRawValue() as S;
  }

  protected setEntity(entity: T): void {
    this.entity = entity;
    this.saveEntity = {...this.entity};
    this.buildForm();
    // this.removeValidatorsIfDraft();
    setTimeout(() => this.setTitle());
  }

  protected newEntity(): void {
    this.setEntity({} as T);
  }

  abstract buildForm(): void;

  isNew(): boolean {
    return this.param.id === null;
  }

  protected setTitle(): void {
    this._title = `${this.isNew() ? 'New ' : (this.param.isDuplicate ? 'Duplicate ' : 'Edit ')}${this.entityName}${this.isNew() ? '' : ': ' + this.getName() ?? '...'}`;
    this._title += this.isDraft() ? ' (Draft)' : '';
  }

  abstract getName(): string | null;

  override isValid(): boolean {
    if (this.isDraftable && (this.isNew() || this.isDraft())) {
      let invalid = false;
      const doCheck = (formGroup: FormGroup) => {
        forEach(Object.keys(formGroup.controls), (field) => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
            if (this.significantDraftFields.includes(field as any) && control.invalid) {
              invalid = true;
              return false;
            }
          } else if (control instanceof FormGroup) {
            doCheck(control);
          }
          return true;
        });
      };
      doCheck(this.form!);
      return !invalid;
    }
    return super.isValid();
  }

  override canSubmit(): boolean {
    if (!this.entity) {
      return false;
    }
    return super.canSubmit();
  }

  override canPerformApply(): boolean {
    if (!this.entity) {
      return false;
    }
    return super.canPerformApply();
  }

  onApplyDone(entity: T): void {
    ServiceLocator.message({
      severity: 'success',
      summary: 'Success',
      detail: `${this.entityName} has been ${this.isNew() ? 'created' : (this.param.isDuplicate ? 'duplicated' : 'updated')}`
    });
    this.entity = entity;
    this.setTitle();
    this.applyAttempted = false;
    if (this.form) {
      this.touchAllFormFields(this.form!, true);
      this.form!.markAsPristine();
    }
  }

  getEntity(): T {
    return this.entity;
  }

  ngOnDestroy(): void {
  }

  getCheckExists(
    api: (searchRequest: ISearchRequest) => Observable<ISearchResponse<T>>, field: keyof T
  ): (value: any) => Observable<boolean> {
    return (value: any) => {
      if (value == null) {
        return of(false);
      }
      const request: ISearchRequest = {
        limit: 1,
        offset: 0,
        query: {
          logical: 'and',
          predicates: [
            {field: field as string, operator: 'equals', value}
          ]
        }
      };
      if (!this.isNew() && !this.param.isDuplicate) {
        (request.query as TQueryExpression).predicates.push({field: 'id', operator: 'notEquals', value: this.param.id!})
      }
      return api(request).pipe(
        map((response) => response.total > 0)
      );
    }
  }

  canArchiveThis(): boolean {
    return !!this.api.archiveEntities &&
      this.entity && !this.isNew() && !this.param.isDuplicate && (this.entity as any)?.status !== 'Archived';
  }

  archiveThis(): void {
    if (!!!this.api.archiveEntities ||
      !this.entity || this.isNew() || this.param.isDuplicate || (this.entity as any).status === 'Archived') {
      return;
    }

    const data: Array<TWithId<T>> // TODO: remove when get rid of old this.api usage
     = (this.api instanceof EntityResource
      ? [{id: this.param.id}]
      : [{
      id: this.param.id,
      status: 'Archived'
    }]) as Array<TWithId<any>>;

    this.api.archiveEntities(data as any)
      .subscribe((response) => {
        (this.entity as any).status = 'Archived';
        this.onArchived();
        ServiceLocator.message({
          severity: 'success',
          summary: 'Success',
          detail: `${this.entityName} ${this.getName()} has been archived`
        });
      });
  }

  onArchived(): void {
    this.form?.get('status')!.setValue((this.entity as any).status, {onlySelf: true, emitEvent: false});
  }

  deleteThis(): void {
    if (!!!this.api.deleteEntities || !this.entity || this.isNew() || this.param.isDuplicate) {
      return;
    }
    const name = this.getName();
    ServiceLocator.confirm({
      key: 'g-dialog',
      header: 'Delete Confirmation',
      message: `Are you sure you want to delete ${this.getName()}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.api.deleteEntities!([(this.entity as any).id])
          .subscribe(() => {
            this.hasBeenDeleted = true;
            this.form = null;
            ServiceLocator.message({
              severity: 'success',
              summary: 'Success',
              detail: `${this.entityName} ${name} has been deleted`
            });
          });
      }
    });
  }

  isDraft(): boolean {
    return this.isDraftable && isInstanceOfDraftable(this.entity) && this.entity.isDraft;
  }

  protected removeValidatorsIfDraft(): void {
    if (this.isDraftable && (this.isNew() || this.isDraft())) {
      const doRemove = (formGroup: FormGroup) => {
        Object.keys(formGroup.controls).forEach((field) => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
            if (!this.significantDraftFields.includes(field as any)) {
              control.removeValidators([Validators.required]);
            }
          } else if (control instanceof FormGroup) {
            doRemove(control);
          }
        });
      };
      doRemove(this.form!);
    }
  }
}
