import {Component, ViewChild} from '@angular/core';
import {APPROVAL_STATES, ILens, IPeriod, TApprovalsState} from '../../../api/shared/app-domain/common';
import {getApprovalsTabMenu} from '../common';
import {getCurrentPeriodValue} from '../../../common/components/period-chooser.component';
import {TQueryExpression} from '../../../api/shared/search-api';
import {ExpensesTableComponent} from './expenses-table.component';
import {MenuItem, TreeNode} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {ServiceLocator} from '../../../common/util/util';
import {IViewAsItem} from '../../../api/shared/user-api';
import {AppConfigService} from '../../../app-config.service';
import {AppResourceService} from '../../../app.resource.service';
import {IResponseRequest} from '../../../common/components/pageable-table.component';
import {capitalize} from 'lodash';
import {TeamMembersFilterComponent} from '../../team/team-members-filter.component';
import {modeLensesToTreeNodes} from '../../common/util';
import {EXPENSE_APPROVAL_MODE_LENSES} from '../../../api/shared/app-domain/expenses';

@Component({
  template: `
      <div class="mt-page-header">
          <div class="mt-page-header-content">
              <div class="grid grid-nogutter">
                  <div class="col-4">
                      <div class="flex align-items-baseline">
                          <div class="mt-page-title">Expense {{isApprovalMode ? 'Approvals' : 'Reports'}}</div>
                          <div class="ml-3 text-2xl font-bold">{{total | currency:'USD':'symbol':'1.2-2'}}</div>
                          <div class="text-gray-700 ml-3">
                              {{tmf.currentStatusesAsText}}
                          </div>
                      </div>
                  </div>
                  <div class="col-4 flex justify-content-center align-items-center">
                      <p-menubar *ngIf="isApprovalMode" class="mt-page-menu-bar" [model]="tabMenuItems"></p-menubar>
                  </div>
                  <div class="col-4 flex justify-content-end align-items-center">
                      <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
                      <button pButton pRipple label="Actions"
                              class="p-button-outlined ml-4"
                              [style]="{width: '115px'}"
                              icon="pi pi-chevron-down"
                              iconPos="right" (click)="actionsMenu.toggle($event)"></button>
                  </div>
              </div>
          </div>
          <div class="mt-page-header-content">
              <div class="grid grid-nogutter">
                  <div class="col-7 flex align-items-center">
                      <app-view-as-chooser #viewAsChooser [(ngModel)]="viewAsItem">
                      </app-view-as-chooser>
                      <span *ngIf="isApprovalMode" class="ml-2 p-float-label">
                        <app-tree-select [(ngModel)]="lens" [style]="{width: '250px'}"
                                         [filter]="true"
                                         [options]="lensOptions">
                        </app-tree-select>
                        <label>Lens</label>
                      </span>
                      <app-team-members-filter #tmf [areFieldsEmbedded]="true"
                                               (applyFilter)="applyFilters()"></app-team-members-filter>
                  </div>
                  <div class="col-5 flex justify-content-end">
                      <app-period-chooser [(ngModel)]="period"
                                          [unitFilter]="['Day', 'Week', 'Month', 'Quarter', 'Year', 'Custom']"></app-period-chooser>
                  </div>
              </div>
          </div>
      </div>
      <app-view-as-alert [viewAsItem]="viewAsItem" [viewAsChooser]="viewAsChooser"></app-view-as-alert>
      <div class="mt-3">
          <div class="grid">
              <div class="col-3" *ngFor="let i of 4 | arrayOf">
                  <div class="shadow-1"
                       style="background: #FFF; border: 1px solid var(--surface-300); height: 10rem; font-size: 12px; padding: 2rem; color: var(--gray-500)">
                      TBD
                  </div>
              </div>
          </div>
      </div>
      <div class="mt-2 shadow-1 bg-white p-3 pb-2">
          <app-expense-reports-or-approvals-table *appRecreateViewKey="tab" [isApprovalMode]="isApprovalMode" [period]="period"
                                     [query]="query" [lens]="lens"
                                     [state]="tab" (onDataFetched)="onDataFetched($event)"></app-expense-reports-or-approvals-table>
      </div>
  `,
  styles: [`
  `]
})
export class ExpenseReportsOrApprovalsPageComponent {
  tab: TApprovalsState | null = null;
  tabMenuItems: Array<MenuItem> = [];
  period: IPeriod = getCurrentPeriodValue('Month');
  query: TQueryExpression | null = null;
  viewAsItem: IViewAsItem | null = null;
  total = 0;
  isApprovalMode = false;
  lensOptions: Array<TreeNode> = [];
  lens?: ILens;

  @ViewChild(ExpensesTableComponent) table!: ExpensesTableComponent;
  @ViewChild(TeamMembersFilterComponent) teamMemberFilter?: TeamMembersFilterComponent;

  actionItems: Array<MenuItem> = [
    {label: 'Sync Accounting', command: this.tbd},
    {label: 'Sync Timekeeping', command: this.tbd}
  ];

  constructor(public activatedRoute: ActivatedRoute,
              public router: Router,
              public appConfig: AppConfigService,
              private resource: AppResourceService) {
    this.viewAsItem = this.appConfig.viewAsMe; // TODO: move to component
    this.activatedRoute.data.subscribe((data) => {
      this.isApprovalMode = !!data['isApprovalMode'];
      if (this.isApprovalMode) {
        this.tabMenuItems = getApprovalsTabMenu('/expense-approvals');
        this.lensOptions = modeLensesToTreeNodes(EXPENSE_APPROVAL_MODE_LENSES);
        this.lens = this.lensOptions[0].children![0].data;
      }
    });
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (this.isApprovalMode) {
        const tab = params.get('tab') as TApprovalsState | 'All';
        if (!!!tab || ![...APPROVAL_STATES, 'All'].includes(tab)) {
          this.router.navigate([],
            {relativeTo: this.activatedRoute, queryParams: {tab: 'All'}, queryParamsHandling: 'merge'});
        } else {
          this.tab = tab === 'All' ? null : tab;
        }
        this.applyFilters();
      }
    });
  }

  applyFilters(): void {
    if (!!this.tab || this.teamMemberFilter?.hasFilter()) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: this.teamMemberFilter?.hasFilter() ? this.teamMemberFilter!.query!.predicates : []
      };
      if (!!this.tab) {
        query.predicates.push({
          field: 'state',
          operator: 'equals',
          value: this.tab
        });
      }
      this.query = query;
    } else {
      this.query = null;
    }
  }

  onDataFetched(respReq: IResponseRequest<any>): void {
    this.resource.getExpenseReportsTotal(respReq.request).subscribe((total) => this.total = total);
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }
}
