import {ExistingProvider, forwardRef, Injector, SimpleChanges, Type} from '@angular/core';
import {ConfirmationService, Message, MessageService} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {Observable, of, switchMap, timer} from 'rxjs';
import {IDownloadRefResponse} from '../../api/shared/search-api';
import {AbstractControl, AsyncValidatorFn, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn} from '@angular/forms';
import {catchError, map} from 'rxjs/operators';
import {Confirmation} from 'primeng/api/confirmation';
import {some} from 'lodash';
import {HttpClient} from '@angular/common/http';
import {IAttachment} from '../../api/shared/app-domain/common';

export const HTTP_URL_REGEX = /https?:\/\/(www\.)?(?<domain>[-a-zA-Z0-9@:%._\+~#=]{2,256})\.([a-z]{2,6}){1}/;


export class ServiceLocator {
  static injector: Injector;

  static get<T>(clazz: Type<T>): T {
    return ServiceLocator.injector.get(clazz);
  }

  static message(message: Message): void {
    setTimeout(() => {
      ServiceLocator.get(MessageService).add(message);
    });
  }

  static confirm(confirmation: Confirmation): void {
    setTimeout(() => {
      ServiceLocator.get(ConfirmationService).confirm(confirmation);
    });
  }
}

export function getFirstDayDate(date = new Date()): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function decodeJwtPayload<X = {}>(token: string): X {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}

export function unixTime(date: Date = new Date()): number {
  return Math.floor(date.getTime() / 1000);
}


export function dateToISOString(date: Date, fmt = 'yyyy-MM-dd'): string {
  if (date.getMinutes() === 0 && date.getSeconds() === 0 && date.getMilliseconds() === 0
    || date.getMinutes() === 59 && date.getSeconds() === 59 && date.getMilliseconds() === 999) {
    return new DatePipe('en-US').transform(date, fmt)!;
  }
  return date.toISOString();
}

export function currentDate(): Date {
  const d = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0);
}

export function stringToColor(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    color += ('00' + ((hash >> (i * 8)) & 0xFF).toString(16)).substr(-2);
  }
  return color.toUpperCase();
}

export function hexToRgba(hex: string, alpha = 1): string {
  const [r, g, b] = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
}

export function clearObject(obj: any): any {
  for (const p in obj) {
    delete obj[p];
  }
  return obj;
}


export async function copyToClipboard(text: string): Promise<void> {
  await navigator.clipboard.writeText(text);
}


export function downloadRef(rest: Observable<IDownloadRefResponse>, fileName?: string): void {
  rest.subscribe((response) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = response.url;
    downloadLink.setAttribute('download', fileName ?? '');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  });
}


export function downloadAttachment(attachment: IAttachment): void {
  const http = ServiceLocator.injector.get(HttpClient);
  http.get(new URL(attachment.url).pathname, {responseType: 'blob'})
    .subscribe((response) => {
      const blob = new Blob([response]);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.setAttribute('download', attachment.name);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    })
}

export function accessorProvider(type: Type<any>): ExistingProvider {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true
  }
}

export function inheritanceProvider(fromBase: any, toClass: Type<any>): ExistingProvider {
  return   {provide: fromBase, useExisting: forwardRef(() => toClass)};
}

export const urlValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let isValid = true;
  try {
    if (control.value) {
      const url = new URL(control.value);
      if (!url.host || !url.origin || !url.protocol) {
        isValid = false;
      }
    }
  } catch {
    isValid = false;
  }
  return isValid ? null : {invalidUrl: true};
};

export function existsAsyncValidator(checkFn: (value: any) => Observable<boolean>): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return timer(500).pipe(
      switchMap(() => {
        return checkFn(control.value)
          .pipe(
            map((response) => {
              return response ? {exists: true} : null;
            }),
            catchError(() => of(null)) // TODO: error?
          );
      })
    )
  }
}


export function getUrlDomain(url: string): string | undefined {
  return (url || '').match(HTTP_URL_REGEX)?.groups?.['domain'];
}


export function murmurHash(str: string, seed: number): number {
  let i = 0;
  let l = str.length;
  let h = seed ^ l;
  let k: number;

  while (l >= 4) {
    k =
      ((str.charCodeAt(i) & 0xff)) |
      ((str.charCodeAt(++i) & 0xff) << 8) |
      ((str.charCodeAt(++i) & 0xff) << 16) |
      ((str.charCodeAt(++i) & 0xff) << 24);

    k = (((k & 0xffff) * 0x5bd1e995) + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16));
    k ^= k >>> 24;
    k = (((k & 0xffff) * 0x5bd1e995) + ((((k >>> 16) * 0x5bd1e995) & 0xffff) << 16));

    h = (((h & 0xffff) * 0x5bd1e995) + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16)) ^ k;

    l -= 4;
    ++i;
  }

  switch (l) {
    case 3:
      h ^= (str.charCodeAt(i + 2) & 0xff) << 16;
      break;
    case 2:
      h ^= (str.charCodeAt(i + 1) & 0xff) << 8;
      break;
    case 1:
      h ^= (str.charCodeAt(i) & 0xff);
      h = (((h & 0xffff) * 0x5bd1e995) + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16));
      break;
  }

  h ^= h >>> 13;
  h = (((h & 0xffff) * 0x5bd1e995) + ((((h >>> 16) * 0x5bd1e995) & 0xffff) << 16));
  h ^= h >>> 15;

  return h >>> 0;
}

export function plural(word: string): string {
  const plural: { [key: string]: string } = {
    '(quiz)$': "$1zes",
    '^(ox)$': "$1en",
    '([m|l])ouse$': "$1ice",
    '(matr|vert|ind)ix|ex$': "$1ices",
    '(x|ch|ss|sh)$': "$1es",
    '([^aeiouy]|qu)y$': "$1ies",
    '(hive)$': "$1s",
    '(?:([^f])fe|([lr])f)$': "$1$2ves",
    '(shea|lea|loa|thie)f$': "$1ves",
    'sis$': "ses",
    '([ti])um$': "$1a",
    '(tomat|potat|ech|her|vet)o$': "$1oes",
    '(bu)s$': "$1ses",
    '(alias)$': "$1es",
    '(octop)us$': "$1i",
    '(ax|test)is$': "$1es",
    '(us)$': "$1es",
    '([^s]+)$': "$1s"
  }
  const irregular: { [key: string]: string } = {
    'move': 'moves',
    'foot': 'feet',
    'goose': 'geese',
    'sex': 'sexes',
    'child': 'children',
    'man': 'men',
    'tooth': 'teeth',
    'person': 'people'
  }
  const uncountable: string[] = [
    'sheep',
    'fish',
    'deer',
    'moose',
    'series',
    'species',
    'money',
    'rice',
    'information',
    'equipment',
    'bison',
    'cod',
    'offspring',
    'pike',
    'salmon',
    'shrimp',
    'swine',
    'trout',
    'aircraft',
    'hovercraft',
    'spacecraft',
    'sugar',
    'tuna',
    'you',
    'wood'
  ]
  if (!word) {
    return '';
  }
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word
  }
  for (const w in irregular) {
    const pattern = new RegExp(`${ w }$`, 'i')
    const replace = irregular[w]
    if (pattern.test(word)) {
      return word.replace(pattern, replace)
    }
  }
  for (const reg in plural) {
    const pattern = new RegExp(reg, 'i')
    if (pattern.test(word)) {
      return word.replace(pattern, plural[reg])
    }
  }
  return word
}


export function removeControlErrors(control: AbstractControl, errorKeys: Array<string>): void {
  if (!control || !errorKeys || errorKeys.length === 0) {
    return;
  }

  const remainingErrors = errorKeys.reduce((errors, key) => {
    delete errors[key];
    return errors;
  }, { ...control.errors });

  control.setErrors(remainingErrors);

  if (Object.keys(control.errors || {}).length === 0) {
    control.setErrors(null);
  }
}


export function addControlErrors(control: AbstractControl, errorKeys: Array<string>): void {
  if (!control || !errorKeys) {
    return;
  }
  control.setErrors({
    ...control.errors,
    ...errorKeys.reduce((errors, key) => ({...errors, ...{[key]: true}}), {} )
  });
}

export function addOrRemoveControlErrors(control: AbstractControl, invalid: boolean, errorKeys: Array<string>): void {
  if (invalid) {
    addControlErrors(control, errorKeys);
  } else {
    removeControlErrors(control, errorKeys);
  }
}

export function onComponentPropChanged(changes: SimpleChanges, checkChanges: Array<string>, onChanged?: (changes: SimpleChanges) => void): boolean {
  const changed = some(checkChanges, (ch) => !!changes[ch] && !changes[ch].firstChange);
  if (!!onChanged && changed) {
    onChanged(changes);
  }
  return changed;
}
