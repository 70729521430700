import { Component } from '@angular/core';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';
import { ISearchSource } from '../../api/shared/app-domain/settings';

@Component({
  selector: 'app-sync-candidates-dialog',
  template: `
    <p-dialog [(visible)]="visible"
              header="Sync Candidates Database"
              [style]="{width: '600px'}"
              [closeOnEscape]="true"
              [modal]="true"
              [resizable]="false" (onHide)="onClose()">
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-check"
                  [disabled]="!canApply()"
                  (onClick)="apply()"
                  label="Apply"
                  styleClass="p-button-text"></p-button>
        <p-button icon="pi  pi-times" (onClick)="visible=false" label="Cancel" styleClass="p-button-text"></p-button>
      </ng-template>
      <div class="flex align-items-center">
        <label class="mr-2">Select source:</label>
        <p-multiSelect [(ngModel)]="searchSources"
                       class="flex-1"
                       styleClass="w-full"
                       display="chip"
                       appendTo="body"
                       [options]="$any(propertyOptions.getOptions('searchSources') | async)"
                       optionLabel="name"
                       optionValue="name"
                       [filter]="true"
                       filterBy="name"
                       [showToggleAll]="true"
                       [showClear]="true"
                       nullable>
        </p-multiSelect>
      </div>
    </p-dialog>
  `,
  styles: [`
    :host ::ng-deep .p-multiselect-label {
      white-space: normal;
    }

    :host ::ng-deep .p-multiselect-label .p-multiselect-token {
      margin: 1px .5rem 1px 0;
    }
  `],
  providers: [
    EntityPropertyOptionsService
  ]

})
export class SyncCandidatesDialog {
  protected visible = false;
  searchSources: Array<ISearchSource> | null = null;

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }

  show(): void {
    this.visible = true;
  }

  protected onClose(): void {
  }

  canApply(): boolean {
    return !!this.searchSources;
  }

  apply(): void {
    alert('Not yet implemented');
    this.visible = false;
  }
}
