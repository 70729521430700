import {IIdentified, INamed} from './app-domain/common';

export const USERS_API_ENDPOINTS = {
  userRoles: '/api/users/roles',
  viewAs: '/api/users/view-as'
};

export type TViewAsType = 'user' | 'role';

export interface IViewAsItem extends IIdentified, INamed {
  type: TViewAsType;
  from: string;
}

// tslint:disable-next-line:no-empty-interface
export interface IViewAsRole extends IViewAsItem {
}

export interface IViewAsUser extends IViewAsItem {
  avatar?: string;
}

export interface IViewAs {
  roles: Array<IViewAsRole>;
  users: Array<IViewAsUser>;
}

export interface IUserRole extends IIdentified, INamed {
}
