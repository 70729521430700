import {ChangeDetectorRef, Directive, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {find} from 'lodash';
import {PageableTableComponent} from '../../common/components/pageable-table.component';

export interface IFilterByQueryParamDef {
  field: string;
  matchMode: string;  // see FilterMatchMode;
  isValueArray?: boolean;
}


@Directive({
  selector: '[filterByQueryParams]',
})
export class FilterByQueryParamsDirective implements OnInit {
  @Input('filterByQueryParams') defs!: Array<IFilterByQueryParamDef>;

  constructor(public table: PageableTableComponent<any>,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      const inQueryParams: Array<{ qp: IFilterByQueryParamDef; value: any; }> = [];
      for (const p of params.keys) {
        const qp = find(this.defs,
          (qp) => 'colFilter_' + qp.field === p);
        if (!!qp && !!params.get(p)) {
          inQueryParams.push({qp, value: params.get(p)})
        }
      }
      for (const qp of this.defs) {
        const found = find(inQueryParams, (f) => f.qp.field === qp.field);
        this.table.filters[qp.field] = [
          {
            matchMode: qp.matchMode,
            value: !!found ? (qp.isValueArray ? [found.value] : found.value) : null,
            operator: 'and'
          }
        ];
      }
      this.cdr.detectChanges();
      this.table.load();
    });
  }
}


