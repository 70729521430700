import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ITeamMember} from '../../../api/shared/app-domain/team';
import {ILens, IPeriod, TLens} from '../../../api/shared/app-domain/common';
import {AppResourceService} from '../../../app.resource.service';
import {ServiceLocator} from '../../../common/util/util';
import {PlanningTableComponent} from '../../common/column-expansion/planning-table.component';
import {ITimeSheetsExtraValue, ITimeSheetsValueData} from '../../../api/shared/app-domain/time';
import {TreeNode} from 'primeng/api';
import {IDetailsCategoryRow} from '../../../api/shared/app-domain/details';
import {TProjectEntryEntity} from '../../../api/shared/app-domain/project';
import {AppConfigService} from '../../../app-config.service';

@Component({
  selector: 'app-week-time-sheets-details',
  template: `
    <app-day-time-entries-dialog #te [teamMember]="teamMember" (dataChanged)="table.load()">
    </app-day-time-entries-dialog>
    <app-planning-table #dt [period]="period" [lens]="lens" [fetch]="fetch" entryEntityName="Project"
                        [isSingleWeek]="true"
                        [showPopupColumnByDefault]="false"
                        (addEntryEntity)="te.toggle(null, $event.periodIndex, dt.periodRange.periods[$event.periodIndex].start)"
                        (editEntryEntity)="te.toggle($event.rowData, $event.periodIndex, dt.periodRange.periods[$event.periodIndex].start)"
                        (loaded)="extraData = $event.extraData"
                        [periodCellTooltip]="periodCellTooltip">
      <ng-template pTemplate="entryEntityTpl" let-rowData>
        <app-project-entry-entity [entryEntity]="rowData.entryEntity"
                                  routerLink="/projects" [queryParams]="{tab: 'details'}">
        </app-project-entry-entity>
      </ng-template>
      <ng-template pTemplate="extraRowCellTpl" let-periodIndex>
        <div class="flex flex-column justify-content-center">
          <div class="flex align-items-center mb-2">
            <span *ngIf="dt.extraRowData![periodIndex].unsubmitted !== '00:00'"
                  class="pi pi-circle-fill text-xs p-error mr-2"></span>
            <span *ngIf="!!dt.extraRowData![periodIndex].unsubmitted" class="">Unsubmitted: <span
              class="text-gray-800">{{dt.extraRowData![periodIndex].unsubmitted}}</span></span>
          </div>
          <button *ngIf="config.timeSubmitBasis === 'day'" pButton pRipple type="button"
                  [disabled]="!dt.extraRowData![periodIndex].canSubmit"
                  (click)="tbd()"><span class="text-xs text-center w-full">Submit Day for Approval</span></button>
        </div>
      </ng-template>
    </app-planning-table>
    <div class="grid mt-8">
      <div class="col-6">
        <div class="flex w-full align-items-center">
          <ng-container *ngIf="!!dt.extraRowData">
            <button pButton pRipple type="button" label="Prefill from Prior Week"
                    class="p-button-outlined mr-2" [disabled]="!dt.extraRowData![0].canPrefillFromPrior"
                    (click)="tbd()"></button>
            <button pButton pRipple type="button" label="Prefill from Planning"
                    class="p-button-outlined" [disabled]="!dt.extraRowData![0].canPrefillFromPlanning"
                    (click)="tbd()"></button>
          </ng-container>
        </div>
      </div>
      <div class="col-6">
        <div class="flex w-full align-items-center justify-content-end">
          <ng-container *ngIf="!!extraData">
            <span *ngIf="extraData.unsubmitted !== '00:00'"
                  class="pi pi-circle-fill text-xs p-error mr-2"></span>
            <span *ngIf="!!extraData.unsubmitted" class="text-lg font-medium">Unsubmitted: <span
              class="text-gray-800">{{extraData.unsubmitted}}</span></span>
            <span class="ml-4 text-lg font-medium mr-4">Total: <span
              class="text-gray-800">{{extraData.total}}</span></span>
            <button pButton pRipple type="button" label="Submit Week for Approval"
                    [disabled]="!extraData.canSubmit"
                    (click)="tbd()"></button>
          </ng-container>
        </div>
      </div>
    </div>
  `
})
export class WeekTimeSheetsDetails implements OnChanges {
  @Input() teamMember!: ITeamMember;
  @Input() period!: IPeriod;
  @Input() lens!: ILens;
  @ViewChild(PlanningTableComponent) table!: PlanningTableComponent;
  extraData?: ITimeSheetsExtraValue;

  constructor(private resource: AppResourceService, public config: AppConfigService) {
  }

  fetch = () => this.resource.getWeekTimeSheets(this.teamMember.id, {
    period: this.period,
    data: {
      lens: this.lens.id
    }
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['teamMember'] && !changes['teamMember'].firstChange
      || changes['lens'] && !changes['lens'].firstChange) { // TODO: move lens to app-details-table ?
      this.table.load();
    }
  }

  periodCellTooltip(
    node: TreeNode, rowData: IDetailsCategoryRow<TProjectEntryEntity, ITimeSheetsValueData>, periodIndex: number
  ): string | null {
    if (node.type === 'section') {
      return null;
    }
    return rowData.values[periodIndex].data!.timeEntries.map((te) => `\u2666  ${te.departmentName} | ${te.actualTime}`)
      .join('\n');
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }
}
