import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {IBudgetComponents} from '../../api/shared/app-domain/common';

@Component({
  selector: 'app-budget-components-column-headers',
  template: `
    <ng-template #template>
      <th pSortableColumn="actualFees"
          [style.width]="!width ? 'auto' : width + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth + 'px'">
        <div class="flex justify-content-between align-items-center w-full">
          <div>Actual Fees</div>
          <p-sortIcon class="align-self-center" field="actualFees"></p-sortIcon>
          <p-columnFilter *ngIf="useColumnFilters" type="numeric" field="actualFees" display="menu" class="ml-auto">
          </p-columnFilter>
        </div>
      </th>
      <th pSortableColumn="budget"
          [style.width]="!width ? 'auto' : width + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth + 'px'">
        <div class="flex justify-content-between align-items-center w-full">
          <div>Budget</div>
          <p-sortIcon class="align-self-center" field="budget"></p-sortIcon>
          <p-columnFilter *ngIf="useColumnFilters" type="numeric" field="budget" display="menu" class="ml-auto">
          </p-columnFilter>
        </div>
      </th>
      <th pSortableColumn="remaining"
          [style.width]="!width ? 'auto' : width + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth + 'px'">
        <div class="flex justify-content-between align-items-center w-full">
          <div>Remaining</div>
          <p-sortIcon class="align-self-center" field="remaining"></p-sortIcon>
          <p-columnFilter *ngIf="useColumnFilters" type="numeric" field="remaining" display="menu" class="ml-auto">
          </p-columnFilter>
        </div>
      </th>
      <th pSortableColumn="costs"
          [style.width]="!width ? 'auto' : width + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth + 'px'">
        <div class="flex justify-content-between align-items-center w-full">
          <div>Costs</div>
          <p-sortIcon class="align-self-center" field="costs"></p-sortIcon>
          <p-columnFilter *ngIf="useColumnFilters" type="numeric" field="costs" display="menu" class="ml-auto">
          </p-columnFilter>
        </div>
      </th>
      <th pSortableColumn="billable"
          [style.width]="!width ? 'auto' : width + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth + 'px'">
        <div class="flex justify-content-between align-items-center w-full">
          <div>Billable</div>
          <p-sortIcon class="align-self-center" field="billable"></p-sortIcon>
          <p-columnFilter *ngIf="useColumnFilters" type="numeric" field="billable" display="menu" class="ml-auto">
          </p-columnFilter>
        </div>
      </th>
      <th pSortableColumn="netIncome"
          [style.width]="!width ? 'auto' : width + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth + 'px'">
        <div class="flex justify-content-between align-items-center w-full">
          <div>Net Income</div>
          <p-sortIcon class="align-self-center" field="netIncome"></p-sortIcon>
          <p-columnFilter *ngIf="useColumnFilters" type="numeric" field="netIncome" display="menu" class="ml-auto">
          </p-columnFilter>
        </div>
      </th>
    </ng-template>
  `,
  styles: [`
    :host {
      display: none;
    }
  `]
})
export class BudgetComponentsColumnHeadersComponent implements AfterViewInit {
  @Input() width?: number;
  @Input() minWidth?: number;
  @Input() maxWidth?: number;
  @Input() useColumnFilters = false;
  @ViewChild('template', {static: true}) template!: TemplateRef<any>;

  constructor(private viewContainerRef: ViewContainerRef) {
  }

  ngAfterViewInit(): void {
    setTimeout(() =>
      this.viewContainerRef.createEmbeddedView(this.template)
    );
  }
}


@Component({
  selector: 'app-budget-components-cells',
  template: `
    <ng-template #template>
      <td colspan="2" style="padding-top: 0; padding-bottom: 0; flex: 2"
          [style.width]="!width ? 'auto' : width*2  + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth*2 + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth*2 + 'px'">
        <div class="w-full">
          <div class="flex justify-content-between">
            <div>{{rowData.actualFees | currency:'USD':'symbol':'1.0-0'}}</div>
            <div>of</div>
            <div>{{rowData.budget | currency:'USD':'symbol':'1.0-0'}}</div>
          </div>
          <div>
            <p-progressBar [value]="rowData.actualFees / rowData.budget * 100" [showValue]="false"></p-progressBar>
          </div>
        </div>
      </td>
      <td [style.width]="!width ? 'auto' : width  + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth + 'px'">
        <div class="flex align-items-center justify-content-between w-full">
          <div>{{rowData.remaining | currency:'USD':'symbol':'1.0-0'}}</div>
          <div class="mt-pct-box">{{rowData.remainingPct | percent}}</div>
        </div>
      </td>
      <td colspan="2" style="padding-top: 0; padding-bottom: 0; flex: 2"
          [style.width]="!width ? 'auto' : width*2  + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth*2 + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth*2 + 'px'">
        <div class="w-full">
          <div class="flex justify-content-between">
            <div>{{rowData.costs | currency:'USD':'symbol':'1.0-0'}}</div>
            <div>of</div>
            <div>{{rowData.billable | currency:'USD':'symbol':'1.0-0'}}</div>
          </div>
          <div>
            <p-progressBar class="mt-bar-costs" [value]="rowData.costs / rowData.billable * 100"
                           [showValue]="false"></p-progressBar>
          </div>
        </div>
      </td>
      <td [style.width]="!width ? 'auto' : width + 'px'"
          [style.min-width]="!minWidth ? 'auto' : minWidth + 'px'"
          [style.max-width]="!maxWidth ? 'auto' : maxWidth + 'px'">
        <div class="flex align-items-center justify-content-between w-full">
          <div>{{rowData.netIncome | currency:'USD':'symbol':'1.0-0'}}</div>
          <div class="mt-pct-box">{{rowData.netIncomePct | percent}}</div>
        </div>
      </td>
    </ng-template>
  `,
  styles: [`
    :host {
      display: none;
    }

    ::ng-deep .mt-bar-costs .p-progressbar {
      background-color: #D7F3E5;
    }

    ::ng-deep .mt-bar-costs .p-progressbar .p-progressbar-value {
      background-color: #69D09B;
    }

  `]
})
export class BudgetComponentsCellsComponent implements AfterViewInit {
  @Input() rowData!: IBudgetComponents;
  @Input() width?: number;
  @Input() minWidth?: number;
  @Input() maxWidth?: number;
  @ViewChild('template', {static: true}) template!: TemplateRef<any>;

  constructor(private viewContainerRef: ViewContainerRef) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewContainerRef.createEmbeddedView(this.template);
    });
  }
}
