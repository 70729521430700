import { AfterViewInit, Component, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  template: ``
})
export abstract class EmbeddedComponent implements AfterViewInit {
  @ViewChild('template', { static: true }) template!: TemplateRef<any>;

  protected constructor(protected viewContainerRef: ViewContainerRef) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewContainerRef.createEmbeddedView(this.template);
    });
  }
}

@Component({
  selector: 'app-no-value',
  template: `
    <div class="flex justify-content-end">
      <div class="border-bottom-1 text-gray-400" style="width: 25px"></div>
    </div>
  `
})
export class NoValueComponent {
}
