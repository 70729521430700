import {Component, Input} from '@angular/core';
import {ISearchRequest, TQueryExpression} from '../../api/shared/search-api';
import {IPeriod} from '../../api/shared/app-domain/common';
import {AppResourceService} from '../../app.resource.service';

@Component({
  selector: 'app-all-clients-details',
  template: `
    <app-details-table [query]="query" [period]="period" [fetch]="fetch" [useSearchRequest]="true"
                       [searchEntryEntities]="{searchOption: 'projectClients'}">
      <ng-template pTemplate="entryEntityTpl" let-rowData>
        <a style="width: 160px" class="text-primary no-underline mt-overflow-ellipsis" appTooltipOnOverflow
           [routerLink]="['/projects/clients', rowData.entryEntity.id]" [queryParams]="{tab: 'details'}">
          {{rowData.entryEntity.name}}
        </a>
      </ng-template>
    </app-details-table>
  `,
  providers: [
  ]
})
export class AllClientsDetailsComponents {
  @Input() query: TQueryExpression | null = null;
  @Input() period!: IPeriod;

  fetch = (searchRequest: ISearchRequest) => this.resource.getAllClientsDetails(
    { ...searchRequest, ...{ param: { period: this.period } } }
  );

  constructor(public resource: AppResourceService) {
  }
}
