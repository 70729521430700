import {Component, NgModule} from '@angular/core';
import {ActivatedRoute, RouterModule, Routes} from '@angular/router';
import {LoginPageComponent} from './components/login/login-page.component';
import {AuthGuardService} from './services/auth-guard.service';
import {HsAssociationsPageComponent} from './components/dev-tools/hs/hs-associations-page.component';
import {HsRelationshipDiagramPageComponent} from './components/dev-tools/hs/hs-relationship-diagram-page.component';
import {LandingPageComponent} from './components/landing-page.component';
import {HsObjectBrowserComponent} from './components/dev-tools/hs/hs-object-browser.component';
import {DbRelationshipDiagramPageComponent} from './components/dev-tools/db/db-relationship-diagram-page.component';
import {DbBrowserComponent} from './components/dev-tools/db/browser/db-browser.component';
import {ProjectsPageComponent} from './components/projects/projects-page.component';
import {ProjectDetailsPageComponent} from './components/projects/detail/project-details-page.component';
import {TeamPageComponent} from './components/team/team-page.component';
import {TeamMemberDetailPageComponent} from './components/team/detail/team-member-detail-page.component';
import {TeamCandidatesPageComponent} from './components/team-candidate/team-candidates-page.component';
import {CompaniesPageComponent} from './components/company/companies-page.component';
import {DepartmentsPageComponent} from './components/settings/departments-page.component';
import {RolesPageComponent} from './components/settings/roles-page.component';
import {SkillsPageComponent} from './components/skills/skills-page.component';
import {TeamCandidateEditPageComponent} from './components/team-candidate/team-candidate-edit-page.component';
import {startCase} from 'lodash';
import {TestComponent} from './test.component';
import {ReferralsPageComponent} from './components/referral/referrals-page.component';
import {HolidayCalendarsPageComponent} from './components/schedule/holiday-calendars-page.component';
import {HolidayCalendarEditPageComponent} from './components/schedule/holiday-calendar-edit-page.component';
import {WorkweekPageComponent} from './components/schedule/workweek-page.component';
import {HolidaysUniversePageComponent} from './components/schedule/holidays-universe-page.component';
import {ClientDetailsPageComponent} from './components/projects/detail/client-details-page.component';
import {AgencyDetailsPageComponent} from './components/projects/detail/agency-details-page.component';
import {TimeSheetsPageComponent} from './components/time/time-sheets/time-sheets.page.component';
import {TimeEntriesPageComponent} from './components/time/time-entries/time-entries-page.component';
import {TimeApprovalsPageComponent} from './components/time/time-approvals/time-approvals-page.component';
import {ExpenseCategoriesPageComponent} from './components/settings/expense-category-page.component';
import {ExpensesPageComponent} from './components/time/expenses/expenses-page.component';
import {ExpenseReportsOrApprovalsPageComponent} from './components/time/expenses/expense-reports-or-approvals-page.component';
import {TimeOffRequestsPageComponent} from './components/time/time-offs/time-off-requests-page.component';
import {TimeOffsPageComponent} from './components/time/time-offs/time-offs-page.component';
import {PaidTimeOffsPageComponent} from './components/settings/paid-time-offs-page.component';
import {TeamMemberEditPageComponent} from './components/team/team-member-edit-page.component';
import {ContactsPageComponent} from './components/contacts/contacts-page.component';

@Component({
  selector: 'app-not-found-page',
  template: `
    <div>Page not found</div>`
})
export class NotFoundPageComponent {
}

@Component({
  selector: 'app-tbd-page',
  template: `
    <h3>TBD</h3>
    <div class="text-lg">{{id1}}</div>
    <div class="mt-2">{{id2}}</div>
  `
})
export class TBDComponent {
  id1?: string | null;
  id2?: string | null;
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.id1 = startCase(params.get('id1') || '');
      this.id2 = startCase(params.get('id2') || '');
    });
  }
}

const routes: Routes = [
  {path: '', redirectTo: '/landing-page', pathMatch: 'full'},
  {path: 'login', component: LoginPageComponent},
  {path: 'landing-page', component: LandingPageComponent, canActivate: [AuthGuardService]},
  {path: 'hs-associations', component: HsAssociationsPageComponent, canActivate: [AuthGuardService]},
  {path: 'hs-relationship-diagram', component: HsRelationshipDiagramPageComponent, canActivate: [AuthGuardService]},
  {path: 'hs-object-browser', component: HsObjectBrowserComponent, canActivate: [AuthGuardService]},
  {path: 'db-relationship-diagram', component: DbRelationshipDiagramPageComponent, canActivate: [AuthGuardService]},
  {path: 'db-browser', component: DbBrowserComponent, canActivate: [AuthGuardService]},

  {path: 'projects', component: ProjectsPageComponent, canActivate: [AuthGuardService]},
  {path: 'projects/:id', component: ProjectDetailsPageComponent, canActivate: [AuthGuardService]},
  {path: 'projects/clients/:id', component: ClientDetailsPageComponent, canActivate: [AuthGuardService]},
  {path: 'projects/agencies/:id', component: AgencyDetailsPageComponent, canActivate: [AuthGuardService]},

  {path: 'team', component: TeamPageComponent, canActivate: [AuthGuardService]},
  {path: 'team-members/:id', component: TeamMemberDetailPageComponent, canActivate: [AuthGuardService]},
  {path: 'team-members/edit/:id', component: TeamMemberEditPageComponent, canActivate: [AuthGuardService]},

  {path: 'team-candidates', component: TeamCandidatesPageComponent, canActivate: [AuthGuardService]},
  {path: 'team-candidates/edit/:id', component: TeamCandidateEditPageComponent, canActivate: [AuthGuardService]},
  {path: 'companies', component: CompaniesPageComponent, canActivate: [AuthGuardService]},
  {path: 'referrals', component: ReferralsPageComponent, canActivate: [AuthGuardService]},
  {path: 'contacts', component: ContactsPageComponent, canActivate: [AuthGuardService]},


  {path: 'settings/departments', component: DepartmentsPageComponent, canActivate: [AuthGuardService]},
  {path: 'settings/roles', component: RolesPageComponent, canActivate: [AuthGuardService]},
  {path: 'settings/expense-categories', component: ExpenseCategoriesPageComponent, canActivate: [AuthGuardService]},
  {path: 'settings/skills', component: SkillsPageComponent, canActivate: [AuthGuardService]},

  {path: 'settings/holiday-calendars', component: HolidayCalendarsPageComponent, canActivate: [AuthGuardService]},
  {path: 'settings/holiday-calendars/edit/:id', component: HolidayCalendarEditPageComponent, canActivate: [AuthGuardService]},
  {path: 'settings/workweek', component: WorkweekPageComponent, canActivate: [AuthGuardService]},
  {path: 'settings/holidays', component: HolidaysUniversePageComponent, canActivate: [AuthGuardService]},

  {path: 'settings/paid-time-offs', component: PaidTimeOffsPageComponent, canActivate: [AuthGuardService]},

  {path: 'time-sheets', component: TimeSheetsPageComponent, canActivate: [AuthGuardService]},
  {path: 'time-entries', component: TimeEntriesPageComponent, canActivate: [AuthGuardService]},
  {path: 'time-approvals', component: TimeApprovalsPageComponent, canActivate: [AuthGuardService]},

  {path: 'expenses', component: ExpensesPageComponent, canActivate: [AuthGuardService]},
  {path: 'expense-reports', component: ExpenseReportsOrApprovalsPageComponent, canActivate: [AuthGuardService], data: {isApprovalMode: false}},
  {path: 'expense-approvals', component: ExpenseReportsOrApprovalsPageComponent, canActivate: [AuthGuardService], data: {isApprovalMode: true}},

  {path: 'time-off-requests', component: TimeOffRequestsPageComponent, canActivate: [AuthGuardService]},
  {path: 'annual-log', component: TimeOffsPageComponent, canActivate: [AuthGuardService], data: {isApprovalMode: false}},
  {path: 'time-off-approvals', component: TimeOffsPageComponent, canActivate: [AuthGuardService], data: {isApprovalMode: true}},

  {path: 'tbd', component: TBDComponent},
  {path: 'tbd/:id1', component: TBDComponent},
  {path: 'tbd/:id1/:id2', component: TBDComponent},
  {path: 'test', component: TestComponent},

  {path: '**', component: NotFoundPageComponent, canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [TBDComponent]
})
export class AppRoutingModule { }
