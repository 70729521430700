import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IProject, IProjectTeamMember} from '../../../../api/shared/app-domain/project';
import {AppResourceService} from '../../../../app.resource.service';
import {finalize} from 'rxjs';

@Component({
  selector: 'app-project-team-table',
  template: `
    <app-spinnerizer [active]="loading">
    </app-spinnerizer>
    <p-table [value]="data"
             [scrollable]="true"
             responsiveLayout="scroll"
             scrollHeight="flex"
             dataKey="id"
             sortMode="multiple">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="roleName" style="width: 320px">
            <div class="flex justify-content-between align-items-center w-full">
              <div>Project Team Member</div>
              <p-sortIcon class="align-self-center" field="roleName"></p-sortIcon>
            </div>
          </th>
          <app-budget-components-column-headers></app-budget-components-column-headers>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td style="padding-top: .2rem; padding-bottom: .2rem; width: 320px" class="flex align-items-center">
            <app-progress-avatar [avatar]="rowData.avatar" [value]="rowData.progress"></app-progress-avatar>
            <div class="ml-1 w-full flex align-items-center justify-content-between">
              <div>
                <div class="test-sm">{{rowData.roleName}}</div>
                <div class="text-xs text-gray-600">{{rowData.name}}</div>
              </div>
              <i *ngIf="rowData.hasUnsubmittedTimesheet" class="pi pi-exclamation-circle mt-alert-icon"
                 hint="A team member has an unsubmitted timesheet for the active period and the values here are subject to change."></i>
            </div>
          </td>
          <app-budget-components-cells [rowData]="rowData"></app-budget-components-cells>
        </tr>
      </ng-template>
    </p-table>
  `
})
export class ProjectTeamTableComponent implements OnChanges {
  @Input() project!: IProject;

  loading = false;
  data!: Array<IProjectTeamMember>

  constructor(private resource: AppResourceService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['project']) {
      this.load();
    }
  }

  load(): void {
    this.loading = true;
    this.resource.getProjectTeam(this.project.id, {})
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        this.data = response.results;
      });
  }
}
