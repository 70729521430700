import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, forwardRef} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {AbstractControlValueAccessorComponent} from '../../common/components/abstract-control-value-accessor.component';

export const SCHEDULE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ScheduleChooserComponent),
  multi: true
};


@Component({
  selector: 'app-schedule-chooser',
  template: `
    <span class="p-float-label">
      <p-dropdown [(ngModel)]="value"
                  [options]="scheduleOptions"
                  optionLabel="label"
                  optionValue="value"
                  [style]="{width: '200px'}"
                  (ngModelChange)="onChange()">
      </p-dropdown>
      <label>Schedule</label>
    </span>
  `,
  providers: [SCHEDULE_VALUE_ACCESSOR]
})
export class ScheduleChooserComponent extends AbstractControlValueAccessorComponent<string>{

  scheduleOptions: Array<SelectItem> = [
    {label: 'Project', value: 'project'},
    {label: 'Invoice', value: 'invoice'},
    {label: 'Cashflow', value: 'cashflow'},
    {label: 'Commissions', value: 'commissions'}
  ];


  onChange(): void {
    this.onModelChange(this.value);
  }

}
