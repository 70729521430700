import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  IExpenseCategory,
  TExpenseCategorySave
} from '../../api/shared/app-domain/settings';
import { AppResourceService } from '../../app.resource.service';
import { finalize } from 'rxjs';
import { existsAsyncValidator} from '../../common/util/util';
import {TableBaseComponent} from '../../common/components/table-base.component';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {ColumnsInfoService} from '../common/column-header.component';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {EntityEditorComponent} from '../common/entity-editor.component';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {TControlsOf} from '../../common/types';

@Component({
  selector: 'app-expense-category-form-control',
  template: `
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'name'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'status'" [controlName]="name" controlType="dropdown"
                        [options]="propertyOptions.commonStatuses"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'accountingCode'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'isBillable'" [controlName]="name" dataType="boolean"></app-form-control-wrapper>
    </ng-container>
  `
})
export class ExpenseCategoryFormControlComponent {
  @Input() name!: string;

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }
}

@Component({
  selector: 'app-expense-category-editor',
  template: `
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container>
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-8 p-fluid">
            <app-expense-category-form-control name="name"></app-expense-category-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-expense-category-form-control name="status"></app-expense-category-form-control>
          </div>
          <div class="field col-8 p-fluid">
            <app-expense-category-form-control name="accountingCode"></app-expense-category-form-control>
          </div>
          <div class="field col-4 p-fluid pt-5">
            <app-expense-category-form-control name="isBillable"></app-expense-category-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => ExpenseCategoryEditorComponent)},
    SubscriptionsService
  ]

})
export class ExpenseCategoryEditorComponent extends EntityEditorComponent<TExpenseCategorySave, IExpenseCategory> {

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              fb: FormBuilder) {
    super(fb);
    this.entityName = 'Expense Category';
    this.api = {
      getEntity: this.resource.getExpenseCategory.bind(this.resource),
      createEntity: this.resource.createExpenseCategory.bind(this.resource),
      updateEntity: this.resource.updateExpenseCategory.bind(this.resource),
    }
  }

  override getName(): string | null {
    return this.entity.name ?? '';
  }

  override buildForm(): void {
    const checkExistsName = this.getCheckExists(this.resource.getExpenseCategories.bind(this.resource), 'name');
    this.form = this.fb.group<TControlsOf<TExpenseCategorySave>>({
      name: new FormControl(this.entity.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsName)]
      }),
      accountingCode: new FormControl(this.entity.accountingCode, [Validators.required]),
      isBillable: new FormControl(this.isNew() ? true : this.entity.isBillable),
      status: new FormControl(this.isNew() ? 'Active' : this.entity.status, [Validators.required]),
    });
  }
}

@Component({
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="mt-page-title">Expense Categories</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <button pButton pRipple label="New Expense Category"
                    class="ml-2"
                    icon="pi pi-plus" (click)="openCreateOrUpdateDialog(null)"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 shadow-1 bg-white p-3 pb-3">
      <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit']" [showActions]="false"></app-table-toolbar>
      <app-entity-edit-dialog #editDialog width="50vw" (apply)="onCreatedOrUpdated($event)">
        <ng-template pTemplate let-param>
          <app-expense-category-editor [param]="param"></app-expense-category-editor>
        </ng-template>
      </app-entity-edit-dialog>
      <app-spinnerizer [active]="loading"
                       [target]="container">
      </app-spinnerizer>
      <div #container>
        <p-table [value]="data"
                 responsiveLayout="scroll"
                 [(selection)]="selection"
                 (selectionChange)="onSelectionChange()"
                 [globalFilterFields]="['name','status']">
          <ng-template pTemplate="header">
            <tr>
              <app-column-header [isSelector]="true"></app-column-header>
              <app-column-header field="name" filterType="text" [style]="{minWidth: '400px'}">
              </app-column-header>
              <app-column-header field="accountingCode" filterType="text">
              </app-column-header>
              <app-column-header field="isBillable" label="Default Billable" filterType="boolean">
              </app-column-header>
              <app-column-header field="status" filterType="optionsEquals"
                                 [options]="propertyOptions.commonStatuses">
              </app-column-header>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
              <app-table-cell field="name" type="custom" [rowData]="rowData"
                              styleClass="flex justify-content-between">
                <div class="flex align-items-center overflow-x-hidden">
                  <div class="mt-overflow-ellipsis mt-link" appTooltipOnOverflow (click)="openCreateOrUpdateDialog(rowData)">
                    {{rowData.name}}
                  </div>
                </div>
                <app-row-menu-button [tableBase]="this" [rowData]="rowData" [omitMenuItems]="['duplicate']"></app-row-menu-button>
              </app-table-cell>
              <app-table-cell field="accountingCode" [rowData]="rowData"></app-table-cell>
              <app-table-cell field="isBillable" [rowData]="rowData" type="boolean"></app-table-cell>
              <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService
  ]
})
export class ExpenseCategoriesPageComponent extends TableBaseComponent<IExpenseCategory> implements OnInit {
  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.api = {
      archiveEntities: this.resource.patchExpenseCategories.bind(this.resource),
      deleteEntities: this.resource.deleteExpenseCategories.bind(this.resource),
    };
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.tableComponent?.filterGlobal(this.search, 'contains');
    this.loading = true;
    this.resource.getExpenseCategories({}).pipe(
      finalize(() => this.loading = false)
    ).subscribe((response) => {
      this.data = response.results;
    });
  }

}
