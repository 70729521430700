import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControlValueAccessorComponent} from '../../common/components/abstract-control-value-accessor.component';
import {IHoliday, IHolidayCalendar, THolidayCalendarSave} from '../../api/shared/app-domain/schedule';
import {FormControl, NgControl, NgModel} from '@angular/forms';
import {Calendar} from 'primeng/calendar';
import {COMMON_STATUSES, IPeriod} from '../../api/shared/app-domain/common';
import {getCurrentPeriodValue} from '../../common/components/period-chooser.component';
import {Observable, of} from 'rxjs';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {cloneDeep, find, isDate, max, maxBy, min, minBy, omit, range, remove, sortBy} from 'lodash';
import {DatePipe} from '@angular/common';
import {OverlayPanel} from 'primeng/overlaypanel';
import {AppResourceService} from '../../app.resource.service';
import {ISearchRequest, ISearchResponse, TQueryExpression} from '../../api/shared/search-api';
import {map, tap} from 'rxjs/operators';
import {log10} from 'chart.js/helpers';
import {addControlErrors, addOrRemoveControlErrors, removeControlErrors} from '../../common/util/util';

interface IDateMeta {
  day: number;
  month: number;
  year: number;
}


@Component({
  selector: 'app-holiday-calendar-editor',
  template: `
    <div #container [style.min-height]="'500px'">
      <ng-container #container *ngIf="data">
        <div class="formgrid grid p-fluid">
          <div *ngIf="showBasicCalendarChooser" class="field col-3">
            <label>Basic Calendar</label>
            <p-dropdown #model="ngModel" [(ngModel)]="basicCalendarId"
                        [disabled]="isDisabled"
                        placeholder="Select basic calendar"
                        [autoDisplayFirst]="false" [filter]="true"
                        optionLabel="name" optionValue="id" (ngModelChange)="onChooseBasicCalendar($event)"
                        [options]="$any(propertyOptions.getOptions('holidayCalendars') | async)">
            </p-dropdown>
          </div>
          <ng-container *ngIf="editGeneralProperties">
            <div class="field" [ngClass]="showBasicCalendarChooser ? 'col-3' : 'col-4'">
              <label>Calendar Name</label>
              <input #nameModel="ngModel" [(ngModel)]="data.name" pInputText
                     required [class.ng-dirty]="true" lifeCycleHook
                     [existsAsync]="checkExists"
                     (hook)="$event === 'onInit' ? nameModel.control.markAsDirty() : null"
                     (ngModelChange)="applyValue()" nullable/>
              <app-control-error [control]="nameModel.control"></app-control-error>
            </div>
            <div class="field" [ngClass]="showBasicCalendarChooser ? 'col-4' : 'col-6'">
              <label>Description</label>
              <textarea [(ngModel)]="data.description" pInputTextarea [autoResize]="true" [rows]="1"
                        [style.min-height]="'34px'" (ngModelChange)="applyValue()"></textarea>
            </div>
            <div class="field col-2">
              <label>Status</label>
              <p-dropdown [(ngModel)]="data.status" [options]="statusOptions" [autoDisplayFirst]="false"
                          (ngModelChange)="applyValue()"></p-dropdown>
            </div>
          </ng-container>
        </div>
        <p-overlayPanel #editorPanel [dismissable]="true" [showCloseIcon]="true" appendTo="body">
          <ng-template pTemplate>
            <div class="text-lg mb-3">Create new holiday on {{createHolidayData.day | date:'MM/dd/yy'}}</div>
            <div style="width: 500px" class="formgrid">
              <div class="field p-fluid grid">
                <label class="col-3">
                  Holiday Name
                </label>
                <div class="col-9">
                  <input #model="ngModel" pInputText [(ngModel)]="createHolidayData.formData.date"
                         required [class.ng-dirty]="true" lifeCycleHook
                         (ngModelChange)="getHolidayByName($event) ? model.control.setErrors({exists: true}) : null"
                         (hook)="$event === 'onInit' ? model.control.markAsDirty() : null" nullable>
                  <app-control-error [control]="model.control"></app-control-error>
                </div>
              </div>
            </div>
            <div class="mt-3 flex align-items-center justify-content-end">
              <p-button icon="pi pi-check" [disabled]="!!model.invalid"
                        (onClick)="editorPanel.hide(); createHolidayData.onApply!(model.value)" label="Apply"
                        styleClass="p-button-text p-button-sm"></p-button>
              <p-button icon="pi  pi-times" (onClick)="editorPanel.hide()" label="Cancel"
                        styleClass="p-button-text p-button-sm"></p-button>
            </div>
          </ng-template>
        </p-overlayPanel>
        <div class="grid">
          <div class="col-3">
            <div class="mb-3 p-fluid">
              <app-period-chooser [(ngModel)]="selectedPeriod" [unitFilter]="['Year']" [showUnits]="false"
                                  [disabled]="isDisabled"
                                  (ngModelChange)="onChangePeriod()"
                                  [minDate]="minDate" [maxDate]="maxDate"></app-period-chooser>
            </div>
            <p-listbox class="holiday-list"
                       [disabled]="isDisabled"
                       [(ngModel)]="selectedDates"
                       [options]="currentYearHolidays"
                       optionLabel="name" optionValue="observedDate"
                       filterPlaceHolder="Search for holiday"
                       [checkbox]="true" [filter]="true" [multiple]="true"
                       (ngModelChange)="onHolidayListChange()">
              <ng-template let-holidayDate pTemplate="item">
                <div class="inline-flex align-items-center justify-content-between w-full">
                  <span>{{holidayDate.name}}</span>
                  <span class="flex align-items-center">
                <span class="ml-2 text-basic-holiday text-right w-10rem"
                      [class.text-custom-holiday]="holidayDate.isCustom">
                  <span *ngIf="!!isMovedHoliday(holidayDate.observedDate, true)" class="">
                    <span class="line-through">{{holidayDate.date | date: 'MM/dd/yy'}}</span>
                    <i class="ml-1 mr-1 pi pi-arrow-right"></i>
                  </span>
                  <span>{{holidayDate.observedDate | date: 'MM/dd/yy'}}</span>
                </span>
                <button pButton pRipple icon="pi pi-trash"
                        [style.visibility]="!holidayDate.isCustom ? 'hidden' : 'auto'"
                        style="padding: 0.1rem; height: 2rem;  width: 2rem;"
                        class="p-button-rounded p-button-text p-button-sm"
                        (click)="removeDay($event, holidayDate)"></button>
              </span>
                </div>
              </ng-template>
            </p-listbox>
          </div>
          <div #calendarContainer class="col-9" style="height: auto;">
            <p-calendar #holidayCalendar class="calendar"
                        [disabled]="isDisabled"
                        [(ngModel)]="selectedDates"
                        [showOtherMonths]="false"
                        [numberOfMonths]="12"
                        [firstDayOfWeek]="1"
                        [inline]="true"
                        selectionMode="multiple">
              <ng-template pTemplate="date" let-dateMeta>
            <span #dayEl
                  [class.basic-holiday]="!!getHolidayByDate(dateMeta)"
                  [class.moved-holiday]="!!isMovedHoliday(dateMeta, false)"
                  [class.custom-holiday]="getHolidayByDate(dateMeta)?.isCustom"
                  (click)="onClickDay(dateMeta, $event, editorPanel, dayEl)"
                  [pTooltip]="getTooltip(dateMeta)!" tooltipPosition="bottom">
              {{dateMeta.day}}
            </span>
              </ng-template>
            </p-calendar>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data == null">
        <div class="text-lg text-gray-600">There is no Holiday Calendar</div>
      </ng-container>
    </div>
  `,
  styles: [`
    $calendar-height: 725px;
    $calendar-min-width: 800px;

    .text-basic-holiday {
      color: var(--gray-600);
    }

    .text-custom-holiday {
      color: var(--cyan-600);
    }

    ::ng-deep .p-overlaypanel-close {
      z-index: 1;
    }

    :host ::ng-deep .holiday-list .p-listbox {
      display: flex;
      flex-direction: column;
      height: calc($calendar-height - 45px);
    }

    :host ::ng-deep .holiday-list .p-ink {
      display: none !important;
    }

    :host ::ng-deep .calendar .p-calendar {
      min-height: $calendar-height;
      overflow-x: auto;
    }

    :host ::ng-deep .calendar .p-datepicker table {
      font-size: .9rem !important;
    }

    :host ::ng-deep .calendar .p-datepicker .p-datepicker-header .p-datepicker-prev,
    :host ::ng-deep .calendar .p-datepicker .p-datepicker-header .p-datepicker-next {
      display: none !important;
    }

    :host ::ng-deep .calendar .p-datepicker table td,
    :host ::ng-deep .calendar .p-datepicker table th {
      padding: .2rem !important;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span {
      width: 1.8rem;
      height: 1.8rem;
      border: none;
    }

    :host ::ng-deep .calendar .p-datepicker {
      // border: none !important;
      min-width: $calendar-min-width;
    }

    :host ::ng-deep .calendar .p-datepicker .p-datepicker-group-container {
      flex-wrap: wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
      padding: 0 .5rem 0 .5rem;
      user-select: none;
    }

    :host ::ng-deep .calendar .p-datepicker .p-datepicker-group-container .p-datepicker-group {
      flex: 0 0 auto !important;
      padding: 0.5rem 1rem 0.5rem 1rem !important;
      width: 25% !important;
      border: none !important;
    }

    :host ::ng-deep .calendar .p-datepicker table td.p-datepicker-today > span {
      box-shadow: none;
      border: none;
    }

    :host ::ng-deep .calendar .p-datepicker table td.p-datepicker-today > span > span {
      border-color: #0000cd;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span > .p-ink {
      display: none !important;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span > span {
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      border: 1px solid transparent;
      z-index: 1;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight > span.basic-holiday {
      background: var(--gray-700);
      color: #FFF;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight > span.basic-holiday:hover {
      background: var(--gray-800) !important;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span:not(.p-highlight) > span.basic-holiday {
      border-color: var(--gray-700);
    }

    :host ::ng-deep .calendar .p-datepicker table td > span > span.moved-holiday {
      text-decoration: line-through !important;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight > span.custom-holiday {
      background: var(--cyan-700);
      color: #FFF;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight > span.custom-holiday:hover {
      background: var(--cyan-800) !important;
    }

    :host ::ng-deep .calendar .p-datepicker table td > span:not(.p-highlight) > span.custom-holiday {
      border-color: var(--cyan-700);
    }


    :host ::ng-deep .calendar .p-datepicker table td > span.p-highlight {
      background: var(--gray-300);
    }

    :host ::ng-deep .calendar .p-datepicker:not(.p-disabled) table td > span.p-highlight:not(.p-disabled):hover {
      background: var(--gray-400) !important;
    }
  `],
  providers: []
})
export class HolidayCalendarEditorComponent extends AbstractControlValueAccessorComponent<THolidayCalendarSave> implements OnInit {
  @Input() showBasicCalendarChooser = false;
  @Input() editGeneralProperties = false;
  @Input() checkExists: (value: any) => Observable<boolean> = (value: any) => of(false);

  $calendarComponent!: Calendar;

  @ViewChild('holidayCalendar', {read: Calendar}) set calendarComponent(c: Calendar) {
    if (c) {
      this.$calendarComponent = c;
      this.calendarComponent.updateUI = () => {
        this.calendarComponent.currentMonth = 0;
        this.calendarComponent.currentYear = this.currentYear;
        this.calendarComponent.createMonths(this.calendarComponent.currentMonth, this.calendarComponent.currentYear);
      }
    }
  };

  nameCtrl?: FormControl;

  @ViewChild('nameModel') set nameModel(m: NgModel) {
    if (m) {
      this.nameCtrl = m.control;
    }
  }

  get calendarComponent(): Calendar {
    return this.$calendarComponent;
  }

  data!: THolidayCalendarSave;
  minDate = new Date(new Date().getFullYear() - 5, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 15, 0, 1);
  selectedPeriod: IPeriod = getCurrentPeriodValue('Year');
  selectedDates: Array<Date> = [];
  currentYear = this.selectedPeriod.start.getFullYear();
  currentYearHolidays: Array<IHoliday> = [];
  statusOptions = [...COMMON_STATUSES];
  holidaysChanged = false;
  basicCalendarId?: string;


  createHolidayData: {
    day?: Date,
    formData: Partial<IHoliday>;
    onApply?: (name: string) => void;
  } = {
    formData: {}
  };

  constructor(private control: NgControl,
              public elementRef: ElementRef,
              public propertyOptions: EntityPropertyOptionsService,
              public resource: AppResourceService) {
    super();
    this.control.valueAccessor = this;
  }

  ngOnInit(): void {
    if (this.editGeneralProperties) {
      const chk = this.checkExists;
      this.checkExists = (val: any) => {
        return chk(val).pipe( tap((exists) => {
          addOrRemoveControlErrors(this.control.control!, exists, ['exists']);
        }));
      }
    }
  }

  onChooseBasicCalendar(id: string): void {
    this.resource.getHolidayCalendar(id)
      .pipe()
      .subscribe((response) => {
        this.setData(response, true);
        this.applyValue();
      });
  }

  getHolidayByDate(dateOrMeta: IDateMeta | Date): IHoliday | undefined {
    return find(this.currentYearHolidays, (o) => this.cmp(dateOrMeta)(o.observedDate));
  }

  getHolidayByName(name: string): IHoliday | undefined {
    return find(this.currentYearHolidays, {name});
  }

  isMovedHoliday(dateOrMeta: IDateMeta | Date, byObserved: boolean): IHoliday | undefined {
    const holiday = find(this.currentYearHolidays,
      (o) => this.cmp(dateOrMeta)(byObserved ? o.observedDate : o.date));
    return (holiday != null && !this.cmp(holiday.date)(holiday.observedDate)) ? holiday : undefined;
  }

  getTooltip(dateOrMeta: IDateMeta | Date): string | undefined {
    let holiday = this.getHolidayByDate(dateOrMeta);
    if (holiday) {
      return holiday.name;
    }
    holiday = this.isMovedHoliday(dateOrMeta, false);
    if (holiday) {
      return `${holiday.name} - moved to ${(new DatePipe('en-US')).transform(holiday.observedDate, 'MM/dd/yy')}`;
    }
    return undefined;
  }

  getSelected(dateOrMeta: IDateMeta | Date): Date | undefined {
    return find(this.selectedDates || [], this.cmp(dateOrMeta));
  }

  cmp(dateOrMeta1: IDateMeta | Date): (dateOrMeta2: IDateMeta | Date) => boolean {
    const dateMeta1: IDateMeta = isDate(dateOrMeta1) ? this.dateToMeta(dateOrMeta1 as Date) : dateOrMeta1;
    return (dateOrMeta2: IDateMeta | Date) => {
      const dateMeta2: IDateMeta = isDate(dateOrMeta2) ? this.dateToMeta(dateOrMeta2 as Date) : dateOrMeta2;
      return this.metaToDate(dateMeta2).getTime() === this.metaToDate(dateMeta1).getTime();
    };
  }

  dateToMeta(date: Date): IDateMeta {
    return {
      day: date.getDate(),
      month: date.getMonth(),
      year: date.getFullYear()
    }
  }

  metaToDate(dateMeta: IDateMeta): Date {
    return new Date(dateMeta.year, dateMeta.month, dateMeta.day);
  }

  incrementDay(date: Date, inc = 1): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + inc);
  }

  onHolidayListChange(): void {
    this.applyValue();
    // this.calendarComponent.updateUI();
  }

  toggleDay(dateOrMeta: IDateMeta | Date): void {
    if (this.getSelected(dateOrMeta)) {
      remove(this.selectedDates, this.cmp(dateOrMeta));
    } else {
      const dateMeta: IDateMeta = isDate(dateOrMeta) ? this.dateToMeta(dateOrMeta as Date) : dateOrMeta;
      this.selectedDates.push(this.metaToDate(dateMeta));
    }
  }

  onClickDay(dateMeta: IDateMeta, event: MouseEvent, editorPanel: OverlayPanel, dayEl: any): void {
    event.preventDefault();
    event.stopPropagation();
    if (editorPanel.overlayVisible) {
      editorPanel.hide();
      return;
    }

    const select = () => {
      this.toggleDay(dateMeta);
      //(event.target as Element)?.parentElement?.click();
      this.selectedDates = sortBy(this.selectedDates);
      this.applyValue();
//      this.calendarComponent.updateUI();
    };

    const existedHoliday = this.getHolidayByDate(dateMeta);
    if (!existedHoliday) {
      this.createHolidayData = {
        day: this.metaToDate(dateMeta),
        formData: {},
        onApply: ((name) => {
          this.currentYearHolidays.push({
            name,
            date: this.metaToDate(dateMeta),
            observedDate: this.metaToDate(dateMeta),
            isCustom: true,
          });
          // this.currentYearHolidays = sortBy(this.currentYearHolidays, ['observedDate']);
          this.currentYearHolidays.sort((h1, h2) => h1.observedDate < h2.observedDate ? -1 : 1);
          select();
        })
      };
      editorPanel.show(event);
    } else {
      select();
    }
  }

  removeDay(event: MouseEvent, holiday: IHoliday): boolean {
    event.preventDefault();
    event.stopPropagation();
    remove(this.currentYearHolidays, {name: holiday.name});
    remove(this.selectedDates, this.cmp(holiday.observedDate));
    this.applyValue();
    return false;
  }

  onChangePeriod(): void {
    this.currentYearHolidays.forEach((h) => {
      h.isInactive = this.getSelected(h.observedDate) ? undefined : true;
    });
    this.currentYear = this.selectedPeriod.start.getFullYear();
    this.currentYearHolidays = find(this.data.holidayRange, {year: this.currentYear})!.holidays;
    this.selectedDates = this.currentYearHolidays.filter((h) => !!!h.isInactive).map((h) => h.observedDate);
  }


  applyValue(): void {
    this.checkValidity();
    this.holidaysChanged = true;
    this.currentYearHolidays.forEach((h) => {
      h.isInactive = this.getSelected(h.observedDate) ? undefined : true;
    });
    this.value = this.data;
    setTimeout(() => {
      this.onModelChange(this.value);
      this.onModelTouched();
    });
  }

  private setData(data: IHolidayCalendar, fromBasic: boolean): void {
    let _data = !!data ? cloneDeep(data) : {} as THolidayCalendarSave;
    this.selectedPeriod = getCurrentPeriodValue('Year');
    this.currentYear = this.selectedPeriod.start.getFullYear();

    if (_data.holidayRange == null || !_data.holidayRange.length) {
      _data.holidayRange = range(this.minDate.getFullYear(), this.maxDate.getFullYear()).map((year) => ({
        year,
        holidays: []
      }))
    }
    this.minDate = new Date(minBy(_data.holidayRange, 'year')!.year, 0, 1);
    this.maxDate = new Date(maxBy(_data.holidayRange, 'year')!.year, 0, 1);
    _data.holidayRange.forEach((yh) => yh.holidays.sort((h1, h2) => h1.observedDate < h2.observedDate ? -1 : 1));
    if (fromBasic) {
      if (this.editGeneralProperties) {
        _data.name += ' (copy)';
      } else {
        _data = omit(_data, ['name', 'description', 'status']) as any;
      }
      _data.baseId = data.id;
    }
    this.data = omit(_data, ['id', 'createdAt', 'updatedAt', 'holidayCount']) as any;
    this.onChangePeriod();
    this.checkValidity();
  }


  checkValidity(): void {
    if (this.editGeneralProperties) {
      setTimeout(()  => {
        const invalid = (this.data?.name == null);
        addOrRemoveControlErrors(this.control.control!, invalid, ['invalidData']);
      }, 100);
    }
  }

  override writeValue(value: any) {
    this.holidaysChanged = false;
    this.setData(value, false);
    if (value?.baseId) {
      this.basicCalendarId = value.baseId;
    }
    super.writeValue(value);
  }

}
