export const DB_API_ENDPOINTS = {
  schema: '/api/db/schema',
  relationships: '/api/db/relationships',
  checkSchema: '/api/db/schema/check',
  search: '/api/db/schema/:tableName/search',
  tableCrud: '/api/db/schema/:tableName/crud',
  table: '/api/db/schema/:tableName'
};

export interface IDbEnums {
  [name: string]: Array<string>;
}

export interface IDbRelationship {
  fromTable: string;
  toTable: string;
}

export enum EDbColumnConstraintType {
  pk = 'PK',
  fk = 'FK'
}

export interface IDbColumnConstraint {
  name: string;
  type: EDbColumnConstraintType;
  ordinalPosition: number;
  relTableName: string | null;
  relColumnsNames: Array<string> | null;
}

export type TDbType =
  'uuid' |
  'integer' | 'numeric' | 'currency' |
  'text' |
  'date' | 'datetime' | 'time' |
  'boolean' |
  'bit' |
  'json' |
  'enum' |
  'composite' |
  'unknown';

export type TDbTypeCategory =
  'uuid' | 'number' | 'string' | 'date' | 'boolean' | 'bit' | 'json' | 'unknown' | 'custom';

export interface IDbTypeDescriptor {
  name: TDbType;
  category: TDbTypeCategory;
  dbType: string;
}

export interface IDbColumn {
  name: string;
  origName: string;
  seqNumber: number;
  isRequired: boolean;
  isArray: boolean;
  arrayDimensions: number;
  defaultValue: string | null;
  type: IDbTypeDescriptor;
  constraints: { [type in EDbColumnConstraintType]?: IDbColumnConstraint } | null;
}

export interface IDbTable {
  name: string;
  hash: string;
  columns: Array<IDbColumn>;
}

export interface IDbSchema {
  tables: Array<IDbTable>;
  enums: IDbEnums;
}

export interface IDbCyclicDependence {
  sequence: Array<string>;
  references: Array<{
    tableName: string;
    columnName: string;
    isExternal: boolean;
  }>;
}

export interface IDbSchemaInconsistencies {
  cyclicDependencies: Array<IDbCyclicDependence>;
}
