import {Component, Input, ViewChild} from '@angular/core';
import {IDbColumn, IDbSchema} from '../../../../api/shared/dev-tools/db-api';
import {ColumnFilter} from 'primeng/table';
import {TableQuery} from '../../../../common/util/table-query';

@Component({
  selector: 'app-db-column-filter',
  template: `
    <ng-container *ngIf="!column.isArray; else arr">
      <ng-container *ngIf="column.constraints?.FK && column.type.name !== 'text'; else notFK">
        <p-columnFilter #cf [field]="column.name" matchMode="in" display="menu"
                        [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-overlayPanel #chooserPanel [style]="{width: '800px'}"
                            [showCloseIcon]="true"
                            styleClass="mt-pk-chooser-panel"
                            (onShow)="cf.unbindDocumentClickListener()"
                            (onHide)="cf.bindDocumentClickListener()">
              <ng-template pTemplate>
                <app-db-pk-chooser [schema]="schema"
                                   [tableName]="column.constraints!.FK!.relTableName!"
                                   [pkColumnName]="column.constraints!.FK!.relColumnsNames![0]"
                                   [selected]="chipsModel.value"
                                   (onSelect)="chipsModel.control.setValue($event.length ? $event : null)">
                </app-db-pk-chooser>
              </ng-template>
            </p-overlayPanel>
            <span class="p-input-icon-right">
                  <i class="pi pi-table cursor-pointer"
                     (click)="chooserPanel.toggle($event)"
                     [pTooltip]="'Open ' + (column.constraints!.FK!.relTableName! | dbNormalizeName) + ' to select from '"
                     tooltipPosition="bottom"></i>
                  <p-chips #chipsModel="ngModel"
                           class="mt-filter-chips"
                           [ngModel]="value"
                           placeholder="Any"
                           [addOnBlur]="true" [addOnTab]="true"
                           (ngModelChange)="filter($event)" nullable>
                  </p-chips>
                </span>
          </ng-template>
        </p-columnFilter>
      </ng-container>
      <ng-template #notFK>
        <ng-container [ngSwitch]="column.type.name">
          <ng-container *ngSwitchCase="'date'">
            <p-columnFilter type="date" [field]="column.name" display="menu" class="ml-auto">
            </p-columnFilter>
          </ng-container>
          <ng-container *ngSwitchCase="'datetime'">
            <p-columnFilter type="date" [field]="column.name" display="menu" class="ml-auto"
                            matchMode="dateBefore" [matchModeOptions]="tableQuery.getMatchModeOptions('timestamp')">
            </p-columnFilter>
          </ng-container>
          <ng-container
            *ngSwitchCase="['integer', 'numeric', 'currency'].includes(column.type.name) ? column.type.name : ''">
            <p-columnFilter type="numeric" [field]="column.name" display="menu" class="ml-auto">
            </p-columnFilter>
          </ng-container>
          <ng-container *ngSwitchCase="'text'">
            <p-columnFilter type="text" [field]="column.name" display="menu" class="ml-auto">
            </p-columnFilter>
          </ng-container>
          <ng-container *ngSwitchCase="'boolean'">
            <p-columnFilter type="boolean" matchMode="is"
                            [field]="column.name" display="menu"></p-columnFilter>
          </ng-container>
          <ng-container *ngSwitchCase="'enum'">
            <p-columnFilter [field]="column.name" matchMode="in" display="menu"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value" [options]="schema.enums[column.type.dbType] | options"
                               placeholder="Any"
                               selectedItemsLabel="{0} items selected"
                               optionLabel="label" optionValue="value"
                               (onChange)="filter($event.value)">
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <p-columnFilter type="text" [field]="column.name" display="menu" class="ml-auto"
                            matchMode="equals" [matchModeOptions]="tableQuery.getMatchModeOptions('default')">
            </p-columnFilter>
          </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #arr>
      <ng-container [ngSwitch]="column.type.name">
        <ng-container *ngSwitchCase="'enum'">
          <p-columnFilter [field]="column.name" matchMode="contains" display="menu"
                          [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="schema.enums[column.type.dbType] | options"
                             placeholder="Contains"
                             selectedItemsLabel="{0} items selected"
                             optionLabel="label" optionValue="value"
                             (onChange)="filter($event.value)">
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <!-- && column.type.category === 'string' -->
          <ng-container *ngIf="column.arrayDimensions === 1; else default">
            <p-columnFilter [field]="column.name" matchMode="contains" display="menu"
                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-chips [ngModel]="value" placeholder="Contains Elements"
                         [addOnBlur]="true" [addOnTab]="true"
                         (ngModelChange)="filter($event)" nullable>
                </p-chips>
              </ng-template>
            </p-columnFilter>
          </ng-container>
          <ng-template #default>
            <p-columnFilter type="text" [field]="column.name" display="menu" class="ml-auto"
                            matchMode="equals" [matchModeOptions]="tableQuery.getMatchModeOptions('default')">
            </p-columnFilter>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-template>
  `,
  styles: [`
    ::ng-deep .mt-pk-chooser-panel .p-overlaypanel-close {
      z-index: 1;
    }

    ::ng-deep .mt-filter-chips .p-chips-multiple-container {
      min-width: 350px;
      max-width: 600px;
      overflow-y: auto;
      max-height: 300px;
    }
  `]
})
export class DbColumnFilter {
  @Input() schema!: IDbSchema;
  @Input() column!: IDbColumn;
  @ViewChild(ColumnFilter) columnFilter!: ColumnFilter;
  tableQuery: TableQuery = new TableQuery();

  constructor() {
  }

  clearFilter(): void {
    if (this.columnFilter) {
      this.columnFilter.initFieldFilterConstraint();
    }
  }
}
