import {Component, Input, ViewChild} from '@angular/core';
import {ColumnExpansionTableBaseComponent} from './column-expansion-table.component';
import {finalize, Observable} from 'rxjs';
import {LazyLoadEvent} from 'primeng/api';
import {
  ESortDir,
  ISearchRequest,
  ISearchResponse,
  TQueryExpression,
  TQueryOperator
} from '../../../api/shared/search-api';
import {TableQuery} from '../../../common/util/table-query';
import {TreeTable} from 'primeng/treetable';
import {IDetails} from '../../../api/shared/app-domain/details';

@Component({
  selector: 'app-details-table',
  template: `
    <p-dialog [(visible)]="dialog.show"
              [closable]="true"
              [modal]="true" [style]="{width: '30vw'}" appendTo="body">
      <ng-template pTemplate="header">
        <div class="text-2xl">{{dialog.title}}</div>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (onClick)="dialog.show=false" label="Apply" styleClass="p-button-text"></p-button>
        <p-button icon="pi  pi-times" (onClick)="dialog.show=false" label="Close" styleClass="p-button-text"></p-button>
      </ng-template>
      <p-skeleton height="250px"></p-skeleton>
    </p-dialog>
    <app-spinnerizer [active]="loading" [target]="container" spinnerPosition="top"></app-spinnerizer>
    <div #container>
      <p-treeTable [value]="nodes"
                   [columns]="columns"
                   class="mt-budget-table"
                   [lazy]="useSearchRequest"
                   (onLazyLoad)="load($any($event))"
                   [paginator]="useSearchRequest"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                   [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
                   [showCurrentPageReport]="true"
                   [lazyLoadOnInit]="false"
                   [rows]="pageSize"
                   [totalRecords]="total">
        <ng-template pTemplate="header" let-columns>
          <app-column-expansion-table-header [table]="this"></app-column-expansion-table-header>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-node="node" let-rowData="rowData">
          <ng-container *ngIf="node.type !== 'summary' && node.type !== 'extra'; else summaryTpl">
            <tr [class.mt-section-row]="node.type === 'section'"
                [class.mt-has-children]="!node.leaf">
              <td *ngFor="let col of columns; let i = index" class="relative"
                  [class.mt-value-cell]="col.periodIndex != null"
                  [class.mt-has-popup-cell]="col.periodIndex === popupColumn"
                  (click)="togglePopupColumn(col.periodIndex); cellClick.emit({mouseEvent: $event, node, rowData, column: col})">
                <ng-container *ngIf="node.type !== 'section'">
                  <div class="mt-nested-row-cell-top"></div>
                </ng-container>
                <ng-container *ngIf="i === 0; else val">
                  <app-column-expansion-table-category-cell [table]="this" [node]="node" [rowNode]="rowNode">
                  </app-column-expansion-table-category-cell>
                </ng-container>
                <ng-template #val>
                  <ng-container *ngIf="col.periodIndex != null; else extra">
                    <app-column-expansion-table-popup-cell [table]="this" [node]="node"
                                                           [col]="col"></app-column-expansion-table-popup-cell>
                    <app-column-expansion-table-data-cell [table]="this" [node]="node"
                                                          [col]="col"></app-column-expansion-table-data-cell>
                  </ng-container>
                  <ng-template #extra>
                    <ng-container *ngIf="extraFieldsTplRef">
                      <ng-container
                        *ngTemplateOutlet="extraFieldsTplRef; context: {$implicit: col.extraDataField, node, rowData}"></ng-container>
                    </ng-container>
                  </ng-template>
                </ng-template>
              </td>
            </tr>
          </ng-container>
          <ng-template #summaryTpl>
            <app-column-expansion-table-summary-row [table]="this"></app-column-expansion-table-summary-row>
          </ng-template>
        </ng-template>
      </p-treeTable>
    </div>
  `,
  styles: [`
  `]
})
export class DetailsTableComponent extends ColumnExpansionTableBaseComponent {
  @Input() fetch!: (searchRequest: ISearchRequest) => Observable<IDetails<any> | ISearchResponse<IDetails<any>>>;
  @Input() useSearchRequest = false;

  @ViewChild(TreeTable, {static: false}) tableComponent!: TreeTable;

  pageSize = 10;
  total = 0;
  tableQuery: TableQuery = new TableQuery();

  constructor() {
    super();
  }

  override load(event?: LazyLoadEvent): void {
    setTimeout(() => this.loading = true);
    let searchRequest: ISearchRequest = {};
    if (this.useSearchRequest) {
      searchRequest = this.tableQuery.prepareQuery(
        event || this.tableComponent.createLazyLoadMetadata(), {
          pageSize: this.pageSize,
        }
      );
      if (searchRequest.query || this.query || this.searchEntryEntities) {
        const query: TQueryExpression = {
          logical: 'and',
          predicates: [
            ...(this.searchEntryEntities ? (this.searchEntryEntitiesModel?.length > 0 ? [{field: 'id', operator: 'in' as TQueryOperator, value: this.searchEntryEntitiesModel}]: []) : []),
            ...(this.query?.predicates || []),
            ...((searchRequest.query as TQueryExpression)?.predicates || [])
          ]
        }
        searchRequest.query = query;
      }
      searchRequest.sort = [{field: 'name', dir: ESortDir.ASC}]; // TODO: sort field
    }
    this.fetch(searchRequest).pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((response) => {
      let data: IDetails<any>;
      if (this.useSearchRequest) {
        const searchResponse = response as ISearchResponse<IDetails<any>>;
        data = searchResponse.results[0];
        this.total = searchResponse.total;
      } else {
        data = response  as IDetails<any>;
      }
      this.onLoad(data);
    });
  }

}
