import {Component, Input, ViewChild, ViewChildren} from '@angular/core';
import {FormControlWrapperComponent, TFormControlWrapperType} from '../../common/components/form-control-wrapper.component';
import {EntityPropertyOptionsService, TEntityPropertyOption} from './entity-property-options.service';

@Component({
  template: '',
})
export abstract class EntityFormControlBaseComponent<T extends {}> {
  @Input() name!: keyof T;
  @ViewChild(FormControlWrapperComponent) formControlWrapper?: FormControlWrapperComponent;

  relatedFields: {[field in keyof T]?: {relatedField: string; entityPropertyOption: TEntityPropertyOption}} = {};

  protected constructor(public propertyOptions: EntityPropertyOptionsService) {
  }

  onApplyNewRelatedEntity(ctrl: FormControlWrapperComponent, relatedEntityData: any): void {
    const rel = (this.relatedFields as any)[ctrl.controlName];
    if (rel) {
      if (rel.entityPropertyOption) {
        this.propertyOptions.addOptionItem(rel.entityPropertyOption, relatedEntityData);
      }
      const value = relatedEntityData[rel.relatedField];
      if (ctrl.isArrayType()) {
        ctrl.setValueAndMark([...(ctrl.value || []), ...[value]]);
      } else {
        ctrl.setValueAndMark(value);
      }
    }
  }
}
