import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppResourceService} from '../../app.resource.service';
import {
  PROJECT_SCOPES,
  PROJECT_STATUS,
  PROJECT_TYPE,
  TProjectScope,
  TProjectStatus,
  TProjectType
} from '../../api/shared/app-domain/project';
import {ServiceLocator} from '../../common/util/util';
import { MenuItem, SelectItem } from 'primeng/api';
import {AppConfigService} from '../../app-config.service';
import {IViewAsItem} from '../../api/shared/user-api';
import {getCurrentPeriodValue} from '../../common/components/period-chooser.component';
import { getProjectTypeIcon, PROJECT_TABS, TProjectTab } from './projects';
import {TQuery, TQueryExpression} from '../../api/shared/search-api';
import {IPeriod} from '../../api/shared/app-domain/common';
import { IScopeFilter } from '../common/common';

const PROJECT_TAB_MENU: Array<MenuItem> = [
  {
    label: 'Listing',
    routerLink: ['/projects'],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'listing' as TProjectTab
    }
  },
  {
    label: 'Details',
    routerLink: ['/projects'],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'details' as TProjectTab
    }
  },
  {
    label: 'Planning',
    routerLink: ['/projects'],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'planning' as TProjectTab
    }
  }
];

@Component({
  template: `
    <p-dialog [(visible)]="showFilterDialog"
              [closable]="true"
              [modal]="true" [style]="{width: '600px'}" appendTo="body">
      <ng-template pTemplate="header">
        <div class="flex align-items-center w-full justify-content-between">
          <div class="text-2xl">Filter by</div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-filter-slash" (onClick)="clearFilters()" label="Clear"
                  [disabled]="!typeFilter.length && !statusFilter.length"
                  styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-check" (onClick)="showFilterDialog=false; applyFilters()" label="Apply"
                  styleClass="p-button-text"></p-button>
        <p-button icon="pi  pi-times" (onClick)="showFilterDialog=false" label="Cancel"
                  styleClass="p-button-text"></p-button>
      </ng-template>
      <div class="grid">
        <div class="col-6">
          <div class="text-gray-700 mb-3">Project Type</div>
          <div *ngFor="let item of typeOptions" class="field-checkbox">
            <p-checkbox [value]="item" [(ngModel)]="typeFilter"></p-checkbox>
            <span class="inline-flex align-items-center ml-3">
              <img [src]="getProjectTypeIcon(item)" height="16" width="16" class="mr-2"/>
              {{item}}
            </span>
          </div>
        </div>
        <div class="col-6">
          <div class="text-gray-700 mb-3">Project Status</div>
          <div *ngFor="let item of statusOptions" class="field-checkbox">
            <p-checkbox [value]="item" [(ngModel)]="statusFilter"></p-checkbox>
            <span class="ml-3">
              {{item}}
            </span>
          </div>
        </div>
      </div>
    </p-dialog>
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="flex align-items-baseline">
              <div class="mt-page-title">Projects</div>
              <div class="text-gray-700 ml-3">
                {{!statusFilter.length || statusFilter.length === 4 ? 'All statuses' : statusFilter.join(', ')}}
              </div>
            </div>
            <div class="text-gray-700 text-sm">{{totalProjects}} project{{totalProjects ? 's' : ''}}</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <p-menubar class="mt-page-menu-bar" [model]="tabMenuItems"></p-menubar>
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
            <button pButton pRipple label="Actions"
                    class="p-button-outlined ml-4"
                    [style]="{width: '115px'}"
                    icon="pi pi-chevron-down"
                    iconPos="right" (click)="actionsMenu.toggle($event)"></button>
            <button pButton pRipple label="New Project"
                    class="ml-2"
                    [style]="{width: '120px'}"
                    icon="pi pi-plus" (click)="tbd()"></button>
          </div>
        </div>
      </div>
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-7 flex align-items-center">
            <app-view-as-chooser #viewAsChooser [(ngModel)]="viewAsItem" (ngModelChange)="onChangeViewAs()">
            </app-view-as-chooser>
            <app-schedule-chooser [(ngModel)]="schedule" class="ml-2"></app-schedule-chooser>
            <span class="ml-2 p-float-label">
                <p-dropdown [ngModel]="scope" [options]="scopeItems"
                            (ngModelChange)="router.navigate([],
                        {relativeTo: activatedRoute, queryParams: {scope: $event}, queryParamsHandling: 'merge'})">
                </p-dropdown>
              <label>Scope</label>
            </span>
            <button pButton pRipple type="button" label="Filter"
                    [icon]="'pi ' + (hasFilter() ? 'pi-filter-fill' :  'pi-filter')"
                    class="p-button-outlined ml-2" (click)="showFilterDialog=true">
            </button>
            <div *ngIf="scopeFilters[scope]" class="flex align-items-center ml-4 ">
              <span>Showing {{scope === 'all' ? 'Projects' : 'Clients'}} for {{scope === 'all' ? 'Client' : 'Agency'}}
                :</span>
              <span class="text-gray-600  font-semibold ml-1"> {{scopeFilters[scope]?.name}}</span>
              <button pButton pRipple type="button" icon="pi pi-times"
                      class="p-button-sm p-button-rounded p-button-text"
                      (click)="scopeFilters[scope]=null; applyFilters()"></button>
            </div>
          </div>
          <div class="col-5 flex justify-content-end">
            <app-period-chooser [(ngModel)]="period" [unitFilter]="['Day', 'Week', 'Month']"></app-period-chooser>
          </div>
        </div>
      </div>
    </div>
    <app-view-as-alert [viewAsItem]="viewAsItem" [viewAsChooser]="viewAsChooser"></app-view-as-alert>
    <!--    <div class="mt-3 pb-2 border-bottom-1 border-300">-->
    <div class="mt-3">
      <div class="grid">
        <div class="col-3" *ngFor="let i of 4 | arrayOf">
          <div class="shadow-1"
               style="background: #FFF; border: 1px solid var(--surface-300); height: 10rem; font-size: 12px; padding: 2rem; color: var(--gray-500)">
            TBD
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 shadow-1 bg-white p-3 pb-2">
      <ng-container *ngIf="tab==='listing'">
        <app-projects-table
          [scope]="scope" *appRecreateViewKey="scope"
          [query]="query"
          (changeScopeFilter)="scopeFilters[$event.scope] = $event.scopeFilter"></app-projects-table>
      </ng-container>
      <ng-container *ngIf="tab === 'details'">
        <ng-container *ngIf="scope === 'all'">
          <app-all-projects-details
            [query]="query"
            [period]="period"></app-all-projects-details>
        </ng-container>
        <ng-container *ngIf="scope === 'client'">
          <app-all-clients-details
            [query]="query"
            [period]="period"></app-all-clients-details>
        </ng-container>
        <ng-container *ngIf="scope === 'agency'">
          <app-all-agencies-details
            [query]="query"
            [period]="period"></app-all-agencies-details>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="tab ==='planning'">
        <div class="pt-5 pb-5">TBD: {{scope}}: {{tab}}</div>
      </ng-container>
    </div>
  `,
  styles: [`
    :host ::ng-deep .p-datatable .p-paginator-bottom {
      border: none;
    }
  `]
})
export class ProjectsPageComponent implements OnInit {
  scopeItems: Array<SelectItem> = [
    {label: 'All Projects', value: 'all' as TProjectScope},
    {label: 'By Client', value: 'client' as TProjectScope},
    {label: 'By Agency', value: 'agency' as TProjectScope}
  ];
  tabMenuItems = PROJECT_TAB_MENU;
  tab: TProjectTab = 'listing';

  totalProjects = 0;

  scope: TProjectScope = 'all';

  actionItems: Array<MenuItem> = [
    {label: 'Sync Accounting', command: this.tbd},
    {label: 'Sync Timekeeping', command: this.tbd}
  ];

  statusOptions = PROJECT_STATUS;
  statusFilter: Array<TProjectStatus> = [];

  typeOptions = PROJECT_TYPE;
  typeFilter: Array<TProjectType> = [];

  schedule: string = 'project';
  period: IPeriod = getCurrentPeriodValue();
  viewAsItem: IViewAsItem | null = null;

  showFilterDialog = false;

  query: TQueryExpression | null = null;

  scopeFilters: { [scope in TProjectScope]?: IScopeFilter | null } = {
    all: null,
    client: null
  };

  constructor(public activatedRoute: ActivatedRoute,
              public router: Router,
              private resource: AppResourceService,
              public appConfig: AppConfigService) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const scope: TProjectScope = params.get('scope') as TProjectScope;
      if (!!!scope || !PROJECT_SCOPES.includes(scope)) {
        this.router.navigate([],
          {relativeTo: this.activatedRoute, queryParams: {scope: 'all'} , queryParamsHandling: 'merge'});
      } else {
        this.scope = scope;
      }
      const tab: TProjectTab = params.get('tab') as TProjectTab;
      if (!!!tab || !PROJECT_TABS.includes(tab)) {
        this.router.navigate([],
          {relativeTo: this.activatedRoute, queryParams: {tab: 'listing'}, queryParamsHandling: 'merge'});
      } else {
        this.tab = tab;
      }
      const status = params.get('status');
      if (!!status && PROJECT_STATUS.includes(status as any)) {
        this.statusFilter = [status as TProjectStatus];
        this.router.navigate([],{relativeTo: this.activatedRoute, queryParams: {scope: this.scope}})
      }
      const scopeFilter = params.get('scopeFilter');
      const id = params.get('id');
      if (!!scopeFilter && ['clientId', 'agencyId'].includes(scopeFilter) && !!id) {
        this.scopeFilters[this.scope] = {id, name: params.get('name') || '?', field: scopeFilter};
        this.router.navigate([],{relativeTo: this.activatedRoute, queryParams: {scope: this.scope}})
      }
      this.applyFilters();
    });
    this.viewAsItem = this.appConfig.viewAsMe;
  }

  ngOnInit(): void {
    this.fetchCount();
  }

  onChangeViewAs(): void {
    // reload table
  }

  fetchCount(): void {
    this.resource.getProjectCount({
      query: this.query || undefined
    }).subscribe((count) => this.totalProjects = count);
  }

  hasFilter(): boolean {
    return !!this.typeFilter.length || !!this.statusFilter.length;
  }

  applyFilters(): void {
    if (this.hasFilter() || !!this.scopeFilters[this.scope]) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: []
      }
      if (this.typeFilter.length) {
        query.predicates.push({
          field: 'type',
          operator: 'in',
          value: this.typeFilter
        });
      }
      if (this.statusFilter.length) {
        query.predicates.push({
          field: 'status',
          operator: 'in',
          value: this.statusFilter
        });
      }
      if (!!this.scopeFilters[this.scope]) {
        query.predicates.push({
          field: this.scopeFilters[this.scope]!.field,
          operator: 'equals',
          value: this.scopeFilters[this.scope]!.id
        });
      }
      this.query = query;
    } else {
      this.query = null;
    }
    this.fetchCount();
  }

  clearFilters(): void {
    this.statusFilter = [];
    this.typeFilter = [];
  }

  getProjectTypeIcon(type: TProjectType): string | null {
    return getProjectTypeIcon(type);
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }

}
