import {Component, Input} from '@angular/core';
import {NgControl, NgModel} from '@angular/forms';
import {AbstractControlValueAccessorComponent} from '../../common/components/abstract-control-value-accessor.component';
import {IWorkweek} from '../../api/shared/app-domain/settings';
import {IIdentified, WEEK_DAYS} from '../../api/shared/app-domain/common';
import {IDictionary} from '../../common/types';
import {cloneDeep, isEmpty, isEqual, padStart, range, remove, some, sortBy, sum} from 'lodash';
import {DateTime} from 'luxon';
import {ServiceLocator} from '../../common/util/util';

@Component({
  selector: 'app-workweek-editor',
  template: `
    <div class="bg-white shadow-1 p-4" style="width: fit-content">
      <p-table [value]="workweeks" dataKey="id"
               editMode="row" tableStyleClass="w-auto"
               [editingRowKeys]="editingRowKeys">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let weekday of 7 | arrayOf; let i = index" class="day-cell"
                [style.filter]="i === 5 || i === 6 ? ' brightness(97%)' : 'none'">
              {{weekdays[i]}}
            </th>
            <th class="text-center" style="width: 60px">Total</th>
            <th *ngIf="usePeriod" class="text-center" style="width: 180px">Start Date</th>
            <th *ngIf="usePeriod" class="text-center" style="width: 180px">End Date</th>
            <th *ngIf="usePeriod" class="text-center" style="width: 100px">Active</th>
            <th class="text-center" style="width: 100px;">Name</th>
            <th class="flex align-items-center justify-content-center" style="width: 120px">
              <span class="mr-1">Actions</span>
              <button pButton pRipple type="button" icon="pi pi-plus" [disabled]="!canAdd()"
                      (click)="addNew()"
                      class="p-button-rounded p-button-text p-button-danger"></button>

            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="rowData">
            <td *ngFor="let weekday of 7 | arrayOf; let i = index" class="day-cell"
                [class.bg-gray-100]="i === 5 || i === 6">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputMask [(ngModel)]="rowData.hours[i]" mask="99:99" slotChar="0" placeholder="00:00"
                               styleClass="day-editor"></p-inputMask>
                </ng-template>
                <ng-template pTemplate="output">
                  <span (click)="canEdit() ? edit(rowData) : null">{{rowData.hours[i]}}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td class="text-center text-gray-600">
              {{total(rowData)}}
            </td>
            <td *ngIf="usePeriod" class="text-center p-fluid">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <ng-container *ngIf="ri > 0">
                    <p-calendar #calModel="ngModel" [selectOtherMonths]="true"
                                [(ngModel)]="rowData.startDate" appendTo="body" [firstDayOfWeek]="1"
                                [readonlyInput]="true" [showIcon]="true" placeholder="Select a Monday"
                                [class.ng-dirty]="true"
                                [exists]="isExists(rowData)"
                                (ngModelChange)="onSelectDate(rowData, calModel)"
                                inputStyleClass="text-center"></p-calendar>
                    <app-control-error [control]="calModel.control"></app-control-error>
                  </ng-container>
                  <span *ngIf="ri === 0" class="text-gray-600">The beginning of time</span>
                </ng-template>
                <ng-template pTemplate="output">
                  <span *ngIf="ri === 0" class="text-gray-600">The beginning of time</span>
                  <span *ngIf="ri > 0" (click)="canEdit() ? edit(rowData) : null">
                      {{rowData.startDate | date}}
                    </span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td *ngIf="usePeriod" class="text-center">
              <span
                class="text-gray-600">{{intervals[rowData.id].endDate ? (intervals[rowData.id].endDate | date) : 'The end of time'}}</span>
            </td>
            <td *ngIf="usePeriod" class="text-center">
              <i *ngIf="isActive(rowData)" class="pi pi-check-circle"></i>
            </td>
            <td style="max-width: 100px">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <ng-container *ngIf="ri > 0">
                    <input #model="ngModel" pInputText [(ngModel)]="rowData.name" nullable required
                           [class.ng-dirty]="true" style="width: 100%" (ngModelChange)="checkValidity()">
                    <app-control-error [control]="model.control"></app-control-error>
                  </ng-container>
                  <div *ngIf="ri === 0" class="text-gray-600">{{rowData.name}}</div>
                </ng-template>
                <ng-template pTemplate="output">
                  <div *ngIf="ri === 0" class="text-gray-600">{{rowData.name}}</div>
                  <div *ngIf="ri > 0" (click)="canEdit() ? edit(rowData) : null"
                       class="mt-overflow-ellipsis" appTooltipOnOverflow>
                    {{rowData.name}}</div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <div class="flex align-items-center justify-content-center relative">
                <i *ngIf="!isValid(rowData)"
                   class="text-xs pi pi-exclamation-triangle absolute right-0 top-0 p-error"></i>
                <ng-container *ngIf="!editing">
                  <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                          [disabled]="!canEdit()"
                          (click)="edit(rowData)" class="p-button-rounded p-button-text"></button>
                  <button pButton pRipple type="button" icon="pi pi-trash"
                          [disabled]="!canRemove(rowData, ri)"
                          (click)="remove($event, rowData, ri)" class="p-button-rounded p-button-text"></button>
                </ng-container>
                <ng-container *ngIf="editing">
                  <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                          [disabled]="!canSave(rowData)"
                          (click)="save(rowData, ri)"
                          class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                  <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                          (click)="cancelEdit(rowData, ri)"
                          class="p-button-rounded p-button-text p-button-danger"></button>
                </ng-container>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [`
    .day-cell {
      width: 70px;
      text-align: center !important;
    }

    :host ::ng-deep .p-inputtext.day-editor {
      width: 50px;
      text-align: center !important;
    }
  `],
  providers: [
    // accessorProvider(WorkweekEditorComponent)
  ]
})
export class WorkweekEditorComponent extends AbstractControlValueAccessorComponent<Array<IWorkweek>> {
  @Input() canRemoveDefault = true;
  @Input() usePeriod = true;
  workweeks: Array<IWorkweek> = [];
  weekdays: Array<string> = WEEK_DAYS.map((wd) => wd.substring(0, 3));
  cache: IDictionary<IWorkweek> = {};
  editingRowKeys: IDictionary<boolean> = {};
  intervals: IDictionary<{startDate: Date | null; endDate: Date | null}> = {};
  newId = 0;

  constructor(private control: NgControl) {
    super();
    this.control.valueAccessor = this;
  }

  updateIntervals(): void {
    if (this.usePeriod) {
      const sorted = sortBy(this.workweeks, (w) => {
        const start = w.startDate == null ? new Date(0, 0, 0) : w.startDate;
        return start.getTime();
      });
      const intervals: IDictionary<{ startDate: Date | null; endDate: Date | null }> = {};
      sorted.forEach((ww, i) => {
        intervals[ww.id] = {
          startDate: ww.startDate,
          endDate: i === sorted.length - 1 ? null :
            new Date(sorted[i + 1].startDate!.getFullYear(), sorted[i + 1].startDate!.getMonth(), sorted[i + 1].startDate!.getDate() - 1)
        }
      });
      this.intervals = intervals;
    }
    this.checkValidity();
  }

  checkValidity(): void {
    const invalid = some(this.workweeks, (w) => !this.isValid(w));
    this.control.control?.setErrors(invalid ? {invalidData: true} : null);
  }

  defaultHours(): Array<string> {
    return range(0, 7).map((v) => v >= 0 && v < 5 ? '08:00' : '00:00');
  }

  total(workweek: IWorkweek): string {
    const minutes = sum(workweek.hours.map((v) => {
      const [h, m] = v.split(':');
      return +h * 60 + (+m);
    }));
    return `
      ${padStart((~~(minutes / 60)).toString(), 2, '0')}:${padStart((minutes % 60).toString(), 2, '0')}
    `;
  }

  isExists(workweek: IWorkweek): (val?: any) => boolean {
    for (const ww of this.workweeks) {
      if(ww.id !== workweek.id && workweek.startDate != null && ww.startDate?.getTime() === workweek.startDate!.getTime()) {
        return () => true;
      }
    }
    return () => false;
  }

  onSelectDate(workweek: IWorkweek, model: NgModel): void {
    workweek.startDate = this.startOfThisWeek(workweek.startDate!);
    this.updateIntervals();
  }

  startOfThisWeek(date: Date): Date {
    return DateTime.fromJSDate(date).startOf('week').toJSDate();
  }

  startOfNextWeek(date: Date): Date {
    return DateTime.fromJSDate(date).endOf('week').plus({days: 1}).toJSDate();
  }

  isActive(workweek: IWorkweek): boolean {
    const current = new Date().getTime();
    const interval = this.intervals[workweek.id];
    if (!!interval
      && (interval.startDate ?? new Date(0, 0, 0)).getTime() <= current
      && (interval.endDate ?? new Date(2100, 0, 0)).getTime() >= current) {
      return true;
    }
    return false;
  }

  canEdit(): boolean {
    return !this.isDisabled && isEmpty(this.editingRowKeys);
  }

  edit(workweek: IWorkweek): void {
    this.editingRowKeys[workweek.id] = true;
    this.cache[workweek.id] = cloneDeep(workweek);
  }

  cancelEdit(workweek: IWorkweek, index: number): void {
    this.workweeks[index] = this.cache[workweek.id];
    delete this.cache[workweek.id];
    delete this.editingRowKeys[workweek.id];
    // if (this.isNewRecord(workweek)) {
    //   if (!this.canSave(workweek)) {
    //     remove(this.workweeks, {id: workweek.id});
    //   }
    // }
    this.updateIntervals();
  }

  canRemove(workweek: IWorkweek, rowIndex: number): boolean {
    return !this.isDisabled && (rowIndex > 0 || this.canRemoveDefault)
  }

  remove(ev: any, workweek: IWorkweek, index: number): void {
    const onRemove = () => {
      delete this.editingRowKeys[workweek.id];
      this.workweeks.splice(index, 1);
      delete this.cache[workweek.id];
      this.applyValue();
      this.updateIntervals();
    };
    if (!this.isNewRecord(workweek)) {
      ServiceLocator.confirm({
        target: ev.target,
        key: 'g-popup',
        header: 'Delete Confirmation',
        message: `Are you sure you want to delete workweek "${workweek.name}"?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          onRemove();
        }
      });
    } else {
      onRemove();
    }
  }

  isValid(workweek: IWorkweek): boolean {
    return !!workweek.name && !this.isExists(workweek)();
  }

  canSave(workweek: IWorkweek): boolean {
    return !this.isDisabled && this.isValid(workweek) && !isEqual(workweek, this.cache[workweek.id]);
  }

  save(workweek: IWorkweek, index: number): void {
    delete this.cache[workweek.id];
    delete this.editingRowKeys[workweek.id];
    this.applyValue();
    this.updateIntervals();
  }

  applyValue(): void {
    this.value = this.workweeks.map((ww) => ({...ww, ...{id: this.isNewRecord(ww) ? null : ww.id} as IIdentified}));
    setTimeout(() => {
      this.onModelChange(this.value);
      this.onModelTouched();
    });
  }

  canAdd(): boolean {
    return !this.isDisabled && isEmpty(this.editingRowKeys);
  }

  addNew(): void {
    const workweek: IWorkweek = {
      id: (++this.newId).toString(),
      hours: this.defaultHours(),
      startDate: !this.usePeriod || !this.workweeks.length ? null : this.startOfThisWeek(new Date()),
      name: !this.workweeks.length ? 'Default' : null as any
    } as IWorkweek;
    this.workweeks.push(workweek);
    if (this.workweeks.length === 1) {
      this.applyValue();
    } else {
      this.editingRowKeys[workweek.id] = true;
      this.edit(workweek);
    }
    this.updateIntervals();
  }


  isNewRecord(workweek: IWorkweek): boolean {
    return !isNaN(workweek.id as any);
  }

  override writeValue(value: any) {
    this.cache = {};
    this.editingRowKeys = {};
    this.workweeks = value ? [...value] : [];
    super.writeValue(value);
    this.updateIntervals();
  }
}
