import {Component, OnInit} from '@angular/core';
import {AppResourceService} from '../../../app.resource.service';
import {IGraphData, IGraphLink, IGraphNode} from '../../../common/components/relationship-graph.component';
import {finalize, forkJoin} from 'rxjs';
import {filter, find} from 'lodash';
import {Router} from '@angular/router';
import {IHsObjectType} from '../../../api/shared/dev-tools/hs-api';
import { HsService } from '../../../services/hs/hs.service';

@Component({
  selector: 'app-hs-relationship-diagram-page',
  template: `
    <app-spinnerizer [active]="loading"
                     [invertSpinner]="false"
                     [invertText]="true"
                     text="Loading schemas..."
                     [target]="container">
    </app-spinnerizer>
    <div #container class="svg-container shadow-1 relative">
      <app-relationship-graph [data]="data" (selectNode)="onSelectNode($event)"></app-relationship-graph>
      <div *ngIf="selectedType" class="absolute" [style]="{top: 0, left: 0, 'min-width': '300px'}">
        <p-accordion>
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="flex justify-content-between w-full">
                <div>{{selectedType.label}} <span class="text-xs text-gray-500">{{selectedType.name}}</span></div>
                <div class="mr-2">
                  <a [routerLink]="['/hs-associations']" [queryParams]="{objectTypeId: selectedType.objectTypeId}">
                    <i class="pi pi-external-link" pTooltip="Open in Editor"></i>
                  </a>
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <div class="border-top-1 border-gray-300 pt-1">
                <div *ngFor="let prop of selectedType.properties" class="grid">
                  <div class="col-9 mt-overflow-ellipsis prop-label" appTooltipOnOverflow>
                    {{prop.label}}
                  </div>
                  <div class="col-3 mt-overflow-ellipsis prop-type" appTooltipOnOverflow>
                    {{prop.type}}
                  </div>
                </div>
              </div>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  `,
  styles: [`
    .svg-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 148px);
      background-color: #FFF;
    }

    :host ::ng-deep .p-accordion .p-accordion-header .p-accordion-header-link {
      padding: .5rem 1.25rem;
    }

    .prop-label {
      font-size: .9rem;
    }

    .prop-type {
      color: var(--gray-600);
      font-size: .8rem;
    }
  `]
})
export class HsRelationshipDiagramPageComponent implements OnInit {
  data!: IGraphData;
  objectTypes: { [objectTypeId: string]: IHsObjectType } = {};
  selectedType: IHsObjectType | null = null;
  loading = false;

  constructor(private resource: AppResourceService,
              private hsService: HsService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin([this.hsService.getObjectTypes(), this.resource.hsGetAssociations()])
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((results) => {
        for (const type of results[0]) {
          this.objectTypes[type.objectTypeId] = type;
        }
        const nodes: Array<IGraphNode> = filter(results[0], {isCustom: true})
          .map((t) => ({id: t.objectTypeId, name: t.label}));
        const links: Array<IGraphLink> = filter(
          results[1], (a) => !!find(nodes, {id: a.toObjectTypeId})
        ).map((a) => ({target: a.fromObjectTypeId, source: a.toObjectTypeId}));
        this.data = {
          nodes,
          links
        };
      });
  }

  onSelectNode(ev: { event: any; id: string }): void {
    if (ev.event.button === 0) {
      if (ev.event.ctrlKey) {
        this.router.navigate(['hs-associations'], {
          queryParams: {objectTypeId: ev.id},
          skipLocationChange: false
        });
      } else {
        this.selectedType = this.objectTypes[ev.id];
      }
    }
  }
}
