import {Component} from '@angular/core';
import {EntityFormControlBaseComponent} from './entity-form-control-base.component';
import {ITeamParticipant} from '../../api/shared/app-domain/common';
import {EntityPropertyOptionsService} from './entity-property-options.service';
import {FormControlWrapperComponent} from '../../common/components/form-control-wrapper.component';
import {ICompany} from '../../api/shared/app-domain/company';

@Component({
  selector: `app-team-participant-form-control`,
  template: `
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'firstName'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'lastName'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'birthday'" dataType="date"
                                [controlName]="name"></app-form-control-wrapper>
      <div *ngSwitchCase="'skills'">
        <app-entity-edit-dialog #editDialog (apply)="onApplyNewRelatedEntity(ctrl, $event.data)">
          <ng-template pTemplate let-param>
            <app-skills-editor [param]="param"></app-skills-editor>
          </ng-template>
        </app-entity-edit-dialog>
        <app-form-control-wrapper #ctrl [controlName]="name" controlType="multiselect"
                                  [options]="(propertyOptions.getOptions('skillNames') | async) || []"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="name"
                                  [virtualScroll]="true"
                                  [showAddButton]="true"
                                  (addButtonClick)="editDialog.show({id: null})"></app-form-control-wrapper>
      </div>
      <div *ngSwitchCase="'roleId'">
        <app-entity-edit-dialog #editDialog (apply)="onApplyNewRelatedEntity(ctrl, $event.data)">
          <ng-template pTemplate let-param>
            <app-role-editor [param]="param"></app-role-editor>
          </ng-template>
        </app-entity-edit-dialog>
        <app-form-control-wrapper #ctrl [controlName]="name" controlType="dropdown" label="Role"
                                  [options]="$any(propertyOptions.getOptions('roles') | async)"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [showAddButton]="true"
                                  (addButtonClick)="editDialog.show({id: null})"></app-form-control-wrapper>
      </div>
      <app-form-control-wrapper *ngSwitchCase="'country'" [controlName]="name" controlType="dropdown"
                                [options]="$any(propertyOptions.getOptions('countries') | async) | options"
                                optionValue="name" optionLabel="name" optionIcon="flag"
                                [optionsFilter]="true"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'state'" [controlName]="name" controlType="dropdown"
                                [options]="propertyOptions.usStates | options"
                                [optionsFilter]="true"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'region'" controlName="state" label="Region"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'timezone'" [controlName]="name" controlType="dropdown"
                                [options]="$any(propertyOptions.getOptions('timezones') | async) | options"
                                [optionsFilter]="true"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'phone'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'email'" [controlName]="name"></app-form-control-wrapper>
      <div *ngSwitchCase="'departmentId'">
        <app-entity-edit-dialog #editDialog (apply)="onApplyNewRelatedEntity(ctrl, $event.data)">
          <ng-template pTemplate let-param>
            <app-department-editor [param]="param"></app-department-editor>
          </ng-template>
        </app-entity-edit-dialog>
        <app-form-control-wrapper #ctrl [controlName]="name" controlType="dropdown" label="Department"
                                  [options]="$any(propertyOptions.getOptions('departments') | async)"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [showAddButton]="true"
                                  (addButtonClick)="editDialog.show({id: null})"></app-form-control-wrapper>
      </div>
      <div *ngSwitchCase="'talentPartnerId'">
        <app-entity-edit-dialog #editDialog (apply)="onApplyNewRelatedEntity(ctrl, $event.data)">
          <ng-template pTemplate let-param>
            <app-company-editor [param]="param"></app-company-editor>
          </ng-template>
        </app-entity-edit-dialog>
        <app-form-control-wrapper #ctrl [controlName]="name" label="Talent Partner"
                                  controlType="dropdown"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [options]="$any(propertyOptions.getOptions('talentPartners') | async)"
                                  [showAddButton]="true"
                                  (addButtonClick)="editDialog.show({id: null, data: {types: ['Talent Partner']}})"></app-form-control-wrapper>
      </div>
      <app-form-control-wrapper *ngSwitchCase="'costRateDefault'" [controlName]="name" label="Default Cost Rate"
                                dataType="currency"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'primarySourceUrl'" [controlName]="name" label="Search Source URL"
                                [iconIsLink]="true"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'searchSource'" [controlName]="name" controlType="dropdown"
                                [dropdownEditable]="false"
                                [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="name"
                                [options]="$any(propertyOptions.getOptions('searchSources') | async)"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'searchSourceKeyword'" [controlName]="name"
                                controlType="chips"></app-form-control-wrapper>
      <div *ngSwitchCase="'referralId'">
        <app-entity-edit-dialog #editDialog (apply)="onApplyNewRelatedEntity(ctrl, $event.data)">
          <ng-template pTemplate let-param>
            <app-referral-editor [param]="param"></app-referral-editor>
          </ng-template>
        </app-entity-edit-dialog>
        <app-form-control-wrapper #ctrl [controlName]="name" label="Referral" controlType="dropdown"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [options]="$any(propertyOptions.getOptions('referrals') | async)"
                                  [showAddButton]="true"
                                  (addButtonClick)="editDialog.show({id: null})"></app-form-control-wrapper>
      </div>
    </ng-container>
  `
})
export class TeamParticipantFormControlComponent extends EntityFormControlBaseComponent<ITeamParticipant> {
  constructor(propertyOptions: EntityPropertyOptionsService) {
    super(propertyOptions);
    this.relatedFields = {
      skills: {relatedField: 'name', entityPropertyOption: 'skillNames'},
      departmentId: {relatedField: 'id', entityPropertyOption: 'departments'},
      roleId: {relatedField: 'id', entityPropertyOption: 'roles'},
      referralId: {relatedField: 'id', entityPropertyOption: 'referrals'}
    }
  }

  override onApplyNewRelatedEntity(ctrl: FormControlWrapperComponent, relatedEntityData: any): void {
    if (ctrl.controlName === 'talentPartnerId') {
      if ((relatedEntityData as ICompany).types.includes('Talent Partner')) {
        this.propertyOptions.addOptionItem('talentPartners', relatedEntityData);
        ctrl.setValueAndMark(relatedEntityData.id);
      }
      return;
    }
    super.onApplyNewRelatedEntity(ctrl, relatedEntityData);
  }

}

