import {Component, Input} from '@angular/core';
import {ColumnExpansionTableBaseComponent} from './column-expansion-table.component';
import {finalize, Observable} from 'rxjs';
import {LazyLoadEvent} from 'primeng/api';
import {IDetails, IDetailsCategoryRow} from '../../../api/shared/app-domain/details';
import {IPeriodTotals} from '../../../api/shared/app-domain/common';

@Component({
  selector: 'app-planning-table',
  template: `
    <p-dialog [(visible)]="dialog.show"
              [closable]="true"
              [modal]="true" [style]="{width: '30vw'}" appendTo="body">
      <ng-template pTemplate="header">
        <div class="text-2xl">{{dialog.title}}</div>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (onClick)="dialog.show=false" label="Apply" styleClass="p-button-text"></p-button>
        <p-button icon="pi  pi-times" (onClick)="dialog.show=false" label="Close" styleClass="p-button-text"></p-button>
      </ng-template>
      <p-skeleton height="250px"></p-skeleton>
    </p-dialog>
    <app-spinnerizer [active]="loading" [target]="container" spinnerPosition="top">
    </app-spinnerizer>
    <div #container>
      <p-treeTable
        [value]="nodes"
        [columns]="columns"
        [scrollable]="true"
        frozenWidth="800px"
        class="mt-budget-table mt-planning-table">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" style="width: 150px">
          </colgroup>
        </ng-template>

        <!-- Headers  -->
        <ng-template pTemplate="frozenheader">
          <app-column-expansion-table-frozen-header [table]="this"></app-column-expansion-table-frozen-header>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <app-column-expansion-table-header [table]="this"></app-column-expansion-table-header>
        </ng-template>

        <!--  Bodies  -->
        <ng-template pTemplate="frozenbody" let-rowNode let-node="node" let-rowData="rowData">
          <ng-container *ngIf="node.type !== 'summary' && node.type !== 'extra'; else additionTpl">
            <tr class="data-row"
                [class.mt-section-row]="node.type === 'section'"
                [class.mt-has-children]="!node.leaf" (click)="togglePopupColumn(-1)">
              <td class="name-column">
                <ng-container *ngIf="node.type !== 'section'">
                  <div class="mt-nested-row-cell-top"></div>
                </ng-container>
                <ng-container>
                  <app-column-expansion-table-category-cell [table]="this" [node]="node" [rowNode]="rowNode">
                  </app-column-expansion-table-category-cell>
                </ng-container>
              </td>
              <td colspan="2" style="padding-top: 0; padding-bottom: 0; flex: 2"
                  [style.padding-bottom]="node.type !== 'section' ? '8px' : '0'">
                <ng-container *ngIf="node.type !== 'section'">
                  <div class="mt-nested-row-cell-top"></div>
                </ng-container>
                <div class="w-full">
                  <!-- TODO: process null values  -->
                  <div class="flex justify-content-between">
                    <div>{{totals(rowData).actual.currency | currency:'USD':'symbol':'1.0-0'}}</div>
                    <div>of</div>
                    <div>{{totals(rowData).budget.currency | currency:'USD':'symbol':'1.0-0'}}</div>
                  </div>
                  <div class="flex justify-content-between text-gray-600">
                    <div>{{totals(rowData).actual.time}} h</div>
                    <div>of</div>
                    <div>{{totals(rowData).budget.time}} h</div>
                  </div>
                  <div *ngIf="totals(rowData).actual.currency != null && totals(rowData).budget.currency">
                    <p-progressBar [value]="totals(rowData).actual.currency! / totals(rowData).budget.currency! * 100"
                                   [showValue]="false"></p-progressBar>
                  </div>
                </div>
              </td>
              <td *ngFor="let totCol of ['delta', 'future', 'eac']">
                <ng-container *ngIf="node.type !== 'section'">
                  <div class="mt-nested-row-cell-top"></div>
                </ng-container>
                <div class="flex flex-column text-right">
                  <div [class.font-medium]="node.type === 'section'">
                    {{$any(totals(rowData))[totCol].currency | currency:'USD':'symbol':'1.0-0'}}</div>
                  <div class="text-gray-600">{{$any(totals(rowData))[totCol].time}} h</div>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-template #additionTpl>
            <tr *ngIf="node.type === 'summary'" class="mt-section-row mt-summary-row"
                [style.height]="summaryRowHeight()"
                (click)="togglePopupColumn(-1)">
              <td colspan="6">
                <div class="flex flex-column align-items-end">
                  <div *ngFor="let sum of summary" class="mt-summary-item" [class.font-semibold]="sum.isEmphasis">
                    {{sum.name}}
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="node.type === 'extra'" class="mt-extra-row"
                (click)="togglePopupColumn(-1)">
              <td colspan="6">
              </td>
            </tr>
          </ng-template>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-node="node" let-rowData="rowData" let-columns="columns">
          <ng-container *ngIf="node.type !== 'summary' && node.type !== 'extra'; else additionTpl">
            <tr class="data-row"
                [class.mt-section-row]="node.type === 'section'"
                [class.mt-has-children]="!node.leaf">
              <td *ngFor="let col of columns; let i = index"
                  [pTooltip]="$any(!!periodCellTooltip ? periodCellTooltip(node, rowData, i) : null)"
                  [tooltipOptions]="{tooltipPosition: 'bottom'}"
                  class="mt-value-cell"
                  [class.mt-has-popup-cell]="col.periodIndex === popupColumn"
                  (click)="togglePopupColumn(col.periodIndex)">
                <ng-container *ngIf="node.type !== 'section'">
                  <div class="mt-nested-row-cell-top"></div>
                </ng-container>
                <app-column-expansion-table-popup-cell [table]="this" [node]="node"
                                                       [col]="col"></app-column-expansion-table-popup-cell>
                <app-column-expansion-table-data-cell [table]="this" [node]="node"
                                                      [col]="col"></app-column-expansion-table-data-cell>
              </td>
            </tr>
          </ng-container>
          <ng-template #additionTpl>
            <app-column-expansion-table-summary-row *ngIf="node.type === 'summary'"
                                                    [table]="this"></app-column-expansion-table-summary-row>
            <tr *ngIf="node.type === 'extra'" class="mt-extra-row"
                [style.height]="'auto'"
                (click)="togglePopupColumn(-1)">
              <td *ngFor="let col of columns; let i = index" class="pb-0">
                <ng-container *ngIf="extraRowPeriodCellTplRef">
                  <ng-container
                    *ngTemplateOutlet="extraRowPeriodCellTplRef; context: {$implicit: i, rowData }"></ng-container>
                </ng-container>
              </td>
            </tr>
          </ng-template>
        </ng-template>
      </p-treeTable>
    </div>
  `,
  styles: [`
    :host ::ng-deep .mt-planning-table .p-treetable-scrollable-header-box {
      padding-right: 0 !important;
    }
  `]
})
export class PlanningTableComponent extends ColumnExpansionTableBaseComponent {
  @Input() fetch!: () => Observable<IDetails<any>>;

  constructor() {
    super();
    this.hasLeftFixedColumns = true;
  }

  totals(rowData: IDetailsCategoryRow<any>): IPeriodTotals {
    return rowData.totals!;
  }

  override load(event?: LazyLoadEvent): void {
    setTimeout(() => this.loading = true);
    this.fetch().pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((data) => {
      this.onLoad(data);
    });
  }

}
