import {Component, forwardRef, OnInit} from '@angular/core';
import {
  TTeamMemberArchiveReason,
  TTeamMemberCurrentlyUnavailableReason
} from '../../api/shared/app-domain/team';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {TControlsOf} from '../../common/types';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {TreeNode} from 'primeng/api';
import {find} from 'lodash';

export interface ITeamMemberArchiveReason {
  statusChangedReason: TTeamMemberArchiveReason | TTeamMemberCurrentlyUnavailableReason;
}

@Component({
  selector: 'app-team-member-status-editor',
  template: `
    <form *ngIf="form" [formGroup]="form">
      <div class="formgrid grid w-full">
        <div class="field p-fluid col-12">
          <app-team-member-form-control name="statusChangedReason" [statusChangedReasonOptions]="statusChangedReasonOptions"></app-team-member-form-control>
        </div>
      </div>
    </form>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => TeamMemberStatusEditorComponent)},
    SubscriptionsService
  ]
})
export class TeamMemberStatusEditorComponent
  extends EntityEditorBaseComponent<ITeamMemberArchiveReason, TControlsOf<ITeamMemberArchiveReason>> implements OnInit {

  statusChangedReasonOptions!: Array<TreeNode>

  constructor(public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.statusChangedReasonOptions = find(
      this.propertyOptions.teamMemberStatusChangedReasonsAsTree, {key: 'Archived'}
    )!.children!;
  }

  ngOnInit(): void {
    this.form = new FormGroup<TControlsOf<ITeamMemberArchiveReason>>({
      statusChangedReason: new FormControl(null, [Validators.required]),
    });
  }

  apply(onApply: (result: ITeamMemberArchiveReason) => void): void {
    onApply(this.form!.getRawValue() as ITeamMemberArchiveReason);
  }

}


