import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'nullValue'})
export class NullValuePipe implements PipeTransform {

  transform(value: any, otherwise?: string, symb = '\u{02014}'): string {
    return value == null ? symb : `${value}${otherwise || ''}`;
  }
}

