import {TableQuery} from '../util/table-query';
import {FilterMetadata, LazyLoadEvent} from 'primeng/api';
import {ISearchRequest, ISearchResponse, TQueryExpression} from '../../api/shared/search-api';
import {finalize, Observable, of} from 'rxjs';
import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TableBaseComponent} from './table-base.component';
import {IIdentified} from '../../api/shared/app-domain/common';
import {onComponentPropChanged} from '../util/util';


export interface IResponseRequest<T> {
  request: ISearchRequest;
  response: ISearchResponse<T>;
}

@Component({
  template: ''
})
export abstract class PageableTableComponent<T extends IIdentified> extends TableBaseComponent<T> implements AfterViewInit, OnChanges {
  @Input() query: TQueryExpression | null = null;
  @Output() onDataFetched: EventEmitter<IResponseRequest<T>> = new EventEmitter<IResponseRequest<T>>();
  tableQuery = new TableQuery();
  pageSize = 10;
  total = 0;
  firstPage = 0;


  protected constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    onComponentPropChanged(changes, ['query'], (changes ) => this.load());
  }

  override load(event?: LazyLoadEvent): void {
    setTimeout(() => {
      this.loading = true;
    });
    const searchRequest: ISearchRequest = this.tableQuery.prepareQuery(
      event || {...this.tableComponent.createLazyLoadMetadata(), first: 0}, {
        pageSize: this.pageSize,
        search: this.search
      }
    );
    this.prepareSearchRequestQuery(searchRequest);
    this.fetchData(
      searchRequest
    ).pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((response) => {
      this.currentSearchRequest = searchRequest;
      console.log(response);
      this.onFetchData(response);
      this.onDataFetched.emit({request: searchRequest, response});
      this.total = response.total;
      if (event == null) {
        this.firstPage = 0;
      }
    });
  }

  protected prepareSearchRequestQuery(searchRequest: ISearchRequest): ISearchRequest {
    if (searchRequest.query || this.query || this.getAdditionalQuery()) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: [
          ...(this.getAdditionalQuery()?.predicates || []),
          ...(this.query?.predicates || []),
          ...((searchRequest.query as TQueryExpression)?.predicates || [])
        ]
      }
      searchRequest.query = query;
    }
    console.log('search request', searchRequest);
    return searchRequest;
  }

  protected getAdditionalQuery(): TQueryExpression | null {
    return null;
  }

  protected fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<T>> {
    if (this.api.searchEntities) {
      return this.api.searchEntities(searchRequest);
    } else {
      return of({total: 0, offset: 0, limit: 0, results: []});
    }
  }

  protected onFetchData(response: ISearchResponse<T>): void {
    this.data = response.results;
  }
}
