import {Component} from '@angular/core';
import {ITeamMember} from '../../../api/shared/app-domain/team';
import {AppResourceService} from '../../../app.resource.service';
import {getTeamMemberTypeIcon} from '../team';


@Component({
  template: `
    <app-single-entity-details tabRoute="/team-members" [fetchEntity]="resource.getTeamMember.bind(resource)">
      <ng-template pTemplate="entityTpl" let-entity>

        <div class="flex align-items-center">
          <app-avatar [(ngModel)]="entity.avatar" [name]="entity.name!" size="3rem"></app-avatar>
          <div class="ml-3">
            <div class="flex align-items-center">
              <div class="mt-page-title mr-2">{{entity.name}}</div>
              <img [src]="getTeamMemberTypeIcon(entity)" height="24" width="24"
                   [pTooltip]="entity.type || ''"/>
            </div>
            <div class="text-sm text-gray-700">{{entity.roleName}} | {{entity.status}}
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="detailsTpl" let-entity let-tab="tab" let-period="period">
        <app-single-team-member-details *ngIf="tab === 'details'"
                                        [entity]="entity" [period]="period"></app-single-team-member-details>
        <app-single-team-member-planning *ngIf="tab === 'planning'"
                                         [entity]="entity" [period]="period"></app-single-team-member-planning>
        <ng-container *ngIf="!['details', 'planning'].includes(tab)">
          TBD
        </ng-container>
      </ng-template>
    </app-single-entity-details>
  `
})
export class TeamMemberDetailPageComponent {
  constructor(public resource: AppResourceService) {
  }

  getTeamMemberTypeIcon(entity: ITeamMember): string | null {
    return getTeamMemberTypeIcon(entity.type);
  }
}
