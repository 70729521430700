import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren, EventEmitter,
  Input,
  OnChanges, Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {LazyLoadEvent, MenuItem, PrimeTemplate, TreeNode} from 'primeng/api';
import {ILens, IPeriod, TPeriodType} from '../../../api/shared/app-domain/common';
import {TQueryExpression} from '../../../api/shared/search-api';
import {EntityPropertyOptionsService, TEntityPropertyOption} from '../entity-property-options.service';
import {
  IDetails,
  IDetailsCategoryRow,
  IDetailsRow,
  IDetailsSummaryRow,
  IDetailsValue, IPeriodRange
} from '../../../api/shared/app-domain/details';
import { filter, omit, startCase } from 'lodash';
import {EmbeddedComponent} from '../../../common/components/util.components';
import {onComponentPropChanged} from '../../../common/util/util';

export interface IDetailsColumn {
  header: string;
  periodIndex: number | null;
  extraDataField?: string;
  columnClass?: string;
}

export interface IDetailsColumnGroup {
  name: string;
  count: number;
}

export interface IExtraDataColumn {
  header: string;
  field: string;
  columnClass?: string;
}

export interface IDetailsCellClickEvent {
  mouseEvent: any;
  node: TreeNode;
  rowData: IDetailsCategoryRow<any>;
  column: IDetailsColumn;
}

@Component({
  template: ``
})
export abstract class ColumnExpansionTableBaseComponent implements OnChanges, AfterContentInit, AfterViewInit {
  @Input() period!: IPeriod;
  @Input() query: TQueryExpression | null = null;
  @Input() lens: ILens | null = null;
  @Input() extraFieldsColumns?: Array<IExtraDataColumn>;
  @Input() leftColWidth = '250px';
  @Input() extraFieldsGroupClass = '';
  @Input() searchEntryEntities?: { searchOption: TEntityPropertyOption; };
  @Input() showGroupHeader = true;
  @Input() showPopupColumnByDefault = false;
  @Input() isSingleWeek = false;
  @Input() entryEntityName = 'Category';
  @Input() periodCellTooltip?:
    (node: TreeNode<IDetailsCategoryRow<any>>, rowData: IDetailsCategoryRow<any>, periodIndex: number) => string | null;
  @Output() loaded: EventEmitter<IDetails<any>> = new EventEmitter<IDetails<any>>();
  @Output() cellClick: EventEmitter<IDetailsCellClickEvent> = new EventEmitter<IDetailsCellClickEvent>();
  @Output() addEntryEntity: EventEmitter<{periodIndex: number;}> = new EventEmitter<{periodIndex: number;}>();
  @Output() editEntryEntity: EventEmitter<{periodIndex: number; rowData: IDetailsCategoryRow<any>}>
    = new EventEmitter<{periodIndex: number; rowData: IDetailsCategoryRow<any>}>();

  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;

  hasLeftFixedColumns: boolean = false;
  selectedPeriodIndex!: number;

  entryEntityTplRef?: TemplateRef<any>;
  searchEntryEntityTplRef?: TemplateRef<any>;
  extraFieldsTplRef?: TemplateRef<any>;
  extraRowPeriodCellTplRef?: TemplateRef<any>

  searchEntryEntitiesModel: Array<string> = [];

  loading = false;
  columns!: Array<IDetailsColumn>;
  columnGroups!: Array<IDetailsColumnGroup>;
  nodes!: Array<TreeNode<IDetailsCategoryRow<any>>>;
  popupColumn = -1;

  dialog: { show: boolean; title: string; } = {show: false, title: ''};
  menuRowData?: IDetailsCategoryRow<any>;

  rowMenu: Array<MenuItem> = [
    {label: 'Edit', icon: 'pi pi-pencil', command: () => this.openEditRowDialog()},
    {label: 'Shift Timeline', icon: 'pi pi-history'},
    {label: 'View Report', icon: 'pi pi-file'},
    {label: 'Archive', icon: 'pi pi-server'},
    {label: 'Delete', icon: 'pi pi-trash', styleClass: 'mt-alert-menuitem'}
  ];

  periodRange!: IPeriodRange;
  summary?: Array<IDetailsSummaryRow>;
  extraRowData?: Array<any>;

  ngOnChanges(changes: SimpleChanges): void {
    onComponentPropChanged(changes, ['period', 'query', 'lens'], () => this.load());
  }

  ngAfterViewInit(): void {
    this.load();
  }

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (tpl.getType() === 'entryEntityTpl') {
        this.entryEntityTplRef = tpl.template;
      } else if (tpl.getType() === 'searchEntryEntityTpl') {
        this.searchEntryEntityTplRef = tpl.template;
      } else if (tpl.getType() === 'extraFieldsTpl') {
        this.extraFieldsTplRef = tpl.template;
      } else if (tpl.getType() === 'extraRowCellTpl') {
        this.extraRowPeriodCellTplRef = tpl.template;
      }
    });
  }

  abstract load(event?: LazyLoadEvent): void;

  protected onLoad(data: IDetails<any>): void {
    if (this.showPopupColumnByDefault) {
      this.popupColumn = data.periodRange.selected;
    } else {
      this.popupColumn = -1;
    }
    this.periodRange = data.periodRange;
    this.summary = (data.summary || []).map((s) => ({...s, ...{name: this.rowName(s)}}));
    this.extraRowData = data.extraValues;
    this.selectedPeriodIndex = data.periodRange.selected;
    this.prepareColumns(data);
    this.nodes = this.detailsRows2TreeNodes(data);
    this.loaded.emit(data);
  }

  togglePopupColumn(i: number | null): void {
    if (i == null) {
      i = -1;
    }
    this.popupColumn = (i === this.popupColumn ? -1 : i);
  }

  totalHeaderTitle(): string {
    if (this.isSingleWeek) {
      return 'Weekly total';
    }
    const labels: { [type in TPeriodType]?: string } = {
      Day: 'Weekly',
      Week: 'Monthly',
      Month: 'Yearly'
    };
    const label = labels[this.period.type];
    return `${label} total`;
  }

  canAddCategory(rowData: IDetailsCategoryRow<any>): boolean {
    if (rowData.isEntry) {
      if (filter(this.columns || [], (c) => c.periodIndex != null).length === 1) {
        return true;
      } else  {
        return this.popupColumn !== -1
      }
    } else {
      return true;
    }
  }

  openAddDialog(mouseEvent: any, node: TreeNode<IDetailsCategoryRow<any>>, rowData: IDetailsCategoryRow<any>): void {
    if (rowData.isEntry) {
      let periodIndex = -1;
      if (filter(this.columns || [], (c) => c.periodIndex != null).length === 1) {
        periodIndex = 0;
      } else if (this.popupColumn !== -1) {
        periodIndex = this.popupColumn;
      }
      if (periodIndex !== -1) {
        this.addEntryEntity.emit({ periodIndex });
      }
    } else {
      this.dialog.title = 'Add Category';
      this.dialog.show = true;
    }
  }

  openEditCellDialog(column: IDetailsColumn, node: TreeNode<IDetailsCategoryRow<any>>, rowData: IDetailsCategoryRow<any>): void {
    if (node.type !== 'section') {
      if (rowData.isEntry) {
        this.editEntryEntity.emit({periodIndex: column.periodIndex!, rowData});
      } else {
        this.dialog.title = rowData.name + ' - ' + column.header;
        this.dialog.show = true;
      }
    } else {
      if (node.leaf) {
        this.dialog.title = rowData.name + ' - ' + column.header;
        this.dialog.show = true;
      }
    }
  }

  openEditRowDialog(): void {
    this.dialog.title = 'Edit: ' + this.menuRowData!.name;
    this.dialog.show = true;
  }

  protected prepareColumns(data: IDetails<any>): void {
    this.columns = [];
    this.columnGroups = [];
    for (const pg of data.periodRange.groups) {
      this.columns = [...this.columns, ...pg.columns.map((p, i) => ({
        header: p,
        periodIndex: i + this.columns.length
      }))];
      this.columnGroups = [...this.columnGroups, ...[{
        name: pg.group,
        count: pg.columns.length
      }]];
    }
    if (!this.hasLeftFixedColumns) {
      this.columns.unshift({
        header: '',
        periodIndex: null
      });
    }
    if (!!this.extraFieldsColumns) {
      this.columns = [...this.columns, ...this.extraFieldsColumns.map((c) => ({
        header: c.header,
        extraDataField: c.field,
        periodIndex: null,
        columnClass: c.columnClass
      }))]
    }
  }

  rowName(row: IDetailsRow): string {
    return row.name ?? startCase(row.id);
  }

  protected detailsRows2TreeNodes(data: IDetails<any>): Array<TreeNode> {
    const nodes: Array<TreeNode<IDetailsCategoryRow<any>>> = [];
    for (const c of data.categories) {
      const hasChildren = !!c.categories;
      const node: TreeNode<IDetailsCategoryRow<any>> = {
        data: {...omit(c, 'categories'), ...{name: this.rowName(c)}},
        leaf: !!!c.categories?.length,
        expanded: hasChildren,
        type: 'section',
        children: []
      };
      for (const sc of c.categories || []) {
        const nestedNode: TreeNode<IDetailsCategoryRow<any>> = {
          data: {...sc, ...{name: this.rowName(sc)}},
          leaf: true
        };
        node.children?.push(nestedNode);
      }
      nodes.push(node);
      if (!!data.summary?.length) {
        const node: TreeNode = {
          leaf: true,
          type: 'summary'
        };
        nodes.push(node);
      }
      if (filter(this.columns, (c) => c.periodIndex != null).length > 1 && !!data.extraValues?.length) {
        const node: TreeNode = {
          leaf: true,
          type: 'extra'
        };
        nodes.push(node);
      }
    }
    return nodes;
  }

  summaryRowHeight(): string {
    return (this.summary?.length || 0) * 22 + 'px';
  }
}

@Component({
  selector: 'app-column-expansion-table-header',
  template: `
    <ng-template #template>
      <tr *ngIf="table.showGroupHeader" class="mt-period-group" (click)="table.togglePopupColumn(-1)">
        <th *ngIf="!table.hasLeftFixedColumns" [style]="{ width: table.leftColWidth, visibility: 'hidden' }"></th>
        <th *ngFor="let group of table.columnGroups; let i = index"
            [colSpan]="group.count"
            class="text-center">
          <div class="mt-overflow-ellipsis" appTooltipOnOverflow>{{group.name}}</div>
          <div *ngIf="!table.hasLeftFixedColumns" class="mt-period-group-header-bottom"></div>
        </th>
        <th *ngIf="!!table.extraFieldsColumns" [class]="table.extraFieldsGroupClass"
            [colSpan]="table.extraFieldsColumns!.length" [style]="{visibility: 'hidden'}"></th>
      </tr>
      <tr class="mt-period-header">
        <th *ngFor="let col of table.columns; let i = index"
            [style]="!table.hasLeftFixedColumns && i === 0
            ? {width: table.leftColWidth, visibility: 'hidden'}
            : {}"
            class="text-center cursor-pointer"
            [class.mt-period-current]="!table.isSingleWeek && col.periodIndex === table.selectedPeriodIndex"
            [class]="col.columnClass"
            (click)="table.togglePopupColumn(col.periodIndex)">
          <div class=" mt-overflow-ellipsis" appTooltipOnOverflow>{{col.header}}</div>
          <div *ngIf="!table.hasLeftFixedColumns" class="mt-period-header-top"></div>
          <ng-container *ngIf="col.periodIndex === table.popupColumn">
            <div class="mt-popup-cell-shadow"></div>
            <div class="flex align-items-center justify-content-center mt-popup-cell">
              <div class="mt-overflow-ellipsis" appTooltipOnOverflow>{{col.header}}</div>
            </div>
          </ng-container>
        </th>
      </tr>
    </ng-template>
  `,
  styles: [`
    :host {
      display: none;
    }
  `]
})
export class ColumnExpansionTableHeader extends EmbeddedComponent {
  @Input() table!: ColumnExpansionTableBaseComponent;

  constructor(viewContainerRef: ViewContainerRef) {
    super(viewContainerRef);
  }

}

@Component({
  selector: 'app-column-expansion-table-body-row',
  template: `
  `
})
export class ColumnExpansionTableBodyRow extends EmbeddedComponent {
  @Input() table!: ColumnExpansionTableBaseComponent;

  constructor(viewContainerRef: ViewContainerRef) {
    super(viewContainerRef);
  }
}

@Component({
  selector: 'app-column-expansion-table-popup-cell',
  template: `
    <ng-container
      *ngIf="col.periodIndex === table.popupColumn">
      <div class="mt-popup-cell-shadow"></div>
      <div class="w-full flex flex-column justify-content-center mt-popup-cell"
           (click)="table.openEditCellDialog(col, node, node.data!)">
        <div class="grid grid-nogutter" [class.text-gray-500]="val.allocated.currency == null && val.utilized.currency == null">
          <div class="col">{{val.utilized.currency | currency:'USD':'symbol':'1.0-0' | nullValue}}</div>
          <div class="col-fixed text-sm text-center w-1rem">of</div>
          <div class="col text-right">{{val.allocated.currency | currency:'USD':'symbol':'1.0-0' | nullValue}}</div>
        </div>
        <ng-container *ngIf="val.allocated.time !== undefined">
          <div class="grid grid-nogutter" [class.text-gray-500]="val.allocated.currency == null && val.utilized.currency == null">
            <div class="col">{{val.utilized.time | hours | nullValue}}</div>
            <div class="col-fixed text-sm text-center w-1rem">of</div>
            <div class="col text-right">{{val.allocated.time | hours | nullValue}}</div>
          </div>
        </ng-container>
        <div>
          <p-progressBar *ngIf="val.allocated.currency != null && val.utilized.currency"
                         [value]="val.utilized.currency! / val.allocated.currency! * 100"
                         [showValue]="false"></p-progressBar>
        </div>
      </div>
    </ng-container>
  `
})
export class ColumnExpansionTablePopupCell {
  @Input() table!: ColumnExpansionTableBaseComponent;
  @Input() col!: IDetailsColumn;
  @Input() node!: TreeNode<IDetailsCategoryRow<any>>;

  get val(): IDetailsValue {
    return this.node.data!.values[this.col.periodIndex!];
  }
}

@Component({
  selector: 'app-column-expansion-table-data-cell',
  template: `
    <div class="flex flex-column text-right" [class.font-medium]="node.type === 'section'">
      <div *ngIf="val.allocated.currency != null">
        {{val.allocated.currency | currency:'USD':'symbol':'1.0-0'}}
      </div>
      <app-no-value *ngIf="val.allocated.currency === null"></app-no-value>

      <div *ngIf="val.allocated.time != null" class="text-gray-600">
        {{val.allocated.time | hours}}
      </div>
      <app-no-value *ngIf="val.allocated.time === null"></app-no-value>

      <div *ngIf="val.allocated.pct != null" class="text-gray-600">
        {{val.allocated.pct | percent}}
      </div>
      <app-no-value *ngIf="val.allocated.pct === null"></app-no-value>
    </div>
  `
})
export class ColumnExpansionTableDataCell {
  @Input() table!: ColumnExpansionTableBaseComponent;
  @Input() col!: IDetailsColumn;
  @Input() node!: TreeNode<IDetailsCategoryRow<any>>;

  get val(): IDetailsValue {
      return this.node.data!.values[this.col.periodIndex!];
  }

}

@Component({
  selector: 'app-column-expansion-table-category-cell',
  template: `
    <div class="flex align-items-center justify-content-between">
      <div class="flex align-items-center">
        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        <ng-container *ngIf="node.type !== 'section' && !!rowData.entryEntity; else other">
          <ng-container *ngIf="entryEntityTplRef">
            <ng-container *ngTemplateOutlet="entryEntityTplRef; context: {$implicit: rowData}"></ng-container>
          </ng-container>
        </ng-container>
        <ng-template #other>
          <div class="mt-overflow-ellipsis" [class.font-medium]="node.type === 'section'" [style.width]="'150px'"
               appTooltipOnOverflow>{{rowData.name}}</div>
        </ng-template>
      </div>
      <div *ngIf="node.type === 'section'; else rowMenuTpl" class="flex align-items-center">
        <ng-container *ngIf="rowData.isEntry && !!table.searchEntryEntities">
          <p-overlayPanel #op [dismissable]="true"
                          (onShow)="searchEntryEntitiesModelTmp = table.searchEntryEntitiesModel">
            <ng-template pTemplate>
              <div class="flex align-items-center">
                <ng-container *ngIf="table.searchEntryEntityTplRef; else std">
                  <ng-container
                    *ngTemplateOutlet="table.searchEntryEntityTplRef; context: {$implicit: table.searchEntryEntitiesModel}"></ng-container>
                </ng-container>
                <ng-template #std>
                  <p-multiSelect [(ngModel)]="this.searchEntryEntitiesModelTmp" [style]="{minWidth: '200px'}"
                                 [options]="$any(propertyOptions.getOptions(table.searchEntryEntities!.searchOption) | async)"
                                 optionLabel="name"
                                 optionValue="id"
                                 [virtualScroll]="true"
                                 [showToggleAll]="false"
                                 [virtualScrollItemSize]="39"
                                 [showClear]="true"
                                 placeholder="Any"
                                 selectedItemsLabel="{0} item(s) selected">
                  </p-multiSelect>
                </ng-template>
                <button class="ml-2" pButton
                        (click)="table.searchEntryEntitiesModel = searchEntryEntitiesModelTmp; table.load()">
                  Apply
                </button>
              </div>
            </ng-template>
          </p-overlayPanel>
          <button type="button" icon="pi pi-filter"
                  class="p-column-filter-menu-button cursor-pointer"
                  [class.p-column-filter-menu-button-active]="table.searchEntryEntitiesModel &&  table.searchEntryEntitiesModel.length > 0"
                  (click)="op.toggle($event)"><span class="pi pi-filter-icon pi-filter"></span>
          </button>
        </ng-container>
        <!-- TODO: add "add text"       -->
        <button pButton pRipple type="button" icon="pi pi-plus"
                class="p-button-rounded p-button-text p-button-sm w-2rem h-2rem"
                [pTooltip]="'Add ' + table.entryEntityName"
                [disabled]="!table.canAddCategory(rowData)"
                (click)="table.openAddDialog($event, node, rowData)">
        </button>
      </div>
      <ng-template #rowMenuTpl>
        <p-menu #menu [popup]="true" [model]="table.rowMenu" appendTo="body"></p-menu>
        <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                class="p-button-rounded p-button-text p-button-sm w-2rem h-2rem"
                (click)="table.menuRowData = rowData; menu.toggle($event)">
        </button>
      </ng-template>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService
  ]
})
export class ColumnExpansionTableCategoryCell implements AfterContentInit {
  @Input() table!: ColumnExpansionTableBaseComponent;
  @Input() rowNode: any;
  @Input() node!: TreeNode<IDetailsCategoryRow<any>>;

  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;
  entryEntityTplRef?: TemplateRef<any>;
  searchEntryEntitiesModelTmp: Array<string> = [];

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }

  get rowData(): IDetailsCategoryRow<any> {
    return this.node.data!;
  }

  ngAfterContentInit(): void {
    if (this.table.entryEntityTplRef) {
      this.entryEntityTplRef = this.table.entryEntityTplRef;
    } else {
      this.templates.forEach((tpl) => {
        if (tpl.getType() === 'entryEntityTpl') {
          this.entryEntityTplRef = tpl.template;
        }
      });
    }
  }
}

@Component({
  selector: 'app-column-expansion-table-frozen-header',
  template: `
    <ng-template #template>
      <tr class="mt-period-group" (click)="table.togglePopupColumn(-1)">
        <th class="mt-hidden-header name-column"></th>
        <th colspan="5" class="text-center">
          <div>{{table.totalHeaderTitle()}}</div>
        </th>
      </tr>
      <tr class="mt-period-header" (click)="table.togglePopupColumn(-1)">
        <th class="mt-hidden-header name-column"></th>
        <th class="text-center">{{table.lens ? table.lens.actualLabel : 'Actual'}}</th>
        <th class="text-center">{{table.lens ? table.lens.budgetLabel : 'Budget'}}</th>
        <th class="text-center">Delta</th>
        <th class="text-center">Future</th>
        <th class="text-center">EAC</th>
      </tr>
    </ng-template>
  `,
  styles: [`
    :host {
      display: none;
    }
  `]
})
export class ColumnExpansionTableFrozenHeader extends EmbeddedComponent {
  @Input() table!: ColumnExpansionTableBaseComponent;

  constructor(viewContainerRef: ViewContainerRef) {
    super(viewContainerRef);
  }
}

@Component({
  selector: 'app-column-expansion-table-summary-row',
  template: `
    <ng-template #template>
      <tr class="mt-summary-row mt-section-row"
          [style.height]="table.summaryRowHeight()"
          (click)="table.togglePopupColumn(-1)">
        <td *ngFor="let col of table.columns; let i = index">
          <div *ngIf="!table.hasLeftFixedColumns && i === 0; else dataCell" class="flex flex-column align-items-end">
            <div *ngFor="let sum of table.summary" class="mt-summary-item" [class.font-semibold]="sum.isEmphasis">
              {{sum.name}}
            </div>
          </div>
          <ng-template #dataCell>
            <div *ngIf="col.periodIndex != null" class="flex flex-column align-items-end">
              <div *ngFor="let sum of table.summary" class="mt-summary-item" [class.font-semibold]="sum.isEmphasis">
                <!-- TODO: process null values -->
                <ng-container *ngIf="sum.values[col.periodIndex!].currency != null">
                  {{sum.values[col.periodIndex!].currency | currency:'USD':'symbol':'1.0-0'}}
                </ng-container>
                <ng-container *ngIf="sum.values[col.periodIndex!].pct != null">
                  {{sum.values[col.periodIndex!].pct | percent}}
                </ng-container>
              </div>
            </div>
          </ng-template>
        </td>
      </tr>
    </ng-template>
  `,
  styles: [`
    :host {
      display: none;
    }
  `]
})
export class ColumnExpansionTableSummaryRow extends EmbeddedComponent {
  @Input() table!: ColumnExpansionTableBaseComponent;

  constructor(viewContainerRef: ViewContainerRef) {
    super(viewContainerRef);
  }
}
