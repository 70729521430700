import {Component} from '@angular/core';

@Component({
  selector: 'app-referrals-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="mt-page-title">Referrals (draft)</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <button pButton pRipple label="New Referral"
                    class="ml-2"
                    icon="pi pi-plus" (click)="table.openCreateOrUpdateDialog(null)"></button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 shadow-1 bg-white p-3 pb-3">
      <app-referrals-table #table></app-referrals-table>
    </div>
  `
})
export class ReferralsPageComponent {
}
