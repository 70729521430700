import {Component} from '@angular/core';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {TeamMemberEditService} from '../team/team-member-edit.service';
import {AppResourceService} from '../../app.resource.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {TControlsOf} from '../../common/types';
import {IMAGE_API_ENDPOINTS} from '../../api/shared/app-domain/common';
import {existsAsyncValidator, inheritanceProvider} from '../../common/util/util';
import {IContact, TContactSave} from '../../api/shared/app-domain/contact';
import {EntityEditorComponent} from '../common/entity-editor.component';

@Component({
  selector: 'app-contact-editor',
  template: `
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container class="mt-transparent">
      <form *ngIf="form" [formGroup]="form" class="flex">
        <div style="width: 400px;">
          <div class="flex align-items-center mb-3">
            <app-avatar formControlName="avatar"
                        [imageServiceUrl]="imageServiceUrl"
                        [editable]="true" size="100px" fontSize="50px"
                        [name]="(form.controls['firstName'].value || '')  + ' ' + (form.controls['lastName'].value || '')"></app-avatar>
            <div class="formgrid grid w-full ml-3">
              <div class="field p-fluid col-12">
                <app-team-member-form-control name="firstName"></app-team-member-form-control>
              </div>
              <div class="field p-fluid col-12">
                <app-team-member-form-control name="lastName"></app-team-member-form-control>
              </div>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="email"></app-team-member-form-control>
            </div>
            <div class="field p-fluid col-12">
              <app-team-member-form-control name="birthday"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="country"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control
                  *ngIf="this.form!.get('country')!.value === 'United States'"
                  name="state"></app-team-member-form-control>
              <app-team-member-form-control
                  *ngIf="this.form!.get('country')!.value !== 'United States'"
                  [name]="$any('region')"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="city"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="phone"></app-team-member-form-control>
            </div>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(EntityEditorBaseComponent, ContactEditorComponent),
    SubscriptionsService,
    TeamMemberEditService
  ]

})
export class ContactEditorComponent extends EntityEditorComponent<TContactSave, IContact> {
  imageServiceUrl = IMAGE_API_ENDPOINTS.upload;
  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              public propertyOptions: EntityPropertyOptionsService,
              fb: FormBuilder) {
    super(fb);
    this.entityName = 'Contact';
    this.api = this.resource.contacts;
  }

  override getName(): string | null {
    return this.entity.name ?? '';
  }

  override buildForm(): void {
    this.subscriptions.unsubscribe();
    const checkExistsEmail = this.getCheckExists(this.resource.contacts.searchEntities.bind(this.resource.contacts), 'email');
    this.form = this.fb.group<TControlsOf<TContactSave>>({
      status: new FormControl(this.isNew() ? 'Active' : this.entity.status, [Validators.required]),
      firstName: new FormControl<string>(this.entity.firstName, [Validators.required]),
      lastName: new FormControl(this.entity.lastName, [Validators.required]),
      avatar: new FormControl(this.entity.avatar, []),
      birthday: new FormControl(this.entity.birthday, []),
      country: new FormControl(this.entity.country, [Validators.required]),
      state: new FormControl(this.entity.state, []),
      city: new FormControl(this.entity.city, [Validators.required]),
      phone: new FormControl(this.entity.phone, []),
      email: new FormControl(this.entity.email, [Validators.email, Validators.required]),
    });
    this.form!.controls.email.setAsyncValidators([existsAsyncValidator(checkExistsEmail)]);
    this.subscriptions
      .add(this.form!.controls.country!.valueChanges.subscribe((val) => {
        this.processCountry(val);
      }))
    this.processCountry(this.entity.country, true);
  }

  private processCountry(country: string | null | undefined, initial = false): void {
    if (country === 'United States') {
      this.form!.controls.state!.setValidators([Validators.required]);
    } else {
      this.form!.controls.state!.clearValidators();
    }
    if (!initial) {
      this.setValueAndMark('state', null);
      this.setValueAndMark('city', null);
      this.form!.controls.state!.markAsTouched();
      this.form!.controls.state!.updateValueAndValidity();
    }

  }
  override getLink(): string | null {
    return !this.isNew() ? `/tbd/contact-edit/${this.param!.id}` : null;
  }

}
