import {AfterViewInit, Component, forwardRef, OnChanges} from '@angular/core';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {ColumnsInfoService} from '../common/column-header.component';
import {PageableTableComponent} from '../../common/components/pageable-table.component';
import {AppResourceService} from '../../app.resource.service';
import {Router} from '@angular/router';
import {ISearchRequest, ISearchResponse} from '../../api/shared/search-api';
import {Observable} from 'rxjs';
import {IHolidayCalendar} from '../../api/shared/app-domain/schedule';
import {inheritanceProvider} from '../../common/util/util';

@Component({
  selector: 'app-holiday-calendars-table',
  template: `
    <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit']"></app-table-toolbar>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Holiday Calendars"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               scrollDirection="horizontal"
               [scrollable]="true"
               [filters]="filters">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="name" filterType="text"></app-column-header>
            <app-column-header field="status" filterType="optionsEquals" [fixedWidth]="120"
                               [options]="propertyOptions.commonStatuses"></app-column-header>
            <app-column-header field="countryName" filterType="optionsIn" label="Country of origin"
                               [fixedWidth]="150"
                               optionValue="name" optionLabel="name"
                               optionIcon="flag"
                               [options]="$any(propertyOptions.getOptions('countries') | async) | options">
            </app-column-header>
            <app-column-header field="holidayCount" filterType="numeric" label="Holidays"></app-column-header>
            <app-column-header field="description" filterType="text" [fixedWidth]="200"></app-column-header>
            <app-column-header field="baseName" filterType="text" [fixedWidth]="240" label="Based On">
            </app-column-header>
            <app-column-header field="createdAt" filterType="date" [fixedWidth]="180"></app-column-header>
            <app-column-header field="updatedAt" filterType="date" [fixedWidth]="180"></app-column-header>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="name" type="custom" [rowData]="rowData" tdStyleClass="py-0"
                            styleClass="justify-content-between py-0">
              <div class="flex align-items-center overflow-x-hidden">
                <div class="mt-overflow-ellipsis" appTooltipOnOverflow>
                  <a class="text-primary no-underline"
                     [routerLink]="['/settings/holiday-calendars/edit', rowData.id]">{{rowData.name}}</a>
                </div>
              </div>
              <app-row-menu-button [tableBase]="this" [rowData]="rowData"></app-row-menu-button>
            </app-table-cell>
            <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            <app-table-cell type="custom" [rowData]="rowData" field="countryCode" tdStyleClass="py-0">
              <img class="mr-2" [src]="rowData.countryFlag" width="24">
              <span>{{rowData.countryCode}}</span>
            </app-table-cell>
            <app-table-cell field="holidayCount" [rowData]="rowData" align="right"></app-table-cell>
            <app-table-cell field="description" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="baseName" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="createdAt"
                            [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="updatedAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService,
    inheritanceProvider(PageableTableComponent, HolidayCalendarsTableComponent)
  ]
})
export class HolidayCalendarsTableComponent extends PageableTableComponent<IHolidayCalendar> implements AfterViewInit, OnChanges {
  constructor(private resource: AppResourceService,
              private router: Router,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.api = {
      archiveEntities: this.resource.patchHolidayCalendar.bind(this.resource),
      deleteEntities: this.resource.deleteHolidayCalendar.bind(this.resource),
      // export: this.resource.exportHolidayCalendars.bind(this.resource),
      // importUrl: HOLIDAY_CALENDAR_API_ENDPOINTS.import
    };
  }

  protected override fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<IHolidayCalendar>> {
    return this.resource.getHolidayCalendars(searchRequest);
  }

  override openCreateOrUpdateDialog(rowData: IHolidayCalendar | null, initialData?: any): void {
    this.router.navigate(['/settings/holiday-calendars/edit', rowData?.id ?? 'new']);
  }

  override openDuplicateDialog(id: string): void {
    this.router.navigate(['/settings/holiday-calendars/edit', id], {queryParams: {duplicate: true}});
  }
}
