import {IDbColumn, IDbSchema, IDbTable} from '../../../api/shared/dev-tools/db-api';
import {filter, find, isString, pickBy, some, sortBy, values} from 'lodash';
import {IDictionary} from '../../../common/types';
import {ITableRow} from './db';
import {DbNormalizeNamePipe} from './db-normalize-name.pipe';


export class DbConstraintsUtil {
  private _table: IDbTable;
  private readonly _requiredFields: Array<string>;
  private readonly _primaryKeys: Array<string>;
  private readonly _foreignKeys: Array<string>;
  private readonly _defaultValueCols: Array<string>;
  private _fkColumns!: Array<IDbColumn>;
  private _childTables!: Array<IDbTable>;

  constructor(public schema: IDbSchema, public tableOrName: IDbTable | string) {
    if (isString(tableOrName)) {
      this._table = this.getTableByName(tableOrName);
    } else {
      this._table = tableOrName;
    }
    this._requiredFields = filter(this._table.columns, {isRequired: true})
      .map((p) => p.name);

    this._primaryKeys = sortBy(filter(this._table.columns, (col) => {
      return col.constraints?.PK != null;
    }), (col) => col.constraints?.PK?.ordinalPosition).map((p) => p.name);

    this._foreignKeys = this.fkColumns.map((p) => p.name);

    this._defaultValueCols = filter(this._table.columns, (col) => {
      return !!col.defaultValue;
    }).map((p) => p.name);
  }

  get table(): IDbTable {
    return this._table;
  }

  get requiredFields(): Array<string> {
    return this._requiredFields;
  }

  get primaryKeys(): Array<string> {
    return this._primaryKeys;
  }

  get foreignKeys(): Array<string> {
    return this._foreignKeys;
  }

  get defaultValueCols(): Array<string> {
    return this._defaultValueCols;
  }

  get fkColumns(): Array<IDbColumn> {
    if (!this._fkColumns) {
      this._fkColumns = filter(this._table.columns, (col) => {
        return col.constraints?.FK != null;
      });
    }
    return this._fkColumns;
  }

  isPK(field: string): boolean {
    return this.primaryKeys.indexOf(field) !== -1;
  }

  isFK(field: string): boolean {
    return this.foreignKeys.indexOf(field) !== -1;
  }

  isDefault(field: string): boolean {
    return this.defaultValueCols.indexOf(field) !== -1;
  }

  get childTables(): Array<IDbTable> {
    if (!this._childTables) {
      this._childTables = filter(this.schema.tables, (table) => {
        return some(table.columns, (col) => {
          return col.constraints?.FK?.relTableName === this._table.name;
        });
      });
    }
    return this._childTables;
  }

  addDataKeyToRowData(rowData: IDictionary): ITableRow {
    (rowData as ITableRow).dataKey = this.primaryKeys.map((pk) => rowData[pk].toString()).join('_');
    return rowData as ITableRow;
  }

  getTableByName(tableName: string): IDbTable {
    return find(this.schema.tables, {name: tableName})!;
  }

}
