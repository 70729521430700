import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppResourceService } from '../../app.resource.service';
import { ISearchRequest, ISearchResponse } from '../../api/shared/search-api';
import { Observable } from 'rxjs';
import { PageableTableComponent } from '../../common/components/pageable-table.component';
import { TableQuery } from '../../common/util/table-query';
import { IProjectBase, TProjectScope, TProjectType } from '../../api/shared/app-domain/project';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { getProjectTypeIcon } from './projects';
import { SubscriptionsService } from '../../common/services/subscriptions.service';
import { IScopeFilter } from '../common/common';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';

@Component({
  selector: 'app-projects-table',
  template: `
    <p-menu #tableActionsMenu [popup]="true" [model]="tableActionItems"></p-menu>
    <div class="flex align-items-center justify-content-between mb-2">
      <div class="flex align-items-center">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <input [(ngModel)]="search"
                 pInputText type="text" class="p-inputtext-sm" [style.width]="'300px'"
                 placeholder="Search"
                 appChangesDebounce (debounced)="load()"/>
        </span>
        <div class="ml-3 text-gray-700" style="width: 6rem;">{{selection.length}} selected</div>
        <p-menubar class="ml-2 mt-page-menu-bar" [model]="tableMenu"></p-menubar>
      </div>
      <button pButton pRipple label="Table Actions" class="p-button-sm p-button-outlined"
              icon="pi pi-chevron-down"
              iconPos="right" (click)="tableActionsMenu.toggle($event)"></button>
    </div>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               [currentPageReportTemplate]=
                 "'Showing {first} to {last} of {totalRecords} ' +  (scope==='all' ? 'Projects' : (scope==='client' ? 'Clients' : 'Agencies'))"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header filterType="optionsIn" field="name" label="Project Name"
                               filterField="id"
                               [fixedWidth]="240"
                               [options]="$any(propertyOptions.getOptions(scope === 'all' ? 'projects' : scope === 'client' ? 'projectClients' : 'projectAgencies') | async)"
                               optionLabel="name" optionValue="id"></app-column-header>
            <ng-container *ngIf="scope === 'all'">
              <th pSortableColumn="status" style="width: 120px">
                <div class="flex justify-content-between align-items-center w-full">
                  <div>Status</div>
                  <p-sortIcon class="align-self-center" field="status"></p-sortIcon>
                </div>
              </th>
              <th pSortableColumn="type">
                <div class="flex justify-content-between align-items-center w-full">
                  <div>Type</div>
                  <p-sortIcon class="align-self-center" field="type"></p-sortIcon>
                </div>
              </th>
            </ng-container>
            <app-budget-components-column-headers [useColumnFilters]="true" [width]="scope === 'all' ? 130 : 180">
            </app-budget-components-column-headers>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
<!--            <td [style]="{width: (1 * 45) + 'px', 'max-width': (1 * 45) + 'px'}">-->
<!--              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>-->
<!--            </td>-->
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <td style="padding-top: 0; padding-bottom: 0" [class.cursor-pointer]="true"
                (click)="goToDetail(rowData.id, rowData.name)">
              <div class="flex align-items-center justify-content-between">
                <div>
                  <div class="text-primary">{{rowData.name}}</div>
                  <div *ngIf="scope === 'all'"
                       class="text-xs text-gray-700">{{rowData.clientName}} | {{rowData.agencyName}}</div>
                </div>
                <div *ngIf="scope === 'all'" style="width: 30px">
                  <p-menu #menu [popup]="true" [model]="rowMenu" appendTo="body"></p-menu>
                  <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                          class="p-button-rounded p-button-text p-button-sm w-2rem h-2rem"
                          (click)="$event.stopPropagation(); menuRowData = rowData; menu.toggle($event)">
                  </button>
                </div>
              </div>
            </td>
            <ng-container *ngIf="scope === 'all'">
              <td>
                {{rowData.status}}
              </td>
              <td>
                <div class="flex align-items-center" style="padding-top: 0; padding-bottom: 0">
                  <img [src]="getProjectTypeIcon(rowData.type)" height="16" width="16"/>
                  <div class="ml-2">{{rowData.type}}</div>
                </div>
              </td>
            </ng-container>
            <app-budget-components-cells [rowData]="rowData"></app-budget-components-cells>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    SubscriptionsService
  ]
})
export class ProjectsTableComponent extends PageableTableComponent<IProjectBase> implements OnInit, AfterViewInit, OnChanges {
  @Input() scope!: TProjectScope;
  @Output() changeScopeFilter = new EventEmitter<{ scope: TProjectScope; scopeFilter: IScopeFilter | null }>();


  tableActionItems: Array<MenuItem> = [
    {label: 'Import', command: this.tbd},
    {label: 'Export to CSV', command: this.tbd},
  ];

  rowMenu: Array<MenuItem> = [
    {label: 'Edit', icon: 'pi pi-pencil'},
    {label: 'Duplicate', icon: 'pi pi-clone'},
    {label: 'Archive', icon: 'pi pi-server'},
    {label: 'Delete', icon: 'pi pi-trash', styleClass: 'mt-alert-menuitem'}
  ];

  menuRowData: any;

  tableMenu: Array<MenuItem> = [
    {label: 'Assign', icon: 'pi pi-arrow-right', disabled: true},
    {label: 'Edit', icon: 'pi pi-pencil', disabled: true},
    {label: 'Archive', icon: 'pi pi-server', disabled: true},
    {label: 'Delete', icon: 'pi pi-trash', disabled: true}
  ];

  constructor(private resource: AppResourceService,
              private router: Router,
              private subscriptions: SubscriptionsService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
  }

  ngOnInit(): void {
  }


  protected override fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<IProjectBase>> {
    return this.resource.getProjects(searchRequest, this.scope);
  }

  getProjectTypeIcon(type: TProjectType): string | null {
    return getProjectTypeIcon(type);
  }

  goToDetail(id: string, name: string): void {
    if (this.scope === 'all') {
      this.router.navigate(['projects', id], {queryParams: {tab: 'overview'}});
    } else if (this.scope === 'client') {
      this.changeScopeFilter.emit({scope: 'all', scopeFilter: {field: 'clientId', id, name}});
      this.router.navigate(['projects'], {queryParams: {scope: 'all'}});
    } else if (this.scope === 'agency') {
      this.changeScopeFilter.emit({scope: 'client', scopeFilter: {field: 'agencyId', id, name}});
      this.router.navigate(['projects'], {queryParams: {scope: 'client'}});
    }
  }

  override updateTableMenu(): void {
    for (const mi of this.tableMenu) {
      mi.disabled = !this.selection?.length;
    }
    this.tableMenu = [...this.tableMenu];
  }

  tbd(): void {
    alert('Not implemented yet')
  }
}
