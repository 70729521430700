import {Component, Input} from '@angular/core';
import {IDbColumn, IDbSchema} from '../../../../api/shared/dev-tools/db-api';
import {IDictionary} from '../../../../common/types';


@Component({
  selector: 'app-db-column-output',
  template: `
    <ng-container *ngIf="rowData[column.name] != null; else nullValue">
        <span appTooltipOnOverflow [useParent]="true">
          <ng-container *ngIf="!column.isArray; else arr">
            <ng-container [ngSwitch]="column.type.name">
              <ng-container *ngSwitchCase="'date'">
                  {{rowData[column.name] | date: 'MM/dd/yy'}}
              </ng-container>
              <ng-container *ngSwitchCase="'datetime'">
                  {{rowData[column.name] | date: 'MM/dd/yy hh:mm:ss a'}}
              </ng-container>
              <ng-container *ngSwitchCase="'currency'">
                  {{rowData[column.name] | currency:'USD':'symbol':'1.2-2'}}
              </ng-container>
              <ng-container *ngSwitchCase="'boolean'">
                <i class="pi"
                   [class.pi-check-circle]="rowData[column.name] === true"
                   [class.pi-times-circle]="rowData[column.name] === false">
                </i>
              </ng-container>
              <ng-container *ngSwitchCase="'json'">
                  {{rowData[column.name] | json}}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{rowData[column.name]}}
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-template #arr>
            {{rowData[column.name]}}
          </ng-template>
        </span>
    </ng-container>
    <ng-template #nullValue>
      <div class="border-bottom-1 text-gray-400" style="width: 25px"></div>
    </ng-template>

  `
})
export class DbColumnOutput {
  @Input() schema!: IDbSchema;
  @Input() column!: IDbColumn;
  @Input() rowData!: IDictionary;
}

@Component({
  selector: 'app-db-column-input',
  template: `
    <div class="p-fluid">
      <ng-container *ngIf="!column.isArray; else arr">
        <ng-container [ngSwitch]="column.type.name">
          <ng-container *ngSwitchCase="'date'">
            <p-calendar [(ngModel)]="rowData[column.name]" [showIcon]="true"
                        [disabled]="disabled"
                        [showButtonBar]="true" appendTo="body"></p-calendar>
          </ng-container>
          <ng-container *ngSwitchCase="'datetime'">
            <p-calendar [(ngModel)]="rowData[column.name]" [showTime]="true" [showIcon]="true"
                        [disabled]="disabled"
                        [showSeconds]="true" [showButtonBar]="true" appendTo="body"></p-calendar>
          </ng-container>
          <ng-container *ngSwitchCase="'currency'">
            <p-inputNumber [(ngModel)]="rowData[column.name]" mode="currency" currency="USD"
                           [disabled]="disabled"
                           (onInput)="rowData[column.name] = $event.value"
                           locale="en-US" [showButtons]="true"></p-inputNumber>
          </ng-container>
          <ng-container *ngSwitchCase="'numeric'">
            <p-inputNumber [(ngModel)]="rowData[column.name]" mode="decimal" locale="en-US"
                           [disabled]="disabled"
                           [minFractionDigits]="0" [maxFractionDigits]="20"
                           (onInput)="rowData[column.name] = $event.value"
                           [showButtons]="true"></p-inputNumber>
          </ng-container>
          <ng-container *ngSwitchCase="'integer'">
            <p-inputNumber [(ngModel)]="rowData[column.name]"
                           [disabled]="disabled"
                           (onInput)="rowData[column.name] = $event.value"
                           [showButtons]="column.constraints == null"></p-inputNumber>
          </ng-container>
          <ng-container *ngSwitchCase="'boolean'">
            <p-checkbox [(ngModel)]="rowData[column.name]"
                        [disabled]="disabled"
                        [binary]="true"></p-checkbox>
          </ng-container>
          <ng-container *ngSwitchCase="'enum'">
            <p-dropdown [options]="schema.enums[column.type.dbType] | options"
                        [disabled]="disabled"
                        [(ngModel)]="rowData[column.name]"
                        optionLabel="label" optionValue="value"
                        appendTo="body" [autoDisplayFirst]="false"
                        [filter]="schema.enums[column.type.dbType].length > 10" filterBy="label"
                        [showClear]="true">
            </p-dropdown>
          </ng-container>
          <ng-container *ngSwitchDefault>
              <span class="p-input-icon-right">
                <i *ngIf="!disabled" class="pi pi-times cursor-pointer"
                   (click)="textModel.control.setValue(null)"></i>
                <input #textModel="ngModel" [(ngModel)]="rowData[column.name]"
                       [disabled]="disabled"
                       [class.ng-invalid]="column.isRequired && !rowData[column.name]"
                       [class.ng-dirty]="true"
                       type="text" pInputText nullable/>
              </span>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #arr>
        <ng-container [ngSwitch]="column.type.name">
          <ng-container *ngSwitchCase="'enum'">
            <p-multiSelect [options]="schema.enums[column.type.dbType] | options"
                           [(ngModel)]="rowData[column.name]"
                           optionLabel="label" optionValue="value"
                           appendTo="body"></p-multiSelect>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <!-- && column.type.category === 'string' -->
            <ng-container *ngIf="column.arrayDimensions === 1; else default">
              <p-chips [(ngModel)]="rowData[column.name]"
                       [addOnBlur]="true" [addOnTab]="true"
                       [class.ng-invalid]="column.isRequired && !rowData[column.name]"
                       [class.ng-dirty]="true" nullable></p-chips>
            </ng-container>
            <ng-template #default>
              <input #textModel="ngModel" [(ngModel)]="rowData[column.name]"
                     type="text" pInputText nullable/>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  `
})
export class DbColumnInput {
  @Input() schema!: IDbSchema;
  @Input() column!: IDbColumn;
  @Input() rowData!: IDictionary;
  @Input() disabled!: boolean;

  onChange(ev: any): void {
    console.log(ev);
  }
}
