import { Component, forwardRef } from '@angular/core';
import { AppResourceService } from '../../app.resource.service';
import { ITeamCandidate, TTeamCandidateSave } from '../../api/shared/app-domain/team-candidate';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { faGithub, faHubspot, faLinkedin, faSkype } from '@fortawesome/free-brands-svg-icons';
import { faEarth } from '@fortawesome/free-solid-svg-icons';
import { existsAsyncValidator, urlValidator } from '../../common/util/util';
import { IHubspotCandidate, IHubspotContact } from '../../api/shared/app-domain/hubspot';
import { SubscriptionsService } from '../../common/services/subscriptions.service';
import { EntityEditorBaseComponent } from '../common/entity-editor-base.component';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';
import { IUsefulLinks } from '../../api/shared/app-domain/common';
import { TControlsOf } from '../../common/types';
import { TeamParticipantEditorComponent } from '../common/team-participant-editor.component';

@Component({
  selector: 'app-team-candidate-editor',
  template: `
    <p-messages *ngIf="hasBeenDeleted" key="tcDeleted"
                [value]="[{severity:'info', summary:'Success', detail:'Team Candidate has been deleted'}]"></p-messages>
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>

    <div #container>
      <form *ngIf="form" [formGroup]="form">
        <div class="flex align-items-center mb-3">
          <app-avatar formControlName="avatar"
                      [imageServiceUrl]="imageServiceUrl"
                      [editable]="true" size="100px" fontSize="50px"
                      [name]="(form.controls['firstName'].value || '')  + ' ' + (form.controls['lastName'].value || '')"></app-avatar>
          <div class="formgrid grid w-full ml-3">
            <div class="field p-fluid col-4">
              <app-team-candidate-form-control name="firstName"></app-team-candidate-form-control>
            </div>
            <div class="field p-fluid col-4">
              <app-team-candidate-form-control name="lastName"></app-team-candidate-form-control>
            </div>
            <div class="field p-fluid col-2">
              <app-team-candidate-form-control name="birthday"></app-team-candidate-form-control>
            </div>
            <div class="field col-2 p-fluid">
              <app-team-candidate-form-control name="status"></app-team-candidate-form-control>
            </div>
          </div>
        </div>
        <div class="formgrid grid">
          <p-fieldset [toggleable]="true" class="field col-12 p-fluid">
            <ng-template pTemplate="header">
              <fa-icon [icon]="hubspotIcon" class="mr-2"></fa-icon>
              HubSpot
            </ng-template>
            <div class="formgrid grid">
              <div class="field col-3 p-fluid mb-0">
                <app-team-candidate-form-control name="hubspotCandidateId"
                                                 [linkValue]="hubspotCandidate?.url"></app-team-candidate-form-control>
              </div>
              <div class="field col-3 p-fluid mb-0">
                <label class="opacity-80">Pipeline</label>
                <input [ngModel]="hubspotCandidate?.pipeline" [ngModelOptions]="{standalone: true}" pInputText
                       type="text"
                       [disabled]="true" class="opacity-80"/>
              </div>
              <div class="field col-3 p-fluid mb-0">
                <label class="opacity-80">Stage</label>
                <input [ngModel]="hubspotCandidate?.stage" [ngModelOptions]="{standalone: true}" pInputText type="text"
                       [disabled]="true" class="opacity-80"/>
              </div>
              <div class="field col-3 p-fluid mb-0">
                <app-team-candidate-form-control name="hubspotContactId"
                                                 [linkValue]="hubspotContact?.url"></app-team-candidate-form-control>
              </div>
            </div>
          </p-fieldset>
          <div class="field col-12 p-fluid">
            <app-team-candidate-form-control name="skills"></app-team-candidate-form-control>
          </div>

          <div class="field col-6 p-fluid">
            <app-team-candidate-form-control name="availability"></app-team-candidate-form-control>
          </div>
          <div class="field col-6 p-fluid">
            <app-team-candidate-form-control name="costRateDefault"></app-team-candidate-form-control>
          </div>

          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="roleId"></app-team-candidate-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="departmentId"></app-team-candidate-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="talentPartnerId"></app-team-candidate-form-control>
          </div>

          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="country"></app-team-candidate-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control *ngIf="this.form!.get('country')!.value === 'United States'"
                                             name="state"></app-team-candidate-form-control>
            <app-team-candidate-form-control *ngIf="this.form!.get('country')!.value !== 'United States'"
                                             [name]="$any('region')"></app-team-candidate-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="city"></app-team-candidate-form-control>
          </div>

          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="timezone"></app-team-candidate-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="phone"></app-team-candidate-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="email"></app-team-candidate-form-control>
          </div>

          <div class="field col-2 p-fluid">
            <app-team-candidate-form-control name="searchSource"></app-team-candidate-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="primarySourceUrl"></app-team-candidate-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-team-candidate-form-control name="searchSourceKeyword"></app-team-candidate-form-control>
          </div>
          <div class="field col-2 p-fluid">
            <app-team-candidate-form-control name="referralId"></app-team-candidate-form-control>
          </div>

          <p-fieldset legend="Links" [toggleable]="true" class="field col-12 p-fluid">
            <div formGroupName="usefulLinks" class="flex justify-content-between">
              <div *ngFor="let link of links; let i = index" class="p-fluid w-full"
                   [class.mr-2]="i < links.length - 1" [class.ml-2]="i > 0">
                <app-form-control-wrapper [controlName]="link.field" [label]="link.label" [labelIcon]="link.icon"
                                  [iconIsLink]="!link.notUrl"></app-form-control-wrapper>
              </div>
            </div>
          </p-fieldset>
        </div>
      </form>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => TeamCandidateEditorComponent)},
    SubscriptionsService
  ]

})
export class TeamCandidateEditorComponent extends TeamParticipantEditorComponent<TTeamCandidateSave, ITeamCandidate> {
  links = [
    {field: 'linkedIn', icon: faLinkedin, label: 'Linked In'},
    {field: 'github', icon: faGithub, label: 'GitHub'},
    {field: 'website', icon: faEarth, label: 'Web Site'},
    {field: 'skype', icon: faSkype, label: 'Skype', notUrl: true},
  ];

  hubspotIcon = faHubspot;
  hubspotCandidate: IHubspotCandidate | null = null;
  hubspotContact: IHubspotContact | null = null;

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              fb: FormBuilder,
              propertyOptions: EntityPropertyOptionsService) {
    super(fb, propertyOptions);
    this.entityName = 'Team Candidate';
    this.api = {
      getEntity: this.resource.getTeamCandidate.bind(this.resource),
      createEntity: this.resource.createTeamCandidate.bind(this.resource),
      updateEntity: this.resource.updateTeamCandidate.bind(this.resource),
      archiveEntities: this.resource.patchTeamCandidates.bind(this.resource),
      deleteEntities: this.resource.deleteTeamCandidates.bind(this.resource),
    }
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override onLoad(): void {
    this.refreshHubspotCandidate(this.entity.hubspotCandidateId);
    this.refreshHubspotContact(this.entity.hubspotContactId);
  }

  override buildForm(): void {
    this.subscriptions.unsubscribe();
    const checkExistsPrimarySourceUrl = this.getCheckExists(this.resource.getTeamCandidates.bind(this.resource), 'primarySourceUrl');
    this.form = this.fb.group<TControlsOf<TTeamCandidateSave>>({
      ...this.createSharedControls(),
      status: new FormControl(this.isNew() ? 'Active' : this.entity.status, [Validators.required]),
      availability: new FormControl(this.entity.availability, [Validators.required]),
      hubspotCandidateId: new FormControl(this.entity.hubspotCandidateId, []),
      hubspotContactId: new FormControl(this.entity.hubspotContactId, []),
      usefulLinks: this.fb.group<TControlsOf<IUsefulLinks>>({
        linkedIn: new FormControl(this.entity.usefulLinks?.linkedIn, [urlValidator]),
        website: new FormControl(this.entity.usefulLinks?.website, [urlValidator]),
        github: new FormControl(this.entity.usefulLinks?.github, [urlValidator]),
        skype: new FormControl(this.entity.usefulLinks?.skype, []),
      }) as any,
    });
    this.form!.controls.primarySourceUrl!.setAsyncValidators([existsAsyncValidator(checkExistsPrimarySourceUrl)]);
    this.addSharedControlsProcessing(this.subscriptions);
    this.subscriptions
      .add(this.form.controls.hubspotCandidateId!.valueChanges.subscribe((val) => {
      this.refreshHubspotCandidate(val);
    })).add(this.form.controls.hubspotContactId!.valueChanges.subscribe((val) => {
      this.refreshHubspotContact(val);
    }));
  }

  protected refreshHubspotCandidate(id?: string | null): void {
    if (id) {
      this.resource.getHubspotCandidate(id).subscribe((e) => this.hubspotCandidate = e);
    } else {
      this.hubspotCandidate = null;
    }
  }

  protected refreshHubspotContact(id?: string | null): void {
    if (id) {
      this.resource.getHubspotContact(id).subscribe((e) => this.hubspotContact = e);
    } else {
      this.hubspotContact = null;
    }
  }

  override getLink(): string | null {
    return this.param?.id ? `/team-candidates/edit/${this.param!.id}` : null;
  }

}
