import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule, NotFoundPageComponent} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainPageComponent} from './components/main/main-page.component';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ConfirmationService, MessageService} from 'primeng/api';
import {MenubarModule} from 'primeng/menubar';
import {LoginPageComponent} from './components/login/login-page.component';
import {BypassHttpService} from './common/services/bypass-http.service';
import {AuthService} from './common/services/auth.service';
import {TabMenuModule} from 'primeng/tabmenu';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthGuardService} from './services/auth-guard.service';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {AppHttpInterceptor} from './app-http-interceptor.service';
import {AppResourceService} from './app.resource.service';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SpinnerizerComponent} from './common/components/spinnerizer.component';
import {ServiceLocator} from './common/util/util';
import {environment} from '../environments/environment';
import {TableModule} from 'primeng/table';
import {JsonViewerComponent} from './common/components/json-viewer.component';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'primeng/tooltip';
import {HsAssociationsPageComponent} from './components/dev-tools/hs/hs-associations-page.component';
import {DropdownModule} from 'primeng/dropdown';
import {TooltipOnOverflowDirective, TooltipOnOverflowShared} from './common/components/tooltip-on-overflow.directive';
import {InplaceModule} from 'primeng/inplace';
import {TabViewModule} from 'primeng/tabview';
import {HsAssociationsEditorComponent} from './components/dev-tools/hs/hs-associations-editor.component';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {HsNewAssociationComponent} from './components/dev-tools/hs/hs-new-association.component';
import {CheckboxModule} from 'primeng/checkbox';
import {AccordionModule} from 'primeng/accordion';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {RelationshipGraphComponent} from './common/components/relationship-graph.component';
import {HsRelationshipDiagramPageComponent} from './components/dev-tools/hs/hs-relationship-diagram-page.component';
import {SidebarModule} from 'primeng/sidebar';
import {TokenStorageService} from './common/services/token-storage.service';
import {BadgeModule} from 'primeng/badge';
import {MenuModule} from 'primeng/menu';
import {LandingPageComponent} from './components/landing-page.component';
import {AppConfigService} from './app-config.service';
import {SkeletonModule} from 'primeng/skeleton';
import {HsObjectBrowserComponent, HsObjectTableComponent} from './components/dev-tools/hs/hs-object-browser.component';
import {HsService} from './services/hs/hs.service';
import {CalendarModule} from 'primeng/calendar';
import {HttpDataConverterService} from './common/services/http-data-converter.service';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MultiSelectModule} from 'primeng/multiselect';
import {NullableDirective} from './common/components/nullable.directive';
import {ChangesDebounceDirective} from './common/components/changes-debounce.directive';
import {DbRelationshipDiagramPageComponent} from './components/dev-tools/db/db-relationship-diagram-page.component';
import {ArrayOfPipe} from './common/components/array-of.pipe';
import {DbBrowserComponent} from './components/dev-tools/db/browser/db-browser.component';
import {DbTableComponent} from './components/dev-tools/db/browser/db-table.component';
import {OptionsPipe} from './common/components/options.pipe';
import {ChipsModule} from 'primeng/chips';
import {DialogModule} from 'primeng/dialog';
import {DbNormalizeNamePipe} from './components/dev-tools/db/db-normalize-name.pipe';
import {HintComponent, HintDirective, TipComponent} from './common/components/tip.component';
import {RecreateViewDirective, RenderThisDirective} from './common/components/recreate-view.directive';
import {AddKeyPipe} from './components/dev-tools/db/add-key.pipe';
import {DbColumnInput, DbColumnOutput} from './components/dev-tools/db/browser/db-column.component';
import {DbPkChooserComponent} from './components/dev-tools/db/browser/db-pk-chooser.component';
import {DbColumnFilter} from './components/dev-tools/db/browser/db-column-filter.component';
import {LifeCycleDirective} from './common/components/life-cycle.directive';
import {TreeModule} from 'primeng/tree';
import {ProjectsPageComponent} from './components/projects/projects-page.component';
import {ProjectsTableComponent} from './components/projects/projects-table.component';
import {ProgressBarModule} from 'primeng/progressbar';
import {MonthChooserComponent} from './common/components/month-chooser.component';
import {ViewAsChooserComponent} from './components/common/view-as-chooser.component';
import {ScheduleChooserComponent} from './components/common/schedule-chooser.component';
import {ProjectDetailsPageComponent} from './components/projects/detail/project-details-page.component';
import {ViewAsAlertComponent} from './components/common/view-as-alert.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ChartModule} from 'primeng/chart';
import { SpeedometerChartComponent } from './components/projects/speedometer-chart.component';
import {KnobModule} from 'primeng/knob';
import {ProgressAvatarComponent} from './components/projects/progress-avatar.component';
import {ProjectBalancePanelComponent} from './components/projects/detail/overview/project-balance-panel.component';
import {
  BudgetComponentsColumnHeadersComponent,
  BudgetComponentsCellsComponent
} from './components/common/budget-components-columns.component';
import {ProjectTeamTableComponent} from './components/projects/detail/overview/project-team-table.component';
import {PeriodChooserComponent} from './common/components/period-chooser.component';
import {ProjectDetailOverviewComponent} from './components/projects/detail/overview/project-detail-overview.component';
import {TreeTableModule} from 'primeng/treetable';
import {SingleProjectDetailsComponent} from './components/projects/detail/details/single-project-details.component';
import {SingleProjectPlanningComponent} from './components/projects/detail/planning/single-project-planning.component';
import { TeamPageComponent } from './components/team/team-page.component';
import { TeamMembersTableComponent } from './components/team/team-members-table.component';
import {TeamMemberDetailPageComponent} from './components/team/detail/team-member-detail-page.component';
import {SingleTeamMemberDetailsComponent} from './components/team/detail/budget/single-team-member-details.component';
import {SingleTeamMemberPlanningComponent} from './components/team/detail/planning/single-team-member-planning.component';
import {TeamCandidatesPageComponent} from './components/team-candidate/team-candidates-page.component';
import {TeamCandidatesTableComponent} from './components/team-candidate/team-candidates-table.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ListboxModule} from 'primeng/listbox';
import {TalentPartnersTableComponent} from './components/team-candidate/talent-partners-table.component';
import {TeamTalentPartnersTableComponent} from './components/team/team-talent-partners-table.component';
import { CompaniesPageComponent } from './components/company/companies-page.component';
import { CompaniesTableComponent } from './components/company/companies-table.component';
import {
  DepartmentEditorComponent,
  DepartmentFormControlComponent,
  DepartmentsPageComponent
} from './components/settings/departments-page.component';
import {
  RoleEditorComponent,
  RoleFormControlComponent,
  RolesPageComponent
} from './components/settings/roles-page.component';
import { SkillsPageComponent } from './components/skills/skills-page.component';
import {SanitizePipe} from './common/components/sanitize.pipe';
import {SkillsTableComponent} from './components/skills/skills-table.component';
import {EntityEditDialogComponent} from './components/common/entity-edit.dialog';
import {
  TeamCandidateEditorComponent
} from './components/team-candidate/team-candidate-editor.component';
import {ControlErrorComponent} from './common/components/control-error.component';
import {ControlErrorDirective} from './common/components/control-error.directive';
import {
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule
} from '@abacritt/angularx-social-login';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {DownloadAttachmentComponent, FileUploadDialog, UploadAttachmentsComponent} from './common/components/file';
import {AvatarComponent} from './common/components/avatar.component';
import {ImageModule} from 'primeng/image';
import {LazyLoadMultiselectComponent} from './common/components/lazy-load-multiselect.component';
import {FormControlWrapperComponent} from './common/components/form-control-wrapper.component';
import {TeamCandidateBulkEditorComponent} from './components/team-candidate/team-candidate-bulk-editor.component';
import {DividerModule} from 'primeng/divider';
import {RadioButtonModule} from 'primeng/radiobutton';
import {
  TeamCandidateFormControlComponent
} from './components/team-candidate/team-candidate-form-control.component';
import {TeamCandidateEditPageComponent} from './components/team-candidate/team-candidate-edit-page.component';
import {SyncCandidatesDialog} from './components/team-candidate/sync-candidates.dialog';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ColumnHeaderComponent} from './components/common/column-header.component';
import {SwitchCasesDirective} from './common/components/switch-cases.directive';
import {TableCellComponent} from './components/common/table-cell.component';
import {RowMenuButtonComponent, TableToolbarComponent} from './common/components/table-base.component';
import {HoursPipe} from './common/components/hours.pipe';
import {CardModule} from 'primeng/card';
import {TreeSelectModule} from 'primeng/treeselect';
import {TreeSelectComponent} from './common/components/tree-select.component';
import {TestComponent} from './test.component';
import {SkillsFormControlComponent} from './components/skills/skills-form-control.component';
import {SkillsEditorComponent} from './components/skills/skills-editor.component';
import { CompanyFormControlComponent } from './components/company/company-form-control.component';
import { CompanyEditorComponent } from './components/company/company-editor.component';
import {ReferralsTableComponent} from './components/referral/referrals-table.component';
import {ReferralsPageComponent} from './components/referral/referrals-page.component';
import {ReferralFormControlComponent} from './components/referral/referral-form-control.component';
import {ReferralEditorComponent} from './components/settings/referral-editor.component';
import {TeamParticipantFormControlComponent} from './components/common/team-participant-form-control.component';
import {TeamMemberFormControlComponent} from './components/team/team-member-form-control.component';
import {TeamMemberEditorComponent} from './components/team/team-member-editor.component';
import {TeamMemberStatusEditorComponent} from './components/team/team-member-status-editor.component';
import {TeamMemberBulkEditorComponent} from './components/team/team-member-bulk-editor.component';
import {EntityBulkEditorFormComponent} from './components/common/entity-bulk-editor.component';
import {HolidayCalendarEntityEditorComponent} from './components/schedule/holiday-calendar-entity-editor.component';
import {HolidayCalendarsTableComponent} from './components/schedule/holiday-calendars-table.component';
import {HolidayCalendarsPageComponent} from './components/schedule/holiday-calendars-page.component';
import {HolidayCalendarEditPageComponent} from './components/schedule/holiday-calendar-edit-page.component';
import {WorkweekPageComponent} from './components/schedule/workweek-page.component';
import {HolidaysUniversePageComponent} from './components/schedule/holidays-universe-page.component';
import {InputMaskModule} from 'primeng/inputmask';
import {ExistsAsyncValidatorDirective, ExistsValidatorDirective} from './common/components/exists-validator.directive';
import {WorkweekEditorComponent} from './components/schedule/workweek-editor.component';
import {LifeCycleHookDirective} from './common/components/life-cycle-hook.directive';
import {HolidayCalendarEditorComponent} from './components/schedule/holiday-calendar-editor.component';
import {
  HolidayCalendarEntityEditorOldComponent
} from './components/schedule/holiday-calendar-entity-editor-old.component';
import { AllProjectsDetailsComponent } from './components/projects/all-projects-details.component';
import {
  ColumnExpansionTableBodyRow, ColumnExpansionTableCategoryCell, ColumnExpansionTableDataCell,
  ColumnExpansionTableFrozenHeader,
  ColumnExpansionTableHeader, ColumnExpansionTablePopupCell, ColumnExpansionTableSummaryRow
} from './components/common/column-expansion/column-expansion-table.component';
import { DetailsTableComponent } from './components/common/column-expansion/details-table.component';
import { PlanningTableComponent } from './components/common/column-expansion/planning-table.component';
import { NoValueComponent } from './common/components/util.components';
import { TeamMemberEntryEntityComponent } from './components/team/detail/team-member-entry-entity.component';
import { ProjectEntryEntityComponent } from './components/projects/detail/project-entry-entity.component';
import {AllClientsDetailsComponents} from './components/projects/all-clients-details.component';
import {AllAgenciesDetailsComponent} from './components/projects/all-agencies-details.component';
import {SingleEntityDetailsComponent} from './components/common/single-entity-details.component';
import {ClientDetailsPageComponent} from './components/projects/detail/client-details-page.component';
import {SingleClientDetailsComponent} from './components/projects/detail/details/single-client-details.component';
import {SingleAgencyDetailsComponent} from './components/projects/detail/details/single-agency-details.component';
import {AgencyDetailsPageComponent} from './components/projects/detail/agency-details-page.component';
import {TimeSheetsPageComponent} from './components/time/time-sheets/time-sheets.page.component';
import {
  DayTimeEntriesDialogComponent,
  DayTimeEntryInlineEditor,
  DayTimeSheetsDetails
} from './components/time/time-sheets/day-time-sheets-details';
import {NullValuePipe} from './common/components/null-value.pipe';
import {WeekTimeSheetsDetails} from './components/time/time-sheets/week-time-sheets-details';
import {TimeEntriesPageComponent} from './components/time/time-entries/time-entries-page.component';
import {TimeEntriesTableComponent} from './components/time/time-entries/time-entries-table.component';
import {TimeApprovalsPageComponent} from './components/time/time-approvals/time-approvals-page.component';
import {TimeApprovalsTableComponent} from './components/time/time-approvals/time-approvals-table.component';
import {TreeTableFilterComponent} from './components/common/tree-table';
import {
  ExpenseCategoriesPageComponent,
  ExpenseCategoryEditorComponent,
  ExpenseCategoryFormControlComponent
} from './components/settings/expense-category-page.component';
import {TeamMemberChooserComponent} from './components/common/team-member-chooser.component';
import {ExpensesPageComponent} from './components/time/expenses/expenses-page.component';
import {
  ExpensesTableComponent
} from './components/time/expenses/expenses-table.component';
import {FileSizePipe} from './common/components/file-size.pipe';
import {ChipModule} from 'primeng/chip';
import {TeamMembersFilterComponent} from './components/team/team-members-filter.component';
import {
  AttachmentsCellComponent,
  ExpenseEditorComponent,
  ExpenseFormControl
} from './components/time/expenses/expenses';
import {ExpenseReportsOrApprovalsTableComponent} from './components/time/expenses/expense-reports-or-approvals-table.component';
import {ExpenseReportsOrApprovalsPageComponent} from './components/time/expenses/expense-reports-or-approvals-page.component';
import {ApprovalsStateComponent} from './components/time/common';
import {TimeOffRequestsPageComponent} from './components/time/time-offs/time-off-requests-page.component';
import {TimeOffRequestsTableComponent} from './components/time/time-offs/time-off-requests-table.component';
import {TimeOffRequestEditorComponent, TimeOffRequestFormControl} from './components/time/time-offs/time-offs';
import {TimeOffsPageComponent} from './components/time/time-offs/time-offs-page.component';
import {TimeOffsTableComponent} from './components/time/time-offs/time-offs-table.component';
import {
  PaidTimeOffEditorComponent,
  PaidTimeOffFormControlComponent, PaidTimeOffsPageComponent
} from './components/settings/paid-time-offs-page.component';
import {CollapsiblePanelComponent} from './common/components/collapsible-panel.component';
import {RippleModule} from 'primeng/ripple';
import {EntityEditPageComponent} from './components/common/entity-edit-page.component';
import {TeamMemberEditPageComponent} from './components/team/team-member-edit-page.component';
import {ContactsTableComponent} from './components/contacts/contacts-table.component';
import {CommonPageComponent} from './components/common/common-page.component';
import {ContactsPageComponent} from './components/contacts/contacts-page.component';
import {FilterByQueryParamsDirective} from './components/common/filter-by-query-params.directive';
import {ContactFormControlComponent} from './components/contacts/contact-form-control.component';
import {ContactEditorComponent} from './components/contacts/contact-editor.component';

@NgModule({
  declarations: [
    SpinnerizerComponent,
    JsonViewerComponent,
    TooltipOnOverflowDirective,
    NullableDirective,
    SwitchCasesDirective,
    ChangesDebounceDirective,
    LifeCycleHookDirective,
    ArrayOfPipe,
    OptionsPipe,
    SanitizePipe,
    FileSizePipe,
    HoursPipe,
    NullValuePipe,
    ExistsValidatorDirective,
    ExistsAsyncValidatorDirective,
    TipComponent,
    HintComponent,
    HintDirective,
    RecreateViewDirective,
    RenderThisDirective,
    LifeCycleDirective,
    MonthChooserComponent,
    PeriodChooserComponent,
    ControlErrorComponent,
    ControlErrorDirective,
    FileUploadDialog,
    DownloadAttachmentComponent,
    UploadAttachmentsComponent,
    TreeSelectComponent,
    LazyLoadMultiselectComponent,
    FormControlWrapperComponent,
    AvatarComponent,
    RowMenuButtonComponent,
    TableToolbarComponent,
    NoValueComponent,
    CollapsiblePanelComponent,

    AppComponent,
    MainPageComponent,
    LoginPageComponent,
    LandingPageComponent,
    HsAssociationsPageComponent,
    HsAssociationsEditorComponent,
    HsNewAssociationComponent,
    RelationshipGraphComponent,
    HsRelationshipDiagramPageComponent,
    HsObjectTableComponent,
    HsObjectBrowserComponent,
    DbRelationshipDiagramPageComponent,
    DbBrowserComponent,
    DbTableComponent,
    DbColumnFilter,
    DbColumnOutput,
    DbColumnInput,
    DbNormalizeNamePipe,
    DbPkChooserComponent,
    AddKeyPipe,
    NotFoundPageComponent,

    FilterByQueryParamsDirective,
    ColumnHeaderComponent,
    TreeTableFilterComponent,
    TableCellComponent,
    ProgressAvatarComponent,
    ViewAsChooserComponent,
    ScheduleChooserComponent,
    TeamMemberChooserComponent,
    ViewAsAlertComponent,
    SpeedometerChartComponent,
    BudgetComponentsColumnHeadersComponent,
    BudgetComponentsCellsComponent,
    EntityEditDialogComponent,
    EntityEditPageComponent,
    EntityBulkEditorFormComponent,
    ColumnExpansionTableHeader,
    ColumnExpansionTableFrozenHeader,
    ColumnExpansionTableBodyRow,
    ColumnExpansionTablePopupCell,
    ColumnExpansionTableDataCell,
    ColumnExpansionTableCategoryCell,
    ColumnExpansionTableSummaryRow,
    DetailsTableComponent,
    PlanningTableComponent,
    SingleEntityDetailsComponent,
    CommonPageComponent,

    HolidayCalendarsTableComponent,
    HolidayCalendarEntityEditorOldComponent,
    HolidayCalendarEntityEditorComponent,
    HolidayCalendarsPageComponent,
    HolidayCalendarEditPageComponent,
    HolidayCalendarEditorComponent,
    WorkweekPageComponent,
    WorkweekEditorComponent,
    HolidaysUniversePageComponent,

    ProjectsPageComponent,
    ProjectsTableComponent,
    ProjectDetailsPageComponent,
    ProjectDetailOverviewComponent,
    ProjectBalancePanelComponent,
    ProjectTeamTableComponent,
    ProjectEntryEntityComponent,
    SingleProjectDetailsComponent,
    SingleProjectPlanningComponent,
    AllProjectsDetailsComponent,
    AllClientsDetailsComponents,
    AllAgenciesDetailsComponent,
    ClientDetailsPageComponent,
    SingleClientDetailsComponent,
    AgencyDetailsPageComponent,
    SingleAgencyDetailsComponent,

    TeamParticipantFormControlComponent,
    TeamPageComponent,
    TeamMembersTableComponent,
    TeamMemberFormControlComponent,
    TeamMemberEditorComponent,
    TeamMemberEditPageComponent,
    TeamMemberBulkEditorComponent,
    TeamTalentPartnersTableComponent,
    TeamMemberDetailPageComponent,
    TeamMembersFilterComponent,
    SingleTeamMemberDetailsComponent,
    SingleTeamMemberPlanningComponent,
    TeamMemberStatusEditorComponent,
    TeamMemberEntryEntityComponent,
    TeamCandidatesPageComponent,
    TeamCandidatesTableComponent,
    TeamCandidateFormControlComponent,
    TeamCandidateEditorComponent,
    TeamCandidateBulkEditorComponent,
    TeamCandidateEditPageComponent,
    TalentPartnersTableComponent,

    SyncCandidatesDialog,
    CompaniesPageComponent,
    CompaniesTableComponent,
    CompanyFormControlComponent,
    CompanyEditorComponent,

    DepartmentsPageComponent,
    DepartmentFormControlComponent,
    DepartmentEditorComponent,
    RolesPageComponent,
    RoleFormControlComponent,
    RoleEditorComponent,
    ExpenseCategoriesPageComponent,
    ExpenseCategoryFormControlComponent,
    ExpenseCategoryEditorComponent,
    SkillsPageComponent,
    SkillsTableComponent,
    SkillsFormControlComponent,
    SkillsEditorComponent,
    PaidTimeOffFormControlComponent,
    PaidTimeOffEditorComponent,
    PaidTimeOffsPageComponent,

    ReferralsPageComponent,
    ReferralsTableComponent,
    ReferralFormControlComponent,
    ReferralEditorComponent,

    ContactsPageComponent,
    ContactsTableComponent,
    ContactFormControlComponent,
    ContactEditorComponent,

    ApprovalsStateComponent,
    TimeSheetsPageComponent,
    DayTimeSheetsDetails,
    DayTimeEntriesDialogComponent,
    DayTimeEntryInlineEditor,
    WeekTimeSheetsDetails,
    TimeEntriesPageComponent,
    TimeEntriesTableComponent,
    TimeApprovalsPageComponent,
    TimeApprovalsTableComponent,
    ExpensesPageComponent,
    ExpensesTableComponent,
    ExpenseReportsOrApprovalsPageComponent,
    ExpenseReportsOrApprovalsTableComponent,
    ExpenseFormControl,
    ExpenseEditorComponent,
    TimeOffRequestsPageComponent,
    TimeOffRequestsTableComponent,
    AttachmentsCellComponent,
    TimeOffRequestFormControl,
    TimeOffRequestEditorComponent,
    TimeOffsPageComponent,
    TimeOffsTableComponent,
    TestComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    InplaceModule,

    // PrimeNG
    ToastModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    MenubarModule,
    TabMenuModule,
    MessagesModule,
    MessageModule,
    ProgressSpinnerModule,
    TableModule,
    InputTextModule,
    TooltipModule,
    DropdownModule,
    TabViewModule,
    DynamicDialogModule,
    DialogModule,
    CheckboxModule,
    AccordionModule,
    OverlayPanelModule,
    SidebarModule,
    BadgeModule,
    MenuModule,
    SkeletonModule,
    CalendarModule,
    InputNumberModule,
    InputTextareaModule,
    MultiSelectModule,
    ChipsModule,
    TreeModule,
    ProgressBarModule,
    SplitButtonModule,
    SelectButtonModule,
    KnobModule,
    ChartModule,
    TreeTableModule,
    ListboxModule,
    FieldsetModule,
    FileUploadModule,
    ImageModule,
    DividerModule,
    RadioButtonModule,
    CardModule,
    TreeSelectModule,
    InputMaskModule,
    ChipModule,
    RippleModule,

    // Other
    SocialLoginModule,
    GoogleSigninButtonModule,
    FontAwesomeModule,
    ImageCropperModule
  ],
  providers: [
    // PrimeNG
    MessageService,
    ConfirmationService,

    AuthService,
    BypassHttpService,
    HttpDataConverterService,
    TokenStorageService,
    TooltipOnOverflowShared,

    HsService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId,
              { oneTapEnabled: !!!localStorage.getItem('token')}
            )
          }
        ],
        onError: (error) => {
          ServiceLocator.message({
            severity: 'error',
            summary: 'Google Auth',
            detail: error.details.toString(),
            life: 10000
          });
        }
      } as SocialAuthServiceConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: AppConfigService): () => Promise<any> => {
        return () => service.init()
      },
      deps: [AppConfigService],
      multi: true
    },
    AuthGuardService,
    AppResourceService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
