import {
  COMMON_STATUSES,
  IBudgetComponents,
  IIdentified,
  INamed,
  IPerson,
  ITeamParticipant,
  PAYMENT_TERMS,
  TMtEmailType,
  TNetTerm,
  TPaymentTerm
} from './common';
import {TProjectEntryEntity} from './project';
import {IWorkweek} from './settings';
import {IHolidayCalendarBase} from './schedule';
import {IDetails} from './details';
import {COMPANY_TYPE} from './company';

export const TEAM_API_ENDPOINTS = {
  teamMemberCount: '/api/team-members/count',
  teamMembers: '/api/team-members',
  teamMember: '/api/team-members/:id',
  export: '/api/team-members/export',
  import: '/api/team-members/import',

  talentPartners: '/api/team-members/talent-partners',
  singleTeamMemberDetails: '/api/team-members/:id/details',
  singleTeamMemberPlanning: '/api/team-members/:id/planning',
  teamMemberCompanies: '/api/team-members/companies',
  paymentRecipients: '/api/team-members/payment-recipients'
};

export const TEAM_MEMBER_TYPE = [
  'Employee - Hourly',
  'Employee - Recurring Fixed',
  'Contractor - Hourly',
  'Contractor - Monthly Fixed',
  'Contractor - One-Time Fixed',
  'TP SubContractor - Hourly',
  'TP SubContractor - Monthly Fixed',
  'TP SubContractor - One-Time Fixed'
] as const;

export type TTeamMemberType = typeof TEAM_MEMBER_TYPE[number];

export const CONTRACTOR_TYPE = ['Self', 'Contractor'] as const;

export type TContractorType = typeof CONTRACTOR_TYPE[number];


export function isTalentPartnerRequiredForTeamMember(type: TTeamMemberType): boolean {
  return /\bSubContractor\b/.test(type);
}

export function isContractorTypeRequiredForTeamMember(type: TTeamMemberType): boolean {
  return /\bContractor\b/.test(type);
}

export function isContractorCompanyRequiredForTeamMember(type: TTeamMemberType, contractorType: TContractorType): boolean {
  return isContractorTypeRequiredForTeamMember(type) && contractorType === 'Contractor';
}

export type TTeamMemberPaymentDep = {
  paymentTerms: Array<TPaymentTerm>;
  requiresNetTerms: boolean;
  requiresWeeklyCapacityMin: boolean;
};

export const TEAM_MEMBER_DEPS: { [type in TTeamMemberType]: TTeamMemberPaymentDep } = {
  'Employee - Hourly': {paymentTerms: ['Arrears'], requiresNetTerms: false, requiresWeeklyCapacityMin: false},
  'Employee - Recurring Fixed': {paymentTerms: [], requiresNetTerms: false, requiresWeeklyCapacityMin: true},
  'Contractor - Hourly': {paymentTerms: ['Arrears'], requiresNetTerms: true, requiresWeeklyCapacityMin: false},
  'Contractor - Monthly Fixed': {paymentTerms: [...PAYMENT_TERMS], requiresNetTerms: true, requiresWeeklyCapacityMin: true},
  'Contractor - One-Time Fixed': {paymentTerms: [...PAYMENT_TERMS], requiresNetTerms: true, requiresWeeklyCapacityMin: true},
  'TP SubContractor - Hourly': {paymentTerms: ['Arrears'], requiresNetTerms: true, requiresWeeklyCapacityMin: false},
  'TP SubContractor - Monthly Fixed': {paymentTerms: [...PAYMENT_TERMS], requiresNetTerms: true, requiresWeeklyCapacityMin: true},
  'TP SubContractor - One-Time Fixed': {paymentTerms: [...PAYMENT_TERMS], requiresNetTerms: true, requiresWeeklyCapacityMin: true}
};

export const DEFAULT_PAID_TIME_OFF: {[type in TTeamMemberType]: number | null } = {
  'Employee - Hourly' : 21,
  'Employee - Recurring Fixed' : null,
  'Contractor - Hourly': 0,
  'Contractor - Monthly Fixed': 0,
  'Contractor - One-Time Fixed': 0,
  'TP SubContractor - Hourly': 0,
  'TP SubContractor - Monthly Fixed': 0,
  'TP SubContractor - One-Time Fixed': 0
};

export const TEAM_MEMBER_STATUS = [
  ...COMMON_STATUSES,
  'Currently Unavailable'
] as const;

export type TTeamMemberStatus = typeof TEAM_MEMBER_STATUS[number];

export const LEAVING_VOLUNTARY_TERMINATION_REASONS = [
  'Retirement', 'Another job', 'Higher wages', 'Better work conditions', 'Conflict with colleagues or supervisor',
  'Entered military service', 'Expiration of appointment', 'Family obligations', 'Further education',
  'Transportation', 'Job abandonment', 'Health reasons', 'Relocation', 'Other',
] as const;

export const LEAVING_LAYOFF_REASONS = [
  'Lack of work', 'Displaced by senior employee', 'Lack of funds', 'Reorganization', 'Retirement due to layoff',
] as const;


export const LEAVING_TERMINATION_FOR_CAUSE_REASONS = [
  'Retirement at discharge', 'Absenteeism/punctuality', 'Inappropriate behavior/conduct', 'Theft or dishonesty',
  'Insubordination', 'Misuse of alcohol or drugs', 'Unsatisfactory performance', 'Other violation of Institute policies',
] as const;

export const LEAVING_OTHER_REASONS = [
  'Deceased', 'Failed to report to work/no show', 'Return to retirement status', 'Resign in lieu of discharge',
  'Not specified by employee', 'Theft/dishonesty – not eligible for rehire', 'Violation of Institute policy - not eligible for rehire',
  'Threats of violence – not eligible for rehire', 'Contact HR for reason – not eligible for rehire',
  'Involuntary, loss of license/certification/IPIA/visa'
] as const;

export const TEAM_MEMBER_ARCHIVE_REASONS_GROUPED: { [group: string]: readonly string[] } = {
  'Voluntary Termination': LEAVING_VOLUNTARY_TERMINATION_REASONS,
  'Layoff': LEAVING_LAYOFF_REASONS,
  'Termination for Cause': LEAVING_TERMINATION_FOR_CAUSE_REASONS,
  'Other': LEAVING_OTHER_REASONS
};

export const TEAM_MEMBER_CURRENTLY_UNAVAILABLE_REASONS = [
  'Freelance assignment', 'Medical leave', 'Maternity leave'
] as const;

export const TEAM_MEMBER_CURRENTLY_UNAVAILABLE_REASONS_GROUPED: { [group: string]: readonly string[] } = {
  'Currently Unavailable': TEAM_MEMBER_CURRENTLY_UNAVAILABLE_REASONS
};

export const TEAM_MEMBER_ARCHIVE_REASONS = [
  ...LEAVING_VOLUNTARY_TERMINATION_REASONS, ...LEAVING_LAYOFF_REASONS, ...LEAVING_TERMINATION_FOR_CAUSE_REASONS, ...LEAVING_OTHER_REASONS
] as const;

export type TTeamMemberArchiveReason = typeof TEAM_MEMBER_ARCHIVE_REASONS[number];
export type TTeamMemberCurrentlyUnavailableReason = typeof TEAM_MEMBER_CURRENTLY_UNAVAILABLE_REASONS[number];

export interface ITeamMember extends ITeamParticipant, IBudgetComponents {
  status: TTeamMemberStatus;
  statusChangedReason?: TTeamMemberArchiveReason | TTeamMemberCurrentlyUnavailableReason;
  estimatedAvailableDate?: Date;

  type: TTeamMemberType;

  title: string;
  weeklyCapacityMin?: number;
  weeklyCapacityTarget: number;
  weeklyCapacityMax: number;


  defaultWorkweekId?: string;
  defaultWorkweekName?: string;
  isWorkweekOverridden: boolean;
  overriddenWorkweeks?: Array<IWorkweek>;

  holidayCalendarId?: string;
  holidayCalendarName?: string;
  isHolidayCalendarOverridden: boolean;
  overriddenHolidayCalendar?: IHolidayCalendarBase;

  pto: number | null;
  ptoFrom: Date | null;

  contractorType?: TContractorType;
  contractorCompanyId?: string;
  contractorCompanyName?: string;

  paymentRecipientId: string;
  paymentRecipientName: string;

  paymentTerms?: TPaymentTerm;
  netTerms?: TNetTerm;
  isActivelyStuffed: boolean;
  gustoRequired: boolean;
  defaultHourlyRate: number;
  costRateDefaultPlus?: number;

  mtEmail: string;
  mtEmailType: TMtEmailType;
  hiredDate: Date;
  userRoles: Array<string>;

}

export type TTeamMemberSave = Omit<ITeamMember,
  'id' | 'createdAt' | 'updatedAt' | 'name' |
  'roleName' | 'departmentName' | 'talentPartnerName' | 'searchSourceUrl' |
  'referralName' | 'companyName' | 'paymentRecipientName' | 'holidayCalendarName' | 'isDraft' |
  keyof IBudgetComponents>;


export interface ITeamMemberTalentPartner extends IIdentified, INamed, IBudgetComponents {
}

export type TTeamMemberEntryEntity = IPerson & IIdentified;
export type TSingleTeamMemberDetails = IDetails<TProjectEntryEntity>;


export interface ITeamMemberEmbedded {
  teamMemberId: string;
  teamMemberName: string;
  teamMemberAvatar: string;
  teamMemberRoleId: string;
  teamMemberRoleName: string;
  teamMemberType: string;
  teamMemberStatus: string;
}

export function isInstanceOfTeamMemberEmbedded(project: ITeamMemberEmbedded | TTeamMemberEntryEntity): project is ITeamMemberEmbedded {
  return 'teamMemberId' in project;
}
