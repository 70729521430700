import {EHsRelationship, IHsAssociation, IHsObjectType} from '../../../api/shared/dev-tools/hs-api';

export interface IAssociatedObjectType extends IHsObjectType {
  association: IHsAssociation;
}

export interface IRelationship {
  relationship: EHsRelationship;
  label: string;
}

export const RELATIONSHIPS: Array<IRelationship> = [
  {relationship: EHsRelationship.o2m, label: '1 .. N'},
  {relationship: EHsRelationship.m2o, label: 'N .. 1'},
  {relationship: EHsRelationship.m2m, label: 'N .. M'}
];

export interface IShownObjectType {
  objectType: IHsObjectType | null;
}


export interface ICreateRelationshipParam {
  fromObjectType: IHsObjectType;
  toObjectTypes: Array<IHsObjectType>;
}
