import {Component, forwardRef} from '@angular/core';
import {Validators} from '@angular/forms';
import {AppResourceService} from '../../app.resource.service';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {ITeamMember, TTeamMemberSave} from '../../api/shared/app-domain/team';
import {EntityBulkEditorComponent} from '../common/entity-bulk-editor.component';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {TeamMemberEditService} from './team-member-edit.service';

@Component({
  selector: 'app-team-member-bulkeditor',
  template: `
    <app-entity-bulk-editor-form [bulkEditor]="this">
      <ng-template pTemplate let-field>
        <form [formGroup]="form!">
          <app-team-member-form-control [name]="field"
                                        [statusChangedReasonOptions]="editService.statusChangedReasonOptions"
                                        [paymentTermsOptions]="editService.paymentTermsOptions">
          </app-team-member-form-control>
        </form>
      </ng-template>
    </app-entity-bulk-editor-form>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => TeamMemberBulkEditorComponent)},
    TeamMemberEditService
  ]
})
export class TeamMemberBulkEditorComponent extends EntityBulkEditorComponent<TTeamMemberSave, ITeamMember> {

  constructor(private resource: AppResourceService,
              public editService: TeamMemberEditService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.entityName = 'Team Member';
    this.editService.setEditor(this);
    this.api.patchEntities = this.resource.patchTeamMembers.bind(resource);
    this.fieldDescriptors = {
      status: {validators: [Validators.required], deps: ['statusChangedReason', 'estimatedAvailableDate']},
      statusChangedReason: {},
      estimatedAvailableDate: {},
      type: {validators: [Validators.required], deps: ['paymentTerms', 'netTerms']},
      paymentTerms: {},
      netTerms: {validators: [Validators.required]},
      hiredDate: {validators: [Validators.required]},
      skills: {validators: [Validators.required], isArray: true},
      costRateDefault: {},
      costRateDefaultPlus: {},
      weeklyCapacityMin: {},
      weeklyCapacityTarget:  {validators: [Validators.required]},
      weeklyCapacityMax:  {validators: [Validators.required]},
      defaultHourlyRate:  {validators: [Validators.required]},
      // defaultWorkweek:  {validators: [Validators.required]},
      holidayCalendarId: {validators: [Validators.required]},
      isActivelyStuffed: {validators: [Validators.required]},
      gustoRequired: {validators: [Validators.required]},
      contractorCompanyId: {},
      paymentRecipientId: {validators: [Validators.required]},
      roleId: {validators: [Validators.required]},
      departmentId: {validators: [Validators.required]},
      talentPartnerId: {},
      referralId: {},
      userRoles: {isArray: true}
    };
  }

  override onChangeSelectedFields(selectedFields:Array<keyof TTeamMemberSave>) {
    this.editService.unsubscribe();
    super.onChangeSelectedFields(selectedFields);
    this.editService.subscribe();
  }

}
