import { Component, Input } from '@angular/core';
import { IPeriod } from '../../api/shared/app-domain/common';
import { AppResourceService } from '../../app.resource.service';
import { ISearchRequest, TQueryExpression } from '../../api/shared/search-api';

@Component({
  selector: 'app-all-projects-details',
  template: `
    <app-details-table [query]="query" [period]="period" [fetch]="fetch" [useSearchRequest]="true"
                       [searchEntryEntities]="{searchOption: 'projects'}">
      <ng-template pTemplate="entryEntityTpl" let-rowData>
        <app-project-entry-entity [entryEntity]="rowData.entryEntity" routerLink="/projects" [queryParams]="{tab: 'details'}">
        </app-project-entry-entity>
      </ng-template>
    </app-details-table>
  `,
  providers: [
  ]
})
export class AllProjectsDetailsComponent {
  @Input() query: TQueryExpression | null = null;
  @Input() period!: IPeriod;

  fetch = (searchRequest: ISearchRequest) => this.resource.getAllProjectsDetails(
    { ...searchRequest, ...{ param: { period: this.period } } }
  );

  constructor(public resource: AppResourceService) {
  }
}
