import {Component, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {ServiceLocator} from '../../../common/util/util';
import {EntityPropertyOptionsService, TOptionsTeamMember} from '../../common/entity-property-options.service';
import {APPROVAL_STATES, IPeriod, TApprovalsState} from '../../../api/shared/app-domain/common';
import {getCurrentPeriodValue} from '../../../common/components/period-chooser.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TQueryExpression} from '../../../api/shared/search-api';
import {getApprovalsTabMenu} from '../common';
import {TimeOffRequestsTableComponent} from './time-off-requests-table.component';

@Component({
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="flex align-items-baseline">
              <div class="mt-page-title">Time Off Requests</div>
            </div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <p-menubar class="mt-page-menu-bar" [model]="tabMenuItems"></p-menubar>
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
            <button pButton pRipple label="Actions"
                    class="p-button-outlined ml-4"
                    [style]="{width: '115px'}"
                    icon="pi pi-chevron-down"
                    iconPos="right" (click)="actionsMenu.toggle($event)"></button>
            <button [disabled]="teamMember==null" pButton pRipple label="Create Time Off Request"
                    class="ml-2"
                    icon="pi pi-plus" (click)="table.openCreateOrUpdateDialog(null)"></button>
          </div>
        </div>
      </div>
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-7 flex align-items-center">
            <app-team-member-chooser [(ngModel)]="teamMember"></app-team-member-chooser>
            <img class="ml-2" [src]="propertyOptions.getTeamMemberTypeIcon(teamMember?.type!)" height="16" width="16"/>
            <div class="ml-1 text-gray-700">
              {{teamMember?.type}}
            </div>
          </div>
          <div class="col-5 flex justify-content-end">
            <app-period-chooser [disabled]="teamMember == null" [(ngModel)]="period"
                                [unitFilter]="['Day', 'Week', 'Month', 'Quarter', 'Year', 'Custom']"></app-period-chooser>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="teamMember" class="mt-2 shadow-1 bg-white p-3 pb-2">
      <app-time-off-requests-table *appRecreateViewKey="tab" [period]="period" [teamMember]="teamMember" [query]="query"
                                   [state]="tab"></app-time-off-requests-table>
    </div>
  `,
  styles: [`
  `],
  providers: [
    EntityPropertyOptionsService,
  ]
})
export class TimeOffRequestsPageComponent {
  teamMember: TOptionsTeamMember | null = null;
  tab: TApprovalsState = 'Unsubmitted';
  tabMenuItems = getApprovalsTabMenu('/time-off-requests', ['All']);
  period: IPeriod = getCurrentPeriodValue('Month');
  query: TQueryExpression | null = null;

  @ViewChild(TimeOffRequestsTableComponent) table!: TimeOffRequestsTableComponent;

  actionItems: Array<MenuItem> = [
    {label: 'Sync Accounting', command: this.tbd},
    {label: 'Sync Timekeeping', command: this.tbd}
  ];

  constructor(public activatedRoute: ActivatedRoute,
              public router: Router,
              public propertyOptions: EntityPropertyOptionsService) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const tab = params.get('tab') as TApprovalsState;
      if (!!!tab || !APPROVAL_STATES.includes(tab)) {
        this.router.navigate([],
          {relativeTo: this.activatedRoute, queryParams: {tab: 'Unsubmitted'}, queryParamsHandling: 'merge'});
      } else {
        this.tab = tab;
      }
      this.applyFilters();
    });
  }

  applyFilters(): void {
    const query: TQueryExpression = {
      logical: 'and',
      predicates: [{
        field: 'state',
        operator: 'equals',
        value: this.tab
      }]
    };
    this.query = query;
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }
}
