import {Injectable} from '@angular/core';
import {AppResourceService} from './app.resource.service';
import {catchError} from 'rxjs/operators';
import {IUserProfileRecord} from './api/api';
import {forkJoin} from 'rxjs';
import {IViewAs, IViewAsItem} from './api/shared/user-api';
import {find} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  constructor(private resource: AppResourceService) {
  }

  private _userProfile: IUserProfileRecord | null = null;

  get userProfile(): IUserProfileRecord | null {
    return this._userProfile;
  }

  private _viewAs: IViewAs | null = null;

  get viewAs(): IViewAs | null {
    return this._viewAs;
  }

  get viewAsMe(): IViewAsItem | null {
    return find(this.viewAs?.users, {id: this.userProfile?.email}) ?? null;
  }

  init(onSignIn?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      forkJoin([
        this.resource.getCurrentUserProfile(),
        this.resource.getViewAs()
      ])
        .pipe(
          catchError((e, c) => {
            if (e.status === 401) {
              resolve();
            } else {
              if (!onSignIn) {
                alert(e.error?.message ?? e.message);
              }
              reject();
            }
            throw e;
          })
        )
        .subscribe((responses) => {
          this._userProfile = responses[0];
          this._viewAs = responses[1];
          resolve();
        });
    });
  }

  isViewAsMe(viewAsItem: IViewAsItem | null): boolean {
    return viewAsItem == null ||
      (viewAsItem.type === 'user' && viewAsItem.id === this.userProfile?.email)
  }

  get timeSubmitBasis(): 'day' | 'week' {
    return 'week';
  }
}
