import {Component, ViewChild} from '@angular/core';
import {AppResourceService} from '../../app.resource.service';
import {ContactsTableComponent} from './contacts-table.component';
import {FilterMatchMode} from 'primeng/api';

@Component({
  selector: 'app-contacts-page',
  template: `
    <app-common-page entityName="Contact" [entityResource]="resource.contacts"
                     (onNewEntity)="table.openCreateOrUpdateDialog(null)">
      <ng-template pTemplate="content">
        <app-contacts-table
          [filterByQueryParams]="[{field: 'types', matchMode: 'contains', isValueArray: true}]">
        </app-contacts-table>
      </ng-template>
    </app-common-page>
  `
})
export class ContactsPageComponent {
  @ViewChild(ContactsTableComponent) table!: ContactsTableComponent;

  constructor(public resource: AppResourceService) {
  }

}
