import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { getCurrentPeriodValue } from '../../common/components/period-chooser.component';
import { IViewAsItem } from '../../api/shared/user-api';
import { TQueryExpression } from '../../api/shared/search-api';
import { ActivatedRoute, Router } from '@angular/router';
import { AppResourceService } from '../../app.resource.service';
import { AppConfigService } from '../../app-config.service';
import { ServiceLocator } from '../../common/util/util';
import { IPeriod } from '../../api/shared/app-domain/common';
import { TEAM_CANDIDATE_SCOPES, TTeamCandidateScope } from './team-candidate';
import { TeamCandidatesTableComponent } from './team-candidates-table.component';
import { SyncCandidatesDialog } from './sync-candidates.dialog';
import { IScopeFilter } from '../common/common';

const TEAM_CANDIDATE_MENU: Array<MenuItem> = [
  {
    label: 'All Candidates',
    routerLink: ['/team-candidates'],
    queryParams: {
      scope: 'all'
    }
  },
  {
    label: 'By Talent Partner',
    routerLink: ['/team-candidates'],
    queryParams: {
      scope: 'talentPartner'
    }
  }
];

@Component({
  selector: 'app-team-candidates-page',
  template: `
    <app-sync-candidates-dialog></app-sync-candidates-dialog>
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="flex align-items-baseline">
              <div class="mt-page-title">Team Candidates</div>
            </div>
            <div class="text-gray-700 text-sm">{{totalTeamCandidates}} candidate{{totalTeamCandidates ? 's' : ''}}</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <p-menubar class="mt-page-menu-bar" [model]="menuItems"></p-menubar>
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
            <button pButton pRipple label="Actions"
                    class="p-button-outlined ml-4"
                    [style]="{width: '115px'}"
                    icon="pi pi-chevron-down"
                    iconPos="right" (click)="actionsMenu.toggle($event)"></button>
            <button pButton pRipple label="New Candidate"
                    class="ml-2"
                    icon="pi pi-plus" (click)="newEntity()"></button>
          </div>
        </div>
      </div>
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-7 flex">
            <app-view-as-chooser #viewAsChooser [(ngModel)]="viewAsItem" (ngModelChange)="onChangeViewAs()">
            </app-view-as-chooser>
            <div *ngIf="scopeFilters[scope]" class="flex align-items-center ml-4 ">
              <span>Showing Team Candidates for:</span>
              <span class="text-gray-600  font-semibold ml-1"> {{scopeFilters[scope]?.name}}</span>
              <button pButton pRipple type="button" icon="pi pi-times"
                      class="p-button-sm p-button-rounded p-button-text"
                      (click)="scopeFilters[scope]=null; applyFilters()"></button>
            </div>
          </div>
          <div class="col-5 flex justify-content-end">
          </div>
        </div>
      </div>
    </div>
    <app-view-as-alert [viewAsItem]="viewAsItem" [viewAsChooser]="viewAsChooser"></app-view-as-alert>
    <div class="mt-2 shadow-1 bg-white p-3 pb-2">
      <app-team-candidates-table *ngIf="scope==='all'" [query]="query">
      </app-team-candidates-table>
      <app-talent-partners-table *ngIf="scope==='talentPartner'" [query]="query"
                                 (changeModeFilter)="scopeFilters[$event.scope] = $event.scopeFilter">
      </app-talent-partners-table>
    </div>
  `,
  styles: [`
    :host ::ng-deep .p-datatable .p-paginator-bottom {
      border: none;
    }
  `]
})
export class TeamCandidatesPageComponent implements OnInit {
  @ViewChild(TeamCandidatesTableComponent) teamCandidateTable!: TeamCandidatesTableComponent;
  @ViewChild(SyncCandidatesDialog) syncCandidatesDialog!: SyncCandidatesDialog;
  menuItems = TEAM_CANDIDATE_MENU;
  totalTeamCandidates = 0;

  scope: TTeamCandidateScope = 'all';

  actionItems: Array<MenuItem> = [
    {label: 'Sync Candidates', command: () => this.syncCandidatesDialog.show()}
  ];

  schedule: string = 'project';
  period: IPeriod = getCurrentPeriodValue();
  viewAsItem: IViewAsItem | null = null;

  query: TQueryExpression | null = null;

  scopeFilters: {[scope in TTeamCandidateScope]?: IScopeFilter | null} = {
    all: null,
  };

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private resource: AppResourceService,
              public appConfig: AppConfigService) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const scope = params.get('scope');
      if (!!!scope || !TEAM_CANDIDATE_SCOPES.includes(scope as any)) {
        this.router.navigate([],
          {relativeTo: this.activatedRoute, queryParams: {scope: 'all'}, queryParamsHandling: 'merge'})
      } else {
        this.scope = scope as TTeamCandidateScope;
      }
      const scopeFilter = params.get('scopeFilter');
      const id = params.get('id');
      if (!!scopeFilter && ['talentPartnerId'].includes(scopeFilter) && !!id) {
        this.scopeFilters[this.scope] = {id, name: params.get('name') || '?', field: scopeFilter};
        this.router.navigate([],{relativeTo: this.activatedRoute, queryParams: {scope: this.scope}})
      }

      const isNew = params.get('new') === 'true';
      if (this.scope === 'all' && isNew) {
        this.router.navigate([],{relativeTo: this.activatedRoute, queryParams: {scope: this.scope}});
        setTimeout(() => this.newEntity());
      }

      this.applyFilters();
    });
    this.viewAsItem = this.appConfig.viewAsMe;
  }

  ngOnInit(): void {
    this.fetchCount();
  }

  onChangeViewAs(): void {
    // reload table
  }


  fetchCount(): void {
    this.resource.getTeamCandidateCount({
      query: this.query || undefined
    }).subscribe((count) => this.totalTeamCandidates = count);
  }

  applyFilters(): void {
    if (!!this.scopeFilters[this.scope]) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: []
      }
      if (!!this.scopeFilters[this.scope]) {
        query.predicates.push({
          field: this.scopeFilters[this.scope]!.field,
          operator: 'equals',
          value: this.scopeFilters[this.scope]!.id
        });
      }
      this.query = query;
    } else {
      this.query = null;
    }
    this.fetchCount();
  }


  newEntity(): void {
    if (this.scope === 'all') {
      this.teamCandidateTable.openCreateOrUpdateDialog(null)
    } else {
      this.router.navigate(['team-candidates'], {queryParams: {scope: 'all', new: 'true'}});
    }
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }
}
