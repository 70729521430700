import { Component } from '@angular/core';
import { PageableTableComponent } from '../../common/components/pageable-table.component';
import { ISearchRequest, ISearchResponse } from '../../api/shared/search-api';
import { AppResourceService } from '../../app.resource.service';
import { Router } from '@angular/router';
import { TableQuery } from '../../common/util/table-query';
import { Observable } from 'rxjs';
import { ITeamCandidate, TEAM_CANDIDATE_API_ENDPOINTS } from '../../api/shared/app-domain/team-candidate';
import { faEarth } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin, faSkype } from '@fortawesome/free-brands-svg-icons';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';
import { ColumnsInfoService } from '../common/column-header.component';
import { IIconLinkOption } from '../common/table-cell.component';
import {IMenuItem} from '../../common/components/table-base.component';

@Component({
  selector: 'app-team-candidates-table',
  template: `
    <app-table-toolbar [tableBase]="this"></app-table-toolbar>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <app-entity-edit-dialog #editDialog (apply)="onCreatedOrUpdated($event)">
      <ng-template pTemplate let-param>
        <app-team-candidate-editor [param]="param"></app-team-candidate-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-edit-dialog #bulkEditDialog (apply)="onBulkUpdated($event)">
      <ng-template pTemplate let-param>
        <app-team-candidate-bulkeditor [param]="param"></app-team-candidate-bulkeditor>
      </ng-template>
    </app-entity-edit-dialog>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Team Candidates"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               scrollDirection="horizontal"
               [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="name" filterType="optionsIn" label="Team Candidate"
                               frozen="lastLeft" [fixedWidth]="300"
                               [options]="$any(propertyOptions.getOptions('teamCandidateFullNames') | async)"
                               optionValue="name"
                               [optionsVirtualScroll]="true"></app-column-header>
            <app-column-header filterType="optionsIn" field="roleName"  label="Role"
                               [fixedWidth]="300"
                               [options]="$any(propertyOptions.getOptions('roles') | async)"
                               optionValue="name">
            </app-column-header>
            <app-column-header field="status" filterType="optionsEquals"
                               [fixedWidth]="120"
                               [options]="propertyOptions.teamCandidateStatuses">
            </app-column-header>
            <app-column-header field="hubspotCandidate" filterType="optionsIn" label="HubSpot Candidate"
                               filterField="hubspotCandidateId"
                               [fixedWidth]="200"
                               [options]="$any(propertyOptions.getOptions('hubspotCandidates') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header field="pipeline" filterType="text"
                               [fixedWidth]="150">
            </app-column-header>
            <app-column-header field="stage" filterType="optionsEquals"
                               [fixedWidth]="200"
                               [options]="propertyOptions.stages">
            </app-column-header>
            <app-column-header field="hubspotContact" filterType="optionsIn" label="HubSpot Contact"
                               filterField="hubspotContactId"
                               [fixedWidth]="200"
                               [options]="$any(propertyOptions.getOptions('hubspotContacts') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header filterType="optionsIn" field="departmentName"  label="Department"
                               [fixedWidth]="300"
                               [options]="$any(propertyOptions.getOptions('departments') | async)"
                               optionValue="name">
            </app-column-header>
            <app-column-header field="skills" filterType="optionsArray"
                               [fixedWidth]="350"
                               [options]="$any(propertyOptions.getOptions('skillNames') | async)"
                               optionValue="name"
                               optionsSelectedLabel="{0} Skills selected"
                               [optionsVirtualScroll]="true">
            </app-column-header>
            <app-column-header field="availability" filterType="optionsEquals" field="availability"
                               [fixedWidth]="150"
                               [options]="propertyOptions.availabilities">
            </app-column-header>
            <app-column-header field="costRateDefault" filterType="numeric" label="Default Cost Rate"
                               [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="country" filterType="optionsIn"
                               [fixedWidth]="150"
                               optionValue="name" optionLabel="name"
                               optionIcon="flag"
                               [options]="$any(propertyOptions.getOptions('countries') | async) | options">
            </app-column-header>
            <app-column-header field="state" filterType="optionsIn"
                               [fixedWidth]="150"
                               [options]="propertyOptions.usStates | options">
            </app-column-header>
            <app-column-header field="city" filterType="optionsIn"
                               [fixedWidth]="150"
                               [options]="$any(propertyOptions.getOptions('teamCandidateCities') | async)"
                               [optionsVirtualScroll]="true"
                               optionValue="city">
            </app-column-header>
            <app-column-header field="searchSource" filterType="optionsIn"
                               [fixedWidth]="170"
                               [options]="$any(propertyOptions.getOptions('searchSources') | async)"
                               optionValue="name">
            </app-column-header>
            <app-column-header field="primarySourceUrl" filterType="text" label="Search Source URL"
                               [fixedWidth]="170">
            </app-column-header>
            <app-column-header field="searchSourceKeyword" filterType="text"
                               [fixedWidth]="250">
            </app-column-header>
            <app-column-header field="timezone" filterType="optionsIn"
                               [fixedWidth]="150"
                               [options]="$any(propertyOptions.getOptions('timezones') | async) | options">
            </app-column-header>
            <app-column-header field="referralName" filterType="optionsIn" label="Referral"
                               [fixedWidth]="170"
                               [options]="$any(propertyOptions.getOptions('referrals') | async)"
                               optionValue="name">
            </app-column-header>
            <app-column-header field="talentPartnerName" filterType="optionsIn" label="Talent Partner"
                               filterField="talentPartnerId"
                               [fixedWidth]="200"
                               [options]="$any(propertyOptions.getOptions('talentPartners') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header field="phone" filterType="text" [fixedWidth]="150">
            </app-column-header>
            <app-column-header field="email" filterType="text" [fixedWidth]="250">
            </app-column-header>
            <app-column-header field="birthday" filterType="date" [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="createdAt" filterType="date" [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="updatedAt" filterType="date" [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="usefulLinks" [sortable]="false" label="Links" frozen="firstRight"
                               [fixedWidth]="links.length * 20">
            </app-column-header>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>

            <app-table-cell field="name" type="custom" frozen="lastLeft" [rowData]="rowData"
                            tdStyleClass="py-0" styleClass="justify-content-between">
              <div class="flex align-items-center overflow-x-hidden">
                <app-avatar [(ngModel)]="rowData.avatar" [name]="rowData.name" [editable]="false"></app-avatar>
                <div class="ml-2 mt-overflow-ellipsis" appTooltipOnOverflow>
                  <a class="text-primary no-underline"
                     [routerLink]="['/team-candidates/edit', rowData.id]">{{rowData.name}}</a>
                </div>
              </div>
              <app-row-menu-button [tableBase]="this" [rowData]="rowData" [customMenuItems]="customRowMenuItems"></app-row-menu-button>
            </app-table-cell>
            <app-table-cell field="roleName" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="hubspotCandidate" type="externalLink" linkUrlField="hubspotCandidateUrl"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="pipeline" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="stage" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="hubspotContact" type="externalLink" linkUrlField="hubspotContactUrl"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="departmentName" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="skills" type="stringArray" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="availability" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="costRateDefault" align="right"
                            [value]="rowData.costRateDefault | currency:'USD':'symbol':'1.2-2'"></app-table-cell>
            <app-table-cell field="country" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="state" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="city" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="searchSource" type="externalLink" linkUrlField="searchSourceUrl"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="primarySourceUrl" type="externalLink" linkUrlField="primarySourceUrl"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="searchSourceKeyword" type="stringArray" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="timezone" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="referralName" type="internalLink"
                            [routerLinkOptions]="{
                                link: ['/tbd', 'referral', rowData.referralId]
                            }"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="talentPartnerName" type="internalLink"
                            [filterLinkOptions]="{
                                link: ['/team-candidates'],
                                queryParams: {scope: 'all', scopeFilter: 'talentPartnerId', id: rowData.talentPartnerId, name: rowData.talentPartnerName}
                            }"
                            [routerLinkOptions]="{
                                link: ['/tbd', 'talent-partner', rowData.talentPartnerId]
                            }"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="phone" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="email" type="externalLink" [href]="'mailto:' + rowData.email"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="birthday"
                            [value]="rowData.birthday | date: 'MM/dd/yyyy'"></app-table-cell>
            <app-table-cell field="createdAt"
                            [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="updatedAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="usefulLinks" type="iconLinks" [iconLinksOptions]="links" frozen="firstRight"
                            [rowData]="rowData"></app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService
  ]
})
export class TeamCandidatesTableComponent extends PageableTableComponent<ITeamCandidate> {

  constructor(private resource: AppResourceService,
              private router: Router,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.entityName = 'Team Candidates';
    this.api = {
      archiveEntities: this.resource.patchTeamCandidates.bind(this.resource),
      deleteEntities: this.resource.deleteTeamCandidates.bind(this.resource),
      exportEntities: this.resource.exportTeamCandidates.bind(this.resource),
      importUrl: TEAM_CANDIDATE_API_ENDPOINTS.import
    };
  }

  links: Array<IIconLinkOption> = [
    {field: 'usefulLinks.linkedIn', icon: faLinkedin, tooltip: 'Linked In'},
    {field: 'usefulLinks.github', icon: faGithub, tooltip: 'GitHub'},
    {field: 'usefulLinks.website', icon: faEarth, tooltip: 'Web Site'},
    {field: 'usefulLinks.skype', icon: faSkype, protocol: 'skype', tooltip: 'Skype'},
  ];

  customRowMenuItems: Array<IMenuItem> = [
    {
      id: 'transform',
      label: 'Transform into Team Member',
      icon: 'pi pi-arrow-right',
      command: ({item}: { item: IMenuItem }) => {
        this.router.navigate(['/team'], {queryParams: {transform: item.rowData.id}});
      }
    }
  ];
  protected override fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<ITeamCandidate>> {
    return this.resource.getTeamCandidates(searchRequest);
  }

  override afterCrudPerformed() {
    this.propertyOptions.reset('teamCandidateFullNames', 'teamCandidateCities');
  }
}
