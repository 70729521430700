import {Directive, EventEmitter, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[lifeCycleHook]',
})
export class LifeCycleHookDirective implements OnInit {
  @Output() hook: EventEmitter<'onInit'> = new EventEmitter<'onInit'>()

  ngOnInit(): void {
    this.hook.emit('onInit');
  }

}
