import {AfterViewInit, Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IFileUploadDialogOptions} from './common/components/file';
import {FILE_STORAGE_API_ENDPOINTS, IAttachment} from './api/shared/app-domain/common';
import {downloadAttachment} from './common/util/util';
import {BoolExprParser} from './common/util/bool-expr-parser';

@Component({
  selector: 'app-test',
  template: `
      <div style="height: 400px; width: fit-content; background-color: #FFF" class="flex border-1 p-3">
          <div style="width: 300px">
              Test
          </div>
          <div style="background-color: yellow;">
              <app-collapsible-panel [isExpanded]="false" [width]="'500px'" expandTooltip="Expand Panel">
                  <div class="flex" style="height: 100%; width: 500px">
                      <div class="p-2 flex-1">
                          Expand to right
                      </div>
                      <div style="background-color: cyan; ; padding-right: 0px">
                          <app-collapsible-panel [isExpanded]="true" [width]="'200px'">
                              <div class="p-2" style="background-color: #fff">
                                  Expand to right 2
                              </div>
                          </app-collapsible-panel>
                      </div>
                  </div>
              </app-collapsible-panel>
          </div>
      </div>
  `,
  styles: [`
  `]
})
export class TestComponent implements OnInit, AfterViewInit {
  constructor(private http: HttpClient) {

  }
  ngOnInit(): void {
     const expr = '(PERM_1 & (PERM_2 | PERM_3))';
     console.log(BoolExprParser.eval(expr, ['PERM_1', 'PERM_3'])); // true
     console.log(BoolExprParser.eval(expr, (t) => ['PERM_2', 'PERM_4'].indexOf(t) !== -1)); // false
  }

  ngAfterViewInit(): void {
  }
}



