import { Component, Input } from '@angular/core';
import { AppResourceService } from '../../../../app.resource.service';
import { IIdentified, IPeriod } from '../../../../api/shared/app-domain/common';

@Component({
  selector: 'app-single-project-details',
  template: `
    <app-details-table [period]="period" [fetch]="fetch">
      <ng-template pTemplate="entryEntityTpl" let-rowData>
        <app-team-member-entry-entity [entryEntity]="rowData.entryEntity"></app-team-member-entry-entity>
      </ng-template>
    </app-details-table>
  `
})
export class SingleProjectDetailsComponent {
  @Input() period!: IPeriod;
  @Input() entity!: IIdentified;

  fetch = () => this.resource.getSingleProjectDetails(this.entity.id, {
    period: this.period
  });

  constructor(public resource: AppResourceService) {
  }

}
