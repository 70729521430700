import { Component, Input } from '@angular/core';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';

@Component({
  selector: 'app-skills-form-control',
  template: `
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'name'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'status'" [controlName]="name" controlType="dropdown"
                        [options]="propertyOptions.skillsStatuses"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'silo'" [controlName]="name" controlType="dropdown" [dropdownEditable]="true"
                        optionValue="silo"
                        [options]="$any(propertyOptions.getOptions('skillSilos') | async)"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'adjective'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'singular'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'plural'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'team'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'teams'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'siloMember'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'siloMembers'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'urlSlug'" label="URL Slug" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'metaTitle'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'metaDescriptions'" [controlName]="name" dataType="text"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'isOkToPublish'" [controlName]="name" label="Ok to Publish" dataType="boolean"></app-form-control-wrapper>
    </ng-container>
  `
})
export class SkillsFormControlComponent {
  @Input() name!: string;

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }

}

