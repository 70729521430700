import {Component, ExistingProvider, forwardRef, OnInit} from '@angular/core';
import {AbstractControlValueAccessorComponent} from '../../common/components/abstract-control-value-accessor.component';
import {IViewAsItem} from '../../api/shared/user-api';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppConfigService} from '../../app-config.service';
import {accessorProvider} from '../../common/util/util';


@Component({
  selector: 'app-view-as-chooser',
  template: `
    <ng-template #userItem let-item let-selected="selected">
      <div class="flex align-items-center ml-2">
        <app-avatar *ngIf="item && item?.type === 'user'" [(ngModel)]="item.avatar" [name]="item.name" [editable]="false"
                    [size]="selected ? '16px' : '24px'"></app-avatar>

        <div class="flex align-items-baseline">
          <div class="ml-2">{{item?.name}}</div>
          <div class="ml-1 text-xs text-gray-600">{{item?.from}}</div>
        </div>
      </div>
    </ng-template>
    <span #dropdown class="p-float-label">
        <p-dropdown [(ngModel)]="value"
                    [options]="viewAsOptions"
                    [group]="true"
                    optionLabel="name"
                    optionGroupLabel="name"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    filterBy="name"
                    [style]="{width: '300px'}"
                    (ngModelChange)="onChangeViewAs()">
          <ng-template let-group pTemplate="group">
           <div class="flex align-items-center">
            <img [src]="'assets/images/' + (group.name === 'Roles' ? 'roles.svg' : 'users.svg')" width="20"/>
            <span class="ml-1">{{group.name}}</span>
          </div>
          </ng-template>
          <ng-template pTemplate="selectedItem">
            <ng-container
              *ngTemplateOutlet="userItem; context: {$implicit: value, selected: true}"></ng-container>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <ng-container
              *ngTemplateOutlet="userItem; context: {$implicit: item, selected: false}"></ng-container>
          </ng-template>
        </p-dropdown>
        <label>View As</label>
     </span>
  `,
  providers: [accessorProvider(ViewAsChooserComponent)]
})
export class ViewAsChooserComponent extends AbstractControlValueAccessorComponent<IViewAsItem> implements OnInit {
  viewAsOptions: Array<any> = [
    {name: 'Roles', items: []},
    {name: 'Users', items: []},
  ];

  constructor(public appConfig: AppConfigService) {
    super();
  }

  ngOnInit(): void {
    this.viewAsOptions[0].items = this.appConfig.viewAs?.roles ?? [];
    this.viewAsOptions[1].items = this.appConfig.viewAs?.users ?? [];
  }

  onChangeViewAs(): void {
    this.onModelChange(this.value);
  }

  resumeViewingAsYourself(): void {
    this.value = this.appConfig.viewAsMe;
    this.onChangeViewAs();
  }

}
