import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'addKey'
})
export class AddKeyPipe implements PipeTransform {
  transform(collection: Array<any>, idField: string, keyField = 'key'): Array<any> {
    if (!collection) {
      return [];
    }
    return collection.map((e) => {
      e[keyField] = e[idField]
      return e;
    });
  }
}

