import {Component, Input, OnInit} from '@angular/core';
import {
  isInstanceOfTeamMemberEmbedded,
  ITeamMemberEmbedded,
  TTeamMemberEntryEntity
} from '../../../api/shared/app-domain/team';
import {isInstanceOfProjectEmbedded, TProjectEntryEntity} from '../../../api/shared/app-domain/project';
import {Params} from '@angular/router';

@Component({
  selector: 'app-team-member-entry-entity',
  template: `
    <app-avatar [(ngModel)]="data.avatar" [name]="data.name" [editable]="false"></app-avatar>
    <div class="flex flex-column ml-1" [style]="{width}">
      <div class="mt-overflow-ellipsis text" appTooltipOnOverflow>
        <a *ngIf="routerLink != null; else simple" class="text-primary no-underline"
           [routerLink]="[routerLink, data.id]" [queryParams]="queryParams">
          {{data.name || ''}}
        </a>
        <ng-template #simple>
          {{data.name || ''}}
        </ng-template>
      </div>

      <div class="mt-overflow-ellipsis text-sm text-gray-600"
           appTooltipOnOverflow>{{data.roleName}}</div>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      align-items: center;
    }
  `]
})
export class TeamMemberEntryEntityComponent implements OnInit {
  @Input() entryEntity!: TTeamMemberEntryEntity | ITeamMemberEmbedded;
  @Input() routerLink?: string;
  @Input() queryParams: Params | null = null;

  @Input() width = '120px';
  data!: TTeamMemberEntryEntity;

  ngOnInit() {
    if (isInstanceOfTeamMemberEmbedded(this.entryEntity)) {
      this.data = {
        id: this.entryEntity.teamMemberId,
        name: this.entryEntity.teamMemberName,
        roleName: this.entryEntity.teamMemberRoleName,
        avatar: this.entryEntity.teamMemberAvatar
      } as TTeamMemberEntryEntity;
    } else {
      this.data = this.entryEntity;
    }
  }
}
