import { Component, forwardRef } from '@angular/core';
import { AppResourceService } from '../../app.resource.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { EntityEditorComponent } from '../common/entity-editor.component';
import { existsAsyncValidator, plural } from '../../common/util/util';
import { SubscriptionsService } from '../../common/services/subscriptions.service';
import { EntityEditorBaseComponent } from '../common/entity-editor-base.component';
import { ConfirmationService } from 'primeng/api';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';
import {ISkill, TSkillSave} from '../../api/shared/app-domain/skill';
import {IDictionary, TControlsOf} from '../../common/types';
import { kebabCase } from 'lodash';

const AUTO_FILLS: IDictionary<string> = {
  'Design': 'Designer',
  'Development': 'Developer',
  'Marketing': 'Marketer',
  'Production': 'Producer',
  'Strategy': 'Expert',
  'Technology': 'Engineer'
};

@Component({
  selector: 'app-skills-editor',
  template: `
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container>
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-4 p-fluid">
            <app-skills-form-control name="name"></app-skills-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-skills-form-control name="status"></app-skills-form-control>
          </div>
          <div class="field col-3 p-fluid">
            <app-skills-form-control name="silo"></app-skills-form-control>
          </div>
          <div class="field col-1 p-fluid" [style.padding-top]="'1.8rem'">
            <p-button label="Auto Fill" [disabled]="!canAutoFill()" (click)="autoFill()"></p-button>
          </div>

          <div class="field col-4 p-fluid">
            <app-skills-form-control name="adjective"></app-skills-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-skills-form-control name="singular"></app-skills-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-skills-form-control name="plural"></app-skills-form-control>
          </div>

          <div class="field col-6 p-fluid">
            <app-skills-form-control name="team"></app-skills-form-control>
          </div>
          <div class="field col-6 p-fluid">
            <app-skills-form-control name="teams"></app-skills-form-control>
          </div>

          <div class="field col-6 p-fluid">
            <app-skills-form-control name="siloMember"></app-skills-form-control>
          </div>
          <div class="field col-6 p-fluid">
            <app-skills-form-control name="siloMembers"></app-skills-form-control>
          </div>

          <div class="field col-6 p-fluid">
            <app-skills-form-control name="urlSlug"></app-skills-form-control>
          </div>
          <div class="field col-6 p-fluid pt-5">
            <app-skills-form-control name="isOkToPublish"></app-skills-form-control>
          </div>


          <div class="field col-12 p-fluid">
            <app-skills-form-control name="metaTitle"></app-skills-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-skills-form-control name="metaDescriptions"></app-skills-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => SkillsEditorComponent)},
    SubscriptionsService
  ]

})
export class SkillsEditorComponent extends EntityEditorComponent<TSkillSave, ISkill> {

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              fb: FormBuilder,
              private confirmationService: ConfirmationService,
              private propertyOptions: EntityPropertyOptionsService) {
    super(fb);
    this.entityName = 'Skill';
    this.api = {
      getEntity: this.resource.getSkill.bind(this.resource),
      createEntity: this.resource.createSkill.bind(this.resource),
      updateEntity: this.resource.updateSkill.bind(this.resource),
    }
  }

  override getName(): string | null {
    return this.entity.name ?? '';
  }

  override buildForm(): void {
    const checkExistsSkill = this.getCheckExists(this.resource.searchSkills.bind(this.resource), 'name');
    this.form = this.fb.group<TControlsOf<TSkillSave>>({
      name: new FormControl(this.entity.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsSkill)]
      }),
      status: new FormControl(this.isNew() ? 'Active' : this.entity.status, [Validators.required]),
      silo: new FormControl(this.entity.silo, [Validators.required]),
      adjective: new FormControl(this.entity.adjective, [Validators.required]),

      singular: new FormControl(this.entity.singular, [Validators.required]),
      plural: new FormControl(this.entity.plural, [Validators.required]),
      team: new FormControl(this.entity.team, [Validators.required]),
      teams: new FormControl(this.entity.teams, [Validators.required]),
      siloMember: new FormControl(this.entity.siloMember, [Validators.required]),
      siloMembers: new FormControl(this.entity.siloMembers, [Validators.required]),
      urlSlug: new FormControl(this.entity.urlSlug, [Validators.required]),
      metaTitle: new FormControl(this.entity.metaTitle, []),
      metaDescriptions: new FormControl(this.entity.metaDescriptions, []),
      isOkToPublish: new FormControl(this.isNew() ? true : this.entity.isOkToPublish, [Validators.required]),
    });
  }


  canAutoFill(): boolean {
    return this.getValue('name') != null && this.getValue('silo') != null && this.getValue('silo') !== 'TBD';
  }

  autoFill(): void {
    if (this.canAutoFill()) {
      let name: string = this.getValue('name');
      const nameParts = name.split(' ');
      const silo = this.getValue('silo');
      const autoFillToken = AUTO_FILLS[silo] || '???';
      const autoFillTokenPlural = plural(autoFillToken);
      if ([autoFillToken.toLowerCase(), silo.toLowerCase()].includes(nameParts[nameParts.length - 1].toLowerCase())) {
        nameParts.pop();
        name = nameParts.join(' ');
      }
      this.setValueAndMark('adjective', `Fluid team of ${autoFillTokenPlural.toLowerCase()}`);
      this.setValueAndMark('singular',`${name} ${autoFillToken}`);
      this.setValueAndMark('plural', `${name} ${autoFillTokenPlural}`);
      this.setValueAndMark('siloMember', autoFillToken);
      this.setValueAndMark('siloMembers', autoFillTokenPlural);
      this.setValueAndMark('urlSlug', kebabCase(`${name} ${autoFillTokenPlural}`));
      this.setValueAndMark('team', `${name} ${silo} Team`);
      this.setValueAndMark('teams', `${name} ${silo} Teams`);
    }
  }
}
