import {
  AfterContentInit, ApplicationRef,
  Component, ComponentFactoryResolver, ComponentRef,
  ContentChildren,
  Directive, EmbeddedViewRef,
  HostListener, Injector,
  Input, OnDestroy, OnInit,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import {PrimeTemplate} from 'primeng/api';

@Component({
  selector: 'app-tip',
  template: `
    <div class="mt-tip-panel" [class.mt-tip-box]="boxed" [ngClass]="styleClass">
      <span class="mt-tip mt-text-small">
        <img src="assets/images/tip.png" width="16" />
        Tip:
      </span>
      <span *ngIf="text" class="mt-text-color mt-text-small">{{text}}</span>
      <span class="mt-text-color mt-text-small" *ngIf="customTip">
        <ng-container *ngTemplateOutlet="customTip"></ng-container>
      </span>
    </div>
  `,
  styles: [`
    .p-fluid .mt-tip-panel {
      width: 100%;
    }
    .mt-tip-panel {
      display: flex;
      align-items: center;
    }

    .mt-tip-box {
      padding: .5em;
      background-color: #FFFFCC;
      border: 2px solid #FFAB00;
    }

    .mt-tip {
      width: 3.750em;
      display: flex;
      align-items: center;
      margin-right: .5rem;
    }

    .mt-text-small {
      font-size: .750em;
      line-height: 1.313em;
    }

    .mt-text-color {
      color: var(--gray-600);
    }

  `]
})
export class TipComponent implements AfterContentInit {
  @Input() boxed = false;
  @Input() text!: string;
  @Input() styleClass!: string;
  customTip!: TemplateRef<any>;
  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (!tpl.getType() || tpl.getType() === 'customTip') {
        this.customTip = tpl.template;
      }
    });
  }
}


@Component({
  selector: 'app-hint',
  template: `
        <p-overlayPanel #overlay styleClass="" appendTo="body">
            <span class="text-sm">{{text}}</span>
        </p-overlayPanel>
    `
})
export class HintComponent {
  @Input() text!: string;
  @ViewChild(OverlayPanel) overlay!: OverlayPanel;

  show(event: any) {
    this.overlay.show(event);
  }

  hide() {
    this.overlay.hide();
  }
}

@Directive({
  selector: '[hint]'
})
export class HintDirective implements OnInit, OnDestroy {
  private componentRef!: ComponentRef<HintComponent>;
  private text!: string;

  @Input() set hint(text: string) {
    this.text = text;
  }

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) {
  }

  ngOnInit(): void {
    this.componentRef = this.componentFactoryResolver
      .resolveComponentFactory(HintComponent)
      .create(this.injector);
    this.componentRef.instance.text = this.text;
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = <HTMLElement>(<EmbeddedViewRef<any>>this.componentRef.hostView).rootNodes[0];
    document.body.appendChild(domElem);
  }

  ngOnDestroy(): void {
    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter($event: MouseEvent) {
    this.componentRef.instance.show($event);
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave($event: MouseEvent) {
    this.componentRef.instance.hide();
  }

}
