import { MenuItem } from 'primeng/api';
import { TCompanyStatus, TCompanyType } from '../../api/shared/app-domain/company';
import {TContactType} from '../../api/shared/app-domain/contact';

export const MAIN_MENU: Array<MenuItem> = [
  {
    label: 'Time & Expenses',
    items: [
      {
        label: 'Time',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Time Sheets',
        routerLink: ['/time-sheets'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Time Entries',
        routerLink: ['/time-entries'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Time Approvals',
        routerLink: ['/time-approvals'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Expenses',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Expenses',
        routerLink: ['/expenses'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Expense Reports',
        routerLink: ['/expense-reports'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Expense Approvals',
        routerLink: ['/expense-approvals'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Schedule',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Schedule',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Workweek Settings',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Holiday Calendar',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Time Off',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Time Off Requests',
        routerLink: ['/time-off-requests'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Annual Log',
        routerLink: ['/annual-log'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Time Off Approvals',
        routerLink: ['/time-off-approvals'],
        styleClass: 'mt-main-menu-indented-item'
      }
    ]
  },
  {
    label: 'Team',
    items: [
      {
        label: 'Team',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Team',
        routerLink: ['/team'],
        queryParams: {
          scope: 'all',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Talent Partner',
        routerLink: ['/team'],
        queryParams: {
          scope: 'talentPartner',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Candidates',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Candidates',
        routerLink: ['/team-candidates'],
        queryParams: {
          scope: 'all'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Talent Partner',
        routerLink: ['/team-candidates'],
        queryParams: {
          scope: 'talentPartner'
        },
        styleClass: 'mt-main-menu-indented-item'
      }
    ]
  },
  // {
  //   label: 'Assignments',
  //   items: [
  //   ]
  // },
  {
    label: 'Projects',
    items: [
      {
        label: 'Projects',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Projects',
        routerLink: ['/projects'],
        queryParams: {
          scope: 'all',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Client',
        routerLink: ['/projects'],
        queryParams: {
          scope: 'client',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Agency',
        routerLink: ['/projects'],
        queryParams: {
          scope: 'agency',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Pending',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Projects Pending',
        routerLink: ['/projects'],
        styleClass: 'mt-main-menu-indented-item',
        queryParams: {
          mode: 'all',
          status: 'Pending'
        }
      },
      {
        label: 'Quoted',
        routerLink: ['/projects'],
        styleClass: 'mt-main-menu-indented-item',
        queryParams: {
          mode: 'all',
          status: 'Quoted'
        }
      },
      {
        separator: true
      },
      {
        label: 'Archived',
        routerLink: ['/projects'],
        queryParams: {
          mode: 'all',
          status: 'Archived'
        }
      }
    ]
  },
  {
    label: 'Billing',
    items: [
      {
        label: 'Billing',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Invoices',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Payments',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Preferences',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Payments',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Invoices',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Fixed Payroll',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Commissions',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Payments',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Preferences',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      }
    ]
  },
  {
    label: 'Companies',
    items: [
      {
        label: 'All Companies',
        routerLink: ['/companies'],
        queryParams: {
          colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Clients',
        routerLink: ['/companies'],
        queryParams: {
          colFilter_types: 'Client' as TCompanyType,
          colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Agency Resellers',
        routerLink: ['/companies'],
        queryParams: {
          colFilter_types: 'Agency Reseller' as TCompanyType,
          colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Talent Partners',
        routerLink: ['/companies'],
        queryParams: {
          colFilter_types: 'Talent Partner' as TCompanyType,
          colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Sales Rep Companies',
        routerLink: ['/companies'],
        queryParams: {
          colFilter_types: 'Sales Rep' as TCompanyType,
          colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Referral Partners',
        routerLink: ['/companies'],
        queryParams: {
          colFilter_types: 'Referral Partner' as TCompanyType,
          colFilter_status: 'Active' as TCompanyStatus
        }
      }
    ]
  },
  {
    label: 'Contacts',
    items: [
      {
        label: 'All Contacts',
        routerLink: ['/contacts']
      },
      {
        label: 'Team Members',
        routerLink: ['/contacts'],
        queryParams: {
          colFilter_types: 'Team Member' as TContactType,
        }
      },
      {
        label: 'Team Candidates',
        routerLink: ['/contacts'],
        queryParams: {
          colFilter_types: 'Team Candidate' as TContactType,
        }
      },
      {
        label: 'Sales Reps',
        routerLink: ['/contacts'],
        queryParams: {
          colFilter_types: 'Sales Rep' as TContactType,
        }
      },
      {
        label: 'Affiliates',
        routerLink: ['/contacts'],
        queryParams: {
          colFilter_types: 'Affiliate' as TContactType,
        }
      },
      {
        label: 'Clients',
        routerLink: ['/contacts'],
        queryParams: {
          colFilter_types: 'Client' as TContactType,
        }
      },
      {
        label: 'Agency Resellers',
        routerLink: ['/contacts'],
        queryParams: {
          colFilter_types: 'Agency Reseller' as TContactType,
        }
      },
      {
        label: 'Talent Partners',
        routerLink: ['/contacts'],
        queryParams: {
          colFilter_types: 'Talent Partner' as TContactType,
        }
      },
      {
        label: 'Referral Partners',
        routerLink: ['/contacts'],
        queryParams: {
          colFilter_types: 'Referral Partner' as TContactType,
        }
      }
    ]
  },
  {
    label: 'Users',
    items: [
      {
        label: 'All Users',
        routerLink: ['/tbd']
      }
    ]
  },
  {
    label: 'Referrals',
    items: [
      {
        label: 'Project Referrals',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Referrals',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Referral Partner',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Expected Commissions',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },

      {
        separator: true
      },
      {
        label: 'Sales Reps',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Sales Reps',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Sales Rep Company',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Expected Commissions',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },

      {
        separator: true
      },
      {
        label: 'Team Referrals',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Team Referrals',
        routerLink: ['/referrals'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Promo & Sales Collateral',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Share Online',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Campaign Assets',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      }
    ]
  },
  {
    label: 'Reports',
    items: [
      {
        label: 'Time',
        routerLink: ['/tbd']
      },
      {
        label: 'Transactions',
        routerLink: ['/tbd']
      }
    ]
  },
  {
    label: 'Development Tools',
    items: [
      {
        label: 'HubSpot',
        items: [
          {
            label: 'Hubspot Object Types',
            items: [
              {
                label: 'Associations Editor',
                routerLink: ['/hs-associations']
              },
              {
                label: 'Associations Diagram',
                routerLink: ['/hs-relationship-diagram']
              }
            ]
          },
          {
            label: 'Hubspot Object Browser',
            routerLink: ['/hs-object-browser']
          }
        ]
      }
    ]
  }
];

export const USER_MENU: Array<MenuItem> = [
  {
    id: 'manage-your-account',
    label: 'Manage your Account',
    icon: 'pi pi-fw pi-user',
    routerLink: '/tbd'
  },
  {
    id: 'settings',
    label: 'Settings',
    icon: 'pi pi-fw pi-cog',
    routerLink: '/tbd'
  },
  {
    id: 'referrals',
    label: 'Referrals',
    icon: 'pi pi-fw pi-users'
  },
  {
    id: 'sign-out',
    label: 'Sign Out',
    icon: 'pi pi-fw pi-sign-out'
  },
  {
    separator: true
  },
  {
    id: 'switch-workspace',
    label: 'Switch Workspace',
    icon: 'pi pi-fw pi-sort-alt'
  }
];

export const SETTINGS_MENU: Array<MenuItem> = [
  {
    label: 'Workspace Settings',
    routerLink: '/tbd'
  },
  {
    label: 'Company Settings',
    routerLink: '/tbd'
  },
  {
    separator: true
  },
  {
    label: 'Schedule',
    disabled: true,
    styleClass: 'mt-main-menu-header'
  },
  {
    label: 'Holiday Calendars',
    routerLink: ['settings/holiday-calendars'],
    styleClass: 'mt-main-menu-indented-item'
  },
  {
    label: 'Holidays',
    routerLink: ['settings/holidays'],
    styleClass: 'mt-main-menu-indented-item'
  },
  {
    label: 'Workweek',
    routerLink: ['settings/workweek'],
    styleClass: 'mt-main-menu-indented-item'
  },
  {
    label: 'Paid Time Off',
    routerLink: ['settings/paid-time-offs'],
    styleClass: 'mt-main-menu-indented-item'
  },
  {
    separator: true
  },
  {
    label: 'Departments',
    routerLink: '/settings/departments'
  },
  {
    label: 'Roles',
    routerLink: '/settings/roles'
  },
  {
    label: 'Skills',
    routerLink: '/settings/skills'
  },
  {
    label: 'System User Roles',
    routerLink: '/tbd'
  },
  {
    label: 'Expense Categories',
    routerLink: '/settings/expense-categories'
  },
  {
    label: 'Tax',
    routerLink: '/tbd'
  },
  {
    label: 'Terms & Conditions',
    routerLink: '/tbd'
  },
  {
    label: 'Quotes',
    routerLink: '/tbd'
  }

];

