import {Component, forwardRef, Input, SimpleChanges} from '@angular/core';
import {EntityFormControlBaseComponent} from '../../common/entity-form-control-base.component';
import {IExpense, TExpenseSave} from '../../../api/shared/app-domain/expenses';
import {EntityPropertyOptionsService} from '../../common/entity-property-options.service';
import {FormControlWrapperComponent} from '../../../common/components/form-control-wrapper.component';
import {ICompany} from '../../../api/shared/app-domain/company';
import {EntityEditorBaseComponent} from '../../common/entity-editor-base.component';
import {SubscriptionsService} from '../../../common/services/subscriptions.service';
import {EntityEditorComponent} from '../../common/entity-editor.component';
import {AppResourceService} from '../../../app.resource.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {TControlsOf} from '../../../common/types';
import {IAttachment, IIdentified} from '../../../api/shared/app-domain/common';

@Component({
  selector: 'app-expense-form-control',
  template: `
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'date'" dataType="date"
                                [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'amount'" [controlName]="name"
                                dataType="currency"></app-form-control-wrapper>
      <div *ngSwitchCase="'clientId'">
        <app-entity-edit-dialog #editDialog (apply)="onApplyNewRelatedEntity(ctrl, $event.data)">
          <ng-template pTemplate let-param>
            <app-company-editor [param]="param"></app-company-editor>
          </ng-template>
        </app-entity-edit-dialog>
        <app-form-control-wrapper #ctrl [controlName]="name" controlType="dropdown" label="Client"
                                  [options]="$any(propertyOptions.getOptions('clients') | async)"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [showAddButton]="true"
                                  (addButtonClick)="editDialog.show({id: null})"></app-form-control-wrapper>
      </div>
      <app-form-control-wrapper *ngSwitchCase="'projectId'" [controlName]="name" controlType="dropdown" label="Project"
                                [options]="$any(propertyOptions.getOptions('projects') | async)"
                                [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id">
      </app-form-control-wrapper>
      <div *ngSwitchCase="'categoryId'">
        <app-entity-edit-dialog #editDialog (apply)="onApplyNewRelatedEntity(ctrl, $event.data)">
          <ng-template pTemplate let-param>
            <app-expense-category-editor [param]="param"></app-expense-category-editor>
          </ng-template>
        </app-entity-edit-dialog>
        <app-form-control-wrapper #ctrl [controlName]="name" controlType="dropdown" label="Category"
                                  [options]="$any(propertyOptions.getOptions('expenseCategories') | async)"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [showAddButton]="true"
                                  (addButtonClick)="editDialog.show({id: null})"></app-form-control-wrapper>
      </div>
      <app-form-control-wrapper *ngSwitchCase="'isBillable'" [controlName]="name" label="Is Billable?"
                                dataType="boolean">
      </app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'isReimbursable'" [controlName]="name" label="Is Reimbursable?"
                                dataType="boolean">
      </app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'notes'" [controlName]="name"
                                dataType="text"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'attachments'" [controlName]="name" controlType="attachments"></app-form-control-wrapper>


    </ng-container>
  `
})
export class ExpenseFormControl extends EntityFormControlBaseComponent<IExpense> {
  constructor(propertyOptions: EntityPropertyOptionsService) {
    super(propertyOptions);
    this.relatedFields = {
      clientId: {relatedField: 'id', entityPropertyOption: 'clients'},
      categoryId: {relatedField: 'id', entityPropertyOption: 'expenseCategories'},
    }
  }

  override onApplyNewRelatedEntity(ctrl: FormControlWrapperComponent, relatedEntityData: any): void {
    if (ctrl.controlName === 'clientId') {
      if ((relatedEntityData as ICompany).types.includes('Client')) {
        this.propertyOptions.addOptionItem('clients', relatedEntityData);
        ctrl.setValueAndMark(relatedEntityData.id);
      }
      return;
    }
    super.onApplyNewRelatedEntity(ctrl, relatedEntityData);
  }
}

@Component({
  selector: 'app-expense-editor',
  template: `
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container>
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-6 p-fluid">
            <app-expense-form-control name="date"></app-expense-form-control>
          </div>
          <div class="field col-6 p-fluid">
            <app-expense-form-control name="amount"></app-expense-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-expense-form-control name="clientId"></app-expense-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-expense-form-control name="projectId"></app-expense-form-control>
            <div class="mt-1">
              <app-tip
                text="When choosing a project, the client to which it belongs is automatically selected"></app-tip>
            </div>
          </div>
          <div class="field col-12 p-fluid">
            <app-expense-form-control name="categoryId"></app-expense-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-expense-form-control name="notes"></app-expense-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-expense-form-control name="attachments"></app-expense-form-control>
          </div>
          <div class="field col-6 text-right p-fluid">
            <app-expense-form-control name="isBillable"></app-expense-form-control>
          </div>
          <div class="field col-6 p-fluid">
            <app-expense-form-control name="isReimbursable"></app-expense-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => ExpenseEditorComponent)},
    SubscriptionsService
  ]

})
export class ExpenseEditorComponent extends EntityEditorComponent<TExpenseSave, IExpense> {
  @Input() teamMember?: IIdentified;

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              fb: FormBuilder) {
    super(fb);
    this.entityName = 'Expense';

  }

  override ngOnChanges(changes: SimpleChanges): void {
    this.api = !!this.teamMember ? {
      getEntity: (id: string) => this.resource.getExpense(this.teamMember!.id, id),
      createEntity: (data: TExpenseSave) => this.resource.createExpense(this.teamMember!.id, data),
      updateEntity: (id: string, data: TExpenseSave) => this.resource.updateExpense(this.teamMember!.id, id, data),
    } : {
      getEntity: this.resource.getExpenseReport.bind(this.resource),
      updateEntity: this.resource.updateExpenseReport.bind(this.resource),
    }
    super.ngOnChanges(changes);
  }

  override getName(): string | null {
    return this.entity.date != null ? new DatePipe('en-US').transform(this.entity.date, 'EEE, MMM dd, y') : '';
  }

  override buildForm(): void {
    this.form = this.fb.group<TControlsOf<TExpenseSave>>({
      date: new FormControl(this.isNew() ? new Date() : this.entity.date, [Validators.required]) as any,
      amount: new FormControl(this.entity.amount, [Validators.required]),
      clientId: new FormControl(this.entity.clientId, [Validators.required]),
      projectId: new FormControl(this.entity.projectId, []),
      categoryId: new FormControl(this.entity.categoryId, [Validators.required]),
      isBillable: new FormControl(this.isNew() ? true : this.entity.isBillable, [Validators.required]),
      isReimbursable: new FormControl(this.isNew() ? true : this.entity.isReimbursable, [Validators.required]),
      notes: new FormControl(this.entity.notes, []),
      attachments: new FormControl(this.entity.attachments, [])
    });
    this.subscriptions
      .add(this.form!.controls.clientId.valueChanges.subscribe((val) => {
        this.processClient(val);
      })).add(this.form!.controls.projectId!.valueChanges.subscribe((val) => {
      this.processProject(val!);
    }))
  }

  processClient(clientId: string | null) {
    this.setValueAndMark('projectId', null);
  }

  processProject(projectId: string | null) {
    if (!!projectId) {
      this.resource.getProject(projectId).subscribe((project) => {
        this.form!.controls.clientId.setValue(project.clientId, {onlySelf: true, emitEvent: false});
      });
    }
  }
}



@Component({
  selector: 'app-attachments-cell',
  template: `
    <div *ngFor="let file of attachments || []" class="mt-attachment">
      <a class="mt-link" [href]="file.url" target="_blank">{{file.name}}</a>
      <span class="ml-2 text-xs">{{file.size | fileSize}}</span>
      <app-download-attachment class="ml-2" [attachment]="file"></app-download-attachment>
    </div>
  `,
  styles: [`
    :host {
      display: flex;
    }

    .mt-attachment {
      display: inline-flex;
      align-items: center;
      background-color: rgba(63, 81, 181, 0.12);
      font-size: 1rem;
      white-space: nowrap;
      border-radius: 20px;
      padding: 3px 9px;
      margin-top: 2px;
      margin-bottom: 2px;
      text-decoration: none;
    }

    .mt-attachment:not(:last-child) {
      margin-right: 5px;
    }
  `],
})
export class AttachmentsCellComponent {
  @Input() attachments?: Array<IAttachment>;
}


