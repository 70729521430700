import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuItem, SelectItem} from 'primeng/api';
import {getCurrentPeriodValue} from '../../common/components/period-chooser.component';
import {IViewAsItem} from '../../api/shared/user-api';
import {TQueryExpression} from '../../api/shared/search-api';
import {ActivatedRoute, Router} from '@angular/router';
import {AppResourceService} from '../../app.resource.service';
import {AppConfigService} from '../../app-config.service';
import {ServiceLocator} from '../../common/util/util';
import {TEAM_MEMBER_STATUS, TTeamMemberStatus} from '../../api/shared/app-domain/team';
import {TEAM_SCOPES, TEAM_TABS, TTeamScope, TTeamTab} from './team';
import {IPeriod} from '../../api/shared/app-domain/common';
import {TeamMembersTableComponent} from './team-members-table.component';
import {ITeamCandidate} from '../../api/shared/app-domain/team-candidate';
import {IScopeFilter} from '../common/common';
import {TProjectTab} from '../projects/projects';
import {TeamMembersFilterComponent} from './team-members-filter.component';
import {IResponseRequest} from '../../common/components/pageable-table.component';

const TEAM_TAB_MENU: Array<MenuItem> = [
  {
    label: 'Listing',
    routerLink: ['/team'],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'listing' as TTeamTab
    }
  },
  {
    label: 'Details',
    routerLink: ['/team'],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'details' as TTeamTab
    }
  },
  {
    label: 'Planning',
    routerLink: ['/team'],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'planning' as TTeamTab
    }
  }
];

@Component({
  selector: 'app-team-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="flex align-items-baseline">
              <div class="mt-page-title">Team</div>
              <div class="text-gray-700 ml-3">
                {{tmf.currentStatusesAsText}}
              </div>
            </div>
            <div class="text-gray-700 text-sm">{{totalTeamMembers}} team member{{totalTeamMembers ? 's' : ''}}</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <p-menubar class="mt-page-menu-bar" [model]="tabMenuItems"></p-menubar>
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
            <button pButton pRipple label="Actions"
                    class="p-button-outlined ml-4"
                    [style]="{width: '115px'}"
                    icon="pi pi-chevron-down"
                    iconPos="right" (click)="actionsMenu.toggle($event)"></button>
            <button pButton pRipple label="New Team Member"
                    class="ml-2"
                    icon="pi pi-plus" (click)="newEntity()"></button>
          </div>
        </div>
      </div>
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-7 flex align-items-center">
            <app-view-as-chooser #viewAsChooser [(ngModel)]="viewAsItem" (ngModelChange)="onChangeViewAs()">
            </app-view-as-chooser>
            <app-schedule-chooser [(ngModel)]="schedule" class="ml-2"></app-schedule-chooser>
            <span class="ml-2 p-float-label">
                <p-dropdown [ngModel]="scope" [options]="scopeItems"
                            (ngModelChange)="router.navigate([],
                        {relativeTo: activatedRoute, queryParams: {scope: $event}, queryParamsHandling: 'merge'})">
                </p-dropdown>
              <label>Scope</label>
            </span>
            <app-team-members-filter #tmf [statusFilter]="initialStatuses" (applyFilter)="applyFilters()"></app-team-members-filter>
            <div *ngIf="scopeFilters[scope]" class="flex align-items-center ml-4 ">
              <span>Showing for:</span>
              <span class="text-gray-600  font-semibold ml-1"> {{scopeFilters[scope]?.name}}</span>
              <button pButton pRipple type="button" icon="pi pi-times"
                      class="p-button-sm p-button-rounded p-button-text"
                      (click)="scopeFilters[scope]=null; applyFilters()"></button>
            </div>
          </div>
          <div class="col-5 flex justify-content-end">
            <app-period-chooser [(ngModel)]="period" (ngModelChange)="onChangePeriod()"></app-period-chooser>
          </div>
        </div>
      </div>
    </div>
    <app-view-as-alert [viewAsItem]="viewAsItem" [viewAsChooser]="viewAsChooser"></app-view-as-alert>
    <!--    <div class="mt-3 pb-2 border-bottom-1 border-300">-->
    <div class="mt-3">
      <div class="grid">
        <div class="col-3" *ngFor="let i of 4 | arrayOf">
          <div class="shadow-1"
               style="background: #FFF; border: 1px solid var(--surface-300); height: 10rem; font-size: 12px; padding: 2rem; color: var(--gray-500)">
            TBD
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 shadow-1 bg-white p-3 pb-2">
      <ng-container *ngIf="tab==='listing'">
        <app-team-members-table *ngIf="scope==='all'" [query]="query" (onDataFetched)="onDataFetched($event)">
        </app-team-members-table>
        <app-team-talent-partners-table *ngIf="scope==='talentPartner'" [query]="query"
                                        (changeModeFilter)="scopeFilters[$event.scope] = $event.scopeFilter">
        </app-team-talent-partners-table>
      </ng-container>
      <ng-container *ngIf="tab!=='listing'">
        <div class="pt-5 pb-5">TBD: {{tab}}</div>
      </ng-container>
    </div>
  `,
  styles: [`
    :host ::ng-deep .p-datatable .p-paginator-bottom {
      border: none;
    }
  `]
})
export class TeamPageComponent implements OnInit {
  @ViewChild(TeamMembersTableComponent) teamTable!: TeamMembersTableComponent;
  @ViewChild(TeamMembersFilterComponent) teamMemberFilter!: TeamMembersFilterComponent;
  scopeItems: Array<SelectItem> = [
    {label: 'All Team', value: 'all' as TTeamScope},
    {label: 'By Talent Partner', value: 'talentPartner' as TTeamScope},
  ];

  tabMenuItems = TEAM_TAB_MENU;
  tab: TProjectTab = 'listing';

  totalTeamMembers = 0;

  scope: TTeamScope = 'all';

  actionItems: Array<MenuItem> = [
    {label: 'Sync Accounting', command: this.tbd},
    {label: 'Sync Timekeeping', command: this.tbd}
  ];

  initialStatuses: Array<TTeamMemberStatus> = [];

  schedule: string = 'project';
  period: IPeriod = getCurrentPeriodValue();
  viewAsItem: IViewAsItem | null = null;

  showFilterDialog = false;

  query: TQueryExpression | null = null;

  scopeFilters: { [scope in TTeamScope]?: IScopeFilter | null } = {
    all: null,
  };


  constructor(public activatedRoute: ActivatedRoute,
              public router: Router,
              private resource: AppResourceService,
              public appConfig: AppConfigService) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      const scope: TTeamScope = params.get('scope') as TTeamScope;
      if (!!!scope || !TEAM_SCOPES.includes(scope)) {
        this.router.navigate([],
          {relativeTo: this.activatedRoute, queryParams: {scope: 'all'}, queryParamsHandling: 'merge'})
      } else {
        this.scope = scope;
      }

      const tab: TTeamTab = params.get('tab') as TTeamTab;
      if (!!!tab || !TEAM_TABS.includes(tab)) {
        this.router.navigate([],
          {relativeTo: this.activatedRoute, queryParams: {tab: 'listing'}, queryParamsHandling: 'merge'});
      } else {
        this.tab = tab;
      }

      const status: TTeamMemberStatus = params.get('status') as TTeamMemberStatus;
      if (!!status && TEAM_MEMBER_STATUS.includes(status)) {
        this.initialStatuses = [status];
        this.router.navigate([],
          {relativeTo: this.activatedRoute, queryParams: {scope: this.scope}})
      }
      const scopeFilter = params.get('scopeFilter');
      const id = params.get('id');
      if (!!scopeFilter && ['talentPartnerId'].includes(scopeFilter) && !!id) {
        this.scopeFilters[this.scope] = {id, name: params.get('name') || '?', field: scopeFilter};
        this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: {scope: this.scope}})
      }

      const isNew = params.get('new') === 'true';
      if (this.scope === 'all' && isNew) {
        this.router.navigate([], {relativeTo: this.activatedRoute, queryParams: {scope: this.scope}});
        setTimeout(() => this.newEntity());
      }

      const transformFromId = params.get('transform');
      if (transformFromId) {
        this.router.navigate([],
          {relativeTo: this.activatedRoute, queryParams: {scope: this.scope}})
        setTimeout(() => this.transformFromTeamCandidate(transformFromId));
      }

      setTimeout(() => this.initQuery());
    });
    this.viewAsItem = this.appConfig.viewAsMe;
  }

  ngOnInit(): void {
  }

  onChangePeriod(): void {
  }

  onChangeViewAs(): void {
    // reload table
  }

  private initQuery(): void {
    if (!!this.initialStatuses.length || !!this.scopeFilters[this.scope]) {
      this.query = {
        logical: 'and',
        predicates: []
      };
      if (!!this.initialStatuses.length) {
        this.query.predicates.push({
          field: 'status',
          operator: 'in',
          value: this.initialStatuses
        });
      }
      if (!!this.scopeFilters[this.scope]) {
        this.query.predicates.push({
          field: this.scopeFilters[this.scope]!.field,
          operator: 'equals',
          value: this.scopeFilters[this.scope]!.id
        });
      }
    }
  }

  applyFilters(): void {
    if (this.teamMemberFilter.hasFilter() || !!this.scopeFilters[this.scope]) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: this.teamMemberFilter.hasFilter() ? this.teamMemberFilter.query!.predicates : []
      }
      if (!!this.scopeFilters[this.scope]) {
        query.predicates.push({
          field: this.scopeFilters[this.scope]!.field,
          operator: 'equals',
          value: this.scopeFilters[this.scope]!.id
        });
      }
      this.query = query;
    } else {
      this.query = null;
    }
  }

  newEntity(teamCandidate?: ITeamCandidate): void {
    if (this.scope === 'all') {
      this.teamTable.openCreateOrUpdateDialog(null, teamCandidate)
    } else {
      this.router.navigate(['team'], {queryParams: {scope: 'all', new: 'true'}});
    }
  }

  transformFromTeamCandidate(teamCandidateId: string): void {
    this.resource.getTeamCandidate(teamCandidateId).subscribe((response) => {
      this.newEntity(response);
    });
  }


  onDataFetched(respReq: IResponseRequest<any>): void {
    this.resource.getTeamMemberCount(respReq.request).subscribe((total) => this.totalTeamMembers = total);
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }
}
