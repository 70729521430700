import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  ITeamMember,
  TEAM_MEMBER_STATUS,
  TEAM_MEMBER_TYPE,
  TTeamMemberStatus,
  TTeamMemberType
} from '../../api/shared/app-domain/team';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {TQueryExpression} from '../../api/shared/search-api';

@Component({
  selector: 'app-team-members-filter',
  template: `
    <p-dialog [(visible)]="showFilterDialog"
              [closable]="true"
              [modal]="true" [style]="{width: '700px'}" appendTo="body">
      <ng-template pTemplate="header">
        <div class="flex align-items-center w-full justify-content-between">
          <div class="text-2xl">Filter by</div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-filter-slash" (onClick)="clearFilters()" label="Clear"
                  [disabled]="!typeFilter.length && !statusFilter.length"
                  styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-check" (onClick)="showFilterDialog=false; onApplyFilters()" label="Apply"
                  styleClass="p-button-text"></p-button>
        <p-button icon="pi  pi-times" (onClick)="showFilterDialog=false" label="Cancel"
                  styleClass="p-button-text"></p-button>
      </ng-template>
      <div class="grid">
        <div class="col-6">
          <p-fieldset legend="Team Member Type" [toggleable]="false">
            <div *ngFor="let item of typeOptions" class="field-checkbox">
              <p-checkbox [value]="item" [(ngModel)]="typeFilter"></p-checkbox>
              <span class="inline-flex align-items-center ml-3">
              <img [src]="entityOptions.getTeamMemberTypeIcon(item)" height="16" width="16" class="mr-2"/>
                {{item}}
            </span>
            </div>
          </p-fieldset>
        </div>
        <div class="col-6 flex flex-column justify-content-between">
          <p-fieldset legend="Team Member Status" [toggleable]="false" class="h-full" styleClass="h-full">
            <div *ngFor="let item of statusOptions" class="field-checkbox">
              <p-checkbox [value]="item" [(ngModel)]="statusFilter"></p-checkbox>
              <span class="ml-3">
              {{item}}
            </span>
            </div>
          </p-fieldset>

          <p-fieldset legend="Record State" [toggleable]="false" class="h-full" styleClass="h-full">
            <div *ngFor="let item of recordStateOptions" class="field-checkbox">
              <p-checkbox [value]="item.value" [(ngModel)]="recordStateFilter"></p-checkbox>
              <span class="ml-3">
              {{item.label}}
            </span>
            </div>
          </p-fieldset>
        </div>
      </div>
    </p-dialog>
    <button pButton pRipple type="button" label="Filter"
            [icon]="'pi ' + (hasFilter() ? 'pi-filter-fill' :  'pi-filter')"
            class="p-button-outlined ml-2" (click)="show()">
    </button>
  `,
  providers: [
    EntityPropertyOptionsService
  ]
})
export class TeamMembersFilterComponent {
  @Input() statusFilter: Array<TTeamMemberStatus> = [];
  @Input() typeFilter: Array<TTeamMemberType> = [];
  @Input() recordStateFilter: Array<boolean> = [];
  @Input() areFieldsEmbedded = false;
  @Output() applyFilter: EventEmitter<TQueryExpression | null> = new EventEmitter<TQueryExpression | null>();
  statusOptions = TEAM_MEMBER_STATUS;
  typeOptions = TEAM_MEMBER_TYPE;
  recordStateOptions = [{value: true, label: 'Draft'}, {value: false, label: 'Permanent'}];
  showFilterDialog = false;

  constructor(public entityOptions: EntityPropertyOptionsService) {
  }


  show(): void {
    this.showFilterDialog = true;
  }


  get currentStatusesAsText(): string {
    return !this.statusFilter.length || this.statusFilter.length === TEAM_MEMBER_STATUS.length ? 'All statuses' : this.statusFilter.join(', ')
  }

  clearFilters(): void {
    this.statusFilter = [];
    this.typeFilter = [];
  }

  hasFilter(): boolean {
    return !!this.typeFilter.length || !!this.statusFilter.length || this.recordStateFilter.length === 1;
  }

  onApplyFilters(): void {
    this.applyFilter.emit(this.query);
  }

  private getQueryFieldName(field: keyof ITeamMember): string {
    const prefix = this.areFieldsEmbedded ? 'teamMember' : '';
    return prefix + field;
  }

  get query(): TQueryExpression | null {
    let result: TQueryExpression | null = null;
    if (this.hasFilter()) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: []
      }
      if (this.typeFilter.length) {
        query.predicates.push({
          field: this.getQueryFieldName('type'),
          operator: 'in',
          value: this.typeFilter
        });
      }
      if (this.statusFilter.length) {
        query.predicates.push({
          field: this.getQueryFieldName('status'),
          operator: 'in',
          value: this.statusFilter
        });
      }
      if (this.recordStateFilter.length === 1) {
        query.predicates.push({
          field: this.getQueryFieldName('isDraft'),
          operator: 'equals',
          value: this.recordStateFilter[0]
        });
      }
      result = query;
    }
    return result;
  }
}
