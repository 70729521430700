import { Component, Input } from '@angular/core';
import { AppResourceService } from '../../../../app.resource.service';
import { IIdentified, IPeriod } from '../../../../api/shared/app-domain/common';

@Component({
  selector: 'app-single-team-member-planning',
  template: `
    <div class="mt-3 mb-2">
      <div class="grid">
        <div class="col-3" *ngFor="let i of 4 | arrayOf">
          <div class="shadow-1"
               style="background: #FFF; border: 1px solid var(--surface-300); height: 10rem; font-size: 12px; padding: 2rem; color: var(--gray-500)">
            TBD
          </div>
        </div>
      </div>
    </div>
    <app-planning-table [period]="period" [fetch]="fetch">
      <ng-template pTemplate="entryEntityTpl" let-rowData>
        <app-project-entry-entity [entryEntity]="rowData.entryEntity">
        </app-project-entry-entity>
      </ng-template>
    </app-planning-table>
  `
})
export class SingleTeamMemberPlanningComponent {

  @Input() period!: IPeriod;
  @Input() entity!: IIdentified;


  fetch = () => this.resource.getSingleTeamMemberPlanning(this.entity.id, {
    period: this.period
  });

  constructor(public resource: AppResourceService) {
  }


}
