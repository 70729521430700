import {Component, forwardRef, OnChanges, ViewChild} from '@angular/core';
import {IHolidayCalendar, THolidayCalendarSave} from '../../api/shared/app-domain/schedule';
import {EntityEditorComponent} from '../common/entity-editor.component';
import {FormBuilder, NgModel} from '@angular/forms';
import {AppResourceService} from '../../app.resource.service';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HolidayCalendarEditorComponent} from './holiday-calendar-editor.component';


@Component({
  selector: 'app-holiday-calendar-entity-editor',
  template: `
    <p-messages *ngIf="hasBeenDeleted" key="tcDeleted"
                [value]="[{severity:'info', summary:'Success', detail:'Holiday Calendar has been deleted'}]"></p-messages>
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container [style]="{visibility: entity != null && !hasBeenDeleted ? 'visible' : 'hidden'}">
        <app-holiday-calendar-editor #model="ngModel" [(ngModel)]="entity" [checkExists]="checkExists"
                                     [editGeneralProperties]="true"
                                     [showBasicCalendarChooser]="isNew()"></app-holiday-calendar-editor>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => HolidayCalendarEntityEditorComponent)},
    SubscriptionsService
  ]
})
export class HolidayCalendarEntityEditorComponent extends EntityEditorComponent<THolidayCalendarSave, IHolidayCalendar> implements OnChanges {
  checkExists!: (value: any) => Observable<boolean>;
  @ViewChild('model') model!: NgModel;
  @ViewChild(HolidayCalendarEditorComponent) holidayEditor!: HolidayCalendarEditorComponent;

  constructor(fb: FormBuilder, public resource: AppResourceService, private router: Router) {
    super(fb);
    this.entityName = 'Holiday Calendar';
    this.api = {
      getEntity: this.resource.getHolidayCalendar.bind(this.resource),
      updateEntity: this.resource.updateHolidayCalendar.bind(this.resource),
      createEntity: this.resource.createHolidayCalendar.bind(this.resource),
      deleteEntities: this.resource.deleteHolidayCalendar.bind(this.resource),
      archiveEntities: this.resource.patchHolidayCalendar.bind(this.resource) as any,
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    this.checkExists = this.getCheckExists(this.resource.getHolidayCalendars.bind(this.resource) as any, 'name')
  }

  override getName(): string {
    return this.entity?.name || '';
  }

  override buildForm(): void {
  }

  override onLoad() {
    if (this.param.isDuplicate) {
      this.entity.name += ' (copy)';
      this.model.control.markAsTouched();
    }
    this.setTitle();
  }


  override newEntity(): void {
    this.setEntity({
      status: 'Active',
    } as any);
  }

  override canSubmit(): boolean {
    if (!this.entity || this.model?.invalid || !this.model?.touched) {
      return false;
    }
    return true;
  }

  override canPerformApply(): boolean {
    return true;
  }

  override getData(): THolidayCalendarSave {
    if (this.param.isDuplicate) {
      this.entity.baseId = this.param.id;
    }
    return this.entity;
  }

  override onApplyDone(entity: IHolidayCalendar) {
    super.onApplyDone(entity);
    this.model.control.markAsUntouched();
    if (this.param.id == null) {
      this.router.navigate(['/settings/holiday-calendars/edit', entity.id]);
    } else {
      this.onLoad();
    }
  }

  override onArchived(): void {
    this.entity.status = 'Archived';
    this.holidayEditor.data.status = this.entity.status;
  }
}
