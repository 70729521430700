import {
  IApprovable,
  IAttachment,
  IIdentified,
  IPeriodRequest,
  IPeriodTotals,
  ITimestamped,
  TApprovalsState,
  TLens,
  TModeLenses
} from './common';
import {IClientEmbedded, IProjectEmbedded, TProjectStatus, TProjectType} from './project';
import {ICategoryEmbedded} from './settings';
import {ITeamMemberEmbedded} from './team';

export const EXPENSES_API_ENDPOINTS = {
  expenses: '/api/team-members/:teamMemberId/expenses',
  searchExpenses: '/api/team-members/:teamMemberId/expenses/search',
  expense: '/api/team-members/:teamMemberId/expenses/:id',
  exportExpenses: '/api/team-members/:teamMemberId/expenses/export',
  importExpenses: '/api/team-members/:teamMemberId/expenses/import',
  downloadExportExpenses: '/api/team-members/:teamMemberId/expenses/export/:id',

  expenseReports: '/api/expense-reports',
  searchExpenseReports: '/api/expense-reports/search',
  expenseReport: '/api/expense-reports/:id',
  expenseReportsTotal: '/api/expense-reports/total',

  searchExpenseApprovals: '/api/expense-approvals/search'
};

export interface IExpense extends IIdentified, ITimestamped, Partial<IProjectEmbedded>, IClientEmbedded, ICategoryEmbedded, IApprovable {
  date: Date;
  amount: number;

  clientId: string;
  clientName: string;

  isBillable: boolean;
  isReimbursable: boolean;

  notes?: string;
  attachments?: Array<IAttachment>;

  budget?: IPeriodTotals;
}

export type TExpenseSave = Omit<IExpense,
  'id' | 'projectName' | 'projectType' | 'projectStatus' | 'categoryName' | 'clientName' | 'createdAt' | 'updatedAt' | 'state'>;

export interface IExpenseReport extends IExpense, ITeamMemberEmbedded {
}

export const EXPENSE_APPROVAL_MODE_LENSES: TModeLenses = {
  Project: [{
    id: 'Budget',
    actualLabel: 'Expense',
    budgetLabel: 'Budget'
  }, {
    id: 'Forecast',
    actualLabel: 'Expense',
    budgetLabel: 'Assigned'
  }]
};

export type TExpenseApprovalsRequest = IPeriodRequest<{ lens: TLens }>;


