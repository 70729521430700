import {IDetails} from './details';
import {IClientEmbedded, IProject, IProjectEmbedded, TProjectEntryEntity, TProjectStatus} from './project';
import {
  IApprovable,
  IIdentified,
  IPeriodRequest,
  IPeriodTotals, ITimestamped,
  IUrlLink,
  IUsefulLinks,
  TApprovalsState,
  TLens,
  TLensMode,
  TModeLenses
} from './common';
import {ITeamMember, ITeamMemberEmbedded} from './team';


export const TIME_API_ENDPOINTS = {
  dayTimeSheets: '/api/times-sheets/day/:teamMemberId',
  dayTimeProject: '/api/times-sheets/day/:teamMemberId/projects/:projectId',
  dayTimeEntries: '/api/times-sheets/day/:teamMemberId/projects/:projectId/time-entries',
  weekTimeSheets: '/api/times-sheets/week/:teamMemberId',
  searchTimeEntries: '/api/time-entries/search',
  searchTimeApprovals: '/api/time-approvals/search'
};


export const TIMESHEETS_MODE_LENSES: TModeLenses = {
  'Payroll': [{
    id: 'Payable of Budget',
    actualLabel: 'Payable',
    budgetLabel: 'Budget'
  }, {
    id: 'Hours Payable of Engagement',
    actualLabel: 'Hours Payable',
    budgetLabel: 'Engagement',
  }, {
    id: 'Hours Payable of Workweek',
    actualLabel: 'Hours Payable',
    budgetLabel: 'Workweek'
  }, {
    id: 'Hours Payable of Max Capacity',
    actualLabel: 'Hours Payable',
    budgetLabel: 'Max Capacity'
  }],
  'Billable': [ {
    id: 'Billable od Budget',
    actualLabel: 'Billable',
    budgetLabel: 'Budget'
  }],
  'Billable Blended': [{
    id: 'Billable Blended of Budget',
    actualLabel: 'Billable Blended',
    budgetLabel: 'Budget'
  }],
  'Payroll/Billable': [{
    id: 'Cost of Billable',
    actualLabel: 'Cost',
    budgetLabel: 'Billable'
  }]
};

export const TIME_APPROVAL_MODE_LENSES: TModeLenses = {
  Payroll: [{
    id: 'Net Utilization',
    actualLabel: 'Hours Worked',
    budgetLabel: 'Hours Paid'
  }, {
    id: 'Billable Utilization',
    actualLabel: 'Hours Billable',
    budgetLabel: 'Hours Paid',
  }, {
    id: 'Workweek',
    actualLabel: 'Hours Worked',
    budgetLabel: 'Workweek'
  }, {
    id: 'Capacity',
    actualLabel: 'Hours Worked',
    budgetLabel: 'Capacity'
  }],
  Billable: [ {
    id: 'Gross Profit Margin',
    actualLabel: 'Billable',
    budgetLabel: 'Cost'
  }],
  Project: [{
    id: 'Allocation',
    actualLabel: 'Hours Worked',
    budgetLabel: 'Scheduled'
  }, {
    id: 'Forecast',
    actualLabel: 'Hours Worked',
    budgetLabel: 'Assigned'
  }]
};


export interface ITimeEntry extends IIdentified {
  departmentId: string;
  departmentName: string;
  notes?: string;
  link?: IUrlLink;
  actualTime: string;
  billableTime?: string; // TaaS or Blended is always calculated
  blendedTime?: string;  // Billable is a manual override of time
  billable: boolean;
}

export type TTimeEntrySave = Omit<ITimeEntry, 'departmentName'>;

export interface ITimeSheetsValueData {
  timeEntries: Array<ITimeEntry>;
}

export interface ITimeSheetsExtraValue {
  unsubmitted: string;
  total: string;
  canSubmit: boolean;
  canPrefillFromPrior: boolean;
  canPrefillFromPlanning: boolean;
}

export type TTimeSheetsDetails = IDetails<TProjectEntryEntity, ITimeSheetsValueData, ITimeSheetsExtraValue, ITimeSheetsExtraValue>;

export type TDayTimeEntriesRequest = IPeriodRequest<{ timeEntries: Array<TTimeEntrySave> }>;

export type TTimeSheetsDetailsRequest = IPeriodRequest<{ lens: TLens }>;

export interface ITimeEntryRow extends IProjectEmbedded, IClientEmbedded, ITeamMemberEmbedded, IApprovable {
  id: string;
  date: Date;

  usefulLinks?: IUsefulLinks;
  departmentId: string;
  departmentName: string;

  payableTime: string;
  billableTime: string;
  billableRate: number;
  billable: number;
  taasPct: number;
  taasTime: string;
  taasRate: number;
  taasBillable: number;
  isBillable: boolean;

  notes?: string;
  link?: IUrlLink;
}


export interface IApprovalsTimeValues {
  actualTime: string;
}

export type TTimeApprovalsProject = IIdentified & IProjectEmbedded & {
  totals: IPeriodTotals;
  values: Array<IApprovalsTimeValues & {timeEntries: Array<ITimeEntry>}>;
};


export type TTimeApprovalsTeamMember = IIdentified & ITeamMemberEmbedded & IApprovable & {
  totals: IPeriodTotals;
  values: Array<IApprovalsTimeValues>;
  projects: Array<TTimeApprovalsProject>;
};

export type TTimeApprovalsRequest = IPeriodRequest<{ lens: TLens }>;

export const TIME_OFF_API_ENDPOINTS = {
  timeOffRequests: '/api/team-members/:teamMemberId/time-off-requests',
  searchTimeOffRequests: '/api/team-members/:teamMemberId/time-off-requests/search',
  timeOffRequest: '/api/team-members/:teamMemberId/time-off-requests/:id',
  exportTimeOffRequests: '/api/team-members/:teamMemberId/time-off-requests/export',
  importTimeOffRequests: '/api/team-members/:teamMemberId/time-off-requests/import',
  downloadExportTimeOffRequests: '/api/team-members/:teamMemberId/time-off-requests/export/:id',

  timeOffs: '/api/time-offs',
  searchTimeOffs: '/api/time-offs/search',
  timeOff: '/api/time-offs/:id',
};

export interface ITimeOff extends IIdentified, ITimestamped, IApprovable {
  startDate: Date;
  endDate: Date;
  useHalfDay: boolean;
  totals: IPeriodTotals;
  details: string;
}

export type TTimeOffSave = Omit<ITimeOff, 'id' | 'totals' | 'createdAt' | 'updatedAt' | 'state'>;

export type TTimeOffApprovalsTeamMember = IIdentified & ITeamMemberEmbedded & {
  totals: IPeriodTotals;
  timeOffs: Array<ITimeOff>;
};
