import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppResourceService} from '../../../app.resource.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {finalize} from 'rxjs';
import {filter, find, sortBy} from 'lodash';
import {ICreateRelationshipParam, IShownObjectType, IAssociatedObjectType, RELATIONSHIPS} from './hs';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {HsNewAssociationComponent} from './hs-new-association.component';
import {IHsObjectType, IHsProperty} from '../../../api/shared/dev-tools/hs-api';

const ASSOCIATIONS_LIMIT = 10;

@Component({
  selector: 'app-hs-associations-editor',
  template: `
    <ng-template #propList let-objectType>
      <div *ngIf="!objectType.properties.length" class="prop-label">
        There are no properties defined
      </div>
      <div *ngFor="let prop of objectType.properties" class="grid">
        <div class="col-4 mt-overflow-ellipsis prop-label" appTooltipOnOverflow>
          {{prop.label}}
        </div>
        <div class="col-4 mt-overflow-ellipsis prop-name" appTooltipOnOverflow>
          {{prop.isRequired ? '(R) ' : ''}}{{prop.isSearchable ? '(S) ' : ''}}{{prop.name}}
        </div>
        <div class="col-2 mt-overflow-ellipsis prop-type" appTooltipOnOverflow>
          {{prop.showCurrencySymbol ? '($) ' : ''}}{{prop.type}}
        </div>
        <div class="col-2 mt-overflow-ellipsis prop-type" appTooltipOnOverflow>
          {{prop.fieldType}}
        </div>
        <div *ngIf="prop.options.length" class="col-offset-4 col-8 mt-overflow-ellipsis prop-type mb-1"
             style="margin-top: -1rem">
          {{propOptionsAsString(prop)}}
        </div>
      </div>
    </ng-template>
    <div>
      <app-spinnerizer [active]="loading"
                       [invertSpinner]="false"
                       [invertText]="true"
                       [target]="container">
      </app-spinnerizer>
      <div #container>
        <div class="grid border-bottom-1 border-gray-300 pt-2 pb-1 w-full relative">
          <div class="col-4">
            From Object Type
          </div>
          <div class="col-8 flex">
            <div [style]="{width: '490px'}" class="">
              Association
            </div>
            <div>
              To Object Type
            </div>
          </div>
          <div class="absolute" [style]="{right: '0', top: '0'}">
            <button pButton pRipple type="button" icon="pi pi-refresh"
                    class="p-button-rounded p-button-text"
                    pTooltip="Reload" tooltipPosition="left"
                    [disabled]="!!!selectedType"
                    (click)="loadToObjectTypes()">
            </button>
            <button pButton pRipple type="button" icon="pi pi-plus"
                    class="ml-2 p-button-rounded p-button-text"
                    pTooltip="Create Association" tooltipPosition="left"
                    [disabled]="!!!selectedType || customObjectTypeCount === customObjectTypeLimit"
                    (click)="createRelationship()">
            </button>
          </div>
        </div>
        <div *ngIf="!!selectedType" class="mt-1 flex justify-content-end align-items-center">
          <div class="assoc-count" [class.error]="customObjectTypeCount === customObjectTypeLimit">
            <i *ngIf="customObjectTypeCount === customObjectTypeLimit"
               class="pi pi-exclamation-triangle text-sm mr-2"></i>
            <span>
              Custom Object Associations Left: {{customObjectTypeLimit - customObjectTypeCount}}
              of {{customObjectTypeLimit}}
            </span>
          </div>
        </div>
        <div class="grid">
          <div class="col-4">
            <div class="flex mt-1">
              <p-dropdown [options]="customObjectTypes"
                          class="w-full"
                          styleClass="w-full"
                          [(ngModel)]="selectedType"
                          dataKey="objectTypeId"
                          optionLabel="label"
                          [filter]="true"
                          filterBy="label"
                          placeholder="Select Object Type"
                          (ngModelChange)="onChangeToObjectType()">
                <ng-template pTemplate="selectedItem">
                  <span>{{selectedType?.label}}</span><span class="ml-2 text-gray-500 text-xs">{{selectedType?.name}}</span>
                </ng-template>
                <ng-template let-type pTemplate="item">
                  <span>{{type.label}}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <div *ngIf="!!selectedType" class="mt-2">
              <p-accordion>
                <p-accordionTab header="Properties" [selected]="true">
                  <ng-container *ngTemplateOutlet="propList; context: {$implicit: selectedType}">
                  </ng-container>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>
          <div class="col-8">
            <div *ngFor="let toObjectType of toObjectTypes" class="flex mt-1 align-items-center">
              <p-dropdown [options]="relationships" [style]="{width: '130px'}"
                          [(ngModel)]="toObjectType.association.relationship"
                          [autoDisplayFirst]="false"
                          optionValue="relationship"
                          optionLabel="label"
                          (onChange)="applyRelationship(toObjectType)">
              </p-dropdown>
              <p-inplace *ngIf="showAssociationNames"
                         #inplace [style]="{width: '260px'}"
                         styleClass="ml-1 mt-overflow-ellipsis text-center">
                <ng-template pTemplate="display">
                  <span class="association">
                    {{toObjectType.association.name}}
                  </span>
                </ng-template>
                <ng-template pTemplate="content">
                  <div class="flex">
                    <input pInputText class="flex-1" [(ngModel)]="toObjectType.association.name">
                    <button pButton pRipple icon="pi pi-check" class="ml-1 p-button-rounded p-button-text"></button>
                    <button pButton pRipple icon="pi pi-times" class="ml-1 p-button-rounded p-button-text"
                            (click)="inplace.deactivate()">
                    </button>
                  </div>
                </ng-template>
              </p-inplace>
              <button pButton pRipple icon="pi pi-trash" class="ml-1 p-button-rounded p-button-text"
                      (click)="removeRelationship($event, toObjectType)">
              </button>
              <div class="ml-1 flex-1 to-object-type mt-overflow-ellipsis relative"
                   [class.cursor-pointer]="toObjectType.isCustom"
                   [class.custom-object-type]="toObjectType.isCustom"
                   appTooltipOnOverflow
                   (click)="toObjectType.isCustom ? openObjectType.emit(toObjectType) : null">
                {{toObjectType.label}}
                <button pButton pRipple icon="pi pi-list"
                        [style]="{top: 0, right: '1rem'}" (click)="propsPanel.toggle($event); $event.stopPropagation()"
                        class="absolute p-button-rounded p-button-text"></button>
              </div>
              <p-overlayPanel #propsPanel [dismissable]="true" [showCloseIcon]="true">
                <ng-template pTemplate>
                  <div class="border-bottom-1 border-gray-300 pb-1 mb-2">
                    {{toObjectType.label}} Properties
                  </div>
                  <div class="overflow-y-auto overflow-x-hidden" [style]="{'max-height': '400px', 'max-width': '600px'}">
                    <ng-container *ngTemplateOutlet="propList; context: {$implicit: toObjectType}"></ng-container>
                  </div>
                </ng-template>
              </p-overlayPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .to-object-type {
      background-color: #fff;
      padding: .75rem;
      border: 1px solid var(--surface-300);
      border-radius: 3px;
    }

    .custom-object-type {
      color: var(--primary-color);
    }

    .association {
      font-size: .8rem;
      color: var(--gray-600);
      text-align: center;
    }

    .assoc-count {
      color: var(--gray-700);
      font-size: .9rem;
    }

    .error {
      color: #B71C1C;
    }

    .prop-label {
      font-size: .9rem;
    }

    .prop-name {
      color: var(--gray-800);
      font-size: .8rem;
    }

    .prop-type {
      color: var(--gray-600);
      font-size: .8rem;
    }

  `],
  providers: [DialogService]
})
export class HsAssociationsEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() objectTypes!: Array<IHsObjectType>;
  @Input() customObjectTypes!: Array<IHsObjectType>;
  @Input() shownObjectType!: IShownObjectType;
  @Input() excludeStandardTypes = true;
  @Input() showAssociationNames = false;
  @Output() openObjectType = new EventEmitter<IHsObjectType>();

  selectedType: IHsObjectType | null = null;
  toObjectTypes!: Array<IAssociatedObjectType>;
  loading = false;
  relationships = RELATIONSHIPS;
  customObjectTypeCount = 0;
  customObjectTypeLimit = ASSOCIATIONS_LIMIT;
  private createAssocDlgRef!: DynamicDialogRef;

  constructor(private resource: AppResourceService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.selectShownType();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['excludeStandardTypes']) {
      if (!!this.selectedType) {
        this.loadToObjectTypes();
      }
    }
  }

  selectShownType(): void {
    if (!!this.shownObjectType.objectType) {
      this.selectedType = this.shownObjectType.objectType;
      this.loadToObjectTypes();
    }
  }

  onChangeToObjectType(): void {
    this.shownObjectType.objectType = this.selectedType;
    this.loadToObjectTypes();
  }

  loadToObjectTypes(): void {
    this.loading = true;
    this.resource.hsGetObjectTypeAssociations(this.selectedType!.objectTypeId)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((associations) => {
        let assocs = associations.map((assoc) => {
          return {
            ...find(this.objectTypes, {objectTypeId: assoc.toObjectTypeId})!,
            ...{association: assoc}
          }
        });
        if (this.excludeStandardTypes) {
          assocs = filter(assocs, {isCustom: true});
        }
        this.toObjectTypes = sortBy(assocs, (ot) => ot.label.toLowerCase());
        this.customObjectTypeCount = filter(assocs, {isCustom: true}).length;
      });
  }

  applyRelationship(toObjectType: IAssociatedObjectType, isCreate?: boolean): void {
    this.loading = true;
    this.resource.hsCreateOrUpdateObjectTypeAssociation(this.selectedType!.objectTypeId, toObjectType.association)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        this.loadToObjectTypes();
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail:
            `Association [${toObjectType.association.name || response.name}] has been ${isCreate ? 'created' : 'updated'}`
        });
      });
  }

  removeRelationship(ev: any, toObjectType: IAssociatedObjectType): void {
    this.confirmationService.confirm({
      key: 'g-popup',
      target: ev.target,
      message: `Are you sure you want to remove [${toObjectType.association.name}] association?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.resource.hsRemoveObjectTypeAssociation(this.selectedType!.objectTypeId, toObjectType.objectTypeId)
          .pipe(
            finalize(() => this.loading = false)
          )
          .subscribe(() => {
            this.loadToObjectTypes();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Association [${toObjectType.association.name}] has been removed`
            });
          });
      }
    });
  }

  createRelationship(): void {
    this.createAssocDlgRef = this.dialogService.open(HsNewAssociationComponent, {
      header: 'Create Association',
      width: '500px',
      modal: true,
      closable: true,
      data: {
        fromObjectType: this.selectedType,
        toObjectTypes: sortBy(filter(this.objectTypes, (ot) => (
          ot.objectTypeId !== this.selectedType?.objectTypeId &&
          find(this.toObjectTypes, {objectTypeId: ot.objectTypeId}) == null
        )), (ot) => ot.label.toLowerCase())
      } as ICreateRelationshipParam
    });

    this.createAssocDlgRef.onClose.subscribe((toObjectType: IAssociatedObjectType) => {
      if (toObjectType) {
        this.applyRelationship(toObjectType, true);
      }
    });
  }

  propOptionsAsString(prop: IHsProperty): string {
    return prop.options.map((o) => `${o.label}(${o.value})`).join('; ')
  }

  ngOnDestroy(): void {
    if (this.createAssocDlgRef) {
      this.createAssocDlgRef.close();
    }
  }

}
