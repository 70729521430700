import {Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';

@Injectable()
export class SubscriptionsService implements OnDestroy {
  private subscriptions: Array<Subscription> = [];

  add(subscription: Subscription): SubscriptionsService {
    this.subscriptions.push(subscription);
    return this;
  }

  unsubscribe(): void {
    while (this.subscriptions.length) {
      this.subscriptions.shift()!.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
