import {Component, forwardRef} from '@angular/core';
import {ITeamCandidate, TTeamCandidateSave} from '../../api/shared/app-domain/team-candidate';
import {Validators} from '@angular/forms';
import {AppResourceService} from '../../app.resource.service';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {EntityBulkEditorComponent} from '../common/entity-bulk-editor.component';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';


@Component({
    selector: 'app-team-candidate-bulkeditor',
    template: `
      <app-entity-bulk-editor-form [bulkEditor]="this">
        <ng-template pTemplate let-field>
          <form [formGroup]="form!">
            <app-team-candidate-form-control [name]="field">
            </app-team-candidate-form-control>
          </form>
        </ng-template>
      </app-entity-bulk-editor-form>
    `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => TeamCandidateBulkEditorComponent)},
  ]
})
export class TeamCandidateBulkEditorComponent extends EntityBulkEditorComponent<TTeamCandidateSave, ITeamCandidate> {
  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.entityName = 'Team Candidate';
    this.api.patchEntities = this.resource.patchTeamCandidates.bind(resource);
    this.fieldDescriptors = {
      status: {validators: [Validators.required]},
      skills: {validators: [Validators.required], isArray: true},
      availability: {validators: [Validators.required]},
      costRateDefault: {},
      roleId: {validators: [Validators.required]},
      departmentId: {validators: [Validators.required]},
      talentPartnerId: {},
      referralId: {}
    };
  }

}

