import {
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ColumnsInfoService, TFrozenColumn} from './column-header.component';
import {Params, Router} from '@angular/router';
import {IconDefinition} from '@fortawesome/free-brands-svg-icons';
import {get, isArray, isFunction} from 'lodash';

export interface IIconLinkOption {
  field: string | ((rowData: any) => any);
  icon: IconDefinition;
  tooltip: string;
  protocol?: string;
  isInternal?: boolean;
}

export type TTableCellType =
  'simple'
  | 'boolean'
  | 'stringArray'
  | 'externalLink'
  | 'internalLink'
  | 'iconLinks'
  | 'custom';

@Component({
  selector: 'app-table-cell',
  template: `
    <ng-template #template>
      <td *ngIf="isSelector; else other" [style.z-index]="1" [style]="_style" pFrozenColumn>
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <ng-template #other>
        <td [style.z-index]="!!frozen ? 1 : 'auto'"
            pFrozenColumn [frozen]="!!frozen"
            [class.mt-last-left-frozen-column]="frozen === 'lastLeft'"
            [class.mt-first-right-frozen-column]="frozen === 'firstRight'"
            [alignFrozen]="frozen === 'firstRight' || frozen === 'right' ? 'right' : undefined!"
            [style]="tdStyle"
            [ngClass]="tdStyleClass"
        >
          <div [ngSwitch]="type" class="flex align-items-center"
               [class.justify-content-end]="align === 'right'"
               [class.justify-content-center]="align === 'center' || type === 'boolean'"
               [style]="_style"
               [class]="styleClass">
            <ng-container *ngIf="isEmpty(); else notNull">
              <div *ngIf="nullSymbol == null; else nullSym" class="border-bottom-1 text-gray-400"
                   style="width: 25px"></div>
              <ng-template #nullSym>
                  {{nullSymbol}}
              </ng-template>
            </ng-container>
            <ng-template #notNull>
              <ng-container *ngSwitchCase="'stringArray'">
                <p-overlayPanel #opStringArray [dismissable]="true" [showCloseIcon]="true">
                  <ng-template pTemplate>
                    <p-listbox class="string-array-list"
                               [class.string-array-links]="!!stringArrayRoute"
                               [options]="_stringArray"
                               [filter]="true"
                               [listStyle]="{'max-height':'250px'}"
                               [readonly]="false" (onClick)="onStringArrayItemClick($event)">
                    </p-listbox>
                  </ng-template>
                </p-overlayPanel>
                <div class="cursor-pointer white-space-nowrap overflow-x-hidden text-overflow-ellipsis"
                     (click)="_stringArray = rowData?.[field] || []; rowData?.[field] ? opStringArray.toggle($event) : null">
                  <div *ngFor="let item of rowData?.[field] || []" class="mt-chip">
                    {{item}}
                  </div>
                </div>
              </ng-container>
              <div *ngSwitchCase="'externalLink'"
                   [class.mt-overflow-ellipsis]="tooltipOnOverflow" appTooltipOnOverflow
                   [showTooltip]="tooltipOnOverflow">
                <a [href]="href || rowData?.[linkUrlField || '']" class="mt-link"
                   target="_blank">{{rowData?.[field]}}</a>
              </div>
              <div *ngSwitchCase="'internalLink'"
                   [class.mt-overflow-ellipsis]="tooltipOnOverflow" appTooltipOnOverflow
                   [showTooltip]="tooltipOnOverflow">
                <a *ngIf="filterLinkOptions && rowData?.[field] != null" [routerLink]="filterLinkOptions.link"
                   [queryParams]="filterLinkOptions.queryParams">
                  <i class="pi pi-filter mt-filter-link text-sm"></i>
                </a>
                <a class="mt-link" [routerLink]="routerLinkOptions?.link"
                   [queryParams]="routerLinkOptions?.queryParams">
                  {{rowData?.[field]}}
                </a>
              </div>
              <ng-container *ngSwitchCase="'iconLinks'">
                <a *ngFor="let link of iconLinksOptions || []; let i = index"
                   class="mt-link"
                   [style.pointer-events]="!getIconLinkValue(link) ? 'none' : 'auto'"
                   [href]="(getIconLinkValue(link) ? ((link.protocol ? (link.protocol + ':')  : '') + getIconLinkValue(link)) : null) | sanitize"
                   [class.ml-2]="i > 0"
                   [class.opacity-50]="!getIconLinkValue(link)"
                   [class.text-gray-600]="!getIconLinkValue(link)"
                   [target]="link.isInternal ? '_self' : '_blank'" [pTooltip]="link.tooltip" tooltipPosition="bottom">
                  <fa-icon [icon]="link.icon"></fa-icon>
                </a>
              </ng-container>
              <ng-container *ngSwitchCase="'boolean'">
                <i class="pi"
                   [class.pi-check-circle]="(value !== undefined ? value : rowData?.[field]) === true"
                   [class.pi-times-circle]="(value !== undefined ? value : rowData?.[field]) === false">
                </i>
              </ng-container>
              <ng-container *ngSwitchCase="'custom'">
                <ng-content></ng-content>
              </ng-container>
              <div *ngSwitchDefault
                   [class.mt-overflow-ellipsis]="tooltipOnOverflow" appTooltipOnOverflow
                   [showTooltip]="tooltipOnOverflow">
                {{value !== undefined ? value : rowData?.[field]}}
              </div>
            </ng-template>
          </div>
        </td>
      </ng-template>
    </ng-template>
  `,
  styles: [`
    ::ng-deep .string-array-list .p-listbox-item {
      cursor: default;
    }

    ::ng-deep .string-array-list.string-array-links .p-listbox-item {
      cursor: pointer;
      color: var(--primary-color) !important;
    }
  `]
})
export class TableCellComponent implements OnChanges, OnInit {
  @Input() isSelector = false;
  @Input() type?: TTableCellType
  @Input() field!: string;
  @Input() linkUrlField?: string;
  @Input() href?: string;
  @Input() routerLinkOptions?: { link: Array<string>; queryParams?: Params };
  @Input() rowData?: any;
  @Input() value?: any;
  @Input() tdStyle: { [prop: string]: any } = {};
  @Input() tdStyleClass?: string;
  @Input() style: { [prop: string]: any } = {};
  @Input() styleClass?: string;
  @Input() tooltipOnOverflow = true;
  @Input() frozen?: TFrozenColumn;
  @Input() iconLinksOptions?: Array<IIconLinkOption>;
  @Input() align: 'left' | 'right' | 'center' = 'left';
  @Input() stringArrayRoute?: string | ((value: any, rowData: any) => string);
  @Input() filterLinkOptions?: { link: Array<string>; queryParams?: Params };
  @Input() showNull = true;
  @Input() nullSymbol: string | null = null;

  @ViewChild('template', {static: true}) template!: TemplateRef<any>;
  _style: { [prop: string]: any } = {};
  _stringArray: Array<string> = [];

  constructor(private viewContainerRef: ViewContainerRef,
              private router: Router,
              @Optional() private columnsInfo: ColumnsInfoService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    let widthStyle: any = {};
    if (this.isSelector) {
      /* widthStyle.maxWidth = widthStyle.width = */
      widthStyle.minWidth = '45px';
    } else {
      widthStyle = this.columnsInfo?.getStyle(this.field) || {};
    }
    this._style = {
      ...widthStyle,
      ...this.style
    };
  }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }

  onStringArrayItemClick(event: any): void {
    if (this.stringArrayRoute) {
      if (isFunction(this.stringArrayRoute)) {
        this.router.navigateByUrl(this.stringArrayRoute(event.value, this.rowData));
      } else {
        this.router.navigateByUrl(this.stringArrayRoute.replace('{id}', event.value));
      }
    }
  }

  getIconLinkValue(iconLinksOption: IIconLinkOption): any {
    return isFunction(iconLinksOption.field)
      ? iconLinksOption.field(this.rowData)
      : get(this.rowData, iconLinksOption.field)
  }

  isEmpty(): boolean {
    return this.showNull
      && ((this.value == null && this.rowData?.[this.field] == null) || (isArray(this.rowData?.[this.field]) && !this.rowData?.[this.field].length));
  }
}
