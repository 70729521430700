import {Component, Host, Input, OnInit, Optional, SkipSelf} from '@angular/core';
import {AbstractControl, ControlContainer} from '@angular/forms';

@Component({
  selector: 'app-control-error',
  template: `
    <div *ngIf="control && control.invalid && (control.dirty || control.touched)"
         class="mt-error_message" [ngClass]="styleClass || ''">
      <div *ngFor="let error of control.errors! | keyvalue" [class.mt-required]="error.key === 'required'">
        <ng-container *ngIf="error.value">
          <ng-container [ngSwitch]="error.key">
            <ng-container *ngSwitchCase="'required'">Value is required</ng-container>
            <ng-container *ngSwitchCase="'minlength'">Value must be at least {{control.errors?.['minlength']?.requiredLength}} characters long</ng-container>
            <ng-container *ngSwitchCase="'maxlength'">Value must be no more than {{control.errors?.['maxlength']?.requiredLength}} characters long</ng-container>
            <ng-container *ngSwitchCase="'min'">Minimum value is {{control.errors?.['min']?.min}}</ng-container>
            <ng-container *ngSwitchCase="'max'">Maximum value is {{control.errors?.['max']?.max}}</ng-container>
            <ng-container *ngSwitchCase="'email'">Invalid email</ng-container>
            <ng-container *ngSwitchCase="'invalidUrl'">Invalid URL</ng-container>
            <ng-container *ngSwitchCase="'invalidData'">Invalid data</ng-container>
            <ng-container *ngSwitchCase="'exists'">Already exists</ng-container>
            <ng-container *ngSwitchDefault>{{error.key}}</ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  `,
  styles: [`
  `]
})
export class ControlErrorComponent implements OnInit {
  @Input() name?: string;
  @Input() styleClass?: string;
  @Input() control?: AbstractControl;
  @Input() dirtyOnInit = false;

  constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {
  }

  ngOnInit() {
    if (!this.control && this.controlContainer && this.name) {
      this.control = this.controlContainer!.control!.get(this.name)!;
    }
    if (this.control && this.dirtyOnInit) {
      this.control.markAsDirty();
    }
  }
}

