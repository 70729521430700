import { Component, Input } from '@angular/core';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';

@Component({
  selector: 'app-company-form-control',
  template: `
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'name'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'status'" [controlName]="name" controlType="dropdown"
                        [options]="propertyOptions.commonStatuses"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'types'" [controlName]="name" controlType="multiselect"
                        [options]="propertyOptions.companyTypes | options"
                        [optionsFilter]="true" [showAddButton]="false"></app-form-control-wrapper>

    </ng-container>
  `
})
export class CompanyFormControlComponent {
  @Input() name!: string;

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }
}
