import {
  AfterContentInit,
  Component,
  ContentChildren, EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef
} from '@angular/core';
import { MenuItem, PrimeTemplate, TreeNode } from 'primeng/api';
import {IIdentified, ILens, IPeriod, TLens, TNavPeriodType, TPeriodType} from '../../api/shared/app-domain/common';
import { getCurrentPeriodValue } from '../../common/components/period-chooser.component';
import { IViewAsItem } from '../../api/shared/user-api';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '../../app-config.service';
import { ServiceLocator } from '../../common/util/util';
import { finalize, Observable } from 'rxjs';
import { find } from 'lodash';

@Component({
  selector: 'app-single-entity-details',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <app-spinnerizer [active]="loading" [target]="entityContainer" spinnerPosition="top"></app-spinnerizer>
        <div class="grid grid-nogutter">
          <div class="col-4" #entityContainer>
            <ng-container *ngIf="entity || !!!fetchEntity; else error">
              <ng-container *ngIf="entityTplRef">
                <ng-container *ngTemplateOutlet="entityTplRef!; context: {$implicit: entity}"></ng-container>
              </ng-container>
            </ng-container>
            <ng-template #error>
              <div *ngIf="!loading" class="text-2xl mt-invalid">Error getting entity for id: {{id}}</div>
            </ng-template>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <p-menubar class="mt-page-menu-bar" [model]="tabMenuItems || []"></p-menubar>
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
            <button pButton pRipple label="Actions"
                    class="p-button-outlined ml-2"
                    [style]="{width: '115px'}"
                    icon="pi pi-chevron-down"
                    iconPos="right" (click)="actionsMenu.toggle($event)"></button>
          </div>
        </div>
      </div>
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter align-items-center">
          <div class="col-5 flex align-items-center">
            <ng-container *ngIf="bottomLeftHeaderTplRef">
              <ng-container
                *ngTemplateOutlet="bottomLeftHeaderTplRef!; context: {$implicit: entity, tab, period}">
              </ng-container>
            </ng-container>
            <app-view-as-chooser [class.hidden]="!showViewAs" #viewAsChooser [(ngModel)]="viewAsItem"
                                 (ngModelChange)="onChangeViewAs()">
            </app-view-as-chooser>
            <app-schedule-chooser *ngIf="showSchedule" [(ngModel)]="schedule" class="ml-2"></app-schedule-chooser>
            <span *ngIf="entity && showLens && lensOptions" class="ml-2 p-float-label">
              <app-tree-select [(ngModel)]="lens" [style]="{width: '250px'}"
                               [filter]="true"
                               [options]="lensOptions">
              </app-tree-select>
<!--            <label>Mode &rarr; Lens</label>-->
            <label>Lens</label>
            </span>
          </div>
          <div class="col-2 flex align-items-center justify-content-center">
            <ng-container *ngIf="bottomCenterHeaderTplRef">
              <ng-container
                *ngTemplateOutlet="bottomCenterHeaderTplRef!; context: {$implicit: entity, tab, period}">
              </ng-container>
            </ng-container>
          </div>
          <div class="col-5 flex justify-content-end">
            <app-period-chooser [(ngModel)]="period" [unitFilter]="periodUnitFilter"
                                [initialPeriodType]="initialPeriodType"
                                (ngModelChange)="periodChanged.emit($event)"
                                [disabled]="!!!entity"></app-period-chooser>
          </div>
        </div>
      </div>
    </div>
    <app-view-as-alert [viewAsItem]="viewAsItem" [viewAsChooser]="viewAsChooser"></app-view-as-alert>
    <div *ngIf="entity" class="mt-3">
      <ng-container *ngIf="entityTplRef">
        <ng-container
          *ngTemplateOutlet="detailsTplRef!; context: {$implicit: entity, tab, period, lens}">
        </ng-container>
      </ng-container>
    </div>
  `
})
export class SingleEntityDetailsComponent implements OnInit, AfterContentInit {
  @Input() fetchEntity?: (id: string) => Observable<IIdentified>;
  @Input() tabRoute!: string;
  @Input() tabMenuItems?: Array<MenuItem> | null = [
    { label: 'Dashboard', queryParams: { tab: 'overview' } },
    { label: 'Details', queryParams: { tab: 'details' } },
    { label: 'Planning', queryParams: { tab: 'planning' } },
    { label: 'Invoices', queryParams: { tab: 'invoices' } }
  ];
  @Input() tab: string = 'overview';
  @Input() showViewAs = true;
  @Input() showSchedule = true;
  @Input() showLens = false;
  @Input() lensOptions?: Array<TreeNode<ILens>>;
  @Input() periodUnitFilter: Array<TPeriodType> = ['Day', 'Week', 'Month'];
  @Input() initialPeriodType: TNavPeriodType = 'Month';

  @Input() entity?: IIdentified | null;

  @Output() periodChanged: EventEmitter<IPeriod> = new EventEmitter<IPeriod>();

  @ContentChildren(PrimeTemplate) templates?: QueryList<PrimeTemplate>;
  entityTplRef?: TemplateRef<any>;
  detailsTplRef?: TemplateRef<any>;
  bottomLeftHeaderTplRef?: TemplateRef<any>;
  bottomCenterHeaderTplRef?: TemplateRef<any>;

  id: string | null = null;

  schedule: string = 'project';
  period!: IPeriod;
  viewAsItem: IViewAsItem | null = null;
  lens: ILens | null = null;

  actionItems: Array<MenuItem> = [
    { label: 'Sync QB', command: this.tbd },
    { label: 'Edit', command: this.tbd },
    { label: 'Duplicate', command: this.tbd },
    { label: 'Archive', command: this.tbd },
    { label: 'Delete', command: this.tbd }
  ];
  loading = false;

  constructor(private activatedRoute: ActivatedRoute,
              public appConfig: AppConfigService,
              private router: Router) {
    this.activatedRoute.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (!!id) {
        this.id = id;
        this.updateTabMenu();
      }
    });
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (this.tabMenuItems?.length) {
        const tab: string | null = params.get('tab');
        if (!!!tab || !find(this.tabMenuItems, (mi) => mi.queryParams?.['tab'] === tab)) {
          this.router.navigate([],
            { relativeTo: this.activatedRoute, queryParams: { tab: 'overview' }, queryParamsHandling: 'merge' });
        } else {
          this.tab = tab;
        }
      }
    });
  }

  ngAfterContentInit(): void {
    if (this.templates) {
      this.templates.forEach((tpl) => {
        if (tpl.getType() === 'entityTpl') {
          this.entityTplRef = tpl.template;
        } else if (tpl.getType() === 'detailsTpl') {
          this.detailsTplRef = tpl.template;
        } else if (tpl.getType() === 'bottomLeftHeaderTpl') {
          this.bottomLeftHeaderTplRef = tpl.template;
        } else if (tpl.getType() === 'bottomCenterHeaderTpl') {
          this.bottomCenterHeaderTplRef = tpl.template;
        }
      });
    }
  }

  ngOnInit(): void {
    this.period = getCurrentPeriodValue(this.initialPeriodType);
    if (this.showLens && this.lensOptions?.length) {
      this.lens = this.lensOptions[0].children![0].data!;
    }
    if (this.showViewAs) {
      this.viewAsItem = this.appConfig.viewAsMe;
    }

    this.updateTabMenu();
    this.load();
  }

  private updateTabMenu(): void {
    for (const mi of this.tabMenuItems || []) {
      mi.routerLink = [this.tabRoute, this.id];
    }
  }

  load(): void {
    if (!!this.id && !!this.fetchEntity) {
      this.loading = true;
      this.fetchEntity(this.id)
        .pipe(finalize(() => this.loading = false))
        .subscribe((response) => {
          this.entity = response;
        });
    }
  }

  onChangeViewAs(): void {
    // reload
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }

}
