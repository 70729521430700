import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IIdentified, ILens, IPeriod, IPeriodRequest, TApprovalsState} from '../../../api/shared/app-domain/common';
import {EntityPropertyOptionsService, TOptionsTeamMember} from '../../common/entity-property-options.service';
import {ISearchRequest, ISearchResponse} from '../../../api/shared/search-api';
import {AppResourceService} from '../../../app.resource.service';
import {PageableTableComponent} from '../../../common/components/pageable-table.component';
import {IExpense, IExpenseReport} from '../../../api/shared/app-domain/expenses';
import {Observable} from 'rxjs';
import {TableQuery} from '../../../common/util/table-query';
import {onComponentPropChanged, ServiceLocator} from '../../../common/util/util';
import {ColumnsInfoService} from '../../common/column-header.component';
import {DatePipe} from '@angular/common';
import {IMenuItem} from '../../../common/components/table-base.component';
import {startCase} from 'lodash';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';

import {TApproveAction} from '../common';
import {ITimeOff} from '../../../api/shared/app-domain/time';


@Component({
  selector: 'app-expense-reports-or-approvals-table',
  template: `
    <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit', 'archive']"
                       [customMenuItems]="customTableMenuItems"></app-table-toolbar>
    <app-entity-edit-dialog #editDialog width="600px" minWidth="600px" (apply)="onCreatedOrUpdated($event)">
      <ng-template pTemplate let-param>
        <app-expense-editor [param]="param"></app-expense-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Expenses"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="date" filterType="date" frozen="left"
                               [fixedWidth]="100"></app-column-header>
            <app-column-header filterType="optionsIn" field="clientName" label="Client" frozen="left"
                               filterField="clientId"
                               [fixedWidth]="120"
                               [options]="$any(propertyOptions.getOptions('projectClients') | async)"
                               optionLabel="name" optionValue="id"></app-column-header>
            <app-column-header filterType="optionsIn" field="projectName" label="Project"
                               frozen="left"
                               filterField="projectId"
                               [fixedWidth]="200"
                               [options]="$any(propertyOptions.getOptions('projects') | async)"
                               optionLabel="name" optionValue="id"></app-column-header>
            <app-column-header field="teamMemberName" filterType="optionsIn" label="Team Member"
                               frozen="lastLeft" [fixedWidth]="220"
                               [options]="$any(propertyOptions.getOptions('teamMemberFullNames') | async)"
                               optionValue="name"
                               [optionsVirtualScroll]="true"></app-column-header>

            <app-column-header *ngIf="!isApprovalMode" field="state" filterType="optionsEquals" field="state"
                               [fixedWidth]="120"
                               [options]="propertyOptions.approvalStates"></app-column-header>
            <ng-container *ngIf="isApprovalMode">
              <th class="text-center" [style.min-width]="'100px'">{{lens!.actualLabel}}</th>
              <th class="text-center" [style.min-width]="'100px'">{{lens!.budgetLabel}}</th>
              <th class="text-center" [style.min-width]="'100px'">Delta</th>
              <th class="text-center mt-last-left-frozen-column" [style.min-width]="'100px'">
                <div class="mt-overflow-ellipsis" appTooltipOnOverflow>{{lens!.label ?? lens!.id}}</div>
              </th>
            </ng-container>
            <app-column-header filterType="optionsIn" field="categoryName" label="Category"
                               filterField="categoryId"
                               [fixedWidth]="160"
                               [options]="$any(propertyOptions.getOptions('expenseCategories') | async)"
                               optionLabel="name" optionValue="id"></app-column-header>
            <app-column-header field="amount" filterType="numeric"
                               [fixedWidth]="120"></app-column-header>
            <app-column-header field="isBillable" filterType="boolean"
                               [fixedWidth]="120"></app-column-header>
            <app-column-header field="isReimbursable" filterType="boolean"
                               [fixedWidth]="120"></app-column-header>
            <app-column-header field="notes" filterType="text" [fixedWidth]="220"></app-column-header>
            <app-column-header field="attachments" [sortable]="false"></app-column-header>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <app-table-cell type="custom" field="date" [rowData]="rowData" frozen="left">
              <div class="mt-link"
                   (click)="currentMenuRowData = rowData; openCreateOrUpdateDialog(rowData)">
                {{rowData.date | date: 'EEE, MMM dd, y'}}</div>
            </app-table-cell>
            <app-table-cell field="clientName" [value]="rowData.clientName" frozen="left"></app-table-cell>
            <app-table-cell type="custom" field="projectName" [rowData]="rowData" frozen="left"
                            tdStyleClass="py-0">
              <app-project-entry-entity width="180px" [entryEntity]="rowData" routerLink="/projects"
                                        [queryParams]="{tab: 'details'}">
              </app-project-entry-entity>
            </app-table-cell>
            <app-table-cell type="custom" field="teamMemberName" [rowData]="rowData" frozen="lastLeft"
                            tdStyleClass="py-0" styleClass="justify-content-between">
              <app-team-member-entry-entity [entryEntity]="rowData" width="150px"
                                            routerLink="/team-members"
                                            [queryParams]="{tab: 'details'}"></app-team-member-entry-entity>
              <div class="flex align-items-center justify-content-end">
                <app-approvals-state *ngIf="isApprovalMode && state == null"
                                     [state]="rowData.state" class="mr-1"></app-approvals-state>
                <app-row-menu-button [tableBase]="this" [rowData]="rowData" [customMenuItems]="customRowMenuItems"
                                     [omitMenuItems]="['duplicate', 'archive']"></app-row-menu-button>
              </div>
            </app-table-cell>

            <app-table-cell *ngIf="!isApprovalMode" type="custom" field="state" [rowData]="rowData">
              <app-approvals-state [state]="rowData.state"></app-approvals-state>
            </app-table-cell>
            <ng-container *ngIf="isApprovalMode">
              <td colspan="2" style="padding-top: 0; padding-bottom: 0; flex: 2">
                <div class="w-full">
                  <div class="flex justify-content-between">
                    <div>{{rowData.budget.actual.currency | currency:'USD':'symbol':'1.0-0'}}</div>
                    <div>of</div>
                    <div>{{rowData.budget.budget.currency | currency:'USD':'symbol':'1.0-0'}}</div>
                  </div>
                  <div *ngIf="rowData.budget.actual.currency != null && rowData.budget.budget.currency">
                    <p-progressBar
                      [value]="rowData.budget.actual.currency! / rowData.budget.budget.currency! * 100"
                      [showValue]="false"></p-progressBar>
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-column text-right">
                  <div>{{rowData.budget.delta.currency | currency:'USD':'symbol':'1.0-0'}}</div>
                </div>
              </td>
              <td class="mt-last-left-frozen-column">
                <div class="w-full flex justify-content-end">
                  <div class="mt-pct-box w-fit">
                    {{rowData.budget.deltaPct | percent}}
                  </div>
                </div>
              </td>
            </ng-container>
            <app-table-cell field="categoryName" [value]="rowData.categoryName"></app-table-cell>
            <app-table-cell field="amount" align="right"
                            [value]="rowData.amount | currency:'USD':'symbol':'1.2-2'"></app-table-cell>
            <app-table-cell field="isBillable" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="isReimbursable" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="notes" [rowData]="rowData" [style]="{maxWidth: '200px'}"></app-table-cell>
            <app-table-cell type="custom" field="attachments" [rowData]="rowData" tdStyleClass="py-0">
              <app-attachments-cell [attachments]="rowData.attachments"></app-attachments-cell>
            </app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    ColumnsInfoService,
    EntityPropertyOptionsService
  ]
})
export class ExpenseReportsOrApprovalsTableComponent extends PageableTableComponent<IExpenseReport> implements OnChanges, OnInit {
  @Input() isApprovalMode = false;
  @Input() period!: IPeriod;
  @Input() state: TApprovalsState | null = null;
  @Input() lens?: ILens;
  customTableMenuItems: Array<IMenuItem> = [];
  customRowMenuItems: Array<IMenuItem> = [];

  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.entityName = 'Expense';
    this.api = {
      deleteEntities: this.resource.deleteExpenseReports.bind(this.resource),
      patchEntities: this.resource.patchExpenseReports.bind(this.resource),
    };
  }

  ngOnInit(): void {
    if (this.isApprovalMode) {
      this.customTableMenuItems = [
        ...(this.state === 'Unsubmitted' ? [{
          id: 'submit' as TApproveAction,
          label: 'Submit', icon: 'pi pi-check',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'submit')
        }, {
          id: 'submitAndApprove' as TApproveAction,
          label: 'Submit and Approve', icon: 'pi pi-thumbs-up',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'submitAndApprove')
        }] : []),
        ...(this.state === 'Pending' ? [{
          id: 'approve' as TApproveAction,
          label: 'Approve', icon: 'pi pi-thumbs-up',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'approve')
        }, {
          id: 'reject' as TApproveAction,
          label: 'Reject', icon: 'pi pi-undo',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'reject')
        }, {
          id: 'deny' as TApproveAction,
          label: 'Deny', icon: 'pi pi-ban',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'deny')
        }] : []),
        ...(this.state === 'Approved' ? [{
          id: 'reject' as TApproveAction,
          label: 'Reject', icon: 'pi pi-undo',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'reject')
        }] : [])
      ];
      this.customRowMenuItems = [
        {
          id: 'edit',
          label: 'Edit', icon: 'pi pi-pencil',
          onShowMenu: (entity: ITimeOff, menuItem: IMenuItem) => menuItem.visible = (entity.state !== 'Denied'),
          command: (event: MenuItemCommandEvent) => this.openCreateOrUpdateDialog(this.currentMenuRowData)
        },
        {
          id: 'submit' as TApproveAction,
          label: 'Submit', icon: 'pi pi-check',
          disabled: false,
          onShowMenu: (entity: IExpenseReport, menuItem: IMenuItem) => menuItem.visible = (entity.state === 'Unsubmitted'),
          command: (event: MenuItemCommandEvent) => this.performAction(this.rowMenuButtonClickEvent.target!, [this.currentMenuRowData], 'submit')
        }, {
          id: 'submitAndApprove' as TApproveAction,
          label: 'Submit And Approve', icon: 'pi pi-thumbs-up',
          disabled: false,
          onShowMenu: (entity: IExpenseReport, menuItem: IMenuItem) => menuItem.visible = (entity.state === 'Unsubmitted'),
          command: (event: MenuItemCommandEvent) => this.performAction(this.rowMenuButtonClickEvent.target!, [this.currentMenuRowData], 'submitAndApprove')
        },
        {
          id: 'approve' as TApproveAction,
          label: 'Approve', icon: 'pi pi-thumbs-up',
          disabled: false,
          onShowMenu: (entity: IExpenseReport, menuItem: IMenuItem) => menuItem.visible = (entity.state === 'Pending'),
          command: (event: MenuItemCommandEvent) => this.performAction(this.rowMenuButtonClickEvent.target!, [this.currentMenuRowData], 'approve')
        },
        {
          id: 'reject' as TApproveAction,
          label: 'Reject', icon: 'pi pi-undo',
          disabled: false,
          onShowMenu: (entity: IExpenseReport, menuItem: IMenuItem) => menuItem.visible = (entity.state === 'Pending' || entity.state === 'Approved'),
          command: (event: MenuItemCommandEvent) => this.performAction(this.rowMenuButtonClickEvent.target!, [this.currentMenuRowData], 'reject')
        },
        {
          id: 'deny' as TApproveAction,
          label: 'Deny', icon: 'pi pi-ban',
          disabled: false,
          onShowMenu: (entity: IExpenseReport, menuItem: IMenuItem) => menuItem.visible = entity.state === 'Pending',
          command: (event: MenuItemCommandEvent) => this.performAction(this.rowMenuButtonClickEvent.target!, [this.currentMenuRowData], 'reject')
        }

      ];
    }
  }


  override canPerformTableMenuAction(itemId: string): ((entity: IExpenseReport) => boolean) {
    if (itemId as TApproveAction === 'submit') {
      return (entity: IExpenseReport) => (entity.state === 'Unsubmitted');
    }
    if (itemId as TApproveAction === 'submitAndApprove') {
      return (entity: IExpenseReport) => (entity.state === 'Unsubmitted');
    }
    if (itemId as TApproveAction === 'approve') {
      return (entity: IExpenseReport) => (entity.state === 'Pending');
    }
    if (itemId as TApproveAction === 'reject') {
      return (entity: IExpenseReport) => (entity.state !== 'Unsubmitted');
    }
    if (itemId as TApproveAction === 'deny') {
      return (entity: IExpenseReport) => (entity.state === 'Pending');
    }
    return super.canPerformTableMenuAction(itemId);
  }


  override ngOnChanges(changes: SimpleChanges): void {
    onComponentPropChanged(changes, ['period', 'teamMember', 'query', 'lens'], () => this.load())
  }


  protected override fetchData(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<IExpenseReport>> {
    searchRequest.param = {
      period: this.period,
      data: this.isApprovalMode ? {lens: this.lens!.id} : undefined
    };
    return this.isApprovalMode
      ? this.resource.searchExpenseApprovals(searchRequest)
      : this.resource.searchExpenseReports(searchRequest);
  }

  performAction(eventTarget: EventTarget, entities: Array<IExpenseReport>, action: TApproveAction): void {
    if (!entities?.length) {
      return;
    }
    const perform = () => {  // TODO use new api call for actions
      let state: TApprovalsState;
      switch (action) {
        case 'submit':
          state = 'Pending';
          break;
        case 'approve':
        case 'submitAndApprove':
          state = 'Approved';
          break;
        case 'reject':
          state = 'Unsubmitted';
          break;
        case 'deny':
          state = 'Denied';
          break;

      }
      this.patchField(entities, {state}, 'state');
    };
    const actionText = startCase(action);
    const itemsText = entities.length === 1
      ? `Expense for ${new DatePipe('en-US').transform(entities[0].date, 'EEE, MMM dd, y')}`
      : `${entities.length} selected Expenses`

    ServiceLocator.confirm({
      target: eventTarget,
      key: 'g-popup',
      message: `Are you sure you want to ${actionText} ${itemsText}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        perform();
      }
    });
  }

}
