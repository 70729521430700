import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {finalize, Subscription} from 'rxjs';
import {findIndex, groupBy, keyBy, keys, sortBy, toArray, values} from 'lodash';
import {AppResourceService} from '../../../../app.resource.service';
import {SelectItem, TreeNode} from 'primeng/api';
import {IDbSchema, IDbSchemaInconsistencies, IDbTable} from '../../../../api/shared/dev-tools/db-api';
import {TabView} from 'primeng/tabview';
import {DbStorage} from '../db';
import {DbBrowserService} from './db-browser.service';
import {DbNormalizeNamePipe} from '../db-normalize-name.pipe';

interface IShownTable {
  table: IDbTable | null;
}

@Component({
  selector: 'app-db-browser',
  template: `
    <app-spinnerizer [active]="loading"
                     [invertSpinner]="false"
                     [invertText]="true"
                     text="Loading database schema..."
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <div class="flex justify-content-between align-items-center">
        <div class="flex align-items-center">
          <div class="text-2xl mb-2">Database Browser</div>
          <ng-container *ngIf="inconsistenciesNodes?.length">
            <p-overlayPanel #depsPanel [dismissable]="true" [showCloseIcon]="true">
              <ng-template pTemplate>
                <div class="text-2xl mb-2">Cyclic Dependencies</div>
                <p-tree [value]="inconsistenciesNodes" scrollHeight="400px">
                  <ng-template let-node pTemplate="deps">
                    <i class="pi pi-sync mr-1"></i><span class="font-medium text-lg">{{node.label}}</span>
                  </ng-template>
                  <ng-template let-node pTemplate="table">
                    <i class="pi pi-table mr-1"></i><span>{{node.label}}</span>
                  </ng-template>
                  <ng-template let-node pTemplate="column">
                    <i class="pi pi-directions mr-1"></i><span>{{node.label}}</span>
                  </ng-template>
                </p-tree>
              </ng-template>
            </p-overlayPanel>
            <i class="pi pi-exclamation-triangle mt-invalid ml-3 text-3xl cursor-pointer"
                pTooltip="Cyclic dependencies detected" (click)="depsPanel.show($event)"></i>
          </ng-container>
        </div>
        <div class="flex align-items-center">
          <button pButton pRipple type="button" icon="pi pi-replay"
                  class="p-button-rounded p-button-text"
                  pTooltip="Reset All States" tooltipPosition="bottom"
                  (click)="onClearAllStates()">
          </button>
          <button pButton pRipple type="button" icon="pi pi-plus"
                  class="p-button-rounded p-button-text ml-2"
                  pTooltip="Add Table Tab" tooltipPosition="bottom"
                  (click)="addTab()"></button>
        </div>
      </div>
      <p-tabView [scrollable]="true" [(activeIndex)]="activeIndex" (onClose)="onTabClose($event.index)">
        <p-tabPanel [header]="!shownTable.table ? '<empty>' : shownTable.table!.name | dbNormalizeName"
                    [closable]="i > 0" class="aaaa"
                    *ngFor="let shownTable of shownTables; let i = index">
          <div class="grid align-items-center">
            <div class="col-4">
              <p-dropdown [options]="tables"
                          class="w-full"
                          styleClass="w-full"
                          [(ngModel)]="shownTable.table"
                          optionLabel="label"
                          optionValue="value"
                          [filter]="true"
                          filterBy="label"
                          placeholder="Select Table"
                          (onChange)="onSelectTable()">
                <ng-template pTemplate="selectedItem">
                  <div>
                    {{shownTable.table?.name | dbNormalizeName}}
                  </div>
                </ng-template>
                <ng-template let-tbl pTemplate="item">
                  <span>{{tbl.label | dbNormalizeName}}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <div class="col-8 flex justify-content-end">
              <app-tip [boxed]="true" class="flex-1"
                       text="The table that contains the foreign key is called the referencing table or child table.
                             And the table referenced by the foreign key is called the referenced table or parent table.">
              </app-tip>
            </div>
          </div>
          <div *ngIf="shownTable.table" class="mt-2">
            <app-db-table [schema]="schema" [table]="shownTable.table" *appRecreateViewKey="shownTable.table">
            </app-db-table>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  `,
  styles: [`
    :host ::ng-deep .p-tabview .p-tabview-panels {
      background-color: #FAFAFA;
      padding-left: 0;
      padding-right: 0;
    }
  `],
  providers: [DbBrowserService]
})
export class DbBrowserComponent implements OnInit, OnDestroy {
  activeIndex = 0;
  loading = false;
  schema!: IDbSchema;
  tables!: Array<SelectItem<IDbTable>>;
  inconsistenciesNodes!: Array<TreeNode>;

  shownTables: Array<IShownTable> = [{table: null}];
  @ViewChild(TabView) tabView!: TabView;
  subscription: Subscription;

  constructor(private resource: AppResourceService,
              private browserService: DbBrowserService) {
    this.subscription = browserService.browseTableSource$.subscribe((table) => {
      this.addTab(table);
    });
  }

  ngOnInit(): void {
    this.loading = true;

    this.resource.dbGetSchema()
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((schema) => {
        this.schema = schema;
        console.log('db schema', schema);
        this.tables = sortBy(schema.tables.map((t) => ({label: t.name, value: t})), ['label']);
        DbStorage.checkSavedTables(schema);
      });

    this.resource.dbCheckSchema().subscribe((response) => {
      if (response.cyclicDependencies?.length) {
        this.inconsistenciesNodes = [];
        response.cyclicDependencies.forEach((dep) => {
          const node: TreeNode = {
            label: '... -> ' + dep.sequence.map(this.normalizeName).join(' -> ') +
              ' -> ' + this.normalizeName(dep.sequence[0]) + ' -> ...',
            type: 'deps',
            children: []
          };
          const grouped = groupBy(sortBy(dep.references, ['tableName']), 'tableName');
          keys(grouped).forEach((tbl) => {
            node.children?.push({
              label: this.normalizeName(tbl),
              type: 'table',
              children: grouped[tbl].map((col) => ({ label: this.normalizeName(col.columnName), type: 'column'}))
            });
          });
          this.inconsistenciesNodes.push(node);
        });
      }
    });
  }

  addTab(table: IDbTable | null = null): void {
    let index = findIndex(this.shownTables, (st) => st.table?.name === table?.name);
    const tabExists = index !== -1;
    if (!tabExists) {
      this.shownTables.push({table});
      index = this.shownTables.length - 1;
    }
    setTimeout(() => {
      this.activeIndex = index;
    });
  }

  onTabClose(index: number): void {
    this.shownTables.splice(index, 1);
    this.activeIndex = index - 1;
  }

  onSelectTable(): void {
    setTimeout(() => {
      this.tabView.updateInkBar();
    });
  }

  onClearAllStates(): void {
    DbStorage.clearTableStates(this.schema);
    this.browserService.onClearAllStates();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  normalizeName(name: string): string {
    return new DbNormalizeNamePipe().transform(name);
  }

}
