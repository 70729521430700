import { Component, forwardRef } from '@angular/core';
import { EntityEditorBaseComponent } from '../common/entity-editor-base.component';
import { SubscriptionsService } from '../../common/services/subscriptions.service';
import { EntityEditorComponent } from '../common/entity-editor.component';
import { AppResourceService } from '../../app.resource.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {ICompany, TCompanySave} from '../../api/shared/app-domain/company';
import { existsAsyncValidator } from '../../common/util/util';
import {TControlsOf} from '../../common/types';

@Component({
  selector: 'app-company-editor',
  template: `
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container>
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-8 p-fluid">
            <app-company-form-control name="name"></app-company-form-control>
          </div>
          <div class="field col-4 p-fluid">
            <app-company-form-control name="status"></app-company-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => CompanyEditorComponent)},
    SubscriptionsService
  ]

})
export class CompanyEditorComponent extends EntityEditorComponent<TCompanySave, ICompany> {

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              fb: FormBuilder) {
    super(fb);
    this.entityName = 'Company';
    this.api = { // TODO: use EntityResource
      getEntity: this.resource.companies.getEntity.bind(this.resource.companies),
      createEntity: this.resource.companies.createEntity.bind(this.resource.companies),
      updateEntity: this.resource.companies.updateEntity.bind(this.resource.companies),
    }
  }

  override getName(): string | null {
    return this.entity.name ?? '';
  }

  override buildForm(): void {
    const checkExistsCompany = this.getCheckExists(this.resource.companies.searchEntities.bind(this.resource), 'name');
    this.form = this.fb.group<TControlsOf<TCompanySave>>({
      name: new FormControl(this.entity.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsCompany)]
      }),
      status: new FormControl(this.isNew() ? 'Active' : this.entity.status, [Validators.required]),
    });
  }
}
