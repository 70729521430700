import {Injectable} from '@angular/core';
import {BypassHttpService} from './bypass-http.service';
import {decodeJwtPayload, unixTime} from '../util/util';
import {AuthService, IJwtToken} from './auth.service';
import {ENDPOINTS, IRefreshTokenPayload, ISignInResponse} from '../../api/api';
import {firstValueFrom} from 'rxjs';


const REFRESH_SAFETY_SECONDS = 5;

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  private promise: Promise<string | null> | null = null;

  constructor(private bypassHttp: BypassHttpService, private authService: AuthService) {
  }

  getOrRefreshToken(): Promise<string | null> {
    const token = this.getToken();
    if (!this.isExpired(token)) {
      return Promise.resolve(token);
    }
    if (!this.promise) {
      this.promise = this.fetchToken();
    }
    return this.promise;
  }

  isExpired(token: string | null) {
    if (!token) {
      return true;
    } else {
      const decoded = decodeJwtPayload<IJwtToken>(token);
      return decoded.exp != null && (decoded.exp - REFRESH_SAFETY_SECONDS < unixTime());
    }
  }

  async fetchToken(): Promise<string | null> {
    try {
      const refreshToken = this.getRefreshToken();
      if (refreshToken && !this.isExpired(refreshToken)) {
        const response = await firstValueFrom(this.bypassHttp.request<ISignInResponse, IRefreshTokenPayload>('post', ENDPOINTS.refreshToken, {
          body: {
            refreshToken
          }
        }));
        const token = response.token;
        this.authService.setUser(token);
        return token;
      } else {
        return null;
      }
    } catch (e) {
      throw e;
    } finally {
      setTimeout(() => this.promise = null);
    }
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  getRefreshToken(): string | null {
    return localStorage.getItem('refreshToken');
  }

}
