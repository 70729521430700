import {
  Component, Injectable,
  Input,
  OnChanges,
  OnInit, Optional, SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {startCase} from 'lodash';
import {plural} from '../../common/util/util';
import {TableQuery} from '../../common/util/table-query';

export type TColumnFilter =
  'text' | 'numeric' | 'date' | 'boolean' |
  'optionsIn' | 'optionsContains' | 'optionsEquals' | 'optionsArray';

export type TFrozenColumn = 'lastLeft' | 'firstRight' | 'left' | 'right';

@Injectable()
export class ColumnsInfoService {
  columns: { [name: string]: { fixedWidth: number } } = {};

  put(name: string, fixedWidth: number): void {
    this.columns[name] = {fixedWidth};
  }

  getStyle(name: string): { [prop: string]: any } {
    const col = name === '$selector' ? {fixedWidth: 45} : this.columns[name];
    return !!col ? {
      width: col.fixedWidth + 'px',
      maxWidth: col.fixedWidth + 'px',
      minWidth: col.fixedWidth + 'px'
    } : {};
  }
}


@Component({
  selector: 'app-column-header',
  template: `
      <ng-template #template>
          <th *ngIf="isSelector; else other" [style]="_style" pFrozenColumn>
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <ng-template #other>
              <th [style]="_style"
                  pFrozenColumn [frozen]="!!frozen"
                  [pSortableColumn]="sortField ?? field" [pSortableColumnDisabled]="!sortable"
                  [class.mt-last-left-frozen-column]="frozen === 'lastLeft'"
                  [class.mt-first-right-frozen-column]="frozen === 'firstRight'"
                  [alignFrozen]="frozen === 'firstRight' || frozen === 'right' ? 'right' : undefined!"
                  [ngClass]="thStyleClass">
                  <div class="flex justify-content-between align-items-center w-full">
                      <div class="mt-overflow-ellipsis" appTooltipOnOverflow>{{label}}</div>
                      <p-sortIcon *ngIf="sortable" class="align-self-center" [field]="sortField ?? field"></p-sortIcon>
                      <ng-container [ngSwitch]="filterType">
                          <p-columnFilter *switchCases="['optionsIn', 'optionsContains', 'optionsArray']"
                                          [field]="filterField ?? field"
                                          [matchMode]="filterType === 'optionsIn' ? 'in' : 'contains'"
                                          display="menu" class="ml-auto"
                                          [showMatchModes]="filterType === 'optionsArray'" [showOperator]="false"
                                          [showAddButton]="false"
                                          [matchModeOptions]="tableQuery.getMatchModeOptions('array')"
                                          [showApplyButton]="false">
                              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-multiSelect [ngModel]="value"
                                                 [options]="options!"
                                                 [optionLabel]="optionLabel!"
                                                 [optionValue]="optionValue!"
                                                 [virtualScroll]="optionsVirtualScroll"
                                                 [showToggleAll]="!optionsVirtualScroll"
                                                 [virtualScrollItemSize]="39"
                                                 [placeholder]="filterType === 'optionsArray' ? '' : filterType === 'optionsIn' ? 'Any' : 'Contains'"
                                                 [selectedItemsLabel]="optionsSelectedLabel!"
                                                 (onChange)="filter($event.value)">
                                      <ng-template *ngIf="optionIcon" let-item pTemplate="item">
                                          <div class="flex align-items-center">
                                              <img class="mr-2" [src]="item[optionIcon!]" width="24"/>
                                              <div>{{optionLabel ? item[optionLabel!] : item}}</div>
                                          </div>
                                      </ng-template>
                                  </p-multiSelect>
                              </ng-template>
                          </p-columnFilter>
                          <p-columnFilter *ngSwitchCase="'optionsEquals'" [field]="filterField ?? field"
                                          matchMode="equals" display="menu" class="ml-auto"
                                          [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                          [showApplyButton]="false">
                              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                  <p-dropdown *ngIf="optionsType === 'array'"
                                              [ngModel]="value"
                                              [options]="options!"
                                              [optionLabel]="optionLabel!"
                                              [optionValue]="optionValue!"
                                              placeholder="Search"
                                              (onChange)="filter($event.value)">
                                  </p-dropdown>
                                  <app-tree-select *ngIf="optionsType === 'tree'"
                                                   [filter]="true"
                                                   selectionMode="single"
                                                   [ngModel]="value"
                                                   [options]="options!"
                                                   placeholder="Search"
                                                   (ngModelChange)="filter($event)">
                                  </app-tree-select>
                              </ng-template>
                          </p-columnFilter>
                          <p-columnFilter *ngSwitchCase="'text'" [field]="filterField ?? field"
                                          type="text"
                                          matchMode="contains" display="menu" class="ml-auto"
                                          [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                          placeholder="Search">
                          </p-columnFilter>
                          <p-columnFilter *ngSwitchCase="'numeric'" [field]="filterField ?? field"
                                          type="numeric"
                                          display="menu" class="ml-auto">
                          </p-columnFilter>
                          <p-columnFilter *ngSwitchCase="'date'" [field]="filterField ?? field"
                                          type="date"
                                          display="menu" class="ml-auto"
                                          matchMode="dateBefore"
                                          [matchModeOptions]="tableQuery.getMatchModeOptions('timestamp')">
                          </p-columnFilter>
                          <p-columnFilter *ngSwitchCase="'boolean'" [field]="filterField ?? field"
                                          type="boolean"
                                          display="menu" class="ml-auto"
                                          matchMode="is" [showApplyButton]="false">
                          </p-columnFilter>
                      </ng-container>
                  </div>
              </th>
          </ng-template>
      </ng-template>
  `
})
export class ColumnHeaderComponent implements OnChanges, OnInit {
  @Input() isSelector = false;
  @Input() field!: string;
  @Input() label!: string;
  @Input() frozen?: TFrozenColumn;
  @Input() fixedWidth?: number;
  @Input() style: { [prop: string]: any } = {};
  @Input() thStyleClass?: string;
  @Input() sortable = true;
  @Input() sortField?: string;
  @Input() filterType?: TColumnFilter;
  @Input() filterField?: string;
  @Input() optionsVirtualScroll = false;
  @Input() options?: any[];
  @Input() optionLabel?: string;
  @Input() optionValue?: string;
  @Input() optionsSelectedLabel?: string;
  @Input() optionsType: 'array' | 'tree' = 'array';
  @Input() optionIcon?: string;
  @ViewChild('template', {static: true}) template!: TemplateRef<any>;
  _style: { [prop: string]: any } = {};
  tableQuery = new TableQuery();

  constructor(private viewContainerRef: ViewContainerRef,
              @Optional() private columnsInfo: ColumnsInfoService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const widthStyle: any = {};
    if (this.isSelector) {
      widthStyle.maxWidth = widthStyle.width = widthStyle.minWidth = '45px';
    } else {
      if (this.fixedWidth != null) {
        /* widthStyle.maxWidth = widthStyle.width = */
        widthStyle.minWidth = this.fixedWidth + 'px';
      }
    }
    this._style = {
      ...widthStyle,
      ...this.style
    };

    if (!!!this.label) {
      this.label = startCase(this.field);
    }
    if (!!this.optionValue && !!!this.optionLabel) {
      this.optionLabel = this.optionValue;
    }
    if (!!!this.optionsSelectedLabel) {
      this.optionsSelectedLabel = `{0} ${plural(this.label)} selected`;
    }
    if (this.columnsInfo && this.fixedWidth != null) {
      this.columnsInfo.put(this.field, this.fixedWidth);
    }
  }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
