import { Component, Input } from '@angular/core';
import { AppResourceService } from '../../../../app.resource.service';
import { IIdentified, IPeriod } from '../../../../api/shared/app-domain/common';

@Component({
  selector: 'app-single-team-member-details',
  template: `
    <app-details-table [period]="period" [fetch]="fetch">
      <ng-template pTemplate="entryEntityTpl" let-rowData>
        <app-project-entry-entity [entryEntity]="rowData.entryEntity">
        </app-project-entry-entity>
      </ng-template>
    </app-details-table>
  `
})
export class SingleTeamMemberDetailsComponent {
  @Input() entity!: IIdentified;
  @Input() period!: IPeriod;

  constructor(private resource: AppResourceService) {
  }

  fetch = () => this.resource.getSingleTeamMemberDetails(this.entity.id, {
    period: this.period
  });

}
