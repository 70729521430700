import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IIdentified, IPeriod, IPeriodRequest, TApprovalsState} from '../../../api/shared/app-domain/common';
import {ISearchRequest, TQueryExpression} from '../../../api/shared/search-api';
import {LazyLoadEvent, MenuItem, TreeNode} from 'primeng/api';
import {onComponentPropChanged, ServiceLocator} from '../../../common/util/util';
import {TableQuery} from '../../../common/util/table-query';
import {EntityPropertyOptionsService} from '../../common/entity-property-options.service';
import {AppResourceService} from '../../../app.resource.service';
import {finalize} from 'rxjs';
import {IPeriodRange} from '../../../api/shared/app-domain/details';
import {TreeTable} from 'primeng/treetable';
import {TApproveAction} from '../common';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';
import {ITimeOff} from '../../../api/shared/app-domain/time';
import {EntityEditDialogComponent} from '../../common/entity-edit.dialog';
import {IEntityEditorParam} from '../../common/entity-editor.component';
import {filter, find, forEach, isEqual, remove, startCase} from 'lodash';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-time-offs-table',
  template: `
    <ng-template #toolbar>
      <div class="flex align-items-center justify-content-between w-full">
        <div class="flex align-items-center">
                <span class="p-input-icon-right">
                  <i class="pi pi-search"></i>
                  <input [(ngModel)]="search"
                         pInputText type="text" class="p-inputtext-sm" [style.width]="'300px'"
                         placeholder="Search"
                         appChangesDebounce (debounced)="load()"/>
                </span>
          <div class="ml-3 text-gray-700" style="width: 6rem;">{{selection.length}} selected</div>
          <p-menubar class="ml-2 mt-page-menu-bar" [model]="tableMenu"></p-menubar>
        </div>
        <div class="flex align-items-center">
          <button pButton pRipple type="button"
                  icon="pi pi-refresh" class="p-button-rounded p-button-text mr-2"
                  pTooltip="Reload" tooltipPosition="bottom" (click)="load()"></button>
        </div>
      </div>
    </ng-template>
    <app-entity-edit-dialog #editDialog width="600px" minWidth="600px" (apply)="onCreatedOrUpdated($event)">
      <ng-template pTemplate let-param>
        <app-time-off-request-editor [param]="param"></app-time-off-request-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-treeTable #tt [value]="data"
                   selectionMode="checkbox" [(selection)]="selection"
                   [lazy]="true"
                   (onLazyLoad)="load($any($event))"
                   [paginator]="true"
                   [rowsPerPageOptions]="[10, 25, 50, 100]"
                   [showCurrentPageReport]="true"
                   [currentPageReportTemplate]=
                     "'Showing {first} to {last} of {totalRecords} Team Members'"
                   [lazyLoadOnInit]="false"
                   [rows]="pageSize"
                   [totalRecords]="total"
                   (selectionChange)="onSelectionChange()"
                   [scrollable]="true"
                   frozenWidth="710px">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center">
            <p-treeTableHeaderCheckbox class="mr-4"></p-treeTableHeaderCheckbox>
            <ng-container *ngTemplateOutlet="toolbar"></ng-container>
          </div>
        </ng-template>
        <ng-template pTemplate="frozencolgroup">
          <colgroup>
            <col style="width: 300px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="colgroup">
          <colgroup>
            <col *ngIf="!isApprovalMode" style="width: 80px">
            <col style="width: 320px">
            <col style="width: 180px">
            <col style="width: 180px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="frozenheader">
          <tr style="height: 43px">
            <th ttSortableColumn="teamMemberName">
              <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center">
                  <div class="ml-2 mr-2">Team Member</div>
                  <p-treeTableSortIcon field="teamMemberName"></p-treeTableSortIcon>
                </div>
                <app-tt-filter field="teamMemberId" filterType="optionsIn"
                               [options]="$any(propertyOptions.getOptions('teamMembers') | async)"
                               optionLabel="name"
                               optionValue="id"
                               [optionsVirtualScroll]="true"></app-tt-filter>
              </div>
            </th>
            <th class="text-center">PTO Requested</th>
            <th class="text-center">PTO Total</th>
            <th class="text-center">Remaining</th>
            <th class="text-center mt-last-left-frozen-column">
              <div>Percent</div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="height: 43px">
            <th *ngIf="!isApprovalMode">State</th>
            <th>Details</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="frozenbody" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode"
              [style.background-color]="rowNode.node.expanded ? '#EFF6FF' : '#FFF'">
            <td pFrozenColumn>
              <div class="flex justify-content-between">
                <div class="flex align-items-center">
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                  <ng-container *ngIf="rowNode.node.type === 'teamMember'">
                    <app-team-member-entry-entity [entryEntity]="rowData" width="160px"
                                                  routerLink="/team-members"
                                                  [queryParams]="{tab: 'details'}"></app-team-member-entry-entity>
                  </ng-container>
                  <ng-container *ngIf="rowNode.node.type === 'timeOff'">
                    <p-treeTableCheckbox [value]="rowNode" class="mr-2"></p-treeTableCheckbox>
                    <div>{{rowData.startDate | date: 'MM/dd/yyyy'}}
                      - {{rowData.endDate | date: 'MM/dd/yyyy'}}</div>
                  </ng-container>
                </div>
                <div style="width: 50px; min-width: 50px"
                     class="flex pr-2 align-items-center justify-content-end">
                  <ng-container *ngIf="rowNode.node.type === 'timeOff'">
                    <app-approvals-state
                      [state]="rowData.state"></app-approvals-state>
                    <p-menu #menu [popup]="true" [model]="rowMenuItems" appendTo="body"
                            [style]="{width: 'auto', minWidth: '150px'}"
                            (onShow)="onShowRowMenu(rowNode.node, rowData)"></p-menu>
                    <button pButton pRipple type="button"
                            icon="pi pi-ellipsis-v"
                            class="p-button-rounded p-button-text p-button-sm w-2rem h-2rem"
                            (click)="currentMenuRowData = rowData; menu.toggle($event)">
                    </button>
                  </ng-container>
                </div>
              </div>
            </td>
            <td pFrozenColumn colspan="2" style="padding-top: 0; padding-bottom: 0; flex: 2">
              <div class="w-full">
                <div class="flex justify-content-between">
                  <div>{{rowData.totals.actual.time}}</div>
                  <div>of</div>
                  <div>{{rowData.totals.budget.time}}</div>
                </div>
                <div *ngIf="rowData.totals.actual.time != null && rowData.totals.budget.time">
                  <p-progressBar
                    [value]="rowData.totals.actual.time! / rowData.totals.budget.time! * 100"
                    [showValue]="false"></p-progressBar>
                </div>
              </div>
            </td>
            <td pFrozenColumn>
              <div class="flex flex-column text-right">
                <div>{{rowData.totals.delta.time}}</div>
              </div>
            </td>
            <td pFrozenColumn class="mt-last-left-frozen-column">
              <div class="w-full flex justify-content-end">
                <div class="mt-pct-box w-fit">
                  {{rowData.totals.deltaPct | percent}}
                </div>
              </div>
            </td>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-node="node" let-rowData="rowData">
          <tr>
            <td *ngIf="!isApprovalMode && rowNode.node.type === 'timeOff'" class="text-center">
              <app-approvals-state [state]="rowData.state"></app-approvals-state>
            </td>
            <td class="mt-overflow-ellipsis" appTooltipOnOverflow>{{rowData.details}}</td>
            <td>{{rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'}}</td>
            <td>{{rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'}}</td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  `,
  styles: [`
    :host ::ng-deep p-treeTable tbody tr {
      height: 46px;
    }

    :host ::ng-deep p-treeTable th {
      background: #F0F5F9 !important;
    }
  `]
})
export class TimeOffsTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() isApprovalMode = false;
  @Input() state!: TApprovalsState | null;
  @Input() period!: IPeriod;
  @Input() query: TQueryExpression | null = null;
  loading = false;
  data: Array<TreeNode<IIdentified>> = [];
  selection: any = [];
  pageSize = 10;
  total = 0;
  search!: string;

  periodRange?: IPeriodRange;

  currentMenuRowData!: any;
  rowMenuItems: Array<MenuItem> = [];
  tableMenu: Array<MenuItem> = [];
  tableQuery: TableQuery = new TableQuery();
  @ViewChild(TreeTable, {static: false}) tableComponent!: TreeTable;
  @ViewChild('editDialog', {read: EntityEditDialogComponent}) editDialog!: EntityEditDialogComponent;

  constructor(public propertyOptions: EntityPropertyOptionsService,
              private resource: AppResourceService) {
  }

  ngOnInit(): void {
    if (this.isApprovalMode) {
      this.tableMenu = [
        ...(this.state === 'Unsubmitted' ? [{
          id: 'submit' as TApproveAction,
          label: 'Submit', icon: 'pi pi-check',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'submit')
        }, {
          id: 'submitAndApprove' as TApproveAction,
          label: 'Submit and Approve', icon: 'pi pi-thumbs-up',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'submitAndApprove')
        }] : []),
        ...(this.state === 'Pending' ? [{
          id: 'approve' as TApproveAction,
          label: 'Approve', icon: 'pi pi-thumbs-up',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'approve')
        }, {
          id: 'reject' as TApproveAction,
          label: 'Reject', icon: 'pi pi-undo',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'reject')
        }, {
          id: 'deny' as TApproveAction,
          label: 'Deny', icon: 'pi pi-ban',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'deny')
        }] : []),
        ...(this.state === 'Approved' ? [{
          id: 'reject' as TApproveAction,
          label: 'Reject', icon: 'pi pi-undo',
          disabled: true,
          command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'reject')
        }] : []),
      ];
    }
    this.tableMenu.push({
      id: 'delete',
      label: 'Delete', icon: 'pi pi-trash',
      disabled: true,
      command: (event: any) => this.delete(event.originalEvent!.target!, this.selection)
    });
  }

  onShowRowMenu(node: TreeNode, rowData: ITimeOff): void {
    if (node.type === 'timeOff') {
      this.rowMenuItems = !this.isApprovalMode ? [] : [
        ...(rowData.state === 'Unsubmitted' ? [{
          id: 'submit',
          label: 'Submit', icon: 'pi pi-check',
          command: (event: any) => this.performAction(event.originalEvent!.target, [node], 'submit')
        }, {
          id: 'submitAndApprove',
          label: 'Submit and Approve', icon: 'pi pi-thumbs-up',
          command: (event: any) => this.performAction(event.originalEvent!.target, [node], 'submitAndApprove')
        }] : []),
        ...(rowData.state === 'Pending' ? [{
          id: 'approve',
          label: 'Approve', icon: 'pi pi-thumbs-up',
          command: (event: any) => this.performAction(event.originalEvent!.target, [node], 'approve')
        }, {
          id: 'reject',
          label: 'Reject', icon: 'pi pi-undo',
          command: (event: any) => this.performAction(event.originalEvent!.target, [node], 'reject')
        }, {
          id: 'deny',
          label: 'Deny', icon: 'pi pi-ban',
          command: (event: any) => this.performAction(event.originalEvent!.target, [node], 'deny')
        }] : []),
        ...(rowData.state === 'Approved' ? [{
          id: 'reject',
          label: 'Reject', icon: 'pi pi-undo',
          command: (event: any) => this.performAction(event.originalEvent!.target, [node], 'reject')
        }] : []),
      ];

      this.rowMenuItems = [...this.rowMenuItems,
        ...(rowData.state !== 'Denied' ? [{
          id: 'edit',
          label: 'Edit', icon: 'pi pi-pencil',
          command: (event: any) => this.editDialog.show({id: rowData.id})
        }] : []),
        {
          id: 'delete',
          label: 'Delete', icon: 'pi pi-trash',
          styleClass: 'mt-alert-menuitem',
          command: (event: any) => this.delete(event.originalEvent!.target, [node])
        }
      ]

    }
  }

  ngAfterViewInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    onComponentPropChanged(changes, ['period', 'query'], () => this.load())
  }

  load(event?: LazyLoadEvent): void {
    setTimeout(() => this.loading = true);

    const searchRequest: ISearchRequest = this.tableQuery.prepareQuery(
      event || this.tableComponent.createLazyLoadMetadata(), {
        pageSize: this.pageSize,
        search: this.search
      }
    );
    this.prepareSearchRequestQuery(searchRequest);
    searchRequest.param = {
      period: this.period,
    } as ISearchRequest<IPeriodRequest>;
    this.resource.searchTimeOffs(
      searchRequest
    ).pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((response) => {
      this.total = response.total;
      this.periodRange = response.extraData!;
      const data = response.results.map((tm) => {
        const node: TreeNode<IIdentified> = {
          key: tm.teamMemberId,
          type: 'teamMember',
          data: {...tm, ...{dataKey: tm.teamMemberId}},
          selectable: false,
          leaf: false,
          children: tm.timeOffs.map((to) => ({
            key: to.id,
            type: 'timeOff',
            leaf: true,
            selectable: true,
            data: {...to, ...{dataKey: to.id}, ...{teamMemberId: tm.teamMemberId}}
          }))
        };
        return node;
      });
      this.data = data;
      this.selection = [];
    });
  }

  prepareSearchRequestQuery(searchRequest: ISearchRequest): ISearchRequest {
    if (searchRequest.query || this.query) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: [
          ...(this.query?.predicates || []),
          ...((searchRequest.query as TQueryExpression)?.predicates || [])
        ]
      };
      searchRequest.query = query;
    }
    console.log('search request', searchRequest);
    return searchRequest;
  }

  onSelectionChange(): void {
    this.selection = filter(this.selection, {type: 'timeOff'});
    this.updateTableMenu();
  }

  updateTableMenu(): void {
    for (const mi of this.tableMenu) {
      mi.disabled = !this.selection.length;
    }
    // TODO: check each row
    this.tableMenu = [...this.tableMenu];
  }

  performAction(eventTarget: EventTarget, timeOffNodes: Array<TreeNode<ITimeOff>>, action: TApproveAction): void {
    if (!timeOffNodes?.length) {
      return;
    }
    const entities: Array<ITimeOff> = timeOffNodes.map((n) => n.data);

    const perform = () => {  // TODO use new api call for actions
      let state: TApprovalsState;
      switch (action) {
        case 'submit':
          state = 'Pending';
          break;
        case 'approve':
        case 'submitAndApprove':
          state = 'Approved';
          break;
        case 'reject':
          state = 'Unsubmitted';
          break;
        case 'deny':
          state = 'Denied';
          break;

      }
      this.patchField(entities, {state}, 'state');
    };
    const actionText = startCase(action);
    const itemsText = entities.length === 1
      ? `Time Off Request for ${new DatePipe('en-US').transform(entities[0].startDate, 'EEE, MMM dd, y')} - `
      + `${new DatePipe('en-US').transform(entities[0].endDate, 'EEE, MMM dd, y')}`
      : `${entities.length} selected Time Off Requests`

    ServiceLocator.confirm({
      target: eventTarget,
      key: 'g-popup',
      message: `Are you sure you want to ${actionText} ${itemsText}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        perform();
      }
    });
  }


  onCreatedOrUpdated(event: { param: IEntityEditorParam; data: ITimeOff }): void {
    this.replaceDataFor(event.data);
  }


  replaceDataFor(data: ITimeOff): void {
    forEach(this.data, (node) => {
      const timeOffNode = find(node.children, {key: data.id});
      if (!!timeOffNode) {
        timeOffNode.data = {...data, ...{dataKey: data.id}, ...{teamMemberId: node.key}}
        return false;
      }
      return true;
    });
  }

  patchField(entities: Array<ITimeOff>, patchData: Partial<ITimeOff>, field: keyof ITimeOff) {
    const data: Array<IIdentified & Partial<ITimeOff>> = filter(entities, (e) => !isEqual(e[field], patchData[field])).map((e) => ({
      id: e.id,
      ...patchData
    }));
    this.resource.patchTimeOffs(data)
      .subscribe((response) => {
        this.onUpdatedBySelection(response);
      });
  }

  protected onUpdatedBySelection(response: Array<ITimeOff>): void {
    this.selection = [];
    if (response.length === 1) {
      this.replaceDataFor(response[0]);
    } else {
      this.load();
    }
    ServiceLocator.message({
      severity: 'success',
      summary: 'Success',
      detail: `${response.length} record(s) have been updated`
    });
    this.updateTableMenu();
  }

  delete(eventTarget: EventTarget, timeOffNodes: Array<TreeNode<ITimeOff>>): void {
    const ids = timeOffNodes.map((n) => n.data.id);
    ServiceLocator.confirm({
      target: eventTarget,
      key: 'g-popup', // 'g-dialog',
      header: 'Delete Confirmation',
      message: `Are you sure you want to delete ${ids.length > 1 ? ids.length + ' ' : ''}record${ids.length > 1 ? 's' : ''}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.resource.deleteTimeOffs(ids)
          .subscribe(() => {
            ServiceLocator.message({
              severity: 'success',
              summary: 'Success',
              detail: `${ids.length} record(s) have been deleted`
            });
            this.selection = [];
            if (ids.length === 1) {
              forEach(this.data, (node) => {
                if (remove(node.children || [], {key: ids[0]}).length) {
                  if (!node.children?.length) {
                    remove(this.data, {key: node.key});
                  }
                  this.data = [...this.data];
                  return false;
                }
                return true;
              });
            } else {
              this.load();
            }
            this.updateTableMenu();
          });
      }
    });
  }
}
