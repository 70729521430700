import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress-avatar',
  template: `
    <p-knob [(ngModel)]="value" [size]="42" [strokeWidth]="10" [readonly]="true"
            [style]="{height: '42px'}"
            valueColor="#DF425A" rangeColor="#48D1CC"></p-knob>
    <div *ngIf="!avatar; else avatarImg"
         class="mt-no-avatar avatar-img flex align-items-center justify-content-center">
<!--      <i class="pi pi-user text-white"></i>-->
    </div>
    <ng-template #avatarImg>
      <img [src]="avatar"
           class="avatar-img">
    </ng-template>
    <div class="avatar-pct">{{value}}%</div>
  `,
  styles: [`
    :host {
      display: block;
      position: relative;
    }

    .avatar-img {
      position: absolute;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 26px;
      height: 26px;
    }

    .avatar-pct {
      position: absolute;
      left: 50%;
      color: var(--gray-600);
      bottom: -2px;
      font-size: 8px;
      transform: translateX(-50%);
    }
  `]
})
export class ProgressAvatarComponent {
  @Input() value!: number;
  @Input() avatar?: string;
}
