import {Component} from '@angular/core';
import {accessorProvider} from '../../common/util/util';
import {AbstractControlValueAccessorComponent} from '../../common/components/abstract-control-value-accessor.component';
import {EntityPropertyOptionsService, TOptionsTeamMember} from './entity-property-options.service';


@Component({
  selector: 'app-team-member-chooser',
  template: `
      <ng-template #tm let-item let-selected="selected">
          <div class="flex align-items-center ml-2">
              <app-avatar [(ngModel)]="item.avatar" [name]="item.name"
                          [editable]="false"
                          [size]="selected ? '16px' : '24px'"></app-avatar>
              <div class="flex align-items-baseline">
                  <div class="ml-2">{{item.name}}</div>
                  <div class="ml-1 text-xs text-gray-600">{{item.companyName}}</div>
              </div>
          </div>
      </ng-template>
      <span class="p-float-label">
          <p-dropdown [(ngModel)]="value"
                      [style]="{width: '300px'}"
                      [autoDisplayFirst]="false"
                      dataKey="id"
                      [options]="$any(entityOptions.getOptions('teamMembers') | async)"
                      [filter]="true"
                      filterBy="name"
                      optionLabel="name"
                      (ngModelChange)="onChange()">
            <ng-template pTemplate="selectedItem">
                <ng-container
                        *ngTemplateOutlet="tm; context: {$implicit: value, selected: true}"></ng-container>
                </ng-template>
            <ng-template let-item pTemplate="item">
                <ng-container
                        *ngTemplateOutlet="tm; context: {$implicit: item, selected: false}"></ng-container>
                </ng-template>
          </p-dropdown>
          <label>Team Member</label>
        </span>
  `,
  providers: [accessorProvider(TeamMemberChooserComponent), EntityPropertyOptionsService]
})
export class TeamMemberChooserComponent extends AbstractControlValueAccessorComponent<TOptionsTeamMember> {

  constructor(public entityOptions: EntityPropertyOptionsService) {
    super();
  }

  onChange(): void {
    this.onModelChange(this.value);
  }
}
