import {Component, Input} from '@angular/core';
import {IProject, TViewpoint} from '../../../../api/shared/app-domain/project';

@Component({
  selector: 'app-project-detail-overview',
  template: `
    <div class="grid">
      <div class="col-3" style="height: 700px">
        <app-project-balance-panel [viewpoint]="viewpoint" [project]="project"></app-project-balance-panel>
      </div>
      <div class="col-9 flex flex-column" style="height: 700px">
        <div class="grid" style="height: 250px">
          <div class="col-8">
            <div class="bg-white shadow-1 h-full" style="padding: 2rem; color: var(--gray-400)">
              TBD
            </div>
          </div>
          <div class="col-4 flex flex-column h-full">
            <div class="bg-white shadow-1 flex-1 mb-2" style="padding: 2rem; color: var(--gray-400)">TBD</div>
            <div class="bg-white shadow-1 flex-1 mt-2" style="padding: 2rem; color: var(--gray-400)">TBD</div>
          </div>
        </div>
        <div class="bg-white shadow-1 flex-1 mt-2 p-3 overflow-y-auto">
          <app-project-team-table [project]="project"></app-project-team-table>
        </div>
      </div>
    </div>
  `
})
export class ProjectDetailOverviewComponent {
  @Input() viewpoint: TViewpoint = 'money';
  @Input() project!: IProject;
}
