import {IIdentified, INamed, ITimestamped, TCommonStatus} from './common';

export const REFERRAL_API_ENDPOINTS = {
  count: '/api/referrals/count',
  referrals: '/api/referrals',
  referral: '/api/referrals/:id',
  export: '/api/referrals/export',
  import: '/api/referrals/import',
};

export interface IReferral extends IIdentified, INamed, ITimestamped {
  status: TCommonStatus;
}

export type TReferralSave = Omit<IReferral, 'id' | 'createdAt' | 'updatedAt'>;

