import {AfterViewInit, Directive, EventEmitter, Output} from '@angular/core';

@Directive({
  selector: '[appLifeCycle]'
})
export class LifeCycleDirective implements AfterViewInit {

  @Output() afterViewInit: EventEmitter<void> = new EventEmitter<void>();

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.afterViewInit.emit();
    });
  }
}

