import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {random, sumBy, values} from 'lodash';
import {IProject, TProjectBalances, TViewpoint} from '../../../../api/shared/app-domain/project';

@Component({
  selector: 'app-project-balance-panel',
  template: `
    <div class="flex flex-column align-items-center p-3 bg-white shadow-1 h-full">
      <ng-container>
        <div style="height: 237px; width: 237px">
          <app-speedometer-chart [data]="projectBalances[viewpoint].costStages.data"
                                 [total]="projectBalances[viewpoint].costStages.total">
            <ng-template pTemplate="center">
              <ng-container *ngIf="viewpoint === 'money'; else time">
                <div class="text-4xl font-medium">{{projectBalances[viewpoint].teamTime | currency:'USD':'symbol':'1.0-0' }}</div>
                <div class="mt-1 medium font-medium">Current Spend</div>
                <div class="mt-1 text-sm text-gray-600">as of {{projectBalances[viewpoint].asOf | date: 'MM/dd/yy hh:mm a'}}</div>
              </ng-container>
              <ng-template #time>
                <div class="text-4xl font-medium">{{projectBalances[viewpoint].teamTime}}</div>
                <div class="text-lg font-medium">/ {{projectBalances[viewpoint].budget}} <span class="text-gray-600 text-sm font-normal">Taas hours used</span>
                </div>
              </ng-template>
            </ng-template>
            <ng-template pTemplate="bottom">
              <ng-container *ngIf="viewpoint === 'money'; else time">
                <p-badge [value]="projectBalances[viewpoint].remaining.toString()" size="xlarge" severity="warning"
                         [style]="{background: '#D5DFE6', 'border-radius': '1.5rem'}"></p-badge>
              </ng-container>
              <ng-template #time>
                <img src="assets/images/speedometer.svg" width="46"/>
              </ng-template>
            </ng-template>
          </app-speedometer-chart>
        </div>
        <div *ngIf="viewpoint === 'money'" class="mt-1 text-sm text-gray-600" style="padding-bottom: 2px">
          days left in cycle
        </div>
        <div *ngIf="viewpoint === 'time'" class="mt-1 font-medium">
          {{projectBalances[viewpoint].remaining}} <span class="text-sm text-gray-600 font-normal">Taas hours remaining</span>
        </div>
      </ng-container>

      <ng-template #row let-label let-value="value" let-isRemaining="isRemaining">
        <div class="flex justify-content-between align-items-center w-full mb-3"
             [class.text-gray-600]="!isRemaining" [class.font-medium]="isRemaining">
          <div class="flex align-items-center">
            <span>{{label}}</span>
            <i *ngIf="label === 'Team Time' && projectBalances[viewpoint].hasUnsubmittedTimesheet"
               class="ml-2 mt-alert-icon pi pi-exclamation-circle"
               hint="A team member has an unsubmitted timesheet for the active period and the values here are subject to change.">
            </i>
          </div>
          <span>{{viewpoint === 'money' ? (value | currency:'USD':'symbol':'1.0-0') : (value + ' hrs')}}</span>
        </div>
      </ng-template>
      <div class="mt-1 w-full">
        <ng-container
          *ngTemplateOutlet="row; context: {$implicit: 'Budget', value: projectBalances[viewpoint].budget}"></ng-container>
        <ng-container
          *ngTemplateOutlet="row; context: {$implicit: 'Bonus', value: projectBalances[viewpoint].bonus}"></ng-container>
        <ng-container
          *ngTemplateOutlet="row; context: {$implicit: 'Team Time', value: projectBalances[viewpoint].teamTime}"></ng-container>
        <ng-container
          *ngTemplateOutlet="row; context: {$implicit: 'Billable Expenses', value: projectBalances[viewpoint].billableExpenses}"></ng-container>
        <ng-container
          *ngTemplateOutlet="row; context: {$implicit: 'Remaining Budget', value: projectBalances[viewpoint].remainingBudget, isRemaining: true}"></ng-container>

        <button pButton pRipple label="Get more hours" class="w-full p-button-sm mb-1"></button>
        <div class="text-gray-600 text-xs">
          Note: Access to manage billing account is role specific. Please contact your Company Administrator or Billing
          Contact.
        </div>
      </div>
      <a class="cursor-pointer no-underline text-primary mt-3"
         [href]="'/tbd'">Manage Alert Settings</a>
    </div>
  `
})
export class ProjectBalancePanelComponent implements OnChanges {
  @Input() viewpoint: TViewpoint = 'money';
  @Input() project!: IProject;

  projectBalances!: TProjectBalances;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['project']) {
      this.load();
    }
  }

  load(): void {
    this.projectBalances = {
      money: {
        budget: 10000,
        bonus: 1000,
        teamTime: 8500,
        billableExpenses: 150,
        remainingBudget: 1500,
        hasUnsubmittedTimesheet: true,
        asOf: new Date(),
        remaining: 15,
        costStages: {
          data: {
            strategy: random(500, 1000),
            design: random(1500, 2500),
            technology: random(1500, 2000),
            development: random(1500, 2000),
            production: random(500, 1500),
            marketing: random(500, 2000)
          },
          total: 0
        }
      },
      time: {
        budget: 2000,
        bonus: 200,
        teamTime: 1700,
        billableExpenses: 250,
        remainingBudget: 300,
        hasUnsubmittedTimesheet: true,
        asOf: new Date(),
        remaining: 200,
        costStages: {
          data: {
            strategy: random(10, 80),
            design: random(250, 350),
            technology: random(450, 500),
            development: random(150, 200),
            production: random(150, 250),
            marketing: random(50, 200)
          },
          total: 0
        }
      }
    };
    this.projectBalances.money.costStages.total = sumBy(values(this.projectBalances.money.costStages.data), (v) => v) + random(4000, 5000);
    this.projectBalances.time.costStages.total = sumBy(values(this.projectBalances.time.costStages.data), (v) => v) + random(400, 500);
  }
}
