import {Component, OnInit} from '@angular/core';
import {AppConfigService} from '../../../app-config.service';
import {IViewAsItem} from '../../../api/shared/user-api';
import {IPeriod} from '../../../api/shared/app-domain/common';
import {getCurrentPeriodValue} from '../../../common/components/period-chooser.component';
import {TQueryExpression} from '../../../api/shared/search-api';
import {EntityPropertyOptionsService} from '../../common/entity-property-options.service';

@Component({
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="flex align-items-baseline">
              <div class="mt-page-title">Time Entries</div>
              <div class="text-gray-700 ml-3">
                {{tmf.currentStatusesAsText}}
              </div>
            </div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
          </div>
        </div>
      </div>
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-7 flex align-items-center">
            <app-view-as-chooser #viewAsChooser [(ngModel)]="viewAsItem" (ngModelChange)="onChangeViewAs()">
            </app-view-as-chooser>
            <app-team-members-filter #tmf  [areFieldsEmbedded]="true" (applyFilter)="query = $event"></app-team-members-filter>
          </div>
          <div class="col-5 flex justify-content-end">
            <app-period-chooser [(ngModel)]="period" [unitFilter]="['Day', 'Week', 'Month', 'Quarter', 'Year', 'Custom']"></app-period-chooser>
          </div>
        </div>
      </div>
    </div>
    <app-view-as-alert [viewAsItem]="viewAsItem" [viewAsChooser]="viewAsChooser"></app-view-as-alert>
    <div class="mt-3">
      <div class="grid">
        <div class="col-3" *ngFor="let i of 4 | arrayOf">
          <div class="shadow-1"
               style="background: #FFF; border: 1px solid var(--surface-300); height: 10rem; font-size: 12px; padding: 2rem; color: var(--gray-500)">
            TBD
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 shadow-1 bg-white p-3 pb-2">
      <app-time-entries-table [query]="query" [period]="period"></app-time-entries-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService
  ]
})
export class TimeEntriesPageComponent  implements OnInit {
  period: IPeriod = getCurrentPeriodValue();
  viewAsItem: IViewAsItem | null = null;
  query: TQueryExpression | null = null;

  constructor(public appConfig: AppConfigService) {
    this.viewAsItem = this.appConfig.viewAsMe; // TODO: move to component
  }

  onChangeViewAs(): void {
  }


  ngOnInit(): void {
  }

}
