import {Injectable} from '@angular/core';
import {AppResourceService} from '../../app.resource.service';
import {finalize, map, Observable, of, share} from 'rxjs';
import {ESortDir} from '../../api/shared/search-api';
import {tap} from 'rxjs/operators';
import {COMPANY_TYPE, TCompanyType} from '../../api/shared/app-domain/company';
import {
  APPROVAL_STATES,
  AVAILABILITIES, COMMON_STATUSES,
  HUBSPOT_STAGES, IIdentified, INamed, MT_EMAIL_TYPE,
  NET_TERMS,
  PAYMENT_TERMS,
  US_STATES,
  WEEK_DAYS
} from '../../api/shared/app-domain/common';
import {TEAM_CANDIDATE_STATUS} from '../../api/shared/app-domain/team-candidate';
import {ITeamMember, TEAM_MEMBER_STATUS, TEAM_MEMBER_TYPE, TTeamMemberType} from '../../api/shared/app-domain/team';
import {TreeNode} from 'primeng/api';
import {getTeamMemberTypeIcon, teamMemberStatusChangedReasonsToTreeNodes} from '../team/team';
import {SKILL_STATUSES} from '../../api/shared/app-domain/skill';
import {IHubspotCandidate} from '../../api/shared/app-domain/hubspot';
import {IProject, TProjectType} from '../../api/shared/app-domain/project';
import { getProjectTypeIcon } from '../projects/projects';
import {IPaidTimeOff} from '../../api/shared/app-domain/settings';
import {CONTACT_TYPE} from '../../api/shared/app-domain/contact';

export const OPTIONS_ID_NAME_PAIR_FIELDS: Array<keyof (IIdentified & INamed)> = ['id', 'name'];
export type TOptionsIdNamePair = Pick<IIdentified & INamed, typeof OPTIONS_ID_NAME_PAIR_FIELDS[number]>

export const OPTIONS_TEAM_MEMBER_FIELDS: Array<keyof ITeamMember>
  = ['id', 'name', 'avatar', 'roleName', 'status', 'type', 'contractorCompanyId', 'contractorCompanyName', 'departmentId', 'departmentName', 'email'];
export type TOptionsTeamMember
  = Pick<ITeamMember, typeof OPTIONS_TEAM_MEMBER_FIELDS[number]>;

export const OPTIONS_PROJECT_FIELDS: Array<keyof IProject> = ['id', 'name', 'clientName', 'status', 'type'];
export type TOptionsProject = Pick<IProject, typeof OPTIONS_PROJECT_FIELDS[number]>

export const OPTIONS_PAID_TIME_OFF_FIELDS: Array<keyof IPaidTimeOff> = ['name', 'days'];
export type TOptionsPaidDayTimeOff = Pick<IPaidTimeOff, typeof OPTIONS_PAID_TIME_OFF_FIELDS[number]>;

export const ENTITY_PROPERTY_OPTIONS = [
  'userRoles',

  'countries',
  'timezones',
  'roles',
  'departments',
  'holidayCalendars',
  'expenseCategories',
  'paidTimeOffs',

  'searchSources',
  'hubspotCandidates',
  'hubspotContacts',

  'contactFullNames',

  'talentPartners',
  'clients',
  'referrals',

  'teamCandidateFullNames',
  'teamCandidateCities',

  'skillNames',
  'skillSilos',

  'teamMembers',
  'teamMemberFullNames',
  'teamMemberCities',
  'teamMemberCompanies',
  'teamMemberPaymentRecipients',
  'defaultWorkweeks',

  'projects',
  'projectClients',
  'projectAgencies'
] as const;

export type TEntityPropertyOption = typeof ENTITY_PROPERTY_OPTIONS[number];

@Injectable()
export class EntityPropertyOptionsService {
  private static _commonStatuses = [...COMMON_STATUSES];
  private static _availabilities = [...AVAILABILITIES];
  private static _teamCandidateStatuses = [...TEAM_CANDIDATE_STATUS];
  private static _stages = [...HUBSPOT_STAGES];
  private static _usStates = [...US_STATES];
  private static _skillsStatuses = [...SKILL_STATUSES];
  private static _teamMemberStatuses = [...TEAM_MEMBER_STATUS];
  private static _teamMemberTypes = [...TEAM_MEMBER_TYPE];
  private static _weekdays = [...WEEK_DAYS];
  private static _paymentTerms = [...PAYMENT_TERMS];
  private static _netTerms = [...NET_TERMS];
  private static _teamMemberStatusChangedReasonsAsTree: Array<TreeNode> = teamMemberStatusChangedReasonsToTreeNodes();
  private static _companyTypes = [...COMPANY_TYPE];
  private static _contactTypes = [...CONTACT_TYPE];
  private static _mtEmailTypes = [...MT_EMAIL_TYPE];
  private static _approvalStates = [...APPROVAL_STATES];

  readonly commonStatuses = EntityPropertyOptionsService._commonStatuses;
  readonly availabilities = EntityPropertyOptionsService._availabilities;
  readonly teamCandidateStatuses = EntityPropertyOptionsService._teamCandidateStatuses;
  readonly stages = EntityPropertyOptionsService._stages;
  readonly usStates = EntityPropertyOptionsService._usStates;
  readonly skillsStatuses = EntityPropertyOptionsService._skillsStatuses;
  readonly teamMemberStatuses = EntityPropertyOptionsService._teamMemberStatuses;
  readonly teamMemberTypes = EntityPropertyOptionsService._teamMemberTypes;
  readonly weekdays = EntityPropertyOptionsService._weekdays;
  readonly paymentTerms = EntityPropertyOptionsService._paymentTerms;
  readonly netTerms = EntityPropertyOptionsService._netTerms;
  readonly teamMemberStatusChangedReasonsAsTree = EntityPropertyOptionsService._teamMemberStatusChangedReasonsAsTree;
  readonly companyTypes = EntityPropertyOptionsService._companyTypes;
  readonly contactTypes = EntityPropertyOptionsService._contactTypes;
  readonly mtEmailTypes = EntityPropertyOptionsService._mtEmailTypes;
  readonly approvalStates = EntityPropertyOptionsService._approvalStates;

  private resources: { [option in TEntityPropertyOption]: Observable<Array<any>> };
  private cached: { [option in TEntityPropertyOption]?: { data: Array<any> | null, observable: Observable<Array<any>> | null } } = {};

  constructor(private resource: AppResourceService) {
    this.resources = {
      userRoles: this.resource.getUserRoles(),
      countries: this.resource.getCountries(),
      timezones: this.resource.getTimeZones(),
      roles: this.resource.getRoles({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),


      contactFullNames: this.resource.contacts.searchEntities({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['name']
      }).pipe(
        map((response) => response.results)
      ),


      departments: this.resource.getDepartments({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      holidayCalendars: this.resource.getHolidayCalendars({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      expenseCategories: this.resource.getExpenseCategories({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      paidTimeOffs: this.resource.searchPaidTimeOffs({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_PAID_TIME_OFF_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      searchSources: this.resource.getSearchSources(),
      hubspotCandidates: this.resource.getHubspotCandidates({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['id', 'name', 'pipeline', 'stage', 'url']
      }).pipe(
        map((response) => response.results)
      ),
      hubspotContacts: this.resource.getHubspotContacts({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['id', 'name', 'url']
      }).pipe(
        map((response) => response.results)
      ),
      teamCandidateFullNames: this.resource.getTeamCandidates({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['name']
      }).pipe(
        map((response) => response.results)
      ),
      teamCandidateCities: this.resource.getTeamCandidates({
        sort: [{field: 'city', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['city']
      }).pipe(
        map((response) => response.results)
      ),
      skillNames: this.resource.searchSkills({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['name']
      }).pipe(
        map((response) => response.results)
      ),
      skillSilos: this.resource.searchSkills({
        sort: [{field: 'silo', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['silo']
      }).pipe(
        map((response) => response.results)
      ),
      talentPartners: this.resource.companies.searchEntities({
        query: {
          logical: 'and',
          predicates: [
            {
              field: 'types',
              operator: 'contains',
              value: ['Talent Partner' as TCompanyType]
            }
          ]
        },
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      clients: this.resource.companies.searchEntities({
        query: {
          logical: 'and',
          predicates: [
            {
              field: 'types',
              operator: 'contains',
              value: ['Client' as TCompanyType]
            }
          ]
        },
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      referrals: this.resource.getReferrals({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      teamMembers: this.resource.getTeamMembers({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_TEAM_MEMBER_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      teamMemberFullNames: this.resource.getTeamMembers({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['name']
      }).pipe(
        map((response) => response.results)
      ),
      teamMemberCities: this.resource.getTeamMembers({
        sort: [{field: 'city', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: ['city']
      }).pipe(
        map((response) => response.results)
      ),
      teamMemberCompanies: this.resource.getTeamMemberCompanies({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      teamMemberPaymentRecipients: this.resource.getTeamMemberPaymentRecipients({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),

      defaultWorkweeks: this.resource.searchWorkweeks({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }).pipe(
        map((response) => response.results)
      ),
      projects: this.resource.getProjects({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_PROJECT_FIELDS
      }, 'all').pipe(
        map((response) => response.results)
      ),
      projectClients: this.resource.getProjects({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }, 'client').pipe(
        map((response) => response.results)
      ),
      projectAgencies: this.resource.getProjects({
        sort: [{field: 'name', dir: ESortDir.ASC}],
        isDistinct: true,
        fields: OPTIONS_ID_NAME_PAIR_FIELDS
      }, 'agency').pipe(
        map((response) => response.results)
      ),

    }
  }

  getOptions<T = any>(option: TEntityPropertyOption): Observable<Array<T>> {
    if (!this.cached[option]) {
      this.cached[option] = {data: null, observable: null};
    }
    const cached = this.cached[option]!;
    let observable: Observable<Array<any>>;
    if (cached.data) {
      observable = of(cached.data);
    } else if (cached.observable) {
      observable = cached.observable;
    } else {
      cached.observable = this.resources[option]
        .pipe(
          tap((response) => cached.data = response),
          share(),
          finalize(() => cached.observable = null)
        );
      observable = cached.observable;
    }
    return observable;
  }

  reset(...options: Array<TEntityPropertyOption>): void {
    for (const op of options) {
      delete this.cached[op];
    }
  }

  addOptionItem(option: TEntityPropertyOption, data: any): void {
    if (this.cached[option] && this.cached[option]!.data) {
      this.cached[option]!.data!.unshift(data);
    } else {
      this.reset(option);
    }
  }

  getTeamMemberTypeIcon(type: TTeamMemberType): string | null {
    return getTeamMemberTypeIcon(type);
  }

  getProjectTypeIcon(projectType: TProjectType): string | null {
    return getProjectTypeIcon(projectType);
  }

}
