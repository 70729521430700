import {Pipe, PipeTransform} from '@angular/core';
import {startCase} from 'lodash';

@Pipe({
  name: 'dbNormalizeName'
})
export class DbNormalizeNamePipe implements PipeTransform {
  transform(value: string | undefined | null): any {
    return startCase((value || '').replace('_m_2_m_', '_mtom_'))
      .replace('Mtom', 'M2M');
  }
}





