import {TProjectType} from '../../api/shared/app-domain/project';


export const PROJECT_TYPE_ICONS: {[prop in TProjectType]: string} = {
  'Monthly Flex Subscription': 'type-monthly-flex-subscription',
  'Monthly Fixed Subscription': 'type-monthly-fixed-subscription',
  'Service Block Recurring': 'type-service-block-recurring',
  'Service Block': 'type-service-block',
  'Monthly Pay as you Go': 'type-monthly-pay-as-you-go',
  'Milestones': 'type-milestones',
  'Dedicated Resource Fixed': 'type-dedicated-resource-fixed',
  'Non-Billable': 'type-non-billable',
  'Non-Billable Internal': 'type-non-billable-internal'
};


export function getProjectTypeIcon(type: TProjectType): string | null {
  return PROJECT_TYPE_ICONS[type] ? `assets/images/project/${PROJECT_TYPE_ICONS[type]}.svg` : null;
}

export const PROJECT_TABS = [
  'listing', 'details', 'planning'
] as const;
export type TProjectTab = typeof PROJECT_TABS[number];

