import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ICreateRelationshipParam, IAssociatedObjectType, RELATIONSHIPS } from './hs';
import {EHsRelationship, IHsObjectType} from '../../../api/shared/dev-tools/hs-api';

@Component({
  selector: 'app-hs-new-association',
  template: `
    <div>
      <span class="text-sm">From Object Type: </span>{{param.fromObjectType.label}}
    </div>
    <div class="flex mt-3">
      <span class="p-float-label">
        <p-dropdown [options]="relationships" [style]="{width: '130px'}"
                    [(ngModel)]="relationship"
                    [autoDisplayFirst]="false"
                    appendTo="body"
                    optionValue="relationship"
                    optionLabel="label">
        </p-dropdown>
        <label>Association</label>
      </span>
      <span class="p-float-label ml-1 flex-1">
        <p-dropdown [options]="param.toObjectTypes"
                    class="w-full"
                    styleClass="w-full"
                    [(ngModel)]="selectedType"
                    appendTo="body"
                    [autoDisplayFirst]="false"
                    dataKey="objectTypeId"
                    optionLabel="label"
                    [filter]="true"
                    filterBy="label">
                <ng-template pTemplate="selectedItem">
                  <span [class.custom-object-type]="selectedType?.isCustom">
                    {{selectedType?.label}}
                  </span>
                </ng-template>
                <ng-template let-type pTemplate="item">
                  <span [class.custom-object-type]="type.isCustom">
                    {{type.label}}
                  </span>
                </ng-template>
         </p-dropdown>
        <label>To Object Type</label>
      </span>
    </div>
    <div class="flex justify-content-end mt-3">
      <button class="p-button-sm" type="button" (click)="apply()" pButton icon="pi pi-check"
              [disabled]="!!!relationship || !!!selectedType"
              label="Create"></button>
      <button class="ml-1 p-button-sm" type="button" (click)="this.ref.close()" pButton icon="pi pi-times"
              label="Cancel"></button>
    </div>
  `,
  styles: [`
    .custom-object-type {
      color: var(--primary-color);
    }
  `]
})
export class HsNewAssociationComponent {
  selectedType: IHsObjectType | null = null;
  relationship: EHsRelationship | null = null;
  relationships = RELATIONSHIPS;
  param: ICreateRelationshipParam;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.param = config.data;
  }

  apply(): void {
    const toObjectType: IAssociatedObjectType = {
      ...this.param.fromObjectType,
      ...{
        association: {
          fromObjectTypeId: this.param.fromObjectType.objectTypeId,
          toObjectTypeId: this.selectedType!.objectTypeId,
          relationship: this.relationship!
        }
      }
    };
    this.ref.close(toObjectType);
  }
}
