import {Component} from '@angular/core';
import {AppResourceService} from '../../app.resource.service';
import {ISearchRequest, ISearchResponse} from '../../api/shared/search-api';
import {Observable} from 'rxjs';
import {PageableTableComponent} from '../../common/components/pageable-table.component';
import {TableQuery} from '../../common/util/table-query';
import {ConfirmationService} from 'primeng/api';
import {Router} from '@angular/router';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {ColumnsInfoService} from '../common/column-header.component';
import {IReferral, REFERRAL_API_ENDPOINTS} from '../../api/shared/app-domain/referral';

@Component({
  selector: 'app-referrals-table',
  template: `
    <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit']"></app-table-toolbar>
    <app-entity-edit-dialog #editDialog (apply)="onCreatedOrUpdated($event)">
      <ng-template pTemplate let-param>
        <app-referral-editor [param]="param"></app-referral-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Skills"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               scrollDirection="horizontal"
               [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="name" filterType="text">
            </app-column-header>
            <app-column-header field="status" filterType="optionsEquals" [fixedWidth]="120"
                               [options]="propertyOptions.commonStatuses">
            </app-column-header>
            <app-column-header field="createdAt" filterType="date" [fixedWidth]="180"></app-column-header>
            <app-column-header field="updatedAt" filterType="date" [fixedWidth]="180"></app-column-header>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="name" type="custom" [rowData]="rowData" frozen="lastLeft"
                            styleClass="justify-content-between py-0">
              <div class="flex align-items-center overflow-x-hidden">
                <div class="mt-overflow-ellipsis" appTooltipOnOverflow>
                  <a class="text-primary no-underline"
                     [routerLink]="['/tbd', 'referral-edit', rowData.id]">{{rowData.name}}</a>
                </div>
              </div>
              <app-row-menu-button [tableBase]="this" [rowData]="rowData"></app-row-menu-button>
            </app-table-cell>
            <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="createdAt"
                            [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="updatedAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService
  ]
})
export class ReferralsTableComponent extends PageableTableComponent<IReferral> {

  constructor(private resource: AppResourceService,
              private router: Router,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.api = {
      archiveEntities: this.resource.patchReferrals.bind(this.resource),
      deleteEntities: this.resource.deleteReferrals.bind(this.resource),
      exportEntities: this.resource.exportReferrals.bind(this.resource),
      importUrl: REFERRAL_API_ENDPOINTS.import
    };
  }

  protected override fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<IReferral>> {
    return this.resource.getReferrals(searchRequest);
  }

}
