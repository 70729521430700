import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IIdentified, ILens, IPeriod, TLens} from '../../../api/shared/app-domain/common';
import {AppResourceService} from '../../../app.resource.service';
import {DetailsTableComponent} from '../../common/column-expansion/details-table.component';
import {ServiceLocator} from '../../../common/util/util';
import {TProjectEntryEntity} from '../../../api/shared/app-domain/project';
import {ITimeEntry, ITimeSheetsValueData, TTimeEntrySave} from '../../../api/shared/app-domain/time';
import {DateTime} from 'luxon';
import {cloneDeep, differenceWith, find, findIndex, isEqual, omit, padStart, remove, sum} from 'lodash';
import {
  EntityPropertyOptionsService,
  TOptionsIdNamePair,
  TOptionsProject,
  TOptionsTeamMember
} from '../../common/entity-property-options.service';
import {ITeamMember} from '../../../api/shared/app-domain/team';
import {IDetailsCategoryRow} from '../../../api/shared/app-domain/details';
import {finalize, forkJoin, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AppConfigService} from '../../../app-config.service';


@Component({
  selector: 'app-day-time-entry-inline-editor',
  template: `
    <div class="formgrid grid edit-frame ml-2 mr-2 relative">
      <span *ngIf="timeEntry.id == null" class="text-primary text-xs absolute mt-0 ml-1">new</span>
      <div class="col-11 grid pl-4 pr-4 pt-4 pb-1">
        <div class="field col-4">
          <label>Department </label>
          <p-dropdown styleClass="w-full" [(ngModel)]="timeEntry.departmentId"
                      [autoDisplayFirst]="false" appendTo="body"
                      [filter]="true" filterBy="name" optionValue="id" optionLabel="name"
                      [options]="departments">
          </p-dropdown>
        </div>
        <div class="field col-1">
          <label>Is Billable</label>
          <p-checkbox [(ngModel)]="timeEntry.billable" [binary]="true" class="block mt-2 ml-4"></p-checkbox>
        </div>
        <div class="field col-4">
          <label>Link</label>
          <div class="p-input-icon-left w-full">
            <i class="cursor-pointer pi pi-external-link"
               [class.p-disabled]="!!!timeEntry.link!.url"
               (click)="openLink()"></i>
            <input pInputText class="w-full" [(ngModel)]="timeEntry.link!.url" nullable/>
          </div>
        </div>
        <div class="field col-1">
          <label>Time </label>
          <p-inputMask [(ngModel)]="timeEntry.actualTime" mask="99:99" slotChar="0" placeholder="00:00"
                       styleClass="time-editor"></p-inputMask>
        </div>
        <div class="field col-1">
          <label>Billable </label>
          <p-inputMask [(ngModel)]="timeEntry.billableTime" mask="99:99" slotChar="0" placeholder="00:00"
                       styleClass="time-editor"></p-inputMask>
        </div>
        <div class="field col-1">
          <label>Blended </label>
          <p-inputMask [(ngModel)]="timeEntry.blendedTime" mask="99:99" slotChar="0" placeholder="00:00"
                       styleClass="time-editor"></p-inputMask>
        </div>
        <div class="field col-12">
          <label>Notes </label>
          <textarea class="w-full" pInputTextarea [(ngModel)]="timeEntry.notes" nullable></textarea>
        </div>
      </div>
      <div class="col-1 flex align-items-center justify-content-end">
        <p-button icon="pi pi-check" styleClass="p-button-rounded p-button-text"
                  pTooltip="Done" tooltipPosition="bottom"
                  [disabled]="!canSave()"
                  (onClick)="onEditDone()"></p-button>
        <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-text"
                  pTooltip="Cancel" tooltipPosition="bottom"
                  (onClick)="editCancel.emit()"></p-button>
      </div>
    </div>
  `,
  styles: [`
    .edit-frame {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #BFDBFE;
      border-color: #3B82F6;
      border-radius: 4px;
      background: #F4F9FE;
    }

    :host ::ng-deep .p-inputtext.time-editor {
      width: 100%; // 50px;
      text-align: center !important;
    }

  `]
})
export class DayTimeEntryInlineEditor implements OnInit {
  @Input() teamMember!: TOptionsTeamMember;
  @Input() timeEntry!: ITimeEntry;
  @Input() timeEntries!: Array<ITimeEntry>;
  @Output() editDone: EventEmitter<ITimeEntry> = new EventEmitter<ITimeEntry>();
  @Output() editCancel: EventEmitter<void> = new EventEmitter<void>();
  departments: Array<TOptionsIdNamePair> = [];
  private origTimeEntry!: ITimeEntry;

  constructor(public propertyOptions: EntityPropertyOptionsService) {
  }

  ngOnInit(): void {
    if (this.timeEntry.link == null) {
      this.timeEntry.link = {url: null as any};
    }
    this.origTimeEntry = cloneDeep(this.timeEntry);
    if (this.timeEntry.id == null) {
      this.timeEntry.actualTime = '00:00';
      this.timeEntry.billableTime = '00:00';
      this.timeEntry.blendedTime = '00:00';
      this.timeEntry.billable = true;
    }

    this.propertyOptions.getOptions<TOptionsIdNamePair>('departments').subscribe((response) => {
      const used = this.timeEntries.map((te) => ({id: te.departmentId, name: te.departmentName}));
      remove(used, {id: this.timeEntry.departmentId});
      this.departments = differenceWith(
        response,
        used,
        (v1, v2) => v1.id === v2.id);
      if (this.timeEntry.id == null) {
        let selected = findIndex(this.departments, {id: this.teamMember.departmentId});
        if (selected === -1) {
          selected = 0;
        }
        this.timeEntry.departmentId = this.departments[selected].id;
        this.timeEntry.departmentName = this.departments[selected].name;
      }
    });
  }

  canSave(): boolean {
    if (isEqual(this.timeEntry, this.origTimeEntry)) {
      return false;
    }
    return true;
  }

  onEditDone(): void {
    if (!!!this.timeEntry.link?.url) {
      delete this.timeEntry.link;
    }
    const department = find(this.departments, {id: this.timeEntry.departmentId});
    this.timeEntry.departmentName = department!.name;
    this.editDone.emit(this.timeEntry);
  }

  openLink(): void {
    if (this.timeEntry.link?.url) {
      window.open(this.timeEntry.link!.url, '_blank');
    }
  }
}

@Component({
  selector: 'app-day-time-entries-dialog',
  template: `
    <p-dialog header="Header" [(visible)]="visible" [style]="{ width: '70vw' }"
              [modal]="true" [resizable]="false" [maximizable]="true" (onHide)="onClose()">
      <ng-template pTemplate="header">
        <div *ngIf="visible && teamMember" class="flex align-items-center justify-content-between w-full">
          <div class="flex align-items-center">
            <div class="p-dialog-title mr-3">Time Entries for:</div>
            <app-team-member-entry-entity class="mr-3" [entryEntity]="$any(teamMember)" width="auto"></app-team-member-entry-entity>
            <app-project-entry-entity *ngIf="!isNewProject; else newProject" [entryEntity]="projectEntity!"
                                      width="auto">
            </app-project-entry-entity>
            <ng-template #newProject>
              <span class="p-float-label">
                <p-dropdown #model="ngModel"
                            [(ngModel)]="projectEntity"
                            [required]="true"
                            [class.ng-dirty]="true"
                            [style]="{width: '300px'}"
                            [autoDisplayFirst]="false"
                            optionLabel="name"
                            [options]="projects"
                            [filter]="true"
                            filterBy="name"
                            [virtualScrollItemSize]="39"
                            [virtualScroll]="true" placeholder="Select Project">
                </p-dropdown>
                <label>Project</label>
                <app-control-error class="block absolute"
                                   [control]="model.control" [dirtyOnInit]="true"></app-control-error>
              </span>
            </ng-template>
          </div>
          <div class="flex align-items-center">
            <span class="mr-5 p-dialog-title">{{day}}</span>
          </div>
        </div>
      </ng-template>
      <app-spinnerizer [active]="loading"></app-spinnerizer>
      <div *ngIf="visible" class="border-top-1 border-gray-300">
        <div *ngFor="let timeEntry of timeEntries; let i = index"
             class="grid entry">
          <div *ngIf="!!editTimeEntry && editTimeEntry.id === timeEntry.id; else view"
               class="col-12 ng-star-inserted p-0 m-0">
            <app-day-time-entry-inline-editor [teamMember]="teamMember"
                                              [timeEntry]="editTimeEntry"
                                              [timeEntries]="timeEntries"
                                              (editCancel)="onEditCancel()"
                                              (editDone)="onEditDone($event)"></app-day-time-entry-inline-editor>
          </div>
          <ng-template #view>
            <div class="col-7 flex align-items-center">
              <div>
                <div class="mt-overflow-ellipsis font-medium" appTooltipOnOverflow>
                  {{timeEntry.departmentName}}
                </div>
                <div *ngIf="!!timeEntry.link" class="mt-link mt-1">
                  <a [href]="timeEntry.link.url" class="mt-link inline-flex align-items-center"
                     target="_blank">
                    <i *ngIf="timeEntry.link.icon == null" class="pi pi-link"></i>
                    <img *ngIf="timeEntry.link.icon != null" [src]="timeEntry.link.icon" width="12">
                    <span class="ml-2">{{linkLabel(timeEntry)}}</span>
                  </a>
                </div>
                <div *ngIf="!!timeEntry.notes" class="text-justify text-gray-600 mt-1">
                  {{timeEntry.notes}}
                </div>
              </div>
            </div>
            <div class="col-4 flex align-items-center justify-content-center">
              <span class="mt-pct-box font-medium">{{timeEntry.actualTime}}</span>
              <span class="ml-2 text-gray-600 text-sm mr-2">Billable:</span>
              <span class="mt-pct-box font-medium">{{timeEntry.billableTime}}</span>
              <span class="ml-2 text-gray-600 text-sm mr-2">Blended:</span>
              <span class="mt-pct-box font-medium">{{timeEntry.blendedTime}}</span>
            </div>
            <div class="col-1 flex align-items-center justify-content-end pr-0">
              <i class="pi mr-3 text-gray-700" [class]="timeEntry.billable ? 'pi-check-circle' : 'pi-times-circle'"
                 [pTooltip]="timeEntry.billable ? 'Billable' : 'Non-Billable'"
                 tooltipPosition="bottom"></i>
              <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-text"
                        pTooltip="Edit Time Entry" tooltipPosition="bottom"
                        [disabled]="!!projectEntity && !!editTimeEntry"
                        (onClick)="edit(timeEntry)"></p-button>
              <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-text"
                        pTooltip="Delete Time Entry" tooltipPosition="bottom"
                        [disabled]="!canRemove()"
                        (onClick)="remove($event, timeEntry)"></p-button>
            </div>
          </ng-template>
        </div>
        <div *ngIf="visible" class="border-top-1 border-gray-300">
          <div class="grid mt-4 -ml-1">
            <div class="col-7 flex align-items-center justify-content-end font-medium">
              Total:
            </div>
            <div class="col-4 flex align-items-center justify-content-center  pr-3">
              <span class="mt-pct-box font-medium">{{total('actualTime')}}</span>
              <span class="ml-2 text-gray-600 text-sm mr-2">Billable:</span>
              <span class="mt-pct-box font-medium">{{total('billableTime')}}</span>
              <span class="ml-2 text-gray-600 text-sm mr-2">Blended:</span>
              <span class="mt-pct-box font-medium">{{total('blendedTime')}}</span>
            </div>
            <div class="col-1 flex justify-content-end">
              <p-button icon="pi pi-plus" [disabled]="!canAdd()"
                        pTooltip="Add Time Entry" tooltipPosition="bottom"
                        (onClick)="edit(null)" styleClass="p-button-rounded p-button-secondary"></p-button>
            </div>
          </div>
        </div>
      </div>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" [disabled]="!canApply()"
                  (onClick)="apply()" label="Apply" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-times" (onClick)="visible = false" label="Cancel"
                  styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>
  `,
  styles: [`
    .entry {
      padding: 1rem 0;
      margin: 0;
    }

    .entry + .entry {
      border-top: 1px solid var(--gray-200);
    }
  `]
})
export class DayTimeEntriesDialogComponent implements OnInit {
  @Input() teamMember!: TOptionsTeamMember;
  @Output() dataChanged: EventEmitter<void> = new EventEmitter<void>();
  loading = false;
  visible = false;
  projectEntity: TProjectEntryEntity | null = null;
  timeEntries: Array<ITimeEntry> = [];
  editTimeEntry: ITimeEntry | null = null;
  departments: Array<IIdentified> = [];
  isEdited = false;
  newId = 0;
  projects: Array<TOptionsProject> = [];
  isNewProject = false;
  period!: IPeriod;

  constructor(public propertyOptions: EntityPropertyOptionsService,
              private resource: AppResourceService) {
  }

  ngOnInit(): void {
    this.propertyOptions.getOptions<IIdentified>('departments').subscribe((response) => {
      this.departments = response;
    });
  }

  isNewEntry(timeEntry: TTimeEntrySave): boolean {
    return !isNaN(timeEntry.id as any);
  }

  fetchCategoryRowAndToggle(teamMemberId: string, projectId: string, date: Date): void {
    if (!this.visible) {
      this.period = {
        start: date,
        end: date, // TODO: calc end by @date@
        type: 'Day'
      }
      this.timeEntries = [];
      this.isEdited = false;
      this.editTimeEntry = null;
      this.visible = true;
      this.loading = true;
      forkJoin([
        this.resource.getTeamMember(teamMemberId),
        this.resource.getDayProjectCategoryRow(teamMemberId, projectId, {
          period: this.period
        })
      ]).pipe(
        finalize(() => {
          this.loading = false;
        }),
        catchError((error) => {
          this.visible = false;
          return throwError(error);
        }),
      ).subscribe((responses) => {
          this.teamMember = responses[0];
          this.projectEntity = responses[1].entryEntity!;
          this.timeEntries = [...responses[1].values[0].data!.timeEntries];
          if (!this.timeEntries.length) {
            this.edit(null);
          }
        });
    } else {
      this.visible = false;
    }
  }

  toggle(
    rowData: IDetailsCategoryRow<TProjectEntryEntity, ITimeSheetsValueData> | null,
    periodIndex: number,
    date: Date
  ): void {
    if (!this.visible) {
      this.period = {
        start: date,
        end: date, // TODO: calc end by @date@
        type: 'Day'
      }
      this.isEdited = false;
      this.editTimeEntry = null;
      if (rowData != null) {
        this.projectEntity = rowData.entryEntity!;
        this.timeEntries = [...rowData.values[periodIndex].data!.timeEntries];
      } else {
        this.isNewProject = true;
        this.projectEntity = null;
        this.timeEntries = [];
        this.propertyOptions.getOptions<TOptionsProject>('projects').subscribe((response) => {
          this.projects = response;
          // TODO: exclude projects that are already in sheets
        });
      }
      if (!this.timeEntries.length) {
        this.edit(null);
      }
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  linkLabel(timeEntry: ITimeEntry): string {
    return timeEntry.link!.label || timeEntry.link!.url.split('://')[1];
  }

  get day(): string {
    return this.period ? DateTime.fromJSDate(this.period.start).toFormat('EEE dd, MMM') : '';
  }

  total(timeEntryField: keyof Pick<ITimeEntry, 'actualTime' | 'billableTime' | 'blendedTime'>): string {
    const minutes = sum(this.timeEntries.map((v) => {
      if (v[timeEntryField]) {
        const [h, m] = v[timeEntryField]!.split(':');
        return +h * 60 + (+m);
      } else {
        return 0;
      }
    }));
    return `
      ${padStart((~~(minutes / 60)).toString(), 2, '0')}:${padStart((minutes % 60).toString(), 2, '0')}
    `;
  }


  canAdd(): boolean {
    return this.editTimeEntry == null && this.timeEntries.length < this.departments.length;
  }

  edit(timeEntry: ITimeEntry | null): void {
    if (!!timeEntry) {
      this.editTimeEntry = cloneDeep(timeEntry);
    } else {
      this.editTimeEntry = {
        id: null,
      } as any;
      this.timeEntries.push(cloneDeep(this.editTimeEntry) as any);
    }
  }

  onEditDone(timeEntry: ITimeEntry): void {
    let index: number;
    if (timeEntry.id == null) {
      timeEntry.id = (++this.newId).toString();
      index = this.timeEntries.length - 1;
    } else {
      index = findIndex(this.timeEntries, {id: timeEntry.id});
    }
    this.timeEntries[index] = timeEntry;
    this.editTimeEntry = null;
    this.timeEntries = [...this.timeEntries];
    this.isEdited = true;
  }

  onEditCancel(): void {
    if (this.editTimeEntry!.id == null) {
      this.timeEntries.pop();
    }
    this.editTimeEntry = null;
  }

  onClose(): void {
    if (this.isEdited) {
      this.dataChanged.emit();
    }
  }

  canRemove(): boolean {
    return this.editTimeEntry == null;
  }

  remove(ev: any, timeEntry: ITimeEntry): void {
    const onRemove = () => {
      remove(this.timeEntries, {id: timeEntry.id});
      this.isEdited = true;
    }
    if (!this.isNewEntry(timeEntry)) {
      ServiceLocator.confirm({
        target: ev.target,
        key: 'g-popup',
        header: 'Delete Confirmation',
        message: `Are you sure you want to delete Time Entry "${timeEntry.departmentName}"?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          onRemove();
        }
      });
    } else {
      onRemove();
    }
  }

  canApply(): boolean {
    return this.projectEntity != null && this.editTimeEntry == null && this.isEdited;
  }

  apply(): void {
    const timeEntriesToSave: Array<TTimeEntrySave> = this.timeEntries.map((te) => {
      const timeEntryToSave = omit(te, ['departmentName']);
      if (this.isNewEntry(timeEntryToSave)) {
        timeEntryToSave.id = null as any;
      }
      return timeEntryToSave;
    });
    this.resource.updateDayTimeEntries(this.teamMember.id, this.projectEntity!.id, {
      period: this.period,
      data: {
        timeEntries: timeEntriesToSave
      }
    }).subscribe((response) => {
      ServiceLocator.message({
        severity: 'success',
        summary: 'Success',
        detail: `Time Entries for "${this.projectEntity!.name}" has been saved`
      });
      this.visible = false;
    });
  }
}

@Component({
  selector: 'app-day-time-sheets-details',
  template: `
    <app-day-time-entries-dialog #te [teamMember]="teamMember" (dataChanged)="table.load()">
    </app-day-time-entries-dialog>
    <div class="mt-2">
      <span *ngIf="!!workWeek" class="text-lg font-medium">Week: <span class="text-gray-700">{{workWeek}}</span></span>
    </div>
    <app-details-table #dt [period]="period" [fetch]="fetch" entryEntityName="Project"
                       [showPopupColumnByDefault]="true"
                       [showGroupHeader]="false"
                       [extraFieldsColumns]="[{header: 'Time Entries', field: 'timeEntries', columnClass: 'extra-field-class'}]"
                       leftColWidth="400px"
                       (loaded)="workWeek = $event.periodRange.groups[0].group"
                       (addEntryEntity)="te.toggle(null, 0, dt.periodRange.periods[0].start)"
                       (editEntryEntity)="te.toggle($event.rowData, 0, dt.periodRange.periods[0].start)"
                       (cellClick)="
                            $event.column.extraDataField != null && $event.node.type !== 'section'
                            ? te.toggle($event.rowData, 0, dt.periodRange.periods[0].start)
                            : null
                        ">
      <ng-template pTemplate="entryEntityTpl" let-rowData>
        <app-project-entry-entity [entryEntity]="rowData.entryEntity"
                                  routerLink="/projects" [queryParams]="{tab: 'details'}"
                                  width="270px">
        </app-project-entry-entity>
      </ng-template>
      <ng-template pTemplate="extraFieldsTpl" let-field let-node="node" let-rowData="rowData">
        <ng-container *ngIf="node.type !== 'section'">
          <div class="cursor-pointer">
            <div *ngFor="let timeEntry of rowData.values[0].data.timeEntries || []" class="mt-time-entry">
              <span>{{timeEntry.departmentName}}</span>
              <span class="ml-2 text-gray-700 text-sm">{{timeEntry.actualTime}}</span>
              <span class="ml-2 text-gray-700 text-sm pi"
                    [class]="timeEntry.billable ? 'pi-check-circle' : 'pi-times-circle'"></span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </app-details-table>
    <div class="grid mt-2">
      <div class="col-6">
        <div class="flex w-full align-items-center">
          <ng-container *ngIf="!!dt.extraRowData">
            <button pButton pRipple type="button" label="Prefill from Prior Day"
                    class="p-button-outlined mr-2" [disabled]="!dt.extraRowData![0].canPrefillFromPrior"
                    (click)="tbd()"></button>
            <button pButton pRipple type="button" label="Prefill from Planning"
                    class="p-button-outlined" [disabled]="!dt.extraRowData![0].canPrefillFromPlanning"
                    (click)="tbd()"></button>
          </ng-container>
        </div>
      </div>
      <div class="col-6">
        <div class="flex w-full align-items-center justify-content-end">
          <ng-container *ngIf="!!dt.extraRowData">
            <span *ngIf="dt.extraRowData![0].unsubmitted !== '00:00'"
                  class="pi pi-circle-fill text-xs p-error mr-2"></span>
            <span *ngIf="!!dt.extraRowData![0].unsubmitted" class="text-lg font-medium">Unsubmitted: <span
              class="text-gray-800">{{dt.extraRowData![0].unsubmitted}}</span></span>
            <span class="ml-4 text-lg font-medium mr-4">Total: <span
              class="text-gray-800">{{dt.extraRowData![0].total}}</span></span>
            <button *ngIf="config.timeSubmitBasis === 'day'" pButton pRipple type="button" label="Submit Day for Approval"
                    [disabled]="!dt.extraRowData![0].canSubmit"
                    (click)="tbd()"></button>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styles: [`
    :host ::ng-deep .extra-field-class {
      width: 55%;
      @media (max-width: 1600px) {
        width: 50%;
      }
      @media (max-width: 1400px) {
        width: 45%;
      }
    }

    .mt-time-entry {
      display: inline-flex;
      align-items: center;
      background-color: rgba(63, 81, 181, 0.12);
      font-size: 1rem;
      white-space: nowrap;
      border-radius: 20px;
      padding: 3px 9px;
      margin-top: 2px;
      margin-bottom: 2px;
      text-decoration: none;
    }

    .mt-time-entry:not(:last-child) {
      margin-right: 5px;
    }

  `]
})
export class DayTimeSheetsDetails implements OnChanges {
  @Input() teamMember!: ITeamMember;
  @Input() period!: IPeriod;
  @Input() lens!: ILens;
  @ViewChild(DetailsTableComponent) table!: DetailsTableComponent;
  workWeek: string | null = null;

  constructor(private resource: AppResourceService, public config: AppConfigService) {
  }

  fetch = () => this.resource.getDayTimeSheets(this.teamMember.id, {
    period: this.period,
    data: {
      lens: this.lens.id
    }
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['teamMember'] && !changes['teamMember'].firstChange
      || changes['lens'] && !changes['lens'].firstChange) { // TODO: move lens to app-details-table ?
      this.table.load();
    }
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }
}
