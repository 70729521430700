import {Component} from '@angular/core';
import {EntityEditorComponent} from './entity-editor.component';
import {IIdentified, IMAGE_API_ENDPOINTS, ITeamParticipant} from '../../api/shared/app-domain/common';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {TControlsOf} from '../../common/types';
import {getUrlDomain, urlValidator} from '../../common/util/util';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {find} from 'lodash';
import {ISearchSource} from '../../api/shared/app-domain/settings';
import {EntityPropertyOptionsService} from './entity-property-options.service';
import {ITeamCandidate} from '../../api/shared/app-domain/team-candidate';

export type TTeamParticipantSave = Omit<ITeamParticipant,
  'id' | 'createdAt' | 'updatedAt' | 'name' |
  'roleName' | 'departmentName' | 'talentPartnerName' | 'searchSourceUrl' |
  'referralName' | 'isDraft'>;

@Component({
  template: ''
})
export abstract class TeamParticipantEditorComponent<S extends TTeamParticipantSave, T extends S & IIdentified> extends EntityEditorComponent<S, T> {
  imageServiceUrl = IMAGE_API_ENDPOINTS.upload;
  searchSources: Array<ISearchSource> = [];

  protected constructor(fb: FormBuilder, public propertyOptions: EntityPropertyOptionsService) {
    super(fb);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.propertyOptions.getOptions('searchSources')
      .subscribe((response) => this.searchSources = response);
  }

  override getName(): string | null {
    return (this.entity as unknown as ITeamParticipant)?.name ?? '';
  }


  protected createSharedControls(from?: ITeamCandidate): TControlsOf<TTeamParticipantSave> {
    return {
      firstName: new FormControl<string>(from?.firstName ?? this.entity.firstName, [Validators.required]),
      lastName: new FormControl(from?.lastName ?? this.entity.lastName, [Validators.required]),
      avatar: new FormControl(from?.avatar ?? this.entity.avatar, []),
      skills: new FormControl(from?.skills ?? this.entity.skills, [Validators.required]),
      birthday: new FormControl(from?.birthday ?? this.entity.birthday, []),
      costRateDefault: new FormControl(from?.costRateDefault ?? this.entity.costRateDefault, []),
      roleId: new FormControl(from?.roleId ?? this.entity.roleId, [Validators.required]),
      departmentId: new FormControl(from?.departmentId ?? this.entity.departmentId, [Validators.required]),
      country: new FormControl(from?.country ?? this.entity.country, [Validators.required]),
      state: new FormControl(from?.state ?? this.entity.state, []),
      city: new FormControl(from?.city ?? this.entity.city, [Validators.required]),
      timezone: new FormControl(from?.timezone ?? this.entity.timezone, [Validators.required]),
      phone: new FormControl(from?.phone ?? this.entity.phone, []),
      email: new FormControl(from?.email ?? this.entity.email, [Validators.email, Validators.required]),
      talentPartnerId: new FormControl(from?.talentPartnerId ?? this.entity.talentPartnerId, []),
      searchSource: new FormControl(from?.searchSource ?? this.entity.searchSource, []),
      primarySourceUrl: new FormControl(from?.primarySourceUrl ?? this.entity.primarySourceUrl,
        [Validators.required, urlValidator]),
      searchSourceKeyword: new FormControl(from?.searchSourceKeyword ?? this.entity.searchSourceKeyword, []),
      referralId: new FormControl(from?.referralId ?? this.entity.referralId, []),
    };
  }

  protected addSharedControlsProcessing(subscriptions: SubscriptionsService): void {
    subscriptions
      .add(this.form!.controls.country!.valueChanges.subscribe((val) => {
        this.processCountry(val);
      }))
      .add(this.form!.controls.searchSource!.valueChanges.subscribe((val) => {
        this.processSearchSource(val);
      }))
      .add(this.form!.controls.primarySourceUrl!.valueChanges.subscribe((val) => {
        this.processPrimarySourceUrl(val);
      }));
    this.processCountry(this.entity.country, true);
  }

  private processCountry(country: string | null | undefined, initial = false): void {
    if (country === 'United States') {
      this.form!.controls.state!.setValidators([Validators.required]);
    } else {
      this.form!.controls.state!.clearValidators();
    }
    if (!initial) {
      this.setValueAndMark('state', null);
      this.setValueAndMark('city', null);
      this.form!.controls.state!.markAsTouched();
      this.form!.controls.state!.updateValueAndValidity();
    }

  }

  processSearchSource(name?: string | null): void {
    if (name) {
      const searchSource = find(this.searchSources, {name});
      const primarySourceUrl = this.getValue('primarySourceUrl');
      if (getUrlDomain(primarySourceUrl) !== searchSource?.urlDomainLinkage) {
        this.setValueAndMark('primarySourceUrl', searchSource?.url);
      }
    }
  }

  processPrimarySourceUrl(url?: string | null): void {
    if (url) {
      const domain = getUrlDomain(url);
      if (!!domain) {
        const searchSource = find(this.searchSources, {urlDomainLinkage: domain});
        if (searchSource?.urlDomainLinkage === domain) {
          this.setValueAndMark('searchSource', searchSource?.name);
        }
      }
    }
  }

}
