import {Component, OnInit} from '@angular/core';
import {AppResourceService} from '../../../app.resource.service';
import {ITeamMember} from '../../../api/shared/app-domain/team';
import {EntityPropertyOptionsService, TOptionsTeamMember} from '../../common/entity-property-options.service';
import {getTeamMemberTypeIcon} from '../../team/team';
import {TreeNode} from 'primeng/api';
import {TIMESHEETS_MODE_LENSES} from '../../../api/shared/app-domain/time';
import {modeLensesToTreeNodes} from '../../common/util';

@Component({
  template: `
    <app-single-entity-details [entity]="teamMember"
                               [showViewAs]="false" [showSchedule]="false" [showLens]="true" [lensOptions]="lensOptions"
                               initialPeriodType="Week"
                               [periodUnitFilter]="['Day', 'Week']" [tabMenuItems]="null">
      <ng-template pTemplate="bottomLeftHeaderTpl" let-entity>
        <app-team-member-chooser [(ngModel)]="teamMember"></app-team-member-chooser>
      </ng-template>
      <ng-template pTemplate="entityTpl" let-entity>
        <div class="flex align-items-center">
          <div class="mt-page-title mr-4">Time Sheets</div>
          <ng-container *ngIf="teamMember">
            <app-avatar [(ngModel)]="entity.avatar" [name]="entity.name!" size="3rem"></app-avatar>
            <div class="ml-3">
              <div class="flex align-items-center">
                <div class="text-lg mr-2">{{entity.name}}</div>
                <img [src]="getTeamMemberTypeIcon(entity)" height="24" width="24"
                     [pTooltip]="entity.type || ''"/>
              </div>
              <div class="text-sm text-gray-700">{{entity.roleName}} | {{entity.status}}
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <ng-template pTemplate="detailsTpl" let-entity let-tab="tab" let-period="period" let-lens="lens">
        <app-day-time-sheets-details *ngIf="period.type === 'Day'"
                                     [teamMember]="teamMember!" [period]="period" [lens]="lens"></app-day-time-sheets-details>
        <app-week-time-sheets-details *ngIf="period.type === 'Week'"
                                      [teamMember]="teamMember!" [period]="period" [lens]="lens"></app-week-time-sheets-details>
      </ng-template>
    </app-single-entity-details>
  `,
  providers: [
    EntityPropertyOptionsService
  ]
})
export class TimeSheetsPageComponent implements OnInit {
  teamMember: TOptionsTeamMember | null = null;
  lensOptions: Array<TreeNode> = modeLensesToTreeNodes(TIMESHEETS_MODE_LENSES);

  constructor(public resource: AppResourceService) {
  }

  ngOnInit(): void {
  }

  getTeamMemberTypeIcon(entity: ITeamMember): string | null {
    return getTeamMemberTypeIcon(entity.type);
  }

}
