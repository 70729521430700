import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Table} from 'primeng/table';
import {IDictionary} from '../../../../common/types';
import {ConfirmationService, FilterMetadata, LazyLoadEvent, MessageService, TableState} from 'primeng/api';
import {AppResourceService} from '../../../../app.resource.service';
import {TableQuery} from '../../../../common/util/table-query';
import {cloneDeep, filter, find, findIndex, isArray, isEqual, keyBy, keys, omit, pickBy, remove, values} from 'lodash';
import {finalize, forkJoin, Observable, Subscription, throwError} from 'rxjs';
import {IDbColumn, IDbSchema, IDbTable} from '../../../../api/shared/dev-tools/db-api';
import {ISearchRequest, ISearchResponse} from '../../../../api/shared/search-api';
import {DbStorage, ITableRow} from '../db';
import {catchError} from 'rxjs/operators';
import {DbColumnFilter} from './db-column-filter.component';
import {DbConstraintsUtil} from '../db-constraints-util';
import {DbNormalizeNamePipe} from '../db-normalize-name.pipe';
import {DbBrowserService} from './db-browser.service';
import {RenderThisDirective} from '../../../../common/components/recreate-view.directive';
import {SubscriptionsService} from '../../../../common/services/subscriptions.service';

interface IChildToParentInfo {
  referencingTableName: string;
  referencingColumnName: string;
  referencingFKValue?: any;
  referencedColumnName: string;
  canApplyFK: boolean;
  onApplyFK: (value: any) => void;
}

interface IParentToChildInfo {
  referencedTable: IDbTable;
  referencedPKValue: any;
  referencedViewColumnsData: Array<string>;
}

const NEW_RECORD_KEY = Math.random().toString(36).slice(2);

@Component({
  selector: 'app-db-table',
  template: `
    <!--  Referenced (Parent) Table -->
    <p-dialog [(visible)]="referencedTableDialog.show"
              [closable]="true"
              [modal]="true" [style]="{width: '70vw'}" [maximizable]="true" appendTo="body"
              contentStyleClass="pt-1">
      <ng-template pTemplate="header">
        <div class="flex align-items-baseline">
          <div class="text-2xl mr-2">Referenced Table:</div>
          <div class="text-gray-700">
            <span class="text-2xl font-medium">
              {{referencedTableDialog.referencedTableName | dbNormalizeName}}
              ({{referencedTableDialog.childToParentInfo.referencedColumnName | dbNormalizeName}} <sup>PK</sup>)
            </span>
            <i class="ml-2 mr-2 pi pi-arrow-left"></i>
            <span class="">
              {{table.name | dbNormalizeName}}
              ({{referencedTableDialog.childToParentInfo.referencingColumnName | dbNormalizeName}} <sup>FK</sup>)
            </span>
          </div>
        </div>
      </ng-template>
      <app-db-table *ngIf="referencedTableDialog.show"
                    [childToParentInfo]="referencedTableDialog.childToParentInfo"
                    [schema]="schema"
                    [table]="referencedTableDialog.referencedTable">
      </app-db-table>
    </p-dialog>
    <!-- Referencing (Child) Tables   -->
    <p-dialog [(visible)]="referencingTablesDialog.show"
              [closable]="true"
              [modal]="true" [style]="{width: '70vw'}" [maximizable]="true" appendTo="body"
              contentStyleClass="pt-1">
      <ng-template pTemplate="header">
        <div class="flex align-items-baseline">
          <div class="text-2xl mr-2">
            Referencing Table{{constraintsUtil.childTables.length > 1 ? 's' : ''}} for Parent:
          </div>
          <div class="text-gray-700 flex align-items-baseline">
            <span class="text-2xl font-medium">
              {{referencingTablesDialog.parentToChildrenInfo.referencedTable.name | dbNormalizeName}}
            </span>
            <span class="text-lg ml-1">
              ({{constraintsUtil.primaryKeys[0] | dbNormalizeName}}
              <sup>PK</sup> = {{referencingTablesDialog.parentToChildrenInfo.referencedPKValue}})
            </span>
            <span class="mt-overflow-ellipsis ml-2 bg-gray-400 text-white"
                  style="max-width: 400px; padding: .1rem 1rem; border-radius: 1rem" appTooltipOnOverflow>
              {{referencingTablesDialog.parentToChildrenInfo.referencedViewColumnsData.join(' | ')}}
            </span>
          </div>
        </div>
      </ng-template>
      <p-tabView #tabView *ngIf="referencingTablesDialog.show" [scrollable]="true"
                 appLifeCycle (afterViewInit)="tabView.updateInkBar()">
        <p-tabPanel *ngFor="let childTable of constraintsUtil.childTables; let i = index" [selected]="i === 0"
                    [header]="childTable.name | dbNormalizeName">
          <app-db-table [schema]="schema"
                        [table]="childTable"
                        [parentToChildInfo]="referencingTablesDialog.parentToChildrenInfo">
          </app-db-table>
        </p-tabPanel>
      </p-tabView>
    </p-dialog>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table *appRenderThis
        [columns]="table.columns | addKey:'name'"
        [value]="data"
        [(first)]="firstPage"
        responsiveLayout="scroll"
        [scrollable]="true"
        scrollDirection="horizontal"
        [(selection)]="selectedRows"
        dataKey="dataKey"
        [selectionPageOnly]="true"
        [lazy]="true"
        (onLazyLoad)="load($any($event))"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [lazyLoadOnInit]="false"
        [rows]="pageSize"
        [totalRecords]="total"
        editMode="row"
        [editingRowKeys]="editingRowKeys"
        sortMode="multiple"
        [resizableColumns]="true"
        columnResizeMode="fit"
        [reorderableColumns]="true"
        stateStorage="local"
        [stateKey]="getStateKey()"
        (onStateSave)="onStateSave($event)"
        (onStateRestore)="onStateRestore($event)">
        <ng-template pTemplate="caption">
          <div class="flex justify-content-between">
            <div class="flex align-items-center">
              <button pButton pRipple type="button" icon="pi pi-plus"
                      class="p-button-rounded p-button-text ml-2"
                      pTooltip="Add Row" tooltipPosition="bottom"
                      (click)="add()" [disabled]="!canAdd()">
              </button>
              <button pButton pRipple type="button" icon="pi pi-refresh"
                      class="p-button-rounded p-button-text"
                      pTooltip="Reload" tooltipPosition="bottom"
                      (click)="load()">
              </button>
              <button pButton pRipple type="button" icon="pi pi-filter-slash"
                      class="p-button-rounded p-button-text"
                      pTooltip="Clear Filters" tooltipPosition="bottom"
                      [disabled]="!getFilteredColumnNames().length"
                      (click)="clearFiltersAndReload()">
              </button>
              <span *ngIf="getFilteredColumnNames().length" class="ml-2 mt-overflow-ellipsis" style="max-width: 40rem">
                <span>Filtered by: </span>
                <span class="text-gray-600" appTooltipOnOverflow [useParent]="true">
                  {{getFilteredColumnNames().join(', ')}}
                </span>
              </span>
            </div>
            <div class="flex align-items-center">
              <button pButton pRipple type="button" icon="pi pi-replay"
                      class="p-button-rounded p-button-text"
                      pTooltip="Reset State" tooltipPosition="bottom"
                      (click)="onClearState()">
              </button>
              <button *ngIf="isReferencedTable() || isReferencingTable()"
                      pButton pRipple type="button" icon="pi pi-external-link"
                      class="p-button-rounded p-button-text"
                      pTooltip="Open in Database Browser Tab" tooltipPosition="bottom"
                      (click)="browserService.onBrowseTable(table)">
              </button>
            </div>
          </div>
        </ng-template>
        <!------------>
        <!-- HEADER -->
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th [style]="{width: (4 * 45) + 'px', 'max-width': (4 * 45) + 'px'}"
                style="border-right: 1px solid #e4e4e4;"
                pFrozenColumn>
              <!-- <p-tableHeaderCheckbox class="ml-6"></p-tableHeaderCheckbox> -->
            </th>
            <th *ngFor="let col of columns"
                style="width: 210px; position: relative"
                [pSortableColumn]="col.name" [pSortableColumnDisabled]="isChildReferencingColumn(col)"
                pResizableColumn pReorderableColumn>
              <span
                class="text-gray-600 absolute cursor-auto"
                style="font-size: .6rem; left: .2rem; top: .2rem">
                <span pTooltip="Primary Key" tooltipPosition="top">{{col.constraints?.PK ? 'PK ' : ''}}</span>
                <span pTooltip="Foreign Key" tooltipPosition="top">{{col.constraints?.FK ? 'FK ' : ''}}</span>
                <span pTooltip="Required" tooltipPosition="top">
                  {{col.isRequired && !col.constraints?.PK && !col.constraints?.FK ? '* ' : ''}}</span>
                <span pTooltip="Default Value on Create" tooltipPosition="top">{{col.defaultValue ? 'D ' : ''}}</span>
              </span>

              <div class="flex justify-content-between align-items-center w-full">
                <div class="mt-overflow-ellipsis">
                  <i *ngIf="isChildReferencingColumn(col)" class="pi pi-directions-alt text-primary cursor-auto"
                    pTooltip="Referencing FK Column" tooltipPosition="bottom"></i>
                  <span class="align-self-center" [class.text-primary]="isChildReferencingColumn(col)"
                        appTooltipOnOverflow [useParent]="true" style="width: 210px">
                    {{col.name | dbNormalizeName}}
                  </span>
                </div>
                <p-sortIcon *ngIf="!isChildReferencingColumn(col)"
                            class="align-self-center" [field]="col.name">
                </p-sortIcon>
                <ng-container *ngIf="!isChildReferencingColumn(col)">
                  <app-db-column-filter [schema]="schema" [column]="col"></app-db-column-filter>
                </ng-container>
              </div>
            </th>
          </tr>
        </ng-template>
        <!------------>
        <!--  BODY  -->
        <ng-template pTemplate="body"
                     let-rowData let-columns="columns" let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="rowData">
            <!------------>
            <!--  FROZEN  -->
            <td [style]="{width: (4 * 45) + 'px', 'max-width': (4 * 45) + 'px'}"
                style="z-index: 2; border-right: 1px solid #e4e4e4;"
                pFrozenColumn class="flex justify-content-center">
              <!--  <p-tableCheckbox class="ml-1" [disabled]="isNewRow(rowData)" [value]="rowData"></p-tableCheckbox> -->
              <ng-container *ngIf="!editing">
                <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                        (click)="edit(rowData)" class="p-button-rounded p-button-text"
                        pTooltip="Edit Row" tooltipPosition="bottom"></button>
                <button pButton pRipple type="button" pInitEditableRow icon="pi pi-trash"
                        (click)="remove($event, rowData, ri)" class="p-button-rounded p-button-text"
                        pTooltip="Delete Row" tooltipPosition="bottom"></button>
                <button pButton pRipple type="button" icon="pi pi-directions-alt"
                        class="p-button-rounded p-button-text"
                        [disabled]="!constraintsUtil.childTables.length || isNewRow(rowData)"
                        (click)="showReferencingTables(rowData)"
                        pTooltip="Open Referencing Table(s)" tooltipPosition="bottom"></button>
                <button *ngIf="childToParentInfo?.canApplyFK"
                        pButton pRipple type="button" icon="pi pi-reply"
                        class="p-button-rounded p-button-text"
                        [disabled]="isNewRow(rowData) || isParentReferencedRow(rowData)"
                        [pTooltip]="'Assign '
                            + normalizeName(this.table.name)
                            + ' (' + normalizeName(childToParentInfo!.referencedColumnName) + '/PK) to '
                            + normalizeName(childToParentInfo!.referencingTableName)
                            + ' (' + normalizeName(childToParentInfo!.referencingColumnName) + '/FK)'"
                        tooltipPosition="bottom"
                        (click)="childToParentInfo!.onApplyFK(rowData[childToParentInfo!.referencedColumnName])">
                </button>
              </ng-container>
              <ng-container *ngIf="editing">
                <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                        [disabled]="!canSave(rowData)"
                        (click)="save(rowData, ri)"
                        class="p-button-rounded p-button-text p-button-success"></button>
                <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                        (click)="cancelEdit(rowData, ri)"
                        class="p-button-rounded p-button-text p-button-danger"></button>
              </ng-container>
              <div *ngIf="isNewRow(rowData)"
                   style="position: absolute; top: 0; left: .4em; font-size: 12px" class="text-gray-400">
                new
              </div>
              <div *ngIf="this.backendErrorRowKeys[rowData.dataKey]"
                   style="position: absolute; top: .2rem; right: .4rem; font-size: 12px">
                <i class="pi pi-exclamation-triangle mt-invalid"
                   pTooltip="Recent CRUD operation failed" tooltipPosition="bottom"></i>
              </div>
            </td>
            <!------------>
            <!--  DATA  -->
            <td *ngFor="let col of columns" style="width: 210px; position: relative">
              <button *ngIf="col.constraints?.FK && !isChildReferencingColumn(col)"
                      pButton pRipple type="button" icon="pi pi-directions"
                      (click)="showReferencedTable(col, rowData, editing)"
                      class="p-button-rounded p-button-text mr-1"
                      [pTooltip]="'Open Referenced Table: ' + normalizeName(col.constraints?.FK?.relTableName)"
                      tooltipPosition="bottom"></button>
              <div *ngIf="!editing && col.constraints?.FK"
                   style="top: .2rem; left: .75rem; right: .75rem; font-size:.8rem; position: absolute"
                   class="text-gray-600 mt-overflow-ellipsis" appTooltipOnOverflow>
                {{getReferencedRecordForView(col, rowData).join(' | ')}}
              </div>
              <div *ngIf="isParentReferencedCell(col, rowData)">
                <i class="pi pi-directions mr-1"
                   pTooltip="Referenced PK Value" tooltipPosition="bottom"></i>
              </div>
              <p-cellEditor class="mt-overflow-ellipsis w-full relative">
                <ng-template pTemplate="input">
                  <ng-container>
                    <app-db-column-input [schema]="schema" [column]="col"
                                         [rowData]="rowData" [disabled]="!isFieldEditable(col, rowData)">
                    </app-db-column-input>
                  </ng-container>
                </ng-template>
                <ng-template pTemplate="output">
                  <ng-container *ngIf="rowData[col.name] != null; else nullValue">
                    <span appTooltipOnOverflow [useParent]="true">
                      <app-db-column-output [schema]="schema" [column]="col" [rowData]="rowData"></app-db-column-output>
                    </span>
                  </ng-container>
                  <ng-template #nullValue>
                    <div class="border-bottom-1 text-gray-400" style="width: 25px"></div>
                  </ng-template>
                </ng-template>
              </p-cellEditor>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [`
  `],
  providers: [SubscriptionsService]
})
export class DbTableComponent implements AfterViewInit {
  @Input() schema!: IDbSchema;
  @Input() table!: IDbTable;
  @Input() childToParentInfo: IChildToParentInfo | null = null;
  @Input() parentToChildInfo: IParentToChildInfo | null = null;

  loading = false;
  data!: Array<ITableRow>;

  pageSize = 10;
  total = 0;
  firstPage = 0;

  selectedRows: Array<ITableRow> = [];
  editingRowKeys: IDictionary<boolean> = {};
  private editCache: IDictionary<ITableRow> = {};

  constraintsUtil!: DbConstraintsUtil;

  backendErrorRowKeys: IDictionary<boolean> = {};
  referencedRecords: {
    [fkColumnName: string]: {
      table: IDbTable;
      records: {
        [id: string | number]: IDictionary
      };
    }
  } = {};

  @ViewChild(Table, {static: false}) tableComponent!: Table;
  @ViewChildren(DbColumnFilter) columnFilters!: QueryList<DbColumnFilter>;
  @ViewChild(RenderThisDirective, {static: false}) renderThisDirective!: RenderThisDirective;

  referencedTableDialog: {
    referencedTable: IDbTable;
    referencedTableName: string;
    childToParentInfo: IChildToParentInfo;
    show: boolean;
  } = {
    show: false
  } as any;

  referencingTablesDialog: {
    parentToChildrenInfo: IParentToChildInfo;
    show: boolean;
  } = {
    show: false
  } as any;
  tableQuery: TableQuery = new TableQuery();

  constructor(private resource: AppResourceService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private changeDetector: ChangeDetectorRef,
              public browserService: DbBrowserService,
              subscriptions: SubscriptionsService) {
    subscriptions.add(browserService.browseTableSource$.subscribe((table) => {
      this.referencedTableDialog.show = false;
      this.referencingTablesDialog.show = false;
    })).add (browserService.clearAllStatesSource$.subscribe(() => {
      this.recreateTable();
    }));
  }



  ngAfterViewInit(): void {
    this.constraintsUtil = new DbConstraintsUtil(this.schema, this.table);
    this.initTable();
  }

  private initTable(): void {
    if (this.isReferencedTable() && !!this.childToParentInfo!.referencingFKValue) {
      this.clearFilters(false);
      this.tableComponent.filters[this.childToParentInfo!.referencedColumnName] =
        [{matchMode: 'equals', operator: 'and', value: this.childToParentInfo!.referencingFKValue}];
      this.changeDetector.detectChanges();
    }

    if (this.isReferencingTable()) {
      this.clearFilters(false);
      const fkCol = this.getChildReferencingColumn();
      this.tableComponent.filters[fkCol!.name] =
        [{matchMode: 'equals', operator: 'and', value: this.parentToChildInfo?.referencedPKValue}];
      this.changeDetector.detectChanges();
    }

    this.load();
  }

  load(event?: LazyLoadEvent): void {
    setTimeout(() => this.loading = true);
    const searchRequest: ISearchRequest = this.tableQuery.prepareQuery(
      event || this.tableComponent.createLazyLoadMetadata(), {
        pageSize: this.pageSize
      }
    );
    this.resource.dbSearch(this.table.name,
      searchRequest
    ).pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((response) => {
      this.data = response.results.map((r) => {
        return this.constraintsUtil.addDataKeyToRowData(r);
      });
      console.log('table data loaded', this.data);
      this.total = response.total;
      this.editCache = {};
      this.editingRowKeys = {};
      this.backendErrorRowKeys = {};
      this.loadReferencedTables();
    });
  }

  private loadReferencedTables(): void {
    const requests: {
      request: Observable<ISearchResponse<any>>,
      fkColumn: IDbColumn,
      referencedTable: IDbTable
    }[] = [];
    for (const fkCol of this.constraintsUtil.fkColumns) {
      const fKeys: Array<any> = this.data.map(((rowData) => rowData[fkCol.name]));
      const searchRequest: ISearchRequest = {
        offset: 0,
        limit: this.pageSize,
        query: {
          logical: 'and',
          predicates: [
            {
              field: fkCol.constraints!.FK!.relColumnsNames![0]!,
              operator: 'in',
              value: fKeys
            }
          ]
        }
      };
      requests.push({
        request: this.resource.dbSearch(fkCol.constraints!.FK!.relTableName!, searchRequest),
        referencedTable: this.constraintsUtil.getTableByName(fkCol.constraints!.FK!.relTableName!),
        fkColumn: fkCol
      });
    }
    if (requests.length) {
      forkJoin(requests.map((req) => req.request)).subscribe((results) => {
        results.forEach((result, i) => {
          this.referencedRecords[requests[i].fkColumn.name] = {
            table: requests[i].referencedTable,
            records: keyBy(result.results, ((rowData) => rowData[requests[i].fkColumn.constraints!.FK!.relColumnsNames![0]]))
          }
        });
      });
    }
  }

  getReferencedRecordForView(fkColumn: IDbColumn, rowData: ITableRow): Array<string> {
    if (this.isNewRow(rowData)) {
      return [];
    }
    const referencedRecordsOf = this.referencedRecords[fkColumn.name];
    if (!referencedRecordsOf) {
      return [];
    }
    const record: IDictionary = referencedRecordsOf.records[rowData[fkColumn.name]];
    if (!record) {
      return [];
    }
    return this.getViewColumnsData(referencedRecordsOf.table, record);
  }

  getViewColumnsData(table: IDbTable, rowData: IDictionary): Array<string> {
    const viewColumns = filter<IDbColumn>(table.columns,
      (col) => col.isRequired && (col.constraints == null || (!col.constraints.FK && !col.defaultValue))
    ).map((col) => col.name);
    return values(pickBy(rowData, (value, field) => viewColumns.indexOf(field) !== -1));
  }

  isReferencedTable(): boolean {
    return !!this.childToParentInfo;
  }

  isReferencingTable(): boolean {
    return !!this.parentToChildInfo;
  }

  isChildReferencingColumn(column: IDbColumn): boolean {
    return this.isReferencingTable()
      && column.constraints?.FK?.relTableName === this.parentToChildInfo!.referencedTable.name;
  }

  private getChildReferencingColumn(): IDbColumn {
    return find(this.table.columns, (col) => {
      return col.constraints?.FK?.relTableName === this.parentToChildInfo?.referencedTable.name;
    })!;
  }

  isParentReferencedRow(rowData: ITableRow): boolean {
    return this.isReferencedTable()
      && rowData[this.childToParentInfo!.referencedColumnName] === this.childToParentInfo!.referencingFKValue;
  }

  isParentReferencedCell(col: IDbColumn, rowData: ITableRow): boolean {
    return this.isReferencedTable()
      && col.name === this.childToParentInfo!.referencedColumnName
      && rowData[col.name] === this.childToParentInfo!.referencingFKValue;
  }

  clearFilters(saveState = true): void {
    this.columnFilters.forEach((columnFilter) => {
      columnFilter.clearFilter();
    });
    if (saveState && this.tableComponent.isStateful()) {
      this.tableComponent.saveState();
    }
  }

  clearFiltersAndReload(): void {
    this.clearFilters();
    this.load();
  }

  getFilteredColumnNames(): Array<string> {
    if (!this.tableComponent) {
      return [];
    }
    const result: Array<string> = [];
    keys(this.tableComponent.filters).forEach((field) => {
      const val = this.tableComponent.filters[field];
      if (isArray(val) && val.length && !!(val as FilterMetadata[])[0].value) {
        result.push(this.normalizeName(field));
      }
    });
    return result;
  }

  private indexInPage(index: number): number {
    return index % this.pageSize;
  }

  isFieldEditable(column: IDbColumn, rowData: ITableRow): boolean {
    if (this.isChildReferencingColumn(column)) {
      return false;
    }
    if (!!!column.constraints?.PK) { // not PK
      if (!!column.defaultValue) {
        return !this.isNewRow(rowData);
      }
      return true;
    } else { // PK
      if (column.constraints?.FK) { // todo: edit pk+fk
        return this.isNewRow(rowData);
      }
      if (!column.defaultValue && this.isNewRow(rowData)) {
        return true;
      }
      return false;
    }
    return true;
  }

  isNewRow(rowData: ITableRow): boolean {
    return rowData.dataKey === NEW_RECORD_KEY;
  }

  edit(rowData: ITableRow): void {
    if (!this.backendErrorRowKeys[rowData.dataKey]) {
      this.editCache[rowData.dataKey] = cloneDeep(rowData);
    }
  }

  canSave(rowData: ITableRow): boolean {
    for (const prop of this.constraintsUtil.requiredFields) {
      if (rowData[prop] == null || rowData[prop] === '') {
        if (this.isNewRow(rowData) &&
          (this.constraintsUtil.isPK(prop) && !this.constraintsUtil.isFK(prop) || this.constraintsUtil.isDefault(prop))) {
          continue;
        }
        return false;
      }
    }
    return !isEqual(rowData, this.editCache[rowData.dataKey]);
  }

  save(rowData: ITableRow, index: number): void {
    if (this.canSave(rowData)) {
      index = this.indexInPage(index);
      const isNew = this.isNewRow(rowData);
      const bodyObj: IDictionary = omit(rowData, ['dataKey']);
      this.loading = true;
      (
        !isNew
          ? this.resource.dbUpdateTableRow(this.table.name, bodyObj)
          : this.resource.dbCreateTableRow(this.table.name, bodyObj)
      ).pipe(
        finalize(() => this.loading = false),
        catchError((error) => {
          this.backendErrorRowKeys[rowData.dataKey] = true;
          this.tableComponent.initRowEdit(rowData);
          return throwError(error);
        })
      ).subscribe((response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `Row has been ${isNew ? 'created' : 'updated'}`
        });
        delete this.editCache[rowData.dataKey];
        delete this.backendErrorRowKeys[rowData.dataKey];
        this.data[index] = rowData = this.constraintsUtil.addDataKeyToRowData(response);
        this.loadReferencedTables();
      });
    }
  }

  cancelEdit(rowData: ITableRow, index: number): void {
    index = this.indexInPage(index);
    this.data[index] = this.editCache[rowData.dataKey];
    delete this.editCache[rowData.dataKey];
    delete this.backendErrorRowKeys[rowData.dataKey];
    if (this.isNewRow(rowData)) {
      delete this.editingRowKeys[rowData.dataKey];
      remove(this.data, {dataKey: NEW_RECORD_KEY});
    }
  }

  canAdd(): boolean {
    return findIndex(this.data, {dataKey: NEW_RECORD_KEY}) === -1;
  }

  add(): void {
    if (this.canAdd()) {
      this.data.unshift({
        dataKey: NEW_RECORD_KEY
      } as ITableRow);
      if (this.isReferencingTable()) {
        const fkCol = this.getChildReferencingColumn();
        this.data[0][fkCol.name] = this.parentToChildInfo!.referencedPKValue;
      }
      this.editingRowKeys[NEW_RECORD_KEY] = true;
      this.edit(this.data[0]);
    }
  }

  remove(ev: any, rowData: ITableRow, index: number): void {
    index = this.indexInPage(index);
    delete this.editingRowKeys[rowData.dataKey];
    const onRemove = () => {
      this.data.splice(index, 1);
      delete this.editCache[rowData.dataKey];
      delete this.backendErrorRowKeys[rowData.dataKey];
    };
    if (this.isNewRow(rowData)) {
      onRemove();
      return;
    }
    this.confirmationService.confirm({
      target: ev.target,
      message: 'Are you sure that you want to delete object?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.resource.dbDeleteTableRow(this.table.name, omit(rowData, ['dataKey']))
          .subscribe(() => {
            onRemove();
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Object has been deleted`
            });
          });
      }
    });
  }

  showReferencedTable(col: IDbColumn, rowData: ITableRow, editing: boolean): void {
    this.referencedTableDialog.referencedTableName = col.constraints!.FK!.relTableName!;
    this.referencedTableDialog.referencedTable = this.constraintsUtil.getTableByName(this.referencedTableDialog.referencedTableName);
    let canApplyFK = true;
    if (col.constraints!.PK && !this.isNewRow(rowData)) { // todo: edit pk+fk
      canApplyFK = false;
    }
    this.referencedTableDialog.childToParentInfo = {
      referencingTableName: this.table.name,
      referencingColumnName: col.name,
      referencingFKValue: rowData[col.name],
      referencedColumnName: col.constraints!.FK!.relColumnsNames![0],
      canApplyFK,
      onApplyFK: ((value) => {
        this.referencedTableDialog.show = false;
        if (!editing) {
          this.edit(rowData);
          this.tableComponent.initRowEdit(rowData);
        }
        setTimeout(() => {
          if (canApplyFK) {
            rowData[col.name] = value;
          }
        })
      })
    };
    this.referencedTableDialog.show = true;
  }


  showReferencingTables(rowData: ITableRow): void {
    this.referencingTablesDialog.parentToChildrenInfo = {
      referencedTable: this.table,
      referencedPKValue: rowData[this.constraintsUtil.primaryKeys[0]],
      referencedViewColumnsData: this.getViewColumnsData(this.table, rowData)
    }
    this.referencingTablesDialog.show = true;
  }

  onStateRestore(state: any): void {
  }

  onStateSave(state: TableState): void {
    if (this.isReferencedTable() || this.isReferencingTable()) {
      delete state.columnWidths;
      delete state.filters;
      if (this.isReferencingTable()) {
        delete state.multiSortMeta;
      }
      this.tableComponent.getStorage().setItem(this.tableComponent.stateKey!, JSON.stringify(state));
    }
  }

  getStateKey(): any {
    if (this.isReferencedTable()) {
      return DbStorage.getReferencedTableKey(this.table.name);
    }
    if (this.isReferencingTable()) {
      return DbStorage.getReferencingTableKey(this.table.name);
    }
    return DbStorage.getTableKey(this.table.name);
  }

  onClearState(): void {
    this.tableComponent.clearState();
    this.recreateTable();
  }

  recreateTable(): void {
    this.renderThisDirective.recreate();
    this.changeDetector.detectChanges();
    this.initTable();
  }

  normalizeName(name: string): string {
    return new DbNormalizeNamePipe().transform(name);
  }

}

