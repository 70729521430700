import {TCompanyStatus, TCompanyType} from '../../api/shared/app-domain/company';

export const COMPANY_TYPE_ICONS: {[prop in TCompanyType]: string} = { // TODO
  'Client': 'type-unknown',
  'Agency Reseller': 'type-unknown',
  'Talent Partner': 'type-unknown',
  'Sales Rep': 'type-unknown',
  'Referral Partner': 'type-unknown',
  'Contractor Company': 'type-unknown'
};


export function getCompanyTypeIcon(type: TCompanyType): string | null {
  return COMPANY_TYPE_ICONS[type] ? `assets/images/company/${COMPANY_TYPE_ICONS[type]}.svg` : null;
}

export const COMPANY_ASSOC_LINKS: {[type in TCompanyType]: string} = {
  'Client': '/projects?mode=all&scopeFilter=clientId&id={:id}&name={:name}',
  'Agency Reseller': '/projects?mode=client&scopeFilter=agencyId&id={:id}&name={:name}',
  'Talent Partner': '/team?mode=all&scopeFilter=talentPartnerId&id={:id}&name={:name}',
  'Sales Rep': '/tbd/sales-reps/{:id}',
  'Referral Partner': '/tbd/referral-partners/{:id}',
  'Contractor Company': '/tbd/contractor-companies/{:id}'
}
