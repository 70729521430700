import {Component, Input, SimpleChanges, ViewChild} from '@angular/core';
import {PageableTableComponent} from '../../../common/components/pageable-table.component';
import {ITimeEntryRow} from '../../../api/shared/app-domain/time';
import {AppResourceService} from '../../../app.resource.service';
import {EntityPropertyOptionsService} from '../../common/entity-property-options.service';
import {TableQuery} from '../../../common/util/table-query';
import {IPeriod, IPeriodRequest, IUrlLink} from '../../../api/shared/app-domain/common';
import {ISearchRequest, ISearchResponse} from '../../../api/shared/search-api';
import {Observable} from 'rxjs';
import {IIconLinkOption} from '../../common/table-cell.component';
import {faClock, faUserGear} from '@fortawesome/free-solid-svg-icons';
import {faSkype, faSlack} from '@fortawesome/free-brands-svg-icons';
import {DayTimeEntriesDialogComponent} from '../time-sheets/day-time-sheets-details';

@Component({
  selector: 'app-time-entries-table',
  template: `
      <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit', 'archive']"></app-table-toolbar>
      <app-day-time-entries-dialog #te (dataChanged)="load()">
      </app-day-time-entries-dialog>
      <app-spinnerizer [active]="loading"
                       [target]="container">
      </app-spinnerizer>
      <div #container>
          <p-table [value]="data"
                   [(first)]="firstPage"
                   responsiveLayout="scroll"
                   dataKey="id"
                   [lazy]="true"
                   (onLazyLoad)="load($any($event))"
                   [paginator]="true"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Time Entries"
                   [rowsPerPageOptions]="[10, 25, 50, 100]"
                   [showCurrentPageReport]="true"
                   [lazyLoadOnInit]="false"
                   [rows]="pageSize"
                   [totalRecords]="total"
                   sortMode="multiple"
                   [(selection)]="selection"
                   [selectionPageOnly]="true"
                   (selectionChange)="onSelectionChange()"
                   scrollDirection="horizontal"
                   [scrollable]="true">
              <ng-template pTemplate="header">
                  <tr>
                      <app-column-header [isSelector]="true"></app-column-header>
                      <app-column-header field="date" filterType="date" [fixedWidth]="140"
                                         frozen="left"></app-column-header>
                      <app-column-header filterType="optionsIn" field="clientName" label="Client"
                                         frozen="left"
                                         filterField="clientId"
                                         [fixedWidth]="160"
                                         [options]="$any(propertyOptions.getOptions('projectClients') | async)"
                                         optionLabel="name" optionValue="id"></app-column-header>
                      <app-column-header filterType="optionsIn" field="projectName" label="Project"
                                         filterField="projectId"
                                         frozen="left"
                                         [fixedWidth]="220"
                                         [options]="$any(propertyOptions.getOptions('projects') | async)"
                                         optionLabel="name" optionValue="id"></app-column-header>
                      <app-column-header field="teamMemberName" filterType="optionsIn" label="Team Member"
                                         frozen="lastLeft" [fixedWidth]="260"
                                         [options]="$any(propertyOptions.getOptions('teamMemberFullNames') | async)"
                                         optionValue="name"
                                         [optionsVirtualScroll]="true"></app-column-header>
                      <app-column-header field="state" filterType="optionsEquals" field="state"
                                         [fixedWidth]="120"
                                         [options]="propertyOptions.approvalStates">
                      </app-column-header>
                      <app-column-header filterType="optionsIn" field="departmentName" label="Department"
                                         [fixedWidth]="240"
                                         [options]="$any(propertyOptions.getOptions('departments') | async)"
                                         optionValue="name"></app-column-header>
                      <app-column-header field="payableTime" label="Hours Payable" filterType="text"
                                         [fixedWidth]="140"></app-column-header>
                      <app-column-header field="billableTime" label="Hours Billable" filterType="text"
                                         [fixedWidth]="140"></app-column-header>
                      <app-column-header field="billableRate" filterType="numeric"
                                         [fixedWidth]="150"></app-column-header>
                      <app-column-header field="billable" filterType="numeric"
                                         [fixedWidth]="150"></app-column-header>

                      <app-column-header field="taasTime" label="Hours TaaS" filterType="text"
                                         [fixedWidth]="140"></app-column-header>
                      <app-column-header field="taasRate" label="TaaS Rate" filterType="numeric"
                                         [fixedWidth]="140"></app-column-header>
                      <app-column-header field="taasPct" label="TaaS %" filterType="numeric"
                                         [fixedWidth]="140"></app-column-header>
                      <app-column-header field="taasBillable" label="TaaS Billable" filterType="numeric"
                                         [fixedWidth]="140"></app-column-header>
                      <app-column-header field="isBillable" filterType="boolean" [fixedWidth]="180"></app-column-header>
                      <app-column-header field="notes" filterType="text" [fixedWidth]="300"></app-column-header>
                      <app-column-header field="link" filterType="text" [fixedWidth]="300"></app-column-header>

                      <app-column-header field="usefulLinks" [sortable]="false" label="Links" frozen="firstRight"
                                         [fixedWidth]="links.length * 20"></app-column-header>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                  <tr [style.background-color]="te.visible && currentMenuRowData?.id === rowData.id ? '#EFF6FF' : '#FFF'">
                      <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
                      <app-table-cell type="custom" field="date" [rowData]="rowData" frozen="left">
                          <div class="mt-link"
                               (click)="currentMenuRowData = rowData; te.fetchCategoryRowAndToggle(rowData.teamMemberId, rowData.projectId, rowData.date)">
                              {{rowData.date | date: 'EEE, MMM dd, y'}}</div>
                      </app-table-cell>
                      <app-table-cell field="clientName" [value]="rowData.clientName" frozen="left"
                                      [style]="{maxWidth: '140px'}"></app-table-cell>
                      <app-table-cell type="custom" field="projectName" [rowData]="rowData"
                                      tdStyleClass="py-0" frozen="left">
                          <div class="flex align-items-center">
                              <app-project-entry-entity [width]="'220px'" [entryEntity]="rowData" routerLink="/projects"
                                                        [queryParams]="{tab: 'details'}">
                              </app-project-entry-entity>
                          </div>
                      </app-table-cell>
                      <app-table-cell type="custom" field="teamMemberName" [rowData]="rowData" frozen="lastLeft"
                                      tdStyleClass="py-0" styleClass="justify-content-between">
                          <app-team-member-entry-entity [entryEntity]="rowData" width="170px"
                                                        routerLink="/team-members"
                                                        [queryParams]="{tab: 'details'}"></app-team-member-entry-entity>
                          <app-row-menu-button [tableBase]="this" [rowData]="rowData"
                                               [omitMenuItems]="['duplicate', 'archive']"></app-row-menu-button>
                      </app-table-cell>
                      <app-table-cell type="custom" field="state" [rowData]="rowData">
                        <app-approvals-state [state]="rowData.state"></app-approvals-state>
                      </app-table-cell>
                      <app-table-cell field="departmentName" [rowData]="rowData"></app-table-cell>
                      <app-table-cell type="custom" field="payableTime" [rowData]="rowData">
                          <div class="_mt-pct-box">{{rowData.payableTime}}</div>
                      </app-table-cell>
                      <app-table-cell type="custom" field="billableTime" [rowData]="rowData">
                          <div class="_mt-pct-box">{{rowData.billableTime}}</div>
                      </app-table-cell>
                      <app-table-cell field="billableRate" align="right"
                                      [value]="rowData.billableRate | currency:'USD':'symbol':'1.2-2'"></app-table-cell>
                      <app-table-cell field="billable" align="right"
                                      [value]="rowData.billable | currency:'USD':'symbol':'1.2-2'"></app-table-cell>
                      <app-table-cell type="custom" field="taasTime" [rowData]="rowData">
                          <div class="_mt-pct-box">{{rowData.taasTime}}</div>
                      </app-table-cell>
                      <app-table-cell type="custom" field="taasRate" [rowData]="rowData" align="right">
                          <div>{{rowData.taasRate | currency:'USD':'symbol':'1.2-2'}}</div>
                      </app-table-cell>
                      <app-table-cell type="custom" field="taasPct" [rowData]="rowData">
                          <div class="mt-pct-box">{{rowData.taasPct | percent}}</div>
                      </app-table-cell>
                      <app-table-cell type="custom" field="taasBillable" [rowData]="rowData" align="right">
                          <div>{{rowData.taasBillable | currency:'USD':'symbol':'1.2-2'}}</div>
                      </app-table-cell>
                      <app-table-cell field="isBillable" [rowData]="rowData" type="boolean"></app-table-cell>
                      <app-table-cell field="notes" [rowData]="rowData" [style]="{maxWidth: '280px'}"></app-table-cell>
                      <app-table-cell type="custom" field="link" [rowData]="rowData">
                          <div *ngIf="!!rowData.link" class="mt-link">
                              <a [href]="rowData.link.url" class="mt-link inline-flex align-items-center"
                                 target="_blank">
                                  <i *ngIf="rowData.link.icon == null" class="pi pi-link"></i>
                                  <img *ngIf="rowData.link.icon != null" [src]="rowData.link.icon" width="12">
                                  <span class="ml-2 mt-overflow-ellipsis" [style]="{maxWidth: '270px'}"
                                        appTooltipOnOverflow>
                    {{linkLabel(rowData.link)}}</span>
                              </a>
                          </div>
                      </app-table-cell>


                      <app-table-cell field="usefulLinks" type="iconLinks" [iconLinksOptions]="links"
                                      frozen="firstRight"
                                      [rowData]="rowData"></app-table-cell>
                  </tr>
              </ng-template>
          </p-table>
      </div>
  `,
  styles: [`
    :host ::ng-deep .p-datatable .p-paginator-bottom {
      border: none;
    }
  `]
})
export class TimeEntriesTableComponent extends PageableTableComponent<ITimeEntryRow> {
  @Input() period!: IPeriod;
  @ViewChild(DayTimeEntriesDialogComponent) teDialog!: DayTimeEntriesDialogComponent;
  links: Array<IIconLinkOption> = [
    {
      field: (rowData: any) => `/tbd/user-settings/${ rowData.id }`,
      icon: faUserGear,
      tooltip: 'User Settings',
      isInternal: true
    },
    { field: 'usefulLinks.timekeeping', icon: faClock, tooltip: 'TimeKeeping' },
    { field: 'usefulLinks.slack', icon: faSlack, tooltip: 'Slack' },
    { field: 'usefulLinks.skype', icon: faSkype, protocol: 'skype', tooltip: 'Skype' }
  ];



  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
  }

  protected override fetchData(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<ITimeEntryRow>> {
    searchRequest.param = {
      period: this.period
    }
    return this.resource.searchTimeEntryRows(searchRequest);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes['period']) {
      if (!changes['period'].firstChange) {
        this.load();
      }
    }
  }

  linkLabel(link: IUrlLink): string {
    return link.label || link.url.split('://')[1];
  }

  override openCreateOrUpdateDialog(rowData: ITimeEntryRow | null, initialData?: any): void {
    this.teDialog.fetchCategoryRowAndToggle(rowData!.teamMemberId, rowData!.projectId, rowData!.date);
  }
}
