import {Component, Input, ViewChild} from '@angular/core';
import {accessorProvider, downloadAttachment, ServiceLocator} from '../util/util';
import {faCloudArrowUp, faEarth} from '@fortawesome/free-solid-svg-icons';
import {FileUpload} from 'primeng/fileupload';
import {HttpResponse} from '@angular/common/http';
import {FILE_STORAGE_API_ENDPOINTS, IAttachment} from '../../api/shared/app-domain/common';
import {AbstractControlValueAccessorComponent} from './abstract-control-value-accessor.component';


export type TFileUploadDialogMode = 'upload' | 'choose';

export interface IFileUploadDialogOptions {
  mode?: TFileUploadDialogMode;
  url?: string;
  header?: string;
  maxFileSize?: number | null;
  accept?: string;
  onClose?: (file?: File, httpResponse?: HttpResponse<any>) => void;
  onChoose?: (file: File) => void;
  closeOnUpload?: boolean;
}

@Component({
  selector: 'app-file-upload-dialog',
  template: `
    <p-dialog [(visible)]="visible"
              [header]="options.header!"
              [closable]="!uploading"
              [closeOnEscape]="false"
              [modal]="true"
              appendTo="body"
              [resizable]="false"
              styleClass="mt-file-upload-dialog"
              (onHide)="onClose()">
      <ng-container *ngIf="visible">
        <p-fileUpload name="file" [url]="options.url!"
                      [class.hide-progress]="!uploading"
                      [accept]="options.accept!"
                      [maxFileSize]="options.maxFileSize!"
                      cancelIcon="pi pi-times-circle"
                      chooseIcon="pi pi-folder-open"
                      (onBeforeUpload)="onBeforeUpload($event)"
                      (onError)="onError($event)"
                      (onUpload)="onUpload($event)"
                      (onSelect)="onSelect($event)"
                      [showUploadButton]="this.options.mode === 'upload'"
                      [showCancelButton]="this.options.mode === 'upload'">
          <ng-template pTemplate="toolbar">
            <p-button class="ml-auto" label="Close" icon="pi pi-times"
                      [disabled]="uploading"
                      (onClick)="visible=false"></p-button>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="text-center">
              <fa-icon size="6x" [icon]="uploadIcon" style="color: #bec0da"></fa-icon>
            </div>
            <div class="text-center text-gray-600 font-medium mt-2" style="color: #bec0da">
              <span>Drop files here.</span>
              <span *ngIf="options.maxFileSize"> Max file size {{options.maxFileSize! / 1000000 | number}}MB</span>
            </div>
            <ul *ngIf="uploadedFiles.length">
              <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size | number}} bytes</li>
            </ul>
          </ng-template>
        </p-fileUpload>
      </ng-container>
    </p-dialog>
  `,
  styles: [`

    ::ng-deep .mt-file-upload-dialog .p-fileupload {
      width: 600px;
    }

    ::ng-deep .mt-file-upload-dialog .p-fileupload .p-fileupload-buttonbar {
      display: flex;
      padding: 0.75rem 0;
      border: none;
    }

    ::ng-deep .mt-file-upload-dialog .p-fileupload .p-fileupload-content {
      border: 3px dashed #bec0da;
    }


    ::ng-deep .mt-file-upload-dialog .p-fileupload-content .p-progressbar {
      top: 0.5rem;
      width: auto;
      left: 0.5rem;
      right: 0.5rem;
    }

    ::ng-deep .mt-file-upload-dialog .hide-progress .p-fileupload-content .p-progressbar {
      display: none;
    }

    ::ng-deep .mt-file-upload-dialog p-fileupload:not(.hide-progress) .p-fileupload-buttonbar .p-button {
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.38) !important;
      opacity: 1;
      pointer-events: none;
    }

    ::ng-deep .mt-file-upload-dialog .p-fileupload-row > div:nth-child(1) {
      width: 20%;
    }

    ::ng-deep .mt-file-upload-dialog .p-fileupload-row > div:nth-child(2) {
      width: 50%;
    }

    ::ng-deep .mt-file-upload-dialog .p-fileupload-row > div:nth-child(3) {
      text-align: right;
      width: 20%;
    }

    ::ng-deep .mt-file-upload-dialog .p-fileupload-row > div:nth-child(4) {
      width: 10%;
    }

  `]

})
export class FileUploadDialog {
  protected uploadedFiles: any[] = [];
  protected visible = false;
  protected options: IFileUploadDialogOptions = {};
  protected uploadIcon = faCloudArrowUp;
  protected uploading = false;
  protected lastLoadedFile?: File;
  protected lastHttpResponse?: HttpResponse<any>;
  @ViewChild(FileUpload) protected fileUpload!: FileUpload;

  show(options: IFileUploadDialogOptions): void {
    this.options = {
      ...{
        mode: 'upload',
        header: 'Import',
        maxFileSize: null,
        accept: '*.*',
        closeOnUpload: false
      },
      ...options
    };
    this.lastLoadedFile = undefined;
    this.uploading = false;
    this.uploadedFiles = [];
    this.visible = true;
  }

  protected onBeforeUpload(ev: any): void {
    // fill form data here if required
    this.uploading = true;
  }

  protected onError(ev: any): void {
    // this.fileUpload.msgs.push({}); // if we will need to show error
    this.uploading = false;
  }

  protected onUpload(ev: any): void {
    // for (let file of ev.files) { this.uploadedFiles.push(file); }
    this.lastLoadedFile = ev.files[0];
    this.lastHttpResponse = ev.originalEvent;
    this.uploading = false;
    ServiceLocator.message({
      severity: 'success',
      summary: 'Import',
      detail: `File ${ev.files[0].name} has been uploaded`
    });
    if (this.options.closeOnUpload) {
      this.visible = false;
    }
  }

  protected onClose(): void {
    if (this.options.onClose) {
      this.options.onClose(this.lastLoadedFile, this.lastHttpResponse);
    }
  }

  protected onSelect(ev: any): void {
    if (this.options.mode === 'choose') {
      if (this.options.onChoose) {
        this.options.onChoose(ev.currentFiles[0]);
      }
      this.visible = false;
    }
  }

  isShown(): boolean {
    return this.visible;
  }
}



@Component({
  selector: 'app-download-attachment',
  template: `
    <i class="cursor-pointer pi pi-download" (click)="download()"></i>
  `
})
export class DownloadAttachmentComponent {
  @Input() attachment!: IAttachment;
  download(): void {
    downloadAttachment(this.attachment);
  }

}

@Component({
  selector: 'app-upload-attachments',
  template: `
    <app-file-upload-dialog #fileUploadDialog></app-file-upload-dialog>
    <div class="p-inputgroup">
      <p-chips [(ngModel)]="value" [showClear]="true" (onRemove)="onRemove()" (onClear)="onRemove()">
        <ng-template let-file pTemplate="item">
          <a class="mt-link" [href]="file.url" target="_blank">{{file.name}}</a>
          <span class="ml-2 text-xs text-black-alpha-90" style="padding-top: 0.1rem">{{file.size | fileSize}}</span>
          <app-download-attachment class="ml-2 mr-2" [attachment]="file"></app-download-attachment>
        </ng-template>
      </p-chips>
      <button type="button" pButton icon="pi pi-upload" (click)="fileUploadDialog.show(uploadOptions)"></button>
    </div>
  `,
  styles: [`
    :host ::ng-deep .p-chips-input-token input {
      visibility: hidden;
    }
  `],
  providers: [accessorProvider(UploadAttachmentsComponent)]
})
export class UploadAttachmentsComponent extends AbstractControlValueAccessorComponent<Array<IAttachment>>{
  uploadOptions: IFileUploadDialogOptions = {
    url: FILE_STORAGE_API_ENDPOINTS.upload,
    accept: 'image/*, .pdf, .doc, .docx',
    header: 'Attach document',
    maxFileSize: 1048576,
    onClose: (file?, httpResponse?) => {
      if (!!file) {
        if (!!!this.value) {
          this.value = [];
        }
        this.value.push(httpResponse!.body);
        this.value = [...this.value];
        this.onModelChange(this.value);
      }
    },
    closeOnUpload: true
  }

  onRemove(): void {
    if (!!this.value) {
      this.value = this.value.length === 0 ? null : [...this.value];
      this.onModelChange(this.value);
    }
  }
}

