import {Component, OnInit} from '@angular/core';
import {AppResourceService} from '../../app.resource.service';
import {IWorkweek} from '../../api/shared/app-domain/settings';
import {finalize} from 'rxjs';
import {ServiceLocator} from '../../common/util/util';
import {NgModel} from '@angular/forms';

@Component({
  selector: 'app-workweek-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="mt-page-title">Workweek</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <button pButton pRipple label="Apply"
                    class="ml-5" [disabled]="model.invalid || !model.touched"
                    icon="pi pi-check" (click)="apply(model)"></button>
          </div>
        </div>
      </div>
    </div>
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>
    <div #container class="mt-4 flex justify-content-center">
      <app-workweek-editor #model="ngModel" [(ngModel)]="workweeks" [canRemoveDefault]="false" [usePeriod]="false"></app-workweek-editor>
    </div>
  `
})
export class WorkweekPageComponent implements OnInit {
  loading = false;

  workweeks: Array<IWorkweek> = [];

  constructor(private resource: AppResourceService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.resource.searchWorkweeks({})
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        this.workweeks = response.results;
      });
  }

  apply(model: NgModel): void {
    this.loading = true;
    this.resource.updateWorkweeks(this.workweeks)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((data) => {
        this.workweeks = data;
        model.control.markAsUntouched();
        ServiceLocator.message({
          severity: 'success',
          summary: 'Success',
          detail: `Workweeks have been updated`
        });
      });
  }
}
