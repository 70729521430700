import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IIdentified, ILens, IPeriod, TApprovalsState, TLens} from '../../../api/shared/app-domain/common';
import {EntityPropertyOptionsService} from '../../common/entity-property-options.service';
import {AppResourceService} from '../../../app.resource.service';
import {LazyLoadEvent, MenuItem, TreeNode, TreeTableNode} from 'primeng/api';
import {TableQuery} from '../../../common/util/table-query';
import {ISearchRequest, TQueryExpression} from '../../../api/shared/search-api';
import {finalize} from 'rxjs';
import {TreeTable} from 'primeng/treetable';
import {
  ITimeEntry,
  TTimeApprovalsTeamMember,
  TTimeApprovalsRequest,
} from '../../../api/shared/app-domain/time';
import {filter, find, some, startCase} from 'lodash';
import {IPeriodRange} from '../../../api/shared/app-domain/details';
import {onComponentPropChanged, ServiceLocator} from '../../../common/util/util';

type TAction = 'submit' | 'submitAndApprove' | 'approve' | 'reject' | 'delete';

@Component({
  selector: 'app-time-approvals-table',
  template: `
    <app-day-time-entries-dialog #te (dataChanged)="load()"></app-day-time-entries-dialog>
    <app-file-upload-dialog #fileUploadDialog></app-file-upload-dialog>
    <ng-template #toolbar>
      <div class="flex align-items-center justify-content-between w-full">
        <div class="flex align-items-center">
                <span class="p-input-icon-right">
                  <i class="pi pi-search"></i>
                  <input [(ngModel)]="search"
                         pInputText type="text" class="p-inputtext-sm" [style.width]="'300px'"
                         placeholder="Search"
                         appChangesDebounce (debounced)="load()"/>
                </span>
          <div class="ml-3 text-gray-700" style="width: 6rem;">{{selection.length}} selected</div>
          <p-menubar class="ml-2 mt-page-menu-bar" [model]="tableMenu"></p-menubar>
        </div>
        <div class="flex align-items-center">
          <button pButton pRipple type="button"
                  icon="pi pi-refresh" class="p-button-rounded p-button-text mr-2"
                  pTooltip="Reload" tooltipPosition="bottom" (click)="load()"></button>
        </div>
      </div>
    </ng-template>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-treeTable #tt [value]="data"
                   selectionMode="checkbox" [(selection)]="selection"
                   [lazy]="true"
                   (onLazyLoad)="load($any($event))"
                   [paginator]="true"
                   [rowsPerPageOptions]="[10, 25, 50, 100]"
                   [showCurrentPageReport]="true"
                   [currentPageReportTemplate]=
                     "'Showing {first} to {last} of {totalRecords} Team Members'"
                   [lazyLoadOnInit]="false"
                   [rows]="pageSize"
                   [totalRecords]="total"
                   (selectionChange)="onSelectionChange()"
                   [scrollable]="true"
                   frozenWidth="700px">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center">
            <p-treeTableHeaderCheckbox class="mr-4"></p-treeTableHeaderCheckbox>
            <ng-container *ngTemplateOutlet="toolbar"></ng-container>
          </div>
        </ng-template>
        <ng-template pTemplate="frozencolgroup">
          <colgroup>
            <col style="width: 300px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="colgroup">
          <colgroup>
            <col *ngFor="let col of weekDays" style="width: 110px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="frozenheader">
          <tr style="height: 43px">
            <th ttSortableColumn="teamMemberName">
              <div class="flex align-items-center justify-content-between">
                <div class="flex align-items-center">
                  <div class="ml-2 mr-2">Team Member</div>
                  <p-treeTableSortIcon field="teamMemberName"></p-treeTableSortIcon>
                </div>
                <app-tt-filter field="teamMemberId" filterType="optionsIn"
                               [options]="$any(propertyOptions.getOptions('teamMembers') | async)"
                               optionLabel="name"
                               optionValue="id"
                               [optionsVirtualScroll]="true"></app-tt-filter>
              </div>
            </th>
            <th class="text-center">{{lens.actualLabel}}</th>
            <th class="text-center">{{lens.budgetLabel}}</th>
            <th class="text-center">Delta</th>
            <th class="text-center mt-last-left-frozen-column">
              <div class="mt-overflow-ellipsis" appTooltipOnOverflow>{{lens.label ?? lens.id}}</div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr style="height: 43px">
            <th *ngFor="let col of weekDays">
              {{ col }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="frozenbody" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode"
              [style.background-color]="rowNode.node.expanded ? '#EFF6FF' : '#FFF'">
            <td pFrozenColumn>
              <div class="flex justify-content-between">
                <div class="flex align-items-center">
                  <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                  <ng-container *ngIf="rowNode.node.type === 'teamMember'">
                    <p-treeTableCheckbox [value]="rowNode" class="mr-2"></p-treeTableCheckbox>
                    <app-team-member-entry-entity [entryEntity]="rowData" width="160px"
                                                  routerLink="/team-members"
                                                  [queryParams]="{tab: 'details'}"></app-team-member-entry-entity>
                  </ng-container>
                  <ng-container *ngIf="rowNode.node.type === 'project'">
                    <app-project-entry-entity width="180px" [entryEntity]="rowData"
                                              routerLink="/projects"
                                              [queryParams]="{tab: 'details'}">
                    </app-project-entry-entity>
                  </ng-container>
                </div>
                <div style="width: 50px; min-width: 50px" class="flex pr-2 align-items-center justify-content-end">
                  <ng-container *ngIf="rowNode.node.type === 'teamMember'">
                    <app-approvals-state *ngIf="state == null" [state]="rowData.state"></app-approvals-state>
                    <p-menu #menu [popup]="true" [model]="rowMenuItems" appendTo="body"
                            [style]="{width: 'auto', minWidth: '150px'}"
                            (onShow)="onShowRowMenu(rowNode.node, rowData)"></p-menu>
                    <button pButton pRipple type="button"
                            icon="pi pi-ellipsis-v"
                            class="p-button-rounded p-button-text p-button-sm w-2rem h-2rem"
                            (click)="currentMenuRowData = rowData; menu.toggle($event)">
                    </button>
                  </ng-container>
                </div>
              </div>
            </td>
            <td pFrozenColumn colspan="2" style="padding-top: 0; padding-bottom: 0; flex: 2">
              <div class="w-full">
                <div class="flex justify-content-between">
                  <div>{{rowData.totals.actual.currency | currency:'USD':'symbol':'1.0-0'}}</div>
                  <div>of</div>
                  <div>{{rowData.totals.budget.currency | currency:'USD':'symbol':'1.0-0'}}</div>
                </div>
                <div class="flex justify-content-between text-gray-600">
                  <div>{{rowData.totals.actual.time}} h</div>
                  <div>of</div>
                  <div>{{rowData.totals.budget.time}} h</div>
                </div>
                <div *ngIf="rowData.totals.actual.currency != null && rowData.totals.budget.currency">
                  <p-progressBar
                    [value]="rowData.totals.actual.currency! / rowData.totals.budget.currency! * 100"
                    [showValue]="false"></p-progressBar>
                </div>
              </div>
            </td>
            <td pFrozenColumn>
              <div class="flex flex-column text-right">
                <div>{{rowData.totals.delta.currency | currency:'USD':'symbol':'1.0-0'}}</div>
                <div class="text-gray-600">{{rowData.totals.delta.time}} h</div>
              </div>
            </td>
            <td pFrozenColumn class="mt-last-left-frozen-column">
              <div class="w-full flex justify-content-end">
                <div class="mt-pct-box w-fit">
                  {{rowData.totals.deltaPct | percent}}
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-node="node" let-rowData="rowData">
          <tr>
            <td *ngFor="let col of weekDays; let i = index"
                [style.font-weight]="rowNode.node.expanded ? '500' : 'normal'">
              <div class="date-time-cell" [class.project-date-time-cell]="rowNode.node.type === 'project'"
                   [pTooltip]="timeEntriesTooltip(rowNode.node, rowData.values[i].timeEntries)!"
                   tooltipPosition="bottom"
                   (click)="rowNode.node.type === 'project'
                     ? te.fetchCategoryRowAndToggle(rowData.teamMemberId, rowData.projectId, this.periods[i].start)
                     : null">
                {{ rowData.values[i].actualTime }}</div>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  `,
  styles: [`
    .date-time-cell {
      width: fit-content;
      padding: 0 .2rem;
    }

    .project-date-time-cell {
      color: var(--primary-color);
      cursor: pointer;
      border: 1px solid transparent;
      transition: .3s background-color linear;
    }

    .project-date-time-cell:hover {
      background-color: var(--surface-200);
      border: 1px solid var(--surface-300);
      border-radius: 3px;
      padding: .2rem .2rem;
    }

    :host ::ng-deep p-treeTable tbody tr {
      height: 48px;
    }

    :host ::ng-deep p-treeTable th {
      background: #F0F5F9 !important;
    }
  `]
})
export class TimeApprovalsTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() state!: TApprovalsState | null;
  @Input() period!: IPeriod;
  @Input() lens!: ILens;
  @Input() query: TQueryExpression | null = null;
  loading = false;
  data: Array<TreeNode<IIdentified>> = [];
  selection: any = []; // Array<TreeNode<IIdentified>> = [];
  pageSize = 10;
  total = 0;
  search!: string;

  periodRange?: IPeriodRange;

  currentMenuRowData!: any;
  rowMenuItems: Array<MenuItem> = [];
  tableMenu: Array<MenuItem> = [];
  tableQuery: TableQuery = new TableQuery();
  @ViewChild(TreeTable, {static: false}) tableComponent!: TreeTable;

  constructor(public propertyOptions: EntityPropertyOptionsService,
              private resource: AppResourceService) {
  }

  ngOnInit(): void {
    this.tableMenu = [
      ...(this.state === 'Unsubmitted' ? [{
        id: 'submit' as TAction,
        label: 'Submit', icon: 'pi pi-check',
        disabled: true,
        command: (event: any) => this.performTeamMemberAction(event.originalEvent, this.selection as any, 'submit')
      }, {
        id: 'submitAndApprove' as TAction,
        label: 'Submit and Approve', icon: 'pi pi-thumbs-up',
        disabled: true,
        command: (event: any) => this.performTeamMemberAction(event.originalEvent, this.selection as any, 'submitAndApprove')
      }] : []),
      ...(this.state === 'Pending' ? [{
        id: 'approve' as TAction,
        label: 'Approve', icon: 'pi pi-thumbs-up',
        disabled: true,
        command: (event: any) => this.performTeamMemberAction(event.originalEvent, this.selection as any, 'approve')
      }, {
        id: 'reject' as TAction,
        label: 'Reject', icon: 'pi pi-undo',
        disabled: true,
        command: (event: any) => this.performTeamMemberAction(event.originalEvent, this.selection as any, 'reject')
      }] : []),
      ...(this.state === 'Approved' ? [{
        id: 'reject' as TAction,
        label: 'Reject', icon: 'pi pi-undo',
        disabled: true,
        command: (event: any) => this.performTeamMemberAction(event.originalEvent, this.selection as any, 'reject')
      }] : []),
      {
        id: 'delete' as TAction,
        label: 'Delete', icon: 'pi pi-trash',
        disabled: true,
        command: (event: any) => this.performTeamMemberAction(event.originalEvent, this.selection as any, 'delete')
      }
    ];
  }

  onShowRowMenu(node: TreeNode, rowData: any): void {
    if (node.type === 'teamMember') {
      this.rowMenuItems = [
        ...(rowData.state === 'Unsubmitted' ? [{
          id: 'submit',
          label: 'Submit', icon: 'pi pi-check',
          command: (event: any) => this.performTeamMemberAction(event.originalEvent, [node], 'submit')
        }, {
          id: 'submitAndApprove',
          label: 'Submit and Approve', icon: 'pi pi-thumbs-up',
          command: (event: any) => this.performTeamMemberAction(event.originalEvent, [node], 'submitAndApprove')
        }] : []),
        ...(rowData.state === 'Pending' ? [{
          id: 'approve',
          label: 'Approve', icon: 'pi pi-thumbs-up',
          command: (event: any) => this.performTeamMemberAction(event.originalEvent, [node], 'approve')
        }, {
          id: 'reject',
          label: 'Reject', icon: 'pi pi-undo',
          command: (event: any) => this.performTeamMemberAction(event.originalEvent, [node], 'reject')
        }] : []),
        ...(rowData.state === 'Approved' ? [{
          id: 'reject',
          label: 'Reject', icon: 'pi pi-undo',
          command: (event: any) => this.performTeamMemberAction(event.originalEvent, [node], 'reject')
        }] : []),
        {
          id: 'delete' as TAction,
          label: 'Delete', icon: 'pi pi-trash',
          command: (event: any) => this.performTeamMemberAction(event.originalEvent, [node], 'delete')
        }
      ];
    }
  }

  ngAfterViewInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    onComponentPropChanged(changes, ['period', 'query', 'lens'], () => this.load())
  }

  load(event?: LazyLoadEvent): void {
    setTimeout(() => this.loading = true);
    const searchRequest: ISearchRequest = this.tableQuery.prepareQuery(
      event || this.tableComponent.createLazyLoadMetadata(), {
        pageSize: this.pageSize,
        search: this.search
      }
    );
    this.prepareSearchRequestQuery(searchRequest);
    searchRequest.param = {
      period: this.period,
      data: {lens: this.lens.id}
    } as TTimeApprovalsRequest;
    this.resource.searchTimeApprovals(
      searchRequest
    ).pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((response) => {
      this.total = response.total;
      this.periodRange = response.extraData!;
      const data = response.results.map((tm) => {
        const node: TreeNode<IIdentified> = {
          key: tm.teamMemberId,
          type: 'teamMember',
          data: {...tm, ...{dataKey: tm.teamMemberId}},
          selectable: true,
          leaf: false,
          children: tm.projects.map((p) => ({
            key: tm.teamMemberId + p.projectId,
            type: 'project',
            leaf: true,
            selectable: false,
            data: {...p, ...{dataKey: tm.teamMemberId + p.projectId}, ...{teamMemberId: tm.teamMemberId}}
          }))
        };
        return node;
      });
      this.data = data;
      this.selection = [];
    });
  }

  prepareSearchRequestQuery(searchRequest: ISearchRequest): ISearchRequest {
    if (searchRequest.query || this.query) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: [
          ...(this.query?.predicates || []),
          ...((searchRequest.query as TQueryExpression)?.predicates || [])
        ]
      };
      searchRequest.query = query;
    }
    console.log('search request', searchRequest);
    return searchRequest;
  }

  get weekDays(): Array<string> {
    return this.periodRange?.groups[0].columns ?? [];
  }

  get periods(): Array<{ start: Date; end: Date; }> {
    return this.periodRange?.periods ?? [];
  };

  onSelectionChange(): void {
    this.selection = filter(this.selection, {type: 'teamMember'});
    this.updateMenu();
  }

  timeEntriesTooltip(node: TreeNode, timeEntries: Array<ITimeEntry>): string | null {
    if (node.type !== 'project') {
      return null;
    }
    return timeEntries.map((te) => `\u2666  ${te.departmentName} | ${te.actualTime}`)
      .join('\n');
  }

  updateMenu(): void {
    for (const mi of this.tableMenu) {
      mi.disabled = !this.selection.length;
    }
    this.tableMenu = [...this.tableMenu];
  }

  performTeamMemberAction(ev: any, teamMemberNodes: Array<TreeNode<TTimeApprovalsTeamMember>>, action: TAction): void {
    if (!!!teamMemberNodes?.length) {
      return;
    }
    const perform = () => {
      this.tbd();
    };
    const actionText = startCase(action);
    const itemsText = teamMemberNodes.length === 1 ? `${teamMemberNodes[0].data!.teamMemberName}` : `${teamMemberNodes.length} selected Team Members`
    ServiceLocator.confirm({
      target: ev.target,
      key: 'g-popup',
      header: `${actionText} week ${this.periodRange!.groups[0].group}`,
      message: `Are you sure you want to ${actionText} week [${this.periodRange!.groups[0].group}] for ${itemsText}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        perform();
      }
    });
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet on the backend side'
    });
  };
}
