import {IDbSchema} from '../../../api/shared/dev-tools/db-api';
import {IDictionary} from '../../../common/types';

export interface ITableRow {
  dataKey: string;

  [key: string]: any;
}


const ST_KEY = {
  dbTable: 'taas@db_tbl',
  dbReferencedTable: 'taas@db_tbl_parent',
  dbReferencingTable: 'taas@db_tbl_child',
  dbSchemaHashes: 'taas@db_schema_hashes'
};



export class DbStorage {
  static getTableKey(tableName: string): string {
    return `${ST_KEY.dbTable}::${tableName}`;
  }

  static getReferencedTableKey(tableName: string): string {
    return `${ST_KEY.dbReferencedTable}::${tableName}`;
  }

  static getReferencingTableKey(tableName: string): string {
    return `${ST_KEY.dbReferencingTable}::${tableName}`;
  }

  static saveSchemaHashes(schema: IDbSchema): void {
    const stObj: IDictionary<string> = {};
    for (const table of schema.tables) {
      stObj[table.name] = table.hash;
    }
    window.localStorage.setItem(ST_KEY.dbSchemaHashes, JSON.stringify(stObj));
  }

  static readSchemaHashes(): IDictionary<string> {
    const stJson = window.localStorage.getItem(ST_KEY.dbSchemaHashes);
    return !!stJson ? JSON.parse(stJson) : {};
  }

  static checkSavedTables(schema: IDbSchema): void {
    const currentHashes = DbStorage.readSchemaHashes();
    for (const table of schema.tables) {
      if (currentHashes[table.name] && currentHashes[table.name] !== table.hash) {
        DbStorage.clearTableState(table.name);

      }
    }
    DbStorage.saveSchemaHashes(schema);
  }

  static clearTableStates(schema: IDbSchema): void {
    for (const table of schema.tables) {
      DbStorage.clearTableState(table.name);
    }
  }

  static clearTableState(tableName: string): void {
    window.localStorage.removeItem(DbStorage.getTableKey(tableName));
    window.localStorage.removeItem(DbStorage.getReferencedTableKey(tableName));
    window.localStorage.removeItem(DbStorage.getReferencingTableKey(tableName));
  }
}
