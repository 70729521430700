import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AppResourceService} from '../../app.resource.service';
import {finalize} from 'rxjs';
import {TreeNode} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {filter, find, forEach, some, sumBy} from 'lodash';
import {IPeriod} from '../../api/shared/app-domain/common';
import {getCurrentPeriodValue} from '../../common/components/period-chooser.component';
import {TreeTable} from 'primeng/treetable';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';


@Component({
  selector: 'app-holiday-universe-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="mt-page-title">Holidays</div>
            <div class="text-gray-600 text-sm">Holidays count: {{treeTable?.filteredNodes?.length || data.length}}</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <app-period-chooser [(ngModel)]="selectedPeriod" [unitFilter]="['Year']" [showUnits]="false"
                                (ngModelChange)="load()"
                                [minDate]="minDate" [maxDate]="maxDate"></app-period-chooser>
            <button pButton pRipple label="New Calendar"
                    class="ml-5"
                    icon="pi pi-plus" (click)="newHoliday()"></button>
          </div>
        </div>
      </div>
    </div>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container class="mt-3 shadow-1 bg-white p-3 pb-3">
      <p-treeTable [value]="data" [scrollable]="true" scrollHeight="calc(100vh - 350px)">
        <ng-template pTemplate="header">
          <tr>
            <th>Date | Holiday</th>
            <th>Holidays Count</th>
            <th>Countries/Calendars count</th>
          </tr>
          <tr>
            <th class="p-fluid">
              <span class="p-input-icon-right">
                <i class="pi pi-times" (click)="holidayModel.control.setValue(null)"></i>
                <input #holidayModel="ngModel" pInputText [(ngModel)]="filters.holidayName"
                       appChangesDebounce (debounced)="filter()" nullable
                       placeholder="Search holiday"/>
              </span>
            </th>
            <th></th>
            <th class="p-fluid flex align-items-center">
              <div class="mr-1 flex-1">
                <p-multiSelect [(ngModel)]="filters.countries"
                               [options]="$any(optionsService.getOptions('countries') | async)"
                               optionLabel="name"
                               optionValue="code"
                               [showToggleAll]="true"
                               placeholder="Select country(-es)"
                               appendTo="body"
                               [showClear]="true"
                               (ngModelChange)="filter()">
                  <ng-template let-item pTemplate="item">
                    <div class="flex align-items-center">
                      <img class="mr-2" [src]="item.flag" width="24"/>
                      <div>{{item.name}}</div>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </div>
              <div class="ml-1 flex-1">
                <span class="p-input-icon-right">
                  <i class="pi pi-times" (click)="calendarModel.control.setValue(null)"></i>
                  <input #calendarModel="ngModel" pInputText [(ngModel)]="filters.calendars"
                         appChangesDebounce (debounced)="filter()" nullable
                         placeholder="Search calendar"/>
                </span>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode" *ngIf="!rowData.isCountries" [class.expanded]="rowData.isDay && rowNode.node.expanded">
            <td>
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              <span [class.font-bold]="rowNode.node.expanded">{{rowData.name}}</span>
            </td>
            <td>
              {{rowData.count}}
            </td>
            <td>
              {{rowData.countries}} / {{rowData.calendars}}
            </td>
          </tr>
          <tr [ttRow]="rowNode" *ngIf="rowData.isCountries">
            <td colspan="3">
              <div class="ml-8">
                <p-tabView>
                  <p-tabPanel header="Countries">
                    <div class="flex flex-wrap">
                      <a *ngFor="let country of rowData.countries" class="chip inline-flex align-items-center mt-link"
                         [routerLink]="['/settings/holiday-calendars']" [queryParams]="{colFilter_countryName: country.name}">
                        <img class="mr-2" [src]="country.flag" width="16">{{country.name}}
                      </a>
                    </div>
                  </p-tabPanel>
                  <p-tabPanel header="Calendars">
                    <div class="flex flex-wrap">
                      <a *ngFor="let calendar of rowData.calendars"
                         class="chip calendar-chip inline-flex align-items-center mt-link"
                         [routerLink]="['/settings/holiday-calendars/edit', calendar.id]">
                        {{calendar.name}}
                      </a>
                    </div>
                  </p-tabPanel>
                </p-tabView>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>
    </div>
  `,
  styles: [`
    .chip {
      border-radius: 12px;
      background-color: #DEE2E6;
      padding: 0 10px 0;
      margin: 3px 0 3px;
      margin-right: .5rem;
    }

    .calendar-chip {
      padding: 2px 10px 2px;
    }

    :host ::ng-deep p-treetable table .p-treetable-tbody > tr:nth-child(even) {
      background: #fcfcfc;
    }

    :host ::ng-deep .p-treetable .p-treetable-tbody > tr.expanded {
      // background: #004f79;
      background-image: linear-gradient(to bottom, #416D86 0%, #6EA6C6 100%);
      color: #FFF;
    }

    :host ::ng-deep .p-treetable .p-treetable-tbody > tr.expanded > td .p-treetable-toggler {
      color: #FFF;
    }
  `],
  providers: [
    EntityPropertyOptionsService
  ]
})
export class HolidaysUniversePageComponent implements OnInit {
  loading = false;
  data: Array<TreeNode> = [];
  minDate = new Date(new Date().getFullYear() - 5, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 15, 0, 1);
  selectedPeriod: IPeriod = getCurrentPeriodValue('Year');
  @ViewChild(TreeTable) treeTable?: TreeTable;

  filters: {
    holidayName?: string;
    countries?: Array<string>;
    calendars?: string;
  } = {};

  constructor(private router: Router,
              private resource: AppResourceService,
              public optionsService: EntityPropertyOptionsService) {
  }

  ngOnInit(): void {
    this.load();
  }


  load(): void {
    this.loading = true;
    this.resource.getHolidaysUniverse(this.selectedPeriod.start.getFullYear())
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        const data: Array<TreeNode> = [];
        response.forEach((holidayByDate) => {
          const node: TreeNode = {
            data: {
              isDay: true,
              name: new DatePipe('en-US').transform(holidayByDate.date, 'MMM dd'),
              count: holidayByDate.holidays.length,
              countries: sumBy(holidayByDate.holidays, ((dh) => dh.countries.length)),
              calendars: sumBy(holidayByDate.holidays, ((dh) => dh.calendars.length)),
            }
          };
          node.children = holidayByDate.holidays.map((dh) => {
            const nestedNode: TreeNode = {
              data: {
                isHoliday: true,
                name: dh.name,
                count: null,
                countries: dh.countries.length,
                calendars: dh.calendars.length
              }
            };
            nestedNode.children = [{
              data: {
                isCountries: true,
                name: null,
                count: null,
                countries: dh.countries,
                calendars: dh.calendars
              }
            }];
            return nestedNode;
          });

          data.push(node);
        });
        this.data = data;
      });
  }

  newHoliday(): void {
    this.router.navigate(['/settings/holiday-calendars/edit/new'])
  }

  filter(): void {
    const filtered: Array<TreeNode> = [];
    let hasFilter = false;
    if (this.filters.countries?.length || this.filters.calendars || this.filters.holidayName) {
      hasFilter = true;
      forEach(this.data, (nDate) => {
        const nHolidays: Array<TreeNode> = [];
        forEach(nDate.children, (nHoliday) => {
          let found = true;
          if (this.filters.countries?.length) {
            if (!find(nHoliday.children![0].data.countries, (c) => this.filters.countries?.includes(c.code))) {
              found = false;
            }
          }
          if (this.filters.calendars) {
            if (!find(nHoliday.children![0].data.calendars,
              (c) => c.name.toLowerCase().includes(this.filters.calendars!.toLowerCase()))) {
              found = false;
            }
          }
          if (this.filters.holidayName) {
            // if (!find(nHoliday!,
            //   (c) => c.data.name.toLowerCase().includes(this.filters.holidayName!.toLowerCase()))) {
            if (!nHoliday.data.name.toLowerCase().includes(this.filters.holidayName!.toLowerCase())) {
              found = false;
            }

          }
          if (found) {
            nHolidays.push(nHoliday);
          }
        })
        if (nHolidays.length) {
          filtered.push({
            ...nDate,
            ...{
              children: nHolidays
            }
          });
        }
      });
    }
    const nodes = hasFilter ? filtered : this.data;
    this.treeTable!.filteredNodes = hasFilter ? filtered : null as any;
    this.treeTable!.tableService.onUIUpdate(nodes);
    this.treeTable!.updateSerializedValue();

    if (this.treeTable!.scrollable) {
      this.treeTable!.resetScrollTop();
    }
  }
}
