import {Component, ViewChild} from '@angular/core';
import {AppResourceService} from '../../app.resource.service';
import {CompaniesTableComponent} from './companies-table.component';


@Component({
  selector: 'app-companies-page',
  template: `
    <app-common-page entityName="Company" [entityResource]="resource.companies"
                     (onNewEntity)="table.openCreateOrUpdateDialog(null)">
      <ng-template pTemplate="content">
        <app-companies-table [filterByQueryParams]="[
          {field: 'status', matchMode: 'equals'},
          {field: 'types', matchMode: 'contains', isValueArray: true}
        ]"></app-companies-table>
      </ng-template>
    </app-common-page>
  `
})
export class CompaniesPageComponent {
  @ViewChild(CompaniesTableComponent) table!: CompaniesTableComponent;

  constructor(public resource: AppResourceService) {
  }
}
