import {Directive, ElementRef, OnInit} from '@angular/core';
import {NgControl} from '@angular/forms';
import {isString} from 'lodash';

@Directive({
  selector: '[nullable]'
})
export class NullableDirective implements OnInit {
  constructor(private el: ElementRef, private ngControl: NgControl) {
  }

  ngOnInit(): void {
    this.ngControl.valueChanges?.subscribe((value) => {
      if (value != null) {
        if (Array.isArray(value)) {
          if (!value.length) {
            this.ngControl.control?.setValue(null);
          }
        } else if (isString(value)) {
          if (value?.trim() === '') {
            this.ngControl.control?.setValue(null);
          }
        }
      }
    });
  }
}
