import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IViewAsItem} from '../../api/shared/user-api';
import {AppConfigService} from '../../app-config.service';
import {ViewAsChooserComponent} from './view-as-chooser.component';


@Component({
  selector: 'app-view-as-alert',
  template: `
    <p-messages *ngIf="viewAsItem != null && show" severity="info">
      <ng-template pTemplate>
        <span class="p-message-icon pi pi-info-circle"></span>
        <span class="p-message-summary">
          {{appConfig.userProfile?.firstName}} {{appConfig.userProfile?.lastName}}
        </span>
        <span class="ml-2">
          You are viewing as
          {{viewAsItem!.name}}, from {{viewAsItem!.from}}.
          <span class="ml-2 cursor-pointer text-primary font-medium" (click)="onResumeViewingAsYourself()">
            Resume viewing as yourself.</span>
        </span>
        <button pButton type="button" pRipple class="p-message-close p-link" icon="pi pi-times"
                (click)="show=false"></button>
      </ng-template>
    </p-messages>

  `
})
export class ViewAsAlertComponent implements OnChanges {
  @Input() viewAsItem: IViewAsItem | null = null;
  @Input() viewAsChooser!: ViewAsChooserComponent
  show = false;

  constructor(public appConfig: AppConfigService) {
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewAsItem']) {
      this.show = !this.appConfig.isViewAsMe(this.viewAsItem);
    }
  }

  onResumeViewingAsYourself(): void {
    if (!!this.viewAsChooser) {
      this.viewAsChooser.resumeViewingAsYourself();
    }
  }
}
