import {Component, Input, ViewChild} from '@angular/core';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {EntityFormControlBaseComponent} from '../common/entity-form-control-base.component';
import {ITeamMember} from '../../api/shared/app-domain/team';
import {TreeNode} from 'primeng/api';
import {TPaymentTerm} from '../../api/shared/app-domain/common';
import {FormControlWrapperComponent} from '../../common/components/form-control-wrapper.component';

@Component({
  selector: 'app-team-member-form-control',
  template: `
    <app-team-participant-form-control [name]="$any(name)"></app-team-participant-form-control>
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'status'" [controlName]="name" controlType="dropdown"
                                [options]="propertyOptions.teamMemberStatuses"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'title'" [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'city'" [controlName]="name" controlType="dropdown"
                                [dropdownEditable]="true"
                                [optionsFilter]="true" optionsFilterBy="city" optionLabel="city" optionValue="city"
                                [options]="$any(propertyOptions.getOptions('teamMemberCities') | async)"
                                [virtualScroll]="true"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'estimatedAvailableDate'" dataType="date"
                                [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'hiredDate'" dataType="date"
                                [controlName]="name"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'type'" [controlName]="name" controlType="dropdown"
                                [options]="propertyOptions.teamMemberTypes"
                                [optionIcon]="propertyOptions.getTeamMemberTypeIcon">
      </app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'statusChangedReason'" [controlName]="name" controlType="treeSelect"
                                [options]="statusChangedReasonOptions"
                                [optionsFilter]="true"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'costRateDefaultPlus'" [controlName]="name"
                                label="Default Cost Rate Plus"
                                dataType="currency"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'defaultHourlyRate'" [controlName]="name" label="Default Billing Rate"
                                dataType="currency"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'weeklyCapacityMin'" [controlName]="name" suffix=" h"
                                [showButtons]="true" [min]="0"
                                dataType="number"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'weeklyCapacityTarget'" [controlName]="name" suffix=" h"
                                [showButtons]="true" [min]="0"
                                dataType="number"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'weeklyCapacityMax'" [controlName]="name" suffix=" h"
                                [showButtons]="true" [min]="0"
                                dataType="number"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'paymentTerms'" [controlName]="name" controlType="dropdown"
                                [options]="paymentTermsOptions"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'netTerms'" [controlName]="name" controlType="dropdown"
                                [options]="propertyOptions.netTerms | options"></app-form-control-wrapper>

      <app-form-control-wrapper *ngSwitchCase="'pto'" label="PTO" [controlName]="name" suffix=" d" placeholder="&infin;"
                                [showButtons]="true" [min]="0"
                                dataType="number"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'ptoFrom'" label="PTO starting from" dataType="date" placeholder="All past and future"
                                [controlName]="name"></app-form-control-wrapper>

      <app-form-control-wrapper *ngSwitchCase="'defaultWorkweekId'" label="Default Workweek"
                                [controlName]="name" controlType="dropdown"
                                [options]="propertyOptions.weekdays"
                                [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                [options]="$any(propertyOptions.getOptions('defaultWorkweeks') | async)">
      </app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'isWorkweekOverridden'" [controlName]="name" label="Override Default Workweek?"
                                dataType="boolean">
      </app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'isActivelyStuffed'" [controlName]="name" label="Actively Stuffed"
                                dataType="boolean">
      </app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'gustoRequired'" [controlName]="name" dataType="boolean">
      </app-form-control-wrapper>
      <div *ngSwitchCase="'holidayCalendarId'">
        <app-form-control-wrapper #ctrl [controlName]="name" label="Holiday Calendar"
                                  controlType="dropdown"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [options]="$any(propertyOptions.getOptions('holidayCalendars') | async)"
                                  [showAddButton]="false"></app-form-control-wrapper>
      </div>
      <app-form-control-wrapper *ngSwitchCase="'isHolidayCalendarOverridden'" [controlName]="name" label="Override Holiday Calendar?"
                                dataType="boolean">
      </app-form-control-wrapper>

      <div *ngSwitchCase="'userRoles'">
        <app-form-control-wrapper [controlName]="name" controlType="multiselect"
                                  [options]="($any(propertyOptions.getOptions('userRoles') | async) || [])"
                                  [optionsFilter]="true"
                                  optionsFilterBy="id" optionLabel="name" optionValue="id"
                                  [showAddButton]="false"></app-form-control-wrapper>
      </div>
      <div *ngSwitchCase="'contractorCompanyId'">
        <app-form-control-wrapper #ctrl [controlName]="name" label="Contractor Company"
                                  controlType="dropdown"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [options]="$any(propertyOptions.getOptions('teamMemberCompanies') | async)"
                                  [showAddButton]="false"></app-form-control-wrapper>
      </div>
      <div *ngSwitchCase="'paymentRecipientId'">
        <app-form-control-wrapper #ctrl [controlName]="name" label="Payment Recipient"
                                  controlType="dropdown"
                                  [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                                  [options]="$any(propertyOptions.getOptions('teamMemberPaymentRecipients') | async)"
                                  [showAddButton]="false"></app-form-control-wrapper>
      </div>
      <app-form-control-wrapper *ngSwitchCase="'mtEmailType'" [controlName]="name" controlType="dropdown"
                                label="MT Email Type"
                                [options]="propertyOptions.mtEmailTypes"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'mtEmail'" [controlName]="name"
                                label="MT Email"></app-form-control-wrapper>

    </ng-container>
  `
})
export class TeamMemberFormControlComponent extends EntityFormControlBaseComponent<ITeamMember> {
  @Input() statusChangedReasonOptions: Array<TreeNode> = [];
  @Input() paymentTermsOptions: Array<TPaymentTerm> = [];

  constructor(propertyOptions: EntityPropertyOptionsService) {
    super(propertyOptions);
  }

}

