import {Component, ViewChild} from '@angular/core';
import {APPROVAL_STATES, IPeriod, TApprovalsState} from '../../../api/shared/app-domain/common';
import {getCurrentPeriodValue} from '../../../common/components/period-chooser.component';
import {IViewAsItem} from '../../../api/shared/user-api';
import {AppConfigService} from '../../../app-config.service';
import {TQueryExpression} from '../../../api/shared/search-api';
import {EntityPropertyOptionsService} from '../../common/entity-property-options.service';
import {ActivatedRoute, Router} from '@angular/router';
import {getApprovalsTabMenu} from '../common';
import {TeamMembersFilterComponent} from '../../team/team-members-filter.component';
import {MenuItem} from 'primeng/api';


@Component({
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="flex align-items-baseline">
              <div class="mt-page-title">{{isApprovalMode ? 'Time Off Approvals' : 'Annual Log'}}</div>
              <div class="text-gray-700 ml-3">
                {{tmf.currentStatusesAsText}}
              </div>
            </div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
            <p-menubar *ngIf="isApprovalMode" class="mt-page-menu-bar" [model]="tabMenuItems"></p-menubar>
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
          </div>
        </div>
      </div>
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-7 flex align-items-center">
            <app-view-as-chooser #viewAsChooser [(ngModel)]="viewAsItem" (ngModelChange)="onChangeViewAs()">
            </app-view-as-chooser>
            <app-team-members-filter #tmf (applyFilter)="applyFilters()" [areFieldsEmbedded]="true"></app-team-members-filter>
          </div>
          <div class="col-5 flex justify-content-end">
            <app-period-chooser [(ngModel)]="period" [unitFilter]="['Month', 'Quarter', 'Year']" initialPeriodType="Year"></app-period-chooser>
          </div>
        </div>
      </div>
    </div>
    <app-view-as-alert [viewAsItem]="viewAsItem" [viewAsChooser]="viewAsChooser"></app-view-as-alert>
    <div class="mt-3">
      <div class="grid">
        <div class="col-3" *ngFor="let i of 4 | arrayOf">
          <div class="shadow-1"
               style="background: #FFF; border: 1px solid var(--surface-300); height: 10rem; font-size: 12px; padding: 2rem; color: var(--gray-500)">
            TBD
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 shadow-1 bg-white p-3 pb-2">
      <app-time-offs-table *appRecreateViewKey="tab" [isApprovalMode]="isApprovalMode" [state]="tab" [query]="query"
                                [period]="period"></app-time-offs-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
  ]

})
export class TimeOffsPageComponent {
  tabMenuItems: Array<MenuItem> = [];
  tab: TApprovalsState | null = null;

  period: IPeriod = getCurrentPeriodValue('Year');
  viewAsItem: IViewAsItem | null = null;

  query: TQueryExpression | null = null;
  @ViewChild(TeamMembersFilterComponent) teamMemberFilter?: TeamMembersFilterComponent;
  isApprovalMode = false;

  constructor(public appConfig: AppConfigService,
              public propertyOptions: EntityPropertyOptionsService,
              public activatedRoute: ActivatedRoute,
              public router: Router) {
    this.activatedRoute.data.subscribe((data) => {
      this.isApprovalMode = !!data['isApprovalMode'];
      if (this.isApprovalMode) {
        this.tabMenuItems = getApprovalsTabMenu('/time-off-approvals');
      }
    });
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (this.isApprovalMode) {
        const tab = params.get('tab') as TApprovalsState | 'All';
        if (!!!tab || ![...APPROVAL_STATES, 'All'].includes(tab)) {
          this.router.navigate([],
            {relativeTo: this.activatedRoute, queryParams: {tab: 'All'}, queryParamsHandling: 'merge'});
        } else {
          this.tab = tab === 'All' ? null : tab;
        }
        this.applyFilters();
      }
    });
    this.viewAsItem = this.appConfig.viewAsMe;
  }

  onChangeViewAs(): void {
  }

  applyFilters(): void {
    if (!!this.tab || this.teamMemberFilter?.hasFilter()) {
      const query: TQueryExpression = {
        logical: 'and',
        predicates: this.teamMemberFilter?.hasFilter() ? this.teamMemberFilter!.query!.predicates : []
      };
      if (!!this.tab) {
        query.predicates.push({
          field: 'state',
          operator: 'equals',
          value: this.tab
        });
      }
      this.query = query;
    } else {
      this.query = null;
    }
  }

}
