import {Component} from '@angular/core';

@Component({
  selector: 'app-landing-page',
  template: `
    <h3>Landing Page</h3>
  `
})
export class LandingPageComponent {

}
