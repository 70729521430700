import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren, EventEmitter, Input, Output,
  QueryList,
  TemplateRef
} from '@angular/core';
import {PrimeTemplate} from 'primeng/api';
import {copyToClipboard} from '../../common/util/util';
import {Router} from '@angular/router';
import {EntityEditorBaseComponent} from './entity-editor-base.component';


@Component({
  selector: 'app-entity-edit-dialog',
  template: `
    <p-dialog styleClass="mt-edit-dialog"
              [(visible)]="visible"
              [position]="$any(position)"
              [maximizable]="true"
              [closable]="true"
              [closeOnEscape]="false"
              [modal]="true" [style]="{width, minWidth}" appendTo="body">
      <ng-template pTemplate="header">
        <div class="flex align-items-center">
          <div class="text-2xl">{{title ?? editor?.title}}</div>
          <button *ngIf="editor?.getLink()" pButton pRipple icon="pi pi-link"
                  class="ml-2 p-button-rounded p-button-text" (click)="copyLink($event)"
                  pTooltip="Copy TaaS Link to Clipboard" tooltipPosition="bottom"></button>
          <button *ngIf="editor?.getLink()" pButton pRipple icon="pi pi-external-link"
                  class="ml-2 p-button-rounded p-button-text" (click)="navigateToLink()"
                  pTooltip="Go to Edit Page" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-check"
                  [disabled]="!editor?.canSubmit()"
                  (onClick)="performApply()"
                  label="Apply"
                  styleClass="p-button-text"></p-button>
        <p-button icon="pi  pi-times" (onClick)="visible=false" label="Cancel" styleClass="p-button-text"></p-button>
      </ng-template>
      <ng-container *ngIf="visible">
        <ng-container *ngTemplateOutlet="tplEditor; context:{$implicit: param}"></ng-container>
      </ng-container>
    </p-dialog>  `
})
export class EntityEditDialogComponent implements AfterContentInit {
  @Input() title?: string;
  @Input() position = 'center';
  @Input() width = '80vw';
  @Input() minWidth = '600px';
  @Output() protected apply = new EventEmitter<{param: any; data: any}>();
  protected param: any;
  protected visible = false;
  protected onApply?: (data: any) => void;
  protected tplEditor!: TemplateRef<any>;
  @ContentChildren(PrimeTemplate) protected templates!: QueryList<PrimeTemplate>;
  @ContentChild(EntityEditorBaseComponent) protected editor?: EntityEditorBaseComponent<any>;

  constructor(private cdr: ChangeDetectorRef, private router: Router) {
  }

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (!tpl.getType() || tpl.getType() === 'editor') {
        this.tplEditor = tpl.template;
      }
    });
  }

  show(param: any, onApply?: (data: any) => void): void {
    this.onApply = onApply;
    this.param = param;
    this.visible = true;
    this.cdr.detectChanges();
  }

  protected performApply(): void {
    if (this.editor?.canPerformApply()) {
      this.editor.apply((result: any) => {
        this.visible = false;
        this.apply.emit({param: this.param, data: result});
        if (this.onApply) {
          this.onApply(result);
        }
      });
    }
  }

  copyLink(event: MouseEvent): void {
    if (this.editor?.getLink()) {
      copyToClipboard(location.origin + this.editor.getLink());
    }
  }

  navigateToLink(): void {
    if (this.editor?.getLink()) {
      this.router.navigate([this.editor!.getLink()!]);
    }

  }

}
