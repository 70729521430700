import {COMMON_STATUSES, IIdentified, INamed, ITimestamped} from './common';

export const COMPANY_API_ENDPOINT = '/api/companies';

export const COMPANY_TYPE = [
  'Client',
  'Agency Reseller',
  'Talent Partner',
  'Sales Rep',
  'Referral Partner',
  'Contractor Company'
] as const;

export type TCompanyType = typeof COMPANY_TYPE[number];

export const COMPANY_STATUS = [
  ...COMMON_STATUSES
] as const;

export type TCompanyStatus = typeof COMPANY_STATUS[number];

export interface ICompany extends IIdentified, INamed, ITimestamped {
  types: Array<TCompanyType>;
  status: TCompanyStatus;
}

export type TCompanySave = Omit<ICompany, 'id' | 'types' | 'createdAt' | 'updatedAt'>;

