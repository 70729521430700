import {Component} from '@angular/core';

@Component({
  selector: 'app-team-member-edit-page',
  template: `
    <app-entity-edit-page pageUrl="/team-members/edit">
      <ng-template pTemplate let-param>
        <app-team-member-editor [param]="param"></app-team-member-editor>
      </ng-template>
    </app-entity-edit-page>
  `
})
export class TeamMemberEditPageComponent {
}

