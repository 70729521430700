import {TreeNode} from 'primeng/api';
import {transform} from 'lodash';
import {ILens, TModeLenses} from '../../api/shared/app-domain/common';

export function modeLensesToTreeNodes(modeLenses: TModeLenses): Array<TreeNode> {
  return transform<Array<ILens>, Array<TreeNode>>(modeLenses, (result, value, key) => {
    result.push({
      key: key,
      label: key,
      leaf: false,
      selectable: false,
      styleClass: 'font-medium',
      icon: 'pi pi-money-bill',
      children: value.map((lens) => ({
        key: lens.id,
        leaf: true,
        label: lens.label ?? lens.id,
        selectable: true,
        styleClass: 'font-normal',
        data: lens
      }))
    })
  }, []);
}

