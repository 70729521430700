import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef
} from '@angular/core';
import {MenuItem, PrimeTemplate} from 'primeng/api';
import {IViewAsItem} from '../../api/shared/user-api';
import {IEntityResource} from '../../app.resource.service';
import {AppConfigService} from '../../app-config.service';
import {plural, ServiceLocator} from '../../common/util/util';
import {IIdentified} from '../../api/shared/app-domain/common';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

@Component({
  selector: 'app-common-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-4">
            <div class="flex align-items-baseline">
              <div class="mt-page-title">{{pluralEntityName()}}</div>
            </div>
            <div class="text-gray-700 text-sm">{{totalCount}} {{pluralEntityName(true)}}</div>
          </div>
          <div class="col-4 flex justify-content-center align-items-center">
          </div>
          <div class="col-4 flex justify-content-end align-items-center">
            <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
            <button pButton pRipple label="Actions"
                    class="p-button-outlined ml-4"
                    [style]="{width: '115px'}"
                    icon="pi pi-chevron-down"
                    iconPos="right" (click)="actionsMenu.toggle($event)"></button>
            <button pButton pRipple [label]="'New' + entityName"
                    class="ml-2"
                    icon="pi pi-plus" (click)="onNewEntity.emit()"></button>
          </div>
        </div>
      </div>
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-7 flex">
            <app-view-as-chooser #viewAsChooser [(ngModel)]="viewAsItem" (ngModelChange)="onChangeViewAs()">
            </app-view-as-chooser>
          </div>
        </div>
      </div>
    </div>
    <app-view-as-alert [viewAsItem]="viewAsItem" [viewAsChooser]="viewAsChooser"></app-view-as-alert>
    <div class="mt-2 shadow-1 bg-white p-3 pb-2">
      <ng-container *ngTemplateOutlet="tplContent"></ng-container>
    </div>
  `
})
export class CommonPageComponent implements OnInit, AfterContentInit {
  @Input() entityName?: string;
  @Input() entityResource?: IEntityResource<any, IIdentified>;
  @Output() onNewEntity: EventEmitter<void> = new EventEmitter<void>();
  @Output() onActivateRoute: EventEmitter<{params: ParamMap; route: ActivatedRoute; router: Router}> = new EventEmitter();
  totalCount: number | null = null;
  @ContentChildren(PrimeTemplate) protected templates!: QueryList<PrimeTemplate>;
  protected tplContent!: TemplateRef<any>;



  actionItems: Array<MenuItem> = [
    {label: 'Sync Accounting', command: this.tbd},
    {label: 'Sync Timekeeping', command: this.tbd}
  ];

  viewAsItem: IViewAsItem | null = null;

  constructor(public appConfig: AppConfigService,
              public route: ActivatedRoute,
              public router: Router) {
    this.viewAsItem = this.appConfig.viewAsMe;
  }


  pluralEntityName(useCount = false): string {
    return useCount && !this.totalCount ? this.entityName! : plural(this.entityName!);
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.onActivateRoute.emit({params, route: this.route, router: this.router } )
    });
    this.fetchCount();
  }

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (tpl.getType() === 'content') {
        this.tplContent = tpl.template;
      }
    });
  }
  onChangeViewAs(): void {
  }


  fetchCount(): void {
    if (!!this.entityResource) {
      this.entityResource.getCount({})
        .subscribe((count) => this.totalCount = count);
    }
  }

  tbd(): void {
    ServiceLocator.message({
      severity: 'info',
      summary: '',
      detail: 'Not implemented yet'
    });
  }
}
