import {Component} from '@angular/core';
import {AppResourceService} from '../../../app.resource.service';
import {IProject} from '../../../api/shared/app-domain/project';
import {getProjectTypeIcon} from '../projects';

@Component({
  template: `
    <app-single-entity-details tabRoute="/projects/clients" [fetchEntity]="resource.getProjectClient.bind(resource)">
      <ng-template pTemplate="entityTpl" let-entity>
        <div class="flex align-items-center">
          <span class="mt-page-title mr-2">Client: <span class="text-gray-700">{{entity.name}}</span></span>
        </div>
      </ng-template>
      <ng-template pTemplate="detailsTpl" let-entity let-tab="tab" let-period="period">
        <app-single-client-details *ngIf="tab === 'details'"
                                    [entity]="entity" [period]="period"></app-single-client-details>
        <ng-container *ngIf="tab !== 'details'">
          TBD
        </ng-container>

      </ng-template>
    </app-single-entity-details>
  `
})
export class ClientDetailsPageComponent {
  constructor(public resource: AppResourceService) {
  }

  getProjectTypeIcon(entity: IProject): string | null {
    return getProjectTypeIcon(entity.type);
  }

}
