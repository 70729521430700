import {COMMON_STATUSES, IAvatar, IFullNamed, IIdentified, IMailable, INamed, ITimestamped, TUsState} from './common';


export const CONTACT_API_ENDPOINT = '/api/contacts';

export const CONTACT_TYPE = [
  'Team Member',
  'Team Candidate',
  'Sales Rep',
  'Affiliate',
  'Client',
  'Agency Reseller',
  'Talent Partner',
  'Referral Partner',
] as const;

export type TContactType = typeof CONTACT_TYPE[number];

export const CONTACT_STATUS = [
  ...COMMON_STATUSES
] as const;

export type TCompanyStatus = typeof CONTACT_STATUS[number];

export interface IContact extends IIdentified, INamed, IFullNamed, IMailable, IAvatar, ITimestamped {
  types: Array<TContactType>;
  status: TCompanyStatus;
  birthday?: Date;
  country: string;
  state?: TUsState;
  city: string;
  phone?: string;
}

export type TContactSave = Omit<IContact, 'id' | 'name' | 'types' | 'createdAt' | 'updatedAt'>;
