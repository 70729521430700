/* tslint:disable:object-literal-key-quotes */

export function hasPropertyWithType<T extends object, P extends keyof T>(
  obj: T,
  propName: P,
  type: string
): boolean {
  return typeof obj === 'object' && obj !== null && propName in obj && typeof obj[propName] === type;
}

export interface IIdentified {
  id: string;
}

export function isInstanceOfIdentified(obj: any): obj is IIdentified {
  return hasPropertyWithType<IIdentified, 'id'>(obj, 'id', 'string');
}

export interface IDraftable {
  isDraft: boolean;
}

export function isInstanceOfDraftable(obj: any): obj is IDraftable {
  return hasPropertyWithType<IDraftable, 'isDraft'>(obj, 'isDraft', 'boolean');
}


export interface INamed {
  name: string;
}

export interface IFullNamed {
  firstName: string;
  lastName: string;
}

export interface ITimestamped {
  createdAt: Date;
  updatedAt: Date;
}

export interface IMailable {
  email: string;
}

export interface IAvatar {
  avatar?: string;
}

export interface IPerson extends INamed, IAvatar {
  roleName: string;
  avatar?: string;
}


export interface ICountry extends INamed {
  code: string;
  flag?: string;
}

export interface IBudgetComponents {
  actualFees: number;
  budget: number;
  remaining: number;
  remainingPct: number;
  costs: number;
  billable: number;
  netIncome: number;
  netIncomePct: number;
}


export interface IBudgetValue {
  currency?: number | null;
  time?: number | null;
  pct?: number | null;
}

export interface IPeriodTotals {
  actual: IBudgetValue;
  budget: IBudgetValue;
  delta: IBudgetValue;
  deltaPct: number;
  future?: IBudgetValue;
  eac?: IBudgetValue;
}

export const NAV_PERIOD_TYPES = ['Day', 'Week', 'Month', 'Quarter', 'Year'] as const;
export type TNavPeriodType = typeof NAV_PERIOD_TYPES[number];
export const PERIOD_TYPES = [...NAV_PERIOD_TYPES, ...['All Time', 'Custom']] as const;
export type TPeriodType = typeof PERIOD_TYPES[number];

export interface IPeriod {
  start: Date;
  end: Date;
  type: TPeriodType;
}

export function isInstanceOfPeriod(p: any): p is IPeriod {
  return !!p && p.start instanceof Date && p.end instanceof Date && PERIOD_TYPES.includes(p.type);
}

export interface IPeriodRequest<D = any> {
  period: IPeriod;
  data?: D;
}

export const AVAILABILITIES = ['Part-time', 'Full-time'] as const;
export type TAvailability = typeof AVAILABILITIES[number];


export const PAYMENT_TERMS = ['Arrears', 'Advance'] as const;
export type TPaymentTerm = typeof PAYMENT_TERMS[number];

export const NET_TERMS = [0, 5, 10, 15, 30, 45, 60, 75, 90] as const;
export type TNetTerm = typeof NET_TERMS[number];

export const WEEK_DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const;
export type TWeekDay = typeof WEEK_DAYS[number];

export const MT_EMAIL_TYPE = ['Gmail', 'Ionos'] as const;
export type TMtEmailType = typeof MT_EMAIL_TYPE[number];

export const COMMON_STATUSES = ['Active', 'Archived'] as const;
export type TCommonStatus = typeof COMMON_STATUSES[number];

export const HUBSPOT_STAGES = [
  'Subscriber', 'Lead', 'Marketing Qualified Lead', 'Sales Qualified Lead', 'Opportunity', 'Customer', 'Evangelist', 'Other'
] as const;
export type THubspotStage = typeof HUBSPOT_STAGES[number];

export const US_STATES = [
  'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI',
  'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO',
  'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA',
  'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
] as const;

export type TUsState = typeof US_STATES[number];

export interface IImageUploadMeta {
  mimetype: string;
}

export const IMAGE_API_ENDPOINTS = {
  upload: '/api/images/upload'
};


export const FILE_STORAGE_API_ENDPOINTS = {
  upload: '/api/file-storage/upload'
};

export interface IImageServiceResponse {
  url: string;
}

export interface IUsefulLinks {
  toptal?: string;
  linkedIn?: string;
  website?: string;
  github?: string;
  skype?: string;
  slack?: string;
  timekeeping?: string;
}

export interface ITeamParticipant extends IIdentified, IFullNamed, IMailable, IPerson, ITimestamped, IDraftable {
  birthday?: Date;
  skills: Array<string>;

  roleId: string;

  country: string;
  state?: TUsState;
  city: string;
  timezone?: string;
  phone?: string;

  departmentId?: string;
  departmentName?: string;

  talentPartnerId?: string;
  talentPartnerName?: string;

  searchSource?: string;
  searchSourceUrl?: string;
  primarySourceUrl: string;
  searchSourceKeyword?: Array<string>;

  referralId?: string;
  referralName?: string;
  referralSourceUrl?: string;

  costRateDefault?: number;

  usefulLinks?: IUsefulLinks;

}

export interface ISimpleEntryEntity extends IIdentified, INamed {
}

export interface IUrlLink {
  url: string;
  icon?: string;
  label?: string;
}

export const COMMON_API_ENDPOINTS = {
  countries: '/api/common/countries',
  timeZones: '/api/common/time-zones',
};


export const LENSES = [
  'Billable Adjusted of Budget',
  'Billable Blended of Budget',
  'Billable od Budget',
  'Cost of Billable',
  'Hours Payable of Engagement',
  'Hours Payable of Max Capacity',
  'Hours Payable of Workweek',
  'Logged of Engagement',
  'Logged of Max Capacity',
  'Logged of Workweek',
  'Payable of Budget',

  'Net Utilization',
  'Billable Utilization',
  'Workweek',
  'Capacity',
  'Gross Profit Margin',
  'Allocation',
  'Forecast',
  'Budget'
] as const;

export type TLens = typeof LENSES[number];

export interface ILens {
  id: TLens;
  label?: string;
  actualLabel: string;
  budgetLabel: string;
}

export const LENS_MODES = [
  'Billable Blended',
  'Billable',
  'Fixed Adjusted',
  'Payable',
  'Payroll',
  'Payroll/Billable',
  'Project'
] as const;

export type TLensMode = typeof LENS_MODES[number];
export type TModeLenses = { [node in TLensMode]?: Array<ILens> };


export interface IAttachment {
  url: string;
  name: string;
  mimetype: string;
  size: number;
}

export const APPROVAL_STATES = ['Unsubmitted', 'Approved', 'Pending', 'Denied'] as const;
export type TApprovalsState = typeof APPROVAL_STATES[number];

export interface IApprovable {
  state: TApprovalsState;
}
