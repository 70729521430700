import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
  TemplateRef
} from '@angular/core';
import {EntityEditorComponent, IEntityEditorParam} from '../common/entity-editor.component';
import {ActivatedRoute, Router} from '@angular/router';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {combineLatest, debounceTime} from 'rxjs';
import {map} from 'rxjs/operators';
import {MenuItem, PrimeTemplate} from 'primeng/api';
import {find} from 'lodash';
import {IIdentified} from '../../api/shared/app-domain/common';

@Component({
  selector: 'app-entity-edit-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter">
          <div class="col-6">
            <div class="mt-page-title">{{editor?.title || 'Edit: Unknown'}}</div>
          </div>
          <div *ngIf="!editor?.hasBeenDeleted" class="col-6 flex justify-content-end">
            <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body"></p-menu>
            <button *ngIf="this.param?.id != null" pButton pRipple label="Actions"
                    class="p-button-outlined mr-2"
                    icon="pi pi-chevron-down"
                    iconPos="right" (click)="actionsMenu.toggle($event)"></button>
            <p-button icon="pi pi-check"
                      [disabled]="!canSubmit()"
                      (onClick)="performApply()"
                      [label]="isEditExisting() || param?.id == null ? 'Apply' : 'Duplicate'"></p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <ng-container *ngIf="param">
        <ng-container *ngTemplateOutlet="tplEditor; context:{$implicit: param}"></ng-container>
      </ng-container>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService
  ]
})
export class EntityEditPageComponent implements AfterContentInit, OnDestroy {
  @Input() pageUrl!: string;
  param?: IEntityEditorParam;
  @ContentChild(EntityEditorBaseComponent) protected editor?: EntityEditorComponent<IIdentified, IIdentified>;
  @ContentChildren(PrimeTemplate) protected templates!: QueryList<PrimeTemplate>;
  protected tplEditor!: TemplateRef<any>;

  checkActionsMenuPollingId: any = null;
  actionItems: Array<MenuItem> = [
    {
      id: 'edit',
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () =>
        this.router.navigate([this.pageUrl, this.param?.id])
    },
    {
      id: 'duplicate',
      label: 'Duplicate',
      icon: 'pi pi-clone',
      command: () =>
        this.router.navigate([this.pageUrl, this.param?.id], {queryParams: {duplicate: 'true'}})
    },
    {
      id: 'archive',
      label: 'Archive',
      icon: 'pi pi-server',
      command: () => this.editor?.archiveThis()
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: 'pi pi-trash',
      styleClass: 'mt-alert-menuitem',
      command: () => this.editor?.deleteThis()
    }
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(
        map(results => ({params: results[0], query: results[1]})), debounceTime(0)
      )
      .subscribe(results => {
        if (!!results.params['id']) {
          this.param = {
            id: results.params['id'] === 'new' ? null : results.params['id'],
            isDuplicate: results.query['duplicate'] === 'true'
          };
          this.processActionMenu();
        }
      });
  }

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (!tpl.getType() || tpl.getType() === 'editor') {
        this.tplEditor = tpl.template;
      }
    });
  }


  canSubmit(): boolean {
    if (!this.editor) {
      return false;
    }
    return this.editor.canSubmit();
  }
  private processActionMenu(): void {
    find(this.actionItems, {id: 'edit'})!.disabled = !this.param?.isDuplicate;
    find(this.actionItems, {id: 'archive'})!.disabled = !this.editor?.canArchiveThis();
    find(this.actionItems, {id: 'duplicate'})!.disabled = this.param?.isDuplicate;
    find(this.actionItems, {id: 'delete'})!.disabled = this.param?.isDuplicate;
    this.checkActionsMenuPollingId = setInterval(() => {
      find(this.actionItems, {id: 'archive'})!.disabled = !this.editor?.canArchiveThis();
    }, 1000);
  }

  isEditExisting(): boolean {
    return !!this.param?.id && !!!this.param?.isDuplicate;
  }

  protected performApply(): void {
    if (this.editor?.canPerformApply()) {
      this.editor.apply((result: IIdentified) => {
        if (this.param?.isDuplicate || this.param?.id == null) {
          this.router.navigate([this.pageUrl, result.id]);
        }
      });
    }
  }

  ngOnDestroy(): void {
    console.log('destroy');
    if (this.checkActionsMenuPollingId != null) {
      clearInterval(this.checkActionsMenuPollingId);
    }
  }

}
