import {Component, ViewChild} from '@angular/core';
import { PageableTableComponent } from '../../common/components/pageable-table.component';
import { ISearchRequest, ISearchResponse } from '../../api/shared/search-api';
import { AppResourceService } from '../../app.resource.service';
import { Router } from '@angular/router';
import { TableQuery } from '../../common/util/table-query';
import { Observable } from 'rxjs';
import {ITeamMember, TEAM_API_ENDPOINTS, TTeamMemberSave} from '../../api/shared/app-domain/team';
import { faClock, faInfo, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faSkype, faSlack } from '@fortawesome/free-brands-svg-icons';
import { IDictionary } from '../../common/types';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';
import { ColumnsInfoService } from '../common/column-header.component';
import { IIconLinkOption } from '../common/table-cell.component';
import {EntityEditDialogComponent} from '../common/entity-edit.dialog';
import {IMenuItem} from '../../common/components/table-base.component';

@Component({
  selector: 'app-team-members-table',
  template: `
    <app-table-toolbar [tableBase]="this"></app-table-toolbar>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <app-entity-edit-dialog #editDialog position="top" (apply)="onCreatedOrUpdated($event)" width="auto" minWidth="auto">
      <ng-template pTemplate let-param>
        <app-team-member-editor [param]="param"></app-team-member-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-edit-dialog #bulkEditDialog (apply)="onBulkUpdated($event)">
      <ng-template pTemplate let-param>
        <app-team-member-bulkeditor [param]="param"></app-team-member-bulkeditor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-edit-dialog #archiveDialog>
      <ng-template pTemplate let-param>
        <app-team-member-status-editor></app-team-member-status-editor>
      </ng-template>
    </app-entity-edit-dialog>

    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Team Members"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               scrollDirection="horizontal"
               [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="name" filterType="optionsIn" label="Team Member"
                               frozen="lastLeft" [fixedWidth]="300"
                               [options]="$any(propertyOptions.getOptions('teamMemberFullNames') | async)"
                               optionValue="name"
                               [optionsVirtualScroll]="true"></app-column-header>
            <app-column-header filterType="optionsIn" field="roleName" label="Role"
                               [fixedWidth]="300"
                               [options]="$any(propertyOptions.getOptions('roles') | async)"
                               optionValue="name">
            </app-column-header>
            <app-column-header field="status" [fixedWidth]="140"></app-column-header>
            <app-column-header field="statusChangedReason" filterType="optionsEquals" optionsType="tree"
                               [fixedWidth]="200"
                               [options]="propertyOptions.teamMemberStatusChangedReasonsAsTree">
            </app-column-header>
            <app-column-header field="type" [fixedWidth]="270"></app-column-header>

            <app-budget-components-column-headers [useColumnFilters]="true"
                                                  [width]="150" [minWidth]="150" [maxWidth]="150">
            </app-budget-components-column-headers>
            <app-column-header field="title" filterType="text" [fixedWidth]="200">
            </app-column-header>
            <app-column-header filterType="optionsIn" field="departmentName" label="Department"
                               [fixedWidth]="300"
                               [options]="$any(propertyOptions.getOptions('departments') | async)"
                               optionValue="name">
            </app-column-header>
            <app-column-header filterType="optionsArray" field="skills"
                               [fixedWidth]="350"
                               [options]="$any(propertyOptions.getOptions('skillNames') | async)"
                               optionValue="name"
                               optionsSelectedLabel="{0} Skills selected"
                               [optionsVirtualScroll]="true">
            </app-column-header>
            <app-column-header field="weeklyCapacityMin" filterType="numeric"
                               [fixedWidth]="220">
            </app-column-header>
            <app-column-header field="weeklyCapacityTarget" filterType="numeric"
                               [fixedWidth]="220">
            </app-column-header>
            <app-column-header field="weeklyCapacityMax" filterType="numeric"
                               [fixedWidth]="220">
            </app-column-header>
            <app-column-header filterType="optionsIn" field="defaultWorkweekName" label="Default Workweek"
                               filterField="defaultWorkweekId"
                               [fixedWidth]="200"
                               [options]="$any(propertyOptions.getOptions('defaultWorkweeks') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header field="isWorkweekOverridden" label="Workweek Overridden" filterType="boolean"
                               [fixedWidth]="180">
            </app-column-header>
            <app-column-header filterType="optionsIn" field="holidayCalendarName" label="Holiday Calendar"
                               [fixedWidth]="200"
                               filterField="holidayCalendarId"
                               [options]="$any(propertyOptions.getOptions('holidayCalendars') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header field="isHolidayCalendarOverridden" label="Calendar Overridden" filterType="boolean"
                               [fixedWidth]="180">
            </app-column-header>
            <app-column-header filterType="optionsIn" field="contractorCompanyName" label="Contractor Company"
                               filterField="contractorCompanyId"
                               [fixedWidth]="200"
                               [options]="$any(propertyOptions.getOptions('teamMemberCompanies') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header filterType="optionsIn" field="paymentRecipientName" label="Payment Recipient"
                               filterField="paymentRecipientId"
                               [fixedWidth]="200"
                               [options]="$any(propertyOptions.getOptions('teamMemberPaymentRecipients') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header field="paymentTerms" filterType="optionsEquals"
                               [fixedWidth]="180"
                               [options]="propertyOptions.paymentTerms">
            </app-column-header>
            <app-column-header field="netTerms" filterType="optionsEquals"
                               [fixedWidth]="150"
                               [options]="propertyOptions.netTerms | options">
            </app-column-header>
            <app-column-header field="isActivelyStuffed" label="Actively Stuffed" filterType="boolean"
                               [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="gustoRequired" filterType="boolean"
                               [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="defaultHourlyRate" filterType="numeric" label="Default Billing Rate"
                               [fixedWidth]="200">
            </app-column-header>
            <app-column-header field="costRateDefault" filterType="numeric" label="Default Cost Rate"
                               [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="costRateDefaultPlus" filterType="numeric" label="Default Cost Rate Plus"
                               [fixedWidth]="200">
            </app-column-header>

            <app-column-header field="country" filterType="optionsIn"
                               [fixedWidth]="150"
                               optionValue="name" optionLabel="name"
                               optionIcon="flag"
                               [options]="$any(propertyOptions.getOptions('countries') | async) | options">
            </app-column-header>
            <app-column-header field="state" filterType="optionsIn"
                               [fixedWidth]="150"
                               [options]="propertyOptions.usStates | options">
            </app-column-header>
            <app-column-header field="city" filterType="optionsIn"
                               [fixedWidth]="150"
                               [options]="$any(propertyOptions.getOptions('teamMemberCities') | async)"
                               [optionsVirtualScroll]="true"
                               optionValue="city">
            </app-column-header>

            <app-column-header field="birthday" filterType="date" [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="searchSource" filterType="optionsIn"
                               [fixedWidth]="170"
                               [options]="$any(propertyOptions.getOptions('searchSources') | async)"
                               optionValue="name">
            </app-column-header>
            <app-column-header field="primarySourceUrl" filterType="text" label="Search Source URL"
                               [fixedWidth]="170">
            </app-column-header>
            <app-column-header field="searchSourceKeyword" filterType="text"
                               [fixedWidth]="250">
            </app-column-header>
            <app-column-header field="timezone" filterType="optionsIn"
                               [fixedWidth]="150"
                               [options]="$any(propertyOptions.getOptions('timezones') | async) | options">
            </app-column-header>

            <app-column-header field="referralName" filterType="optionsIn" label="Referral"
                               [fixedWidth]="170"
                               [options]="$any(propertyOptions.getOptions('referrals') | async)"
                               optionValue="name">
            </app-column-header>
            <app-column-header filterType="optionsIn" field="talentPartnerName" label="Talent Partner"
                               filterField="talentPartnerId"
                               [fixedWidth]="200"
                               [options]="$any(propertyOptions.getOptions('talentPartners') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header filterType="text" field="phone" [fixedWidth]="150">
            </app-column-header>
            <app-column-header filterType="text" field="email" [fixedWidth]="250">
            </app-column-header>
            <app-column-header filterType="text" field="mtEmail" label="MindTrust Email" [fixedWidth]="250">
            </app-column-header>
            <app-column-header field="hiredDate" filterType="date" [fixedWidth]="150">
            </app-column-header>
            <app-column-header filterType="optionsArray" field="userRoles"
                               [fixedWidth]="350"
                               [options]="$any(propertyOptions.getOptions('userRoles') | async)"
                               optionLabel="name" optionValue="id">
            </app-column-header>
            <app-column-header filterType="date" field="createdAt" [fixedWidth]="180">
            </app-column-header>
            <app-column-header filterType="date" field="updatedAt" [fixedWidth]="180">
            </app-column-header>
            <app-column-header field="usefulLinks" [sortable]="false" label="Links" frozen="firstRight"
                               [fixedWidth]="links.length * 20">
            </app-column-header>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>

            <app-table-cell type="custom" field="name" [rowData]="rowData" frozen="lastLeft"
                            tdStyleClass="py-0" styleClass="justify-content-between">
              <div class="flex align-items-center overflow-x-hidden">
                <app-avatar [(ngModel)]="rowData.avatar" [name]="rowData.name" [editable]="false"></app-avatar>
                <div class="ml-2 mt-overflow-ellipsis" appTooltipOnOverflow>
                  <a class="text-primary no-underline"
                     [routerLink]="['/team-members/edit', rowData.id]">{{rowData.name}}</a>
                </div>
              </div>
              <div class="flex align-items-center">
                <i class="pi pi-file" [style.visibility]="rowData.isDraft ? 'visible' : 'hidden'"
                   [pTooltip]="rowData.isDraft ? 'Draft' : undefined" tooltipPosition="bottom"></i>
                <app-row-menu-button [tableBase]="this" [rowData]="rowData" [customMenuItems]="customRowMenuItems"></app-row-menu-button>
              </div>
            </app-table-cell>
            <app-table-cell field="roleName" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="statusChangedReason" [rowData]="rowData"></app-table-cell>

            <app-table-cell type="custom" [rowData]="rowData" field="type" styleClass="py-0">
              <div class="flex align-items-center overflow-x-hidden">
                <img [src]="propertyOptions.getTeamMemberTypeIcon(rowData.type)" height="16" width="16"/>
                <div class="ml-2 mt-overflow-ellipsis" appTooltipOnOverflow>
                  {{rowData.type}}
                </div>
              </div>
            </app-table-cell>

            <app-budget-components-cells [rowData]="rowData"
                                         [width]="150" [minWidth]="150" [maxWidth]="150"></app-budget-components-cells>
            <app-table-cell field="title" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="departmentName" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="skills" type="stringArray" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="weeklyCapacityMin" align="right"
                            [value]="rowData.weeklyCapacityMin | hours"></app-table-cell>
            <app-table-cell field="weeklyCapacityTarget" align="right"
                            [value]="rowData.weeklyCapacityTarget | hours"></app-table-cell>
            <app-table-cell field="weeklyCapacityMax" align="right"
                            [value]="rowData.weeklyCapacityMax | hours"></app-table-cell>
            <app-table-cell field="defaultWorkweekName" [rowData]="rowData" type="custom" [showNull]="false">
              <span *ngIf="!rowData.isWorkweekOverridden">{{rowData.defaultWorkweekName}}</span>
              <span *ngIf="rowData.isWorkweekOverridden" class="text-gray-500">[overridden]</span>
            </app-table-cell>
            <app-table-cell field="isWorkweekOverridden" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="holidayCalendarName" [rowData]="rowData" type="custom" [showNull]="false">
              <span *ngIf="!rowData.isHolidayCalendarOverridden">{{rowData.holidayCalendarName}}</span>
              <span *ngIf="rowData.isHolidayCalendarOverridden" class="text-gray-500">[overridden]</span>
            </app-table-cell>
            <app-table-cell field="isHolidayCalendarOverridden" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="contractorCompanyName" type="internalLink"
                            [routerLinkOptions]="{
                                link: ['/tbd', 'contractor company', rowData.contractorCompanyId]
                            }"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="paymentRecipientName" type="internalLink"
                            [routerLinkOptions]="{
                                link: ['/tbd', 'payment-recipient', rowData.paymentRecipientId]
                            }"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="paymentTerms" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="netTerms" [rowData]="rowData" align="right"></app-table-cell>
            <app-table-cell field="isActivelyStuffed" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="gustoRequired" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="defaultHourlyRate" align="right"
                            [value]="rowData.defaultHourlyRate | currency:'USD':'symbol':'1.2-2'"></app-table-cell>
            <app-table-cell field="costRateDefault" align="right"
                            [value]="rowData.costRateDefault | currency:'USD':'symbol':'1.2-2'"></app-table-cell>
            <app-table-cell field="costRateDefaultPlus" align="right"
                            [value]="rowData.costRateDefaultPlus | currency:'USD':'symbol':'1.2-2'"></app-table-cell>

            <app-table-cell field="country" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="state" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="city" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="birthday" [value]="rowData.birthday | date: 'MM/dd/yyyy'"></app-table-cell>

            <app-table-cell type="externalLink" field="searchSource" linkUrlField="searchSourceUrl"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell type="externalLink" field="primarySourceUrl" linkUrlField="primarySourceUrl"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell type="stringArray" field="searchSourceKeyword" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="timezone" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="referralName" type="internalLink"
                            [routerLinkOptions]="{
                                link: ['/tbd', 'referral', rowData.referralId]
                            }"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="talentPartnerName" type="internalLink"
                            [filterLinkOptions]="{
                                link: ['/team'],
                                queryParams: {scope: 'all', scopeFilter: 'talentPartnerId', id: rowData.talentPartnerId, name: rowData.talentPartnerName}
                            }"
                            [routerLinkOptions]="{
                                link: ['/tbd', 'talent-partner', rowData.talentPartnerId]
                            }"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="phone" [rowData]="rowData"></app-table-cell>
            <app-table-cell type="externalLink" field="email" [href]="'mailto:' + rowData.email"
                            [rowData]="rowData"></app-table-cell>
            <app-table-cell field="mtEmail" type="custom" [rowData]="rowData">
              <fa-icon *ngIf="rowData.mtEmailType" [icon]="icons[rowData.mtEmailType]" [fixedWidth]="true" class="mr-2 text-gray-700"
                       [pTooltip]="rowData.mtEmailType" tooltipPosition="bottom"></fa-icon>
              <a class="mt-overflow-ellipsis mt-link" appTooltipOnOverflow
                 [href]="'mailto:' + rowData.mtEmail">{{rowData.mtEmail}}</a>
            </app-table-cell>
            <app-table-cell field="hiredDate" [value]="rowData.hiredDate | date: 'MM/dd/yyyy'"></app-table-cell>
            <app-table-cell field="userRoles" type="stringArray" [rowData]="rowData"
                            stringArrayRoute="/tbd/role/{id}"></app-table-cell>

            <app-table-cell field="updatedAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="createdAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="usefulLinks" type="iconLinks" [iconLinksOptions]="links" frozen="firstRight"
                            [rowData]="rowData"></app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService
  ]

})
export class TeamMembersTableComponent extends PageableTableComponent<ITeamMember> {
  @ViewChild('archiveDialog', {read: EntityEditDialogComponent}) archiveDialog?: EntityEditDialogComponent;
  links: Array<IIconLinkOption> = [
    {
      field: (rowData: any) => `/tbd/user-settings/${ rowData.id }`,
      icon: faUserGear,
      tooltip: 'User Settings',
      isInternal: true
    },
    { field: 'usefulLinks.timekeeping', icon: faClock, tooltip: 'TimeKeeping' },
    { field: 'usefulLinks.slack', icon: faSlack, tooltip: 'Slack' },
    { field: 'usefulLinks.skype', icon: faSkype, protocol: 'skype', tooltip: 'Skype' }
  ];

  icons: IDictionary = { Gmail: faGoogle, Ionos: faInfo };

  customRowMenuItems: Array<IMenuItem> = [
    {
      id: 'details',
      label: 'Details',
      icon: 'pi pi-list',
      styleClass: 'font-medium',
      onShowMenu: (entity: ITeamMember, menuItem: IMenuItem) => menuItem.routerLink = ['/team-members', entity.id],
      queryParams: {tab: 'details'}
    },
    {
      separator: true
    }
  ]
  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.entityName = 'Team';
    this.api = {
      archiveEntities: this.resource.patchTeamMembers.bind(this.resource),
      deleteEntities: this.resource.deleteTeamMembers.bind(this.resource),
      exportEntities: this.resource.exportTeamMembers.bind(this.resource),
      importUrl: TEAM_API_ENDPOINTS.import
    };

  }

  protected override fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<ITeamMember>> {
    return this.resource.getTeamMembers(searchRequest);
  }

  override archive(entities: Array<ITeamMember>): void {
    this.archiveDialog!.show(null, (data) => {
      super.archive(entities, data.statusChangedReason);
    });
    this.archiveDialog!.title = `Archive ${entities.length > 1 ? entities.length + ' Team Members' : 'Team Member: ' + entities[0].name}`;
  }
}
