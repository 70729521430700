import {Component} from '@angular/core';
import {AppResourceService} from '../../../app.resource.service';
import {IProject} from '../../../api/shared/app-domain/project';
import {getProjectTypeIcon} from '../projects';

@Component({
  template: `
    <app-single-entity-details tabRoute="/projects" [fetchEntity]="resource.getProject.bind(resource)">
      <ng-template pTemplate="entityTpl" let-entity>
        <div class="flex align-items-center">
          <span class="mt-page-title mr-2">{{entity.name}}</span>
          <img [src]="getProjectTypeIcon(entity)" height="24" width="24"
               [pTooltip]="entity.type || ''"/>
        </div>
        <div class="text-gray-700 text-sm">{{entity.clientName}} | {{entity.agencyName}}</div>
      </ng-template>
      <ng-template pTemplate="detailsTpl" let-entity let-tab="tab" let-period="period">
        <app-project-detail-overview *ngIf="tab === 'overview'"
                                     [project]="entity"></app-project-detail-overview>
        <app-single-project-details *ngIf="tab === 'details'"
                                    [entity]="entity" [period]="period"></app-single-project-details>
        <app-single-project-planning *ngIf="tab === 'planning'"
                                     [entity]="entity" [period]="period"></app-single-project-planning>
        <ng-container *ngIf="tab === 'invoices'">
          TBD
        </ng-container>
      </ng-template>
    </app-single-entity-details>
  `
})
export class ProjectDetailsPageComponent {
  constructor(public resource: AppResourceService) {
  }

  getProjectTypeIcon(entity: IProject): string | null {
    return getProjectTypeIcon(entity.type);
  }

}
