import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IIdentified, IPeriod, IPeriodRequest, TApprovalsState} from '../../../api/shared/app-domain/common';
import {EntityPropertyOptionsService, TOptionsTeamMember} from '../../common/entity-property-options.service';
import {ISearchRequest, ISearchResponse} from '../../../api/shared/search-api';
import {AppResourceService} from '../../../app.resource.service';
import {PageableTableComponent} from '../../../common/components/pageable-table.component';
import {IExpense} from '../../../api/shared/app-domain/expenses';
import {Observable} from 'rxjs';
import {TableQuery} from '../../../common/util/table-query';
import {onComponentPropChanged, ServiceLocator} from '../../../common/util/util';
import {ColumnsInfoService} from '../../common/column-header.component';
import {DatePipe} from '@angular/common';
import {IMenuItem} from '../../../common/components/table-base.component';
import {startCase} from 'lodash';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';

import {TApproveAction} from '../common';
import {ITimeOff} from '../../../api/shared/app-domain/time';


@Component({
  selector: 'app-expenses-table',
  template: `
    <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit', 'archive']"
                       [customMenuItems]="customTableMenuItems"></app-table-toolbar>
    <app-entity-edit-dialog #editDialog width="600px" minWidth="600px" (apply)="onCreatedOrUpdated($event)">
      <ng-template pTemplate let-param>
        <app-expense-editor [param]="param" [teamMember]="teamMember"></app-expense-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Expenses"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="date" filterType="date" frozen="left"
                               [fixedWidth]="160" [style]="{width: '160px'}"></app-column-header>
            <app-column-header filterType="optionsIn" field="clientName" label="Client" frozen="left"
                               filterField="clientId"
                               [fixedWidth]="160"
                               [options]="$any(propertyOptions.getOptions('projectClients') | async)"
                               optionLabel="name" optionValue="id"></app-column-header>
            <app-column-header filterType="optionsIn" field="projectName" label="Project"
                               frozen="lastLeft"
                               filterField="projectId"
                               [fixedWidth]="260"
                               [options]="$any(propertyOptions.getOptions('projects') | async)"
                               optionLabel="name" optionValue="id"></app-column-header>
            <app-column-header filterType="optionsIn" field="categoryName" label="Category"
                               filterField="categoryId"
                               [fixedWidth]="160"
                               [options]="$any(propertyOptions.getOptions('expenseCategories') | async)"
                               optionLabel="name" optionValue="id"></app-column-header>
            <app-column-header field="amount" filterType="numeric"
                               [fixedWidth]="120"></app-column-header>
            <app-column-header field="isBillable" filterType="boolean" [fixedWidth]="120"></app-column-header>
            <app-column-header field="isReimbursable" filterType="boolean"
                               [fixedWidth]="120"></app-column-header>
            <app-column-header field="notes" filterType="text" [fixedWidth]="220"></app-column-header>
            <app-column-header field="attachments" [sortable]="false"></app-column-header>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <app-table-cell type="custom" field="date" [rowData]="rowData" frozen="left">
              <div class="mt-link"
                   (click)="currentMenuRowData = rowData; openCreateOrUpdateDialog(rowData)">
                {{rowData.date | date: 'EEE, MMM dd, y'}}</div>
            </app-table-cell>
            <app-table-cell field="clientName" [value]="rowData.clientName" frozen="left"></app-table-cell>
            <app-table-cell type="custom" field="projectName" [rowData]="rowData" frozen="lastLeft"
                            tdStyleClass="py-0">
              <div class="flex align-items-center justify-content-between w-full">
                <app-project-entry-entity [width]="'200px'" [entryEntity]="rowData" routerLink="/projects"
                                          [queryParams]="{tab: 'details'}">
                </app-project-entry-entity>
                <app-row-menu-button [tableBase]="this" [rowData]="rowData"
                                     [omitMenuItems]="['duplicate', 'archive']"
                                     [customMenuItems]="customRowMenuItems"></app-row-menu-button>
              </div>
            </app-table-cell>
            <app-table-cell field="categoryName" [value]="rowData.categoryName"></app-table-cell>
            <app-table-cell field="amount" align="right"
                            [value]="rowData.amount | currency:'USD':'symbol':'1.2-2'"></app-table-cell>
            <app-table-cell field="isBillable" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="isReimbursable" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="notes" [rowData]="rowData" [style]="{maxWidth: '200px'}"></app-table-cell>
            <app-table-cell type="custom" field="attachments" [rowData]="rowData" tdStyleClass="py-0">
              <app-attachments-cell [attachments]="rowData.attachments"></app-attachments-cell>
            </app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    ColumnsInfoService,
    EntityPropertyOptionsService
  ]
})
export class ExpensesTableComponent extends PageableTableComponent<IExpense> implements OnChanges, OnInit {
  @Input() period!: IPeriod;
  @Input() teamMember!: TOptionsTeamMember;
  @Input() state!: TApprovalsState;
  customTableMenuItems: Array<IMenuItem> = [];
  customRowMenuItems: Array<IMenuItem> = [];

  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.entityName = 'Expense';
    this.api = {
      deleteEntities: (ids: Array<string>) => this.resource.deleteExpenses(this.teamMember.id, ids),
      patchEntities: (data: Array<IIdentified & Partial<IExpense>>) => this.resource.patchExpenses(this.teamMember.id, data),
    };
  }

  ngOnInit(): void {
    this.customTableMenuItems = [
      ...(this.state === 'Unsubmitted' ? [{
        id: 'submit' as TApproveAction,
        label: 'Submit', icon: 'pi pi-check',
        disabled: true,
        command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'submit')
      }
      ] : [])
    ];
    this.customRowMenuItems = [{
      id: 'edit',
      label: 'Edit', icon: 'pi pi-pencil',
      disabled: false,
      onShowMenu: (entity: ITimeOff, menuItem: IMenuItem) => menuItem.visible = (entity.state !== 'Denied'),
      command: (event: MenuItemCommandEvent) => this.openCreateOrUpdateDialog(this.currentMenuRowData)
    }, {
      id: 'submit' as TApproveAction,
      label: 'Submit', icon: 'pi pi-check',
      disabled: false,
      onShowMenu: (entity: ITimeOff, menuItem: IMenuItem) => menuItem.visible = (entity.state === 'Unsubmitted'),
      command: (event: any) => this.performAction(this.rowMenuButtonClickEvent.target!, [this.currentMenuRowData], 'submit')
    }]
  }

  override ngOnChanges(changes: SimpleChanges): void {
    onComponentPropChanged(changes, ['period', 'teamMember', 'query'], () => this.load())
  }

  protected override fetchData(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<IExpense>> {
    searchRequest.param = {
      period: this.period
    };
    return this.resource.searchExpenses(this.teamMember.id, searchRequest);
  }

  override canPerformTableMenuAction(itemId: string): ((entity: IExpense) => boolean) {
    if (itemId === 'submit') {
      return (entity: IExpense) => (entity.state === 'Unsubmitted');
    }
    return super.canPerformTableMenuAction(itemId);
  }


  performAction(eventTarget: EventTarget, entities: Array<IExpense>, action: TApproveAction): void {
    if (!!!entities?.length) {
      return;
    }
    const perform = () => {
      this.patchField(entities, {state: 'Pending'}, 'state');
    };
    const actionText = startCase(action);
    const itemsText = entities.length === 1
      ? `Expense for ${new DatePipe('en-US').transform(entities[0].date, 'EEE, MMM dd, y')}`
      : `${entities.length} selected Expenses`

    ServiceLocator.confirm({
      target: eventTarget,
      key: 'g-popup',
      message: `Are you sure you want to ${actionText} ${itemsText}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        perform();
      }
    });
  }

}
