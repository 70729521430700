import {AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IDbSchema, IDbTable} from '../../../../api/shared/dev-tools/db-api';
import {ITableRow} from '../db';
import {LazyLoadEvent} from 'primeng/api';
import {ISearchRequest} from '../../../../api/shared/search-api';
import {finalize} from 'rxjs';
import {AppResourceService} from '../../../../app.resource.service';
import {TableQuery} from '../../../../common/util/table-query';
import {Table} from 'primeng/table';
import {IDictionary} from '../../../../common/types';
import {filter, find, sortBy} from 'lodash';
import {DbConstraintsUtil} from '../db-constraints-util';

@Component({
  selector: 'app-db-pk-chooser',
  template: `
    <div class="text-2xl text-gray-700 mb-2">{{tableName | dbNormalizeName}}</div>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container class="shadow-1">
      <p-table
        [columns]="table.columns | addKey:'name'"
        [value]="data"
        [(first)]="firstPage"
        responsiveLayout="scroll"
        [scrollable]="true"
        scrollDirection="horizontal"
        [(selection)]="selectedRows"
        dataKey="dataKey"
        [selectionPageOnly]="true"
        [lazy]="true"
        (onLazyLoad)="load($any($event))"
        [paginator]="true"
        [showCurrentPageReport]="true"
        [lazyLoadOnInit]="false"
        [rows]="pageSize"
        [totalRecords]="total"
        sortMode="multiple"
        [resizableColumns]="true"
        columnResizeMode="fit"
        [reorderableColumns]="true"
        (onRowSelect)="onRowSelect()"
        (onRowUnselect)="onRowSelect()">
        <!------------>
        <!-- HEADER -->
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th [style]="{width: (1 * 45) + 'px', 'max-width': (1 * 45) + 'px'}"
                style="border-right: 1px solid #e4e4e4;"
                pFrozenColumn>
               <p-tableHeaderCheckbox (click)="onRowSelect()"></p-tableHeaderCheckbox>
            </th>
            <th *ngFor="let col of columns"
                style="width: 210px; position: relative"
                [pSortableColumn]="col.name"
                pResizableColumn pReorderableColumn>
              <div class="flex justify-content-between align-items-center w-full">
                <div class="mt-overflow-ellipsis">
                  <span class="align-self-center"
                        appTooltipOnOverflow [useParent]="true" style="width: 210px">
                    {{col.name | dbNormalizeName}}
                  </span>
                </div>
                <p-sortIcon class="align-self-center" [field]="col.name"></p-sortIcon>
                <ng-container>
                  <app-db-column-filter [schema]="schema" [column]="col"></app-db-column-filter>
                </ng-container>
              </div>
            </th>
          </tr>
        </ng-template>
        <!------------>
        <!--  BODY  -->
        <ng-template pTemplate="body"
                     let-rowData let-columns="columns" let-ri="rowIndex">
          <tr>
            <td [style]="{width: (1 * 45) + 'px', 'max-width': (1 * 45) + 'px'}"
                style="z-index: 2; border-right: 1px solid #e4e4e4;"
                pFrozenColumn class="flex justify-content-center">
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns" style="width: 210px;"
                class="mt-overflow-ellipsis w-full">
                  <ng-container *ngIf="rowData[col.name] != null; else nullValue">
                    <span appTooltipOnOverflow [useParent]="true">
                      <app-db-column-output [schema]="schema" [column]="col" [rowData]="rowData"></app-db-column-output>
                    </span>
                  </ng-container>
                  <ng-template #nullValue>
                    <div class="border-bottom-1 text-gray-400" style="width: 25px"></div>
                  </ng-template>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `
})
export class DbPkChooserComponent implements OnInit {
  @Input() schema!: IDbSchema;
  @Input() tableName!: string;
  @Input() pkColumnName!: string;
  @Input() selected: Array<string> | null = null;
  @Output() onSelect: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  loading = false;
  data: Array<ITableRow> = [];

  pageSize = 10;
  firstPage = 0;
  total = 0;

  table!: IDbTable;
  constraintsUtil!: DbConstraintsUtil;

  selectedRows: Array<ITableRow> = [];
  tableQuery: TableQuery = new TableQuery();

  @ViewChild(Table, {static: true}) tableComponent!: Table;

  constructor(private resource: AppResourceService) {
  }

  ngOnInit(): void {
    this.constraintsUtil = new DbConstraintsUtil(this.schema, this.tableName);
    this.table = this.constraintsUtil.table;
    this.load();
    if (!!this.selected) {
      this.resource.dbSearch(this.table.name, {
        query: {
          logical: 'and',
          predicates: [
            {field: this.pkColumnName, operator: 'in', value: this.selected}
          ]
        }
      }).subscribe((response) => {
        this.selectedRows = response.results.map((r) => {
          return this.constraintsUtil.addDataKeyToRowData(r);
        });
      });
    }
  }


  load(event?: LazyLoadEvent): void {
    setTimeout(() => this.loading = true);
    const searchRequest: ISearchRequest = this.tableQuery.prepareQuery(
      event || this.tableComponent.createLazyLoadMetadata(), {
        pageSize: this.pageSize
      }
    );
    this.resource.dbSearch(this.table.name,
      searchRequest
    ).pipe(
      finalize(() => setTimeout(() => this.loading = false))
    ).subscribe((response) => {
      this.data = response.results.map((r) => {
        return this.constraintsUtil.addDataKeyToRowData(r);
      });
      this.total = response.total;
    });
  }

  onRowSelect(): void {
    const keys = this.selectedRows.map((row) => row[this.pkColumnName]);
    this.onSelect.emit(keys);
  }
}

