import { Component, Input } from '@angular/core';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';
import { EntityFormControlBaseComponent } from '../common/entity-form-control-base.component';
import { ITeamCandidate } from '../../api/shared/app-domain/team-candidate';

@Component({
  selector: 'app-team-candidate-form-control',
  template: `
    <app-team-participant-form-control [name]="$any(name)"></app-team-participant-form-control>
    <ng-container [ngSwitch]="name">
      <app-form-control-wrapper *ngSwitchCase="'city'" [controlName]="name" controlType="dropdown" [dropdownEditable]="true"
                        [optionsFilter]="true" optionsFilterBy="city" optionLabel="city" optionValue="city"
                        [options]="$any(propertyOptions.getOptions('teamCandidateCities') | async)"
                        [virtualScroll]="true"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'status'" [controlName]="name" controlType="dropdown"
                        [options]="propertyOptions.teamCandidateStatuses"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'hubspotCandidateId'" [controlName]="name" [linkValue]="linkValue"
                        [iconIsLink]="true"
                        label="HubSpot Candidate" controlType="dropdown"
                        [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                        [options]="$any(propertyOptions.getOptions('hubspotCandidates') | async)"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'hubspotContactId'" [controlName]="name" [linkValue]="linkValue"
                        [iconIsLink]="true"
                        label="HubSpot Contact" controlType="dropdown"
                        [optionsFilter]="true" optionsFilterBy="name" optionLabel="name" optionValue="id"
                        [options]="$any(propertyOptions.getOptions('hubspotContacts') | async)"></app-form-control-wrapper>
      <app-form-control-wrapper *ngSwitchCase="'availability'" [controlName]="name" controlType="dropdown"
                        [options]="propertyOptions.availabilities"></app-form-control-wrapper>
    </ng-container>
  `
})
export class TeamCandidateFormControlComponent extends EntityFormControlBaseComponent<ITeamCandidate> {
  @Input() linkValue?: string;

  constructor(propertyOptions: EntityPropertyOptionsService) {
    super(propertyOptions);
  }

}

