import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IIdentified, IPeriod, IPeriodRequest, TApprovalsState} from '../../../api/shared/app-domain/common';
import {EntityPropertyOptionsService, TOptionsTeamMember} from '../../common/entity-property-options.service';
import {ISearchRequest, ISearchResponse} from '../../../api/shared/search-api';
import {AppResourceService} from '../../../app.resource.service';
import {PageableTableComponent} from '../../../common/components/pageable-table.component';
import {Observable} from 'rxjs';
import {TableQuery} from '../../../common/util/table-query';
import {onComponentPropChanged, ServiceLocator} from '../../../common/util/util';
import {ColumnsInfoService} from '../../common/column-header.component';
import {DatePipe} from '@angular/common';
import {IMenuItem} from '../../../common/components/table-base.component';
import {startCase} from 'lodash';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';

import {TApproveAction} from '../common';
import {ITimeOff} from '../../../api/shared/app-domain/time';


@Component({
  selector: 'app-time-off-requests-table',
  template: `
    <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit', 'archive']"
                       [customMenuItems]="customTableMenuItems"></app-table-toolbar>
    <app-entity-edit-dialog #editDialog width="600px" minWidth="600px" (apply)="onCreatedOrUpdated($event)">
      <ng-template pTemplate let-param>
        <app-time-off-request-editor [param]="param" [teamMember]="teamMember"></app-time-off-request-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Time Off Requests"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="startDate" filterType="date"
                               [fixedWidth]="140" [style]="{width: '140px'}"></app-column-header>
            <app-column-header field="endDate" filterType="date"
                               [fixedWidth]="140" [style]="{width: '140px'}"
                               thStyleClass="mt-cell-right-border"></app-column-header>
            <ng-container>
              <th class="text-center" [style.width]="'110px'">PTO Requested</th>
              <th class="text-center" [style.width]="'110px'">PTO Total</th>
              <th class="text-center" [style.width]="'80px'">Remaining</th>
              <th class="text-center mt-cell-right-border" [style.width]="'80px'">
                <div class="mt-overflow-ellipsis" appTooltipOnOverflow>Percent</div>
              </th>
            </ng-container>
            <app-column-header field="details" filterType="text" [fixedWidth]="320"></app-column-header>
            <app-column-header field="createdAt" filterType="date" [fixedWidth]="180"></app-column-header>
            <app-column-header field="updatedAt" filterType="date" [fixedWidth]="180"></app-column-header>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <app-table-cell type="custom" field="startDate" [rowData]="rowData">
              <div class="mt-link"
                   (click)="currentMenuRowData = rowData; openCreateOrUpdateDialog(rowData)">
                {{rowData.startDate | date: 'EEE, MMM dd, y'}}</div>
            </app-table-cell>
            <app-table-cell type="custom" field="endDate" [rowData]="rowData"
                            tdStyleClass="mt-cell-right-border py-0">
              <div class="flex align-items-center justify-content-between w-full">
                <div>{{rowData.endDate | date: 'EEE, MMM dd, y'}}</div>
                <app-row-menu-button [tableBase]="this" [rowData]="rowData"
                                     [omitMenuItems]="['duplicate', 'archive']"
                                     [customMenuItems]="customRowMenuItems"></app-row-menu-button>
              </div>
            </app-table-cell>
            <ng-container>
              <td colspan="2" style="padding-top: 0; padding-bottom: 0; flex: 2">
                <div class="w-full">
                  <div class="flex justify-content-between">
                    <div>{{rowData.totals.actual.time}}</div>
                    <div>of</div>
                    <div>{{rowData.totals.budget.time}}</div>
                  </div>
                  <div *ngIf="rowData.totals.actual.time != null && rowData.totals.budget.time">
                    <p-progressBar
                      [value]="rowData.totals.actual.time! / rowData.totals.budget.time! * 100"
                      [showValue]="false"></p-progressBar>
                  </div>
                </div>
              </td>
              <td>
                <div class="flex flex-column text-right">
                  <div>{{rowData.totals.delta.time}}</div>
                </div>
              </td>
              <td class="mt-cell-right-border">
                <div class="w-full flex justify-content-end">
                  <div class="mt-pct-box w-fit">
                    {{rowData.totals.deltaPct | percent}}
                  </div>
                </div>
              </td>
            </ng-container>
            <app-table-cell field="details" [rowData]="rowData"
                            [style]="{maxWidth: '300px'}"></app-table-cell>
            <app-table-cell field="createdAt"
                            [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="updatedAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    ColumnsInfoService,
    EntityPropertyOptionsService
  ]
})
export class TimeOffRequestsTableComponent extends PageableTableComponent<ITimeOff> implements OnChanges, OnInit {
  @Input() period!: IPeriod;
  @Input() teamMember!: TOptionsTeamMember;
  @Input() state!: TApprovalsState;
  customTableMenuItems: Array<IMenuItem> = [];
  customRowMenuItems: Array<IMenuItem> = [];

  constructor(private resource: AppResourceService,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.entityName = 'Time Off Request';
    this.api = {
      deleteEntities: (ids: Array<string>) => this.resource.deleteTimeOffRequests(this.teamMember.id, ids),
      patchEntities: (data: Array<IIdentified & Partial<ITimeOff>>) => this.resource.patchTimeOffRequests(this.teamMember.id, data),
    };
  }

  ngOnInit(): void {
    this.customTableMenuItems = [
      ...(this.state === 'Unsubmitted' ? [{
        id: 'submit' as TApproveAction,
        label: 'Submit', icon: 'pi pi-check',
        disabled: true,
        command: (event: MenuItemCommandEvent) => this.performAction(event.originalEvent!.target!, this.selection as any, 'submit')
      }
      ] : [])
    ];
    this.customRowMenuItems = [{
      id: 'edit',
      label: 'Edit', icon: 'pi pi-pencil',
      disabled: false,
      onShowMenu: (entity: ITimeOff, menuItem: IMenuItem) => menuItem.visible = (entity.state !== 'Denied'),
      command: (event: MenuItemCommandEvent) => this.openCreateOrUpdateDialog(this.currentMenuRowData)
    }, {
      id: 'submit' as TApproveAction,
      label: 'Submit', icon: 'pi pi-check',
      disabled: false,
      onShowMenu: (entity: ITimeOff, menuItem: IMenuItem) => menuItem.visible = (entity.state === 'Unsubmitted'),
      command: (event: any) => this.performAction(this.rowMenuButtonClickEvent.target!, [this.currentMenuRowData], 'submit')
    }]
  }

  override ngOnChanges(changes: SimpleChanges): void {
    onComponentPropChanged(changes, ['period', 'teamMember', 'query'], () => this.load())
  }

  protected override fetchData(searchRequest: ISearchRequest<IPeriodRequest>): Observable<ISearchResponse<ITimeOff>> {
    searchRequest.param = {
      period: this.period
    };
    return this.resource.searchTimeOffRequests(this.teamMember.id, searchRequest);
  }

  override canPerformTableMenuAction(itemId: string): ((entity: ITimeOff) => boolean) {
    if (itemId === 'submit') {
      return (entity: ITimeOff) => (entity.state === 'Unsubmitted');
    }
    return super.canPerformTableMenuAction(itemId);
  }


  performAction(eventTarget: EventTarget, entities: Array<ITimeOff>, action: TApproveAction): void {
    if (!!!entities?.length) {
      return;
    }
    const perform = () => {
      this.patchField(entities, {state: 'Pending'}, 'state');
    };
    const actionText = startCase(action);
    const itemsText = entities.length === 1
      ? `Time Off Request for ${new DatePipe('en-US').transform(entities[0].startDate, 'EEE, MMM dd, y')} - `
      + `${new DatePipe('en-US').transform(entities[0].endDate, 'EEE, MMM dd, y')}`
      : `${entities.length} selected Time Off Requests`

    ServiceLocator.confirm({
      target: eventTarget,
      key: 'g-popup',
      message: `Are you sure you want to ${actionText} ${itemsText}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        perform();
      }
    });
  }

}
