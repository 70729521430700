import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DateTime} from 'luxon';
import {DateTimeUnit} from 'luxon/src/datetime';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractControlValueAccessorComponent } from './abstract-control-value-accessor.component';
import {
  IPeriod,
  NAV_PERIOD_TYPES,
  PERIOD_TYPES,
  TNavPeriodType,
  TPeriodType
} from '../../api/shared/app-domain/common';

export const PERIOD_TYPE_TO_DURATION: { [type in TNavPeriodType]: DateTimeUnit } = {
  Day: 'day',
  Week: 'week',
  Month: 'month',
  Quarter: 'quarter',
  Year: 'year'
};

interface IInterval {
  start: DateTime;
  end: DateTime;
}

export const PERIOD_CHOOSER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PeriodChooserComponent),
  multi: true
};


export function getCurrentPeriodValue(unitType: TNavPeriodType = 'Month'): IPeriod {
  return {
    start: DateTime.now().startOf(PERIOD_TYPE_TO_DURATION[unitType]).toJSDate(),
    end: DateTime.now().endOf(PERIOD_TYPE_TO_DURATION[unitType]).toJSDate(),
    type: unitType
  };
}

@Component({
  selector: 'app-period-chooser',
  template: `
    <ng-container *ngIf="!['All Time', 'Custom'].includes(initialPeriodType)">
      <button pButton pRipple type="button"
              [label]="initialPeriodType === 'Day' ? 'Today' : ('This ' + initialPeriodType)"
              [disabled]="isDisabled"
              class="p-button-outlined" (click)="setThis()"></button>
      <button pButton pRipple type="button" icon="pi pi-chevron-left" [disabled]="isDisabled || !canPrev()"
              class="p-button-rounded p-button-text ml-1" style="min-width: 2.75rem" (click)="prev()"></button>
      <button pButton pRipple type="button" icon="pi pi-chevron-right" [disabled]="isDisabled || !canNext()"
              class="p-button-rounded p-button-text ml-1" style="min-width: 2.75rem" (click)="next()"></button>
    </ng-container>
    <div class="ml-1 text-gray-600" [ngSwitch]="initialPeriodType">
      <ng-container *ngSwitchCase="'Day'">
        {{interval.start.toJSDate() | date: 'MMM dd, yyyy'}}
      </ng-container>
      <ng-container *ngSwitchCase="'Week'">
        {{interval.start.toJSDate() | date: 'EEE MMM dd, yyyy'}}
        - {{interval.end.toJSDate() | date: 'EEE MMM dd, yyyy'}}
      </ng-container>
      <ng-container *ngSwitchCase="'Month'">
        {{interval.start.toJSDate() | date: 'MMM, yyyy'}}
      </ng-container>
      <ng-container *ngSwitchCase="'Quarter'">
        {{interval.start.toJSDate() | date: 'MMM, yyyy'}} - {{interval.end.toJSDate() | date: 'MMM, yyyy'}}
      </ng-container>
      <ng-container *ngSwitchCase="'Year'">
        {{interval.start.toJSDate() | date: 'yyyy'}}
      </ng-container>
      <ng-container *ngSwitchCase="'All Time'">
        All Time
      </ng-container>
      <ng-container *ngSwitchCase="'Custom'">
        {{interval.start.toJSDate() | date: 'MMM dd, yyyy'}} - {{interval.end.toJSDate() | date: 'MMM dd, yyyy'}}
      </ng-container>
    </div>
    <button *ngIf="['Day', 'Month', 'Year', 'Week', 'Custom'].includes(initialPeriodType)"
            pButton pRipple type="button" icon="pi pi-calendar"
            class="p-button-rounded p-button-text ml-1"
            [disabled]="isDisabled"
            (click)="!opCalendar.overlayVisible ? onShowCalendar() : null;  opCalendar.toggle($event)">
    </button>
    <p-overlayPanel #opCalendar [style]="{width: '420px'}" [dismissable]="true">
      <p-calendar *ngIf="opCalendar.overlayVisible" [(ngModel)]="calendar"
                  [firstDayOfWeek]="1"
                  [disabled]="isDisabled"
                  [inline]="true"
                  [showWeek]="true"
                  [minDate]="minDate!" [maxDate]="maxDate!"
                  [view]="initialPeriodType === 'Day' || initialPeriodType === 'Week' || initialPeriodType === 'Custom' ? 'date' : (initialPeriodType === 'Month' ? 'month' : 'year')"
                  [selectionMode]="initialPeriodType === 'Custom' ? 'range' : 'single'"
                  styleClass="w-full"
                  (onSelect)="initialPeriodType !== 'Custom' || calendar[1] != null ? opCalendar.hide() : null; onSelectCalendar()"></p-calendar>
    </p-overlayPanel>
    <span *ngIf="showUnits" class="p-float-label ml-2">
      <p-dropdown [(ngModel)]="initialPeriodType"
                  [disabled]="isDisabled"
                  [options]="periodTypes | options"
                  optionLabel="label"
                  optionValue="value"
                  styleClass="p-inputtext-sm"
                  [style]="{width: '130px'}"
                  (ngModelChange)="onChangePeriodType()">
      </p-dropdown>
      <label>Unit</label>
    </span>
    <div *ngIf="false" style="position: absolute; bottom: -10px; left: 0; font-size: 10px">
      {{value?.start | date: 'yy-MM-dd hh:mm'}} - {{value?.end | date: 'yy-MM-dd hh:mm'}}
    </div>
  `,
  styles: [`
    :host {
      display: flex;
      align-items: center;
      position: relative;
    }
  `],
  providers: [PERIOD_CHOOSER_VALUE_ACCESSOR]
})
export class PeriodChooserComponent extends AbstractControlValueAccessorComponent<IPeriod> implements OnInit, OnChanges {
  @Input() unitFilter?: Array<TPeriodType>;
  @Input() showUnits = true;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() useBusinessWeeks = true;
  @Input() initialPeriodType: TPeriodType = 'Month';
  periodTypes: Array<TPeriodType> = [...PERIOD_TYPES];
  calendar: any;

  interval: IInterval = {
    start: DateTime.now().startOf('month'),
    end: DateTime.now().endOf('month')
  }

  ngOnInit(): void {
    this.setPeriod();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitFilter']) {
      if (changes['unitFilter'].currentValue) {
        if (this.unitFilter!.length > 0 && this.unitFilter!.indexOf(this.initialPeriodType) === -1) {
          this.initialPeriodType = this.unitFilter![0];
        }
        this.periodTypes = this.unitFilter!;
      }
    }
  }

  get duration(): DateTimeUnit {
    return PERIOD_TYPE_TO_DURATION[this.initialPeriodType as TNavPeriodType] || 'month';
  }

  setThis(): void {
    this.interval.start = DateTime.now().startOf(this.duration);
    this.interval.end = DateTime.now().endOf(this.duration);
    this.setPeriod();
  }

  canPrev(): boolean {
    if (!this.minDate) {
      return true;
    }
    return this.interval.start.minus({[this.duration]: 1}).toJSDate() >= this.minDate;
  }

  canNext(): boolean {
    if (!this.maxDate) {
      return true;
    }
    return this.interval.start.endOf(this.duration).toJSDate() < this.maxDate;
  }

  prev(): void {
    if (this.useBusinessWeeks && this.duration === 'week') {
      let newStart: DateTime;
      if (this.interval.start.weekday !== 1) {
        newStart = this.interval.start.startOf('week');
        this.interval.end = newStart.endOf('month');
      } else {
        newStart = this.interval.start.minus({[this.duration]: 1});
        if (this.interval.start.startOf('month').month !== newStart.month && this.interval.start.day !== 1) {
          newStart = this.interval.start.startOf('month');
        }
        this.interval.end = newStart.endOf(this.duration);
      }
      this.interval.start = newStart;
    } else {
      this.interval.start = this.interval.start.minus({[this.duration]: 1});
      this.interval.end = this.interval.start.endOf(this.duration);
    }
    this.setPeriod();
  }

  next(): void {
    if (this.useBusinessWeeks && this.duration === 'week') {
      let newEnd: DateTime;
      let newStart: DateTime;
      if (this.interval.end.weekday !== 7) {
        newEnd = this.interval.end.endOf('week');
        this.interval.start = newEnd.startOf('month');
      } else {
        newEnd = this.interval.end.plus({[this.duration]: 1});
        if (this.interval.end.endOf('month').month !== newEnd.month && this.interval.end.day !== this.interval.end.daysInMonth) {
          newEnd = this.interval.end.endOf('month');
        }
        this.interval.start = newEnd.startOf(this.duration);
      }
      this.interval.end = newEnd;
    } else {
      this.interval.start = this.interval.start.plus({[this.duration]: 1});
      this.interval.end = this.interval.start.endOf(this.duration);
    }
    this.setPeriod();
  }

  private adjustPeriod(): void {
    const start = this.interval.start;
    this.interval.start = this.interval.start.startOf(this.duration);
    this.interval.end = this.interval.start.endOf(this.duration);

    if (this.useBusinessWeeks && this.duration === 'week'
      && this.interval.start.month != this.interval.end.month) {
      if (start.month === this.interval.start.month) {
        this.interval.end = start.endOf('month');
      } else {
        this.interval.start = start.startOf('month');
      }
    }
  }

  onChangePeriodType(): void {
    if (NAV_PERIOD_TYPES.includes(this.initialPeriodType as any)) {
      this.adjustPeriod();
    }
    this.setPeriod(this.initialPeriodType === 'All Time');
  }

  private setPeriod(allTime = false): void {
    if (allTime) {
      this.value = null;
    } else {
      this.value = {
        start: this.interval.start.toJSDate(),
        end: this.interval.end.toJSDate(),
        type: this.initialPeriodType
      }
    }
    this.onModelChange(this.value);
  }

  onSelectCalendar(): void {
    if (this.initialPeriodType !== 'Custom') {
      this.interval.start = DateTime.fromJSDate(this.calendar);
      this.interval.end = DateTime.fromJSDate(this.calendar);
      this.adjustPeriod();
    } else {
      this.interval.start = DateTime.fromJSDate(this.calendar[0]).startOf('day');
      if (this.calendar.length < 2 || this.calendar[1] == null) {
        this.interval.end = this.interval.start.endOf('day');
      } else {
        this.interval.end = DateTime.fromJSDate(this.calendar[1]).endOf('day');
      }
    }
    this.setPeriod();
  }

  onShowCalendar(): void {
    if (this.initialPeriodType === 'Custom') {
      this.calendar = [this.interval.start.toJSDate(), this.interval.end.toJSDate()];
    } else {
      this.calendar = this.interval.start.toJSDate();
    }
  }

  override writeValue(value: any): void {
    if (!!value) {
      this.interval.start = DateTime.fromJSDate(value.start as Date).startOf(this.duration);
      this.interval.end = DateTime.fromJSDate(value.end).endOf(this.duration);
    }
    this.value = value;
  }

}
