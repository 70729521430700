import {Component, EventEmitter, Host, Input, OnInit, Output, SkipSelf} from '@angular/core';
import { isString, startCase } from 'lodash';
import {ControlContainer, FormControl, Validators} from '@angular/forms';
import {IconDefinition} from '@fortawesome/free-brands-svg-icons';

export type TFormControlWrapperType = 'input' | 'dropdown' | 'multiselect' | 'chips' | 'treeSelect' | 'attachments';

@Component({
  selector: 'app-form-control-wrapper',
  template: `
    <div class="label-container" [class.checkbox]="dataType === 'boolean'">
      <label class="label" [class.mt-required-field]="isRequired()" [class.text-gray-400]="formControl.disabled">
        <fa-icon *ngIf="labelIcon" [icon]="labelIcon" class="mr-2"></fa-icon>
        {{label}}
      </label>
      <button *ngIf="showAddButton" pButton pRipple type="button" icon="pi pi-plus font-bold"
              class="p-button-rounded p-button-text" (click)="addButtonClick.emit($event)"
              [pTooltip]="'Add New ' + label" tooltipPosition="bottom"></button>
    </div>
    <ng-container [ngSwitch]="controlType">
      <ng-container *ngSwitchCase="'input'">
        <span [class.p-input-icon-left]="!!icon">
          <i *ngIf="icon" class="cursor-pointer" [ngClass]="icon"
             [class.p-disabled]="!!iconDisabled"
             (click)="onIconClick()" [pTooltip]="iconTooltip!" tooltipPosition="bottom"></i>
          <ng-container [ngSwitch]="dataType">
            <ng-container *ngSwitchCase="'string'">
                <input [formControl]="formControl" pInputText type="text" nullable/>
            </ng-container>
            <ng-container *ngSwitchCase="'currency'">
              <p-inputNumber [formControl]="formControl" mode="currency" currency="USD" locale="en-US"
                             [showClear]="true">
              </p-inputNumber>
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
              <p-inputNumber [formControl]="formControl"
                             [minFractionDigits]="minFractionDigits!" [maxFractionDigits]="maxFractionDigits!"
                             [min]="min!" [max]="max!"
                             [prefix]="prefix!" [suffix]="suffix!"
                             [showButtons]="showButtons!"
                             [showClear]="true" [placeholder]="placeholder">
              </p-inputNumber>
            </ng-container>
            <ng-container *ngSwitchCase="'date'">
                <p-calendar [formControl]="formControl" [showIcon]="true" appendTo="body"
                            [placeholder]="placeholder"></p-calendar>
            </ng-container>
            <ng-container *ngSwitchCase="'text'">
                <textarea [formControl]="formControl" pInputTextarea [autoResize]="true"
                          [style.min-height]="'46px'" nullable></textarea>
            </ng-container>
            <ng-container *ngSwitchCase="'boolean'">
                <p-checkbox [formControl]="formControl" [binary]="true"></p-checkbox>
            </ng-container>
          </ng-container>
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="'dropdown'">
        <span [class.p-input-icon-left]="!!icon">
          <i *ngIf="icon" class="cursor-pointer" [ngClass]="icon"
             [class.p-disabled]="!!iconDisabled"
             (click)="onIconClick()" [pTooltip]="iconTooltip!" tooltipPosition="bottom"></i>
          <p-dropdown [formControl]="formControl"
                      [editable]="dropdownEditable"
                      [autoDisplayFirst]="false"
                      appendTo="body"
                      [options]="options"
                      [optionLabel]="optionLabel!"
                      [optionValue]="optionValue!"
                      [filter]="optionsFilter"
                      [filterBy]="optionsFilterBy!"
                      [showClear]="!isRequired()"
                      [virtualScroll]="virtualScroll"
                      [virtualScrollItemSize]="virtualScrollItemSize"
                      nullable>
            <ng-template let-item pTemplate="selectedItem">
              <div class="flex align-items-center" *ngIf="formControl.value != null">
                <img *ngIf="optionIcon" [src]="getOptionIcon(item)" width="14" class="mr-1"/>
                <div>{{item[optionLabel || 'label'] ?? item}}</div>
              </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
               <div class="flex align-items-center">
                 <img *ngIf="optionIcon" [src]="getOptionIcon(item)" width="24" class="mr-1"/>
                 <div>{{item[optionLabel || 'label'] ?? item}}</div>
               </div>
            </ng-template>
          </p-dropdown>
        </span>
      </ng-container>
      <ng-container *ngSwitchCase="'multiselect'">
        <p-multiSelect [formControl]="formControl"
                       display="chip"
                       appendTo="body"
                       [options]="options"
                       [optionLabel]="optionLabel!"
                       [optionValue]="optionValue!"
                       [filter]="optionsFilter"
                       [filterBy]="optionsFilterBy!"
                       [showToggleAll]="!virtualScroll"
                       [showClear]="true"
                       [virtualScroll]="virtualScroll"
                       [virtualScrollItemSize]="virtualScrollItemSize"
                       nullable>
        </p-multiSelect>
      </ng-container>
      <ng-container *ngSwitchCase="'treeSelect'">
        <app-tree-select [formControl]="formControl"
                         [filter]="optionsFilter"
                         selectionMode="single"
                         [options]="options"
                         nullable>
        </app-tree-select>
      </ng-container>
      <ng-container *ngSwitchCase="'chips'">
        <p-chips [formControl]="formControl" [showClear]="true" nullable></p-chips>
      </ng-container>
      <ng-container *ngSwitchCase="'attachments'">
        <app-upload-attachments [formControl]="formControl"></app-upload-attachments>
      </ng-container>
    </ng-container>
    <app-control-error [control]="formControl"></app-control-error>
  `,
  styles: [`
    .label-container {
      display: inline-block;
      margin-bottom: 0.5rem;
    }

    .label-container button {
      width: 1rem;
      height: 1rem;
      margin-left: 0.5rem;
    }

    .label {
      display: inline-block;
    }

    .label-container.checkbox {
      margin-bottom: .2rem;
      margin-right: .5rem;
    }

    :host ::ng-deep .p-multiselect-label {
      white-space: normal;
      display: block !important;
    }

    :host ::ng-deep .p-multiselect-label .p-multiselect-token {
      margin: 1px .5rem 1px 0;
    }

    :host ::ng-deep .p-input-icon-left > i:first-of-type {
      z-index: 1;
    }

    :host ::ng-deep .p-input-icon-left .p-dropdown .p-dropdown-label {
      padding-left: 2.5rem;
    }
  `]
})
export class FormControlWrapperComponent implements OnInit {
  @Input() controlType: TFormControlWrapperType = 'input';
  @Input() dataType: 'string' | 'text' | 'date' | 'number' | 'currency' | 'boolean' = 'string';
  @Input() controlName!: string;

  @Input() label?: string;
  @Input() labelIcon?: IconDefinition;
  @Input() placeholder?: string;

  private _icon?: string;
  @Input() set icon(value: string | undefined) {
    this._icon = value;
  }

  get icon(): string | undefined {
    return this._icon || (this.iconIsLink ? 'pi pi-external-link' : undefined);
  }

  private _iconDisabled?: boolean;
  @Input() set iconDisabled(value: boolean | undefined) {
    this._iconDisabled = value;
  }

  get iconDisabled(): boolean {
    return this._iconDisabled || this.iconIsLink ? this.formControl?.invalid || !this.value : false;
  }

  @Input() iconTooltip?: string;
  @Input() iconIsLink = false;
  @Input() linkValue?: string;
  @Output() iconClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() dropdownEditable = false;
  @Input() options: Array<any> = [];
  @Input() optionLabel?: string;
  @Input() optionValue?: string;
  @Input() optionsFilter = false;
  @Input() optionsFilterBy?: string;
  @Input() optionIcon?: ((item: any) => string | null) | string;

  @Input() virtualScroll = false
  @Input() virtualScrollItemSize = 39;

  @Input() showButtons = false;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() minFractionDigits?: number;
  @Input() maxFractionDigits?: number;
  @Input() min?: number;
  @Input() max?: number;

  @Input() showAddButton = false;
  @Output() addButtonClick: EventEmitter<any> = new EventEmitter<any>();

  formControl!: FormControl;

  constructor(private controlContainer: ControlContainer) {
  }

  get value(): any {
    return this.formControl?.value;
  }

  setValueAndMark(value: any): void {
    this.formControl?.setValue(value);
    this.formControl?.markAsDirty();
    this.formControl?.markAsTouched();
  }

  ngOnInit(): void {
    if (this.controlContainer && this.controlName) {
      this.formControl = this.controlContainer!.control!.get(this.controlName)! as FormControl;
    }
    if (!this.label) {
      this.label = startCase(this.controlName);
    }
    if (!!this.optionValue && !!!this.optionLabel) {
      this.optionLabel = this.optionValue;
    }
  }

  isRequired(): boolean {
    return this.formControl?.hasValidator(Validators.required);
  }

  onIconClick(): void {
    if (this.iconIsLink) {
      window.open(this.controlType === 'dropdown' ? this.linkValue : this.value, '_blank');
    } else {
      this.iconClick.emit(this.value);
    }
  }

  isArrayType(): boolean {
    return (['multiselect', 'chips'] as Array<TFormControlWrapperType>).includes(this.controlType);
  }

  getOptionIcon(item: any): string | null {
    if (!item || !this.optionIcon) {
      return null;
    }
    if (isString(this.optionIcon)) {
      return item[this.optionIcon];
    } else {
      return this.optionIcon(item);
    }
  }
}
