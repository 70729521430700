import {MenuItem} from 'primeng/api';
import {TApprovalsState} from '../../api/shared/app-domain/common';
import {Component, Input} from '@angular/core';

export const getApprovalsTabMenu = (link: string, exclude: Array<TApprovalsState | 'All'> = []): Array<MenuItem> => [
  ...(!exclude.includes('All') ? [{
    label: 'All',
    routerLink: [link],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'All'
    }
  }] as Array<MenuItem> : []),
  {
    label: 'Unsubmitted',
    routerLink: [link],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'Unsubmitted' as TApprovalsState
    }
  },
  {
    label: 'Pending',
    routerLink: [link],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'Pending' as TApprovalsState
    }
  },
  {
    label: 'Approved',
    routerLink: [link],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'Approved' as TApprovalsState
    },
  },
  ...(!exclude.includes('Denied') ? [{
    label: 'Denied',
    routerLink: [link],
    queryParamsHandling: 'merge',
    queryParams: {
      tab: 'Denied' as TApprovalsState
    }
  }] as Array<MenuItem> : []),

];

@Component({
  selector: 'app-approvals-state',
  template: `
      <i class="pi"
         [class.pi-circle]="state === 'Unsubmitted'"
         [class.pi-clock]="state === 'Pending'"
         [class.pi-thumbs-up]="state === 'Approved'"
         [class.pi-ban]="state === 'Denied'"
         [pTooltip]="state"></i>
  `,
  styles: [`
    :host {
      display: block;
      text-align: center;
      width: 100%;
    }
  `]
})
export class ApprovalsStateComponent {
  @Input() state!: TApprovalsState;
}

export type TApproveAction = 'submit' | 'submitAndApprove' | 'approve' | 'reject' | 'deny';
