import {
  COMMON_STATUSES,
  ITeamParticipant,
  TAvailability,
  THubspotStage,
} from './common';

export const TEAM_CANDIDATE_API_ENDPOINTS = {
  teamCandidateCount: '/api/team-candidates/count',
  teamCandidates: '/api/team-candidates',
  teamCandidate: '/api/team-candidates/:id',
  export: '/api/team-candidates/export',
  import: '/api/team-candidates/import',
};

export const TEAM_CANDIDATE_STATUS = [
  ...COMMON_STATUSES
] as const;

export type TTeamCandidateStatus = typeof TEAM_CANDIDATE_STATUS[number];

export interface ITeamCandidate extends ITeamParticipant {
  status: TTeamCandidateStatus;
  availability: TAvailability;

  hubspotCandidateId?: string;
  hubspotCandidate?: string;
  hubspotCandidateUrl?: string;
  pipeline?: string;
  stage?: THubspotStage;
  hubspotContactId?: string;
  hubspotContact?: string;
  hubspotContactUrl?: string;

}
export type TTeamCandidateSave = Omit<ITeamCandidate,
  'id' | 'createdAt' | 'updatedAt' | 'name' |
  'roleName' | 'departmentName' | 'talentPartnerName' | 'searchSourceUrl' |
  'referralName' | 'hubspotCandidate' | 'hubspotContact' | 'hubspotContactUrl' | 'isDraft'>;

