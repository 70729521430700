import {COMMON_STATUSES, IIdentified, INamed, ITimestamped} from './common';

export const SKILL_API_ENDPOINTS = {
    skillCount: '/api/skills/count',
    skills: '/api/skills',
    searchSkills: '/api/skills/search',
    skill: '/api/skills/:id',
    export: '/api/skills/export',
    import: '/api/skills/import',
    downloadExport: '/api/skills/export/:id',
};

export const SKILL_STATUSES = [...COMMON_STATUSES, 'For Review'] as const;
export type TSkillStatus = typeof SKILL_STATUSES[number];

export interface ISkill extends IIdentified, INamed, ITimestamped {
    status: TSkillStatus;
    silo: string;
    adjective: string;
    singular: string;
    plural: string;
    team: string;
    teams: string;
    siloMember: string;
    siloMembers: string;
    urlSlug: string;
    metaTitle?: string;
    metaDescriptions?: string;
    isOkToPublish: boolean;
}

export type TSkillSave = Omit<ISkill, 'id' | 'createdAt' | 'updatedAt'>;
