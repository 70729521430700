import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as d3 from 'd3';
import {Selection, Simulation} from 'd3';

export interface IGraphNode {
  id: string;
  name: string;
}

export interface IGraphLink {
  source: string;
  target: string;
}

export interface IGraphData {
  nodes: Array<IGraphNode>;
  links: Array<IGraphLink>;
}

const RADIUS = 36;


@Component({
  selector: 'app-relationship-graph',
  template: `
  `,
  styles: [`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    :host ::ng-deep .links line {
      stroke: #999;
      stroke-opacity: 0.6;
      marker-end: url(#arrow);
    }

    :host ::ng-deep .nodes path {
      stroke-width: 2px;
      stroke: #6A6A6A;
      fill: #FAFAFA;
      cursor: pointer;
    }

    :host ::ng-deep text {
      font-size: 12px;
      text-anchor: middle;
      dominant-baseline: central;
      cursor: pointer;
    }
  `]
})
export class RelationshipGraphComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() data!: IGraphData;
  @Output() selectNode: EventEmitter<any> = new EventEmitter<any>();
  private svg!: Selection<any, any, any, any>;
  private g!: Selection<any, any, any, any>;
  private link!: Selection<any, any, any, any>;
  private node!: Selection<any, any, any, any>;
  private shapes!: Selection<any, any, any, any>;
  private simulation!: Simulation<any, any>;
  private hostElement: HTMLElement;
  private focusNodeId: string | null = null;
  private activeNodeId: string | null = null;
  private linkSet: {[linkId: string]: boolean} = {};
  private inDegreeDict: {[targetId: string]: number} = {};

  constructor(private elRef: ElementRef) {
    this.hostElement = this.elRef.nativeElement;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (!!changes['data'].currentValue) {
        this.render();
      }
    }
  }

  private init(): void {
    this.svg = d3.select(this.hostElement).append('svg');
    this.svg.attr('width', '100%').attr('height', '100%');
    this.g = this.svg.append('g');
    this.svg.call(
      d3.zoom()
        .scaleExtent([0.1, 8])
        .on('zoom', (e) => this.g.attr('transform', e.transform))
    ).on('dblclick.zoom', null);
    this.svg.style('cursor','move');


    this.g.append('svg:defs').selectAll('marker')
      .data(['arrow'])
      .enter().append('svg:marker')
      .attr('id', String)
      .attr('viewBox', '0 -5 11.3 10') // shift arrow
      .attr('refX', 60)
      .attr('refY', 0)
      .attr('markerWidth', 8)
      .attr('markerHeight', 8)
      .attr('orient', 'auto')
      .append('svg:path')
      .attr('d', 'M0,-5L10,0L0,5');
  }

  private render(): void {
    if (!this.svg) {
      this.init();
    }
    this.linkSet = {};
    this.inDegreeDict = {};
    this.focusNodeId = this.activeNodeId = null;

    this.data.links.forEach((d) => {
      this.linkSet[this.encodeLink(d.source, d.target)] = true;
      if (!this.inDegreeDict[d.target]) {
        this.inDegreeDict[d.target] = 0;
      }
      this.inDegreeDict[d.target] ++;
    });

    this.simulation = d3.forceSimulation(this.data.nodes as any)
      .force('charge', d3.forceManyBody().strength(-1200))
      .force('link', d3.forceLink(this.data.links).id((d: any) => {
        return d.id
      }).distance(150))
      .force('collide', d3.forceCollide(RADIUS + 10).iterations(10))
      .force('center', d3.forceCenter(this.hostElement.clientWidth / 2, this.hostElement.clientHeight / 2))
    // .alphaDecay(0.01)
    // .velocityDecay(0.1);


    this.link =
      this.g.append('g')
        .attr('class', 'links')
        .selectAll('line').data(this.data.links)
        .enter().append('line');

    this.node =
      this.g.append('g')
        .attr('class', 'nodes')
        .attr('fill', '#6A6A6A')
        .selectAll('g').data(this.data.nodes)
        .enter().append('g')
        .call(this.drag());

    const sym = d3.symbol().type(d3.symbolCircle).size(RADIUS * 100);
    this.shapes = this.node.append('path').attr('d', sym);

    this.node.append('text')
      .html( (d) => {
        const pad = 1.2;
        const names: Array<string> = d.name.split(' ');
        const top = (names.length - 1) * -pad / 2;
        return names.map((n, i) =>
          `<tspan x='0' y='${top + pad * i}em'>${n}</tspan>`
        ).join('');
      });

    this.node.append('title').text((d) => {
      return d.id;
    });

    this.node.on('mouseover', (ev, d) => {
      if (this.focusNodeId) {
        return;
      }
      this.activeNodeId = d.id;
      this.update();
    }).on('mouseout', (ev, d) => {
      if (this.focusNodeId) {
        return;
      }
      this.activeNodeId = null;
      this.update();
    }).on('click', (ev, d) => {
      this.selectNode.emit({event: ev, id: d.id});
    }).on("contextmenu", (ev, d) => {
      ev.preventDefault();
      this.selectNode.emit({event: ev, id: d.id});
    });

    this.simulation
      .nodes(this.data.nodes)
      .on('tick', () => this.ticked());

    (this.simulation.force('link') as any).links(this.data.links);
  }

  private ticked() {
    this.link
      .attr('x1', (d: any) => d.source.x)
      .attr('y1', (d: any) => d.source.y)
      .attr('x2', (d: any) => d.target.x)
      .attr('y2', (d: any) => d.target.y);
    this.node
      // .attr('cx', (d: any) => d.x)
      // .attr('cy', (d: any) => d.y)
      .attr('transform', (d) => 'translate(' + d.x + ',' + d.y + ')' );
  }

  private drag(): any {
    const dragStarted = (event: any) => {
      if (!event.active) {
        this.simulation.alphaTarget(0.3).restart();
      }
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
      this.focusNodeId = this.activeNodeId;
      this.update();
    }

    const dragged = (event: any) => {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    const dragEnded = (event: any) => {
      if (!event.active) {
        this.simulation.alphaTarget(0);
      }
      event.subject.fx = null;
      event.subject.fy = null;
      this.focusNodeId = null;
      this.update();
    }

    return d3.drag()
      .on('start', dragStarted)
      .on('drag', dragged)
      .on('end', dragEnded);
  }

  private encodeLink(a: string, b: string) {
    return a + '->' + b;
  }

  private isConnected(a: string, b: string): boolean {
    return this.linkSet[this.encodeLink(a, b)] || this.linkSet[this.encodeLink(b, a)] || a == b;
  }

  private update(): void {
    if (this.activeNodeId) {
      this.node.attr('fill', (d) => {
        return this.isConnected(this.activeNodeId!, d.id) ? '#D5A326' : '#6A6A6A';
      });
      this.node.attr('opacity', (d) => {
        return this.focusNodeId && !this.isConnected(this.activeNodeId!, d.id) ? '10%' : '100%';
      });
      this.shapes.style('stroke', (d) => {
        if (this.linkSet[this.encodeLink(this.activeNodeId!, d.id)]) {
          return 'blue';
        } else if (this.linkSet[this.encodeLink(d.id, this.activeNodeId!)]) {
          return 'red';
        } else if (this.activeNodeId === d.id) {
          return 'black';
        }
        return '#6A6A6A';
      });
      this.link.style('stroke', (d) => {
        if (this.activeNodeId === d.source.id) {
          return 'blue';
        } else if (this.activeNodeId === d.target.id) {
          return 'red';
        }
        return '#999';
      });
      this.link.attr('opacity', (d) => {
        return this.focusNodeId && this.activeNodeId !== d.source.id && this.activeNodeId !== d.target.id
          ? '0%' : '100%';
      });

    } else {
      this.node.attr('fill', '#6A6A6A');
      this.node.attr('opacity', '100%');
      this.shapes.style('stroke', '#6A6A6A');
      this.link.style('stroke', '#999');
      this.link.attr('opacity', '100%');
    }
  }
}

