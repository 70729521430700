import {Component} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

@Component({
  template: ''
})
export abstract class AbstractControlValueAccessorComponent<T> implements ControlValueAccessor {
  value: T | null | undefined;
  isDisabled = false;
  onModelChange: (val: any) => void = () => {};
  onModelTouched: () => void = () => {};

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}

