import {Injectable, Injector, OnDestroy, Optional} from '@angular/core';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {
  ITeamMember,
  TEAM_MEMBER_DEPS,
  TTeamMemberSave,
  TTeamMemberStatus,
  TTeamMemberType
} from '../../api/shared/app-domain/team';
import {find, reduce} from 'lodash';
import {TreeNode} from 'primeng/api';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {TPaymentTerm} from '../../api/shared/app-domain/common';
import {FormControl} from '@angular/forms';
import {IPaidTimeOff} from '../../api/shared/app-domain/settings';

@Injectable()
export class TeamMemberEditService implements OnDestroy {
  private readonly _subscriptions: SubscriptionsService;
  private editor!: EntityEditorBaseComponent<ITeamMember, { [name in keyof Partial<TTeamMemberSave>]: FormControl }>
  statusChangedReasonOptions: Array<TreeNode> = [];
  paymentTermsOptions: Array<TPaymentTerm> = [];
  paidTimeOffs:  {[type in TTeamMemberType]?: number} = {};



  constructor(@Optional() subscriptions: SubscriptionsService,
              public propertyOptions: EntityPropertyOptionsService) {
    this._subscriptions = subscriptions ?? new SubscriptionsService();
  }

  setEditor(editor: EntityEditorBaseComponent<ITeamMember>): void {
    this.editor = editor;
  }

  subscribe(): void {
    if (this.editor.getControl('status')) {
      this.subscriptions.add(
        this.editor.getControl('status')!.valueChanges.subscribe((val) => this.processStatus(val, false))
      );
      this.processStatus(this.editor.getControl('status')!.value, true);
    }
    if (this.editor.getControl('type')) {
      this.propertyOptions.getOptions('paidTimeOffs').subscribe((result) => {
        this.paidTimeOffs = reduce<IPaidTimeOff, any>(result, (acc, v, k) => {
          acc[v.name] = v.days;
          return acc;
        }, {});
      });
      this.subscriptions.add(this.editor.getControl('type')!.valueChanges.subscribe((val) => this.processType(val, false)));
      this.processType(this.editor.getControl('type')!.value, true);
    }
    if (this.editor.getControl('isWorkweekOverridden')) {
      this.subscriptions.add(this.editor.getControl('isWorkweekOverridden')!.valueChanges.subscribe((val) => this.processWorkweeks(val, false)));
      this.processWorkweeks(this.editor.getControl('isWorkweekOverridden')!.value, true);
    }
    if (this.editor.getControl('isHolidayCalendarOverridden')) {
      this.subscriptions.add(this.editor.getControl('isHolidayCalendarOverridden')!.valueChanges.subscribe((val) => this.processHolidayCalendar(val, false)));
      this.processHolidayCalendar(this.editor.getControl('isHolidayCalendarOverridden')!.value, true);
    }
  }

  processStatus(status: TTeamMemberStatus | null, initial: boolean): void {
    if (!initial) {
      this.editor.setValueAndMark('statusChangedReason', null);
      this.editor.setValueAndMark('estimatedAvailableDate', null);
    }
    if (status === 'Archived' || status === 'Currently Unavailable') {
      this.statusChangedReasonOptions = find(
        this.propertyOptions.teamMemberStatusChangedReasonsAsTree, {key: status}
      )!.children!;
      this.editor.setEnable('statusChangedReason', true);
    } else { // Active
      this.statusChangedReasonOptions = [];
      this.editor.setEnable('statusChangedReason', false);
    }
    if (status !== 'Currently Unavailable') {
      this.editor.setEnable('estimatedAvailableDate', false, false);
    } else {
      this.editor.setEnable('estimatedAvailableDate', true, false);
    }
  }


  processType(type: TTeamMemberType | null, initial: boolean): void {
    if (!initial) {
      this.editor.setValueAndMark('paymentTerms', null);
      this.editor.setValueAndMark('netTerms', null);
    }
    if (type) {
      this.paymentTermsOptions = [...TEAM_MEMBER_DEPS[type].paymentTerms];
      if (this.paymentTermsOptions.length > 1) {
        this.editor.setEnable('paymentTerms', true);
      } else {
        if (this.paymentTermsOptions.length === 1) {
          this.editor.setValueAndMark('paymentTerms', this.paymentTermsOptions[0]);
        }
        this.editor.setEnable('paymentTerms', false);
      }
      if (TEAM_MEMBER_DEPS[type].requiresNetTerms) {
        this.editor.setEnable('netTerms', true);
      } else {
        this.editor.setEnable('netTerms', false);
      }
    } else {
      this.editor.setEnable('paymentTerms', false);
      this.editor.setEnable('netTerms', false);
    }
    const ptoCtrl = this.editor.getControl('pto');
    if (!initial && ptoCtrl && !ptoCtrl.dirty && this.paidTimeOffs[type!] !== undefined) {
      ptoCtrl.setValue(this.paidTimeOffs[type!]);
    }
  }

  processWorkweeks(isWorkweekOverridden: boolean, initial: boolean): void {
    this.editor.setEnable('defaultWorkweekId', !isWorkweekOverridden, true);
    this.editor.setEnable('overriddenWorkweeks', isWorkweekOverridden, true);
  }

  processHolidayCalendar(isHolidayCalendarOverridden: boolean, initial: boolean): void {
    this.editor.setEnable('holidayCalendarId', !isHolidayCalendarOverridden, true);
    this.editor.setEnable('overriddenHolidayCalendar', isHolidayCalendarOverridden, true);
  }

  get subscriptions(): SubscriptionsService {
    return this._subscriptions;
  }
  ngOnDestroy(): void {
    this.unsubscribe();
  }

  unsubscribe(): void {
    this.subscriptions.unsubscribe();
  }
}
