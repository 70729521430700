import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {FilterMetadata, MenuItem} from 'primeng/api';
import {EntityEditDialogComponent} from '../../components/common/entity-edit.dialog';
import {IEntityEditorParam} from '../../components/common/entity-editor.component';
import {filter, find, findIndex, isEqual, omit, remove, some, unionWith} from 'lodash';
import {IIdentified, TCommonStatus} from '../../api/shared/app-domain/common';
import {ServiceLocator} from '../util/util';
import {Observable} from 'rxjs';
import {ISearchRequest, TWithId} from '../../api/shared/search-api';
import {FileUploadDialog} from './file';
import {IEntityBulkEditorParam} from '../../components/common/entity-bulk-editor.component';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';
import {EntityResource, IEntityResource} from '../../app.resource.service';

export interface IMenuItem extends MenuItem {
  onShowMenu?: (rowData: any, menuItem: IMenuItem) => void;
  rowData?: any;
}

@Component({
  selector: 'app-row-menu-button',
  template: `
    <div style="width: 30px; min-width: 30px">
      <p-menu #menu [popup]="true" [model]="rowMenuItems" appendTo="body" [style]="{width: 'auto', minWidth: '150px'}"
              (onShow)="onShowRowMenu()"></p-menu>
      <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
              class="p-button-rounded p-button-text p-button-sm w-2rem h-2rem"
              (click)="tableBase.rowMenuButtonClickEvent = $event; tableBase.currentMenuRowData = rowData; menu.toggle($event)">
      </button>
    </div>
  `
})
export class RowMenuButtonComponent {
  @Input() tableBase!: TableBaseComponent<any>;
  @Input() rowData!: any;
  @Input() omitMenuItems: Array<string> = [];
  @Input() customMenuItems?: Array<IMenuItem>;


  standardRowMenuItems: Array<IMenuItem> = [
    {
      id: 'edit',
      label: 'Edit', icon: 'pi pi-pencil',
      command: (event: MenuItemCommandEvent) => this.tableBase.openCreateOrUpdateDialog(this.tableBase.currentMenuRowData)
    },
    {
      id: 'duplicate',
      label: 'Duplicate',
      icon: 'pi pi-clone',
      command: (event: MenuItemCommandEvent) => this.tableBase.openDuplicateDialog(this.tableBase.currentMenuRowData.id)
    },
    {
      id: 'archive',
      label: 'Archive',
      icon: 'pi pi-server',
      command: (event: MenuItemCommandEvent) => this.tableBase.archive([this.tableBase.currentMenuRowData])
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: 'pi pi-trash',
      styleClass: 'mt-alert-menuitem',
      command: (event: MenuItemCommandEvent) => this.tableBase.delete(this.tableBase.rowMenuButtonClickEvent.target!, [this.tableBase.currentMenuRowData.id])
    }
  ];

  rowMenuItems: Array<MenuItem> = [];

  onShowRowMenu(): void {
    const standardRowMenuItems = [...this.standardRowMenuItems];
    standardRowMenuItems.forEach((mi) => {
      mi.visible = !this.omitMenuItems.includes(mi.id!);
    });
    // this.rowMenuItems = [...(this.customMenuItems ?? []), ...standardRowMenuItems];
    this.rowMenuItems = unionWith(this.customMenuItems ?? [], standardRowMenuItems, (a, b) => a.id === b.id);
    this.rowMenuItems.forEach((mi) => {
      (mi as IMenuItem).rowData = this.tableBase.currentMenuRowData;
      if ((mi as IMenuItem).onShowMenu) {
        (mi as IMenuItem).onShowMenu!(this.tableBase.currentMenuRowData, mi);
      }
    });
    const mi = find(this.rowMenuItems, {id: 'archive'});
    mi!.disabled = this.tableBase.currentMenuRowData.status === 'Archived';
  }

}


@Component({
  selector: 'app-table-toolbar',
  template: `
    <app-file-upload-dialog #fileUploadDialog></app-file-upload-dialog>
    <div class="flex align-items-center justify-content-between mb-2">
      <div class="flex align-items-center">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>

          <input [(ngModel)]="tableBase.search"
                 pInputText type="text" class="p-inputtext-sm" [style.width]="'300px'"
                 placeholder="Search"
                 appChangesDebounce (debounced)="tableBase.load()"/>
        </span>
        <div class="ml-3 text-gray-700" style="width: 6rem;">{{tableBase.selection.length}} selected</div>
        <p-menubar class="ml-2 mt-page-menu-bar" [model]="tableMenu"></p-menubar>
      </div>
      <div class="flex align-items-center">
        <button pButton pRipple type="button"
                icon="pi pi-refresh" class="p-button-rounded p-button-text mr-2"
                pTooltip="Reload" tooltipPosition="bottom" (click)="tableBase.load()"></button>
        <ng-container *ngIf="showActions">
          <p-menu #tableActionsMenu [popup]="true" [model]="tableActionItems"></p-menu>
          <button pButton pRipple label="Table Actions" class="p-button-sm p-button-outlined"
                  icon="pi pi-chevron-down"
                  iconPos="right" (click)="tableActionsMenu.toggle($event)"></button>
        </ng-container>
      </div>
    </div>
  `
})
export class TableToolbarComponent implements OnChanges {
  @Input() tableBase!: TableBaseComponent<any>;
  @Input() omitMenuItems: Array<string> = [];
  @Input() customMenuItems?: Array<IMenuItem>;
  @Input() showActions = true;

  @ViewChild(FileUploadDialog) fileUploadDialog!: FileUploadDialog;


  ngOnChanges(changes: SimpleChanges): void {
    this.tableMenu = [...this.standardTableMenu, ...(this.customMenuItems || [])];
    if (changes['omitMenuItems']) {
      this.tableMenu.forEach((i) => i.visible = !this.omitMenuItems.includes(i.id!));
    }
  }


  tableActionItems: Array<MenuItem> = [
    {
      label: 'Import',
      icon: 'pi pi-fw pi-file-import',
      command: () => this.fileUploadDialog.show({
        url: this.tableBase.api?.importUrl,
        header: 'Import ' + this.tableBase.entityName,
        accept: '.csv',
        onClose: (file?: File) => {
          if (file) {
            this.tableBase.load();
          }
        }
      })
    },
    {
      label: 'Export to CSV',
      icon: 'pi pi-fw pi-file-export',
      command: () => {
        if (this.tableBase.api?.exportEntities) {
          this.tableBase.api?.exportEntities(omit(this.tableBase.currentSearchRequest, ['offset', 'limit']));
        }
      }
    }
  ];
  tableMenu: Array<MenuItem> = [];

  standardTableMenu: Array<MenuItem> = [
    {
      id: 'edit',
      label: 'Edit',
      icon: 'pi pi-pencil',
      disabled: true,
      command: (event: MenuItemCommandEvent) => this.tableBase.openBulkEditDialog(this.tableBase.selection)
    },
    {
      id: 'archive',
      label: 'Archive',
      icon: 'pi pi-server',
      disabled: true,
      command: (event: MenuItemCommandEvent) => this.tableBase.archive(this.tableBase.selection)
    },
    {
      id: 'delete',
      label: 'Delete',
      icon: 'pi pi-trash',
      disabled: true,
      command: (event: MenuItemCommandEvent) => this.tableBase.delete(event.originalEvent!.target!, this.tableBase.selection.map((e) => e.id))
    }
  ];

  updateMenu(): void {
    for (const mi of this.tableMenu) {
      mi.disabled = !this.tableBase.selection?.length;
    }
    if (this.tableBase.selection?.length) {
      this.tableMenu.forEach((m) => {
        const someActive = some(this.tableBase.selection!, this.tableBase.canPerformTableMenuAction(m.id!));
        if (!someActive) {
          find(this.tableMenu, {id: m.id})!.disabled = true;
        }
      });
    }
    this.tableMenu = [...this.tableMenu];
  }
}

@Component({
  template: ''
})
export abstract class TableBaseComponent<T extends IIdentified> {
  entityName = '';
  loading = false;
  data!: Array<T>;
  search!: string;
  selection: Array<T> = [];
  currentSearchRequest: ISearchRequest = {};
  currentMenuRowData!: any;
  rowMenuButtonClickEvent!: Event;
  filters: { [prop: string]: FilterMetadata | FilterMetadata[]; } = {};

  @ViewChild(TableToolbarComponent) tableToolbar?: TableToolbarComponent;

  api: Partial<IEntityResource<any, T>> = {};

  @ViewChild('editDialog', {read: EntityEditDialogComponent}) editDialog?: EntityEditDialogComponent;
  @ViewChild('bulkEditDialog', {read: EntityEditDialogComponent}) bulkEditDialog?: EntityEditDialogComponent;


  @ViewChild(Table, {static: false}) tableComponent!: Table;

  openCreateOrUpdateDialog(rowData: T | null, initialData?: any): void {
    if (this.editDialog) {
      this.editDialog.show({id: rowData?.id ?? null, data: initialData} as IEntityEditorParam);
    }
  }

  openDuplicateDialog(id: string): void {
    if (this.editDialog) {
      this.editDialog.show({id, isDuplicate: true} as IEntityEditorParam);
    }
  }

  openBulkEditDialog(entities: Array<T>): void {
    if (this.bulkEditDialog) {
      this.bulkEditDialog.show({ids: entities.map((e) => e.id)} as IEntityBulkEditorParam);
    }
  }


  abstract load(param?: any): void;


  protected replaceDataFor(data: T): void {
    const idx = findIndex<IIdentified>(this.data, {id: data.id});
    if (idx !== -1) {
      this.data[idx] = data;
      this.data = [...this.data];
    }
  }

  archive(entities: Array<T>, statusChangedReason?: string): void {
    if (this.api?.archiveEntities) {

      const data: Array<TWithId<T>> // TODO: remove when get rid of old this.api usage
        = this.api instanceof EntityResource
        ? entities
        : filter<any>(entities, (e) => e.status !== 'Archived').map((e) => ({
          id: e.id,
          status: 'Archived',
          statusChangedReason
        } as TWithId<any>));
      this.api.archiveEntities(data)
        .subscribe((response) => {
          this.onUpdatedBySelection(response);
        });
    }
  }

  patchField(entities: Array<T>, patchData: Partial<T>, field: keyof T) {
    if (this.api?.patchEntities) {
      const data: Array<IIdentified & Partial<T>> = filter(entities, (e) => !isEqual(e[field], patchData[field])).map((e) => ({
        id: e.id,
        ...patchData
      }));
      this.api.patchEntities(data)
        .subscribe((response) => {
          this.onUpdatedBySelection(response);
        });
    }
  }

  protected onUpdatedBySelection(response: Array<T>): void {
    this.selection = [];
    if (response.length === 1) {
      this.replaceDataFor(response[0]);
    } else {
      this.load();
    }
    ServiceLocator.message({
      severity: 'success',
      summary: 'Success',
      detail: `${response.length} record(s) have been updated`
    });
    this.updateTableMenu();
  }

  delete(eventTarget: EventTarget, ids: Array<string>): void {
    if (this.api?.deleteEntities && ids.length) {
      ServiceLocator.confirm({
        target: eventTarget,
        key: 'g-popup', // 'g-dialog',
        header: 'Delete Confirmation',
        message: `Are you sure you want to delete ${ids.length > 1 ? ids.length + ' ' : ''}record${ids.length > 1 ? 's' : ''}?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.api!.deleteEntities!(ids)
            .subscribe(() => {
              this.afterCrudPerformed();
              ServiceLocator.message({
                severity: 'success',
                summary: 'Success',
                detail: `${ids.length} record(s) have been deleted`
              });
              this.selection = [];
              if (ids.length === 1) {
                remove<any>(this.data, {id: ids[0]});
              } else {
                this.load();
              }
              this.updateTableMenu();
            });
        }
      });
    }
  }

  onCreatedOrUpdated(event: { param: IEntityEditorParam; data: T }): void {
    this.afterCrudPerformed();
    const isNew = event.param.id === null || event.param.isDuplicate;
    if (isNew) {
      this.data.unshift(event.data);
    } else {
      this.replaceDataFor(event.data)
    }
  }


  onBulkUpdated(event: { param: IEntityBulkEditorParam; data: Array<T> }): void {
    this.afterCrudPerformed();
    this.selection = [];
    this.load();
    this.updateTableMenu();
  }

  onSelectionChange(): void {
    this.updateTableMenu();
  }

  updateTableMenu(): void {
    if (this.tableToolbar) {
      this.tableToolbar.updateMenu();
    }
  }

  afterCrudPerformed(): void {
  }

  canPerformTableMenuAction(itemId: string): ((entity: T) => boolean) {
    if (itemId === 'archive') {
      return ((entity: T) => ((entity as any).status as TCommonStatus) !== 'Archived');
    } else {
      return ((entity: T) => true);
    }
  }
}
