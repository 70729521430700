import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hours'
})
export class HoursPipe implements PipeTransform {
  transform(value: any): any {
    return value == null ? null : value + ' h';
  }
}
