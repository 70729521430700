import {Component} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {DomHandler} from 'primeng/dom';

@Component({
  selector: 'app-root',
  template: `
    <app-main-page></app-main-page>
  `
})
export class AppComponent {
  constructor(private primengConfig: PrimeNGConfig) {
  }

  ngOnInit() {
    DomHandler.addClass(document.body, '_p-input-filled');
    this.primengConfig.ripple = true;
  }
}
