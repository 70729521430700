import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {AppResourceService} from '../../../app.resource.service';
import {filter, find, findIndex, sortBy} from 'lodash';
import {finalize, map, Subscription} from 'rxjs';
import {TabView} from 'primeng/tabview';
import {IShownObjectType} from './hs';
import {HsAssociationsEditorComponent} from './hs-associations-editor.component';
import {ActivatedRoute} from '@angular/router';
import {IHsObjectType} from '../../../api/shared/dev-tools/hs-api';
import { HsService } from '../../../services/hs/hs.service';

@Component({
  selector: 'app-hs-associations-page',
  template: `
    <div>
      <app-spinnerizer [active]="loading"
                       [invertSpinner]="false"
                       [invertText]="true"
                       text="Loading schemas..."
                       [target]="container">
      </app-spinnerizer>
      <div #container>
        <div class="mb-2 flex justify-content-between">
          <div class="text-2xl">
            Associations Editor
          </div>
          <div>
            <p-checkbox [(ngModel)]="showAssociationNames"
                        [binary]="true" label="Show Association Names">
            </p-checkbox>
            <p-checkbox class="ml-3" [(ngModel)]="excludeStandardTypes"
                        [binary]="true" label="Exclude Standard Types">
            </p-checkbox>
          </div>
        </div>
        <p-tabView [scrollable]="true" [(activeIndex)]="activeIndex"
                   (onChange)="onTabChange($event.index)" (onClose)="onTabClose($event.index)">
          <p-tabPanel [header]="!shownObjectType.objectType ? '<empty>' : shownObjectType.objectType.label"
                      [closable]="i > 0"
                      *ngFor="let shownObjectType of shownObjectTypes; let i = index">
            <app-hs-associations-editor
              [objectTypes]="objectTypes"
              [customObjectTypes]="customObjectTypes"
              [shownObjectType]="shownObjectType"
              [excludeStandardTypes]="excludeStandardTypes"
              [showAssociationNames]="showAssociationNames"
              (openObjectType)="onOpenObjectType($event)">
            </app-hs-associations-editor>
          </p-tabPanel>
        </p-tabView>

      </div>
    </div>
  `,
  styles: [`
    :host ::ng-deep .p-tabview .p-tabview-panels {
      background-color: #FAFAFA;
    }
  `]
})
export class HsAssociationsPageComponent implements OnInit, OnDestroy {
  activeIndex = 0;
  objectTypes!: Array<IHsObjectType>;
  customObjectTypes!: Array<IHsObjectType>;
  loading = false;
  shownObjectTypes: Array<IShownObjectType> = [{objectType: null}];
  excludeStandardTypes = true;
  showAssociationNames = false;
  @ViewChild(TabView, {static: true}) tabView!: TabView;
  @ViewChildren(HsAssociationsEditorComponent) editors!: QueryList<HsAssociationsEditorComponent>;
  private subscription: Subscription;
  private qpObjectTypeId: string | null = null;

  constructor(private hsService: HsService,
              private route: ActivatedRoute) {
    this.subscription = this.route.queryParams.pipe(
      map((qp) => qp['objectTypeId']),
    ).subscribe((objectTypeId: string) => {
      if (!!objectTypeId) {
        this.qpObjectTypeId = objectTypeId;
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.hsService.getObjectTypes()
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((types) => {
        this.objectTypes = types;
        this.customObjectTypes = sortBy(filter(types, {isCustom: true}), (ot) => ot.label.toLowerCase());
        if (!!this.qpObjectTypeId) {
          const qpObjectType = find(this.objectTypes, {objectTypeId: this.qpObjectTypeId});
          if (qpObjectType) {
            this.shownObjectTypes[0].objectType = qpObjectType;
            this.editors.get(0)!.selectShownType();
          }
        }
      });
  }

  onOpenObjectType(objectType: IHsObjectType) {
    if (!!objectType) {
      let index = findIndex(
        this.shownObjectTypes, (ot) => ot.objectType?.objectTypeId === objectType.objectTypeId
      );
      const tabExists = index !== -1;
      if (!tabExists) {
        this.shownObjectTypes.push({objectType});
        index = this.shownObjectTypes.length - 1;
      }
      setTimeout(() => {
        this.activeIndex = index;
        if (tabExists) {
          this.onTabChange(index);
        }
      });
    }
  }

  onTabChange(index: number): void {
    this.editors.get(index)?.loadToObjectTypes();
  }

  onTabClose(index: number): void {
    this.shownObjectTypes.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}


