import {Component, forwardRef} from '@angular/core';
import {AppResourceService} from '../../app.resource.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {faSkype, faSlack} from '@fortawesome/free-brands-svg-icons';
import {faClock} from '@fortawesome/free-solid-svg-icons';
import {existsAsyncValidator, urlValidator} from '../../common/util/util';
import {SubscriptionsService} from '../../common/services/subscriptions.service';
import {EntityEditorBaseComponent} from '../common/entity-editor-base.component';
import {EntityPropertyOptionsService} from '../common/entity-property-options.service';
import {ITeamMember, TTeamMemberSave} from '../../api/shared/app-domain/team';
import {TeamParticipantEditorComponent} from '../common/team-participant-editor.component';
import {TControlsOf} from '../../common/types';
import {IUsefulLinks} from '../../api/shared/app-domain/common';
import {TeamMemberEditService} from './team-member-edit.service';


@Component({
  selector: 'app-team-member-editor',
  template: `
    <app-spinnerizer [active]="loading" [target]="container"></app-spinnerizer>

    <div #container class="mt-transparent">
      <form *ngIf="form" [formGroup]="form" class="flex">
        <div style="min-width: 300px;" class="pr-3"
             [style.max-width]="isNew() && !otherPropsPanel.isExpanded ? '500px' : '300px'">
          <div class="flex align-items-center mb-3">
            <app-avatar formControlName="avatar"
                        [imageServiceUrl]="imageServiceUrl"
                        [editable]="true" size="100px" fontSize="50px"
                        [name]="(form.controls['firstName'].value || '')  + ' ' + (form.controls['lastName'].value || '')"></app-avatar>
            <div class="formgrid grid w-full ml-3">
              <div class="field p-fluid col-12">
                <app-team-member-form-control name="firstName"></app-team-member-form-control>
              </div>
              <div class="field p-fluid col-12">
                <app-team-member-form-control name="lastName"></app-team-member-form-control>
              </div>
            </div>
          </div>
          <div class="formgrid grid">
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="type"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="email"></app-team-member-form-control>
            </div>
            <div class="field p-fluid col-12">
              <app-team-member-form-control name="birthday"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="country"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control
                  *ngIf="this.form!.get('country')!.value === 'United States'"
                  name="state"></app-team-member-form-control>
              <app-team-member-form-control
                  *ngIf="this.form!.get('country')!.value !== 'United States'"
                  [name]="$any('region')"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="city"></app-team-member-form-control>
            </div>

            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="timezone"></app-team-member-form-control>
            </div>
            <div class="field col-12 p-fluid">
              <app-team-member-form-control name="phone"></app-team-member-form-control>
            </div>
          </div>
        </div>
        <div [class.mt-draft-entity]="isNew() || isDraft()">
          <app-collapsible-panel #otherPropsPanel
                                 [isExpanded]="!isNew()"
                                 [showToggle]="isNew()"
                                 [toggleYPosition]="'0'"
                                 [width]="isNew() ? '1300px' : 'auto'"
                                 expandTooltip="Expand Other Properties">
            <div class="pl-2 flex">
              <p-tabView>
                <p-tabPanel header="General Properties">
                  <div class="formgrid grid">
                    <div class="field p-fluid col-3">
                      <app-team-member-form-control name="hiredDate"></app-team-member-form-control>
                    </div>
                    <div class="field p-fluid col-4">
                      <app-team-member-form-control name="title"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control name="mtEmailType"></app-team-member-form-control>
                    </div>
                    <div class="field col-3 p-fluid">
                      <app-team-member-form-control name="mtEmail"></app-team-member-form-control>
                    </div>

                    <ng-container *ngIf="!isNew() && !isDraft()">
                      <div class="field col-4 p-fluid">
                        <app-team-member-form-control name="status"></app-team-member-form-control>
                      </div>
                      <div class="field col-4 p-fluid">
                        <app-team-member-form-control name="statusChangedReason"
                                                      [statusChangedReasonOptions]="editService.statusChangedReasonOptions">
                        </app-team-member-form-control>
                      </div>
                      <div class="field p-fluid col-4">
                        <app-team-member-form-control
                            name="estimatedAvailableDate"></app-team-member-form-control>
                      </div>
                    </ng-container>


                    <div class="field col-12 p-fluid">
                      <app-team-member-form-control name="skills"></app-team-member-form-control>
                    </div>

                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control
                          name="weeklyCapacityMin"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control
                          name="weeklyCapacityTarget"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control
                          name="weeklyCapacityMax"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control
                          name="defaultHourlyRate"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control
                          name="costRateDefault"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control
                          name="costRateDefaultPlus"></app-team-member-form-control>
                    </div>

                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control name="paymentTerms"
                                                    [paymentTermsOptions]="editService.paymentTermsOptions">
                      </app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control name="netTerms"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control name="pto"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid">
                      <app-team-member-form-control name="ptoFrom"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid pt-5">
                      <app-team-member-form-control
                          name="isActivelyStuffed"></app-team-member-form-control>
                    </div>
                    <div class="field col-2 p-fluid pt-5">
                      <app-team-member-form-control name="gustoRequired"></app-team-member-form-control>
                    </div>

                    <div class="field col-12 p-fluid">
                      <app-team-member-form-control name="userRoles"></app-team-member-form-control>
                    </div>


                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control name="contractorCompanyId"></app-team-member-form-control>
                    </div>
                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control
                          name="paymentRecipientId"></app-team-member-form-control>
                    </div>
                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control name="referralId"></app-team-member-form-control>
                    </div>

                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control name="roleId"></app-team-member-form-control>
                    </div>
                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control name="departmentId"></app-team-member-form-control>
                    </div>
                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control
                          name="talentPartnerId"></app-team-member-form-control>
                    </div>

                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control name="searchSource"></app-team-member-form-control>
                    </div>
                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control
                          name="primarySourceUrl"></app-team-member-form-control>
                    </div>
                    <div class="field col-4 p-fluid">
                      <app-team-member-form-control
                          name="searchSourceKeyword"></app-team-member-form-control>
                    </div>

                    <p-fieldset legend="Links" [toggleable]="true" class="field col-12 p-fluid">
                      <div formGroupName="usefulLinks" class="flex justify-content-between">
                        <div *ngFor="let link of links; let i = index" class="p-fluid w-full"
                             [class.mr-2]="i < links.length - 1" [class.ml-2]="i > 0">
                          <app-form-control-wrapper [controlName]="link.field" [label]="link.label"
                                                    [labelIcon]="link.icon"
                                                    [iconIsLink]="!link.notUrl"></app-form-control-wrapper>
                        </div>
                      </div>
                    </p-fieldset>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="Workweek">
                  <div class="field col-12 p-fluid flex justify-content-center">
                    <div>
                      <div class="grid mb-1">
                        <div class="col-4 p-fluid">
                          <app-team-member-form-control
                              name="defaultWorkweekId"></app-team-member-form-control>
                        </div>
                        <div class="col-offset-5 col-3 p-fluid flex justify-content-end pt-6">
                          <app-team-member-form-control
                              name="isWorkweekOverridden"></app-team-member-form-control>
                        </div>
                      </div>
                      <p-divider></p-divider>
                      <label class="block mb-3">Overridden Workweek</label>
                      <app-workweek-editor formControlName="overriddenWorkweeks"
                                           nullable></app-workweek-editor>
                      <app-control-error styleClass="mt-1"
                                         name="overriddenWorkweeks"></app-control-error>
                    </div>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="Holiday Calendar">
                  <div class="field col-12 p-fluid flex justify-content-center">
                    <div>
                      <div class="grid">
                        <div class="col-4 p-fluid">
                          <app-team-member-form-control
                              name="holidayCalendarId"></app-team-member-form-control>
                        </div>
                        <div class="col-offset-5 col-3 p-fluid flex justify-content-end pt-6">
                          <app-team-member-form-control
                              name="isHolidayCalendarOverridden"></app-team-member-form-control>
                        </div>
                      </div>
                      <p-divider></p-divider>
                      <label class="block mb-3 font-medium">Overridden Holiday Calendar</label>
                      <app-holiday-calendar-editor formControlName="overriddenHolidayCalendar"
                                                   [showBasicCalendarChooser]="true"
                                                   [editGeneralProperties]="false"
                                                   nullable></app-holiday-calendar-editor>
                      <app-control-error styleClass="mt-1"
                                         name="overriddenHolidayCalendar"></app-control-error>
                    </div>
                  </div>
                </p-tabPanel>
              </p-tabView>
              <div *ngIf="!isNew() && !isDraft()">
                <app-collapsible-panel [isExpanded]="true"
                                       [toggleYPosition]="'0'"
                                       [width]="'200px'"
                                       expandTooltip="Expand Associations">
                  <div class="pl-3 h-full">
                    <div>
                      <p-card *ngFor="let card of 7 | arrayOf; let i = index" [header]="'Association ' + (i+1)" styleClass="mb-4">
                        <p class="m-0">
                          {{'Association ' + (i+1)}}
                        </p>
                      </p-card>
                    </div>
                  </div>
                </app-collapsible-panel>
              </div>
            </div>
          </app-collapsible-panel>
        </div>
      </form>
    </div>
  `,
  providers: [
    {provide: EntityEditorBaseComponent, useExisting: forwardRef(() => TeamMemberEditorComponent)},
    SubscriptionsService,
    TeamMemberEditService
  ]

})
export class TeamMemberEditorComponent extends TeamParticipantEditorComponent<TTeamMemberSave, ITeamMember> {
  links = [
    {field: 'timekeeping', icon: faClock, label: 'TimeKeeping'},
    {field: 'slack', icon: faSlack, label: 'Slack'},
    {field: 'skype', icon: faSkype, label: 'Skype', notUrl: true},
  ];

  constructor(public resource: AppResourceService,
              private subscriptions: SubscriptionsService,
              public editService: TeamMemberEditService,
              fb: FormBuilder,
              propertyOptions: EntityPropertyOptionsService) {
    super(fb, propertyOptions);
    this.isDraftable = true;
    this.significantDraftFields = ['firstName', 'lastName', 'email', 'country', 'city', 'timezone', 'type'];
    this.entityName = 'Team Member';
    this.editService.setEditor(this);
    this.api = {
      getEntity: this.resource.getTeamMember.bind(this.resource),
      createEntity: this.resource.createTeamMember.bind(this.resource),
      updateEntity: this.resource.updateTeamMember.bind(this.resource),
      archiveEntities: this.resource.patchTeamMembers.bind(this.resource),
      deleteEntities: this.resource.deleteTeamMembers.bind(this.resource)
    }
  }

  override setTitle(): void {
    if (!!this.param.data) {
      this._title = `Transform Team Candidate ${this.param.data.name} into Team Member`;
    } else {
      super.setTitle();
    }
  }

  override buildForm(): void {
    this.subscriptions.unsubscribe();
    const checkExistsPrimarySourceUrl = this.getCheckExists(this.resource.getTeamMembers.bind(this.resource), 'primarySourceUrl');
    const checkExistsEmail = this.getCheckExists(this.resource.getTeamMembers.bind(this.resource), 'email');
    this.form = this.fb.group<TControlsOf<TTeamMemberSave>>({
      ...this.createSharedControls(this.param.data),
      status: new FormControl(this.isNew() ? 'Active' : this.entity.status, [Validators.required]),
      statusChangedReason: new FormControl(this.entity.statusChangedReason, []),
      estimatedAvailableDate: new FormControl(this.entity.estimatedAvailableDate, []),
      title: new FormControl(this.entity.title, [Validators.required]),
      hiredDate: new FormControl(this.param.data || this.isNew() ? new Date() : this.entity.hiredDate, [Validators.required]) as any,
      type: new FormControl(this.entity.type, [Validators.required]),
      costRateDefaultPlus: new FormControl(this.entity.costRateDefaultPlus, []),
      weeklyCapacityMin: new FormControl(this.isNew() ? 40 : this.entity.weeklyCapacityMin, []),
      weeklyCapacityTarget: new FormControl(this.isNew() ? 40 : this.entity.weeklyCapacityTarget, [Validators.required]),
      weeklyCapacityMax: new FormControl(this.isNew() ? 40 : this.entity.weeklyCapacityMax, [Validators.required]),
      defaultHourlyRate: new FormControl(this.entity.defaultHourlyRate, [Validators.required]),
      paymentTerms: new FormControl(this.entity.paymentTerms, []),
      netTerms: new FormControl(this.isNew() ? 10 : this.entity.netTerms, []),

      isWorkweekOverridden: new FormControl(this.isNew() ? false : this.entity.isWorkweekOverridden, [Validators.required]),
      defaultWorkweekId: new FormControl(this.isNew() ? null : this.entity.defaultWorkweekId, []),
      overriddenWorkweeks: new FormControl(this.isNew() ? null : this.entity.overriddenWorkweeks, []),

      isHolidayCalendarOverridden: new FormControl(this.isNew() ? false : this.entity.isHolidayCalendarOverridden, [Validators.required]),
      holidayCalendarId: new FormControl(this.entity.holidayCalendarId, []),
      overriddenHolidayCalendar: new FormControl(this.isNew() ? null : this.entity.overriddenHolidayCalendar, []),

      pto: new FormControl(this.isNew() ? null : this.entity.pto, []),
      ptoFrom: new FormControl(this.isNew() ? null : this.entity.ptoFrom, []),

      userRoles: new FormControl(this.entity.userRoles, [Validators.required]),
      isActivelyStuffed: new FormControl(this.isNew() ? false : this.entity.isActivelyStuffed, [Validators.required]),
      gustoRequired: new FormControl(this.isNew() ? false : this.entity.gustoRequired, [Validators.required]),
      contractorCompanyId: new FormControl(this.entity.contractorCompanyId, []),
      paymentRecipientId: new FormControl(this.entity.paymentRecipientId, [Validators.required]),
      mtEmailType: new FormControl(this.entity.mtEmailType, [Validators.required]),
      mtEmail: new FormControl(this.entity.mtEmail, [Validators.email, Validators.required]),
      usefulLinks: this.fb.group<TControlsOf<IUsefulLinks>>({
        timekeeping: new FormControl(this.entity.usefulLinks?.timekeeping, [urlValidator]),
        slack: new FormControl(this.entity.usefulLinks?.slack, [urlValidator]),
        skype: new FormControl(this.entity.usefulLinks?.skype, []),
      }) as any
    });
    this.form!.controls.primarySourceUrl!.setAsyncValidators([existsAsyncValidator(checkExistsPrimarySourceUrl)]);
    this.form!.controls.email.setAsyncValidators([existsAsyncValidator(checkExistsEmail)]);
    this.addSharedControlsProcessing(this.subscriptions);
    this.editService.subscribe();
    // this.removeRequiredValidators(this.form, ['firstName', 'lastName']);
  }

  override getLink(): string | null {
    return !this.isNew() ? `/team-members/edit/${this.param!.id}` : null;
  }

}
