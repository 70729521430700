import {
  TEAM_MEMBER_ARCHIVE_REASONS_GROUPED,
  TEAM_MEMBER_CURRENTLY_UNAVAILABLE_REASONS,
  TTeamMemberType
} from '../../api/shared/app-domain/team';
import { TreeNode } from 'primeng/api';
import { keys } from 'lodash';

export const TEAM_MEMBER_TYPE_ICONS: {[prop in TTeamMemberType]: string} = { // TODO
  'Employee - Hourly': 'Employee Hourly',
  'Employee - Recurring Fixed': 'Employee Bimonthly Fixed',
  'Contractor - Hourly': 'Contractor Hourly',
  'Contractor - Monthly Fixed': 'Contractor Monthly Fixed',
  'Contractor - One-Time Fixed': 'Contractor One Time Fixed',
  'TP SubContractor - Hourly': 'TP SubContractor Hourly',
  'TP SubContractor - Monthly Fixed': 'TP SubContractor Monthly Fixed',
  'TP SubContractor - One-Time Fixed': 'TP SubContractor One Time Fixed'
};


export function getTeamMemberTypeIcon(type: TTeamMemberType): string | null {
  return TEAM_MEMBER_TYPE_ICONS[type] ? `assets/images/team/${TEAM_MEMBER_TYPE_ICONS[type]}.svg` : null;
}
export const TEAM_SCOPES = ['all', 'talentPartner'] as const;

export const TEAM_TABS = [
  'listing', 'details', 'planning'
] as const;
export type TTeamTab = typeof TEAM_TABS[number];

export type TTeamScope = typeof TEAM_SCOPES[number];

export function teamMemberStatusChangedReasonsToTreeNodes(): Array<TreeNode> {
  const reasons: Array<TreeNode> =[
    {
      key: 'Archived',
      label: 'Archive Reasons', styleClass: 'mt-overflow-ellipsis font-bold', selectable: false, icon: 'pi pi-server',
      children: keys(TEAM_MEMBER_ARCHIVE_REASONS_GROUPED).map((group) => ({
        label: group, styleClass: 'mt-overflow-ellipsis font-medium', selectable: false,
        children: TEAM_MEMBER_ARCHIVE_REASONS_GROUPED[group].map((reason) => ({
          label: reason, styleClass: 'mt-overflow-ellipsis font-normal', leaf: true,
          data: reason
        }))
      }))
    },
    {
      key: 'Currently Unavailable',
      label: 'Currently Unavailable Reasons', styleClass: 'mt-overflow-ellipsis font-bold', selectable: false, icon: 'pi pi-minus-circle',
      // children: keys(TEAM_MEMBER_CURRENTLY_UNAVAILABLE_REASONS_GROUPED).map((group) => ({
      //   label: group, styleClass: 'mt-overflow-ellipsis font-medium', selectable: false,
      //   children: TEAM_MEMBER_CURRENTLY_UNAVAILABLE_REASONS_GROUPED[group].map((reason) => ({
      //     label: reason, styleClass: 'mt-overflow-ellipsis font-normal', leaf: true,
      //     data: reason
      //   }))
      // }))
      children: TEAM_MEMBER_CURRENTLY_UNAVAILABLE_REASONS.map((reason) => ({
        label: reason, styleClass: 'mt-overflow-ellipsis font-normal', leaf: true,
        data: reason
      }))

    }
  ];

  return reasons;
}
