import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest, HttpResponse
} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {from, mergeMap, Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {AuthService} from './common/services/auth.service';
import {environment} from '../environments/environment';
import {TokenStorageService} from './common/services/token-storage.service';
import {HttpDataConverterService} from './common/services/http-data-converter.service';
import {cloneDeep} from 'lodash';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService,
              private auth: AuthService,
              private tokenStorageService: TokenStorageService,
              private dataConverter: HttpDataConverterService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.body && !(request.body instanceof FormData)) {
      request = request.clone({
        body: this.dataConverter.out(cloneDeep(request.body))
      });
    }

    return from(this.tokenStorageService.getOrRefreshToken())
      .pipe(
        catchError((error) => {
          this.auth.signOut();
          return throwError(error);
        }),
        map((token) => {
          let headers = request.headers;
          const params = new HttpParams({fromString: request.params.toString()});
          if (!!token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
          }
          return request.clone({
            url: `${environment.apiUrl}${request.url}`,
            params,
            headers
          });
        }),
        mergeMap((request) => {
          return next
            .handle(request).pipe(
              tap((ev: HttpEvent<any>) => {
                if (ev instanceof HttpResponse) {
                  this.dataConverter.in(ev.body);
                }
              }),
              catchError((response) => {
                if (response instanceof HttpErrorResponse) {
                  const httpErrorResponse = response as HttpErrorResponse;
                  if (httpErrorResponse.status === 401) {
                    this.auth.signOut();
                  }
                  const detail = httpErrorResponse.error?.message || httpErrorResponse.message;
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Response Error',
                    detail,
                    life: 10000
                  });
                }
                return throwError(response);
              })
            );
        })
      );
  }
}



