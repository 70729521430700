import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {capitalize, keys, sumBy, values} from 'lodash';
import {hexToRgba, stringToColor} from '../../common/util/util';
import {IDictionary} from '../../common/types';
import {PrimeTemplate} from 'primeng/api';

const REMAINDER = 'remainder';
const REMAINDER_COLOR = '#D5DFE6';

@Component({
  selector: 'app-speedometer-chart',
  template: `
    <p-chart type="doughnut" [data]="chartData" [options]="options" [style]="{'z-index': 1}"></p-chart>
    <div *ngIf="center" class="mt-speedometer-center">
      <ng-container *ngTemplateOutlet="center"></ng-container>
    </div>
    <div *ngIf="bottom" class="mt-speedometer-bottom">
      <ng-container *ngTemplateOutlet="bottom"></ng-container>
    </div>
  `,
  styles: [`
    :host {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
    }

    :host ::ng-deep p-chart > div {
      z-index: 1;
    }

    .mt-speedometer-center {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .mt-speedometer-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

  `]
})
export class SpeedometerChartComponent implements OnChanges, AfterContentInit {
  @Input() data!: IDictionary<number>;
  @Input() total!: number;
  @Input() dataOrder?: Array<string>;

  center?: TemplateRef<any>;
  bottom?: TemplateRef<any>;


  chartData: any;
  options = {
    plugins: {
      legend: {display: false},
    },
    cutout: '87%',
    rotation: 210,
    circumference: 300
  };

  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      const names: Array<string> = this.dataOrder ? this.dataOrder : keys(this.data);
      names.push(REMAINDER);
      const colors = names.map((item) => item === REMAINDER ? REMAINDER_COLOR : stringToColor(item));
      const sum = sumBy(values(this.data), (v) => v);
      const reminderValue = this.total - sum;
      this.chartData = {
        labels: names.map((item) => ' ' + capitalize(item)),
        datasets: [
          {
            data: names.map((item) => item === REMAINDER ? reminderValue : this.data[item] ?? 0),
            backgroundColor: colors,
            hoverBackgroundColor: colors.map((c) => hexToRgba(c, .7)),
            borderWidth: 0,
            borderRadius: names.map((item, i) => {
              if (i === 0) {
                return {outerStart: 10, innerStart: 10}
              } else if (i === names.length - 1) {
                return {outerEnd: 10, innerEnd: 10};
              }
              return 0;
            })
          }
        ]
      };
    }
  }

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (tpl.getType() === 'center') {
        this.center = tpl.template;
      } else if (tpl.getType() === 'bottom') {
        this.bottom = tpl.template;
      }
    });
  }
}
