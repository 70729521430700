import { AfterViewInit, Component, OnChanges } from '@angular/core';
import { AppResourceService } from '../../app.resource.service';
import { ISearchRequest, ISearchResponse } from '../../api/shared/search-api';
import { Observable } from 'rxjs';
import { PageableTableComponent } from '../../common/components/pageable-table.component';
import { TableQuery } from '../../common/util/table-query';
import { Router } from '@angular/router';
import { ISkill, SKILL_API_ENDPOINTS } from '../../api/shared/app-domain/skill';
import { EntityPropertyOptionsService } from '../common/entity-property-options.service';
import { ColumnsInfoService } from '../common/column-header.component';
import {IMenuItem} from '../../common/components/table-base.component';
import {IIdentified} from '../../api/shared/app-domain/common';
import {filter} from 'lodash';
import {ServiceLocator} from '../../common/util/util';

@Component({
  selector: 'app-skills-table',
  template: `
    <app-table-toolbar [tableBase]="this" [omitMenuItems]="['edit']" [customMenuItems]="customTableMenuItems"></app-table-toolbar>
    <app-entity-edit-dialog #editDialog (apply)="onCreatedOrUpdated($event)">
      <ng-template pTemplate let-param>
        <app-skills-editor [param]="param"></app-skills-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-spinnerizer [active]="loading"
                     [target]="container">
    </app-spinnerizer>
    <div #container>
      <p-table [value]="data"
               [(first)]="firstPage"
               responsiveLayout="scroll"
               dataKey="id"
               [lazy]="true"
               (onLazyLoad)="load($any($event))"
               [paginator]="true"
               currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Skills"
               [rowsPerPageOptions]="[10, 25, 50, 100]"
               [showCurrentPageReport]="true"
               [lazyLoadOnInit]="false"
               [rows]="pageSize"
               [totalRecords]="total"
               sortMode="multiple"
               [(selection)]="selection"
               [selectionPageOnly]="true"
               (selectionChange)="onSelectionChange()"
               scrollDirection="horizontal"
               [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <app-column-header [isSelector]="true"></app-column-header>
            <app-column-header field="name" filterType="text" [fixedWidth]="240" frozen="lastLeft">
            </app-column-header>
            <app-column-header field="status" filterType="optionsEquals"
                               [fixedWidth]="120"
                               [options]="propertyOptions.skillsStatuses">
            </app-column-header>
            <app-column-header field="silo" filterType="optionsIn"
                               [fixedWidth]="200"
                               optionValue="silo"
                               [options]="$any(propertyOptions.getOptions('skillSilos') | async)">
            </app-column-header>
            <app-column-header field="adjective" filterType="text" [fixedWidth]="200"></app-column-header>
            <app-column-header field="singular" filterType="text" [fixedWidth]="300"></app-column-header>
            <app-column-header field="plural" filterType="text" [fixedWidth]="300"></app-column-header>
            <app-column-header field="team" filterType="text" [fixedWidth]="300"></app-column-header>
            <app-column-header field="teams" filterType="text" [fixedWidth]="300"></app-column-header>
            <app-column-header field="siloMember" filterType="text" [fixedWidth]="200"></app-column-header>
            <app-column-header field="siloMembers" filterType="text" [fixedWidth]="200"></app-column-header>
            <app-column-header field="urlSlug" label="URL Slug" filterType="text" [fixedWidth]="300"></app-column-header>
            <app-column-header field="metaTitle" filterType="text" [fixedWidth]="200"></app-column-header>
            <app-column-header field="metaDescriptions" filterType="text" [fixedWidth]="200"></app-column-header>
            <app-column-header field="isOkToPublish" filterType="boolean" label="Ok to Publish" [fixedWidth]="180"></app-column-header>
            <app-column-header field="createdAt" filterType="date" [fixedWidth]="180"></app-column-header>
            <app-column-header field="updatedAt" filterType="date" [fixedWidth]="180"></app-column-header>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
          <tr>
            <app-table-cell [isSelector]="true" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="name" type="custom" [rowData]="rowData" frozen="lastLeft"
                            styleClass="justify-content-between py-0">
              <div class="flex align-items-center overflow-x-hidden">
                <div class="mt-overflow-ellipsis" appTooltipOnOverflow>
                  <a class="text-primary no-underline"
                     [routerLink]="['/tbd', 'skills-edit', rowData.id]">{{rowData.name}}</a>
                </div>
              </div>
              <app-row-menu-button [tableBase]="this" [rowData]="rowData"></app-row-menu-button>
            </app-table-cell>
            <app-table-cell field="status" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="silo" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="adjective" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="singular" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="plural" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="team" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="teams" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="siloMember" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="siloMembers" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="urlSlug" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="metaTitle" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="metaDescriptions" [rowData]="rowData"></app-table-cell>
            <app-table-cell field="isOkToPublish" [rowData]="rowData" type="boolean"></app-table-cell>
            <app-table-cell field="createdAt"
                            [value]="rowData.createdAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
            <app-table-cell field="updatedAt"
                            [value]="rowData.updatedAt | date: 'MM/dd/yy hh:mm:ss a'"></app-table-cell>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  providers: [
    EntityPropertyOptionsService,
    ColumnsInfoService
  ]
})
export class SkillsTableComponent extends PageableTableComponent<ISkill> implements AfterViewInit, OnChanges {
  customTableMenuItems: Array<IMenuItem> = [
    {
      id: 'publish',
      label: 'Publish',
      icon: 'pi pi-reply',
      disabled: true,
      command: () => {
        const data: Array<IIdentified & { isOkToPublish: boolean }> = filter<any>(this.selection, (e) => !e.isOkToPublish).map((e) => ({
          id: e.id,
          isOkToPublish: true
        }));
        this.resource.patchSkills(data)
          .subscribe((response) => {
            this.onUpdatedBySelection(response);
          });
      }
    }
  ];
  constructor(private resource: AppResourceService,
              private router: Router,
              public propertyOptions: EntityPropertyOptionsService) {
    super();
    this.api = {
      archiveEntities: this.resource.patchSkills.bind(this.resource),
      deleteEntities: this.resource.deleteSkills.bind(this.resource),
      exportEntities: this.resource.exportSkills.bind(this.resource),
      importUrl: SKILL_API_ENDPOINTS.import
    };
  }

  protected override fetchData(searchRequest: ISearchRequest): Observable<ISearchResponse<ISkill>> {
    return this.resource.searchSkills(searchRequest);
  }

  override afterCrudPerformed() {
    this.propertyOptions.reset('skillSilos');
  }

  override canPerformTableMenuAction(itemId: string): ((entity: ISkill) => boolean) {
    if (itemId === 'publish') {
      return ((entity: ISkill) => !!!entity.isOkToPublish);
    }
    return super.canPerformTableMenuAction(itemId);
  }
}
